import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeFontWeight } from 'src/styles';

export const Wrapper = styled(Box)(() => ({
  height: '100%',
  position: 'relative',
}));

export const Block = styled(Box)(({ theme }) => ({
  // borderRadius: '16px',
  backgroundColor: theme.palette.background.paper,
  cursor: 'pointer',
  height: '100%',
  '&.GoalListItem_unactive': {
    cursor: 'default',
    '& img': {
      opacity: 0.5,
    },
    '& button': {
      cursor: 'default',
    },
  },
}));

export const Image = styled('img')(() => ({
  objectFit: 'cover',
  width: '100%',
  height: 'auto',
  display: 'block',
  // borderRadius: '16px',
}));

export const Info = styled(Box)(({ theme }) => ({
  padding: '6px 8px',
  position: 'absolute',
  bottom: '16px',
  width: '100%',
  textAlign: 'center',
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  lineHeight: '18px',
  fontWeight: ThemeFontWeight.semiBold,
  color: theme.palette.primary.main,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  textAlign: 'center',
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '18px',
  color: theme.palette.primary.main,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  textAlign: 'center',
}));

export const Row = styled(Stack)(() => ({
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));
