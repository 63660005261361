import { Link as LinkIcon, Download as DownloadIcon, HighlightOff as HighlightOffIcon } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { ethers, parseEther } from 'ethers';
import { FC, useCallback, useEffect, useState } from 'react';

import {
  connectToWallet,
  getAddressAndBalance,
  getNetworkChainId,
  getTokensBalance,
  sendToContract,
  TTokenAddresses,
} from 'src/helpers/Blockchain/Blockchain';
import { getProviders, TProvider } from 'src/helpers/Blockchain/providers';
import { walletProviders } from 'src/types';
import { logger } from 'src/utils';

import { Label } from './Blockchain.styles';

const Blockchain: FC = () => {
  const [providers, setProviders] = useState<TProvider[]>([]);
  const [providerType, setProviderType] = useState<walletProviders>();
  const [, setProvider] = useState<ethers.BrowserProvider>();
  const [signer, setSigner] = useState<ethers.JsonRpcSigner>();

  const [address, setAddress] = useState<string>();
  const [balance, setBalance] = useState<string>();
  const [tokenBalance, setTokenBalance] = useState<TTokenAddresses[]>([]);

  const [outValue, setOutValue] = useState<string>('0.0');
  const [contractId, setContractId] = useState<string>('0x15d6df1694bcc06cc20aad20ebe6bba737ce4dfa');
  const [invoice, setInvoice] = useState<number>(1234);

  const [txID, setTxId] = useState<string>('');

  const [chain, setChain] = useState<{ chainId: bigint; name: string }>();

  const checkProviders = useCallback(() => {
    const providersList = getProviders();
    setProviders(providersList);
  }, []);

  useEffect(() => {
    checkProviders();
  }, [checkProviders]);

  const onConnectWallet = async (provType: walletProviders) => {
    const { accounts, provider, signer } = await connectToWallet(provType);
    setProvider(provider);
    setSigner(signer);
    setProviderType(provType);
    logger.log(accounts, provider, signer);
  };

  useEffect(() => {
    if (!signer) return;
    getAddressAndBalance(signer).then(({ address, balance }) => {
      setAddress(address);
      setBalance(balance.toString());
    });
    getNetworkChainId(signer).then((networkChainId) => {
      setChain(networkChainId);
    });
    getTokensBalance(signer).then((tokens) => {
      setTokenBalance(tokens);
    });
  }, [signer]);

  const onValueChange = (val: string) => {
    setOutValue(val);
  };

  const toBigInt = (val: string) => {
    try {
      return parseEther(String(outValue)).toString();
    } catch (error) {
      return 'error';
    }
  };

  const handlerSendToContract = () => {
    if (!signer || !address || !contractId || !outValue) return;

    sendToContract(signer, {
      to: contractId,
      value: outValue,
      invoice,
    }).then((txID) => {
      setTxId(txID);
    });
  };

  return (
    <Box sx={{ m: '16px' }}>
      <Stack>
        {providers.map((prov) => (
          <Box key={`provItems_${prov.name}`}>
            {prov.name} –{' '}
            {!!prov.provider ? (
              <IconButton
                sx={{ color: providerType === prov.type ? 'red' : 'primary.main' }}
                onClick={() => onConnectWallet(prov.type)}
              >
                <LinkIcon />
              </IconButton>
            ) : (
              <IconButton href={prov.link} target="_blank" rel="noopener noreferrer">
                <DownloadIcon />
              </IconButton>
            )}
          </Box>
        ))}
      </Stack>
      {signer && (
        <Stack spacing="8px">
          <Label>Address</Label>
          <Typography>{address}</Typography>
          {chain && (
            <>
              <Label>Chain</Label>
              <Typography>
                {chain.name} ({chain.chainId.toString()})
              </Typography>
            </>
          )}
          <Label>Balance</Label>
          <Typography>{balance}</Typography>
          <Label>Token Balance</Label>
          {tokenBalance?.map((token, idx) => (
            <Stack
              direction="row"
              key={`token_balance_${token.type}_${idx}`}
              spacing="16px"
              sx={{ whiteSpace: 'nowrap' }}
            >
              <Typography>{token.title}</Typography>
              <Typography>{token.type}</Typography>
              <Typography>
                <b>{token.balance?.toString()}</b>
              </Typography>
              <Label>{token.address}</Label>
            </Stack>
          ))}
          <Divider sx={{ my: '16px' }} />
          <Label sx={{ mt: '16px' }}>Send to conract:</Label>
          <TextField
            onChange={(event) => onValueChange(event.target.value)}
            value={outValue}
            fullWidth
            inputProps={{
              maxLength: 20,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="reset" onClick={() => setOutValue('0.0')}>
                    <HighlightOffIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography>{toBigInt(outValue)}</Typography>
          <Label sx={{ mt: '16px' }}>ConractID:</Label>
          <TextField
            onChange={(event) => setContractId(event.target.value)}
            value={contractId}
            fullWidth
            inputProps={{
              maxLength: 50,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="reset" onClick={() => setContractId('')}>
                    <HighlightOffIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Label sx={{ mt: '16px' }}>Invoice:</Label>
          <TextField
            onChange={(event) => {
              try {
                const val = parseInt(event.target.value, 10);
                if (val) setInvoice(val);
              } catch (error) {
                logger.error(error);
              }
            }}
            value={invoice}
            fullWidth
            inputProps={{
              maxLength: 50,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="reset" onClick={() => setInvoice(0)}>
                    <HighlightOffIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button fullWidth sx={{ my: '16px' }} onClick={() => handlerSendToContract()}>
            send
          </Button>

          {txID && (
            <>
              <Label sx={{ mt: '16px' }}>txID:</Label>
              <Button
                variant="text"
                href={`https://testnet.bscscan.com/tx/${txID}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {txID}
              </Button>
            </>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default Blockchain;
