import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, IconButton, Typography } from '@mui/material';
import { FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerBottom, EmptyData, Loading } from 'src/atoms';
import { DEFAULT_LIMIT } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { MemberOfTeam, VideoPlayer, WindowTitle } from 'src/molecules';
import { TTargetFeedback, getMarketFeedback } from 'src/services';
import { logger } from 'src/utils';

import { ProductFeedbackListProps } from './ProductFeedbackList.types';

const ProductFeedbackList: FC<ProductFeedbackListProps> = ({ targetId, onClick, onLoad, sx }) => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);

  const [isLoading, setLoading] = useState(true);
  const [isPending, setPending] = useState(false);
  const [items, setItems] = useState<TTargetFeedback[] | null>(null);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const [isVideoOpen, setVideoOpen] = useState<string | null>(null);

  useEffect(() => {
    setOffset(0);
  }, [targetId]);

  useEffect(() => {
    if (offset === 0) {
      setLoading(true);
      getMarketFeedback({ offset, limit: DEFAULT_LIMIT }, targetId)
        .then(({ data }) => {
          setItems(data.results);
          setOffset(DEFAULT_LIMIT);
          setHasMore(data.count > offset + data.results.length);
          if (onLoad) onLoad(data);
        })
        .catch((error) => {
          if (error.response?.status >= 500) throwServerError();
          logger.error(error);
        })
        .finally(() => setLoading(false));
    }
  }, [offset, targetId, onLoad, throwServerError]);

  const loadMoreItems = () => {
    if (!hasMore) return;

    if (!isPending) {
      setPending(true);
      getMarketFeedback({ offset: offset, limit: DEFAULT_LIMIT }, targetId)
        .then(({ data }) => {
          items && setItems([...items, ...data.results]);
          setOffset((prevOffset) => prevOffset + DEFAULT_LIMIT);
          setHasMore(data.count > offset + data.results.length);
        })
        .catch((error) => {
          if (error.response?.status >= 500) throwServerError();
          logger.error(error);
        })
        .finally(() => setPending(false));
    }
  };

  return (
    <Box sx={{ height: '100%', ...sx }}>
      {isLoading ? (
        <Loading />
      ) : items?.length ? (
        <Box
          sx={{
            position: 'relative',
            mx: '16px',
            mb: '16px',
          }}
        >
          {items.map((item, idx) => (
            <MemberOfTeam
              key={`Feedbacklist_item_${item.user.user_id}_${idx}`}
              onSelectMember={(id) => setVideoOpen(item.video_link)}
              showAddress={true}
              member={{
                id: item.user.user_id,
                user_id: item.user.user_id,
                photo_uuid: item.user.photo_uuid,
                display_name: item.user.display_name,
                date_joined: item.updated_at,
                public_address: item.user.telegram,
              }}
            >
              {item.video_link ? (
                <IconButton sx={{ backgroundColor: 'secondary.main' }}>
                  <PlayArrowIcon fontSize="large" />
                </IconButton>
              ) : undefined}
            </MemberOfTeam>
          ))}
          {hasMore && !isPending && (
            <Typography
              sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
              data-qa="button.more"
              textAlign="center"
              onClick={() => loadMoreItems()}
            >
              {t('button.more')}
            </Typography>
          )}
        </Box>
      ) : (
        <EmptyData
          title={t('productFeedbackList.emptyTitle')}
          data-qa={'productFeedbackList.emptyTitle'}
          text={t('productFeedbackList.emptyText')}
        />
      )}

      <DrawerBottom isOpen={!!isVideoOpen} onClose={() => setVideoOpen(null)}>
        <>
          <WindowTitle
            data-qa="productFeedbackList.video.title"
            title={t('productFeedbackList.video.title')}
            onClose={() => setVideoOpen(null)}
          />
          {isVideoOpen && (
            <VideoPlayer
              url={isVideoOpen}
              sx={{
                mb: '16',
                '& video': {
                  borderRadius: '16px',
                  backgroundColor: 'background.paper',
                },
              }}
            />
          )}
        </>
      </DrawerBottom>
    </Box>
  );
};

export default ProductFeedbackList;
