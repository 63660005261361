import { useSnackbar } from 'notistack';
import { FC, useEffect } from 'react';

import methods, { SecretSocketMessageType } from 'src/agent/ws-agent';
import { LIMIT_NOTICES } from 'src/constants';
import { useAppDispatch } from 'src/hooks';
import { getNoticesThunk } from 'src/store/noticeSlice';

const MAX_CONTENT_LENGTH = 80;

const NotificationWSController: FC = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    return methods.subscribe(SecretSocketMessageType.NoticeNew, (payload) => {
      const content = payload.content.replace(/(<([^>]+)>)/gi, '');
      enqueueSnackbar(
        content.length > MAX_CONTENT_LENGTH ? content.substring(0, MAX_CONTENT_LENGTH) + '...' : content,
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        }
      );
      dispatch(getNoticesThunk({ is_read: false, offset: 0, limit: LIMIT_NOTICES }));
    });
  }, [enqueueSnackbar, dispatch]);

  return null;
};

export default NotificationWSController;
