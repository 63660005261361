import { FC } from 'react';

import { DateWrapper } from './DateNotification.styles';
import { DateNotificationProps } from './DateNotification.types';

const DateNotification: FC<DateNotificationProps> = ({ children }) => {
  return <DateWrapper>{children}</DateWrapper>;
};

export default DateNotification;
