// import FavoriteIcon from '@mui/icons-material/Favorite';
//import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Button } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NoPhoto } from 'src/assets/images';
import { Paths, pathsArg, getImgSrc } from 'src/constants';

import { Block, Wrapper, Image, Title, SubTitle, Row, Info } from './GoalListItem.styles';
import { GoalItemProps } from './GoalListItem.types';

const GoalListItem: FC<GoalItemProps> = ({ item, onClick, sx }) => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  const handlerOnClick = () => {
    if (item.is_active) {
      if (onClick) {
        onClick(item);
      } else {
        navigate(pathsArg(Paths.goalProduct, { compositeId: item.composite_id }));
      }
    }
  };

  return (
    <Wrapper sx={sx}>
      <Block onClick={handlerOnClick} className={item.is_active ? 'GoalListItem_active' : 'GoalListItem_unactive'}>
        <Image
          sx={{ width: '100%', aspectRatio: '3 / 4' }}
          src={item.photo_uuid ? getImgSrc(item.photo_uuid, 'h800-c') : NoPhoto}
          alt={t(item.name)}
        />
        <Info>
          <Row>
            <Title>{t(item.registry_name || item.name)}</Title>
            {!item.is_folder && (
              <>
                <SubTitle sx={{ my: '16px' }}>{t(item.description)}</SubTitle>
                <Button variant="contained">{t(item.is_active ? 'goalList.choose' : 'goalList.soon')}</Button>
              </>
            )}
          </Row>
        </Info>
      </Block>
    </Wrapper>
  );
};

export default GoalListItem;
