import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PackWidgetImage } from 'src/assets/images';
import { Paths } from 'src/constants';

import {
  PackWidgetBox,
  PackWidgetTitle,
  PackWidgetFutter,
  PackWidgetStack,
  HRFutter,
  PackWidgetImg,
} from './PackWidget.styles';
import { PackWidgetProps } from './PackWidget.types';

const PackWidget: FC<PackWidgetProps> = ({ sx }) => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(Paths.specialOffer);
  };

  return (
    <>
      <PackWidgetBox onClick={onClickHandler} sx={sx}>
        <PackWidgetTitle>{t('dashboard.packWidget')}</PackWidgetTitle>
        <PackWidgetStack direction="row">
          <Box
            className="PackWidgetBox_text"
            sx={{ flex: 1, fontSize: '0.75rem', textOverflow: 'ellipsis', overflow: 'hidden' }}
          >
            {t('dashboard.packWidget.text')}
          </Box>
          <Box sx={{ flex: 0, position: 'relative', minWidth: '135px' }}>
            <PackWidgetImg src={PackWidgetImage} sx={{ width: '129px', height: '102px' }} />
          </Box>
        </PackWidgetStack>
        <HRFutter />
        <PackWidgetFutter>{t('dashboard.packWidget.futter')}</PackWidgetFutter>
      </PackWidgetBox>
    </>
  );
};

export default PackWidget;
