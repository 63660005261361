import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyData, Loading } from 'src/atoms';
import { ErrorContext } from 'src/context/ErrorContext';
import { getGoalRead, TCard } from 'src/services';
import { Card } from 'src/templates';
import { logger } from 'src/utils';

import { GoalCardProps } from './GoalCard.types';

const GoalCard: FC<GoalCardProps> = ({ compositeId }) => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);
  const [isLoading, setLoading] = useState(true);
  const [item, setItem] = useState<TCard | undefined>(undefined);

  useEffect(() => {
    if (compositeId) {
      setLoading(true);
      getGoalRead(compositeId)
        .then(({ data }) => {
          setItem(data);
        })
        .catch((error) => {
          if (error.response?.status >= 500) throwServerError();
          logger.error(error);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [compositeId, throwServerError]);

  if (isLoading) return <Loading />;

  return item ? (
    <Card item={item} />
  ) : (
    <EmptyData title={t('goalCard.emptyTitle')} data-qa={'goalCard.emptyTitle'} text={t('goalCard.emptyText')} />
  );
};

export default GoalCard;
