import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';

import { Loading } from 'src/atoms';
import { DeliveryForm } from 'src/molecules';
import { deliveryCreate, deliveryUpdate, TDeliveryFormValues } from 'src/services';
import { parseErrorTr } from 'src/utils';

const Delivery: FC<{ compositeId: string; onFinish?: () => void }> = ({ compositeId, onFinish }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deliveryData, setDeliveryData] = useState<TDeliveryFormValues>();

  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    deliveryCreate(compositeId)
      .then(({ data }) => {
        setDeliveryData(data.delivery);
      })
      .catch((err) => {
        enqueueSnackbar(parseErrorTr('deliveryForm', err), { variant: 'error' });
      })
      .finally(() => setLoading(false));
  }, [enqueueSnackbar, compositeId]);

  const handlerApprove = (formData: TDeliveryFormValues) => {
    return deliveryUpdate(compositeId, formData)
      .then(() => {
        if (onFinish) onFinish();
      })
      .catch((err) => {
        enqueueSnackbar(parseErrorTr('deliveryForm', err), { variant: 'error' });
      });
  };

  if (isLoading) return <Loading />;

  return deliveryData ? <DeliveryForm formKey={compositeId} data={deliveryData} onApprove={handlerApprove} /> : null;
};

export default Delivery;
