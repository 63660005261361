import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FabMenuBlock = styled(Stack)(() => ({
  position: 'absolute',
  alignItems: 'center',
  width: 'fit-content',
  minWidth: 64,
  zIndex: 1,
}));

export const FabMenuName = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  marginTop: '0px !important',
  textAlign: 'center',
  textShadow: `0px 0px 4px ${theme.palette.background.paper}`,
}));
