import { dequal } from 'dequal';
import { GeoJsonProperties } from 'geojson';
import { useRef, useState, useEffect } from 'react';
import Supercluster from 'supercluster';

import { ClusterValue, UseSuperclusterArgument } from './useSupercluster.types';

const useSupercluster = <
  P extends GeoJsonProperties = Supercluster.AnyProps,
  C extends GeoJsonProperties = Supercluster.AnyProps
>({
  points,
  bounds,
  zoom,
  options,
}: UseSuperclusterArgument<P, C>) => {
  const superclusterRef = useRef<Supercluster<P, C>>();
  const [clusters, setClusters] = useState<Array<ClusterValue<P, C>>>([]);

  useEffect(() => {
    if (!superclusterRef.current) return;

    if (bounds && zoom) {
      setClusters(superclusterRef.current.getClusters(bounds, zoom));
    }
  }, [bounds, zoom]);

  useEffect(() => {
    if (!superclusterRef.current) {
      superclusterRef.current = new Supercluster(options);
    }

    superclusterRef.current.load(points.slice());
    setClusters(superclusterRef.current.getClusters(bounds, zoom));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points, bounds]);

  useEffect(() => {
    if (superclusterRef.current && bounds && zoom) {
      setClusters((prev) => {
        const newClusters = superclusterRef.current!.getClusters(bounds, zoom);

        if (dequal(prev, newClusters)) {
          return prev;
        }

        return newClusters;
      });
    }
  }, [bounds, zoom]);

  return {
    clusters,
    superCluster: superclusterRef.current,
  };
};

export default useSupercluster;
