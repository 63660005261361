import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

export const LevelButton = styled(Button)(() => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  height: 48,
  lineHeight: '48px',
  textAlign: 'center',
  backgroundColor: ThemeColors.blackDark,
  border: '1px solid',
  borderColor: 'currentColor',
  borderRadius: '10px',
  flex: 1,

  '&.specialOffer_levelButton__active': {
    backgroundColor: 'currentColor',
    '& span': {
      color: ThemeColors.blackDark,
    },
  },
}));
