import { useState, FC, ReactNode, createContext, useCallback } from 'react';

import { NotFound, ServerError } from 'src/pages';
import { ErrorCode } from 'src/types/errors';

type ErrorContextType = {
  throwNotFound: VoidFunction;
  throwServerError: VoidFunction;
  throwCustomError: (message?: string) => void;
};

type ErrorContextProviderProps = {
  children: ReactNode;
};

export const ErrorContext = createContext<ErrorContextType>({
  throwNotFound: () => {
    throw new Error('Not implemented');
  },
  throwServerError: () => {
    throw new Error('Not implemented');
  },
  throwCustomError: (message) => {
    throw new Error(message);
  },
});

export const ErrorContextProvider: FC<ErrorContextProviderProps> = ({ children }) => {
  const [errorCode, setCode] = useState<ErrorCode | null>(null);
  const [customErrorMessage, setCustomErrorMessage] = useState('Unknown error');

  const throwNotFound = useCallback(() => {
    setCode(ErrorCode.PageNotFound);
  }, []);

  const throwServerError = useCallback(() => {
    setCode(ErrorCode.ServerError);
  }, []);

  const throwCustomError = useCallback((message?: string) => {
    setCode(ErrorCode.customError);
    if (message) setCustomErrorMessage(message);
  }, []);

  const value = {
    throwNotFound,
    throwServerError,
    throwCustomError,
  };

  if (errorCode === null) return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;

  if (errorCode === ErrorCode.PageNotFound) {
    return <NotFound />;
  }

  if (errorCode === ErrorCode.ServerError) {
    return <ServerError />;
  }

  if (errorCode === ErrorCode.customError) {
    return <p>Custom Error: {customErrorMessage}</p>;
  }

  throw new Error('Unexpected behavior: over text error code');
};
