import { useState, FC, ReactNode, createContext } from 'react';

import { FilterRoleList, FilterStatusList, TTargetStatus, UserRole } from 'src/services';

type TeamFilterContextType = {
  role: UserRole[];
  status: TTargetStatus[];
  changeRole: (newrole: UserRole[]) => void;
  changeStatus: (newstatus: TTargetStatus[]) => void;
};

type TeamFilterContextProviderProps = {
  children: ReactNode;
};

export const TeamFilterContext = createContext<TeamFilterContextType>({
  role: FilterRoleList,
  changeRole: (newrole: UserRole[]) => {
    throw new Error('Not implemented');
  },

  status: FilterStatusList,
  changeStatus: (newstatus: TTargetStatus[]) => {
    throw new Error('Not implemented');
  },
});

export const TeamFilterContextProvider: FC<TeamFilterContextProviderProps> = ({ children }) => {
  const [role, setRole] = useState<UserRole[]>(FilterRoleList);
  const [status, setStatus] = useState<TTargetStatus[]>([]);

  const changeRole = (newrole: UserRole[]): void => {
    setRole(newrole);
  };

  const changeStatus = (newstatus: TTargetStatus[]) => {
    setStatus(newstatus);
  };

  const value = {
    role,
    changeRole,
    status,
    changeStatus,
  };

  return <TeamFilterContext.Provider value={value}>{children}</TeamFilterContext.Provider>;
};
