import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Loading } from 'src/atoms';
import { Paths } from 'src/constants';
import { TOrder, createPackOrder } from 'src/services';

import Help from '../Help';
import ShoppingCart from '../ShoppingCart';

const StudyContract: FC = () => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [help, setHelp] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [order, setOrder] = useState<TOrder>();

  const wrapperId = 30;

  useEffect(() => {
    if (help) return;
    setLoading(true);
    createPackOrder([wrapperId])
      .then(({ data }) => {
        setOrder(data);
      })
      .catch((error) => {
        // if (error.response?.status >= 500) throwServerError();
        enqueueSnackbar(t('errorMsg.contractCreate'), { variant: 'error' });
      })
      .finally(() => setLoading(false));
  }, [enqueueSnackbar, help, t, wrapperId]);

  if (help) {
    return (
      <>
        <Help tkey="study.contract" onNext={() => setHelp(false)} />
      </>
    );
  } else {
    return (
      <>
        {loading && <Loading />}
        {order && (
          <ShoppingCart
            order={order}
            wrapperId={wrapperId}
            onFinish={() => navigate(Paths.study)}
            onPrev={() => setHelp(true)}
          />
        )}
      </>
    );
  }
};

export default StudyContract;
