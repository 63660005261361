import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeFontWeight } from 'src/styles';

export const CostCalc = styled(Stack)(() => ({
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginBottom: 18,
}));

export const CostCalcLabel = styled(Typography)(() => ({
  fontSize: '0.688rem',
  lineHeight: '22px',
  fontWeight: ThemeFontWeight.regular,
}));
