import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

export const Block = styled(Box)(() => ({
  position: 'relative',
  background: ThemeColors.blackDark,
  border: '1px solid',
  borderRadius: '15px',
  minHeight: 130,
  margin: '15px 50px 15px 15px',
}));

export const LevelBlock = styled(Box)(() => ({
  position: 'absolute',
  fontSize: '90px',
  top: -8,
  left: -15,
  width: 60,
  height: 105,
  lineHeight: '105px',
  textAlign: 'center',
  background: ThemeColors.blackDark,
  border: '1px solid',
  borderRadius: '15px',
}));

export const Title = styled(Typography)(() => ({
  padding: '8px 8px 8px 55px',
  textTransform: 'uppercase',
  fontSize: '1.062rem',
  display: 'block',
}));

export const Description = styled(Typography)(() => ({
  padding: '0 50px 0 55px',
  display: 'block',
  color: ThemeColors.gray500,
  minHeight: 55,
}));
