import { Box, IconButton, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LevelAdd } from 'src/assets/images';
import { LEVELS_TO_COLORS, ThemeColors } from 'src/styles';

import Markdown from '../Markdown';

import { ClanActivationProps } from './ClanActivation.types';

const ClanActivation: FC<ClanActivationProps> = ({ level, onClanActivation }) => {
  const { t } = useTranslation('app');

  return (
    <Stack direction="column" alignItems="center" className="ClanActivation_root">
      <IconButton
        onClick={onClanActivation}
        sx={{
          width: 256,
          height: 256,
          border: `1px solid ${ThemeColors.grayLight}`,
          color: LEVELS_TO_COLORS[level - 1],
          mb: '40px',

          svg: {
            fill: 'currentColor',
          },
        }}
      >
        {/*<ClanActivationIcon />*/}
        <Box
          component="img"
          src={LevelAdd}
          sx={{
            width: 256,
            height: 256,
            borderRadius: '50%',
            border: '8px solid',
            borderColor: LEVELS_TO_COLORS[level - 1],
          }}
        />
      </IconButton>
      <Typography
        sx={{ fontSize: '1.75rem', lineHeight: '34px', fontWeight: 600, mb: '16px', textAlign: 'center' }}
        variant="h1"
        component="p"
      >
        {t('clanActivation.title' + level)}
      </Typography>
      <Markdown>{t('clanActivation.textMD' + level)}</Markdown>
    </Stack>
  );
};

export default ClanActivation;
