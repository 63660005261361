import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import { FC } from 'react';

import { ThemeColors } from 'src/styles';

import { CircleGradientLoadingProps } from './CircleGradientLoading.types';

const CircleGradientLoading: FC<CircleGradientLoadingProps> = ({ size, thickness }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: ThemeColors.grayLight,
        }}
        size={size || 22}
        thickness={thickness || 3}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          'svg circle': { stroke: 'url(#gradientCircle)' },
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={size || 22}
        thickness={thickness || 3}
      />
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="gradientCircle" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={ThemeColors.gold1} />
            <stop offset="100%" stopColor={ThemeColors.gold2} />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};

export default CircleGradientLoading;
