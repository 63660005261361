import { FC } from 'react';

import { DrawerStyled, DrawerBoxStyled } from './DrawerBottom.styles';
import { DrawerBottomProps } from './DrawerBottom.types';

const DrawerBottom: FC<DrawerBottomProps> = ({ children, isOpen, onClose, variant, anchor, sx }) => {
  return (
    <DrawerStyled anchor={anchor || 'bottom'} open={isOpen} onClose={onClose} variant={variant} sx={sx}>
      <DrawerBoxStyled className="DrawerBottom_styled" role="presentation">
        {children}
      </DrawerBoxStyled>
    </DrawerStyled>
  );
};

export default DrawerBottom;
