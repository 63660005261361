import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeFontWeight } from 'src/styles';

export const Wrapper = styled(Box)(() => ({
  padding: 2,
  cursor: 'pointer',
}));

export const NFT = styled(Box)(({ theme }) => ({
  //border: '1px solid',
  //borderImageSlice: 1,
  //borderWidth: 1,
  //borderImageSource: ThemeGradients.bg2,
  borderRadius: '6px',
  backgroundColor: theme.palette.background.paper,
}));

export const Image = styled('img')(() => ({
  objectFit: 'cover',
  width: '100%',
  height: 'auto',
  aspectRatio: '1 / 1',
  display: 'block',
}));

export const Info = styled(Box)(({ theme }) => ({
  padding: '6px 8px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '0.812rem',
  lineHeight: '18px',
  fontWeight: ThemeFontWeight.semiBold,
  color: theme.palette.primary.main,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.688rem',
  lineHeight: '13px',
  color: theme.palette.secondary.main,
  textAlign: 'right',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export const Row = styled(Stack)(() => ({
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
}));
