import { Avatar, IconButton } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TeamAncestorItemProps } from './TeamAncestorItem.types';

const TeamAncestorItem: FC<TeamAncestorItemProps> = ({ avatar, name, role, isSelf, onClick }) => {
  const { t } = useTranslation('app');
  return (
    <IconButton
      onClick={onClick}
      sx={{
        p: 0,
        flexBasis: isSelf ? 56 : 44,
        marginBottom: '-12px',
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <Avatar
        alt={name}
        src={avatar}
        sx={{
          width: isSelf ? 56 : 44,
          height: isSelf ? 56 : 44,
          border: '2px solid',
          borderColor: role ? (t(`role.state.color.${role}`) as string) : 'secondary.main',
        }}
      />
    </IconButton>
  );
};

export default TeamAncestorItem;
