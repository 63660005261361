import { ColorOfLevels, ColorOfMiningLevels, ThemeColors } from 'src/styles';
import { Levels } from 'src/types';

export const levels = [
  { level: 1, color: ColorOfLevels.gray },
  { level: 2, color: ColorOfLevels.lightBlue },
  { level: 3, color: ColorOfLevels.blue },
  { level: 4, color: ColorOfLevels.purple },
  { level: 5, color: ColorOfLevels.orange },
];

export const levelsForSwitch = [
  { level: Levels.First, color: ColorOfLevels.gray },
  { level: Levels.Second, color: ColorOfLevels.lightBlue },
  { level: Levels.Third, color: ColorOfLevels.blue },
  { level: Levels.Fourth, color: ColorOfLevels.purple },
  { level: Levels.Fifth, color: ColorOfLevels.orange },
];

export const levelsForFilter = [
  { level: 0, color: ThemeColors.black },
  { level: 1, color: ColorOfLevels.gray },
  { level: 2, color: ColorOfLevels.lightBlue },
  { level: 3, color: ColorOfLevels.blue },
  { level: 4, color: ColorOfLevels.purple },
  { level: 5, color: ColorOfLevels.orange },
];

export const miningLevels = [
  { id: 1, color: ColorOfMiningLevels.gray },
  { id: 2, color: ColorOfMiningLevels.orange },
  { id: 3, color: ColorOfMiningLevels.yellow },
  { id: 4, color: ColorOfMiningLevels.green },
  { id: 5, color: ColorOfMiningLevels.blueLight },
  { id: 6, color: ColorOfMiningLevels.blue },
  { id: 7, color: ColorOfMiningLevels.blueDark },
  { id: 8, color: ColorOfMiningLevels.violet },
  { id: 9, color: ColorOfMiningLevels.magenta },
  { id: 10, color: ColorOfMiningLevels.pink },
];
