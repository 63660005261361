import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

export const TriangleWrapper = styled(Box)(() => ({
  position: 'absolute',
  width: 244,
  height: 209,
  top: -100,
  left: '50%',
  padding: 32,
  transform: 'translateX(-50%)',
}));

export const HistoryDetailsWrapper = styled(Box)(() => ({
  margin: '93px -16px 2px',
}));

export const ReferralTitle = styled(Typography)(() => ({
  textTransform: 'uppercase',
  fontSize: '0.75rem',
  fontWeight: 400,
  color: ThemeColors.gray500,
}));

export const ReferralText = styled(Typography)(() => ({
  fontSize: '0.94rem',
  lineHeight: '20px',
  fontWeight: 600,
  textAlign: 'center',
}));
