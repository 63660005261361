import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Name = styled(Typography)(() => ({
  fontSize: '1rem',
  lineHeight: '21px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  minWidth: '1px',
  display: 'inline-block',
  whiteSpace: 'nowrap',
  flex: 1,
}));
