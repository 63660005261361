import { FC } from 'react';

import { Wraper, Block, Title, Value } from './SquareWidget.styles';
import { SquareWidgetProps } from './SquareWidget.types';

const SquareWidget: FC<SquareWidgetProps> = ({ title, value, children, sx, onClick }) => {
  return (
    <Block sx={sx} onClick={() => (onClick ? onClick() : null)}>
      <Wraper>
        <Title>{title}</Title>
        {children}
        <Value component="div">{value}</Value>
      </Wraper>
    </Block>
  );
};

export default SquareWidget;
