export enum Paths {
  //Guest routes
  home = '/',
  referral = '/r/:referral',
  auth = '/a/:id',
  signIn = '/sign-in',
  telegramWebApp = '/telegram/webapp',
  termsOfUse = '/termsofuse',
  privacyPolicy = '/privacypolicy',
  reg = '/reg',

  // landing pages
  pageAbout = '/about',
  pageMarket = '/market',
  pageMarketCategoryRoot = '/market/list',
  pageMarketCategory = '/market/list/:id',
  pageMarketItem = '/market/item/:compositeId',
  pageJoint = '/joint',
  pageSafety = '/safety',
  pageDelivery = '/delivery',
  pageGoods = '/goods',
  pageFAQ = '/faq',
  pageAny = '/page/:pageId',

  //Private routes
  dashboard = '/dashboard',
  profile = '/profile',
  avatar = '/avatar',
  avatarUser = '/avatar/:id',
  team = '/team',
  teamTree = '/team/tree',
  teamGlobe = '/team/globe',
  teamGlobeAll = '/team/globe/all',
  structure = '/structure',
  structureTree = '/structure-tree',
  marketShoppingCart = '/shopping-cart',
  shoppingCart = '/shopping-cart/:itemId',
  getTarget = '/get-target',
  wallet = '/wallet',
  myNFT = '/wallet/my-nft',
  NFTCard = '/nft/:compositeId',
  NFTBurn = '/nft/burn/:compositeId',
  transaction = '/transaction/:id',
  mining = '/mining',
  miningHistory = '/mining/history',
  profileUser = '/profile/:id',
  notifications = '/notifications',
  notificationsArchive = '/notifications/archive',
  hexglobe = '/hexglobe',
  hexglobeLevel = '/hexglobe/:level',
  hexglobeHex = '/hexglobe/hex/:id',
  specialOffer = '/special-offer',
  specialOfferPack = '/special-offer/:id',
  goal = '/goal',
  goalCard = '/goal/:compositeId',
  feedback = '/feedback/:id',
  goalHistory = '/goal/history/:id',
  goalProduct = '/goal/list/:compositeId',
  goalList = '/goal/list',
  getMoney = '/money/get',
  globeAll = '/globe/all',
  study = '/study',
  studyStep = '/study/:id',
  start = '/start',
  myReferral = '/referral',
  exchange = '/exchange',
}

export const pathsArg = (path: string, args: { [key: string]: any }): string => {
  let pathList = path.split('/:');
  pathList = pathList.map((arg) => (arg[0] !== '/' && args[arg] !== undefined ? args[arg] : arg));
  return pathList.join('/');
};
