import { AxiosResponse } from 'axios';

import agent from 'src/agent';

export const getParams = async (keys?: string[]): Promise<{ [key: string]: any }> => {
  const { data } = await agent.POST('parameters/get', { keys });
  return data;
};

export const setParams = (data: { [key: string]: any }): Promise<AxiosResponse<{}>> => {
  return agent.POST('parameters/set', { data });
};
