import { Stack, Button } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Message, Title } from './ServerError.styles';

const ServerError: FC = () => {
  const { t } = useTranslation('app');

  const onReloadHandler = () => window.location.reload();
  const onHomeHandler = () => (window.location.href = window.location.origin);

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100%' }}>
      <Title>{t('errors.sorry')}</Title>
      <Message>{t('errors.titleServerError')}</Message>
      <Button data-qa="button.reload" variant="outlined" onClick={onReloadHandler} sx={{ width: '250px' }}>
        {t('button.reload')}
      </Button>
      <Button data-qa="button.reload" variant="text" onClick={onHomeHandler} sx={{ width: '250px', mt: '16px' }}>
        {t('button.home')}
      </Button>
    </Stack>
  );
};

export default ServerError;
