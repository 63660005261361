import { Box, Button, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { CircleLoading, DrawerBottom, EmptyData } from 'src/atoms';
import { Paths, pathsArg } from 'src/constants';
import { CameraRec, PageTitle, WindowTitle } from 'src/molecules';
import { videoUpload } from 'src/services';
import { ThemePaddings } from 'src/styles';
import { RouterIdParam } from 'src/types';

const videoRatio = 9 / 16;
const videoWidth = 1024;
const videoHeight = videoWidth / videoRatio;

const Feedback: FC = () => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const { id } = useParams<RouterIdParam>();
  const target_id = parseInt(String(id), 10) || undefined;

  const [isLoading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [error, setError] = useState<string | undefined>();

  if (!target_id) {
    return (
      <EmptyData title={t('feedback.emptyTitle')} data-qa={'feedback.emptyTitle'} text={t('feedback.emptyText')} />
    );
  }

  const onCloseHandler = () => {
    if (error) {
      setError(undefined);
      setOpen(false);
    } else {
      navigate(pathsArg(Paths.goalCard, { compositeId: `target:${target_id}` }), { replace: true });
    }
  };

  const handlerSaveFile = (file: File) => {
    if (target_id) {
      setLoading(true);
      setOpen(true);
      videoUpload(file, target_id)
        .catch((error) => {
          if (error.response?.status === 409) {
            // HTTP_409_CONFLICT товара нет у пользователя
            setError('EMPTY_PRODUCT');
          } else {
            setError('SERVER');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Box sx={{ p: ThemePaddings.page, height: '100%' }}>
      <PageTitle
        title={t('feedback.title')}
        data-qa={'feedback.title'}
        help={{ tkey: 'feedback', isOpen: true }}
        onClose={onCloseHandler}
        // isLoading={isLoading}
      />
      <CameraRec width={videoWidth} height={videoHeight} onFinish={handlerSaveFile} />

      <DrawerBottom isOpen={isOpen} onClose={() => true}>
        <WindowTitle
          data-qa={isLoading ? 'feedback.uploading.title' : 'feedback.upload.title'}
          title={t(isLoading ? 'feedback.uploading.title' : 'feedback.upload.title')}
          onClose={() => !isLoading && onCloseHandler()}
        />
        <Typography
          sx={{ mb: '16px' }}
          data-qa={
            isLoading
              ? 'feedback.uploading.description'
              : error
              ? `feedback.upload.error.${error}.description`
              : 'feedback.upload.description'
          }
        >
          {t(
            isLoading
              ? 'feedback.uploading.description'
              : error
              ? `feedback.upload.error.${error}.description`
              : 'feedback.upload.description'
          )}
        </Typography>
        {!error && <CircleLoading isLoading={isLoading} />}
        {!isLoading && (
          <Button
            sx={{ mt: '16px' }}
            fullWidth
            variant="text"
            onClick={() => onCloseHandler()}
            data-qa={error ? 'feedback.upload.error.button' : 'feedback.upload.button'}
          >
            {t(error ? 'feedback.upload.error.button' : 'feedback.upload.button')}
          </Button>
        )}
      </DrawerBottom>
    </Box>
  );
};

export default Feedback;
