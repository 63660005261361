import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxStack = styled(Stack)(() => ({
  width: '100%',
  flexWrap: 'nowrap',
  flexDirection: 'row',
  justifyContent: 'space-around',
}));

export const Number = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  top: 19,
  color: theme.palette.primary.main,
  zIndex: 2,
  transform: 'translateX(-50%)',
  fontSize: '1.125rem',
  lineHeight: '28px',
  fontWeight: 500,
}));

export const Label = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '16px',
  fontWeight: 400,
  marginTop: 4,
  color: theme.palette.primary.main,
}));
