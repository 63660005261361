import { Button, Skeleton } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

const size = {
  s: 44,
  m: 64,
  l: 100,
  xl: 130,
};

const itemStyle = (theme: Theme) => ({
  border: '0px solid',
  padding: '2px',
  borderRadius: 8,
  borderColor: theme.palette.background.default,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundColor: theme.palette.background.paper,
  width: size.xl,
  minWidth: size.xl,
  height: size.xl,
});

export const CarouselItemButton = styled(Button)(({ theme }) => {
  const iStyle = itemStyle(theme);
  return {
    ...iStyle,
    '&:hover': {
      backgroundColor: 'transparent',
      borderRadius: iStyle['borderRadius'],
    },
    '&.CarouselItem_selected': {
      border: '2px solid',
      padding: '0px',
      backgroundColor: 'transparent',
      borderColor: ThemeColors.blue,
    },
    [theme.breakpoints.down('sm')]: {
      width: size.l,
      minWidth: size.l,
      height: size.l,
    },
  };
});

export const CarouselItemSkeleton = styled(Skeleton)(({ theme }) => {
  const iStyle = itemStyle(theme);
  return {
    ...iStyle,

    display: 'inline-flex',
    position: 'relative',
    boxSizing: 'border-box',
    minWidth: iStyle['width'],
  };
});
