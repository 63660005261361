import { Stack } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';

import { ThemeContext } from 'src/context';
import { Languages } from 'src/molecules';
import { getLanguages, Language } from 'src/services';

import { HelloProps } from './Hello.types';

const Hello: FC<HelloProps> = ({ children }) => {
  const { isShowLang } = useContext(ThemeContext);

  const [languageList, setList] = useState<Language[]>([]);

  useEffect(() => {
    if (!isShowLang) {
      getLanguages()
        .then(({ data }) => setList(data))
        .catch(() => setList([]));
    }
  }, [isShowLang]);

  /*
  const helloAnimationComplete = () => setHelloShowing(false);

  if (isHelloShowing && !isShowLang) {
    return (
      <HelloEnv>
        <HelloAnimation onComplete={helloAnimationComplete} />
      </HelloEnv>
    );
  }*/

  if (!isShowLang) {
    return (
      <Stack sx={{ mx: '16px', height: '100%', overflow: 'hidden' }} justifyContent="end">
        <Languages list={languageList} isShowLangs={true} />
      </Stack>
    );
  }

  /*
  //  пока отключаем страницу ценза
  if (false && !isShowCensor) {
    return (
      <HelloEnv>
        <Censor setCensor={changeShowCensor} />
      </HelloEnv>
    );
  }
  */
  return children;
};

export default Hello;
