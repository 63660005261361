import { FC } from 'react';

import { LevelCircleBox, LevelCircleBadge } from './LevelCircle.styles';
import { LevelCircleProps } from './LevelCircle.types';

const LevelCircle: FC<LevelCircleProps> = (props) => {
  return (
    <LevelCircleBadge
      badgeContent={props.count || 0}
      max={99}
      showZero
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <LevelCircleBox {...props}>{props.children || '∞'}</LevelCircleBox>
    </LevelCircleBadge>
  );
};

export default LevelCircle;
