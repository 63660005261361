import { FC } from 'react';

import { Circle } from './CircleColor.styles';
import { CircleColorProps } from './CircleColor.types';

const CircleColor: FC<CircleColorProps> = ({ color, img, active, disabled, sx, onClick }) => {
  let classes = [];
  if (active) classes.push('CircleColor_active');
  if (disabled) classes.push('CircleColor_disabled');

  const props: any = {};
  if (img) {
    props.src = img;
  }

  return (
    <Circle
      component={img ? 'img' : 'div'}
      onClick={() => onClick && !disabled && onClick()}
      className={classes.join(' ')}
      sx={{ backgroundColor: color, ...sx }}
      {...props}
    />
  );
};

export default CircleColor;
