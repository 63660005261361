import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { LIMIT_NOTICES } from 'src/constants';
import { getNotices, Notice, NoticesResponse, NoticeParams, readAllNotices } from 'src/services';
import { RootState } from 'src/store';
import { parseError } from 'src/utils';

export interface NoticeState {
  isPendingNotice: boolean;
  notices: Notice[];
  count: number;
  noticeError: string | null;
}

const initialState: NoticeState = {
  isPendingNotice: false,
  notices: [],
  count: 0,
  noticeError: null,
};

export const getNoticesThunk = createAsyncThunk<NoticesResponse, NoticeParams | undefined, { rejectValue: string }>(
  'notice/getNotices',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getNotices(params);

      return data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const setReadAllThunk = createAsyncThunk<undefined, undefined, { rejectValue: string }>(
  'notice/setReadAll',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await readAllNotices();

      dispatch(getNoticesThunk({ is_read: false, offset: 0, limit: LIMIT_NOTICES }));
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const noticeSlice = createSlice({
  name: 'notice',
  initialState,
  reducers: {
    resetNotices: (state) => {
      state.notices = [];
      state.count = 0;
      state.isPendingNotice = false;
      state.noticeError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNoticesThunk.pending, (state) => {
        state.isPendingNotice = true;
      })
      .addCase(getNoticesThunk.fulfilled, (state, action) => {
        state.count = action.payload.count;
        state.notices = action.payload.results;
        state.isPendingNotice = false;
      })
      .addCase(getNoticesThunk.rejected, (state, action) => {
        state.notices = [];
        state.count = 0;
        state.isPendingNotice = false;

        if (action.payload) {
          state.noticeError = action.payload;
        }
      });
  },
});

export const { resetNotices } = noticeSlice.actions;

export const selectNoticeState = (state: RootState) => state.notice;

export const noticeReducer = noticeSlice.reducer;
