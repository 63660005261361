import { AxiosResponse } from 'axios';

import agent from 'src/agent';

import { THexagonItemList, THexagonInfo, THexagonGet } from './HexagonService.types';

export const getHexagonList = (level: number): Promise<AxiosResponse<THexagonItemList>> => {
  return agent.GET('hexagon/list', { params: { level } });
};

export const readHexagon = (h3index: string): Promise<AxiosResponse<THexagonInfo>> => {
  return agent.GET(`hexagon/${h3index}/read`, {});
};

export const getHexagon = (h3index: string): Promise<AxiosResponse<THexagonGet>> => {
  return agent.GET(`hexagon/${h3index}/link2nft`, {});
};
