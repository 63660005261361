import { Stack, Button, Box } from '@mui/material';
import { useIsConnectionRestored, useTonAddress, useTonConnectModal, useTonConnectUI } from '@tonconnect/ui-react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TelegramLogo120 } from 'src/assets/images';
import { Address } from 'src/atoms';
import { getImgSrc } from 'src/constants';
import TonWalletProvider from 'src/context/TonWalletProvider';
import { setTonWallet } from 'src/services';
import { ThemeColors } from 'src/styles';

type TTonConnect = { children?: JSX.Element; wallet?: string; onUpdate?: (wallet: string) => void };

const TonWalletConnect: FC<TTonConnect> = ({ children, wallet, onUpdate }) => {
  const { t } = useTranslation('app');

  const userFriendlyAddress = useTonAddress();
  const connectionRestored = useIsConnectionRestored();
  // const rawAddress = useTonAddress(false);
  const { open, state } = useTonConnectModal();
  const [tonConnectUI] = useTonConnectUI();

  const [address, setAddres] = useState(wallet);

  useEffect(() => {
    if (connectionRestored && userFriendlyAddress !== address) {
      setTonWallet(userFriendlyAddress).then(() => {
        if (onUpdate) onUpdate(userFriendlyAddress);
        setAddres(userFriendlyAddress);
      });
    }
  }, [userFriendlyAddress, state, connectionRestored, onUpdate, address]);

  /*
  if (!connectionRestored) {
    return (
      <Stack justifyContent="center" alignContent="center" width="100%">
        <CircleLoading isLoading={true} size={80} />
      </Stack>
    );
  }*/

  return (
    <Stack>
      {userFriendlyAddress ? (
        <>
          {children ? (
            children
          ) : (
            <Address
              color={ThemeColors.green}
              title={t('profile.wallet')}
              data-qa={'profile.wallet'}
              address={userFriendlyAddress}
              onUnlink={() => tonConnectUI.disconnect()}
            />
          )}
        </>
      ) : (
        <>
          <Button fullWidth onClick={open} data-qa="profile.wallet.ton.connect.button">
            <Box
              component="img"
              className="MuiButton_iconimg__left"
              data-qa="profile.wallet.ton.connect.button.icon"
              // src={getImgSrc(t('profile.wallet.ton.connect.button.icon'), 'w32')}
              src={TelegramLogo120}
              alt="TON Logo"
              sx={{ ml: 1, width: '32px', height: '32px' }}
            />
            {t('profile.wallet.ton.connect.button')}
          </Button>
        </>
      )}
    </Stack>
  );
};

const TonWalletConnectProvider: FC<TTonConnect> = ({ children, wallet, onUpdate }) => {
  return (
    <TonWalletProvider>
      <TonWalletConnect wallet={wallet} onUpdate={onUpdate}>
        {children}
      </TonWalletConnect>
    </TonWalletProvider>
  );
};

export default TonWalletConnectProvider;
