import { Avatar, Badge } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

type LevelCircleProps = {
  selected?: boolean;
  color?: string;
};

export const LevelCircleBox = styled(Avatar)<LevelCircleProps>(({ selected, color }) => ({
  border: '3px solid',
  margin: '0px !important',
  borderColor: selected ? ThemeColors.white : color,
  backgroundColor: selected ? color : ThemeColors.white,
  color: ThemeColors.black,
}));

export const LevelCircleBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: ThemeColors.white,
    color: ThemeColors.black,
    bottom: '9px',
    right: '9px',
    border: '1px solid',
    borderColor: ThemeColors.grayLight,
  },
}));
