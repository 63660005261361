import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Circle = styled(Box)(({ theme }) => ({
  width: 42,
  height: 42,
  position: 'relative',
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  borderRadius: '100%',
  boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.9)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '&.CircleColor_active': {
    border: '3px solid',
  },

  '&.CircleColor_disabled': {
    opacity: 0.6,
    cursor: 'default',
    borderColor: theme.palette.background.paper,
  },

  '&:before': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(transparent 20%, white)',
    opacity: 0.2,
    content: '""',
    borderRadius: '100%',
  },

  '&.CircleColor_disabled&:after': {
    position: 'absolute',
    marginTop: '-5px',
    content: '"⊘"',
    fontSize: '40px',
    color: theme.palette.secondary.light,
  },
}));
