import { Box, Stack } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { EmptyTeam, Loading } from 'src/atoms';
import { isMarket } from 'src/constants';
import { TeamFilterContext } from 'src/context';
import { ErrorContext } from 'src/context/ErrorContext';
import { useAppSelector } from 'src/hooks';
import { GoalStatusFilter, InfoTable, MemberOfTeam, TeamSlider } from 'src/molecules';
import { Ancestors, getTeam, TeamList, TTeamMeta, TeamType, FilterRoleList, UserRole } from 'src/services';
import { selectUserMe } from 'src/store/userSlice';
import { ThemePaddings } from 'src/styles';
import { SearchParams } from 'src/utils';

const TeamListComponent: FC = () => {
  const { t } = useTranslation('app');
  const { search: searchParams } = useLocation();
  const userId = new SearchParams(searchParams).getByType('userId', 'number') as number | undefined;
  const userMe = useAppSelector(selectUserMe);
  const { throwServerError } = useContext(ErrorContext);

  const [isLoading, setLoading] = useState(false);
  const [list, setList] = useState<TeamList>();
  const [ancestors, setAncestors] = useState<Ancestors[]>([]);
  const [currentId, setCurrentId] = useState<number>(userMe?.player_id || 0);
  const [meta, setMeta] = useState<TTeamMeta>();

  const { role: roleFilter, changeRole: setRoleFilter } = useContext(TeamFilterContext);

  const onSetCurrentIdHandler = (memberId: number) => {
    setCurrentId(memberId);
  };

  useEffect(() => {
    setLoading(true);
    if (currentId) {
      getTeam(TeamType.List, {
        playerId: currentId,
        userId,
        role: roleFilter,
      })
        .then(({ data }) => {
          setList(data as TeamList);

          setAncestors([
            ...data.ancestors,
            { id: data.id, display_name: data.display_name, photo_uuid: data.photo_uuid, user_id: data.user_id },
          ]);

          setMeta(data.meta);
        })
        .catch((error) => {
          if (error.response.status >= 500) throwServerError();
        })
        .finally(() => setLoading(false));
    }
  }, [currentId, userId, throwServerError, roleFilter]);

  return (
    <>
      {isLoading && <Loading />}
      <Stack sx={{ height: isMarket ? '100%' : 'calc(100% - 164px)', paddingTop: '0px' }}>
        <TeamSlider
          ancestors={ancestors || []}
          currentId={userMe?.player_id || 0}
          onSelectMember={onSetCurrentIdHandler}
        />

        <Box sx={{ p: ThemePaddings.teamBody, overflow: 'hidden', overflowY: 'scroll', flex: 1 }}>
          {!isLoading && list && list.children.length > 0 && (
            <Box>
              {list.children.map((child) => (
                <MemberOfTeam
                  sx={{
                    border: '1px solid',
                    borderColor: t(`role.state.color.${child.role}`) as string,
                  }}
                  key={child.id}
                  member={child}
                  onSelectMember={onSetCurrentIdHandler}
                />
              ))}
            </Box>
          )}

          {!isLoading && list && list.children.length === 0 && <EmptyTeam />}
        </Box>

        <Stack
          sx={{
            flex: 'none',
            width: '100%',
          }}
        >
          <GoalStatusFilter
            sx={{ width: '100%', padding: '10px 16px' }}
            value={roleFilter}
            filter={FilterRoleList}
            tkey="role.state"
            onChange={(role) => setRoleFilter(role as UserRole[])}
          />

          <InfoTable
            sx={{
              borderTop: '1px solid',
              borderColor: 'secondary.main',
              width: '100%',
            }}
            items={[
              {
                title: t('team.meta.team.title'),
                mark: t('team.meta.team.mark'),
                value: meta?.team.team_number,
              },
              {
                title: t('team.meta.private.title'),
                mark: t('team.meta.private.mark'),
                value: meta?.team.personal_team_number,
              },
            ]}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default TeamListComponent;
