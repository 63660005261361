import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';

import { setLikeNotice, Vote } from 'src/services';
import { abbrNum, parseError } from 'src/utils';

import Markdown from '../Markdown';

import {
  NotificationAccordion,
  Icon,
  NotificationHeader,
  TitleStack,
  Title,
  Date,
  ShortText,
  Likes,
  LikeButton,
} from './NotificationItem.styles';
import { NotificationItemProps } from './NotificationItem.types';

const NotificationItem: FC<NotificationItemProps> = ({ id, src, title, time, shortText, fullText, likes, myVote }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [like, setLike] = useState(likes);
  const [vote, setVote] = useState(myVote);
  const [isPending, setPending] = useState(false);

  const onLikeHandler = () => {
    setPending(true);
    setLikeNotice(id)
      .then(({ data }) => {
        setLike(data.likes_count);
        setVote(data.my_vote);
      })
      .catch((error) => {
        enqueueSnackbar(parseError(error), { variant: 'error' });
      })
      .finally(() => setPending(false));
  };

  return (
    <NotificationAccordion sx={!fullText ? { pointerEvents: 'none', cursor: 'default' } : {}}>
      <AccordionSummary
        aria-controls={`${id}-content`}
        id={`${id}-header`}
        sx={{
          '& .MuiAccordionSummary-content': {
            width: '100%',
            overflow: 'hidden',
          },
        }}
      >
        {src && <Icon src={src} alt="" />}
        <NotificationHeader>
          <TitleStack>
            <Title>{title}</Title>
            <Date>{time}</Date>
          </TitleStack>
          <ShortText className="shortText">
            <Markdown>{shortText}</Markdown>
          </ShortText>
        </NotificationHeader>
      </AccordionSummary>
      {fullText && (
        <AccordionDetails>
          <Markdown>{fullText}</Markdown>
          {like !== undefined && (
            <Likes>
              <LikeButton onClick={onLikeHandler} disabled={isPending}>
                {vote === Vote.Like ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </LikeButton>
              {abbrNum(like)}
            </Likes>
          )}
        </AccordionDetails>
      )}
    </NotificationAccordion>
  );
};

export default NotificationItem;
