import { Typography, Stack, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TrustWalletLogoIcon32 } from 'src/assets/images';

const TrustWalletInstall = () => {
  const { t } = useTranslation('app');

  return (
    <Stack>
      <Typography sx={{ mb: 2 }}>{t('signIn.installTrustWalletMsg')}</Typography>
      <Button variant="contained" href="https://trustwallet.com/" target="_blank" rel="noopener noreferrer">
        <Box
          component="img"
          className="MuiButton_iconimg__left"
          src={TrustWalletLogoIcon32}
          alt="TrustWallet Logo"
          sx={{ ml: 1 }}
        />
        {t('button.installTrustWallet')}
      </Button>
    </Stack>
  );
};

export default TrustWalletInstall;
