import { Stack } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BPrice } from 'src/atoms';
import { HelpButtonDrawer } from 'src/organisms';
import { TOrderFee, TOrderPrice, priceSum } from 'src/services';

import { CalcBox, CalcItemBox, Title, Total, TotalLabel } from './ShoppingCartFees.styles';
import { ShoppingCartFeesProps } from './ShoppingCartFees.types';

const showFeeHelp = (feeType: string) => {
  switch (feeType) {
    case 'TENANT':
    case 'SERVICE':
      return true;
    default:
      return false;
  }
};

const ShoppingCartResults: FC<ShoppingCartFeesProps> = ({ items, showTotal: showTotalProp, showFeeOnly }) => {
  const { t } = useTranslation('app');

  const [feesSum, setFeesSum] = useState<TOrderFee[]>();
  const [totalSum, setTotalSum] = useState<TOrderPrice>();

  const showTotal = showTotalProp === undefined ? true : showTotalProp;

  useEffect(() => {
    const feeByType: { [key: string]: TOrderFee } = {};
    let totalPrice: TOrderPrice = {
      cost: 0,
      bcost: 0,
      currency: '',
      bcurrency: '',
    };

    let totalSum: TOrderPrice = {
      cost: 0,
      bcost: 0,
      currency: '',
      bcurrency: '',
    };

    items.forEach((item, iidx) => {
      item.fees.forEach((fee, fidx) => {
        if (!feeByType[fee.fee_type]) {
          feeByType[fee.fee_type] = {
            id: iidx + fidx,
            fee_type: fee.fee_type,
            fee_amount: {
              cost: 0,
              bcost: 0,
              currency: '',
              bcurrency: '',
            } as TOrderPrice,
          };
        }

        feeByType[fee.fee_type].fee_amount = priceSum(
          feeByType[fee.fee_type].fee_amount,
          fee.fee_amount,
          item.count ? 1 : 0
        );
      });
      totalPrice = priceSum(totalPrice, item.price, item.count === undefined ? 1 : item.count);
    });

    const allMoney = showFeeOnly
      ? Object.values(feeByType)
      : [
          {
            id: 999666,
            fee_type: 'TOTAL_PRICE',
            fee_amount: totalPrice,
          },
          ...Object.values(feeByType),
        ];

    // cложим все комиссии и стоимость
    allMoney.forEach((item) => {
      totalSum = priceSum(totalSum, item.fee_amount, 1);
    });

    setFeesSum(allMoney);
    setTotalSum(totalSum);
  }, [items, showFeeOnly]);

  return (
    <>
      <CalcBox>
        {feesSum?.map((item, idx) => (
          <CalcItemBox
            key={`shoppingCart.fee.${item.id}`}
            className={feesSum.length - 1 === idx ? 'CalcItemBox_last' : ''}
          >
            <Title>{t(`shoppingCart.fee.${item.fee_type}`)}</Title>
            <Stack direction="row" spacing="10px" justifyContent="space-between" alignContent="center">
              <BPrice sx={{ fontSize: '1.062rem', mb: '6px' }} price={item.fee_amount} />
              {showFeeHelp(item.fee_type) && (
                <HelpButtonDrawer
                  sx={{ marginTop: '-8px!important', p: 0 }}
                  tkey={`shoppingCart.fee.${item.fee_type}`}
                />
              )}
            </Stack>
          </CalcItemBox>
        ))}
      </CalcBox>

      {showTotal && totalSum && (
        <Total>
          <TotalLabel>{t('shoppingCart.totalPayable')}</TotalLabel>
          <BPrice sx={{ fontSize: '1.062rem' }} price={totalSum} />
        </Total>
      )}
    </>
  );
};

export default ShoppingCartResults;
