import { Box, Button, Divider } from '@mui/material';
import Icon from '@mui/material/Icon';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactPlayerProps } from 'react-player';
import { useNavigate } from 'react-router-dom';
import rehypeAttrs from 'rehype-attr';
import rehypeRaw from 'rehype-raw';
import stringify from 'rehype-stringify';
import remarkGfm from 'remark-gfm';
import remarkImages from 'remark-images';
import remarkParse from 'remark-parse';

import { NoPhoto } from 'src/assets/images';
import { getImgSrc, getVideoSrc } from 'src/constants';
import { useAppSelector } from 'src/hooks';
import { ScrollLanding, VideoPlayer } from 'src/molecules';
import { selectUserMe } from 'src/store/userSlice';

import HomeBlock from '../HomeBlock';
import UserLink from '../UserLink';

import { MarkdownStyled } from './Markdown.styles';
import { MarkdownProps } from './Markdown.types';

const getImgFromSrc = (src: string, imgType?: 'img' | 'video') => {
  let imgSrc: string = src;
  let decodedSrc = '';
  try {
    decodedSrc = decodeURI(String(src) || '');
  } catch (e) {
    decodedSrc = '';
  }
  const matchSrcUUIDParam = /^uuid-(.*)\[(.*)\]/.exec(decodedSrc);
  const matchSrcUUIDParam2 = /^uuid-(.*)/.exec(decodedSrc);
  if (matchSrcUUIDParam || matchSrcUUIDParam2) {
    const mParams = matchSrcUUIDParam || matchSrcUUIDParam2;
    if (mParams) {
      const imgUuid = mParams[1];
      const imgCorp: string = mParams[2] ? mParams[2] : 's600';
      imgSrc = (imgUuid && (imgType === 'video' ? getVideoSrc(imgUuid) : getImgSrc(imgUuid, imgCorp))) || NoPhoto;
    }
  } else if (imgSrc) {
    const newUrl = new URL(imgSrc);
    newUrl.searchParams.set('resize_params', 's600');
    imgSrc = newUrl.toString();
  }
  return imgSrc;
};

const Markdown: FC<MarkdownProps> = ({ children, ...props }) => {
  const userMe = useAppSelector(selectUserMe);
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  const user = {
    display_name: t('user.unknown'),
    photo_uuid: '',
    user_id: 0,
    ...userMe,
  };

  return (
    <MarkdownStyled
      remarkPlugins={[remarkParse, remarkImages, remarkGfm]}
      rehypePlugins={[rehypeRaw, [rehypeAttrs, { properties: 'attr' }], stringify]}
      className="Markdown_block"
      components={{
        hr(props) {
          // const { node, ...rest } = props;
          return <Divider />;
        },
        em(props) {
          const { children, className, node, ...rest } = props;
          let elType = '';
          let elAttr = '';

          const matchUserparam = /user-(\w+)/.exec(String(children) || '');
          if (matchUserparam) {
            elType = 'user';
            elAttr = matchUserparam[1];
          }

          switch (elType) {
            case 'user':
              switch (elAttr) {
                case 'name':
                  return <b className={className}>{user.display_name}</b>;
                case 'link':
                  return (
                    <UserLink user_id={user.user_id} display_name={user.display_name} photo_uuid={user.photo_uuid} />
                  );
                default:
                  return <span className={className}>{elAttr}</span>;
              }
            default:
              return (
                <em {...rest} className={className}>
                  {children}
                </em>
              );
          }
        },
        img(props) {
          const { children, className, src, alt, node, ...rest } = props;
          let imgSrc = src;
          let decodedSrc = '';
          try {
            decodedSrc = decodeURI(String(src) || '');
          } catch (e) {
            decodedSrc = '';
          }
          const matchSrcUUIDParam = /^uuid-(.*)\[(.*)\]/.exec(decodedSrc);
          const matchSrcUUIDParam2 = /^uuid-(.*)/.exec(decodedSrc);
          if (matchSrcUUIDParam || matchSrcUUIDParam2) {
            const mParams = matchSrcUUIDParam || matchSrcUUIDParam2;
            if (mParams) {
              const imgUuid = mParams[1] === 'user' ? user.photo_uuid : mParams[1];
              const imgCorp = mParams[2] ? mParams[2] : 's600';
              imgSrc = imgUuid ? getImgSrc(imgUuid, imgCorp) : NoPhoto;
            }
          } else if (imgSrc) {
            const newUrl = new URL(imgSrc);
            newUrl.searchParams.set('resize_params', 's600');
            imgSrc = newUrl.toString();
          }

          return (
            <img {...rest} src={imgSrc} alt={alt} className={className}>
              {children}
            </img>
          );
        },
        video(props) {
          const { src, node } = props;
          const ratioVideo: string = String(node?.properties['dataRatio'] || '');
          const videoConfigVariant: string = String(node?.properties['dataVariant'] || '');
          let videoSrc = src;
          let decodedSrc = '';
          try {
            decodedSrc = decodeURI(String(src) || '');
          } catch (e) {
            decodedSrc = '';
          }
          const matchSrcUUIDParam = /^uuid-(.*)\[(.*)\]/.exec(decodedSrc);
          const matchSrcUUIDParam2 = /^uuid-(.*)/.exec(decodedSrc);
          if (matchSrcUUIDParam || matchSrcUUIDParam2) {
            const mParams = matchSrcUUIDParam || matchSrcUUIDParam2;
            if (mParams) {
              videoSrc = mParams[1] ? getVideoSrc(mParams[1]) : videoSrc;
            }
          }
          if (!videoSrc) return null;

          let videoConf: ReactPlayerProps;

          switch (videoConfigVariant) {
            case 'controled':
              videoConf = {
                controls: true,
                playing: false,
                muted: false,
                loop: false,
              };
              break;

            default:
              videoConf = {
                controls: false,
                playing: true,
                muted: true,
                value: 0,
                loop: true,
              };
              break;
          }

          return (
            <VideoPlayer
              sx={{
                position: 'relative',
                top: 0,
                left: 0,
                right: 0,
                '& video': {
                  background: 'none',
                  border: 'none',
                  borderRadius: 0,
                },
              }}
              ratio={ratioVideo ? parseFloat(ratioVideo) : 0.810506566604128}
              url={videoSrc}
              config={videoConf}
            />
          );
        },
        button(props) {
          const { children, className, node } = props;
          const varProp = String(node?.properties['variant'] || '');
          const variantBotton = varProp === 'outlined' ? 'outlined' : varProp === 'contained' ? 'contained' : 'text';
          const navLink: string = String(node?.properties['navigate'] || '');
          const fullWidth = node?.properties.hasOwnProperty('fullwidth');
          const outLink: string = String(node?.properties['href'] || '');

          return (
            <Button
              fullWidth={fullWidth}
              variant={variantBotton}
              sx={{ pointerEvents: 'all' }}
              onClick={() => {
                if (navLink) {
                  navigate(navLink);
                } else if (outLink) {
                  window.open(outLink, '_blank');
                }
              }}
              className={className}
            >
              {t(String(children ? children : ''))}
            </Button>
          );
        },
        a(props) {
          const { children, className, node, ...rest } = props;
          let navLink: string = '';
          let outLink: string = String(node?.properties['href'] || '');
          const matchHrev = /^navigate-(.*)/.exec(outLink);
          if (matchHrev) {
            navLink = matchHrev[1];
            outLink = '';
          }

          if (navLink) {
            return (
              <Box
                component="span"
                onClick={(event: any) => {
                  event.stopPropagation();
                  navigate(navLink);
                  return false;
                }}
                sx={{ cursor: 'pointer', textDecoration: 'underline', pointerEvents: 'all' }}
                role="link"
                className={className}
              >
                {children}
              </Box>
            );
          }

          return (
            <a {...rest} rel="external" className={className}>
              {children}
            </a>
          );
        },
        span(props) {
          const { children, className, node, ...rest } = props;
          if (className?.startsWith('material-icons')) {
            return <Icon className={className}>{children}</Icon>;
          }
          return (
            <span {...rest} className={className}>
              {children}
            </span>
          );
        },
        div(props) {
          const { children, className, node, ...rest } = props;
          const componentType: string = String(node?.properties['component'] || '');
          const componentNavigate: string = String(node?.properties['navigate'] || '');
          switch (componentType) {
            case 'block':
            case 'blockImg':
              const bgImg: string = String(node?.properties['dataBackground'] || '');
              const position: string = String(node?.properties['dataPosition'] || '');
              const contrast: string = String(node?.properties['dataContrast'] || '');
              return (
                <HomeBlock
                  onClick={() => {
                    if (componentNavigate) navigate(componentNavigate);
                    return false;
                  }}
                  className={className}
                  bgImg={getImgFromSrc(bgImg, 'img')}
                  position={position || 'top'}
                  contrast={contrast === 'true'}
                >
                  {children}
                </HomeBlock>
              );
            case 'blockVideo':
              const bgVideo: string = String(node?.properties['dataBackground'] || '');
              const openVideo: string = String(node?.properties['dataVideo'] || '');
              const positionText: string = String(node?.properties['dataPosition'] || '');
              const ratioVideo: string = String(node?.properties['dataRatio'] || '');
              const contrastVideo: string = String(node?.properties['dataContrast'] || '');
              return (
                <HomeBlock
                  className={className}
                  onClick={() => {
                    if (componentNavigate) navigate(componentNavigate);
                    return false;
                  }}
                  ratio={ratioVideo ? parseFloat(ratioVideo) : undefined}
                  bgImg={getImgFromSrc(bgVideo, 'video')}
                  openImg={getImgFromSrc(openVideo, 'video')}
                  bgType="video"
                  position={positionText || 'top'}
                  contrast={contrastVideo === 'true'}
                >
                  {children}
                </HomeBlock>
              );
            case 'blockScroll':
              return <ScrollLanding>{children as JSX.Element[]}</ScrollLanding>;
          }
          return (
            <div {...rest} className={className}>
              {children}
            </div>
          );
        },
      }}
      {...props}
    >
      {children}
    </MarkdownStyled>
  );
};

export default Markdown;
