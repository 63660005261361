import { Stack, Button } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Paths } from 'src/constants';

import { Message, Oops } from './NotFound.styles';

const NotFound: FC = () => {
  const { t } = useTranslation('app');

  const onRedirectHandler = () => (window.location.href = Paths.home);

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100%' }}>
      <Oops>{t('errors.oopsTitle')}</Oops>
      <Message>{t('errors.title404')}</Message>
      <Button variant="outlined" onClick={onRedirectHandler} sx={{ width: '250px' }}>
        {t('button.homepage')}
      </Button>
    </Stack>
  );
};

export default NotFound;
