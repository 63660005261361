import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { WalletImage } from 'src/assets/images';
import { Paths } from 'src/constants';
import { SquareWidget } from 'src/molecules';
import { formatCurrency } from 'src/utils';

import { WalletBalance } from './WalletWidget.styles';
import { WalletWidgetProps } from './WalletWidget.types';

const WalletWidget: FC<WalletWidgetProps> = ({ publicAddress, balance }) => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  // const { is_demo } = useAppSelector(selectDemoData);

  // const [address, setAddress] = useState(publicAddress || '0x98nsBJwk');

  const onWalletHandler = () => {
    navigate(Paths.wallet);
  };

  /*
  useEffect(() => {
    if (is_demo) return;

    getAddressAndBalance()
      .then(({ address }) => {
        setAddress(address);
      })
      .catch((error) => {
        logger.error(error);
        throw new Error('Fatal error');
      });
  }, [is_demo]);
  */

  return (
    <SquareWidget
      onClick={onWalletHandler}
      title={t('dashboard.wallet')}
      data-qa={'dashboard.wallet'}
      value={
        <WalletBalance>
          {formatCurrency(balance?.bcost || 0)}
          <span>{balance?.bcurrency || t('wallet.gameCard.currancy')}</span>
        </WalletBalance>
      }
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '10px',
          backgroundImage: `url(${WalletImage})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        {' '}
      </Box>
    </SquareWidget>
  );
};

export default WalletWidget;
