import CloseIcon from '@mui/icons-material/Close';
import { Button, Divider, IconButton, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerBottom, Markdown } from 'src/atoms';

import { Title } from './AutomatOffConfirmation.styles';
import { AutomatOffConfirmationProps } from './AutomatOffConfirmation.types';

const AutomatOffConfirmation: FC<AutomatOffConfirmationProps> = ({ isOpen, onClose, onSwitchOff }) => {
  const { t } = useTranslation('app');

  return (
    <DrawerBottom isOpen={isOpen} onClose={onClose}>
      <IconButton sx={{ position: 'absolute', right: 14, top: 14 }} onClick={onClose}>
        <CloseIcon />
      </IconButton>

      <Title>{t('automatOff.title')}</Title>

      <Markdown>{t('automatOff.textMD')}</Markdown>

      <Divider variant="fullWidth" sx={{ my: '25px' }} />
      <Stack direction="row" justifyContent="space-between" sx={{ zIndex: 0, position: 'relative' }}>
        <Button variant="text" onClick={onSwitchOff} sx={{ width: '48%' }}>
          {t('button.switchOff')}
        </Button>
        <Button variant="contained" onClick={onClose} sx={{ width: '48%' }}>
          {t('button.cancel')}
        </Button>
      </Stack>
    </DrawerBottom>
  );
};

export default AutomatOffConfirmation;
