import { Stack, LinearProgress } from '@mui/material';
import { FC, useState, useContext, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Paths } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { PageTitle } from 'src/molecules';
import { AvatarCreater, AvatarEditor, AvatarView } from 'src/organisms';
import { viewMode } from 'src/organisms/AvatarView/AvatarView.types';
import { getAvatarInfo } from 'src/services';
import { AvatarInfo } from 'src/services/AvatarService/AvatarService.types';
import { ThemePaddings } from 'src/styles';

enum mode {
  view = 'view',
  create = 'create',
  edit = 'edit',
}

const Avatar: FC = () => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);

  const navigate = useNavigate();
  const onCloseHandler = () => navigate(Paths.dashboard);
  const [editMode, setEditMode] = useState<mode>();
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState<AvatarInfo>();

  const readData = useCallback(() => {
    setLoading(true);
    getAvatarInfo()
      .then(({ data }) => {
        setInfo(data);
        if (data.sex) {
          if (data.snapshot && data.snapshot.preview) {
            setEditMode(mode.view);
          } else {
            setEditMode(mode.edit);
          }
        } else {
          setEditMode(mode.create);
        }
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
        if (error.response?.status === 404) {
          // аватар не найден, переходим в режим создания
          setEditMode(mode.create);
        }
      })
      .finally(() => setLoading(false));
  }, [throwServerError]);

  useEffect(() => {
    readData();
  }, [readData]);

  const handleCreateFinished = (gender?: string) => {
    // Делаем переначитку данных и меняем вид на просмотр аватара
    readData();
  };

  const handleViewChange = (newMode: viewMode, meta?: unknown) => {
    switch (newMode) {
      case viewMode.edit:
        setEditMode(mode.edit);
        break;
      case viewMode.create:
        setEditMode(mode.create);
        break;
    }
  };

  const handlerAvaterEditorClose = (img?: string) => {
    setEditMode(mode.view);
    readData();
  };

  if (loading) {
    return <LinearProgress sx={{ position: 'absolute', marginLeft: 0, left: 0, top: 0, width: '100%', zIndex: 10 }} />;
  }

  if (editMode === mode.edit) {
    return (
      <Stack sx={{ height: 'inherit' }} direction="column" justifyContent="stretch">
        <AvatarEditor onClose={handlerAvaterEditorClose} />
      </Stack>
    );
  }

  return (
    <Stack sx={{ p: ThemePaddings.page, height: 'inherit' }} direction="column" justifyContent="stretch">
      <PageTitle title={t('avatar.title')} onClose={onCloseHandler} />
      {editMode === mode.create && <AvatarCreater onFinish={handleCreateFinished} />}
      {editMode === mode.view && info && <AvatarView data={info} onChange={handleViewChange} />}
    </Stack>
  );
};

export default Avatar;
