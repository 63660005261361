import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Loading, StudyStepItem, Markdown } from 'src/atoms';
import { Paths, pathsArg } from 'src/constants';
import { getUserProgress, TUserStudyProgress } from 'src/services';
import { parseError } from 'src/utils';

import { StudyListProps } from './StudyList.types';

const StudyList: FC<StudyListProps> = ({ items }) => {
  const { t } = useTranslation('app');
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(items && items.length ? false : true);
  const [steps, setSteps] = useState<TUserStudyProgress[]>(items || []);

  useEffect(() => {
    getUserProgress()
      .then(({ data }) => {
        setSteps(data);
      })
      .catch((error) => {
        enqueueSnackbar(parseError(error), { variant: 'error' });
      })
      .finally(() => setLoading(false));
  }, [enqueueSnackbar]);

  return (
    <>
      <Markdown>{t('study.textMD')}</Markdown>
      {isLoading && <Loading />}
      <Stack direction="column" spacing={1} sx={{ pb: '16px' }}>
        {steps.map((step) => (
          <StudyStepItem
            key={`StudyStep${step.id}`}
            onClick={(id) => navigate(pathsArg(Paths.studyStep, { id }))}
            id={step.name}
            title={t(`study.${step.name}.item.title`)}
            shortText={t(`study.${step.name}.item.text`)}
            img={t(`study.${step.name}.item.img`)}
            state={step.is_success ? 'complete' : 'progress'}
            disabled={step.is_success === false}
          />
        ))}
      </Stack>
    </>
  );
};

export default StudyList;
