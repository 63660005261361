import { Box, Stack } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { FixedSizeList } from 'react-window';

import { UserLink } from 'src/atoms';
import { useWindowSize } from 'src/hooks';
import { TeamAncestors, TeamTreeChild } from 'src/molecules';

import { TreeProps } from './Tree.types';

const Tree: FC<TreeProps> = ({ currentId, ancestors, childrenData, onNodeClick, isLoading }) => {
  const { width: windowWidth } = useWindowSize();

  const [leftCount, setLeftCount] = useState(0);
  const [rightCount, setRightCount] = useState(0);
  const [visibleBorder, setVisibleBorder] = useState([0, 0]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const countCirclesWithNumber = childrenData.reduce(
    (res, current) => Math.max(res, current.personal_team_number.length),
    0
  );
  const heightFixedSizeList = 50 * countCirclesWithNumber + 60;

  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (!wrapperRef.current) return;

    const { width } = wrapperRef.current.getBoundingClientRect();
    setWidth(width);
  }, [windowWidth]);

  const currentUser = ancestors && ancestors.length ? ancestors[ancestors.length - 1] : undefined;

  return (
    <Box
      ref={wrapperRef}
      sx={{
        position: 'relative',
        width: '100%',

        /*
        '&:before': {
          content: '""',
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '14px',
          height: heightFixedSizeList,
          zIndex: 2,
        },
        
        */
        '&:after': {
          content: '""',
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '14px',
          height: heightFixedSizeList,
          zIndex: 2,
        },

        '.virtualList': {
          position: 'relative',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          padding: '16px 44px 0px 44px',

          /*
          '& .TreeNode_center': {
            transition: '.35s',
            transform: 'scale(1.4)',
          },

          '& .TreeNode_level_1': {
            transform: 'scale(1.3)',
          },

          '& .TreeNode_level_2': {
            transform: 'scale(1.2)',
          },

          '& .TreeNode_level_3': {
            transform: 'scale(1.1)',
          },
          
          '& .TreeNode_first': {
            // paddingLeft: '44px',
          },*/

          '&::-webkit-scrollbar': {
            display: 'none',
          },

          '> div': {
            position: 'relative',
            margin: '0 auto',
          },
        },
      }}
    >
      {currentUser && (
        <UserLink
          sx={{
            position: 'absolute',
            top: 0,
            left: 16,
            zIndex: 0,
            backgroundColor: 'background.paper',
            borderRadius: '10px',
            padding: '10px',
            maxWidth: '40%',
          }}
          showName={true}
          user_id={currentUser.user_id}
          photo_uuid={currentUser.photo_uuid}
          display_name={currentUser.display_name}
          role={currentUser.role}
        />
      )}
      <TeamAncestors ancestors={ancestors} currentId={currentId} onNodeClick={onNodeClick} />

      {childrenData.length > 0 && (
        <Stack direction="row" sx={{ overflow: 'hidden', position: 'relative' }}>
          <Box
            sx={{
              flex: 'none',
              width: '100%',
              height: '54px',
              lineHeight: '50px',
              backgroundColor: 'background.paper',
              position: 'absolute',
              top: '10px',
              left: '0px',
              right: '0px',
              zIndex: -1,
            }}
          ></Box>
          {leftCount ? (
            <Box
              sx={{
                flex: 'none',
                width: '40px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                height: '44px',
                lineHeight: '42px',
                border: '1px solid',
                borderLeft: 'none',
                borderColor: 'primary.main',
                backgroundColor: 'background.paper',
                borderRadius: '0px 30px 30px 0px',
                position: 'absolute',
                padding: '0px',
                top: '16px',
                left: '0px',
                zIndex: 1,
              }}
            >
              {leftCount}
            </Box>
          ) : null}
          <Box
            sx={{
              flex: 1,
              position: 'relative',
              width: '100%',
              maskImage: 'linear-gradient(to right, #0000, #000 10%, #000 90%, #0000)',
            }}
          >
            <FixedSizeList
              itemCount={childrenData.length}
              itemSize={60}
              width={width}
              height={heightFixedSizeList}
              className="virtualList"
              layout="horizontal"
              onItemsRendered={(props) => {
                setLeftCount(props.visibleStartIndex);
                setRightCount(childrenData.length - 1 - props.visibleStopIndex);
                setVisibleBorder([props.visibleStartIndex, props.visibleStopIndex]);
              }}
            >
              {({ style, index }) => {
                const child = childrenData[index];
                const viewPortSize = visibleBorder[1] - visibleBorder[0];
                const halfWidth = Math.round(viewPortSize / 2);
                const center = visibleBorder[0] + halfWidth;
                const offsetIdx = Math.abs(center - index);

                let cname = '';

                if (index === center) {
                  cname = 'TreeNode_center';
                } else if (offsetIdx < 3) {
                  cname = 'TreeNode_level_' + offsetIdx;
                }

                return (
                  <div style={style} className={index === 0 ? 'TreeNode_first' : ''}>
                    {!isLoading && <TeamTreeChild className={cname} child={child} onNodeClick={onNodeClick} />}
                  </div>
                );
              }}
            </FixedSizeList>
          </Box>
          {rightCount ? (
            <Box
              sx={{
                flex: 'none',
                width: '40px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                height: '42px',
                lineHeight: '40px',
                border: '1px solid',
                borderRight: 'none',
                borderColor: 'primary.main',
                backgroundColor: 'background.paper',
                borderRadius: '30px 0px 0px 30px',
                position: 'absolute',
                top: '16px',
                right: '0px',
                zIndex: 1,
              }}
            >
              {rightCount}
            </Box>
          ) : null}
        </Stack>
      )}
    </Box>
  );
};

export default Tree;
