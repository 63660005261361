import { Stack } from '@mui/material';
import { FC, useRef } from 'react';

import { AvatarLink } from 'src/atoms';
import { useResize } from 'src/hooks';

import { Name } from './UserLink.styles';
import { UserLinkProps } from './UserLink.types';

const UserLink: FC<UserLinkProps> = ({
  user_id,
  display_name,
  photo_uuid,
  size: sizeProp,
  role,
  showName: showNameProp,
  sx,
  onClick,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useResize(ref);

  const size = sizeProp || 32;
  const showName = showNameProp ? true : size * 2 >= width;

  return (
    <Stack ref={ref} direction="row" alignItems="center" sx={sx} spacing={1}>
      <AvatarLink
        sx={{ flex: 'none' }}
        user_id={user_id}
        display_name={display_name}
        photo_uuid={photo_uuid}
        role={role}
        size={size}
      />
      {showName && display_name ? <Name>{display_name}</Name> : null}
    </Stack>
  );
};

export default UserLink;
