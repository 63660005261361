import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FONTS, ThemeColors } from 'src/styles';

export const Levels = styled(Stack)(() => ({
  justifyContent: 'flex-start',
  paddingLeft: 2,
}));

export const Level = styled(Box)(() => ({
  position: 'relative',
  width: 22,
  height: 20,
  marginLeft: -1,

  svg: {
    fill: 'currentColor',
  },
}));

export const Number = styled(Box)(() => ({
  position: 'absolute',
  top: 2,
  left: 1,
  width: 16,
  height: 16,
  borderRadius: 16 / 2,
  fontFamily: FONTS,
  fontSize: '0.75rem',
  lineHeight: '16px',
  fontWeight: 500,
  textAlign: 'center',
  color: ThemeColors.gray,
  paddingLeft: 1,
}));
