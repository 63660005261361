import { Slider, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

import { FONTS, ThemeColors } from 'src/styles';

export const InputsStack = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 24,
}));

export const Input = styled(TextField)(() => ({
  maxWidth: '48%',
  backgroundColor: ThemeColors.white,
  borderRadius: 15,

  '& .MuiOutlinedInput-input': {
    textAlign: 'center',
  },
}));

export const SliderBox = styled(Box)(() => ({
  padding: '0 14px',
}));

export const AmountSlider = styled(Slider)(() => ({
  '& .MuiSlider-mark': {
    visibility: 'hidden',
  },

  '& .MuiSlider-thumb': {
    height: 28,
    width: 28,
  },

  '& .MuiSlider-markLabel': {
    fontFamily: FONTS,
    fontSize: '1.062rem',
    fontStyle: 'normal',
  },
}));
