import { Button, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Block = styled(Button)(({ theme }) => ({
  position: 'relative',
  padding: 0,
  height: 0,
  width: '100%',
  paddingBottom: '100%',
  border: 'none',
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
}));

export const Wraper = styled(Stack)(() => ({
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  padding: 12,
  height: '100%',
  width: '100%',
}));

export const Title = styled(Typography)(() => ({
  fontSize: '1.125rem',
  lineHeight: '18px',
  minHeight: '24px',
  fontWeight: 600,
  textTransform: 'uppercase',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  flex: 'none',
}));

export const Value = styled(Typography)(() => ({
  fontSize: '1.062rem',
  lineHeight: '18px',
  minHeight: '24px',
  fontWeight: 600,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  flex: 'none',
})) as typeof Typography;
