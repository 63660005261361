import { TFunction } from 'react-i18next';

import { Paths } from 'src/constants';
import { SwitchLinks } from 'src/types';

export const getSwitchLinksForWallet = (t: TFunction<'app', undefined>): SwitchLinks[] => {
  return [
    { id: 1, title: t('app:wallet.finance'), path: Paths.wallet },
    { id: 2, title: t('app:wallet.myNFT'), path: Paths.myNFT },
  ];
};
