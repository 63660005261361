import TuneIcon from '@mui/icons-material/Tune';
import { cellToLatLng } from 'h3-js';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CircleLoading, UserLink, DrawerBottom } from 'src/atoms';
import { Paths, pathsArg } from 'src/constants';
import { readHexagon, THexagonInfo, getHexagon } from 'src/services';
import { parseError } from 'src/utils';

import { Block, Table, Row, Label, Value, ActionBlock, ActionButton } from './HexagonView.styles';
import { HexagonViewProps } from './HexagonView.types';

const Card: FC<HexagonViewProps> = ({ h3index, sx }) => {
  const { t } = useTranslation('app');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [info, setInfo] = useState<THexagonInfo>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [edit, setEdit] = useState<boolean>(false);

  const loadInfo = useCallback(() => {
    setIsLoading(true);
    readHexagon(h3index)
      .then(({ data }) => {
        setInfo(data);
      })
      .catch((error) => {
        enqueueSnackbar(parseError(error), { variant: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [enqueueSnackbar, h3index]);

  const handlerActionGet = () => {
    setIsLoading(true);
    getHexagon(h3index)
      .then(({ data }) => {
        if (data.err) {
          enqueueSnackbar(data.err, { variant: 'error' });
        }
      })
      .catch((error) => {
        enqueueSnackbar(parseError(error), { variant: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
        loadInfo();
      });
  };

  const handlerActionEdit = () => {
    setEdit(true);
  };

  const handlerActionBuy = (id: number) => {
    navigate(pathsArg(Paths.specialOfferPack, { id }));
  };

  useEffect(() => {
    loadInfo();
  }, [loadInfo]);

  if (isLoading) {
    return <CircleLoading isLoading={isLoading} />;
  }

  return (
    <Block sx={sx}>
      <Table>
        <Row spacing={1}>
          <Label>{t('hexagonView.index')}</Label>
          <Value>
            {info?.index} ({info ? cellToLatLng(info?.index) : ''})
          </Value>
        </Row>
        <Row spacing={1}>
          <Label>{t('hexagonView.name')}</Label>
          <Value>{info?.title || t('hexagonView.emptyName')}</Value>
        </Row>
        <Row spacing={1}>
          <Label>{t('hexagonView.owner')}</Label>
          <Value>{info?.owner?.user_id ? <UserLink {...info?.owner} /> : t('hexagonView.emptyOwner')}</Value>
        </Row>
      </Table>
      {info?.status === 'FREEAVAIL' ? (
        <ActionBlock>
          {info?.have_free_nft ? (
            <ActionButton variant="outlined" onClick={() => handlerActionGet()}>
              {t('hexagonView.button.get')}
            </ActionButton>
          ) : info?.package.id ? (
            <ActionButton variant="outlined" onClick={() => handlerActionBuy(info?.package.id)}>
              {t('button.buy')}
            </ActionButton>
          ) : null}
        </ActionBlock>
      ) : info?.status === 'BUSY' ? (
        <ActionButton variant="outlined">
          <TuneIcon onClick={() => handlerActionEdit()} />
        </ActionButton>
      ) : null}
      <DrawerBottom isOpen={edit} onClose={() => setEdit(false)}>
        123
      </DrawerBottom>
    </Block>
  );
};

export default Card;
