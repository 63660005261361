import { Box } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterTriangleIcon } from 'src/assets/icons';
import { useResize } from 'src/hooks';
import { LEVELS_TO_COLORS } from 'src/styles';

import { BoxStack, Number, Label } from './LevelsStat.styles';
import { LevelsStatProps } from './LevelsStat.types';

const LevelsStat: FC<LevelsStatProps> = ({ levels, sx }) => {
  const { t } = useTranslation('app');
  const columnRef = useRef<HTMLDivElement>(null);
  const { width } = useResize(columnRef);

  const [widthCol, setWidthCol] = useState(0);

  useEffect(() => {
    setWidthCol(width / levels.length);
  }, [levels, width]);

  useEffect(() => {
    const { current } = columnRef;
    if (!current) return;
    setWidthCol(current.offsetWidth / levels.length);
  }, [columnRef, levels, width]);

  return (
    <BoxStack sx={sx} ref={columnRef}>
      {levels.map((level, idx) => (
        <Box
          key={`speed.level.${idx + 1}`}
          sx={{
            position: 'relative',
            overflow: 'hidden',
            '& .triangle': {
              flex: 1,
              width: `${widthCol}px`,
              stroke: LEVELS_TO_COLORS[idx],
              fill: (theme) => theme.palette.background.paper,
            },
          }}
        >
          <FilterTriangleIcon width="auto" />
          <Number sx={{ color: LEVELS_TO_COLORS[idx] }}>{level}</Number>
          <Label sx={{ color: 'primary.main', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {t(`speed.level.${idx + 1}`)}
          </Label>
        </Box>
      ))}
    </BoxStack>
  );
};

export default LevelsStat;
