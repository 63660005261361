import { Divider, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeFontWeight } from 'src/styles';

export const FirstBlock = styled(Stack)(({ theme }) => ({
  textAlign: 'center',
  background: theme.palette.background.paper,
  marginLeft: '-16px',
  marginRight: '-16px',
  paddingRight: '16px',
  paddingLeft: '16px',
})) as typeof Stack;

export const Img = styled('img')(() => ({
  // aspectRatio: '1 / 1',
  display: 'block',
}));

export const Title = styled(Typography)(() => ({
  textTransform: 'uppercase',
  padding: '8px',
  fontSize: '1.125rem',
  fontWeight: ThemeFontWeight.semiBold,
  display: 'block',
  textAlign: 'left',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flex: 1,
}));

export const Id = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '1rem',
  fontWeight: ThemeFontWeight.light,
  display: 'block',
  textAlign: 'right',
  padding: '8px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flex: 1,
}));

export const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '0.75rem',
  fontWeight: ThemeFontWeight.regular,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginRight: '16px',
  width: '30%',
  flex: 'none',
}));

export const Value = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export const Row = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  minHeight: '40px',
}));

export const RowDivider = styled(Divider)(() => ({
  margin: '16px -16px 16px -16px',
}));
