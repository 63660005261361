import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FONTS, ThemeColors, ThemeGradients } from 'src/styles';

export const Title = styled('p')(() => ({
  fontFamily: FONTS,
  fontSize: '1.375rem',
  fontWeight: 500,
  color: ThemeColors.redLight,
  lineHeight: '28px',
  textAlign: 'center',
  marginTop: 4,
  marginBottom: 20,
}));

export const Paragraph = styled(Typography)(() => ({
  marginBottom: 16,

  b: {
    background: ThemeGradients.text,
    backgroundClip: 'text',
    textFillColor: 'transparent',
  },
}));
