import { FC } from 'react';

import { Wrapper, FromPoint, ToPoint, CurrentPoint, Point, FillEnergy } from './ImgProgressbar.styles';
import { ImgProgressbarProps } from './ImgProgressbar.types';

const ImgProgressbar: FC<ImgProgressbarProps> = ({
  percent,
  onInfoHandler,
  sx,
  height,
  fromPoint,
  toPoint,
  children,
}) => {
  const _height = height || 195;
  const _pxPercent = (_height / 100) * percent;
  const _halfHeightPoint = 24;

  return (
    <>
      <Wrapper sx={{ height: `${_height}px`, ...sx }} className="ImgProgressbar">
        {toPoint && (
          <ToPoint>
            <Point>{toPoint}</Point>
          </ToPoint>
        )}
        {fromPoint && (
          <FromPoint>
            <Point>{fromPoint}</Point>
          </FromPoint>
        )}
        <CurrentPoint sx={{ bottom: percent ? `${percent}%` : '1px' }} className="ImgProgressbar_currentPoint">
          <Point
            sx={{
              bottom:
                _pxPercent < _halfHeightPoint
                  ? `-${_pxPercent}px`
                  : _pxPercent < _height - _halfHeightPoint
                  ? `-${_halfHeightPoint}px`
                  : `${_height - _pxPercent - _halfHeightPoint * 2}px`,
              height: `${_halfHeightPoint * 2}px`,
            }}
          >
            {`${percent}%`}
          </Point>
        </CurrentPoint>
        <FillEnergy sx={{ height: `${percent}%` }} />
        {children}
      </Wrapper>
    </>
  );
};

export default ImgProgressbar;
