import { OperationTypeValues } from 'src/store/filtersSlice';
import { walletProviders } from 'src/types';

import { TDeliveryFormValues } from '../TargetsService';
import { User } from '../UserService';

export type TOrderUser = Pick<User, 'user_id' | 'display_name' | 'photo' | 'photo_uuid'> & {
  public_address: string;
};

export enum OrderStatus {
  New = 'NEW',
  Awaiting = 'AWAITING',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
}

export enum TradeRole {
  Buyer = 'BUYER',
  Seller = 'SELLER',
  Neutral = 'NEUTRAL',
}

export enum OrderType {
  TradeNFT = 'TRADE_NFT',
  CreateClan = 'CREATE_CLAN',
  UnlockUpgrade = 'UNLOCK_UPGRADE',
  Unknown = 'UNKNOWN',
}

export type OrderListItem = {
  id: number;
  buyer: TOrderUser;
  seller: TOrderUser;
  status: OrderStatus;
  total_price: string;
  balance_change: string;
  trade_role: TradeRole;
  created_at: string;
  order_type: OrderType;
};

export type OrderList = {
  count: number;
  overall_balance_change: string;
  results: OrderListItem[];
  balance: TOrderPrice;
  max_balance_change: string;
  min_balance_change: string;
};

export enum OrderItemType {
  AvatarPassportNFT = 'AVATAR_PASSPORT_NFT',
  AvatarNFT = 'AVATAR_NFT',
  AvatarSlot = 'AVATAR_SLOT',
  BodyPartNFT = 'BODY_PART_NFT',
  BusinessStructureNFT = 'BUSINESS_STRUCTURE_NFT',
  ClanLevel = 'CLAN_LEVEL',
  EyesNFT = 'EYES_NFT',
  HairNFT = 'HAIR_NFT',
  ItemNFT = 'ITEM_NFT',
  MaterialNFT = 'MATERIAL_NFT',
  MaterialGradeNFT = 'MATERIAL_GRADE_NFT',
  SecretBoxNFT = 'SECRET_BOX_NFT',
  TargetNFT = 'TARGET_NFT',
}

export enum OrderAction {
  Mint = 'MINT',
  Burn = 'BURN',
  Trade = 'TRADE',
  Create = 'CREATE',
}

export enum FeeType {
  Blockchain = 'BLOCKCHAIN',
  Service = 'SERVICE',
  Tenant = 'TENANT',
  Mint = 'MINT',
}

export type ItemFee = {
  id: number;
  fee_amount: string;
  fee_type: FeeType;
};

export type OrderItem = {
  id: number;
  item_type: OrderItemType;
  action: OrderAction;
  price: string;
  transaction_id: string;
  fees: ItemFee[];
  image: string;
};

export type OrderItemFull = {
  id: number;
  item_type: OrderItemType;
  action: OrderAction;
  price: string;
  transaction_id: string;
  image_uuid: string;
  image: string;
  seller: TOrderUser;
  fees: ItemFee[];
};

export type Order = {
  id: number;
  buyer: TOrderUser;
  status: OrderStatus;
  buyer_total: string;
  fee_total: string;
  contract_id: string;
  balance_change: string;
  transaction_id: string;
  trade_role: TradeRole;
  created_at: string;
  order_type: OrderType;
  items: OrderItemFull[];
};

export type OrderParams = {
  wallet?: string;
  balance_change?: string;
  offset?: number;
  limit?: number;
  clan_tier?: number;
  balance_change__gte?: string;
  balance_change__lte?: string;
  created_at__gte?: string;
  created_at__lte?: string;
  ordering?: string;
  ntf_type?: OperationTypeValues;
  secret_box_rarity?: string;
  material_level?: string;
  item_type?: string;
};

export type ClanLevel = {
  id: number;
  price: string;
  name: string;
  tier: number;
  item_wrapper: {
    id: number;
    item_type: OrderItemType;
  };
  land_spawner: {
    id: number;
    land_name: string;
    land_image: string;
    land_price: string;
  };
  have_clan: boolean;
  is_clan_open: boolean;
  fee: number;
};

export type CreateOrder = {
  items_wrappers: number[];
};

export type DefaultOrderItem = {
  fee: number;
  image: string;
  image_uuid: string;
  item_type: OrderItemType;
};

//--- new

export type TOrderPrice = {
  bcost: number;
  bcurrency: string;
  cost?: number;
  currency?: string;
};

export type TOrderFee = {
  id: number;
  fee_amount: TOrderPrice;
  fee_type: string;
};

export type TOrderPackage = {
  id: number;
  level: number;
  can_buy_count: number;
  item_wrapper: {
    id: number;
    item_type: OrderItemType;
  };
  title: string;
  short: string;
  description: string;
  currency: TOrderPrice;
  progress: {
    avail: number;
    sum: number;
    percent: number;
  };
  picture: {
    uuid: string;
    video?: string;
  };
  photo: string;
  created_at?: string;
};

export type TOrderItem = {
  id: number;
  action: string;
  fees: TOrderFee[];
  image_uuid: string;
  item_type: string;
  price: TOrderPrice;
  seller?: TOrderUser;
  transaction_id?: string;
  buyer_total: TOrderPrice;
  fee_total: TOrderPrice;
  pay_total: TOrderPrice;
  package: TOrderPackage;
  count?: number;
  wrapper?: number;
};

export type TOrder = {
  id: number;
  balance_change: TOrderPrice;
  buyer_total: string;
  contract_id: string;
  wallet?: string; // кошелек для отправки денег в маркете
  created_at: string;
  fee_total: string;
  items: TOrderItem[];
  order_type: OrderType;
  status: OrderStatus;
  trade_role: TradeRole;
  wrapper_type: string; // тип операции для кастомных ключей перевода
  transaction_id?: string;
  buyer?: TOrderUser;
  seller?: TOrderUser;
  delivery?: TDeliveryFormValues;
};

export type TGetMoney = TOrder & {
  balances: {
    current_balance: TOrderPrice;
    notrecommend: TOrderPrice;
    recommend: TOrderPrice;
  };
};

export type TOrderWallet = {
  wallet: string; // идентификатор кошелька
  address: string; // адрес кошелька
  walletProvider: walletProviders; // посути тип кошельк
};

export type TOrderList = {
  balance: TOrderPrice;
  count: number;
  results: TOrder[];
  wallets: TOrderWallet[];
};

export type TOrderConfirmFuntion = (
  order: TOrder,
  txId: string,
  callback: (order: TOrder) => void,
  errback: (message: string) => void
) => void;

export const priceSum = (price: TOrderPrice, itemPrice: TOrderPrice, count: number | undefined) => {
  const _count = count === undefined ? 1 : count;
  price.cost = (price.cost || 0) + (itemPrice.cost || 0) * _count;
  price.bcost = (price.bcost || 0) + (itemPrice.bcost || 0) * _count;

  price.currency = price.currency ? price.currency : itemPrice.currency;
  price.bcurrency = price.bcurrency ? price.bcurrency : itemPrice.bcurrency;
  return price;
};
