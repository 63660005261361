import { Stack, Box } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';

import { YouTubeSubscribeProps } from './YouTubeSubscribe.types';

const YouTubeSubscribe: FC<YouTubeSubscribeProps> = ({ channelName, onSubscribe }) => {
  const [init, setInit] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (init) {
      return;
    }

    const youtubescript = document.createElement('script');
    youtubescript.src = 'https://apis.google.com/js/platform.js';
    if (buttonRef.current && buttonRef.current.parentNode) buttonRef.current.parentNode.appendChild(youtubescript);
    setInit(true);
  }, [init]);

  return (
    <Stack>
      <Box
        ref={buttonRef}
        className="g-ytsubscribe"
        data-channel={channelName}
        data-layout="default"
        data-theme="dark"
        data-count="hidden"
      ></Box>
      <script src="https://apis.google.com/js/platform.js"></script>
    </Stack>
  );
};

export default YouTubeSubscribe;
