import { Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors, ThemeGradients } from 'src/styles';

export const PeriodStack = styled(Stack)(() => ({
  height: 17,
  marginBottom: 21,
  borderRadius: 30,
  background: ThemeGradients.bgLight,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const PeriodButton = styled(Button)(() => ({
  padding: '1px 5px 0',
  minWidth: 0,
  height: 28,
  borderRadius: 10,
  fontSize: '0.75rem',
  lineHeight: '18px',
  fontWeight: 400,
  borderColor: 'transparent',
  backdropFilter: 'none',
  textTransform: 'uppercase',

  '&.checked': {
    backgroundColor: ThemeColors.black,
    color: ThemeColors.white,
  },
}));

export const PeriodWrapper = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: 40,
  border: `1px solid ${ThemeColors.grayLight}`,
  backgroundColor: ThemeColors.body,
  borderRadius: 20,
}));

export const PeriodPointText = styled(Typography)(() => ({
  fontSize: '0.812rem',
  lineHeight: '18px',
  fontWeight: 600,
}));

export const DateBox = styled(Typography)(() => ({
  fontSize: '0.812rem',
  lineHeight: '18px',
  fontWeight: 600,
  color: ThemeColors.white,
  padding: '4px 10px',
  backgroundColor: ThemeColors.black,
  borderRadius: 13,
  height: 26,
  minWidth: 96,
  margin: '0 8px',
  textAlign: 'center',
  letterSpacing: 0.5,
}));
