import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SettingsOverscanIcon from '@mui/icons-material/SettingsOverscan';
import { Box, Stack, Typography, IconButton } from '@mui/material';
import * as d3 from 'd3';
import { FC, useRef, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { NoPhoto } from 'src/assets/icons';
import { UserLink } from 'src/atoms';
import { getImgSrc } from 'src/constants';
import { useAppDispatch, useAppSelector, useWindowSize } from 'src/hooks';
import { ClanTreeChildrenNode } from 'src/services';
import { setSelectedClanId } from 'src/store/clanSlice';
import { selectUserMe } from 'src/store/userSlice';
import { ThemeColors } from 'src/styles';
import { degree2p } from 'src/utils';

import { ClansTreeProps } from './ClansTree.types';

// let fakeId = Math.round(Math.random() * 100000);

const maxDepth = (node?: ClanTreeChildrenNode): number => {
  if (node === undefined || node.count) return 0;
  else {
    /* compute the depth of each subtree */
    const lDepth = maxDepth(node.children[0]);
    const rDepth = maxDepth(node.children[1]);

    if (lDepth > rDepth) return lDepth + 1;
    else return rDepth + 1;
  }
};
/*
const clanLevels: { [key: number]: number } = {};


const getNextClanIds = (clanIds?: number[]): [number, number][] => {
  if (!clanIds) return [];

  return clanIds.map((clanId) => {
    clanLevels[clanId] = clanLevels[clanId] === undefined ? 7 : clanLevels[clanId] - 1;
    return [clanId, clanLevels[clanId]];
  });
};


const createFakeNode = (maxLevel: number, clanIds?: number[]): ClanTreeChildrenNode => {
  fakeId += 1;

  //const nextClans = getNextClanIds(clanIds);
  //const nextClanIds =
  //  nextClans.reduce((acc: number[], clan) => {
  //    if (clan[1] > 0) acc.push(clan[0]);
  //    return acc;
  //  }, []) || undefined;

  return {
    id: fakeId,
    user: undefined,
    is_fake: true,
    clan_ids: [],
    children:
      maxLevel > 1
        ? [createFakeNode(maxLevel - 1), createFakeNode(maxLevel - 1)]
        : maxLevel <= 0
        ? []
        : [createFakeNode(maxLevel - 1)],
  };
};
*/

const childHierarchy = (childs: ClanTreeChildrenNode[], maxLevel: number) => {
  return childs.map((item) => {
    /*
    if (item.is_fake) {
      fakeId += 1;
      item.id = fakeId;
    }*/

    item.children = childHierarchy(item.children, maxLevel - 1);
    if (!item.count) {
      // getNextClanIds(item.clan_ids);
      /*if (item.children.length === 0 || (item.children.length === 1 && !item.children[0].count)) {
        item.children.push(createFakeNode(maxLevel - 1, item.clan_ids));
        if (item.children.length === 1 && maxLevel > 1) item.children.push(createFakeNode(maxLevel - 1, item.clan_ids));
      }*/
    }
    return item;
  });
};

const trianglePath = (tiangleGroup: any, [x1, y1]: [number, number], [x2, y2]: [number, number], r: number) => {
  const width = x2 - x1;
  //const height = y2 - y1;

  const pathD = `
	M ${x1 + width / 2} ${y1}
  L ${x2} ${y2}
  L ${x1} ${y2}
  z
  `;

  return tiangleGroup
    .append('path')
    .attr('d', pathD)
    .attr('stroke-linejoin', 'round')
    .attr('stroke-width', r * 2);
};

const defaultColor = ThemeColors.gray;
const emptyColor = ThemeColors.gray;
const sponsorColor = ThemeColors.white;
const referrerClanColor = ThemeColors.redMiddle;

const TreeLegendItem: FC<{ color: string; label: string }> = ({ color, label }) => {
  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ mb: '8px', overflow: 'hidden' }}>
      <Box
        sx={{ width: '20px', height: '20px', mr: '8px', borderRadius: '50%', background: color, flex: '0 0 auto' }}
      ></Box>
      <Typography sx={{ fontSize: '0.625rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {label}
      </Typography>
    </Stack>
  );
};
const ClansTree: FC<ClansTreeProps> = ({
  data,
  accentColor: accentColorProp,
  userId,
  userClanNodes,
  referrerClanNodes,
  sx,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('app');

  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const containerRef = useRef<HTMLDivElement>(null);
  const miniMapRef = useRef<HTMLDivElement>(null);
  const treeRef = useRef<SVGSVGElement>(null);
  const userMe = useAppSelector(selectUserMe);

  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const [treeData, setTreeData] = useState<ClanTreeChildrenNode>();

  const [selectedNode, setSelectedNode] = useState<ClanTreeChildrenNode>();

  const [showMap, setShowMap] = useState<boolean>(false);
  const [showLegend, setShowLegend] = useState<boolean>(false);

  const aLength = data.ancestors.length;
  const beginSize = 20;
  const accentColor = accentColorProp || defaultColor;

  useEffect(() => {
    if (!containerRef.current) return;

    setWidth(containerRef.current.offsetWidth);
    setHeight(containerRef.current.offsetHeight);
  }, [containerRef, windowWidth, windowHeight]);

  // const dataSvg = getSvgData(data, width);

  // ----------------

  useEffect(() => {
    const maxLevel = maxDepth(data);

    const treeData = [...data.ancestors].reverse().reduce(
      (acc: ClanTreeChildrenNode, item) => {
        return {
          id: item.id,
          user: item.user,
          children: [acc],
          isAncestors: true,
          clan_ids: undefined,
        };
      },
      {
        id: data.id,
        user: data.user,
        children: childHierarchy(data.children, maxLevel - 1),
        clan_ids: data.clan_ids,
      }
    );
    setTreeData(treeData);
  }, [data]);

  const miniMap = useCallback(() => {
    if (!containerRef.current || !miniMapRef.current) return;

    const boundaries = {
      width: miniMapRef.current.offsetWidth,
      height: miniMapRef.current.offsetHeight,
    };

    if (miniMapRef.current.children.length === 0) {
      const duplicate: any = containerRef.current.cloneNode(true);

      duplicate.id = 'minimapClone';
      duplicate.width = `${boundaries.width}px`;
      duplicate.height = `${boundaries.height}px`;
      duplicate.getElementsByTagName('svg')[0].id = 'minimap';
      // duplicate.childNodes[1].childNodes[0].childNodes[0].id = 'gradient_minimap';
      // duplicate.childNodes[1].childNodes[1].id = 'minimapBackground';
      miniMapRef.current.append(duplicate);
    }

    const minimap = d3.select('svg#minimap').attr('height', boundaries.height).attr('width', boundaries.width);
    minimap.select('rect#background').remove();

    let mmViewer: any = minimap.select('#minimapViewer');
    if (!mmViewer.node()) {
      mmViewer = minimap.append('rect').attr('id', 'minimapViewer');
    }

    mmViewer
      .attr('width', `${width}px`)
      .attr('height', `${height}px`)
      .attr('fill', 'transparent')
      .attr('stroke', ThemeColors.grayLight);
  }, [height, width]);

  useEffect(() => {
    if (!treeData || !treeRef) return;

    const nodeSize = beginSize * 2 + 8;
    const offsetX = width / 2;
    const offsetY = nodeSize;

    // ----------------------------------------------------------------

    const root: d3.HierarchyNode<unknown> = d3.hierarchy(treeData) as d3.HierarchyNode<unknown>;

    /* TREE LAYOUT */

    const treeLayout = d3.tree();

    treeLayout.nodeSize([nodeSize, nodeSize + 10]).separation((a, b) => {
      return a.parent === b.parent ? 0.8 : 0.8;
    });
    treeLayout(root);

    const svgElement = d3.select(treeRef.current);

    function handleZoom(e: any) {
      svgElement.select('#pan').attr('transform', e.transform);
      const mmScale = 1 / e.transform.k;
      const mmX = -e.transform.x;
      const mmY = -e.transform.y;
      d3.select('#minimapViewer').attr('transform', `translate(${mmX}, ${mmY}) scale(${mmScale})`);
    }

    const zoomSvg: any = d3.zoom().scaleExtent([0.03, 2]).on('zoom', handleZoom);
    svgElement.call(zoomSvg);

    // links ----------------------------------------------------------------

    const drawLine = (selection: any) => {
      selection
        .attr('x1', (d: any) => d.source.x + offsetX)
        .attr('y1', (d: any) => d.source.y + offsetY)
        .attr('x2', (d: any) => d.target.x + offsetX)
        .attr('y2', (d: any) => d.target.y + offsetY)
        .attr('stroke-width', 1);
      return selection;
    };

    const linesTree = svgElement
      .select('g.links')
      .selectAll('line')
      .data(root.links()) //.filter((n: any) => !n.target.data.is_fake))
      .enter();

    linesTree
      .append('line')
      .attr('id', (d: any) => `baseLine_${d.source.data.id}_${d.target.data.id}`)
      .classed('baseLine', true)
      .call(drawLine)
      .each((node: any) => {
        const point = svgElement.select(`#baseLine_${node.source.data.id}_${node.target.data.id}`);

        if (node.target.data.clan_ids && node.source.data.clan_ids && node.target.data.clan_ids.length > 0) {
          node.source.data.clan_ids
            .filter((x: number) => (node.target.data.clan_ids || []).includes(x))
            .forEach((clanId: number) => {
              point.classed('clan_' + clanId, true);
            });
        }
      });

    linesTree
      .append('line')
      .attr('id', (d: any) => `line_${d.source.data.id}_${d.target.data.id}`)
      .classed('line', true)
      .classed('line_fake', (d: any) => d.target.data.is_fake)
      .attr('stroke', (d: any) => (d.target.data.user || d.target.data.count ? defaultColor : emptyColor))
      .attr('stroke-width', 1)
      .call(drawLine);

    // nodes ----------------------------------------------------------------

    const handleEvents = (selection: any) => {
      let radius = 0;

      selection.on('mouseover', (event: any) => {
        if (!radius) radius = Number(selection.attr('r'));
        //let n = g.select('.the-node');
        //if (n.classed('solid')) {
        selection
          .transition()
          .duration(400)
          .attr('r', radius + 3);
      });
      selection.on('mouseout', () => {
        if (!radius) radius = Number(selection.attr('r'));

        selection.transition().duration(400).attr('r', radius);
      });
      selection.on('click', () => {
        const nodes = selection.data();
        if (nodes) {
          const node = nodes[0];
          const element = svgElement.select(`#node_${node.data.id}`);
          if (element.classed('choose')) {
            setSelectedNode(undefined);
            dispatch(setSelectedClanId(node.data.id));
          } else {
            svgElement.select('g.nodes').selectAll('g.node').classed('selected', false).classed('choose', false);
            svgElement.select('g.links').selectAll('line.line').classed('selected', false);
            svgElement
              .select('g.nodes')
              .selectAll('g.node')
              .data(node.ancestors())
              .each((n: any) => {
                d3.select(`#node_${n.data.id}`).classed('selected', true);
                if (n.parent) d3.select(`#line_${n.parent.data.id}_${n.data.id}`).classed('selected', true);
              });
            element.classed('choose', true);
            setSelectedNode(node.data);
          }
        }
      });
    };

    const treeNodes = svgElement
      .select('g.nodes')
      .selectAll('g.node')
      .data(root.descendants())
      .enter()
      .append('g')
      .attr('id', (d: any) => `node_${d.data.id}`)
      .classed('node', true);

    let [minX, maxX] = [0, 0];
    treeNodes.each((node: any) => {
      // не рисовать фэйковые ноды, если они не входят в клан
      if (node.data.is_fake && !node.data.clan_ids) return;

      const depth = node.depth - aLength;
      let radius = depth > 0 ? beginSize - depth : beginSize;

      if (node.data.isAncestors) {
        radius = 18;
      }

      if (node.x < minX) minX = node.x;
      if (node.x > maxX) maxX = node.x;

      const point = d3.select(`#node_${node.data.id}`); //.selectAll('circle').data(node).enter();

      const baseCircle = point
        .append('circle')
        .classed('base', true)
        .attr('cx', node.x + offsetX)
        .attr('cy', node.y + offsetY)
        .attr('r', radius)
        .attr('fill', ThemeColors.blackDark);

      /*
      if (node.data.is_fake) {
        const nextClans = getNextClanIds(node.parent.data.clan_ids);
        node.data.clan_ids =
          nextClans.reduce((acc: number[], clan) => {
            if (clan[1] > 0) acc.push(clan[0]);
            return acc;
          }, []) || undefined;
      } else {
        if (!node.data.count) getNextClanIds(node.data.clan_ids);
      }*/

      // развесим атребуты клана
      if (node.data.clan_ids && node.data.clan_ids.length > 0) {
        node.data.clan_ids.forEach((clanId: number) => {
          baseCircle.classed('clan_' + clanId, true);
        });
      }

      if (node.data.is_fake) return point;
      else {
        baseCircle.attr('stroke', defaultColor).attr('stroke-width', 1).attr('fill', ThemeColors.blackDark);
      }

      if (node.data.count) {
        point
          .append('svg:text')
          .attr('x', node.x + offsetX)
          .attr('y', node.y + offsetY)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .attr('fill', ThemeColors.white)
          .style('fontSize', radius * 1.2)
          .text(node.data.count);
        return point;
      }

      baseCircle.classed('base', false);

      point
        .append('circle')
        .classed('pointer', true)
        .attr('cx', node.x + offsetX)
        .attr('cy', node.y + offsetY)
        .attr('r', radius)
        .attr('fill', `url(#filter_image_${node.data.id})`)
        .attr('stroke-width', (d: any) => (d.data.user.id === userId ? 3 : 1))
        .call(handleEvents);

      const pointDefs = point.append('svg:defs');
      pointDefs
        .append('svg:pattern')
        .attr('id', `filter_image_${node.data.id}`)
        .attr('x', 0)
        .attr('y', 0)
        .attr('height', '100%')
        .attr('width', '100%')
        .attr('viewBox', `0 0 ${radius * 2} ${radius * 2}`)
        .append('svg:image')
        .attr('x', 0)
        .attr('y', 0)
        .attr('height', radius * 2)
        .attr('width', radius * 2)
        .attr(
          'xlink:href',
          node.data.user?.photo_uuid ? getImgSrc(node.data.user.photo_uuid, `s${radius * 2}-c`) || '' : NoPhoto
        );

      /*
      if (node.data.user.id === userId) {
        // если есть текущий пользователь, то рисуем от него спонсорскую линию
        svgElement
          .select('g.nodes')
          .selectAll('g.node')
          .data(node.ancestors())
          .each((n: any) => {
            d3.select(`#node_${n.data.id}`).classed('sponsor', true);
            if (n.parent) d3.select(`#line_${n.parent.data.id}_${n.data.id}`).classed('sponsor', true);
          });
      }
      */

      return point;
    });

    // разметим все кланы реферала
    if (referrerClanNodes && referrerClanNodes.length > 0) {
      referrerClanNodes.forEach((clanId: number) => {
        svgElement.selectAll('.clan_' + clanId).classed('referrerclan', true);
      });
    }

    // разметим все кланы пользователя
    if (userClanNodes && userClanNodes.length > 0) {
      const tiangleGroup = svgElement.select('g.tiangle');
      userClanNodes.forEach((clanId: number, idx) => {
        const selectedNode = svgElement.selectAll('.clan_' + clanId);
        if (selectedNode.empty()) return;

        selectedNode
          .classed('referrerclan', false) // свой клан в приоритете
          .classed('myclan', true);

        const curveLine = d3
          .line()
          .curve(d3.curveBasis)
          .x((d) => d[0])
          .y((d) => d[1]);

        const clanCoord = {
          minX: 1000000,
          minY: 1000000,
          maxX: -1000000,
          maxY: -1000000,
          depth: 0,
        };
        selectedNode.each((node: any) => {
          if (!clanCoord.depth) clanCoord.depth = node.depth;
          clanCoord.maxX = clanCoord.maxX > node.x ? clanCoord.maxX : node.x;
          clanCoord.maxY = clanCoord.maxY > node.y ? clanCoord.maxY : node.y;
          clanCoord.minX = clanCoord.minX < node.x ? clanCoord.minX : node.x;
          clanCoord.minY = clanCoord.minY < node.y ? clanCoord.minY : node.y;
        });

        clanCoord.maxX += offsetX;
        clanCoord.minX += offsetX;
        clanCoord.maxY += offsetY;
        clanCoord.minY += offsetY;
        const depth = clanCoord.depth - aLength;
        let radius = depth > 0 ? beginSize - depth : beginSize;
        // нарисуем треугольник
        trianglePath(
          tiangleGroup,
          [clanCoord.minX, clanCoord.minY],
          [clanCoord.maxX, clanCoord.maxY],
          radius + radius * 0.8
        )
          .attr('fill', 'none')
          .attr('stroke', accentColor)
          .classed(idx === userClanNodes.length - 1 ? 'current_clane' : 'clossed_clane', true);
        trianglePath(
          tiangleGroup,
          [clanCoord.minX, clanCoord.minY],
          [clanCoord.maxX, clanCoord.maxY],
          radius + radius * 0.4
        )
          .attr('fill', ThemeColors.black)
          .attr('stroke', ThemeColors.black)
          .classed(idx === userClanNodes.length - 1 ? 'current_clane_bg' : 'clossed_clane_bg', true);
        /*
          .append('polyline')
          .attr(
            'points',
            `${clanCoord.minX + widthTr / 2},${clanCoord.minY}
            ${clanCoord.maxX}, ${clanCoord.maxY}
            ${clanCoord.minX}, ${clanCoord.maxY}
            ${clanCoord.minX + widthTr / 2},${clanCoord.minY}
            `
          )
          .attr('stroke-linecap', 'round')
          .attr('stroke', '#ff0000')
          .attr('stroke-width', 1);
          */

        // --------------------------------

        if (idx > 0) {
          // рисуем стрелку перехода между кланами
          const point1: any = svgElement.select(`#node_${userClanNodes[idx - 1]}`).node();
          const point2: any = svgElement.select(`#node_${clanId}`).node();
          if (point1 && point2) {
            const fromBBox: { x: number; y: number; width: number } = point1.getBBox();
            const toBBox: { x: number; y: number; width: number } = point2.getBBox();
            const fromP: [number, number] = [
              fromBBox.x < toBBox.x ? fromBBox.x + fromBBox.width : fromBBox.x,
              fromBBox.y,
            ];
            const toP: [number, number] = [toBBox.x < fromBBox.x ? toBBox.x + toBBox.width : toBBox.x, toBBox.y];

            const centerP: [number, number] = [
              fromP[0] - (fromP[0] - toP[0]) / 2,
              (fromP[1] < toP[1] ? fromP[1] : toP[1]) - 20,
            ];

            const clanLines = svgElement.select('.clanLines');
            clanLines
              .append('path')
              .style('fill', 'none')
              .attr('d', curveLine([fromP, centerP, toP]))
              .style('pointer-events', 'none')
              .classed('clanLine', true);

            clanLines
              .append('path')
              .attr('d', 'M -10,-5 0,0 -10,5')
              .style('fill', 'none')
              .style('pointer-events', 'none')
              .attr('transform', `translate(${toP[0]}, ${toP[1]}) rotate(${degree2p(centerP, toP)})`)
              .classed('clanLine', true);

            clanLines
              .append('svg:text')
              .attr('x', centerP[0])
              .attr('y', centerP[1])
              .attr('text-anchor', 'middle')
              .attr('alignment-baseline', 'middle')
              .attr('fill', ThemeColors.white)
              .style('fontSize', beginSize)
              .style('pointer-events', 'none')
              .text(idx);
          }
        }
      });
    }

    if (maxX && minX) {
      /*
      const realWidth = (maxX > Math.abs(minX) ? maxX * 2 : Math.abs(minX) * 2) + beginSize * 2;
      const scale = width / realWidth;
      svgElement.call(zoomSvg.transform, d3.zoomIdentity.scale(scale > 1 ? 1 : scale));
      */
      // svgElement
      // .select('g#initial')
      // .attr('transform', `translate(${width / 2}, ${beginSize * 2}) scale(${scale > 1 ? 1 : scale})`);
    }

    // miniMap();

    /*
    setD3Root(root as d3.HierarchyNode<ClanTreeChildrenNode>);
    setD3Tree(root.descendants() as d3.HierarchyPointNode<ClanTreeChildrenNode>[]);
    setD3Links(root.links());
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeRef, treeData, aLength, userId, userClanNodes, referrerClanNodes]);

  useEffect(() => {
    miniMap();
  }, [miniMap, showMap]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        '.pointer': { cursor: 'pointer' },
        ...sx,
        '& .links .line_fake': {
          visibility: 'hidden',
        },
        '& .nodes circle.referrerclan, .links .referrerclan': {
          visibility: 'visible',
          stroke: referrerClanColor,
          strokeWidth: 6,
        },
        '& .nodes circle.myclan, .links .myclan': {
          visibility: 'visible',
          stroke: accentColor,
          strokeWidth: 4,
        },

        '& .nodes circle.base.myclan': {
          stroke: ThemeColors.grayLight,
          strokeWidth: '2px!important',
        },

        // клан
        '& .clanLines .clanLine': {
          visibility: 'visible',
          stroke: sponsorColor,
          strokeWidth: 2,
          strokeLinecap: 'round',
        },
        '& .current_clane': {
          opacity: 0.4,
        },
        '& .current_clane_bg': {
          fill: ThemeColors.gray,
          stroke: ThemeColors.gray,
        },

        // ------- спонсорская линия
        // выделение спонсорской ноды и линии
        '& .node.selected circle.pointer, .links .selected': {
          visibility: 'visible',
          stroke: sponsorColor,
          strokeWidth: 1,
        },

        '& .links .selected': {
          visibility: 'visible',
          strokeDashoffset: 0,
          strokeDasharray: '3 3',
          strokeWidth: 3,
          animation: 'dash 10s linear forwards infinite',
        },

        '@keyframes dash': {
          to: {
            strokeDashoffset: 90,
          },
        },

        // выбранная нода, куда ткнули
        //'& .node.choose circle.pointer': {
        //  strokeWidth: 3,
        //},
      }}
    >
      <Box
        ref={containerRef}
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <svg
          fill={ThemeColors.black}
          ref={treeRef}
          id="tree"
          version="1.1"
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="pan">
            <rect id="background" fill="inferit" width="100%" height="100%" />
            <g className="tiangle"></g>
            <g className="links"></g>
            <g className="nodes"></g>
            <g className="clanLines"></g>
          </g>
        </svg>
      </Box>

      {selectedNode && selectedNode.user && selectedNode.user.id && userMe?.user_id !== selectedNode.user.id && (
        <UserLink
          sx={{
            position: 'absolute',
            top: 16,
            left: 16,
            zIndex: 3,
            backgroundColor: 'background.paper',
            borderRadius: '10px',
            padding: '10px',
            maxWidth: '50%',
          }}
          showName={true}
          user_id={selectedNode.user.id}
          {...selectedNode.user}
        />
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        flexWrap="nowrap"
        sx={{
          pointerEvents: 'none',
          width: '100%',
          position: 'absolute',
          bottom: 16,
        }}
      >
        <Stack
          sx={{
            height: '160px',
            flex: 1,
            m: '8px',
            mr: '4px',
          }}
        >
          {showMap ? (
            <Box
              sx={{
                height: '100%',
                backgroundColor: 'background.paper',
                padding: '8px 16px 16px 16px',
                borderRadius: '10px',
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: '4px', width: '100%' }}
              >
                <Typography sx={{ fontSize: '0.625rem', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {t('tree.mimimap.title')}
                </Typography>
                <IconButton sx={{ padding: 0, pointerEvents: 'auto' }} onClick={() => setShowMap(false)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Stack>
              <Box
                ref={miniMapRef}
                sx={{
                  width: width / 2 - 44,
                  height: '120px',
                }}
              ></Box>
            </Box>
          ) : (
            <IconButton
              sx={{
                position: 'absolute',
                backgroundColor: 'background.paper',
                border: '1px solid',
                borderColor: 'primary.main',
                overflow: 'hidden',
                left: 16,
                bottom: 16,
                pointerEvents: 'auto',
              }}
              onClick={() => setShowMap(true)}
            >
              <SettingsOverscanIcon />
            </IconButton>
          )}
        </Stack>

        <Stack
          sx={{
            flex: 1,
            overflow: 'hidden',
            m: '8px',
            ml: '4px',
            height: '100%',
          }}
        >
          {showLegend ? (
            <Box
              sx={{
                height: '100%',
                backgroundColor: 'background.paper',
                padding: '8px 16px 16px 16px',
                borderRadius: '10px',
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: '4px', width: '100%' }}
              >
                <Typography sx={{ fontSize: '0.625rem', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {t('tree.legend.title')}
                </Typography>
                <IconButton sx={{ padding: 0, pointerEvents: 'auto' }} onClick={() => setShowLegend(false)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Stack>
              <Box>
                <TreeLegendItem color={sponsorColor} label={t('tree.legend.item.sponsor')} />
                <TreeLegendItem color={accentColor} label={t('tree.legend.item.myClan')} />
                <TreeLegendItem color={referrerClanColor} label={t('tree.legend.item.sponsorClan')} />
                <TreeLegendItem color={defaultColor} label={t('tree.legend.item.other')} />
              </Box>
            </Box>
          ) : (
            <IconButton
              sx={{
                position: 'absolute',
                backgroundColor: 'background.paper',
                border: '1px solid',
                borderColor: 'primary.main',
                overflow: 'hidden',
                right: 16,
                bottom: 16,
                pointerEvents: 'auto',
              }}
              onClick={() => setShowLegend(true)}
            >
              <InfoOutlinedIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default ClansTree;
