import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { IconButton, Stack, Typography, LinearProgress, Box } from '@mui/material';
import { FC } from 'react';

import { ThemeColors } from 'src/styles';

import { PageTitleProps } from './WindowTitle.types';

const WindowTitle: FC<PageTitleProps> = ({ title, subtitle, isLoading, sx, onClose, onBack, ...rest }) => {
  return (
    <>
      <Box
        sx={{
          pointerEvents: 'none',
          top: 0,
          left: 0,
          right: 0,
          position: 'relative',
          backgroundColor: 'transparent',
        }}
      >
        <Stack
          sx={{ maxWidth: '600px', minHeight: '40px', width: '100%', margin: '0 auto', px: '0px', ...(sx || {}) }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {/*<Stack direction="row" alignItems="center">*/}
          {onBack && (
            <IconButton
              data-qa="title.button.back"
              sx={{ alignSelf: 'center', pointerEvents: 'auto', mt: '0px' }}
              onClick={onBack}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
          )}
          <Typography
            data-qa={rest['data-qa']}
            className="WindowTitle_title"
            sx={{ textTransform: 'uppercase', mt: '0px', mb: '0px' }}
            variant="h2"
            noWrap
          >
            {title}
          </Typography>
          {/*</Stack>*/}
          {onClose && (
            <IconButton
              data-qa="title.button.close"
              sx={{ alignSelf: 'center', pointerEvents: 'auto', mt: '0px', color: ThemeColors.gray500 }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          )}
          {isLoading && <LinearProgress sx={{ position: 'absolute', marginLeft: 0, left: 0, top: 0, width: '100%' }} />}
        </Stack>
      </Box>
      {subtitle && (
        <Typography
          sx={{
            mt: '-10px',
            fontSize: '0.812rem',
            textTransform: 'uppercase',
            color: (theme) => theme.palette.secondary.main,
          }}
        >
          {subtitle}
        </Typography>
      )}
    </>
  );
};

export default WindowTitle;
