import { TTargetOnGlobe } from '../TargetsService';

export type GlobePointProperty = {
  id: string;
  connected: string[];
  is_count: boolean;
  photo_uuid: string;
  count: number;
  user_id: number;
  photo?: string;
  target?: TTargetOnGlobe;
};

export type GlobePointGeometry = {
  type: 'Point';
  coordinates: number[];
};

export type GlobePoint = {
  type: 'Feature';
  properties: GlobePointProperty;
  geometry: GlobePointGeometry;
};

export enum GlobeType {
  AllProject = 'all_project',
  MyTeam = 'my_team',
}

export const mockItems = [
  {
    type: 'Feature',
    properties: {
      id: '1',
      user_id: 2547,
      connected: ['2', '3'],
      photo_uuid: 'e37dba76-65f0-4bd0-acde-c59c3e29a8ca',
      is_count: false,
      count: 1,
      target: {},
    },
    geometry: {
      type: 'Point',
      coordinates: [43.2631, 76.9271],
    },
  },
  {
    type: 'Feature',
    properties: {
      id: '2',
      user_id: 4,
      connected: ['1'],
      photo_uuid: 'e37dba76-65f0-4bd0-acde-c59c3e29a8ca',
      is_count: false,
      count: 1,
      target: {},
    },
    geometry: {
      type: 'Point',
      coordinates: [13.2631, 76.9271],
    },
  },
  {
    type: 'Feature',
    properties: {
      id: '3',
      user_id: 1,
      connected: ['2'],
      photo_uuid: 'e37dba76-65f0-4bd0-acde-c59c3e29a8ca',
      is_count: false,
      count: 1,
      target: {},
    },
    geometry: {
      type: 'Point',
      coordinates: [23.2631, 46.9271],
    },
  },
  {
    type: 'Feature',
    properties: {
      id: '4',
      user_id: 1,
      connected: ['2'],
      photo_uuid: 'e37dba76-65f0-4bd0-acde-c59c3e29a8ca',
      is_count: false,
      count: 1,
      target: {},
    },
    geometry: {
      type: 'Point',
      coordinates: [23.2531, 46.9371],
    },
  },
] as GlobePoint[];
