import { styled } from '@mui/material/styles';

import { FONTS, ThemeGradients } from 'src/styles';

export const EmptyTeamWrapper = styled('div')(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
}));

export const Title = styled('p')(() => ({
  padding: '16px 0',
  textAlign: 'center',
  marginBottom: 14,

  span: {
    fontFamily: FONTS,
    fontSize: '1.5rem',
    fontWeight: 600,
    background: ThemeGradients.text,
    backgroundClip: 'text',
    textFillColor: 'transparent',
    lineHeight: '26px',
    textTransform: 'uppercase',
  },
}));

export const Text = styled('p')(() => ({
  fontSize: '0.875rem',
  lineHeight: '16px',
  textAlign: 'center',
}));
