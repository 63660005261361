import { Box, Switch, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

const size = 28;

export const SwitchStyled = styled(Switch)(({ theme }) => ({
  width: 45,
  height: size,
  padding: 0,

  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '2px',
    transitionDuration: '300ms',
    color: theme.palette.background.default,

    '&.Mui-checked': {
      transform: 'translateX(17px)',

      '& + .MuiSwitch-track': {
        backgroundColor: ThemeColors.green,
        border: `1px solid ${ThemeColors.green}`,
        opacity: 1,
      },
      '& .MuiSwitch-thumb': {
        background: theme.palette.primary.main,
        boxShadow: '-1px 0px 5px rgba(0, 0, 0, 0.25)',
      },
    },

    '&.Mui-disabled': {
      '& + .MuiSwitch-track': {
        background: theme.palette.secondary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: size - 4,
    height: size - 4,
    border: 0,
    background: theme.palette.primary.main,
  },

  '& .MuiSwitch-track': {
    borderRadius: size / 2,
    backgroundColor: theme.palette.background.darker,
    border: `1px solid ${theme.palette.background.darker}`,
    height: size,
    opacity: 1,
    transition: 'background-color 300ms',
  },
}));

export const MainLabel = styled(Typography)(() => ({
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '21px',
  marginRight: 10,
}));

export const SwitchBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const OtherLabel = styled(Typography)(() => ({
  fontSize: '0.75rem',
  marginLeft: 5,
  marginRight: 5,
}));
