import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors, ThemeFontWeight } from 'src/styles';

export const CalcBox = styled(Box)(({ theme }) => ({
  //position: 'relative',
  //border: '1px solid',
  //borderColor: theme.palette.secondary.main,
  //borderRadius: 16,
  //backgroundColor: theme.palette.background.paper,
  color: theme.palette.primary.main,
  marginBottom: 10,
  width: '100%',
}));

export const CalcItemBox = styled(Box)(({ theme }) => ({
  //padding: '8px 16px',
  padding: '10px 0px',
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  '&.CalcItemBox_last': {
    borderBottom: 0,
  },
}));

export const Title = styled(Typography)(() => ({
  fontSize: '0.688rem',
  lineHeight: '22px',
  fontWeight: ThemeFontWeight.regular,
  marginBottom: 8,
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.688rem',
  lineHeight: '13px',
  fontWeight: 400,
  color: theme.palette.secondary.main,
  marginBottom: 8,
}));

export const Value = styled(Typography)(({ theme }) => ({
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: 400,
  color: theme.palette.secondary.main,
  display: 'flex',
  alignItems: 'center',

  span: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginRight: 4,
  },

  svg: {
    marginRight: 4,
    fill: ThemeColors.yellow,
  },
}));

export const Total = styled(Stack)(() => ({
  justifyContent: 'space-between',
  flexDirection: 'row',
  marginBottom: 28,

  '& .MuiTypography-root': {
    fontSize: '1.25rem',
    lineHeight: '24px',
    fontWeight: 500,
  },
}));

export const TotalLabel = styled(Typography)(() => ({
  fontSize: '1.062rem',
  fontWeight: ThemeFontWeight.medium,
}));
