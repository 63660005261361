import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors, ThemeGradients } from 'src/styles';

export const Mining = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'flex-start',
}));

export const MaterialImage = styled('img')(() => ({
  width: 56,
  height: 56,
  borderRadius: '100%',
  border: `1px solid ${ThemeColors.grayLight}`,
  background: ThemeColors.white,
  marginBottom: 4,
}));

export const Progress = styled(Box)(() => ({
  flexBasis: '100%',
  height: 17,
  background: ThemeGradients.bgLight,
  margin: '20px -16px 0',
  position: 'relative',
  zIndex: 1,
}));

export const TextProgress = styled(Typography)(() => ({
  fontSize: '0.688rem',
  lineHeight: '13px',
  textAlign: 'center',
  color: ThemeColors.gray500,
  marginTop: 12,

  span: {
    color: ThemeColors.black,
    marginLeft: 4,
  },
}));

export const Percent = styled(Typography)(() => ({
  position: 'relative',
  fontSize: '0.75rem',
  lineHeight: '17px',
  color: ThemeColors.gray500,
  textAlign: 'center',
  zIndex: 4,
}));

export const ColorLine = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  background: ThemeGradients.bg2,
}));

export const Material = styled(Stack)(() => ({
  flexDirection: 'column',
  width: 70,
  alignItems: 'center',
  zIndex: 3,
}));

export const MaterialName = styled(Typography)(() => ({
  width: 70,
  fontSize: '0.75rem',
  lineHeight: '16px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  textAlign: 'center',
  marginBottom: 2,
}));

export const Price = styled(Typography)(() => ({
  fontSize: '0.688rem',
  lineHeight: '13px',
  textAlign: 'center',
  color: ThemeColors.gray500,
}));
