import { Box, Stack } from '@mui/material';
import { FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Loading, SwitchButtons } from 'src/atoms';
import { ErrorContext } from 'src/context/ErrorContext';
import { BeeSwarm, GoalStatusFilter, InfoTable } from 'src/molecules';
import { FilterRoleList, TTeamMeta, TeamChildrenInList, TeamSwarm, TeamType, UserRole, getTeam } from 'src/services';

import { ClansSwarmProps } from './ClansSwarm.types';

const ClansSwarm: FC<ClansSwarmProps> = ({ userId, sx }) => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);

  const [isLoading, setLoading] = useState(true);

  const [swarmData, setSwarmData] = useState<TeamSwarm>();
  const [meta, setMeta] = useState<TTeamMeta>();

  const [sort, setSort] = useState<keyof TeamChildrenInList>('date_joined_diff');

  const [roleFilter, setRoleFilter] = useState<UserRole[]>(FilterRoleList);

  useEffect(() => {
    setLoading(true);
    getTeam(TeamType.List, {
      playerId: userId,
      role: roleFilter,
    })
      .then(({ data }) => {
        setSwarmData(data as TeamSwarm);
        setMeta(data.meta);
      })
      .catch((error) => {
        if (error.response.status >= 500) throwServerError();
      })
      .finally(() => setLoading(false));
  }, [userId, throwServerError, roleFilter]);

  return (
    <Stack
      className="ClansSwarm"
      sx={{
        position: 'relative',
        ...sx,
        '& .LinearProgress': {
          left: 16,
          right: 16,
          top: 45,
          width: 'auto',
        },
      }}
    >
      {isLoading && <Loading />}
      <SwitchButtons
        buttons={[
          { id: 'date_joined_diff', title: t('team.swarm.byDate') },
          { id: 'all_balance', title: t('team.swarm.byProfit') },
          { id: 'team_number', title: t('team.swarm.byTeam') },
        ]}
        value={sort}
        onClick={(item) => setSort(item.id as keyof TeamChildrenInList)}
      />
      <Box sx={{ width: '100%', flex: 1 }}>
        {swarmData?.children && <BeeSwarm data={swarmData?.children} measure={sort} />}
      </Box>
      <Stack
        sx={{
          flex: 'none',
          width: '100%',
        }}
      >
        <GoalStatusFilter
          sx={{ width: '100%', padding: '10px 16px' }}
          value={roleFilter}
          filter={FilterRoleList}
          tkey="role.state"
          onChange={(role) => setRoleFilter(role as UserRole[])}
        />

        <InfoTable
          sx={{
            borderTop: '1px solid',
            borderColor: 'secondary.main',
            width: '100%',
          }}
          items={[
            {
              title: t('team.meta.team.title'),
              mark: t('team.meta.team.mark'),
              value: meta?.team.team_number,
            },
            {
              title: t('team.meta.private.title'),
              mark: t('team.meta.private.mark'),
              value: meta?.team.personal_team_number,
            },
          ]}
        />
      </Stack>
    </Stack>
  );
};

export default ClansSwarm;
