export type AvatarData = {
  name?: string;
  count?: number;
  config?: any;
  selectedClothes?: {
    type: number;
    clothes: number;
    level: number;
  };
};

export type AvatarState = {
  selectorCount?: number;
  materialCount?: number;
  config?: {
    img: string;
  };
  selectedClothes?: {
    type: number;
    clothes: number;
    level: number;
  };
};

export type AvatarAction = {
  type: 'avatar-selector' | 'avatar-material' | 'avatar-clothes' | 'avatar-camera' | 'avatar-save' | 'close';
  event?: string;
  data?: AvatarData | null;
};

export enum AvatarEditPart {
  material = 'material',
  bagpack = 'bagpack',
  wallet = 'wallet',
  market = 'market',
  background = 'background',
  clothes = 'clothes',
  eyes = 'eyes',
  hair = 'hair',
  skin = 'skin',
}

export type AvatarEditorProps = {
  onClose: (img?: string) => void;
};
