import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

export const Price = styled(Typography)(() => ({
  color: ThemeColors.gray500,
  marginRight: 16,
  fontSize: '1rem',
  lineHeight: '21px',
  fontWeight: 400,
  whiteSpace: 'nowrap',
  width: '33.5%',
}));

export const Type = styled(Typography)(() => ({
  fontSize: '1rem',
  lineHeight: '21px',
  fontWeight: 400,
  marginRight: 16,
  whiteSpace: 'nowrap',
  width: '33.5%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const Name = styled(Typography)(() => ({
  fontSize: '1rem',
  lineHeight: '21px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
}));
