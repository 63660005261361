import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-material-ui-carousel';
import { Navigate } from 'react-router-dom';

import { Loading } from 'src/atoms';
import { Paths, globeTKey } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { PageTitle } from 'src/molecules';
import { Help } from 'src/organisms';
import { setParams } from 'src/services';
import { getInfoUser, selectUserMe } from 'src/store/userSlice';
import { ThemePaddings } from 'src/styles';
import { parseErrorTr } from 'src/utils';

const Study: FC = () => {
  const { t } = useTranslation('app');
  const dispatch = useAppDispatch();
  const userMe = useAppSelector(selectUserMe);

  const [isLoading, setLoading] = useState(false);
  const [step, setStep] = useState<number>(0);

  const { enqueueSnackbar } = useSnackbar();

  const steps = [0, 1, 2, 3, 4];
  const maxStep = steps.length;

  const indicators = steps.map((item) => (
    <Box
      key={`studyStart_indicators_${item}`}
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: '3px',
        background: (theme) => theme.palette.primary.main,
        padding: 0,
      }}
    ></Box>
  ));

  const handlerFinished = useCallback(() => {
    dispatch(getInfoUser());
  }, [dispatch]);

  useEffect(() => {
    if (step === maxStep) {
      setLoading(true);
      setParams({ is_study: true })
        .then(() => {
          handlerFinished();
        })
        .catch((error) => {
          setStep(step - 1);
          enqueueSnackbar(parseErrorTr('setParams', error), { variant: 'error' });
        })
        .finally(() => setLoading(false));
    }
  }, [enqueueSnackbar, handlerFinished, maxStep, step]);

  const handlerNext = () => {
    if (step < maxStep) {
      setStep(step + 1);
    }
  };

  const handlerPrev = () => {
    setStep(step - 1);
  };

  if (userMe?.is_study) {
    return <Navigate to={Paths.dashboard} replace={true} />;
  }

  return (
    <Box sx={{ p: ThemePaddings.page, height: '100%' }}>
      {isLoading ? <Loading /> : null}
      {step < maxStep && (
        <PageTitle
          title={t(`${globeTKey}studyStart.step${step}.title`)}
          subtitle={t(`${globeTKey}studyStart.step${step}.subtitle`)}
          isLoading={isLoading}
        />
      )}
      {step < maxStep && (
        <>
          <Carousel
            navButtonsAlwaysInvisible
            //navButtonsAlwaysVisible
            height="16px"
            animation="slide"
            index={step}
            interval={10000}
            autoPlay={false}
            stopAutoPlayOnHover={true}
            swipe={false}
            cycleNavigation={false}
            IndicatorIcon={indicators}
            indicatorIconButtonProps={{
              style: {
                opacity: 0.2,
                height: 3,
                margin: '0 2px',
                flex: 1,
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                opacity: 1,
                flex: 1,
                height: 3,
              },
            }}
            indicatorContainerProps={{
              style: {
                position: 'absolute',
                top: 0,
                marginTop: 0,
                display: 'flex',
              },
            }}
          >
            {steps.map((step) => (
              <Fragment key={`StudyStep_${step}`}></Fragment>
            ))}
          </Carousel>
          <Help
            sx={{ height: '100%' }}
            key={`${globeTKey}studyStart.step${step}`}
            tkey={`${globeTKey}studyStart.step${step}`}
            onPrev={step > 0 ? handlerPrev : undefined}
            onNext={handlerNext}
          />
        </>
      )}
    </Box>
  );
};

export default Study;
