import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeFontWeight } from 'src/styles';

export const Wrapper = styled(Box)(() => ({
  padding: 2,
  cursor: 'pointer',
  height: '100%',
}));

export const Block = styled(Box)(({ theme }) => ({
  borderRadius: '6px',
  backgroundColor: theme.palette.background.paper,
  overflow: 'hidden',
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const OpacityBlock = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  '&:before': {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0.8,
    width: '100%',
    height: '100%',
    background: theme.palette.background.paper,
    content: '""',
    zIndex: -1,
  },
}));

export const Image = styled('img')(() => ({
  objectFit: 'scale-down',
  width: '100%',
  height: 'auto',
  aspectRatio: '1 / 1',
  display: 'block',
}));

export const Info = styled(Box)(({ theme }) => ({
  padding: '6px 8px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  flex: 'none',
}));

export const Title = styled('div')(({ theme }) => ({
  whiteSpace: 'normal',
  height: '3rem',
  fontSize: '0.812rem',
  lineHeight: '1rem',
  fontWeight: ThemeFontWeight.semiBold,
  color: theme.palette.primary.main,
  width: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': '3',
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.625rem',
  lineHeight: '13px',
  color: theme.palette.primary.main,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export const Row = styled(Stack)(() => ({
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
})) as typeof Stack;

export const Column = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const State = styled(Typography)(({ theme }) => ({
  fontSize: '0.812rem',
  lineHeight: '20px',
  fontWeight: ThemeFontWeight.semiBold,
  color: theme.palette.primary.main,
  textAlign: 'center',
  borderRadius: '30px',
  backgroundColor: theme.palette.background.paper,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  height: '30px',
  border: '1px solid',
  padding: '4px',
}));
