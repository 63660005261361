import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Stack, Step, StepContent, StepIconProps, StepLabel, Stepper, Typography } from '@mui/material';
import { FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CircleLoading, CopyValue, Loading, Markdown } from 'src/atoms';
import { ErrorContext } from 'src/context/ErrorContext';
import { getStateProgress, TTargetStatusHistory } from 'src/services';
import { formatDate, formatTime, logger } from 'src/utils';

import { StepIconRoot } from './GoalStates.styles';
import { GoalItemParamsProps } from './GoalStates.types';

function StepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <StepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <CheckCircleIcon className="StepIcon-completedIcon" />
      ) : active ? (
        <CircleLoading className="StepIcon-activeIcon" isLoading={true} size={18} />
      ) : (
        <RadioButtonUncheckedIcon className="StepIcon-circle" />
      )}
    </StepIconRoot>
  );
}

const GoalStates: FC<GoalItemParamsProps> = ({ compositeId, tkey: tkeyProp, sx }) => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);

  const [isLoading, setLoading] = useState(true);
  const [items, setItems] = useState<TTargetStatusHistory[]>([]);
  const [current, setCurrent] = useState<number>(0);

  const tkey = tkeyProp || '';

  useEffect(() => {
    if (compositeId) {
      setLoading(true);
      getStateProgress(compositeId)
        // getGoalList()
        .then(({ data }) => {
          setItems(data);

          let currentStep: number | null = null;
          const _data = data || [];

          if (_data && _data[_data.length - 1] && _data[_data.length - 1].state === true) {
            currentStep = _data.length;
          } else {
            (_data || []).forEach((item, idx) => {
              if (currentStep === null && item.state !== true && item.state !== false) {
                currentStep = idx;
              }
            });
          }

          setCurrent(currentStep || 0);
        })
        .catch((error) => {
          if (error.response?.status >= 500) throwServerError();
          logger.error(error);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
      setItems([]);
    }
  }, [compositeId, throwServerError]);

  return (
    <Box sx={{ position: 'relative', '& .MuiLinearProgress-root': { top: '-8px' } }}>
      {isLoading && <Loading />}
      <Stepper activeStep={current} orientation="vertical" connector={null}>
        {items.map((step) => (
          <Step key={step.id} expanded={true}>
            <StepLabel
              sx={{
                padding: '1px 0',
                '& .MuiStepLabel-label': {
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '0.812rem',
                  paddingLeft: '2px',
                  color: 'primary.main',
                },
              }}
              StepIconComponent={StepIcon}
            >
              {t(tkey + step.title)}
            </StepLabel>
            <StepContent>
              <Stack sx={{ fontStyle: 'normal', fontSize: '0.75rem', color: 'secondary.main' }}>
                {step.udt && <span>{`${formatTime(step.udt)} ${formatDate(step.udt)}`}</span>}
                <Markdown>{t(tkey + step.description)}</Markdown>
              </Stack>
              {step.links && (
                <Stack>
                  {step.links?.map((link) => (
                    <Stack direction="row">
                      <Typography sx={{ fontSize: '0.75rem' }}>{t(tkey + link.label)}</Typography>
                      {link.value && <CopyValue value={link.value} link={link.link} />}
                    </Stack>
                  ))}
                </Stack>
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default GoalStates;
