import { Google } from '@mui/icons-material';
import { Button } from '@mui/material';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getApiUrl } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { TGoogleUser, addUserSN } from 'src/services';
import { selectUser, signInUser } from 'src/store/userSlice';
import { getLocale, parseErrorTr } from 'src/utils';

import { GoogleLoginProps } from './GoogleLogin.types';

const GoogleLoginButton: FC<GoogleLoginProps> = ({ referral, isReg, connection, onFinish }) => {
  const dispatch = useAppDispatch();
  const { isShowAuth } = useAppSelector(selectUser);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('app');
  const page = isReg ? 'registration' : connection ? 'connection' : 'signin';

  const login = useGoogleLogin({
    onSuccess: (resp: any) => {
      const axAgent = axios.create({
        baseURL: getApiUrl(),
        headers: {
          'content-type': 'application/json',
          'Accept-Language': getLocale(),
        },
      });

      axAgent
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          // .GET('https://www.googleapis.com/auth/youtube', {
          headers: { Authorization: `Bearer ${resp.access_token}` },
        })
        .then(({ data: userInfo }) => {
          handleTelegramResponse({
            userInfo,
            ...resp,
          });
        })
        .catch((error) => {
          enqueueSnackbar(parseErrorTr(`google_${page}`, error), { variant: 'error' });
        });
    },
    scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/youtube',
    flow: 'implicit',
  });

  const handleTelegramResponse = (user: TGoogleUser) => {
    if (connection) {
      addUserSN('google', user)
        .then(() => onFinish && onFinish())
        .catch((error) => {
          enqueueSnackbar(parseErrorTr(`google_${page}`, error), { variant: 'error' });
        });
    } else {
      dispatch(
        signInUser({
          provider: 'google',
          authData: user,
          referral_uuid: referral,
        })
      );
    }
  };

  return (
    <Button
      data-qa={`signIn.${page}.google.title`}
      variant="text"
      onClick={() => login()}
      disabled={isShowAuth}
      fullWidth
      sx={{ '& svg': { mr: '4px' } }}
    >
      <Google /> {t(`signIn.${page}.google.title`)}
    </Button>
  );
};

const googleClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const GooogleLogin: FC<GoogleLoginProps> = ({ referral, isReg, connection, onFinish }) => (
  <>
    {googleClientID ? (
      <GoogleOAuthProvider clientId={googleClientID}>
        <GoogleLoginButton referral={referral} isReg={isReg} connection={connection} onFinish={onFinish} />
      </GoogleOAuthProvider>
    ) : null}
  </>
);

export default GooogleLogin;
