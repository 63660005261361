import { Box, IconButton, Typography } from '@mui/material';
import { FC, useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { ClanTreeIcon } from 'src/assets/icons';
import { ClanActivation, HistoryItem, LevelSwitcher } from 'src/atoms';
import { LIMIT_CLANS, Paths, pathsArg } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { DemoClans, DrawerShareText, HistoryDetails, PageTitle } from 'src/molecules';
import SelectedTriangle from 'src/molecules/SelectedTriangle';
import { ClanListItem, getClansList, getClansListToBuy } from 'src/services';
import { selectClanState, setClanLevels, setCurrentLevel, setSelectedClan } from 'src/store/clanSlice';
import { selectDemoData } from 'src/store/demoSlice';
import { selectUserMe } from 'src/store/userSlice';
import { LEVELS_TO_COLORS, ThemePaddings } from 'src/styles';
import { Levels, RouterStateStructure, Sizes } from 'src/types';
import { logger } from 'src/utils';

const Structure: FC = () => {
  const { t } = useTranslation('app');
  const { is_demo } = useAppSelector(selectDemoData);
  const userMe = useAppSelector(selectUserMe);
  const { activeClan } = useAppSelector(selectClanState);
  const navigate = useNavigate();
  const { throwServerError } = useContext(ErrorContext);
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const levelState = (state as RouterStateStructure)?.level;

  const onCloseHandler = () => navigate(Paths.dashboard);

  const onStructureTreeHandler = () => {
    navigate(Paths.structureTree, { state: { level } });
  };

  const scrollRef = useRef<List<any> | null>();

  const [isLoading, setLoading] = useState(true);
  const [level, setLevel] = useState(levelState || Levels.First);
  const [clanList, setClanList] = useState<ClanListItem[] | null>(null);
  const [closedLevels, setClosedLevels] = useState<number[]>([]);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [isOpenShare, setOpenShare] = useState(false);
  const [trianglePaymentClan, setTrianglePaymentClan] = useState<ClanListItem>();

  const onOpenPaymentsHandler = (clanId: number) => {
    const trianglePayment = clanList && clanList.find((clan) => clan.id === clanId);
    trianglePayment && setTrianglePaymentClan(trianglePayment);
    setOpen(true);
  };
  const onClosePaymentsHandler = () => setOpen(false);

  const onChangeLevel = (changeLevel: Levels) => {
    setLevel(changeLevel);
    setOffset(0);

    scrollRef.current?.scrollTo(0);
  };

  const onClanActivation = () => {
    navigate(pathsArg(Paths.shoppingCart, { itemId: level }));
  };

  const onClickMemberHandler = (userId?: number) => {
    if (userId) navigate(`${Paths.profile}/${userId}`);
    else setOpenShare(true);
  };

  useEffect(
    () => () => {
      setClanList(null);
      setCount(0);
      setOffset(0);
    },
    []
  );

  useEffect(() => {
    if (!offset) {
      setLoading(true);
      getClansList(level, { offset, limit: LIMIT_CLANS })
        .then(({ data }) => {
          setClanList(data.results);
          dispatch(setSelectedClan(data.results[0]));
          setClosedLevels(data.closed_levels);
          setCount(data.count);
          setOffset(LIMIT_CLANS);
        })
        .catch((error) => {
          if (error.response?.status >= 500) throwServerError();
          logger.error(error);
        })
        .finally(() => setLoading(false));
    }
  }, [offset, level, throwServerError, dispatch]);

  useEffect(() => {
    dispatch(setCurrentLevel(level));
    getClansListToBuy()
      .then(({ data }) => {
        dispatch(setClanLevels(data));
      })
      .catch((error) => {
        dispatch(setClanLevels(null));
        logger.error(error);
      });
  }, [level, dispatch]);

  const isItemLoaded = (index: number) => (clanList?.length ? !!clanList[index] : false);

  const loadMoreItems = () => {
    if (offset >= count) {
      getClansList(level, { offset, limit: LIMIT_CLANS })
        .then(({ data }) => {
          clanList && setClanList([...clanList, ...data.results]);
          setOffset((prevOffset) => prevOffset + LIMIT_CLANS);
        })
        .catch((error) => {
          if (error.response?.status >= 500) throwServerError();
          logger.error(error);
        });
    }
  };

  return (
    <>
      <PageTitle
        title={t('structureClan.title')}
        data-qa={'structureClan.title'}
        help={{ tkey: 'structureClan' }}
        onClose={onCloseHandler}
        isLoading={isLoading}
      />
      <Box
        sx={{
          position: 'fixed',
          top: 100,
          left: 0,
          right: 0,
          zIndex: 100,
          backgroundColor: (theme) => theme.palette.background.default,
          height: 60,
        }}
      >
        <LevelSwitcher level={level} filled={closedLevels} onChangeLevel={onChangeLevel} />
      </Box>
      <Box sx={{ p: ThemePaddings.structure, position: 'relative' }}>
        {!isLoading &&
          (clanList?.length ? (
            <>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 160,
                  right: 16,
                  color: LEVELS_TO_COLORS[level - 1],
                  zIndex: 3,
                  svg: { fill: 'currentColor' },
                }}
                onClick={onStructureTreeHandler}
              >
                <ClanTreeIcon />
              </IconButton>

              <SelectedTriangle size={Sizes.Large} level={level} onClickMember={onClickMemberHandler} />

              {is_demo && activeClan?.status && (
                <DemoClans
                  setClanList={setClanList}
                  setClosedLevels={setClosedLevels}
                  setOffset={setOffset}
                  setLevel={setLevel}
                  currentStatus={activeClan.status}
                />
              )}

              <Typography
                sx={{
                  fontSize: '1.75rem',
                  lineHeight: '34px',
                  fontWeight: 600,
                  my: '16px',
                  justifyContent: 'center',
                  display: 'flex',
                }}
                variant="h1"
                component="p"
              >
                {t('structure.clanHistoryTitle')}
              </Typography>

              <Box
                sx={{
                  position: 'relative',
                  width: '100%',

                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    height: 14,
                    background: (theme) =>
                      `linear-gradient(180deg, rgba(251,251,253,0) 0%, ${theme.palette.background.default} 100%)`,
                    zIndex: 2,
                  },
                }}
              >
                <InfiniteLoader
                  isItemLoaded={isItemLoaded}
                  itemCount={count}
                  loadMoreItems={loadMoreItems}
                  threshold={LIMIT_CLANS}
                >
                  {({ onItemsRendered, ref }) => (
                    <List
                      height={count * 93}
                      width={'100%'}
                      itemCount={count}
                      itemSize={93}
                      onItemsRendered={onItemsRendered}
                      ref={(listElement) => {
                        ref(listElement);
                        scrollRef.current = listElement;
                      }}
                    >
                      {({ index, style }) => {
                        const clanItem = clanList[index];

                        return (
                          <div style={style}>
                            {clanItem && (
                              <HistoryItem
                                clanItem={clanItem}
                                level={level}
                                onPaymentsOpen={() => onOpenPaymentsHandler(clanItem.id)}
                              />
                            )}
                          </div>
                        );
                      }}
                    </List>
                  )}
                </InfiniteLoader>
              </Box>
            </>
          ) : (
            <ClanActivation level={level} onClanActivation={onClanActivation} />
          ))}
      </Box>

      {trianglePaymentClan && (
        <HistoryDetails
          isOpen={isOpen}
          onClose={onClosePaymentsHandler}
          level={level}
          triangle={trianglePaymentClan}
          onClickMember={onClickMemberHandler}
        />
      )}

      {userMe && (
        <DrawerShareText
          title={userMe?.display_name || t('onboarding.unknownName')}
          link={userMe?.referral_link || ''}
          isOpen={isOpenShare}
          tKey="referal"
          onClose={() => setOpenShare(false)}
        />
      )}
    </>
  );
};

export default Structure;
