import { Box, Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Markdown } from 'src/atoms';
import { Paths, globeTKey } from 'src/constants';
import { useAppSelector } from 'src/hooks';
import { WalletConnect, TelegramLogin, PageTitle, GoogleLogin, InstagramLogin } from 'src/molecules';
import { Hello } from 'src/organisms';
import { selectUser } from 'src/store/userSlice';
import { ThemePaddings } from 'src/styles';
import { isReferralUuid, walletProviders } from 'src/types';

const googleClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const instagramAppId = process.env.REACT_APP_INSTAGRAM_APP_ID;

const SignIn: FC<{ allowReg?: boolean }> = ({ allowReg }) => {
  const { t } = useTranslation('app');
  const { state } = useLocation();
  const locationState = state && isReferralUuid(state) ? state : null;
  const referral = locationState ? locationState.referral : undefined;
  const [reg, setReg] = useState<boolean>(!!referral);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { authError } = useAppSelector(selectUser);

  useEffect(() => {
    if (authError) {
      enqueueSnackbar(authError, { variant: 'error' });
    }
  }, [authError, enqueueSnackbar]);

  const page = reg ? 'registration' : 'signin';

  return (
    <Hello>
      <Box sx={{ p: ThemePaddings.page }}>
        <PageTitle
          data-qa={`${globeTKey}signIn.title.${page}`}
          title={t(`${globeTKey}signIn.title.${page}`)}
          help={{ tkey: globeTKey + page }}
          onClose={() => navigate(Paths.home)}
        />
        <Markdown>{t(`${globeTKey}signIn.title.${page}.descriptionMD`)}</Markdown>
        <Box sx={{ mt: 1 }}>
          <TelegramLogin referral={referral} isReg={reg} />
        </Box>
        {googleClientID && (
          <Box sx={{ mt: 1 }}>
            <GoogleLogin referral={referral} isReg={reg} />
          </Box>
        )}
        {instagramAppId && (
          <Box sx={{ mt: 1 }}>
            <InstagramLogin referral={referral} isReg={reg} />
          </Box>
        )}
        {!reg && (
          <>
            <Typography sx={{ mt: '16px', mb: '8px' }}>{t(`${globeTKey}signIn.${page}.walletConnect`)}</Typography>
            <WalletConnect referral={referral} isReg={reg} walletProvider={walletProviders.TrustWallet} />
          </>
        )}
        {referral || allowReg ? (
          <Button
            data-qa={`${globeTKey}signIn.button.${!reg ? 'registration' : 'signin'}`}
            sx={{ mt: '16px', border: 'none' }}
            variant="text"
            fullWidth
            onClick={() => setReg(!reg)}
          >
            {t(`${globeTKey}signIn.button.${!reg ? 'registration' : 'signin'}`)}
          </Button>
        ) : (
          !reg && <Markdown>{t(`${globeTKey}signIn.title.${page}.noReferalMD`)}</Markdown>
        )}
      </Box>
    </Hello>
  );
};

export default SignIn;
