import { AxiosResponse } from 'axios';

import agent from 'src/agent';

import { TSpecialOfferItem } from './SpecialOfferService.types';

export const getPackages = (): Promise<AxiosResponse<TSpecialOfferItem[]>> => {
  return agent.GET('packages/list', { params: {} });
};

export const getPackage = (id: number): Promise<AxiosResponse<TSpecialOfferItem>> => {
  return agent.GET(`packages/read/${id}`, {});
};
