import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, IconButton, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { ReactI18NextChild } from 'react-i18next';

import { VideoPlayer, WindowTitle } from 'src/molecules';
import { ThemeColors } from 'src/styles';

import DrawerBottom from '../DrawerBottom';

const HomeBlock: FC<{
  bgImg: string;
  openImg?: string;
  bgType?: string;
  ratio?: number;
  contrast?: boolean;
  position: string;
  className?: string;
  onClick?: () => void;
  children: ReactI18NextChild;
}> = ({ bgImg, openImg, bgType, position, className, contrast, ratio, onClick, children }) => {
  const [isFullOpen, setFullOpen] = useState<string | null>(null);

  const handlerPlayFull = () => {
    setFullOpen(openImg || null);
  };

  const positionChild =
    position === 'top'
      ? {
          top: '16px',
          height: '-webkit-fill-available',
        }
      : {
          bottom: '16px',
        };
  return (
    <>
      <Box
        onClick={(event: any) => {
          event.stopPropagation();
          if (onClick) onClick();
        }}
        className={className}
        sx={{
          position: 'relative',
          minHeight: '250px',
          lineHeight: 0,

          '&.HomeBlock_height_1': {
            minHeight: '1px',
          },
        }}
      >
        {bgType === 'video' ? (
          <VideoPlayer
            sx={{
              position: 'relative',
              top: 0,
              left: 0,
              right: 0,
              '& video': {
                background: 'none',
                border: 'none',
                borderRadius: 0,
              },
            }}
            ratio={ratio || 0.810506566604128}
            url={bgImg}
            config={{
              controls: false,
              playing: true,
              muted: true,
              value: 0,
              loop: true,
            }}
          />
        ) : (
          <Box component="img" src={bgImg} alt="" sx={{ width: '100%' }} />
        )}
        <Stack
          alignItems="center"
          sx={{
            p: '1rem',
            width: '100%',
            position: 'absolute',
            zIndex: 0,
            color: contrast ? 'primary.contrastText' : 'primary.main',
            textAlign: 'center',
            lineHeight: '1.5rem',
            left: 0,
            ' a': {
              color: ThemeColors.linkBlue,
              textTransform: 'none',
              textDecoration: 'none',
            },
            ' p': {
              my: '0.5rem',
            },
            ' h1': {
              my: '0.67rem',
            },
            ...positionChild,
          }}
        >
          {children}
        </Stack>
        {openImg && (
          <Box sx={{ position: 'absolute', right: '1rem', bottom: '1rem' }}>
            <IconButton
              onClick={handlerPlayFull}
              sx={{
                p: 0,
                width: '44px',
                height: '44px',
                borderRadius: '50%',
                border: '1px solid',
                borderColor: 'primary.main',
                backgroundColor: 'background.default',
                '&:hover': {
                  opacity: 0.8,
                  backgroundColor: 'background.paper',
                },
              }}
            >
              <PlayArrowIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <DrawerBottom isOpen={!!isFullOpen} onClose={() => setFullOpen(null)}>
        <>
          <WindowTitle data-qa="homeBlock.fullVideo.title" title="" onClose={() => setFullOpen(null)} />
          {isFullOpen && (
            <VideoPlayer
              url={isFullOpen}
              sx={{
                mb: '16',
                '& video': {
                  borderRadius: '16px',
                  backgroundColor: 'background.paper',
                },
              }}
              config={{
                playing: true,
                muted: false,
                loop: true,
                fullscreen: true,
              }}
            />
          )}
        </>
      </DrawerBottom>
    </>
  );
};

export default HomeBlock;
