import { Box, Stack, Button, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NoPhoto } from 'src/assets/images';
import { ImgProgressbar, CircleColor, BPrice } from 'src/atoms';
import { Paths, getImgSrc } from 'src/constants';
import { TTargetListItemAttributes } from 'src/services';

import ImageWidget from '../ImageWidget';

import { ProductWidgetBox, Title, Subtitle, PriceBlock, EmptyTitle, EmptyImage } from './ProductWidget.styles';
import { ProductWidgetProps } from './ProductWidget.types';

const ProductWidget: FC<ProductWidgetProps> = ({
  item,
  cost,
  balance,
  showActions,
  progress,
  height,
  readonly,
  isMe,
  sx,
}) => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const [color, setColor] = useState<TTargetListItemAttributes | undefined>();
  const [size, setSize] = useState<TTargetListItemAttributes | undefined>();
  const [memory, setMemory] = useState<TTargetListItemAttributes | undefined>();

  const _navigate = !readonly ? navigate : () => false;
  const ifFinish = (progress || 0) === 100 && !readonly;

  const onClickHandler = () => {
    _navigate(Paths.goal);
  };

  const onClickEmptyHandler = () => {
    _navigate(Paths.goalList);
  };

  const _height = height || 200;
  const _heightOffset = 80;

  useEffect(() => {
    item?.attributes?.attributes?.forEach((attr) => {
      switch (attr.group) {
        case 'color':
          setColor(attr);
          break;
        case 'memory':
          setMemory(attr);
          break;
        case 'size':
          setSize(attr);
          break;
      }
    });
  }, [item]);

  if (!item || (item && !item.composite_id)) {
    return (
      <>
        <ProductWidgetBox onClick={onClickEmptyHandler} sx={{ height: `${_height}px`, ...sx }}>
          <ImageWidget
            onClick={onClickHandler}
            sx={{ height: `${_height}px` }}
            value={
              <EmptyTitle component="div">
                {t(isMe ? 'productWidget.emptyGoal' : 'productWidget.emptyGoal.user')}
              </EmptyTitle>
            }
          >
            <EmptyImage
              data-qa="productWidget.emptyGoal.img"
              src={getImgSrc(t('productWidget.emptyGoal.img'), 's600')}
            />
          </ImageWidget>
        </ProductWidgetBox>
      </>
    );
  }

  return (
    <>
      <ProductWidgetBox onClick={onClickHandler} sx={sx} direction="column" width="100%" className="ProductWidget">
        <Box sx={{ padding: '16px 16px 8px', position: 'relative' }}>
          <Stack
            direction="row"
            width="100%"
            justifyContent="space-between"
            spacing={2}
            sx={{ filter: ifFinish ? 'blur(5px)' : 'none' }}
          >
            <ImgProgressbar
              percent={progress || 0}
              height={_height - _heightOffset}
              sx={{ width: '160px', flexShrink: 0 }}
            >
              <Box
                component="img"
                src={item.photo_uuid ? getImgSrc(item.photo_uuid, 'w600-p') : NoPhoto}
                alt={item.name}
                sx={{
                  position: 'absolute',
                  right: 0,
                  height: `${_height - _heightOffset}px`,
                  width: '100%',
                  objectFit: 'cover',
                }}
              />
            </ImgProgressbar>
            <Stack
              direction="column"
              width="100%"
              justifyContent="space-between"
              spacing={1}
              sx={{ height: `${_height - _heightOffset}px`, overflow: 'hidden' }}
            >
              <Title sx={{ whiteSpace: 'nowrap', minHeight: '28px' }}>{item.name}</Title>
              <Title sx={{ whiteSpace: 'nowrap', minHeight: '28px' }}>{size?.title}</Title>
              <Title sx={{ whiteSpace: 'nowrap', minHeight: '28px' }}>{memory?.title}</Title>
              <Stack direction="row" width="100%" justifyContent="center" alignItems="center" spacing={1}>
                {color && <CircleColor color={color?.value} sx={{ flexShrink: 0 }} />}
                <Subtitle sx={{ whiteSpace: 'nowrap' }}>{color?.title}</Subtitle>
              </Stack>
              {cost && (
                <PriceBlock>
                  <Subtitle>{t('productWidget.cost')}:</Subtitle>
                  <BPrice price={cost} />
                </PriceBlock>
              )}
            </Stack>
          </Stack>
          {ifFinish ? (
            <Stack
              justifyContent="center"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                // backgroundColor: 'background.paper',
                zIndex: 5,
                p: '16px',
                pointerEvents: 'none',
              }}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ backgroundColor: 'background.paper', borderRadius: '10px', p: '16px', mb: '16px' }}
              >
                <Typography variant="h2grade">{t('productWidget.get.title')}</Typography>
                <Typography>{t('productWidget.get.description')}</Typography>
              </Stack>
              <Button
                sx={{ pointerEvents: 'auto' }}
                variant="outlined"
                onClick={(event) => {
                  event.stopPropagation();
                  _navigate(Paths.getTarget);
                }}
              >
                {t('productWidget.get.button')}
              </Button>
            </Stack>
          ) : null}
        </Box>
        <Box sx={{ backgroundColor: 'background.darker', padding: '4px 16px 8px' }}>
          {balance && (
            <Stack direction="column" width="100%">
              <Subtitle sx={{ color: 'secondary.main' }}>{t('productWidget.contractCost')}:</Subtitle>
              <BPrice
                sx={{
                  whiteSpace: 'nowrap',
                  backgroundColor: 'background.default',
                  padding: '4px',
                  mt: '2px',
                  borderRadius: '3px',
                  textAlign: 'center',
                }}
                price={balance}
              />
            </Stack>
          )}
          {showActions && (
            <Stack direction="row" width="100%" justifyContent="space-between" spacing={1} sx={{ mt: '8px' }}>
              <Button
                variant="text"
                fullWidth
                sx={{ border: 'none' }}
                onClick={(event) => {
                  event.stopPropagation();
                  _navigate(Paths.getMoney);
                }}
              >
                {t('productWidget.getMoney')}
              </Button>
              <Button
                variant="text"
                fullWidth
                sx={{ border: 'none' }}
                onClick={(event) => {
                  event.stopPropagation();
                  _navigate(Paths.goalList);
                }}
              >
                {t('productWidget.changeGoal')}
              </Button>
            </Stack>
          )}
        </Box>
      </ProductWidgetBox>
    </>
  );
};

export default ProductWidget;
