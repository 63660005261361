import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors, ThemeGradients } from 'src/styles';

export const Wrapper = styled(Box)(() => ({
  position: 'relative',
  border: '3px solid transparent',
  backgroundClip: 'padding-box',
  borderRadius: 16,
  backgroundColor: ThemeColors.white,
  marginTop: 16,

  '&:after': {
    position: 'absolute',
    top: -3,
    left: -3,
    right: -3,
    bottom: -3,
    background: ThemeGradients.bg,
    content: '""',
    zIndex: -1,
    borderRadius: 16,
  },
}));

export const Title = styled(Typography)(() => ({
  borderRadius: '13px 13px 0 0',
  backgroundColor: ThemeColors.black,
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: 600,
  textTransform: 'uppercase',
  color: ThemeColors.white,
  textAlign: 'center',
  padding: '10px 0',
}));

export const MiningBox = styled(Box)(() => ({
  padding: 16,
  borderTop: `1px solid ${ThemeColors.grayLight}`,
}));

export const MiningBoxTitle = styled(Typography)(() => ({
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: 600,
  textAlign: 'center',
  marginBottom: 12,
}));
