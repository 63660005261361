import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useForm, SubmitHandler, Controller, Resolver } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AnyObjectSchema } from 'yup';

import { InputField, CheckBoxInput, DrawerBottom, Markdown } from 'src/atoms';
import { Paths, getImgSrc } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { getDemoUserData, setDemoUserData } from 'src/local-storages';
import { StepTitle, WindowTitle } from 'src/molecules';
import { UploadAvatar } from 'src/organisms';
import { setUserInfo } from 'src/services';
import { selectDemoData } from 'src/store/demoSlice';
import { getInfoUser, selectUserMe } from 'src/store/userSlice';

import { schema } from './RegForm.constants';
import { RegFormValues } from './RegForm.types';

const RegForm: FC = () => {
  const { t } = useTranslation('app');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { is_demo } = useAppSelector(selectDemoData);
  const userMe = useAppSelector(selectUserMe);
  const demoUserData = getDemoUserData();

  const [isOpen, setOpen] = useState<'rule' | 'offerta' | undefined>();

  const { handleSubmit, control, setValue } = useForm<RegFormValues>({
    resolver: yupResolver(schema as AnyObjectSchema) as Resolver<RegFormValues, object>,
    mode: 'onChange',
    defaultValues: {
      display_name: userMe?.display_name,
      has_photo: !!userMe?.photo_uuid,
    },
  });

  const onSubmit: SubmitHandler<RegFormValues> = (data) => {
    if (!data.photo) delete data.photo;

    setUserInfo(data)
      .then(({ data: responseData }) => {
        if (is_demo && !demoUserData) {
          setDemoUserData({
            gender: responseData.gender,
            displayName: responseData.display_name,
            birthday: responseData.birthday,
            email: responseData.email,
            photo: responseData.photo,
          });
        }
        dispatch(getInfoUser());
        navigate(Paths.dashboard, { replace: true });
      })
      .catch(({ message }) => {
        enqueueSnackbar(message, { variant: 'error' });
      });
  };

  const socialAuth = userMe?.social?.find((sn) => sn.is_default);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StepTitle step={1} title={t('reg.step1.title')} subTitle={t('reg.step1.subtitle')} />

        <Controller
          name="photo"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <UploadAvatar
                defaultValue={userMe?.photo_uuid ? getImgSrc(userMe.photo_uuid, 's150-c') : undefined}
                value={value}
                onChange={(file) => {
                  if (file) {
                    setValue('has_photo', true);
                  }
                  onChange(file);
                }}
                hasError={!!error}
                textError={error && error.message}
              />
            );
          }}
        />
        <Controller
          name="has_photo"
          control={control}
          render={({ fieldState: { error } }) => {
            return (
              <>
                {error && error.message ? (
                  <Typography textAlign="center" color="error">
                    {t(error.message)}
                  </Typography>
                ) : null}
              </>
            );
          }}
        />

        <Divider variant="fullWidth" sx={{ my: '24px' }} />
        {/*
        <StepTitle step={2} title={t('reg.step2.title')} subTitle={t('reg.step2.subtitle')} />
        <SelectField
          name="gender"
          control={control}
          placeholder={t('placeholder.gender')}
          options={getGenderOptions()}
        />
        <Divider variant="fullWidth" sx={{ my: '32px' }} />*/}

        <StepTitle step={2} title={t('reg.step2.title')} subTitle={t('reg.step2.subtitle')} />
        <InputField name="display_name" control={control} placeholder={t('placeholder.displayName')} maxlength={100} />

        <Divider variant="fullWidth" sx={{ my: '24px' }} />

        {socialAuth && (
          <>
            <StepTitle
              step={3}
              title={t(`reg.step3.title.${socialAuth?.icon}`)}
              subTitle={t(`reg.step3.subtitle.${socialAuth?.icon}`)}
            />
            <Typography sx={{ backgroundColor: 'background.darker', borderRadius: '5px', p: '12px 16px' }}>
              {socialAuth?.account}
            </Typography>

            <Divider variant="fullWidth" sx={{ mt: '24px', mb: '8px' }} />
          </>
        )}

        {/*

        <StepTitle step={4} title={t('reg.step4.title')} subTitle={t('reg.step4.subtitle')} />
        <MobileDatePickerField name="birthday" control={control} placeholder={t('placeholder.birthday')} />
        <Divider variant="fullWidth" sx={{ my: '32px' }} />

        <StepTitle step={5} title={t('reg.step5.title')} subTitle={t('reg.step5.subtitle')} />
        <InputField name="email" control={control} placeholder="you@mail.ru" />
      <Divider variant="fullWidth" sx={{ my: '32px' }} />*/}
        <CheckBoxInput
          name="yes_offerta"
          control={control}
          label={
            <Typography>
              {t('reg.approve.offerta')}{' '}
              <Typography component="span" sx={{ textDecoration: 'underline' }} onClick={() => setOpen('offerta')}>
                {t('reg.approve.offerta.link')}
              </Typography>
            </Typography>
          }
        />
        <CheckBoxInput
          name="yes_rule"
          control={control}
          label={
            <Typography>
              {t('reg.approve.rule')}{' '}
              <Typography component="span" sx={{ textDecoration: 'underline' }} onClick={() => setOpen('rule')}>
                {t('reg.approve.rule.link')}
              </Typography>
            </Typography>
          }
        />

        <Button variant="outlined" sx={{ width: '100%', mt: '10px' }} type="submit">
          {t('button.reg')}
        </Button>
      </form>

      <DrawerBottom
        isOpen={!!isOpen}
        onClose={() => setOpen(undefined)}
        sx={{ height: '100%', '& .DrawerBottom_styled': { border: 'none' }, '& .MuiDrawer-paper': { height: '100%' } }}
      >
        {isOpen ? (
          <>
            <WindowTitle title={t(`reg.${isOpen}.title`)} onClose={() => setOpen(undefined)} />
            <Box
              sx={{
                overflowX: 'scroll',
                height: '100%',
                width: '100%',
              }}
            >
              <Markdown>{t(`reg.${isOpen}MD`)}</Markdown>
            </Box>
          </>
        ) : null}
      </DrawerBottom>
    </>
  );
};

export default RegForm;
