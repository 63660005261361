import { Box, Button, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerStyled, DrawerBoxStyled } from './DrawerChooser.styles';
import { DrawerChooserProps } from './DrawerChooser.types';

const DrawerChooser: FC<DrawerChooserProps> = ({
  titleComponent,
  children,
  isOpen,
  onClose,
  onApply,
  isChanged = false,
}) => {
  const { t } = useTranslation('app');

  return (
    <DrawerStyled anchor="bottom" open={isOpen} onClose={onClose} variant="persistent">
      <DrawerBoxStyled role="presentation">
        <Stack direction="row" justifyContent="space-between" sx={{ mr: 0.5, ml: 2 }}>
          {titleComponent}
          {isChanged && (
            <Button variant="contained" aria-label="close" onClick={onApply} sx={{ height: '30px' }}>
              {t('button.apply')}
            </Button>
          )}
        </Stack>
        <Box sx={{ mt: 0.5 }}>{children}</Box>
      </DrawerBoxStyled>
    </DrawerStyled>
  );
};

export default DrawerChooser;
