import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BNBIcon, USDTIcon, ChipIcon } from 'src/assets/icons';
import { BPrice } from 'src/atoms';
import { copyToClipboard } from 'src/utils';

import {
  CardWrapper,
  Title,
  CardHead,
  BinanceSmartChain,
  MetaMask,
  MetaMaskTitle,
  WalletAddress,
  BalanceText,
  WalletBalance,
} from './Card.styles';
import { CardProps } from './Card.types';

const Card: FC<CardProps> = ({ color, sx, tkey, address, balance }) => {
  const { t } = useTranslation('app');
  const { enqueueSnackbar } = useSnackbar();

  const onCopyHandler = () => {
    copyToClipboard(address);
    enqueueSnackbar(t('button.copied'), { variant: 'info' });
  };

  return (
    <CardWrapper sx={sx} data-qa="wallet.card">
      <CardHead>
        <div>
          <Title sx={{ color: color }}>{t(`${tkey}.cardTitle`)}</Title>
          <ChipIcon style={{ color: color }} />
        </div>
        <BinanceSmartChain>
          {balance &&
            (balance.bcurrency.toLocaleLowerCase() === 'usdt' ? (
              <USDTIcon />
            ) : balance.bcurrency.toLocaleLowerCase() === 'bnb' ? (
              <BNBIcon />
            ) : null)}
          {t(`${tkey}.yourAddress`) === `${tkey}.yourAddress` ? null : <p>{t(`${tkey}.yourAddress`)}</p>}
        </BinanceSmartChain>
      </CardHead>
      <MetaMask>
        <div>
          <MetaMaskTitle sx={{ color: color }}>{t('wallet.yourAddress')}:</MetaMaskTitle>
          <WalletAddress>{address || ' '}</WalletAddress>
        </div>
        <IconButton onClick={onCopyHandler} sx={{ marginBottom: '-10px' }}>
          <ContentCopyIcon sx={{ width: 15, height: 18 }} />
        </IconButton>
      </MetaMask>
      <WalletBalance>
        <BalanceText>{t('wallet.balance')}</BalanceText>
        {balance ? (
          <BPrice
            sx={{
              '& .BPrice_icon': {
                display: 'none',
              },
              '& .BPrice_bcurrency': {
                color: color,
              },
            }}
            price={balance}
          />
        ) : null}
      </WalletBalance>
    </CardWrapper>
  );
};

export default Card;
