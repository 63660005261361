// export const registerAddr = '0x37Adf4F189622375bE136C8Cd1bF99cE542Fe1C';
export const tokenAddr = '0xc11db48009e1a03aa65c5457d87ccb3cef7f640d';
// export const tokenAddr = '0x41C8989fEc21168A182201766791Bb6afa796236';
//export const registerAddr = '0x41C8989fEc21168A182201766791Bb6afa796236';
// export const tokenAddr = '0x69D31373d5DBC11e04fe224ed6EDf3E6d039D78A';
//export const tokenAddr = '0x15D6df1694BCC06CC20aad20EbE6Bba737CE4dFa';

export const registerAddr = '0x1A26d803C2e796601794f8C5609549643832702C';
//export const tokenAddr = '0xD26547AD6a46a6274E6ba39129d08504Dd546AD3';

export enum StorageKeys {
  LANG = 'lang',
  LANG_PREFIX = 'lang_pref',
  THEME = 'theme',
  CENSOR = 'censor',
  SHOW_LANG = 'isShowLang',
  TOKEN = 'token',
  DEMO_USER_DATA = 'demoUserData',
  SEARCH_MARKET = 'searchMarket',
  CATEGORY_MARKET = 'categoryMarket',
}

export const MAX_AVATAR_SIZE = 600;

export const MAPBOX_TOKEN = 'pk.eyJ1Ijoia3N1cGlwciIsImEiOiJja3lyMXIzdjYwcGp4MnZwN2JsOXYxajlmIn0.-9tRqj5xy4kTqQne1DruDg';
