import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeFontWeight } from 'src/styles';

export const ProductListWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  margin: '0 0px 16px',
}));

export const ProductBox = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.secondary.main}`,
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  padding: '8px 16px',
}));

export const Product = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  '&.ProductList_product__disabled': {
    opacity: 0.5,
  },
}));

export const ProductImage = styled(Box)(({ theme }) => ({
  width: 74,
  height: 74,
  position: 'relative',
  marginRight: 9,

  img: {
    background: theme.palette.primary.main,
    display: 'block',
    width: 72,
    height: 72,
  },
}));

export const ProductInfo = styled(Stack)(() => ({
  flexBasis: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const Name = styled(Typography)(() => ({
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: ThemeFontWeight.semiBold,
}));

export const Description = styled(Typography)(({ theme }) => ({
  fontSize: '0.688rem',
  lineHeight: '13px',
  fontWeight: ThemeFontWeight.regular,
  color: theme.palette.secondary.main,
}));

export const PriceTitle = styled(Typography)(() => ({
  fontSize: '0.688rem',
  lineHeight: '13px',
  fontWeight: 400,
  letterSpacing: '0.066px',
}));
