import { Box, Button, Divider, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DrawerBottom, PaymentCost, PaymentTransactionLoader, ShoppingCartFees } from 'src/atoms';
import { Buy, Paths } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { PageTitle, WindowTitle } from 'src/molecules';
import { GetMoneyForm } from 'src/organisms';
import { getMoneyOrder, getMoneyСonfirm, getOrderById, OrderStatus, TGetMoney } from 'src/services';
import { ThemePaddings } from 'src/styles';
import { logger, parseError } from 'src/utils';

/*
[+] создание вывода денег POST /orders/money/create/ (добавил помимо данных корзины ещё балансы)
[+] подтверждение вывода денег POST /orders/money/create-and-confirm/ {amount}
*/

const TIME_GET_STATUS = 10000;

const GetMoney: FC = () => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const { throwServerError } = useContext(ErrorContext);
  const { enqueueSnackbar } = useSnackbar();

  const [order, setOrder] = useState<TGetMoney>();
  const [outValue, setOutValue] = useState<number | undefined>();
  const [confirm, setConfirm] = useState<boolean>(false);

  const [transactionOpen, setTransactionOpen] = useState<boolean>(false);
  const [isTransactionComplite, setTransactionComplite] = useState(false);

  const [status, setStatus] = useState<OrderStatus | null>(null);
  const [isReqPay, setReqPay] = useState(false);

  const wrapperId = Buy.money; // 20 - order вывод денег

  const onCloseHandler = () => (window.history.length ? navigate(-1) : navigate(Paths.dashboard));

  const getOrder = useCallback(
    (hard: boolean) => {
      setLoading(true);
      getMoneyOrder([wrapperId], outValue, hard)
        .then(({ data }) => {
          // комиссия считается по количеству товаров, но в выводе денег количества товара нет, так что ставим 1 всегда
          data.items.forEach((item) => {
            item.count = 1;
          });
          setOrder(data);
        })
        .catch((error) => {
          if (error.response?.status >= 500) throwServerError();
          logger.error(error);
        })
        .finally(() => setLoading(false));
    },
    [throwServerError, wrapperId, outValue]
  );

  useEffect(() => {
    if (!status || !order?.id) return;

    const getStatusTimeout = setInterval(() => {
      if (isReqPay) return;

      setReqPay(true);
      getOrderById(order?.id)
        .then(({ data }) => {
          if (data.status !== OrderStatus.Completed) return;
          clearInterval(getStatusTimeout);
          setTransactionComplite(true);
        })
        .catch(({ message }) => {
          clearInterval(getStatusTimeout);
          enqueueSnackbar(message, { variant: 'error' });
        })
        .finally(() => setReqPay(false));
    }, TIME_GET_STATUS);

    return () => {
      clearInterval(getStatusTimeout);
      setTransactionComplite(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, order, enqueueSnackbar]);

  useEffect(() => {
    getOrder(false);
  }, [getOrder]);

  const handlerFinish = (amount: number) => {
    setOutValue(amount);
    setConfirm(true);
  };

  const handlerReload = () => {
    getOrder(true);
  };

  const confirmGet = (amount: number) => {
    setLoading(true);
    getMoneyСonfirm([wrapperId], amount)
      .then(({ data }) => {
        // setOrder(data);
        // onCloseHandler();
        setStatus(data.status);
        setConfirm(false);
        setTransactionComplite(false);
        setTransactionOpen(true);
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
        if (error.response?.status === 406) {
          // недостаточно денег
          enqueueSnackbar(t('errorMsg.NO_GET_MONEY'), { variant: 'error' });
        } else {
          enqueueSnackbar(parseError(error), { variant: 'error' });
        }
        logger.error(error);
      })
      .finally(() => setLoading(false));
  };

  const transactionClose = () => {
    if (!isTransactionComplite) return false;
    onCloseHandler();
  };

  return (
    <>
      <PageTitle
        title={t('getMoney.title')}
        data-qa={'getMoney.title'}
        onClose={onCloseHandler}
        isLoading={isLoading}
      />
      <Box sx={{ p: ThemePaddings.page }}>
        {order && (
          <>
            <GetMoneyForm
              onReload={handlerReload}
              onChange={setOutValue}
              onFinish={handlerFinish}
              order={order}
              wrapperId={wrapperId}
            />
            {!!outValue && (
              <DrawerBottom isOpen={confirm} onClose={() => false}>
                <WindowTitle
                  sx={{ mb: '20px' }}
                  title={t('getMoney.confirm.title')}
                  onClose={() => setConfirm(false)}
                  isLoading={isLoading}
                />

                <Typography sx={{ mb: '16px' }}>{t('getMoney.warning')}</Typography>

                <PaymentCost
                  tkey={'getMoney.confirm.balance'}
                  price={{
                    bcost: outValue,
                    bcurrency: 'BNB',
                  }}
                />
                <ShoppingCartFees showFeeOnly={true} items={order.items} showTotal={false} />

                <PaymentCost
                  sx={{
                    '& .CostCalc_label': {
                      fontSize: '1.062rem',
                      fontWeight: 'bold',
                      lineHeight: '36px',
                    },
                  }}
                  tkey={'getMoney.confirm.total'}
                  price={order.balance_change}
                />

                <Divider variant="fullWidth" sx={{ my: '25px' }} />
                <Box sx={{ zIndex: 1, position: 'relative' }}>
                  <Button variant="outlined" onClick={() => confirmGet(outValue)} sx={{ width: '100%' }}>
                    {t('getMoney.confirm.button')}
                  </Button>
                </Box>
              </DrawerBottom>
            )}

            <PaymentTransactionLoader
              isOpen={transactionOpen}
              onClose={transactionClose}
              isComplite={isTransactionComplite}
              tkey="getMoney"
            />
          </>
        )}
      </Box>
    </>
  );
};

export default GetMoney;
