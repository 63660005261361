import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors, ThemeGradients, ThemeFontWeight } from 'src/styles';

export const PackWidgetBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  minHeight: 140,
  borderRadius: 15,
  cursor: 'pointer',
  overflow: 'hidden',
}));

export const PackWidgetTitle = styled('span')(({ theme }) => ({
  fontSize: '1.125rem',
  fontWeight: ThemeFontWeight.semiBold,
  color: ThemeColors.purple,
  textTransform: 'uppercase',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
  marginBottom: 12,
  position: 'relative',
  border: '1px solid transparent',
  backgroundClip: 'padding-box',
  borderRadius: '15px 0 0 0',
  zIndex: 1,
  backgroundColor: theme.palette.background.paper,
  padding: '6px 16px',
  top: 5,
  textAlign: 'right',

  '&:after': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: -1,
    background: ThemeGradients.bgFTBLR,
    content: '""',
    zIndex: -1,
    borderRadius: '15px 0 0 0',
  },
}));

export const PackWidgetFutter = styled('span')(() => ({
  fontSize: '0.75rem',
  fontWeight: ThemeFontWeight.regular,
  color: ThemeColors.purple,
  display: 'block',
  padding: '5px 16px 5px 16px',
}));

export const PackWidgetStack = styled(Stack)(() => ({
  padding: '16px',
  paddingBottom: 0,
  minHeight: 70,
  width: '100%',
}));

export const PackWidgetImg = styled('img')(() => ({
  position: 'absolute',
  top: -20,
  right: 0,
  zIndex: 1,
}));

export const HRFutter = styled('hr')(() => ({
  background: ThemeGradients.bgFTBRL,
  border: 'none',
  height: '1px',
  margin: 0,
}));
