import { Stack, Box } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

import { useResize } from 'src/hooks';

import { VideoPlayerProps } from './VideoPlayer.types';

const defaultConfig: ReactPlayerProps = {
  controls: true,
  stopOnUnmount: true,
  config: { file: { attributes: { type: 'file' } } },
};

const VideoPlayer: FC<VideoPlayerProps> = ({
  width: widthProp,
  height: heightProp,
  ratio: ratioProp,
  url,
  config: configProp,
  sx,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const { width: wrapperWidth } = useResize(containerRef);
  const playerRef = useRef<ReactPlayer>(null);

  const ratio = ratioProp || 16 / 9;

  const config = configProp === undefined ? defaultConfig : { ...defaultConfig, ...configProp };

  useEffect(() => {
    const { current } = containerRef;
    if (!current) return;

    setWidth(current.offsetWidth);
    setHeight(current.offsetWidth / ratio);
  }, [containerRef, widthProp, heightProp, ratio]);

  useEffect(() => {
    if (!wrapperWidth) return;

    setWidth(wrapperWidth);
    setHeight(wrapperWidth / ratio);
  }, [wrapperWidth, widthProp, heightProp, ratio]);

  return (
    <Stack sx={sx} ref={containerRef} width="100%" height="100%" position="relative">
      <Box
        sx={{
          flex: 1,
          height: '100%',
          position: 'relative',
          '& video': {
            //borderRadius: '16px',
            //backgroundColor: 'background.paper',
          },
        }}
      >
        <ReactPlayer ref={playerRef} width={width} height={heightProp || height} url={url} {...config} />
      </Box>
    </Stack>
  );
};

export default VideoPlayer;
