import { Accordion } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FONTS, ThemeColors } from 'src/styles';

export const FiltersGroupAccordion = styled(Accordion)(() => ({
  boxShadow: 'none',
  backgroundColor: 'transparent',
  marginTop: 16,

  '&.Mui-expanded': {
    marginBottom: 16,
    border: `1px solid ${ThemeColors.black}`,
    borderRadius: 24,
  },

  '&:before': {
    height: 0,
  },

  '.MuiAccordionDetails-root': {
    padding: 0,
    margin: 0,
    border: 0,
    backgroundColor: 'transparent',
  },

  '.MuiAccordionSummary-root': {
    padding: 0,
    margin: 0,
    height: 40,
    minHeight: 'unset',
    border: `1px solid ${ThemeColors.black}`,
    borderRadius: 60,

    '&.Mui-expanded': {
      minHeight: 'unset',
      height: 44,
      backgroundColor: ThemeColors.black,
      color: ThemeColors.white,
    },
  },

  '.MuiAccordionSummary-content': {
    justifyContent: 'center',
    marginRight: -10,
  },

  '.MuiAccordionSummary-expandIconWrapper': {
    marginRight: 10,
    color: ThemeColors.white,
  },
}));

export const Title = styled('p')(() => ({
  fontFamily: FONTS,
  fontSize: '0.812rem',
  fontWeight: 600,
  lineHeight: '18px',
  letterSpacing: '0.35px',
  margin: 0,
  textAlign: 'center',
}));
