import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Paths } from 'src/constants';
import { setParams } from 'src/services';
import { parseError } from 'src/utils';

import Help from '../Help';

const StudyTutorial: FC<{ name: string; stepCount: number; onFinish?: () => void }> = ({
  name,
  stepCount,
  onFinish,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handlerNext = () => {
    setParams({ [name]: true })
      .then(() => (onFinish ? onFinish() : navigate(Paths.study)))
      .catch((error) => {
        enqueueSnackbar(parseError(error), { variant: 'error' });
      });
  };

  return <Help tkey={`study.${name}`} stepCount={stepCount} sx={{ height: '100%' }} onFinish={handlerNext} />;
};

export default StudyTutorial;
