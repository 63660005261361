import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Switch2Links } from 'src/atoms';
import { Paths } from 'src/constants';
import { PageTitle } from 'src/molecules';
import { ThemePaddings } from 'src/styles';

import { getSwitchLinksForNotification } from './Notification.utils';

const Notification: FC = () => {
  const { t } = useTranslation('app');

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onCloseHandler = () => navigate(Paths.dashboard);

  return (
    <>
      <PageTitle
        title={t('notifications.title')}
        data-qa={'notifications.title'}
        help={{ tkey: 'notifications' }}
        onClose={onCloseHandler}
      />
      <Box sx={{ p: ThemePaddings.notificationTemplate }}>
        <Switch2Links currentPath={pathname} links={getSwitchLinksForNotification(t)} />
      </Box>
      <Outlet />
    </>
  );
};

export default Notification;
