import { Box, Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors, ThemeGradients } from 'src/styles';

export const CheckButton = styled(Button)(() => ({
  display: 'block',
  width: '100%',
  height: 40,
  marginTop: 16,
  fontSize: '0.812rem',
  fontWeight: 600,
  lineHeight: '18px',

  '&.checked': {
    backgroundColor: ThemeColors.black,
    color: ThemeColors.white,
  },
}));

export const SecretBoxStack = styled(Stack)(() => ({
  flexWrap: 'wrap',
  flexDirection: 'row',
}));

export const TriangleButton = styled(Button)(() => ({
  width: 75,
  height: 75,
  padding: 0,
  border: 0,
  flexDirection: 'column',
  flexBasis: '25%',
  marginBottom: 9,

  '.triangle': {
    fill: ThemeColors.black,
    stroke: ThemeColors.black,
  },
}));

export const Number = styled(Box)(() => ({
  position: 'absolute',
  left: '50%',
  top: 19,
  color: ThemeColors.white,
  zIndex: 2,
  transform: 'translateX(-50%)',
  fontSize: '1.375rem',
  lineHeight: '28px',
  fontWeight: 500,
}));

export const Label = styled(Typography)(() => ({
  fontSize: '0.75rem',
  lineHeight: '16px',
  fontWeight: 400,
  marginTop: 4,
  color: ThemeColors.gray500,
}));

export const MaterialStack = styled(Stack)(() => ({
  flexWrap: 'wrap',
  flexDirection: 'row',
}));

export const MaterialButton = styled(Button)(() => ({
  width: 99,
  height: 42,
  flexDirection: 'column',
  padding: 0,
  margin: '0 2px 8px',
  backgroundColor: ThemeColors.black,
  color: ThemeColors.white,
  fontSize: '0.812rem',
  lineHeight: '18px',
  fontWeight: 600,

  '&:hover': {
    background: 'transparent',
    opacity: 1,
    color: 'inherit',
  },
}));

export const Level = styled(Typography)(() => ({
  fontSize: '0.688rem',
  lineHeight: '13px',
  fontWeight: 400,
  color: ThemeColors.gray,
}));

export const WearableStack = styled(Stack)(() => ({
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const WearableButton = styled(Button)(() => ({
  padding: 0,
  borderRadius: 12,
  minWidth: 56,
  height: 56,
  marginBottom: 8,

  '&.checked': {
    background: ThemeGradients.bg2,

    'img:not(.notInverted)': {
      filter: 'brightness(0) invert(1)',
    },

    p: {
      color: ThemeColors.white,
      background: 'unset',
      backgroundClip: 'unset',
      textFillColor: 'unset',
    },
  },
}));

export const WearableTitle = styled(Typography)(() => ({
  fontSize: '0.94rem',
  lineHeight: '20px',
  fontWeight: 600,
  textTransform: 'uppercase',
  margin: '0 10px',
  background: ThemeGradients.colorLight,
  backgroundClip: 'text',
  textFillColor: 'transparent',
}));
