import { Box } from '@mui/material';
import declineWord from 'decline-word';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TeamImg } from 'src/assets/images';
import { Paths, isMarket } from 'src/constants';
import { SquareWidget } from 'src/molecules';
import { getLocale } from 'src/utils';

import { TeamWidgetProps } from './TeamWidget.types';

const TeamWidget: FC<TeamWidgetProps> = ({ count }) => {
  const locale = getLocale();
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  const onTeamHandler = () => {
    if (isMarket) {
      navigate(Paths.team);
    } else {
      const localPath = localStorage.getItem('teamLastPath');
      navigate(localPath || Paths.teamGlobe);
    }
  };

  const declinePeoples = (count: number) => {
    return `${count} ${
      locale === '1' ? declineWord(count, t('team.countPerson'), '', 'а') : declineWord(count, t('team.countPerson'))
    }`;
  };

  return (
    <SquareWidget
      onClick={onTeamHandler}
      title={t('team.titleWidget')}
      data-qa={'team.titleWidget'}
      value={declinePeoples(count)}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '10px',
          backgroundImage: `url(${TeamImg})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        {' '}
      </Box>
    </SquareWidget>
  );
};

export default TeamWidget;
