import { configureStore } from '@reduxjs/toolkit';

import { clanReducer } from './clanSlice';
import { demoReducer } from './demoSlice';
import { filtersReducer } from './filtersSlice';
import { marketReducer } from './marketSlice';
import { noticeReducer } from './noticeSlice';
import { userReducer } from './userSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    clan: clanReducer,
    demo: demoReducer,
    filters: filtersReducer,
    notice: noticeReducer,
    market: marketReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
