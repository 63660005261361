import { Box, Button, Stack } from '@mui/material';
import { FC } from 'react';

import { deleteAvatarConfig } from 'src/services';

import { AvatarViewProps, viewMode } from './AvatarView.types';

const AvatarImg: FC<{ imgSrc: string }> = ({ imgSrc }) => {
  return imgSrc ? <img src={imgSrc} alt="avatar" /> : null;
};

const AvatarView: FC<AvatarViewProps> = ({ data, onChange }) => {
  const handleChange = (mode: viewMode.edit, meta?: unknown) => onChange && onChange(mode, meta);
  const handleDelete = () => {
    deleteAvatarConfig();
    onChange && onChange(viewMode.create);
  };

  return (
    <Box>
      {data.snapshot?.preview && (
        <Stack>
          <AvatarImg imgSrc={data.snapshot.preview} />
        </Stack>
      )}
      <Button variant="contained" onClick={() => handleChange(viewMode.edit)} sx={{ my: 1, width: '100%' }}>
        Настроить внешний вид
      </Button>
      <Button onClick={handleDelete} sx={{ my: 1, width: '100%' }}>
        Удалить аватара
      </Button>
    </Box>
  );
};

export default AvatarView;
