import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

import { ThemeColors, ThemeGradients } from 'src/styles';

export const FilterButtonBorder = styled(Box)(() => ({
  position: 'fixed',
  right: 16,
  bottom: 40,
  width: 50,
  height: 50,
  borderRadius: '100%',
  background: ThemeGradients.bg2,
  padding: 3,
  zIndex: 10,
}));

export const FilterButton = styled(IconButton)(() => ({
  width: 44,
  height: 44,

  svg: {
    fill: 'currentColor',
  },
}));

export const SelectFilters = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 13,
  height: 13,
  borderRadius: '100%',
  backgroundColor: ThemeColors.redLight,
  zIndex: 2,
}));
