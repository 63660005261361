import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors, ThemeGradients } from 'src/styles';

export const Wrapper = styled(Box)(() => ({
  borderTop: `1px solid ${ThemeColors.grayLight}`,
  borderBottom: `1px solid ${ThemeColors.grayLight}`,
  paddingTop: 16,
  paddingBottom: 16,
  marginBottom: 16,
}));

export const Title = styled(Typography)(() => ({
  fontSize: '1.25rem',
  lineHeight: '24px',
  fontWeight: 500,
  background: ThemeGradients.text,
  backgroundClip: 'text',
  textFillColor: 'transparent',
  textAlign: 'center',
}));
