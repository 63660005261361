import { FC } from 'react';
import { Navigate, useOutlet } from 'react-router-dom';

import { Paths } from 'src/constants';
import { useAppSelector } from 'src/hooks';
import { selectAuthorized } from 'src/store/userSlice';

const GuestRoute: FC = () => {
  const isAuthorized = useAppSelector(selectAuthorized);
  const outlet = useOutlet();

  if (isAuthorized) {
    const sparams = new URLSearchParams(window.location.search);
    if (sparams.has('ref')) {
      return <Navigate to={sparams.get('ref') || Paths.dashboard} replace={true} />;
    }
    return <Navigate to={Paths.dashboard} replace={true} />;
  }

  return <>{outlet}</>;
};

export default GuestRoute;
