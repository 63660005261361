import { Box, Stack, Typography, Avatar, Grid, Button } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { DrawerBottom, Location, TitleBadge } from 'src/atoms';
import { Paths, getImgSrc, pathsArg } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { useAppSelector } from 'src/hooks';
import { PageTitle, ProductWidget, DrawerShareText, MemberOfTeam, WindowTitle } from 'src/molecules';
import { GoalCard, GoalListHistoryWidget, SNList, UserState } from 'src/organisms';
import { getDashboardByUserId, TProfileDashboard } from 'src/services';
import { selectUserMe } from 'src/store/userSlice';
import { ThemeColors, ThemePaddings } from 'src/styles';
import { RouterIdParam, RouterStateProfile } from 'src/types';
import { logger } from 'src/utils';

/*
const ProfilePackageItem: FC<TOrderPackage> = (item) => {
  const { t } = useTranslation('app');
  const levelColor = item.level >= 1 && item.level <= 4 ? ColorOfPackages[item.level] : ColorOfPackages[1];
  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: 'background.paper',
        alignItems: 'center',
        borderRadius: '10px',
        margin: '10px 0px 5px',
        opacity: item.progress.sum ? 1 : 0.2,
      }}
    >
      <Box sx={{ flex: 0, color: levelColor, fontSize: '70px', p: '7px 10px', lineHeight: '50px' }}>{item.level}</Box>
      <Stack sx={{ flex: 1 }}>
        <Typography
          variant="h2grade"
          sx={{ fontSize: '1.062rem', fontWeight: ThemeFontWeight.semiBold, textTransform: 'uppercase' }}
        >
          {t(item.title)}
        </Typography>
        <Typography sx={{ color: 'secondary.main', fontSize: '0.625rem' }}>
          {t('profile.packages.dateBuy')}: {item.created_at}
        </Typography>
      </Stack>
      <Stack sx={{ flex: 0, p: '7px 10px' }}>
        <Typography sx={{ color: 'secondary.main', fontSize: '0.625rem' }}>{t('profile.packages.count')}:</Typography>
        <Typography sx={{ fontWeight: ThemeFontWeight.semiBold, fontSize: '1.25rem' }}>{item.progress.sum}</Typography>
      </Stack>
    </Stack>
  );
};
*/

const ProfileUser: FC = () => {
  const { t } = useTranslation('app');
  const { id } = useParams<RouterIdParam>();
  const { throwServerError, throwNotFound } = useContext(ErrorContext);
  const navigate = useNavigate();
  const userMe = useAppSelector(selectUserMe);
  const { state } = useLocation();
  const stateCompositeId = (state as RouterStateProfile)?.compositeId;

  const onCloseHandler = () => (window.history.length ? navigate(-1) : navigate(Paths.dashboard));

  const [isLoading, setLoading] = useState(true);
  const [shareOpen, setShareOpen] = useState(false);
  const [isOpenTarget, setOpenTarget] = useState(!!stateCompositeId);
  const [dashboard, setDashboard] = useState<TProfileDashboard>();

  const isMe = userMe?.user_id === Number(id);

  const userId = Number(id) || userMe?.user_id;

  useEffect(() => {
    if (Number.isNaN(userId) || !userId) return throwNotFound();

    setLoading(true);
    getDashboardByUserId(userId)
      .then(({ data }) => {
        setDashboard(data);
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
        if (error.response?.status >= 400) throwNotFound();
        logger.error(error);
      })
      .finally(() => setLoading(false));
  }, [throwNotFound, throwServerError, userId]);

  if (!id) return null;

  return (
    <Box sx={{ p: ThemePaddings.page }}>
      <PageTitle
        title={t('profile.title')}
        data-qa={'profile.title'}
        help={{ tkey: 'profileUser' }}
        onClose={onCloseHandler}
        isLoading={isLoading}
      />

      {dashboard && (
        <>
          <Stack direction="column" alignItems="center">
            {isMe && <Typography sx={{ mb: '8px' }}>{t('profileUser.itsMe.description')}</Typography>}
            <Stack sx={{ position: 'relative', alignItems: 'center', marginBottom: '8px' }} direction="row">
              <Avatar
                src={getImgSrc(dashboard.photo_uuid, 's150-c')}
                sx={{
                  width: 150,
                  height: 150,
                  border: '2px solid',
                  borderColor: 'primary.main',
                  position: 'relative',
                  right: dashboard.avatar?.sex ? '-5px' : '0px',
                }}
              />
              {dashboard.avatar?.sex ? (
                <Avatar
                  src={getImgSrc(dashboard.avatar?.config?.img_uuid, 's80-c')}
                  sx={{
                    width: 80,
                    height: 80,
                    border: `1px solid ${ThemeColors.gray}`,
                    position: 'relative',
                    left: '-5px',
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate(pathsArg(Paths.avatarUser, { id: dashboard.avatar.id }))}
                />
              ) : null}
            </Stack>
            <Typography sx={{ fontSize: '1.75rem', lineHeight: '36px', fontWeight: 600, marginBottom: '10px' }}>
              {dashboard.display_name}
            </Typography>
          </Stack>
          {/* <Address address={dashboard.public_address} sx={{ margin: '10px 10% 10px 10%' }} /> */}
          <Grid container spacing={'15px'} sx={{ mb: 2 }}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              {dashboard.coordinates && (
                <Location
                  sx={{ color: (theme) => theme.palette.secondary.main }}
                  city={dashboard.coordinates?.city?.name}
                  country={dashboard.coordinates?.country?.name}
                  ip={dashboard.coordinates?.ip}
                />
              )}
              {dashboard.social && <SNList sx={{ mt: '8px' }} items={dashboard.social} />}
            </Grid>
          </Grid>
          <Box sx={{ mx: '-' + ThemePaddings.pageX }}>
            {dashboard.referrer && dashboard.referrer.id && (
              <>
                <TitleBadge count={0}>{t('profile.referrer.title')}</TitleBadge>
                <MemberOfTeam
                  sx={{
                    mt: '10px',
                    mx: ThemePaddings.pageX,
                    borderRadius: '20px',
                    border: '1px solid',
                    borderColor: (theme) => theme.palette.divider,
                  }}
                  member={dashboard.referrer}
                  onSelectMember={(id) => navigate(pathsArg(Paths.profileUser, { id }))}
                />
              </>
            )}
            {dashboard.current && (
              <>
                <TitleBadge count={0}>{t('profile.goal.title')}</TitleBadge>
                <Box sx={{ my: '8px', mx: ThemePaddings.pageX }}>
                  <ProductWidget
                    item={dashboard.current?.target}
                    balance={dashboard.current?.balance}
                    cost={dashboard.current?.cost}
                    progress={dashboard.current?.progress}
                    height={290}
                    isMe={isMe}
                    readonly={true}
                  />
                </Box>
                <GoalListHistoryWidget
                  readonly={false}
                  user_id={dashboard.user_id}
                  items={dashboard.targets?.results}
                  maxCount={dashboard.targets?.count}
                />
              </>
            )}
            {(dashboard.team || dashboard.earned || dashboard.clans) && (
              <TitleBadge count={0} sx={{ my: '8px' }}>
                {t('profile.stat.title')}
              </TitleBadge>
            )}
          </Box>
          {userId && <UserState userId={userId} />}
          <Grid container spacing={'15px'} sx={{ mb: 2 }}>
            {isMe && (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  data-qa="profileUser.itsMe.button.share"
                  onClick={() => setShareOpen(true)}
                >
                  {t('profileUser.itsMe.button.share')}
                </Button>
              </Grid>
            )}
          </Grid>
        </>
      )}
      {userMe && (
        <DrawerShareText
          title={userMe?.display_name}
          link={window.location.href}
          isOpen={shareOpen}
          tKey="shareProfile"
          onClose={() => setShareOpen(false)}
        />
      )}

      {stateCompositeId && (
        <DrawerBottom
          isOpen={isOpenTarget}
          onClose={() => setOpenTarget(false)}
          sx={{ height: '100%', '& .MuiDrawer-paper': { height: '100%' } }}
        >
          <WindowTitle
            title={t('profileUser.target.title')}
            data-qa={'profileUser.target.title'}
            onClose={() => setOpenTarget(false)}
          />
          <Box sx={{ height: 'calc(100% - 50px)', overflow: 'hidden', overflowY: 'scroll' }}>
            {isOpenTarget && <GoalCard compositeId={stateCompositeId} />}
          </Box>
        </DrawerBottom>
      )}
    </Box>
  );
};

export default ProfileUser;
