import { yupResolver } from '@hookform/resolvers/yup';
import { Instagram, YouTube, Telegram } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Accordion, Address, InputField, SelectField, SwitchField } from 'src/atoms';
import { Paths, getImgSrc, isMarket, pathsArg } from 'src/constants';
import { useAppDispatch } from 'src/hooks';
import {
  AutomatOffConfirmation,
  ChangeGeolocation,
  SaveProfileConfirmation,
  MemberOfTeam,
  TonWalletConnect,
} from 'src/molecules';
import { TelegramLogin, GoogleLogin, InstagramLogin } from 'src/molecules';
import { GeolocationTranslate, UploadAvatar } from 'src/organisms';
import {
  setProfileData,
  getGeolocation,
  Geolocation,
  Country,
  City,
  NoticeCategories,
  getLanguages,
  Language,
  getSocialNetworks,
  TSocialNetworks,
  setParams,
} from 'src/services';
import { getInfoUser } from 'src/store/userSlice';
import { ThemeColors, ThemePaddings } from 'src/styles';
import { delay, parseError, setPrefix } from 'src/utils';

import { schema } from './ProfileForm.constants';
import {
  Row,
  SwitchRow,
  GeoTitle,
  GeoData,
  TitleVerified,
  ValueVerified,
  RowDivider,
  TextSuccess,
  TextWarning,
} from './ProfileForm.styles';
import { ProfileFormProps, ProfileFormValues } from './ProfileForm.types';

const ProfileForm: FC<ProfileFormProps> = ({ player }) => {
  const { t } = useTranslation('app');
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // const [, setConfirmEmail] = useState(false);
  const [isOffAutomat, setOffAutomat] = useState(false);
  const [isGeolocation, setIsGeolocation] = useState(false);
  const [isSave, setSave] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [isShowLoader, setShowLoader] = useState(false);

  const [tonState, setTonState] = useState<string | undefined>(player?.ton_usdt);

  const [social, setSocial] = useState<TSocialNetworks[]>(player.social || []);

  const [geolocation, setGeolocation] = useState<Geolocation>();
  const [country, setCountry] = useState<Country | null>(player.country || null);
  const [city, setCity] = useState<City | null>(player.city || null);

  // const [noticeList, setNoticeList] = useState<NoticeCategories[]>(player.notice_categories_subscriptions);

  const connectSN: { [key: string]: any } = {
    instagram: InstagramLogin,
    telegram: TelegramLogin,
    youtube: GoogleLogin,
  };

  const iconsSN: { [key: string]: any } = {
    instagram: Instagram,
    telegram: Telegram,
    youtube: YouTube,
  };

  const {
    handleSubmit,
    control,
    formState: { isValid },
    watch,
    setValue,
  } = useForm<ProfileFormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      ...player,
    },
  });
  const refForm = useRef<HTMLFormElement>(null);
  const [languageList, setLanguageList] = useState<Language[]>([]);

  const isLocationAutomat = watch('coordinates.is_location_update_enabled');
  const isAutomat = watch('is_auto_clan_enabled');
  //const birthDay = watch('birthday');
  //const isAge18plus = birthDay && isComingOfAge(birthDay);
  setValue('is_system_and_news', true);

  const onSubmit: SubmitHandler<ProfileFormValues> = async (formData) => {
    setLoading(true);
    try {
      if (!formData.photo_file) delete formData.photo_file;
      else delete formData.photo_uuid;
      if (isLocationAutomat) delete formData.city_id;
      delete formData.notice_categories_subscriptions;
      delete formData.is_system_and_news;
      delete formData.available_notice_categories;
      delete formData.country;
      delete formData.city;

      // const queryFieldName = 'notice_categories_subscriptions';
      const noticeObj: { [name: string]: NoticeCategories } = {};
      //noticeList.forEach((value, index) => {
      //  noticeObj[`${queryFieldName}[${index}]`] = value;
      //});

      await setProfileData(formData, noticeObj);
      dispatch(getInfoUser());
    } catch (error) {
      enqueueSnackbar(parseError(error), { variant: 'error' });
    } finally {
      setLoading(false);
      await delay(1500);
      setShowLoader(false);
      setSave(false);
    }
  };

  const onSaveConfirmation = () => setSave(true);
  const onCloseSaveConfirmation = () => setSave(false);
  const onSubmitHandler = () => {
    setShowLoader(true);
    handleSubmit(onSubmit)();
  };

  /*
  const onConfirmEmailHandler = () => {
    // TODO: отправлять запрос на отправку письма на E-mail
    setConfirmEmail(true);
  };
  const onCloseConfirmEmailHandler = () => setConfirmEmail(false);
  */

  const onCancelOffAutomatHandler = () => {
    setValue('is_auto_clan_enabled', true);
    setOffAutomat(false);
  };
  const onSwitchOffHandler = () => {
    setValue('is_auto_clan_enabled', false);
    setOffAutomat(false);
  };
  const onSwitchOffAutomatHandler = () => {
    if (isAutomat) {
      setOffAutomat(true);
    }
  };

  const onChangeLocation = () => {
    setValue('coordinates.is_location_update_enabled', !isLocationAutomat);
    setValue('is_location_update_enabled', !isLocationAutomat);
  };

  const onChangeGeolocation = () => {
    setIsGeolocation(true);
  };
  const onCloseGeolocation = () => {
    setCountry(player.country || geolocation?.country);
    setCity(player.city || geolocation?.city);
    setIsGeolocation(false);
  };
  const onChangeGeoData = () => {
    setIsGeolocation(false);
  };

  const onSaveCountry = (country: Country) => {
    setCountry(country);
  };

  const onSaveCity = (city: City | null) => {
    setCity(city);
  };

  const subscribeClick = (social: TSocialNetworks) => {
    let sParams = '';
    switch (social.icon) {
      case 'youtube':
        sParams = '?sub_confirmation=1&feature=subscribe-embed-click';
        break;
    }

    setParams({ [`subscribe_${social.icon}`]: true }).finally(() => {
      window.open(social.official + sParams, '_blank');
      handlerSNConnect();
    });
  };

  /*
  const onSwitchNotificationHandle = (noticeStr: NoticeCategories) => {
    if (!noticeList.length) {
      setNoticeList([noticeStr]);
      return;
    }
    const notices = [...noticeList];
    const index = noticeList.findIndex((i) => i === noticeStr);
    index < 0 ? notices.push(noticeStr) : notices.splice(index, 1);
    setNoticeList([...notices]);
  };*/

  /*
  useEffect(() => {
    getSocialNetworks().then(({ data }) => {
      setSocial(data);
    });
  }, []);
  */

  useEffect(() => {
    if (!geolocation) {
      getGeolocation()
        .then(({ data }) => {
          setGeolocation(data);
        })
        .catch(({ message }) => {
          enqueueSnackbar(message, { variant: 'error' });
        });
    }
  }, [enqueueSnackbar, geolocation]);

  useEffect(() => {
    if (geolocation && !country && !city) {
      setCountry(geolocation.country);
      setCity(geolocation.city);
    }
  }, [city, country, geolocation]);

  useEffect(() => {
    if (city) {
      setValue('city_id', city.id);
    }
  }, [setValue, country, city]);

  useEffect(() => {
    getLanguages()
      .then(({ data }) => setLanguageList(data))
      .catch(() => setLanguageList([]));
  }, []);

  const handlerSNConnect = () => {
    getSocialNetworks().then(({ data }) => {
      setSocial(data);
    });
  };

  return (
    <Box sx={{ marginLeft: '-16px', marginRight: '-16px' }}>
      <form ref={refForm}>
        <Row>
          <Controller
            name="photo_file"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <UploadAvatar
                  defaultValue={getImgSrc(player.photo_uuid, 's150-c')}
                  value={value}
                  onChange={onChange}
                  hasError={!!error}
                  textError={error && error.message}
                />
              );
            }}
          />
        </Row>

        <Accordion
          id="paymentData"
          title={t('accordionTitle.paymentData')}
          data-qa={'accordionTitle.paymentData'}
          isExpanded
        >
          {!isMarket && (
            <>
              <Row>
                <TitleVerified data-qa={'profile.contract.title'} sx={{ mb: '16px' }}>
                  {t('profile.contract.title')}:
                </TitleVerified>
                {player.user_contract?.contract_id ? (
                  <>
                    <Address
                      onClick={() =>
                        player.user_contract?.address && window.open(player.user_contract.address, '_blank')
                      }
                      color={ThemeColors.green}
                      title={t('profile.contract')}
                      data-qa={'profile.contract'}
                      address={player.user_contract?.contract_id}
                    />
                    <TextSuccess>{t('profile.contract.success')}</TextSuccess>
                  </>
                ) : (
                  <>
                    <Button fullWidth onClick={() => navigate(pathsArg(Paths.studyStep, { id: 'contract' }))}>
                      {t('profile.contract.connect.button')}
                    </Button>
                    <TextWarning>{t('profile.contract.connect')}</TextWarning>
                  </>
                )}
              </Row>
              <Row>
                <TitleVerified data-qa={'profile.walletConnect.title'} sx={{ mb: '16px' }}>
                  {t('profile.walletConnect.title')}:
                </TitleVerified>
                {player.public_address ? (
                  <>
                    <Address
                      color={ThemeColors.green}
                      title={t('profile.wallet')}
                      data-qa={'profile.wallet'}
                      address={player.public_address || ''}
                    />
                    <TextSuccess>{t('profile.wallet.success')}</TextSuccess>
                  </>
                ) : (
                  <>
                    <Button fullWidth onClick={() => navigate(pathsArg(Paths.studyStep, { id: 'wallet' }))}>
                      {t('profile.wallet.connect.button')}
                    </Button>
                    <TextWarning>{t('profile.wallet.connect')}</TextWarning>
                  </>
                )}
              </Row>
            </>
          )}
          <Row>
            <TitleVerified data-qa={'profile.tonWallet.title'} sx={{ mb: '16px' }}>
              {t('profile.tonWallet.title')}:
            </TitleVerified>
            <TonWalletConnect wallet={tonState || ''} onUpdate={(val) => setTonState(val)} />
            {tonState ? (
              <TextSuccess>{t('profile.tonWallet.success')}</TextSuccess>
            ) : (
              <TextWarning>{t('profile.tonWallet.connect')}</TextWarning>
            )}
          </Row>
        </Accordion>
        <Accordion
          id="personalData"
          title={t('accordionTitle.personalData')}
          data-qa={'accordionTitle.personalData'}
          isExpanded
        >
          <Row>
            <TitleVerified sx={{ mb: '16px' }}>{t('profile.displayName')}</TitleVerified>
            <InputField name="display_name" control={control} placeholder={t('placeholder.displayName')} />
          </Row>
          {social?.map((social) => {
            const ConnectionComponent = connectSN[social.icon];
            const IconComponent = iconsSN[social.icon];
            return (
              <Row key={`profile_social_${social.icon}_${social.id}`}>
                <TitleVerified sx={{ mb: '8px' }}>{t(`profile.${social.title}`)}:</TitleVerified>
                {social.account ? (
                  <>
                    <ValueVerified
                      sx={{
                        borderRadius: '5px',
                        lineHeight: '44px',
                        borderColor: social.subscribed ? ThemeColors.green : ThemeColors.redLight,
                      }}
                    >
                      {social.account}
                    </ValueVerified>
                    {social.subscribed ? (
                      <TextSuccess>{t(`profile.social.${social.icon}.success`)}</TextSuccess>
                    ) : (
                      <>
                        <TextWarning>{t(`profile.social.${social.icon}.subscribe`)}</TextWarning>
                        <Button
                          fullWidth
                          data-qa={`profile.social.${social.icon}.subscribe.button`}
                          variant="text"
                          sx={{ mt: '10px', '& svg': { mr: '4px' } }}
                          onClick={() => subscribeClick(social)}
                        >
                          <IconComponent />
                          {t(`profile.social.${social.icon}.subscribe.button`)}
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {ConnectionComponent && <ConnectionComponent connection={true} onFinish={handlerSNConnect} />}
                    <TextWarning>{t(`profile.social.${social.icon}.connect`)}</TextWarning>
                  </>
                )}
              </Row>
            );
          })}
          {/*
          <Box
            sx={
              !player.is_email_verified
                ? {
                    background: ThemeGradients.bgLight,
                    mx: '-16px',
                    marginBottom: '16px',
                    padding: '10px 16px 1px',
                  }
                : {}
            }
          >
            <TitleVerified>{t('profile.emailVerifiedTitle')}</TitleVerified>
            <Row>
              <InputField
                name="email"
                control={control}
                placeholder="you@mail.ru"
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: player.is_email_verified ? ThemeColors.greenLight : ThemeColors.grayLight,
                  },
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: ThemeColors.white,
                  },
                }}
              />
              {player.is_email_verified && (
                <FormHelperText sx={{ color: ThemeColors.greenLight, paddingLeft: '14px' }}>
                  {t('label.EmailConfirmed')}
                </FormHelperText>
              )}
            </Row>
            {!player.is_email_verified && (
              <Row>
                <Button
                  variant="outlined"
                  sx={{ width: '100%' }}
                  onClick={onConfirmEmailHandler}
                  disabled={!!errors.email}
                >
                  {t('button.confirmEmail')}
                </Button>
              </Row>
            )}
          </Box>
          <Row>
            <SelectField
              name="gender"
              control={control}
              placeholder={t('placeholder.gender')}
              options={getGenderOptions()}
            />
          </Row>
          */}
        </Accordion>
        {player.referrer && player.referrer.id && (
          <Accordion
            id="yourReferal"
            title={t('accordionTitle.yourReferal')}
            data-qa={'accordionTitle.yourReferal'}
            isExpanded
          >
            <MemberOfTeam
              sx={{
                borderRadius: '20px',
                border: '1px solid',
                borderColor: (theme) => theme.palette.divider,
              }}
              member={player.referrer}
              onSelectMember={(id) => navigate(pathsArg(Paths.profileUser, { id }))}
            />
          </Accordion>
        )}
        {/* <Accordion id="birthdayData" title={t('accordionTitle.birthday')} data-qa={'accordionTitle.birthday'} isExpanded>
          {!isAge18plus && <CensorMessage>{t('profile.censorMessage')}</CensorMessage>}
          <Row>
            <MobileDatePickerField name="birthday" control={control} placeholder={t('placeholder.birthday')} />
          </Row>

          {isAge18plus && (
            <Row>
              <SwitchField
                name="is_nudity_enabled"
                control={control}
                label={t('label.isNudity')}
                beforeLabel={t('button.no')}
                afterLabel={t('button.yes')}
              />
            </Row>
          )}
        </Accordion>
          */}
        <Accordion id="settings" title={t('accordionTitle.settings')} data-qa={'accordionTitle.settings'} isExpanded>
          <TitleVerified sx={{ mb: '16px' }}>{t('profile.language')}</TitleVerified>
          <Row>
            <SelectField
              name="language"
              control={control}
              onChange={(id) => {
                i18n.changeLanguage(String(id));
                const lang = languageList.find((l) => l.id === id);
                if (lang) setPrefix(lang.slug);
              }}
              options={languageList.map((lang) => ({ id: lang.id + '', value: lang.title }))}
            />
          </Row>
          <RowDivider />
          {!isMarket && (
            <>
              <SwitchRow>
                <SwitchField
                  disabled={!player.can_auto_clan_enabled}
                  name="is_auto_clan_enabled"
                  control={control}
                  label={t('label.isAutoClan')}
                  onClickHandler={onSwitchOffAutomatHandler}
                />
                {!player.can_auto_clan_enabled ? (
                  <>
                    <Typography sx={{ my: '8px' }}>{t('profile.isAutoClan.disabled')}</Typography>
                    <Button variant="text" fullWidth onClick={() => navigate(Paths.goal)}>
                      {t('profile.isAutoClan.disabled.button')}
                    </Button>
                  </>
                ) : (
                  !isAutomat && <Typography sx={{ my: '8px' }}>{t('profile.isAutoClan.off')}</Typography>
                )}
              </SwitchRow>
              <RowDivider />
            </>
          )}
          <>
            <SwitchRow>
              <SwitchField
                name="coordinates.is_location_update_enabled"
                control={control}
                label={t('profile.isLocation')}
                onClickHandler={onChangeLocation}
              />
              {!isLocationAutomat && <Typography sx={{ mt: '8px' }}>{t('profile.isLocation.disabled')}</Typography>}
              <GeolocationTranslate send={isLocationAutomat} />
            </SwitchRow>
            {/* <TitleVerified sx={{ mb: '16px' }}>{t('profile.isLocation')}</TitleVerified>*/}
            <Row>
              <GeoData>
                {country && city && `${country.translation}, ${city.translation}`}
                {geolocation?.ip && `, IP ${geolocation.ip}`}
              </GeoData>
              {!isLocationAutomat && (
                <>
                  <GeoTitle>{t('profile.geoTitle')}</GeoTitle>

                  <Button variant="text" sx={{ width: '100%', border: 'none' }} onClick={onChangeGeolocation}>
                    {t('button.changeLocation')}
                  </Button>
                </>
              )}
            </Row>
          </>
        </Accordion>
        <Box sx={{ mx: ThemePaddings.pageX, mt: '16px' }}>
          <Button variant="outlined" disabled={!isValid} onClick={onSaveConfirmation} fullWidth>
            {t('button.save')}
          </Button>
        </Box>
      </form>

      {/* <EmailConfirmation isOpen={isConfirmEmail} onClose={onCloseConfirmEmailHandler} /> */}

      <AutomatOffConfirmation
        isOpen={isOffAutomat}
        onClose={onCancelOffAutomatHandler}
        onSwitchOff={onSwitchOffHandler}
      />

      <ChangeGeolocation
        isOpen={isGeolocation}
        onClose={onCloseGeolocation}
        onChangeGeoData={onChangeGeoData}
        country={country}
        city={city}
        onSaveCountry={onSaveCountry}
        onSaveCity={onSaveCity}
      />

      <SaveProfileConfirmation
        isOpen={isSave}
        onClose={onCloseSaveConfirmation}
        onSubmitForm={onSubmitHandler}
        isLoading={isLoading}
        isShowLoader={isShowLoader}
      />
    </Box>
  );
};

export default ProfileForm;
