import { AxiosResponse } from 'axios';

import agent from 'src/agent';

import { TDeliveryFormValues } from '../TargetsService';

import {
  ClanLevel,
  Order,
  OrderParams,
  CreateOrder,
  DefaultOrderItem,
  TOrder,
  TOrderList,
  TGetMoney,
} from './OrdersService.types';

export const getOrdersByClanId = (clanId: number): Promise<AxiosResponse<TOrderList>> => {
  return agent.GET('orders', { params: { clan_id: clanId } });
};

export const getOrdersByParams = (params: OrderParams): Promise<AxiosResponse<TOrderList>> => {
  return agent.GET('orders', { params });
};

export const getOrderById = (orderId: number): Promise<AxiosResponse<Order>> => {
  return agent.GET(`orders/${orderId}`);
};

export const getClansListToBuy = (): Promise<AxiosResponse<ClanLevel[]>> => {
  return agent.GET('clans/clan_levels');
};

export const createClanOrder = (params: CreateOrder): Promise<AxiosResponse<TOrder>> => {
  return agent.POST('orders', params);
};

export const sendOrderConfirm = (orderId: number, txId: string): Promise<AxiosResponse<TOrder>> => {
  return agent.POST(`orders/${orderId}/confirm`, { txId });
};

export const sendTargetConfirm = (orderId: number): Promise<AxiosResponse<TOrder>> => {
  return agent.POST(`orders/${orderId}/confirm`);
};

export const getDefaultOrderItems = (): Promise<AxiosResponse<{ items: DefaultOrderItem[] }>> => {
  return agent.GET('orders/default-order-items');
};

export const createPackOrder = (items_wrappers: number[]): Promise<AxiosResponse<TOrder>> => {
  return agent.POST('orders', { items_wrappers });
};

export const createNftBurn = (compositeId: string): Promise<AxiosResponse<TOrder>> => {
  return agent.POST(`orders/nft/${compositeId}/create`);
};

export const confirmNftBurn = (compositeId: string, txId: string): Promise<AxiosResponse<TOrder>> => {
  return agent.POST(`orders/nft/${compositeId}/confirm`, { txId });
};

// --------- market

export const getMarketOrder = (target_ids?: number[]): Promise<AxiosResponse<TOrder>> => {
  return agent.POST('orders/market/create', { target_ids });
};

export const delMarketOrder = (target_ids?: number[]): Promise<AxiosResponse<TOrder>> => {
  return agent.POST('orders/market/remove-item', { ids: target_ids });
};

export const confirmMarketOrder = (
  orderId: number,
  deliveryData: TDeliveryFormValues,
  txId: string
): Promise<AxiosResponse<TOrder>> => {
  return agent.POST('orders/market/confirmed', { id: orderId, txId, ...deliveryData });
};

// ---------- get money
export const getMoneyOrder = (
  target_ids?: number[],
  amount?: number,
  updateBalance?: boolean
): Promise<AxiosResponse<TGetMoney>> => {
  return agent.POST('orders/money/create', { target_ids, amount, update_balance: updateBalance });
};

export const getMoneyСonfirm = (target_ids?: number[], amount?: number): Promise<AxiosResponse<TGetMoney>> => {
  return agent.POST('orders/money/create-and-confirm', { target_ids, amount });
};
