import { Box, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DateNotification, EmptyData, Loading, NotificationItem } from 'src/atoms';
import { LIMIT_NOTICES, getImgSrc } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { getNoticesThunk, resetNotices, selectNoticeState } from 'src/store/noticeSlice';
import { ThemePaddings } from 'src/styles';
import { formatDate, formatTime, isToday } from 'src/utils';

const NotificationsArchive: FC = () => {
  const { t } = useTranslation('app');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { notices, isPendingNotice, count, noticeError } = useAppSelector(selectNoticeState);
  const currentDate = useRef('');

  const [offset, setOffset] = useState(0);

  const onLoadMoreHandler = () => {
    if (offset >= count) return;

    currentDate.current = '';
    dispatch(getNoticesThunk({ is_read: true, offset, limit: LIMIT_NOTICES }));
    setOffset((prevOffset) => prevOffset + LIMIT_NOTICES);
  };

  useEffect(() => {
    if (!offset) {
      currentDate.current = '';
      dispatch(getNoticesThunk({ is_read: true, offset, limit: LIMIT_NOTICES }));
      setOffset(LIMIT_NOTICES);
    }
  }, [offset, dispatch]);

  useEffect(() => {
    if (noticeError) {
      enqueueSnackbar(noticeError, { variant: 'error' });
    }
  }, [enqueueSnackbar, noticeError]);

  useEffect(() => {
    if (!isPendingNotice) window.scrollTo(0, 0);
  }, [isPendingNotice]);

  useEffect(
    () => () => {
      dispatch(resetNotices());
    },
    [dispatch]
  );

  return (
    <Box sx={{ p: ThemePaddings.notificationsArchive, height: notices.length ? 'auto' : 400 }}>
      {isPendingNotice && <Loading />}

      {notices.length ? (
        <>
          {notices.map((noticeItem) => {
            let dateNode = undefined;
            /*
            const title = noticeItem.type === NoticeType.Broadcast ? `SECRET - ${t('news.title')}` : 'SECRET';
            const isBroadcast = noticeItem.type === NoticeType.Broadcast;
            const shortText = isBroadcast
              ? noticeItem.short // noticeItem.content.replace(/(<([^>]+)>)/gi, '').substring(0, 80) + '...'
              : noticeItem.content;
            const fullText = isBroadcast ? noticeItem.content : undefined;
            const likes = isBroadcast ? +noticeItem.likes_count : undefined;
            const myVote = isBroadcast ? noticeItem.my_vote : undefined;
            */
            const title = t(noticeItem.title);
            const shortText = noticeItem.short;
            const fullText = noticeItem.content;
            const imgSrc: string = getImgSrc(noticeItem.photo_uuid, 's40-c') || '';

            if (currentDate.current !== formatDate(noticeItem.created_at)) {
              dateNode = (
                <DateNotification>
                  {isToday(noticeItem.created_at) ? t('date.today') : formatDate(noticeItem.created_at)}
                </DateNotification>
              );
              currentDate.current = formatDate(noticeItem.created_at);
            }

            return (
              <Fragment key={noticeItem.id}>
                {dateNode && dateNode}
                <NotificationItem
                  id={noticeItem.id}
                  src={imgSrc}
                  title={title}
                  time={formatTime(noticeItem.created_at)}
                  shortText={shortText}
                  fullText={fullText}
                  // likes={likes}
                  // myVote={myVote}
                />
              </Fragment>
            );
          })}
          {offset < count && (
            <Button sx={{ display: 'flex', m: 'auto' }} onClick={onLoadMoreHandler}>
              {t('button.loadMore')}
            </Button>
          )}
        </>
      ) : (
        !isPendingNotice && (
          <EmptyData
            title={t('notification.emptyTitle')}
            data-qa={'notification.emptyTitle'}
            text={t('notification.emptyText')}
          />
        )
      )}
    </Box>
  );
};

export default NotificationsArchive;
