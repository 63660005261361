import { styled } from '@mui/material/styles';

export const Container = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexDirection: 'column',
  // minHeight: 200,
  marginBottom: '16px',
}));
