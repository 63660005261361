import { walletProviders } from 'src/types';

export const getTrustWallet = () => {
  const isTrustWallet = (ethereum: any) => {
    return !!ethereum.isTrust;
  };

  const injectedProviderExist = typeof window !== 'undefined' && typeof window.ethereum !== 'undefined';

  if (!injectedProviderExist) {
    return null;
  }

  if (isTrustWallet(window.ethereum)) {
    return window.ethereum;
  }

  if (window.ethereum?.providers) {
    return window.ethereum.providers.find(isTrustWallet) ?? null;
  }

  return window['trustwallet'] ?? null;
};

export const getMetamaskWallet = () => {
  const isMM = (ethereum: any) => {
    return !!ethereum.isMetaMask;
  };

  const injectedProviderExist = typeof window !== 'undefined' && typeof window.ethereum !== 'undefined';

  if (!injectedProviderExist) {
    return null;
  }

  if (isMM(window.ethereum)) {
    return window.ethereum;
  }

  if (window.ethereum?.providers) {
    return window.ethereum.providers.find(isMM) ?? null;
  }

  return null;
};

export type TProvider = {
  name: string;
  type: walletProviders;
  link: string;
  provider: any;
};

export const getProviders = (): TProvider[] => {
  return [
    {
      name: 'TrustWallet',
      type: walletProviders.TrustWallet,
      link: 'https://trustwallet.com',
      provider: getTrustWallet(),
    },

    {
      name: 'MetaMask',
      type: walletProviders.MetaMask,
      link: 'https://metamask.io',
      provider: getMetamaskWallet(),
    },
  ];
};

export const getProvider = (provType: walletProviders): TProvider | undefined => {
  const provs = getProviders();
  return provs.find((provider) => provider.type === provType);
};

export const setWalletProvider = (provider: walletProviders) => {
  localStorage.setItem('walletProvider', provider);
};

export const getWalletProvider = (): TProvider | undefined => {
  const prov = (localStorage.getItem('walletProvider') || '') as walletProviders;
  const idx = Object.values(walletProviders).indexOf(prov);
  if (idx >= 0) {
    return getProvider(prov);
  } else {
    return undefined;
  }
};

export class NoProviderError extends Error {
  constructor(providerIn: walletProviders | undefined) {
    let provTitle = 'Unknown Provider';
    switch (providerIn) {
      case walletProviders.MetaMask:
        provTitle = 'Metamask';
        break;
      case walletProviders.TrustWallet:
        provTitle = 'Trust Wallet';
        break;
    }
    super(`${provTitle} not found.`);
  }
}
