import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { WalletGradientBg } from 'src/assets/icons';
import { ThemeColors, ThemeGradients } from 'src/styles';

export const WrapWallet = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Wallet = styled(Box)(() => ({
  position: 'relative',
  width: 112,
  height: 72,
  background: ThemeGradients.wallet,
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'hidden',
}));

export const WalletTitle = styled(Typography)(() => ({
  fontSize: '0.5rem',
  lineHeight: '18px',
  fontWeight: 400,
  color: ThemeColors.blueLight,
  textTransform: 'uppercase',
  paddingLeft: 3,

  span: {
    color: ThemeColors.white,
  },
}));

export const MetaMask = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '2px 0',

  img: {
    width: 12,
    height: 12,
    margin: '0 5px',
  },

  span: {
    display: 'block',
    fontSize: '0.5rem',
    lineHeight: '8px',
    color: ThemeColors.blueLight,
    whiteSpace: 'nowrap',
  },

  p: {
    fontSize: '0.5rem',
    lineHeight: '8px',
    color: ThemeColors.white,
    overflow: 'hidden',
    margin: 0,
    textAlign: 'left',
  },
}));

export const Balance = styled(Box)(() => ({
  fontSize: '0.5rem',
  lineHeight: '18px',
  fontWeight: 600,
  color: ThemeColors.white,
  textAlign: 'left',
  textTransform: 'uppercase',
  paddingLeft: 3,
}));

export const WalletGradient = styled(Box)(() => ({
  position: 'absolute',
  width: 87,
  height: 72,
  top: 0,
  right: 0,
  background: `url(${WalletGradientBg})`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'center',
  paddingRight: 5,
}));

export const My = styled(Typography)(() => ({
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: 600,
  color: ThemeColors.white,
  textTransform: 'lowercase',
}));

export const Nft = styled(Typography)(() => ({
  fontSize: '1.25rem',
  lineHeight: '24px',
  fontWeight: 500,
  color: ThemeColors.white,
}));

export const WalletBalance = styled(Typography)(() => ({
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: 600,

  span: {
    fontWeight: 400,
    color: ThemeColors.gray500,
    marginLeft: 4,
  },
}));
