import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'src/store';

export enum SortFinanceValues {
  Asc = 'balance_change',
  Desc = '-balance_change',
}

export enum OperationTypeValues {
  Material = 'MATERIAL',
  Item = 'ITEM',
  SecretBox = 'SECRET_BOX',
  AvatarPassport = 'AVATAR_PASSPORT',
  BusinessStructures = 'BUSINESS_STRUCTURES',
}

export enum SecretBoxValues {
  Noname = 'NONAME',
  Rare = 'RARE',
  SuperRare = 'SUPER_RARE',
  Epic = 'EPIC',
  Legendary = 'LEGENDARY',
}

export enum WearableItemsValues {
  Head = 'HEAD',
  Outerwear = 'OUTERWEAR',
  Chest = 'CHEST',
  Legs = 'LEGS',
  Feet = 'FEET',
  Wrist = 'WRIST',
  Accessory = 'ACCESSORY',
  Jewelry = 'JEWELRY',
  Ankle = 'ANKLE',
  UnderwearTop = 'UNDERWEAR_TOP',
  UnderwearBottom = 'UNDERWEAR_BOTTOM',
  Tattoo = 'TATTOO',
  Entourage = 'ENTOURAGE',
}

export interface FiltersState {
  isOpenFilters: boolean;
  amount: number[] | null;
  sortFinance: SortFinanceValues | null;
  datePeriod: string[] | null;
  operationType: OperationTypeValues | null;
  secretBox: SecretBoxValues[];
  material: number[];
  wearableItems: WearableItemsValues[];
}

const initialState: FiltersState = {
  isOpenFilters: false,
  amount: null,
  sortFinance: null,
  datePeriod: null,
  operationType: null,
  secretBox: [],
  material: [],
  wearableItems: [],
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setOpenFilters: (state, action: PayloadAction<boolean>) => {
      state.isOpenFilters = action.payload;
    },
    setAmountFilter: (state, action: PayloadAction<number[] | null>) => {
      state.amount = action.payload;
    },
    setSortFinance: (state, action: PayloadAction<SortFinanceValues | null>) => {
      state.sortFinance = action.payload;
    },
    setDatePeriod: (state, action: PayloadAction<string[] | null>) => {
      state.datePeriod = action.payload;
    },
    setOperationType: (state, action: PayloadAction<OperationTypeValues | null>) => {
      state.operationType = action.payload;
      state.secretBox = [];
      state.material = [];
      state.wearableItems = [];
    },
    setSecretBox: (state, action: PayloadAction<SecretBoxValues | null>) => {
      if (action.payload) {
        const index = state.secretBox.findIndex((i) => i === action.payload);
        index < 0 ? state.secretBox.push(action.payload) : state.secretBox.splice(index, 1);
      } else {
        state.secretBox = [];
      }
    },
    setMaterial: (state, action: PayloadAction<number>) => {
      if (action.payload) {
        const index = state.material.findIndex((i) => i === action.payload);
        index < 0 ? state.material.push(action.payload) : state.material.splice(index, 1);
      } else {
        state.material = [];
      }
    },
    setWearableItems: (state, action: PayloadAction<WearableItemsValues | null>) => {
      if (action.payload) {
        const index = state.wearableItems.findIndex((i) => i === action.payload);
        index < 0 ? state.wearableItems.push(action.payload) : state.wearableItems.splice(index, 1);
      } else {
        state.wearableItems = [];
      }
    },

    resetFilters: (state) => {
      state.amount = null;
      state.sortFinance = null;
      state.datePeriod = null;
      state.operationType = null;
      state.secretBox = [];
      state.material = [];
      state.wearableItems = [];
    },
  },
});

export const {
  setOpenFilters,
  setAmountFilter,
  setSortFinance,
  setDatePeriod,
  setOperationType,
  setSecretBox,
  setMaterial,
  setWearableItems,
  resetFilters,
} = filtersSlice.actions;

export const selectOpenFiltersState = (state: RootState) => state.filters.isOpenFilters;
export const selectAmountFilterState = (state: RootState) => state.filters.amount;
export const selectSortFinanceState = (state: RootState) => state.filters.sortFinance;
export const selectDatePeriodState = (state: RootState) => state.filters.datePeriod;
export const selectOperationTypeState = (state: RootState) => state.filters.operationType;
export const selectSecretBoxState = (state: RootState) => state.filters.secretBox;
export const selectMaterialState = (state: RootState) => state.filters.material;
export const selectWearableItemsState = (state: RootState) => state.filters.wearableItems;

export const selectFiltersState = (state: RootState) => state.filters;

export const filtersReducer = filtersSlice.reducer;
