/* eslint-disable max-len */
import { PaletteMode } from '@mui/material';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import {
  SFProDisplayMediumWoff,
  SFProDisplayMediumWoff2,
  SFProDisplayRegularItalicWoff,
  SFProDisplayRegularItalicWoff2,
  SFProDisplayRegularWoff,
  SFProDisplayRegularWoff2,
  SFProDisplaySemiboldWoff,
  SFProDisplaySemiboldWoff2,
} from 'src/assets/fonts';
import { hexToRgbStr } from 'src/utils';

export const ThemeColors = {
  black: '#1D1D1F', // 0
  blackDark: '#000',
  grayDark: '#81818C',
  gray: '#505050', // 3
  grayLight: '#DFDFEB', // 9
  gray500: '#8A8A8A',
  white: '#FFFFFF',
  red: '#CA4740', // 1
  redLight: '#FF4848',
  redMiddle: '#FF4689', // выделение реферальной линии в дереве
  crimson: '#DC004E', //малиновый
  orange: '#FF9500', // 8
  green: '#52A06E', // 2
  greenLight: '#00D108',
  blue: '#0094FF', // 5
  blueLight: '#00E0FF', // 4
  purple: '#B265FF', // 6
  purpleLight: '#DB00FF', // 7
  linkBlue: '#2897FF',
  body: '#1D1D1F',
  bodyDark: '#1D1D1F',
  yellow: '#F3BA2F',
  blueDark: '#005560',
  magenta: '#FF37F7',
  violet: '#9B37FF',
  gold1: '#7E733A',
  gold2: '#F9E4BB',
};

export const ColorOfLevels = {
  gray: '#2EBD85',
  lightBlue: '#00E0FF',
  blue: '#0094FF',
  purple: '#B265FF',
  orange: '#FF9500',
};

export const ColorOfPackages: { [n: number]: string } = {
  1: ColorOfLevels.lightBlue,
  2: ColorOfLevels.blue,
  3: ColorOfLevels.purple,
  4: ColorOfLevels.orange,
};

export const LEVELS_TO_COLORS = Object.values(ColorOfLevels);

export const ThemePaddings = {
  page: '102px 16px 39px',
  pageX: '16px',
  teamTemplate: '102px 30px 0',
  teamBody: '0 18px',
  structure: '186px 0 0',
  structureTree: '155px 0 0',
  globe: '102px 0 0',
  wallet: '0 16px',
  walletTemplate: '102px 1px 0',
  myNFT: '0 16px',
  transaction: '107px 16px 39px',
  miningTemplate: '102px 16px 39px',
  notificationTemplate: '102px 1px 0',
  notifications: '0 16px 118px',
  notificationsArchive: '0 16px 26px',
};

export const ColorOfMiningLevels = {
  gray: '#505050',
  orange: '#FF8637',
  yellow: '#FBDA30',
  green: '#00D108',
  blueLight: '#00E0FF',
  blue: '#37C3FF',
  blueDark: '#377BFF',
  violet: '#9B37FF',
  magenta: '#FF37F7',
  pink: '#FF5E85',
};

// https://i2.wp.com/miro.medium.com/max/3704/1*KKvHleNH7tpSu2aB7mIWPw@2x.jpeg
export const ThemeFontWeight = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  black: 800,
  super: 900,
};

export const MINING_LEVELS_TO_COLORS = Object.values(ColorOfMiningLevels);

export const ThemeGradients = {
  bg: 'linear-gradient(45deg, #7E733A 20%, #E5A040 40%, #F9E4BB 60%, #D3A01F 80%, #7E493A 98%)',
  bgLight: `linear-gradient(90deg, ${hexToRgbStr(ThemeColors.blueLight, 0.1)} 20%, ${hexToRgbStr(
    ThemeColors.blue,
    0.1
  )} 40%, ${hexToRgbStr(ThemeColors.purple, 0.1)} 60%, ${hexToRgbStr(ThemeColors.purpleLight, 0.1)} 80%)`,
  // color: `linear-gradient(90deg, ${ThemeColors.blueLight} 20%, ${ThemeColors.blue} 40%, ${ThemeColors.purple} 60%, ${ThemeColors.purpleLight} 80%)`,
  color: 'linear-gradient(90deg, #7E733A 20%, #E5A040 40%, #F9E4BB 60%, #D3A01F 80%, #7E493A 98%)',
  text: 'linear-gradient(0deg, #7E733A 20%, #E5A040 40%, #F9E4BB 60%, #D3A01F 80%, #7E493A 98%)',
  marker:
    'linear-gradient(151.5deg, rgba(0, 209, 255, 0.99) 9.44%, #00D1FF 9.45%, #6B68FF 9.46%, #BC54FF 9.47%, #00D1FF 9.48%, #6852FF 51.04%, #DB00FF 93.08%)',
  bg2: `linear-gradient(86.07deg, #00D1FF 2.14%, #7A5DFF 51.61%, ${ThemeColors.purpleLight} 96.65%);`,
  wallet: 'linear-gradient(110.55deg, #000 0%, #8E8E93 171.91%)',
  colorLight: 'linear-gradient(77.51deg, #00D1FF -1.94%, #7DA1FD 25%, #E16CB9 59.93%, #E65B8B 93.76%)',
  bgFTBLR: `linear-gradient(90deg, ${ThemeColors.blueLight} 20%, ${ThemeColors.blue} 40%, ${ThemeColors.purple} 60%, ${ThemeColors.black} 80%)`,
  bgFTBRL: `linear-gradient(90deg, ${ThemeColors.black} 20%, ${ThemeColors.blue} 40%, ${ThemeColors.purple} 60%, ${ThemeColors.purpleLight} 80%)`,
};

export const FONTS = 'SFProDisplay, -apple-system, BlinkMacSystemFont, Arial, sans-serif';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h2grade: true;
  }
}

declare module '@mui/material/Fab' {
  interface FabPropsVariantOverrides {
    contrast: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  h2grade: React.CSSProperties;
}

declare module '@mui/material/styles' {
  interface PaletteColor {
    darker?: string;
  }

  interface TypeBackground {
    darker?: string;
  }
}

export const getTheme = (mode: PaletteMode) => {
  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 480,
        md: 600,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      mode,
      primary: {
        main: mode === 'light' ? ThemeColors.black : ThemeColors.white,
        light: ThemeColors.grayLight,
        contrastText: mode === 'light' ? ThemeColors.white : ThemeColors.black,
      },
      secondary: {
        main: mode === 'light' ? ThemeColors.gray : ThemeColors.gray500,
        light: mode === 'light' ? ThemeColors.gray : ThemeColors.grayLight,
        dark: mode === 'light' ? ThemeColors.grayLight : ThemeColors.gray,
      },
      divider: mode === 'light' ? ThemeColors.gray : ThemeColors.gray500,
      text: {
        primary: mode === 'light' ? ThemeColors.black : ThemeColors.white,
        secondary: ThemeColors.grayDark,
        disabled: ThemeColors.gray500,
      },
      background: {
        default: mode === 'light' ? ThemeColors.white : ThemeColors.black,
        paper: mode === 'light' ? ThemeColors.white : ThemeColors.blackDark,
        contrast: mode === 'light' ? ThemeColors.blackDark : ThemeColors.white,
        darker: mode === 'light' ? ThemeColors.gray500 : '#2e2e2e',
      },

      border: {
        widget: mode === 'light' ? ThemeColors.black : ThemeColors.white,
      },
    },

    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'SFProDisplay';
          src: url(${SFProDisplayRegularWoff2}) format('woff2'),
            url(${SFProDisplayRegularWoff}) format('woff');
          font-weight: 400;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'SFProDisplay';
          src: url(${SFProDisplayRegularItalicWoff2}) format('woff2'),
            url(${SFProDisplayRegularItalicWoff}) format('woff');
          font-weight: 400;
          font-style: italic;
          font-display: swap;
        }
        @font-face {
          font-family: 'SFProDisplay';
          src: url(${SFProDisplayMediumWoff2}) format('woff2'),
            url(${SFProDisplayMediumWoff}) format('woff');
          font-weight: 500;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'SFProDisplay';
          src: url(${SFProDisplaySemiboldWoff2}) format('woff2'),
            url(${SFProDisplaySemiboldWoff}) format('woff');
          font-weight: 600;
          font-style: normal;
          font-display: swap;
        }

        body {
          background-color: ${mode === 'light' ? ThemeColors.body : ThemeColors.bodyDark}
        }

        @supports (height: 100dvh) {
            :root {
                --viewport-height: 100dvh;
            }
            .h-screen {
                height: 100dvh;
            }
            .max-h-screen {
                max-height: 100dvh;
            }
        }
        @supports (-webkit-touch-callout: none) and not (height: 100dvh) {
            :root {
                --viewport-height: -webkit-fill-available;
            }
            .h-screen {
                height: -webkit-fill-available;
            }
            .max-h-screen {
                max-height: -webkit-fill-available;
            }
        }
        @supports (height: -webkit-fill-available) and not (height: 100dvh) {
            :root {
                --viewport-height: -webkit-fill-available;
            }
            .h-screen {
                height: -webkit-fill-available;
            }
            .max-h-screen {
                max-height: -webkit-fill-available;
            }
        }

        ::-webkit-scrollbar {
          display: none;
        }

        #tc-widget-root [data-tc-modal="true"] {
          z-index: 2000;
        }

        @media screen and (max-width : 600px) {
          html {
            font-size: 16px;
          }
        }

        @media screen and (max-width : 500px) {
          html {
            font-size: 15px;
          }
        }

        @media screen and (max-width : 400px) {
          html {
            font-size: 14px;
          }
        }

        @media screen and (max-width : 350px) {
          html {
            font-size: 13px;
          }
        }

        @media screen and (max-width : 300px) {
          html {
            font-size: 12px;
          }
        }
        `,
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            paddingLeft: '0!important',
            paddingRight: '0!important',
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: ({ theme }) => ({
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 30,
            background: theme.palette.background.paper,
          }),
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: FONTS,
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            background: 'none',
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
          },
          paper: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            overflow: 'visible',
            maxWidth: '600px',
            minWidth: '300px',
            margin: '0 auto',
            backgroundImage: 'none',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            maxWidth: '600px',
            minWidth: '300px',
            width: '100%',
            margin: '0 auto',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: 60,
            height: 56,
            fontSize: '1rem',
            fontWeight: ThemeFontWeight.medium,
            padding: '0 30px',
            textTransform: 'none',
            textOverflow: 'ellipsis',
            whiteSpace: 'break-spaces',
            lineHeight: '1.25em',
            boxShadow: 'none',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            '& img.MuiButton_iconimg__left': {
              margin: 0,
              marginRight: '8px',
            },
            '&:hover': {
              boxShadow: 'none',
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.default,
            },
          }),
          contained: ({ theme }) => ({
            backgroundColor: theme.palette.text.primary,
            border: 0,
            color: theme.palette.background.paper,
            '&:hover': {
              backgroundColor: theme.palette.text.primary,
              color: theme.palette.background.paper,
              opacity: 0.8,
              border: 0,
            },
            '&.Mui-disabled': {
              opacity: 0.5,
              color: theme.palette.background.paper,
            },
          }),
          outlined: ({ theme }) => ({
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            borderSpacing: 1,
            borderStyle: 'solid',
            borderColor: 'transparent',
            borderRadius: 60,
            backgroundClip: 'padding-box',
            backdropFilter: 'none',
            zIndex: 'auto',
            '&:after': {
              position: 'absolute',
              backdropFilter: 'none',
              border: 'none',
              top: -1,
              left: -1,
              right: -1,
              bottom: -1,
              background: ThemeGradients.bg,
              content: '""',
              zIndex: -1,
              borderRadius: 60,
            },
            '&:hover': {
              backgroundColor: theme.palette.background.default,
              borderColor: 'transparent',
            },
            '&.Mui-disabled': {
              background: theme.palette.background.default,
              color: theme.palette.secondary.main,
              borderColor: theme.palette.secondary.main,
            },
          }),
          containedGradient: {
            background: ThemeGradients.bg2,
            color: 'white',
            border: 0,
            '&:hover': {
              opacity: 0.8,
              border: 0,
            },
            '&.Mui-disabled': {
              opacity: 0.5,
              background: mode === 'light' ? ThemeColors.black : ThemeColors.white,
              color: 'white',
            },
          },
          text: {
            border: `1px solid ${mode === 'light' ? ThemeColors.blackDark : ThemeColors.white}`,
            color: mode === 'light' ? ThemeColors.black : ThemeColors.white,
            '&:hover': {
              backgroundColor: mode === 'light' ? ThemeColors.white : ThemeColors.blackDark,
              opacity: 0.8,
            },
            '&.Mui-disabled': {
              opacity: 0.5,
              color: mode === 'light' ? ThemeColors.black : ThemeColors.white,
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: ({ theme }) => ({
            background: theme.palette.divider,
            border: 0,
            height: 1,
          }),
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '5px',
            fontFamily: FONTS,
            fontWeight: 400,
            padding: '0px',

            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: ThemeColors.redLight,
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            '.MuiFormHelperText-root.Mui-error': {
              color: ThemeColors.redLight,
              fontFamily: FONTS,
              fontSize: '0.75rem',
            },

            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: ThemeColors.grayLight,
            },

            '&:hover': {
              '& .MuiOutlinedInput-notchedOutline': {},
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.MuiOutlinedInput-root': {
              paddingRight: 5,

              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: ThemeColors.gray500,
              },

              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: 1,
              },
            },
          },
          input: {
            '&.MuiOutlinedInput-input': {
              padding: '11.5px 16px',
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            padding: '0px',
            '&.MuiOutlinedInput-input': {
              padding: '0px 10px',
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {},
          input: {
            '&.MuiOutlinedInput-input': {
              padding: '2.5px 16px',
            },
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: ({ theme }) => ({
            backgroundColor: theme.palette.background.paper,
            border: '2px solid',
            borderColor: theme.palette.primary.main,
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: theme.palette.background.paper,
              opacity: '0.8',
            },
          }),
          contrast: () => ({
            color: ThemeColors.white,
            backgroundColor: ThemeColors.black,
            '&:hover': {
              backgroundColor: ThemeColors.black,
              opacity: '0.8',
            },
          }),
        },
      },
    },
    typography: {
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 600,

      body1: {
        fontWeight: 300,
      },
      body2: {
        fontWeight: 400,
        fontStyle: 'italic',
        fontSize: '1.125rem',
      },

      h1: {
        fontSize: '1.375rem',
        lineHeight: '1.75rem',
        fontWeight: 500,
        display: 'table',
        marginTop: '20px',
        marginBottom: '20px',
        letterSpacing: '0.35px',
      },
      h1grade: {
        fontSize: '1.375rem',
        lineHeight: '1.75rem',
        background: ThemeGradients.text,
        backgroundClip: 'text',
        fontWeight: 500,
        display: 'table',
        marginTop: '20px',
        marginBottom: '20px',
        textFillColor: 'transparent',
        letterSpacing: '0.35px',
      },
      h2: {
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
        fontWeight: 500,
      },
      h2grade: {
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
        background: ThemeGradients.text,
        backgroundClip: 'text',
        display: 'table',
        textFillColor: 'transparent',
      },
      subtitle1: {
        fontSize: '0.812rem',
        lineHeight: '1.125rem',
        fontWeight: 600,
        letterSpacing: '-0.078px',
        marginBottom: '24px',
        color: ThemeColors.gray500,
      },
    } as ExtendedTypographyOptions,
  } as ThemeOptions);
};
