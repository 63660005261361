import { Stack, Button, Box } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PrevButton, Markdown } from 'src/atoms';

import { HelpButtonDrawerProps } from './Help.types';

const Help: FC<HelpButtonDrawerProps> = ({
  tkey: tkeyProp,
  children,
  onFinish,
  stepCount: stepCountProp,
  onNext,
  onPrev,
  sx,
}) => {
  const { t } = useTranslation('app');
  const [step, setStep] = useState(0);
  const blockRef = useRef<HTMLDivElement>(null);

  const stepCount = (stepCountProp || 1) - 1;

  const tkey = stepCount > 0 ? tkeyProp + '.step' + step : tkeyProp;

  const handlerPrev = () => {
    let nextStep = step;
    if (blockRef.current) {
      blockRef.current.scrollTo(0, 0);
    }
    if (step > 0) {
      nextStep = step - 1;
      setStep(nextStep);
    }

    if (onPrev) onPrev(nextStep);
  };

  const handlerNext = () => {
    let nextStep = step;
    if (blockRef.current) {
      blockRef.current.scrollTo(0, 0);
    }
    if (step < stepCount) {
      const nextStep = step + 1;
      setStep(nextStep);
    } else {
      if (onFinish) onFinish();
    }
    if (onNext) onNext(nextStep);
  };

  return (
    <>
      <Stack
        sx={{
          position: 'relative',
          zIndex: 0,
          height: '100%',
          ...sx,
        }}
        justifyContent="space-between"
        data-qa={tkey}
      >
        {stepCount > 0 && (
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing="4px"
            sx={{
              '& .Help_step': {
                height: '4px',
                borderRadius: '4px',
                width: '100%',
                minWidth: '4px',
                backgroundColor: 'primary.main',
                opacity: 0.5,
              },
              '& .Help_step__active': {
                opacity: 1,
              },
            }}
          >
            {[...new Array(stepCount + 1)].map((_, index) => (
              <Box key={index} className={'Help_step Help_step__' + (index <= step ? 'active' : 'unactive')}></Box>
            ))}
          </Stack>
        )}
        {children ? (
          children
        ) : (
          <Box
            className="Help_block"
            ref={blockRef}
            sx={{
              flex: 1,
              overflowX: 'scroll',
              width: '100%',
              ...sx,
            }}
          >
            <Markdown>{t(`${tkey}.info.textMD`)}</Markdown>
          </Box>
        )}
        {(onPrev || onNext || onFinish || stepCount > 0) && (
          <Stack
            className="Help_buttons"
            sx={{ my: '10px' }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            {(onPrev || step > 0) && (
              <PrevButton data-qa={`${tkey}.info.button.next`} sx={{ flex: 'none' }} onClick={() => handlerPrev()} />
            )}
            {(onNext || stepCount > 0 || onFinish) && (
              <Button
                data-qa={`${tkey}.info.button.next`}
                variant="text"
                onClick={() => handlerNext()}
                fullWidth
                sx={{ flex: 1 }}
              >
                {t(`${tkey}.info.button.next`) === `${tkey}.info.button.next`
                  ? t('button.next')
                  : t(`${tkey}.info.button.next`)}
              </Button>
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default Help;
