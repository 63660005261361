import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { StepTitleProps } from './StepTitle.types';

const StepTitle: FC<StepTitleProps> = ({ step, title, subTitle }) => {
  const { t } = useTranslation('app');

  return (
    <>
      <Stack direction="row" spacing={1}>
        <Typography
          variant="h2"
          sx={{ fontWeight: 500, mb: '8px', letterSpacing: '0.38px', color: (theme) => theme.palette.secondary.main }}
        >
          {t('reg.step')} {step}.
        </Typography>
        <Typography variant="h2" component="span" sx={{ fontSize: '1.25rem', letterSpacing: '0.38px' }}>
          {title}
        </Typography>
      </Stack>
      {subTitle && <Typography variant="subtitle1">{subTitle}</Typography>}
    </>
  );
};

export default StepTitle;
