import { Instagram, Telegram, YouTube } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DrawerBottom, Markdown } from 'src/atoms';
import { Paths } from 'src/constants';
import { WindowTitle } from 'src/molecules';
import { TSocialNetworks, getSocialNetworks, setParams } from 'src/services';
import { ThemeColors } from 'src/styles';
import { parseError } from 'src/utils';

const icons = {
  instagram: Instagram,
  telegram: Telegram,
  youtube: YouTube,
};

const StudySubscribe: FC = () => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [, setLoading] = useState(true);
  const [items, setItems] = useState<TSocialNetworks[]>();
  const [check, setCheck] = useState<boolean>(false);
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    setLoading(true);
    getSocialNetworks()
      .then(({ data }) => {
        setItems(data);
      })
      .catch((error) => {
        enqueueSnackbar(parseError(error), { variant: 'error' });
      })
      .finally(() => setLoading(false));
  }, [enqueueSnackbar, update]);

  const handlerNext = () => {
    setParams({ subscribe: true })
      .then(() => navigate(Paths.study))
      .catch((error) => {
        enqueueSnackbar(parseError(error), { variant: 'error' });
      });
  };

  const handlerCheck = () => {
    setUpdate(Date.now());
    setCheck(true);
  };

  return (
    <>
      <Markdown>{t('study.subscribe.info.textMD')}</Markdown>
      {items &&
        items.map((item) => {
          const Icon = icons[item.icon];
          return (
            <Button
              data-qa={`study.subscribe.button.${item.title}`}
              key={`snbutton_${item.icon}_${item.id}`}
              variant={item.subscribed ? 'outlined' : 'text'}
              onClick={() => window.open(item.official, '_blank')}
              title={t(item.title)}
              fullWidth
              sx={{ mb: '8px', '& svg': { mr: '4px' } }}
            >
              <Icon /> {t(`study.subscribe.button.${item.title}`)}
            </Button>
          );
        })}
      <Markdown>{t('study.subscribe.check.info.textMD')}</Markdown>
      <Button
        sx={{ mb: '16px' }}
        data-qa={'study.subscribe.check.info.button.next'}
        variant="text"
        onClick={handlerCheck}
        fullWidth
      >
        {t('study.subscribe.check.info.button.next')}
      </Button>
      <Markdown>{t('study.subscribe.skip.info.textMD')}</Markdown>
      <Button
        sx={{ mb: '32px' }}
        data-qa={'study.subscribe.skip.info.button.next'}
        variant="text"
        onClick={handlerNext}
        fullWidth
      >
        {t('study.subscribe.skip.info.button.next')}
      </Button>

      <DrawerBottom isOpen={!!check} onClose={() => setCheck(false)}>
        <>
          <WindowTitle
            data-qa="study.subscribe.check.info.check.title"
            title={t('study.subscribe.check.info.check.title')}
            onClose={() => setCheck(false)}
          />
          <Markdown>{t('study.subscribe.check.info.check.textMD')}</Markdown>
          {items &&
            items.map((item) => {
              const Icon = icons[item.icon];
              return (
                <Stack direction="row" alignContent="center">
                  <Stack sx={{ flex: 'none', width: '8rem' }} direction="row" alignContent="center">
                    <Icon sx={{ mr: '8px' }} /> {item.title}
                  </Stack>
                  <Box sx={{ color: item.subscribed ? ThemeColors.greenLight : ThemeColors.redLight }}>
                    {t(
                      item.subscribed
                        ? 'study.subscribe.check.info.check.subscribed'
                        : 'study.subscribe.check.info.check.unsubscribed'
                    )}
                  </Box>
                </Stack>
              );
            })}
        </>
      </DrawerBottom>
    </>
  );
};

export default StudySubscribe;
