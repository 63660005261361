import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors, ThemeGradients } from 'src/styles';

export const ProductWrapper = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const ProductCheckbox = styled(Checkbox)(() => ({
  padding: 0,
  color: ThemeColors.black,
  marginRight: 13,

  '&.Mui-checked': {
    color: ThemeColors.black,
  },

  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const ProductImage = styled(Box)(() => ({
  width: 74,
  height: 74,
  position: 'relative',
  background: ThemeGradients.bg,
  padding: 2,
  marginRight: 9,

  img: {
    background: ThemeColors.white,
    display: 'block',
    width: 70,
    height: 70,
  },
}));

export const ProductInfo = styled(Stack)(() => ({
  flexBasis: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const ProductPrice = styled(Typography)(() => ({
  alignSelf: 'flex-end',
  whiteSpace: 'nowrap',
  fontSize: '0.688rem',
  lineHeight: '13px',
  fontWeight: 400,

  span: {
    color: ThemeColors.gray500,
  },
}));

export const Name = styled(Typography)(() => ({
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: 600,
  marginBottom: 2,
}));

export const Type = styled(Typography)(() => ({
  fontSize: '0.688rem',
  lineHeight: '13px',
  fontWeight: 400,
  color: ThemeColors.gray500,
  letterSpacing: '0.066px',
}));

export const PriceTitle = styled(Typography)(() => ({
  fontSize: '0.688rem',
  lineHeight: '13px',
  fontWeight: 400,
  color: ThemeColors.gray500,
  letterSpacing: '0.066px',

  svg: {
    display: 'inline-block',
    verticalAlign: -4,
    marginRight: 3,
    fill: ThemeColors.yellow,
  },
}));
