import { useSnackbar } from 'notistack';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { setGeolocation } from 'src/services';
import { parseErrorTr } from 'src/utils';

import { GeolocationTranslateProps } from './GeolocationTranslate.types';

const GeolocationTranslate: FC<GeolocationTranslateProps> = ({ send, onSend, onRermission, onError }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('app');

  useEffect(() => {
    if (send) {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (onRermission) onRermission();
            setGeolocation(position.coords.latitude, position.coords.longitude)
              .then(() => {
                if (onSend) onSend();
              })
              .catch((error) => {
                if (onError) onError(error);
              });
          },
          (error: GeolocationPositionError) => {
            if (error.code === error.PERMISSION_DENIED)
              enqueueSnackbar(t(parseErrorTr('navigator.geolocation', 'PERMISSION_DENIED')), { variant: 'info' });
            else if (error.code === error.POSITION_UNAVAILABLE)
              enqueueSnackbar(t(parseErrorTr('navigator.geolocation', 'POSITION_UNAVAILABLE')), { variant: 'info' });
            else if (error.code === error.TIMEOUT)
              enqueueSnackbar(t(parseErrorTr('navigator.geolocation', 'TIMEOUT')), { variant: 'info' });
          }
        );
      }
    }
  }, [enqueueSnackbar, onError, onRermission, onSend, send, t]);
  return null;
};

export default GeolocationTranslate;
