import { FC } from 'react';

import { LevelsIcon } from 'src/assets/icons';
import { levels } from 'src/constants';
import { ThemeColors } from 'src/styles';

import { Levels, Level, Number } from './LevelOfMemberTeam.styles';
import { LevelOfMemberTeamProps } from './LevelOfMemberTeam.types';

const LevelOfMemberTeam: FC<LevelOfMemberTeamProps> = ({ level }) => {
  return (
    <Levels flexDirection="row">
      {levels.map((item) => (
        <Level sx={{ color: item.color }} key={item.level}>
          <Number
            sx={{
              backgroundColor: item.level <= level ? item.color : 'transparent',
              color: item.level <= level ? ThemeColors.white : ThemeColors.gray,
            }}
          >
            {item.level}
          </Number>
          <LevelsIcon />
        </Level>
      ))}
    </Levels>
  );
};

export default LevelOfMemberTeam;
