import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { FC, useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Loading, DrawerBottom, SearchField, Markdown, CircleLoading } from 'src/atoms';
import { TeamFilterContext } from 'src/context';
import { ErrorContext } from 'src/context/ErrorContext';
import { useAppSelector } from 'src/hooks';
import { DrawerShareText, GoalStatusFilter, InfoTable, WindowTitle } from 'src/molecules';
import { MapboxGlobe } from 'src/organisms';
import {
  getGlobeData,
  GlobePoint,
  GlobeType,
  TTargetStatus,
  TTeamMeta,
  FilterStatusList,
  UserRole,
  FilterRoleList,
} from 'src/services';
import { selectUserMe } from 'src/store/userSlice';
import { SearchParams, logger } from 'src/utils';

/*
const offset_coord = (items: GlobePoint[], myId: number) => {
  const RANDOM_OFFSET = 10;
  return items.map((item) => {
    const itsMe = item.properties.user_id === myId;
    if (!itsMe) {
      item.geometry.coordinates[0] = item.geometry.coordinates[0] + (0.5 - Math.random()) / RANDOM_OFFSET;
      item.geometry.coordinates[1] = item.geometry.coordinates[1] + (0.5 - Math.random()) / RANDOM_OFFSET;
    }
    return item;
  });
};*/

const TeamGlobe: FC = () => {
  const { t } = useTranslation('app');
  const { pathname, search: searchParams } = useLocation();
  const userId = new SearchParams(searchParams).getByType('userId', 'number') as number | undefined;
  const { throwServerError } = useContext(ErrorContext);
  const userMe = useAppSelector(selectUserMe);

  const loaderSize = 345;

  //const navigate = useNavigate();
  //const onCloseHandler = () => navigate(Paths.dashboard);

  const [data, setData] = useState<GlobePoint[]>([]);
  const [meta, setMeta] = useState<TTeamMeta>();
  const [isCircleLoading, setCircleLoading] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [alone, setAlone] = useState<boolean | null>(null);
  const [refer, setRefer] = useState(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [searchFilter, setSearchFilter] = useState<string | undefined>();

  const {
    role: roleFilter,
    status: statusFilter,
    changeRole: setRoleFilter,
    changeStatus: setStatusFilter,
  } = useContext(TeamFilterContext);

  const [zoom, setZoom] = useState<number>();
  const [bounds, setBounds] = useState<[number, number, number, number]>();

  const setAloneTrue = useCallback(() => {
    if (alone === null) setAlone(true);
  }, [alone]);

  useEffect(() => {
    // const type = pathname === Paths.teamGlobeAll ? GlobeType.AllProject : GlobeType.MyTeam;
    const type = GlobeType.MyTeam;
    setLoading(true);
    getGlobeData(
      type,
      {
        search: searchFilter,
        status: statusFilter,
        userId,
        role: roleFilter,
      },
      zoom,
      bounds
    )
      .then(({ data }) => {
        setData(data.items);
        // setData(mockItems);
        setMeta(data.meta);
        if (data.meta?.team.have_team === false && !userId) {
          setAloneTrue();
        }
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
        logger.error(error);
      })
      .finally(() => {
        setLoading(false);
        setCircleLoading(false);
      });
  }, [pathname, userId, throwServerError, searchFilter, statusFilter, roleFilter, zoom, bounds, setAloneTrue]);

  const handlerZoom = (zoom: number, bounds: [number, number, number, number]) => {
    setZoom(zoom);
    setBounds(bounds);
  };

  return (
    <>
      {isLoading && <Loading />}
      {
        <Box
          sx={{
            position: 'absolute',
            top: 164,
            zIndex: 3,
            left: '50%',
            right: 0,
            transform: 'translateX(-50%)',
            width: '100%',
            maxWidth: '510px',
            padding: '16px 16px 0px',
            pointerEvents: 'none',
          }}
        >
          {/*<Switch2Links currentPath={pathname} links={getSwitchLinksForGlobe(t)} isInverted />*/}
          {!searchOpen ? (
            <IconButton
              sx={{
                border: '1px solid',
                backgroundColor: 'background.paper',
                pointerEvents: 'auto',
                float: 'right',
              }}
              onClick={() => setSearchOpen(true)}
            >
              <SearchIcon />
            </IconButton>
          ) : (
            <SearchField
              sx={{
                pointerEvents: 'auto',
              }}
              name="search"
              defaultValue={searchFilter}
              placeholder={t('team.globe.search.placeholder')}
              maxlength={150}
              onClear={() => {
                setSearchFilter(undefined);
                setSearchOpen(false);
              }}
              onSearch={(value) => {
                setSearchFilter(value);
              }}
            />
          )}
        </Box>
      }
      <Box
        className="h-screen"
        sx={{
          width: '100%',
          position: 'absolute',
          top: 0,
          zIndex: 'auto',
          '& .Globe_Loader': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 1,
            '&__circle': {
              position: 'absolute',
              zIndex: 1,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            },

            '&:before': {
              content: '""',
              position: 'absolute',
              left: 0,
              right: 0,
              zIndex: 1,
              display: 'block',
              width: '100%',
              height: '100%',
              background: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(16px)',
            },
          },
        }}
      >
        {isCircleLoading && (
          <Box className="Globe_Loader">
            <CircleLoading isLoading={true} size={loaderSize} className="Globe_Loader__circle" />
          </Box>
        )}
        {data && <MapboxGlobe data={data} userId={userId} onZoom={handlerZoom} logoPosition="top-left" />}
      </Box>

      <Stack
        sx={{
          width: '100%',
          position: 'absolute',
          bottom: 77,
          padding: '0px 16px 4px',
        }}
      >
        <GoalStatusFilter
          sx={{ width: '100%' }}
          value={statusFilter}
          filter={FilterStatusList}
          tkey="goal.state"
          onChange={(status) => setStatusFilter(status as TTargetStatus[])}
        />

        <GoalStatusFilter
          sx={{ width: '100%', mt: '10px' }}
          value={roleFilter}
          filter={FilterRoleList}
          tkey="role.state"
          onChange={(role) => setRoleFilter(role as UserRole[])}
        />
      </Stack>

      <InfoTable
        sx={{
          borderTop: '1px solid',
          borderColor: 'secondary.main',
          width: '100%',
          position: 'absolute',
          bottom: 0,
          paddingBottom: '2px',
        }}
        items={[
          {
            title: t('team.meta.team.title'),
            mark: t('team.meta.team.mark'),
            value: meta?.team.team_number,
          },
          {
            title: t('team.meta.private.title'),
            mark: t('team.meta.private.mark'),
            value: meta?.team.personal_team_number,
          },
        ]}
      />

      <DrawerBottom isOpen={!!alone} onClose={() => setAlone(false)}>
        <WindowTitle
          title={t('globeTeam.alone.title')}
          data-qa={'globeTeam.alone.title'}
          onClose={() => setAlone(false)}
        />
        <Box sx={{ position: 'relative', zIndex: 0 }}>
          <Markdown>{t('globeTeam.alone.textMD')}</Markdown>
          <Button
            variant="outlined"
            onClick={() => {
              setAlone(false);
              setRefer(true);
            }}
            fullWidth
          >
            {t('globeTeam.alone.button')}
          </Button>
        </Box>
      </DrawerBottom>

      {userMe && (
        <DrawerShareText
          title={userMe.display_name || t('onboarding.unknownName')}
          link={userMe.referral_link}
          isOpen={refer}
          tKey="referal"
          onClose={() => setRefer(false)}
        />
      )}
    </>
  );
};

export default TeamGlobe;
