import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeFontWeight } from 'src/styles';

export const ProductWidgetBox = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  minHeight: 140,
  borderRadius: 15,
  cursor: 'pointer',
  overflow: 'hidden',
})) as typeof Stack;

export const Title = styled('span')(() => ({
  fontSize: '1.5rem',
  fontWeight: ThemeFontWeight.regular,
  textTransform: 'uppercase',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '100%',
  position: 'relative',
  textAlign: 'center',
}));

export const Subtitle = styled('span')(() => ({
  fontSize: '0.75rem',
  fontWeight: ThemeFontWeight.regular,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export const PriceBlock = styled(Stack)(({ theme }) => ({
  padding: '0px 4px 4px',
  backgroundColor: theme.palette.background.default,
  width: '100%',
  borderRadius: '10px',
}));

export const EmptyTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.375rem',
  fontWeight: ThemeFontWeight.regular,
  color: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})) as typeof Typography;

export const EmptyImage = styled('img')(() => ({
  overflow: 'auto',
}));
