import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import BPrice from '../BPrice';

import { CostCalc, CostCalcLabel } from './PaymentCost.styles';
import { PaymentCostProps } from './PaymentProducts.types';

const PaymentCost: FC<PaymentCostProps> = ({ tkey, price, sx }) => {
  const { t } = useTranslation('app');

  return (
    <CostCalc sx={sx}>
      <CostCalcLabel className="CostCalc_label">{t(tkey)}:</CostCalcLabel>
      <BPrice sx={{ fontSize: '1.062rem' }} price={price} />
    </CostCalc>
  );
};

export default PaymentCost;
