import { AxiosResponse } from 'axios';

import agent from 'src/agent';

import { Language, Phrase } from './TranslationsService.types';

export const getLanguages = (): Promise<AxiosResponse<Language[]>> => {
  return agent.GET('translations/languages/');
};

export const getPhrases = (langId: number): Promise<AxiosResponse<Phrase[]>> => {
  return agent.GET('translations/phrase-translations', { params: { language_id: langId } });
};
