import { FC } from 'react';

import { StepperRoot, StepperLines, StepperLine } from './LineStepper.styles';
import { LineStepperProps } from './LineStepper.types';

const LineStepper: FC<LineStepperProps> = (props) => {
  const { activeStep = 0, steps = 0 } = props;

  return (
    <StepperRoot square elevation={0}>
      <StepperLines>
        {[...new Array(steps)].map((_, index) => (
          <StepperLine key={index} isActive={index <= activeStep} />
        ))}
      </StepperLines>
    </StepperRoot>
  );
};

export default LineStepper;
