import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { EmptyAvatarEnergyImage, FillAvatarEnergyImage } from 'src/assets/images';
import { ThemeColors, ThemeGradients } from 'src/styles';

export const AvatarWrapper = styled(Box)(() => ({
  position: 'relative',
  height: 234,
  marginTop: 41,
  marginBottom: 38,
  background: `url(${EmptyAvatarEnergyImage}) bottom right no-repeat`,

  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    width: 27,
    height: 1,
    backgroundColor: ThemeColors.white,
    zIndex: 5,
  },
}));

export const FillAvatarEnergy = styled(Box)(() => ({
  position: 'absolute',
  right: 0,
  bottom: 0,
  width: 236,
  background: `url(${FillAvatarEnergyImage}) bottom right no-repeat`,
}));

export const Percent = styled(Typography)(() => ({
  position: 'absolute',
  top: -18,
  width: 64,
  right: 0,
  fontSize: '0.94rem',
  lineHeight: '20px',
  fontWeight: 600,
  textAlign: 'center',
}));

export const CurrentPoint = styled(Box)(() => ({
  position: 'absolute',
  left: 0,
  bottom: 0,
  zIndex: 2,
  width: '100%',

  div: {
    bottom: -14,
  },

  '&:before': {
    content: '""',
    position: 'absolute',
    top: '100%',
    left: '0',
    width: '100%',
    height: 1,
    backgroundColor: ThemeColors.grayLight,
    zIndex: -3,
  },
}));

export const FromPoint = styled(CurrentPoint)(() => ({
  bottom: 0,
  top: 'unset',

  div: {
    bottom: -14,
    top: 'unset',
  },

  '&:before': {
    top: '100%',
    bottom: 'unset',
    width: 'calc(100% - 14px)',
  },
}));

export const ToPoint = styled(CurrentPoint)(() => ({
  bottom: 'unset',
  top: 0,

  div: {
    bottom: 'unset',
    top: -14,
  },

  '&:before': {
    top: 'unset',
    bottom: '100%',
    width: 'calc(100% - 30px)',
  },
}));

export const SubTitle = styled(Typography)(() => ({
  fontSize: '0.94rem',
  lineHeight: '20px',
  fontWeight: 600,
  background: ThemeGradients.text,
  backgroundClip: 'text',
  textFillColor: 'transparent',
  marginBottom: 11,
}));

export const Price = styled(Typography)(() => ({
  fontSize: '1.25rem',
  lineHeight: '24px',
  fontWeight: 500,
  marginBottom: 11,

  span: {
    color: ThemeColors.gray500,
    marginLeft: 4,
  },
}));

export const RestoreButton = styled(Button)(() => ({
  width: '100%',
  marginBottom: 16,
}));

export const PricePoint = styled(Box)(() => ({
  position: 'relative',
  border: '2px solid transparent',
  backgroundClip: 'padding-box',
  borderRadius: 16,
  backgroundColor: ThemeColors.black,
  display: 'inline-block',
  padding: '3px 11px',
  fontSize: '0.812rem',
  lineHeight: '18px',
  fontWeight: 600,
  color: ThemeColors.white,

  span: {
    color: ThemeColors.gray500,
    marginLeft: 4,
  },

  '&:before': {
    position: 'absolute',
    top: -1,
    left: -1,
    right: -1,
    bottom: -1,
    background: ThemeColors.white,
    content: '""',
    zIndex: -2,
    borderRadius: 16,
  },

  '&:after': {
    position: 'absolute',
    top: -3,
    left: -3,
    right: -3,
    bottom: -3,
    background: ThemeGradients.bg,
    content: '""',
    zIndex: -3,
    borderRadius: 16,
  },
}));
