import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';
import { Sizes } from 'src/types';

import { Styles, BoxProps } from './Triangle.types';

const styles: Styles = {
  [Sizes.Large]: {
    triangleWidth: 304,
    triangleHeight: 256,
    marginBottomLevels: 11,
    widthTopLevel: 72,
    heightTopLevel: 72,
    widthSecondLevel: 56,
    heightSecondLevel: 56,
    widthThirdLevel: 40,
    heightThirdLevel: 40,
    marginXSecondLevel: '0 28px',
    marginXThirdLevel: '0 11px',
    widthItemsThirdLevel: 91,
    leftLineTopLevelTop: 63,
    leftLineTopLevelLeft: 8,
    leftLineTopLevelHeight: 33,
    rightLineTopLevelTop: 63,
    rightLineTopLevelRight: 8,
    rightLineTopLevelHeight: 33,
    leftLineSecondLevelTop: 51,
    leftLineSecondLevelLeft: 11,
    leftLineSecondLevelHeight: 18,
    rightLineSecondLevelTop: 51,
    rightLineSecondLevelRight: 11,
    rightLineSecondLevelHeight: 18,
    borderTopWidth: 2,
    borderSecondWidth: 2,
    borderThirdWidth: 2,
  },
  [Sizes.Medium]: {
    triangleWidth: 178,
    triangleHeight: 150,
    marginBottomLevels: 6,
    widthTopLevel: 40,
    heightTopLevel: 40,
    widthSecondLevel: 32,
    heightSecondLevel: 32,
    widthThirdLevel: 24,
    heightThirdLevel: 24,
    marginXSecondLevel: '0 17px',
    marginXThirdLevel: '0 6px',
    widthItemsThirdLevel: 54,
    leftLineTopLevelTop: 35,
    leftLineTopLevelLeft: 5,
    leftLineTopLevelHeight: 20,
    rightLineTopLevelTop: 35,
    rightLineTopLevelRight: 5,
    rightLineTopLevelHeight: 20,
    leftLineSecondLevelTop: 29,
    leftLineSecondLevelLeft: 6,
    leftLineSecondLevelHeight: 12,
    rightLineSecondLevelTop: 29,
    rightLineSecondLevelRight: 6,
    rightLineSecondLevelHeight: 12,
    borderTopWidth: 2,
    borderSecondWidth: 2,
    borderThirdWidth: 2,
  },
  [Sizes.Small]: {
    triangleWidth: 64,
    triangleHeight: 54,
    marginBottomLevels: 2,
    widthTopLevel: 16,
    heightTopLevel: 16,
    widthSecondLevel: 12,
    heightSecondLevel: 12,
    widthThirdLevel: 10,
    heightThirdLevel: 10,
    marginXSecondLevel: '0 7px',
    marginXThirdLevel: '0 2px',
    widthItemsThirdLevel: 22,
    leftLineTopLevelTop: 13,
    leftLineTopLevelLeft: 0,
    leftLineTopLevelHeight: 8,
    rightLineTopLevelTop: 13,
    rightLineTopLevelRight: 0,
    rightLineTopLevelHeight: 8,
    leftLineSecondLevelTop: 11,
    leftLineSecondLevelLeft: 2,
    leftLineSecondLevelHeight: 4,
    rightLineSecondLevelTop: 11,
    rightLineSecondLevelRight: 2,
    rightLineSecondLevelHeight: 4,
    borderTopWidth: 1,
    borderSecondWidth: 1,
    borderThirdWidth: 1,
  },
  [Sizes.Widget]: {
    triangleWidth: 112,
    triangleHeight: 96,
    marginBottomLevels: 4,
    widthTopLevel: 24,
    heightTopLevel: 24,
    widthSecondLevel: 20,
    heightSecondLevel: 20,
    widthThirdLevel: 16,
    heightThirdLevel: 16,
    marginXSecondLevel: '0 12px',
    marginXThirdLevel: '0 4px',
    widthItemsThirdLevel: 36,
    leftLineTopLevelTop: 20,
    leftLineTopLevelLeft: 0,
    leftLineTopLevelHeight: 13,
    rightLineTopLevelTop: 20,
    rightLineTopLevelRight: 0,
    rightLineTopLevelHeight: 13,
    leftLineSecondLevelTop: 18,
    leftLineSecondLevelLeft: 3,
    leftLineSecondLevelHeight: 7,
    rightLineSecondLevelTop: 18,
    rightLineSecondLevelRight: 3,
    rightLineSecondLevelHeight: 7,
    borderTopWidth: 1,
    borderSecondWidth: 1,
    borderThirdWidth: 1,
  },
};

export const TriangleWrapper = styled(Box)<BoxProps>(({ size }) => ({
  position: 'relative',
  width: styles[size].triangleWidth,
  height: styles[size].triangleHeight,
  margin: '0 auto',

  '& > svg': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: styles[size].triangleWidth,
    height: styles[size].triangleHeight,
    fill: 'currentColor',
    zIndex: 1,
  },
}));

export const Clan = styled(Stack)(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  zIndex: 2,
}));

export const TopLevel = styled(Box)<BoxProps>(({ size }) => ({
  height: styles[size].heightTopLevel,
  marginBottom: styles[size].marginBottomLevels,

  svg: {
    width: styles[size].widthTopLevel,
    height: styles[size].heightTopLevel,
  },

  '& .MuiBox-root': {
    '&:before': {
      top: styles[size].leftLineTopLevelTop,
      left: styles[size].leftLineTopLevelLeft,
      height: styles[size].leftLineTopLevelHeight,
    },
    '&:after': {
      top: styles[size].rightLineTopLevelTop,
      right: styles[size].rightLineTopLevelRight,
      height: styles[size].rightLineTopLevelHeight,
    },

    '& .MuiIconButton-root': {
      padding: 0,
      width: styles[size].widthTopLevel,
      height: styles[size].heightTopLevel,
      borderRadius: '100%',
      border: `${styles[size].borderTopWidth}px solid`,
      zIndex: 1,

      '& .MuiAvatar-root': {
        width: '100%',
        height: '100%',
      },
    },
  },
}));

export const SecondLevel = styled(Box)<BoxProps>(({ size }) => ({
  height: styles[size].heightSecondLevel,
  marginBottom: styles[size].marginBottomLevels,
  display: 'flex',

  svg: {
    display: 'block',
    width: styles[size].widthSecondLevel,
    height: styles[size].heightSecondLevel,
  },

  '& .MuiBox-root': {
    margin: styles[size].marginXSecondLevel,

    '&:before': {
      top: styles[size].leftLineSecondLevelTop,
      left: styles[size].leftLineSecondLevelLeft,
      height: styles[size].leftLineSecondLevelHeight,
    },
    '&:after': {
      top: styles[size].rightLineSecondLevelTop,
      right: styles[size].rightLineSecondLevelRight,
      height: styles[size].rightLineSecondLevelHeight,
    },

    '& .MuiIconButton-root': {
      padding: 0,
      width: styles[size].widthSecondLevel,
      height: styles[size].heightSecondLevel,
      borderRadius: '100%',
      border: `${styles[size].borderSecondWidth}px solid`,
      zIndex: 1,

      '& .MuiAvatar-root': {
        width: '100%',
        height: '100%',
      },
    },
  },
}));

export const ThirdLevel = styled(Box)<BoxProps>(({ size }) => ({
  height: styles[size].heightThirdLevel,
  display: 'flex',

  svg: {
    width: styles[size].widthThirdLevel,
    height: styles[size].heightThirdLevel,
  },

  '& .MuiBox-root': {
    display: 'flex',
    justifyContent: 'space-between',
    width: styles[size].widthItemsThirdLevel,
    margin: styles[size].marginXThirdLevel,

    '& .MuiIconButton-root': {
      padding: 0,
      width: styles[size].widthThirdLevel,
      height: styles[size].heightThirdLevel,
      borderRadius: '100%',
      border: `${styles[size].borderThirdWidth}px solid`,
      zIndex: 1,

      '& .MuiAvatar-root': {
        width: '100%',
        height: '100%',
      },
    },
  },
}));

export const ClanMembers = styled(Box)(({ theme }) => ({
  position: 'relative',
  lineHeight: 0,
  color: theme.palette.background.paper,

  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: 1,
    background: ThemeColors.grayLight,
    transform: 'rotate(35deg)',
    zIndex: 0,
  },

  '&:after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: 1,
    background: ThemeColors.grayLight,
    transform: 'rotate(-35deg)',
    zIndex: 0,
  },

  '& .MuiAvatar-root': {
    backgroundColor: 'transparent',
  },
}));
