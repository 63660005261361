import { Box, Button, Typography, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AvatarGenderMale, AvatarGenderFemale } from 'src/assets/images';

import { AvatarGenderSelectorProps, Gender } from './AvatarGenderSelector.types';

const AvatarGenderSelector: FC<AvatarGenderSelectorProps> = ({ onChange, gender }) => {
  const { t } = useTranslation('app');

  const handleSelect = (gender: Gender) => {
    onChange(gender);
  };

  return (
    <Box sx={{ flex: '1 1' }}>
      <Typography variant="h2">{t('avatar.gender.title')}</Typography>
      <Stack direction="row" justifyContent="space-around">
        <Box
          component="img"
          sx={{ my: 2, opacity: gender === Gender.male ? 1 : 0.3 }}
          src={AvatarGenderMale}
          alt={t('avatar.gender.male')}
          onClick={() => handleSelect(Gender.male)}
        />
        <Box
          component="img"
          sx={{ my: 2, opacity: gender === Gender.female ? 1 : 0.3 }}
          src={AvatarGenderFemale}
          alt={t('avatar.gender.female')}
          onClick={() => handleSelect(Gender.female)}
        />
      </Stack>
      <Stack direction="row" justifyContent="stretch">
        <Button
          sx={{ mr: 0.5, flex: '1 1' }}
          variant={gender === Gender.male ? 'contained' : 'text'}
          onClick={() => handleSelect(Gender.male)}
        >
          {t('avatar.gender.male')}
        </Button>
        <Button
          sx={{ ml: 0.5, flex: '1 1' }}
          variant={gender === Gender.female ? 'contained' : 'text'}
          onClick={() => handleSelect(Gender.female)}
        >
          {t('avatar.gender.female')}
        </Button>
      </Stack>
    </Box>
  );
};

export default AvatarGenderSelector;
