import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors, ThemeGradients } from 'src/styles';

export const SwitchStyled = styled(Switch)(() => ({
  flexBasis: 60,
  width: 60,
  height: 30,
  padding: 0,

  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '2px',
    transitionDuration: '300ms',
    color: ThemeColors.black,

    '&.Mui-checked': {
      transform: 'translateX(30px)',

      '& + .MuiSwitch-track': {
        background: ThemeColors.white,
        opacity: 1,
      },
      '& .MuiSwitch-thumb': {
        background: ThemeGradients.bg,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 26,
    height: 26,
    border: `1px solid ${ThemeColors.grayLight}`,
  },

  '& .MuiSwitch-track': {
    borderRadius: 30 / 2,
    backgroundColor: ThemeColors.white,
    border: '1px solid #E9E9EA',
    height: 30,
    opacity: 1,
    transition: 'background-color 300ms',
  },
}));
