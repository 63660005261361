import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors, ThemeGradients } from 'src/styles';

export const Text = styled(Typography)(() => ({
  fontSize: '0.688rem',
  lineHeight: '13px',
  color: ThemeColors.gray500,
  marginBottom: 16,
}));

export const SubTitle = styled(Typography)(() => ({
  fontSize: '0.94rem',
  lineHeight: '20px',
  fontWeight: 600,
  background: ThemeGradients.text,
  backgroundClip: 'text',
  textFillColor: 'transparent',
  marginBottom: 8,
}));

export const Place = styled(Typography)(() => ({
  fontSize: '1.25rem',
  lineHeight: '24px',
  marginBottom: 16,

  span: {
    color: ThemeColors.gray500,
    marginLeft: 8,
  },
}));

export const ChartWrapper = styled(Box)(() => ({
  height: 222,
  marginBottom: 16,
}));
