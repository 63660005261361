import * as d3 from 'd3';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// import { worldWidgetImage } from 'src/assets/images';
import { Paths } from 'src/constants';
import { ImageWidget } from 'src/molecules';
import { ThemeColors } from 'src/styles';
import { loadGeoJson } from 'src/utils/layers/h3-datagen';

import { WorldTitle } from './WorldWidget.styles';
import { WorldWidgetProps } from './WorldWidget.types';

/*
const arcsData = new Array(30).fill(0).map(() => ({
  startLat: (Math.random() - 0.5) * 180,
  startLng: (Math.random() - 0.5) * 360,
  endLat: (Math.random() - 0.5) * 180,
  endLng: (Math.random() - 0.5) * 360,
  color: [ThemeColors.blue, ThemeColors.blueLight, ThemeColors.purple, ThemeColors.purpleLight],
}));
*/

const Globe: FC<{ size: number; geoJson: any }> = ({ size, geoJson }) => {
  const [rotation, setRotation] = useState(0);

  //d3.json('https://codepen.io/frontendcharts/pen/JBprpp.js', (err, json) => {
  // let geoJson = topojson.feature(json, json.objects.ne_110m_admin_0_countries);

  const projection = d3.geoOrthographic().fitSize([size, size], geoJson).rotate([rotation, 0, 0]);

  const geoGenerator = d3.geoPath().projection(projection);
  const pathString = geoGenerator(geoJson);

  const graticules = d3.geoGraticule()();
  const pathArc = geoGenerator(graticules);

  window.requestAnimationFrame(() => {
    setRotation(rotation + 0.2);
  });

  const scale = 0.99;
  const translate = (size - size * scale) / 2;

  const scale0 = 0.85;
  const translate0 = (size - size * scale0) / 2;

  return (
    <svg width={size} height={size}>
      <g transform={`scale(${scale0}, ${scale0}) translate(${translate0}, ${translate0})`}>
        <path
          stroke={ThemeColors.gold2}
          strokeWidth="0.5"
          transform={`scale(${scale}, ${scale}) translate(${translate}, ${translate})`}
          d={pathArc || undefined}
        />
        <path fill="none" stroke={ThemeColors.gold2} strokeWidth="6.0" d={pathString || undefined} />
        <path fill={ThemeColors.blackDark} stroke="none" d={pathString || undefined} />
        {/*arcsData.map((arc) => {
        const circle = d3.geoCircle().center([arc.startLat, arc.startLng]).radius(5);
        const pathCircle = geoGenerator(circle());
        return pathCircle && <path stroke={ThemeColors.red} strokeWidth="2.5" d={pathCircle} />;
      })*/}
      </g>
    </svg>
  );
};

const WorldWidget: FC<WorldWidgetProps> = ({ size, sx }) => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  const [geoJson, setGeoJson] = useState<any>();

  useEffect(() => {
    loadGeoJson().then((geoJson) => {
      setGeoJson(geoJson);
    });
  }, []);

  const onClickHandler = () => {
    navigate(Paths.globeAll);
  };

  return (
    <ImageWidget
      onClick={onClickHandler}
      sx={sx}
      value={<WorldTitle component="div">{t('dashboard.worldText')}</WorldTitle>}
    >
      {geoJson && <Globe size={(size || 120) - 20} geoJson={geoJson} />}
      {/* <Image src={worldWidgetImage} /> */}
    </ImageWidget>
  );
};

export default WorldWidget;
