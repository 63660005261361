import { FC, useState, useCallback, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerChooser, CarouselMaterialTitle } from 'src/atoms';
import { ErrorContext } from 'src/context/ErrorContext';
import { MaterialsCarousel } from 'src/molecules';
import { getNFTClothesMock as getNFTClothes } from 'src/services';
import { NFTClothesResult } from 'src/services/NFTService/NFTService.types';

import { DrawerAvatarClothesChooserProps, ClotheItemProps } from './DrawerAvatarClothesChooser.types';

const dataToMaterialItem = (data: NFTClothesResult[]): ClotheItemProps[] => {
  return data.map((material, idx) => {
    return {
      id: `${material.material_level_tier}_${idx}`,
      img: material.image,
      level: parseInt(material.material_level_tier, 10),
      levelName: material.material_level_name,
      type: parseInt(material.material_type_tier, 10),
      typeName: material.material_type_name,
      grade: parseInt(material.material_grade_tier, 10),
      gradeName: material.material_grade_name,
    };
  });
};

const DrawerAvatarSkinChooser: FC<DrawerAvatarClothesChooserProps> = ({ isOpen, onClose, onApply, onSelect }) => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);

  const [items, setItems] = useState<ClotheItemProps[]>();
  const [selected, setSelected] = useState<string | undefined>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const handleChange = (itemId: string | undefined) => {
    setSelected(itemId);
    setIsChanged(true);

    const selectedItem = items?.find((item) => item.id === itemId);
    onSelect && onSelect(selectedItem?.level || 0, selectedItem?.type || 0, selectedItem?.grade || 0);
  };

  const readData = useCallback(() => {
    getNFTClothes({})
      .then(({ data }) => {
        const resultItems = dataToMaterialItem(data.results);
        setItems(resultItems);
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
      });
  }, [throwServerError]);

  useEffect(() => {
    if (isOpen) readData();
  }, [readData, isOpen]);

  const selectedItem = items?.find((item) => item.id === selected);

  const handlerApply = () =>
    onApply && onApply(selectedItem?.level || 0, selectedItem?.type || 0, selectedItem?.grade || 0);

  return (
    <DrawerChooser
      isOpen={isOpen}
      onClose={onClose}
      isChanged={isChanged}
      onApply={handlerApply}
      titleComponent={<CarouselMaterialTitle item={selectedItem} title={t('avatar.menu.clothes')} />}
    >
      <MaterialsCarousel items={items} selected={selected} onSelect={(id) => handleChange(id)} />
    </DrawerChooser>
  );
};

export default DrawerAvatarSkinChooser;
