import { Drawer, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DrawerStyled = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    border: 'none',
  },
}));

export const DrawerBoxStyled = styled(Box)(({ theme }) => ({
  width: 'auto',
  background: theme.palette.background.paper,
  borderRadius: '16px 16px 0 0',
  padding: '4px 0px 16px',
  boxShadow: '1px 1px 10px rgba(39, 17, 83, 0.1)',
}));
