import { Box, CircularProgress } from '@mui/material';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { ScrollLoaderProps } from './ScrollLoader.types';

const ScrollToTop: FC<ScrollLoaderProps> = ({ isLoading, hasMoreData, onLoad }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [prevRatio, setRrevRatio] = useState(0);

  const handleIntersect: IntersectionObserverCallback = useCallback(
    (entries) => {
      if (isLoading || !hasMoreData) {
        return;
      }
      entries.forEach((entry) => {
        if (entry.intersectionRatio > prevRatio) {
          // loaderEl is in view, perform load more data
          onLoad();
        }
        setRrevRatio(entry.intersectionRatio);
      });
    },
    [hasMoreData, isLoading, onLoad, prevRatio]
  );

  useEffect(() => {
    if (!ref.current) return;

    const options = {
      root: null, // null means document viewport
      rootMargin: '0px',
      threshold: 1, // target element 100% visible
    };
    const observer = new IntersectionObserver(handleIntersect, options);
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [handleIntersect, ref]);

  return (
    <Box ref={ref} sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </Box>
  );
};

export default ScrollToTop;
