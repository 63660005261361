import { FC, useRef, WheelEvent } from 'react';

import { TeamSlideItem } from 'src/atoms';
import { getImgSrc } from 'src/constants';

import { Container, Slider } from './TeamSlider.styles';
import { TeamSliderProps } from './TeamSlider.types';

const TeamSlider: FC<TeamSliderProps> = ({ currentId, ancestors, onSelectMember }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const onWheelHandler = (e: WheelEvent<HTMLDivElement>) => {
    if (e.defaultPrevented) e.preventDefault();
    const { current: container } = scrollRef;
    if (!container) return;
    const containerScrollPosition = container.scrollLeft;
    container.scrollTo({
      left: containerScrollPosition + e.deltaY,
    });
  };

  return (
    <Container>
      <Slider ref={scrollRef} onWheel={onWheelHandler}>
        {ancestors.map((ancestor) => (
          <TeamSlideItem
            key={ancestor.id}
            avatar={getImgSrc(ancestor.photo_uuid, 's60-c') || ''}
            name={ancestor.display_name}
            isSelf={currentId === ancestor.id}
            onClick={() => onSelectMember(ancestor.id)}
          />
        ))}
      </Slider>
    </Container>
  );
};

export default TeamSlider;
