import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

export const CarouselStack = styled(Stack)(() => ({
  backgroundColor: ThemeColors.black,
  padding: 4,
  overflowY: 'scroll',
  boxSizing: 'content-box',
  whiteSpace: 'nowrap',
  minHeight: 100,
}));
