import { Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CarouselTabs = styled(Tabs)(() => ({
  '& .MuiTabs-scroller': {
    overflowX: 'scroll !important',
  },

  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTabs-indicatorSpan': {
    display: 'none',
  },
  '& .Mui-tab-label': {
    display: 'none',
  },
  '& .Mui-selected .Mui-tab-label': {
    display: 'inline',
  },
}));
