import { Accordion } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FONTS, ThemeColors } from 'src/styles';

export const FilterAccordion = styled(Accordion)(() => ({
  boxShadow: 'none',
  backgroundColor: 'transparent',
  margin: '0 -16px',
  padding: '0 16px',

  '&:first-of-type': {
    borderRadius: 0,
    borderTop: `1px solid ${ThemeColors.grayLight}`,
  },

  '&:last-of-type': {
    borderRadius: 0,
  },

  '&.Mui-expanded': {
    margin: '0 -16px',
    padding: '0 16px',
  },
  '&:before': {
    height: 0,
  },
  '.MuiAccordionDetails-root': {
    margin: '0 -16px',
    padding: '16px!important',
    borderBottom: `1px solid ${ThemeColors.grayLight}`,
    backgroundColor: ThemeColors.body,
  },
  '.MuiAccordionSummary-root': {
    height: 60,
    margin: '0 -16px',
    padding: '0 16px',
    borderBottom: `1px solid ${ThemeColors.grayLight}`,
    '&.Mui-expanded': {
      minHeight: 60,
    },
  },
  '.MuiAccordionSummary-content': {},
}));

export const Title = styled('p')(() => ({
  fontFamily: FONTS,
  fontSize: '1.375rem',
  fontWeight: 500,
  lineHeight: '28px',
  letterSpacing: '0.35px',
  margin: 0,
}));

export const SelectFilters = styled('span')(() => ({
  display: 'inline-block',
  width: 8,
  height: 8,
  borderRadius: '100%',
  backgroundColor: ThemeColors.redLight,
  verticalAlign: 'top',
}));
