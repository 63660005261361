import { useSnackbar } from 'notistack';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Loading } from 'src/atoms';
import { Paths } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { selectUser, signInSolt } from 'src/store/userSlice';
import { RouterIdParam } from 'src/types';

const Auth: FC = () => {
  const { id } = useParams<RouterIdParam>();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { authError } = useAppSelector(selectUser);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (authError) {
      enqueueSnackbar(authError, { variant: 'error' });
      navigate(Paths.signIn);
    }
  }, [authError, enqueueSnackbar, navigate]);

  useEffect(() => {
    if (id) {
      dispatch(signInSolt({ solt: id }));
    } else {
      navigate(Paths.signIn);
    }
  }, [id, navigate, dispatch]);

  return <Loading />;
};

export default Auth;
