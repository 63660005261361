import { Box, IconButton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors, ThemeGradients } from 'src/styles';

export const Wrapper = styled(Box)(() => ({
  padding: '16px 16px 8px',
  marginBottom: 16,
  border: `1px solid ${ThemeColors.black}`,
  borderRadius: 16,
}));

export const Header = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 8,
}));

export const Title = styled(Typography)(() => ({
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: 600,
  background: ThemeGradients.text,
  backgroundClip: 'text',
  textFillColor: 'transparent',
}));

export const InfoButton = styled(IconButton)(() => ({
  color: ThemeColors.black,
  marginLeft: 'auto',
  padding: 0,

  svg: {
    fill: 'currentColor',
  },
}));
