import { Box, Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeFontWeight } from 'src/styles';

export const Block = styled(Box)(() => ({
  '&.GoalItemParams_unactive': {
    opacity: 0.5,
  },
}));

export const Image = styled('img')(() => ({
  objectFit: 'cover',
  width: '100%',
  display: 'block',
}));

export const Info = styled(Box)(() => ({
  width: '100%',
}));

export const Title = styled(Typography)(({ theme }) => ({
  lineHeight: '18px',
  fontWeight: ThemeFontWeight.semiBold,
  color: theme.palette.primary.main,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  textAlign: 'center',
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  lineHeight: '18px',
  color: theme.palette.primary.main,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export const LabelTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export const Row = styled(Stack)(() => ({
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

export const Column = styled(Stack)(() => ({
  flexDirection: 'row',
  whiteSpace: 'normal',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

export const MemoButton = styled(Button)(({ theme }) => ({
  border: '3px solid',
  backgroundColor: theme.palette.background.darker,
  borderColor: theme.palette.background.darker,
  color: theme.palette.primary.main,
  '&.MemoButton_active': {
    borderColor: theme.palette.primary.main,
  },
}));

export const SizeButton = styled(Button)(({ theme }) => ({
  border: '1px solid',
  backgroundColor: theme.palette.background.darker,
  borderColor: theme.palette.background.darker,
  color: theme.palette.primary.main,
  '&.SizeButton_active': {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.darker,
  },

  '&.Mui-disabled': {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
  },

  '&.Mui-disabled&:after': {
    position: 'absolute',
    content: '"⊘"',
    fontSize: '40px',
    color: theme.palette.secondary.light,
  },
}));

export const ImageCustom = styled('img')(() => ({
  flex: 1,
  objectFit: 'contain',
  width: '50%',
  cursor: 'pointer',
  opacity: 0.5,
  '&.goalItem_custom__active': {
    opacity: 1,
  },
}));
