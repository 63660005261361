import * as yup from 'yup';

import { getRequiredField } from 'src/utils';

export const schema = yup.object().shape({
  first_name: yup.string().required(getRequiredField).nullable(),
  last_name: yup.string().required(getRequiredField).nullable(),
  // additional_name: yup.string().required(getRequiredField).nullable(),

  country: yup.string().required(getRequiredField).nullable(),
  city: yup.string().required(getRequiredField).nullable(),
  house: yup.string().required(getRequiredField).nullable(),
  // flat: yup.string().required(getRequiredField).nullable(),
  street: yup.string().required(getRequiredField).nullable(),
  postcode: yup.string().required(getRequiredField).nullable(),
  phone: yup.string().required(getRequiredField).nullable(),
  yes_rule: yup.boolean().required(getRequiredField).isTrue(),
});
