import { FC, Children } from 'react';

import { CarouselItem } from 'src/atoms';
import { CarouselItemSkeleton } from 'src/atoms/CarouselItem/CarouselItem.styles';
import { ItemProps } from 'src/atoms/CarouselItem/CarouselItem.types';

import { CarouselStack } from './MaterialsCarousel.styles';
import { MaterialsCarouselProps } from './MaterialsCarousel.types';

// пустое представление карусели
const skeletonItems = Children.toArray(new Array(8).fill(<CarouselItemSkeleton variant="rectangular" />));

const MaterialsCarousel: FC<MaterialsCarouselProps> = ({ items, selected, onSelect }) => {
  return (
    <CarouselStack direction="row" spacing={0.4}>
      {items
        ? items.map((item: ItemProps) => (
            <CarouselItem
              key={`carouselItem_${item.id}`}
              id={item.id}
              title={item.title}
              img={item.img}
              disabled={item.disabled}
              selected={selected === item.id}
              onClick={(id) => (onSelect ? onSelect(id) : undefined)}
            />
          ))
        : skeletonItems}
    </CarouselStack>
  );
};

export default MaterialsCarousel;
