import { Box } from '@mui/material';
import { CarouselProvider, Slider, Slide, Image, DotGroup, Dot } from 'pure-react-carousel';
import { FC, useEffect, useRef, useState } from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { NoPhoto } from 'src/assets/images';
import { getImgSrc } from 'src/constants';
import { useWindowSize } from 'src/hooks';

import { CarouselProps } from './CarouselPhoto.types';

const CarouselPhoto: FC<CarouselProps> = ({ images: imagesProp, sx }) => {
  const { width: windowWidth } = useWindowSize();
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);

  const [images, setImages] = useState(imagesProp);

  useEffect(() => {
    if (!containerRef.current) return;

    setWidth(containerRef.current.offsetWidth);
  }, [containerRef, windowWidth]);

  useEffect(() => {
    setImages(imagesProp);
  }, [imagesProp]);

  const steps = images.length || 0;

  return (
    <Box
      className="Carousel_root"
      ref={containerRef}
      sx={{
        position: 'relative',
        userSelect: 'none',
        height: width + (steps > 1 ? 80 : 0) + 32 + 'px',
        // py: '16px',

        paddingBottom: steps > 1 ? 0 : '8px',

        '& .Carousel_slider': {
          // padding: '0px 0px 8px 0px',
        },
        '& .Carousel_slide .carousel__inner-slide': {
          padding: '0 2px',
        },

        '& .Carousel_dotGroup': {
          overflowX: 'scroll',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignContent: 'center',
        },

        '& .Carousel_dot': {
          backgroundColor: 'primary.main',
          flex: 'none',
          border: 'none',
          padding: 0,
          margin: '8px 8px 8px 0px',
          borderRadius: '8px',
          overflow: 'hidden',
          width: '80px',
          height: '80px',

          '&.carousel__dot--selected': {
            opacity: 0.5,
          },
        },
        ...sx,
      }}
    >
      <CarouselProvider
        infinite={true}
        totalSlides={steps}
        naturalSlideHeight={0}
        naturalSlideWidth={600}
        isIntrinsicHeight={true}
        touchEnabled={true}
      >
        <Slider className="Carousel_slider">
          {images.map((photo_uuid, idx) => (
            <Slide className="CarouselImages_slide" key={`CarouselImages_Slider__${idx}_${photo_uuid}`} index={idx}>
              <Image src={getImgSrc(photo_uuid, 's600-c') || NoPhoto} alt="" hasMasterSpinner={false} />
            </Slide>
          ))}
        </Slider>
        {steps > 1 && (
          <DotGroup renderDots={() => null} className="Carousel_dotGroup">
            {images.map((photo_uuid, idx) => (
              <Dot key={`CarouselImages_Dot__${idx}_${photo_uuid}`} slide={idx} className="Carousel_dot">
                <Image src={getImgSrc(photo_uuid, 's80-c') || NoPhoto} alt="" hasMasterSpinner={false} />
              </Dot>
            ))}
          </DotGroup>
        )}
      </CarouselProvider>
    </Box>
  );
};

export default CarouselPhoto;
