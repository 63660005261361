import { Box, Stack } from '@mui/material';
import { FC, useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useNavigate } from 'react-router-dom';

import { EmptyData, GoalHistoryItem, TitleBadge } from 'src/atoms';
import { Paths, pathsArg } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { useResize } from 'src/hooks';
import { getGoalHistory, TTargetCurrentItem } from 'src/services';
import { ThemePaddings } from 'src/styles';
import { logger } from 'src/utils';

import { GoalListProps } from './GoalListHistoryWidget.types';

const defaultLimit = 5;

const GoalListHistoryWidget: FC<GoalListProps> = ({
  limit,
  user_id,
  items: itemsParam,
  maxCount: maxCountParam,
  readonly,
  sx,
}) => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);
  const navigate = useNavigate();

  const _navigate = !readonly ? navigate : () => false;

  const containerRef = useRef<HTMLDivElement>(null);
  const { width: wrapperWidth } = useResize(containerRef);
  const [width, setWidth] = useState(0);

  const [isLoading, setLoading] = useState(!itemsParam);
  const [items, setItems] = useState<TTargetCurrentItem[] | null>(itemsParam || null);
  const [maxCount, setMaxCount] = useState(maxCountParam || 0);

  useEffect(() => {
    if (items) return;
    setLoading(true);
    getGoalHistory({ offset: 0, limit: limit || defaultLimit }, user_id)
      .then(({ data }) => {
        setItems(data.results);
        setMaxCount(data.count);
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
        logger.error(error);
      })
      .finally(() => setLoading(false));
  }, [items, limit, user_id, throwServerError]);

  useEffect(() => {
    const { current } = containerRef;
    if (!current) return;

    setWidth(current.offsetWidth);
  }, [containerRef]);

  useEffect(() => {
    if (!wrapperWidth) return;

    setWidth(wrapperWidth);
  }, [wrapperWidth]);

  const itemWidth = width * 0.6;
  const itemHeight = itemWidth + 100;

  return (
    <>
      <TitleBadge onClick={() => _navigate(pathsArg(Paths.goalHistory, { id: user_id }))} count={maxCount}>
        {t('goalHistory.title')}
      </TitleBadge>
      <ScrollContainer vertical={false} horizontal={true} hideScrollbars={false} nativeMobileScroll={true}>
        <Box
          ref={containerRef}
          sx={{
            px: ThemePaddings.pageX,
            py: ThemePaddings.pageX,
            pt: '8px',
            ...sx,
          }}
        >
          {!isLoading &&
            (items?.length ? (
              <Stack
                direction="row"
                whiteSpace="nowrap"
                alignContent="flex-start"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  userSelect: 'none',
                  width: 'fit-content',
                }}
              >
                {items.map((item) => {
                  return (
                    <Box
                      key={`HoalHistoryWidgetItem_${item.id}`}
                      sx={{
                        width: itemWidth + 'px',
                        height: itemHeight + 'px',
                        flex: '1 0',
                        margin: '1px',
                      }}
                    >
                      <GoalHistoryItem item={item} readonly={readonly} />
                    </Box>
                  );
                })}
                {(limit || defaultLimit) <= items.length && (
                  <Box
                    key={'GoalHistoryWidgetItem_000'}
                    sx={{
                      width: itemWidth - 4 + 'px',
                      height: itemHeight - 4 + 'px',
                      flex: '1 0',
                      lineHeight: itemHeight + 'px',
                      textAlign: 'center',
                      backgroundColor: 'background.paper',
                      margin: '3px',
                      cursor: 'pointer',
                      borderRadius: '6px',
                    }}
                    onClick={() => _navigate(pathsArg(Paths.goalHistory, { id: user_id }))}
                  >
                    {t('goalHistory.more')}
                  </Box>
                )}
              </Stack>
            ) : (
              <EmptyData
                title={t('goalHistory.emptyTitle')}
                data-qa={'goalHistory.emptyTitle'}
                text={t('goalHistory.emptyText')}
              />
            ))}
        </Box>
      </ScrollContainer>
    </>
  );
};

export default GoalListHistoryWidget;
