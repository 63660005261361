import { IosShare } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Paths, pathsArg } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { PageTitle } from 'src/molecules';
import { ProfileForm } from 'src/organisms';
import { getPlayerData, Player } from 'src/services';
import { ThemePaddings } from 'src/styles';
import { getLocale } from 'src/utils';

const Profile: FC = () => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);

  const navigate = useNavigate();
  const onCloseHandler = () => navigate(Paths.dashboard);

  const [isLoading, setLoading] = useState(false);
  const [player, setPlayer] = useState<Player>();

  useEffect(() => {
    setLoading(true);
    getPlayerData()
      .then(({ data }) => {
        data.language = getLocale();
        setPlayer(data);
      })
      .catch((error) => {
        if (error.response.status >= 500) throwServerError();
      })
      .finally(() => setLoading(false));
  }, [throwServerError]);

  const handlerShare = () => {
    if (player) {
      navigate(pathsArg(Paths.profileUser, { id: player.user_id }));
    }
  };

  return (
    <Box sx={{ p: ThemePaddings.page }}>
      <PageTitle
        title={t('profile.title')}
        data-qa={'profile.title'}
        help={{ tkey: 'profile' }}
        onClose={onCloseHandler}
        isLoading={isLoading}
      >
        <IconButton
          data-qa="title.button.share"
          sx={{ alignSelf: 'center', pointerEvents: 'auto' }}
          onClick={handlerShare}
          disabled={!player}
        >
          <IosShare />
        </IconButton>
      </PageTitle>

      {!isLoading && player && <ProfileForm player={player} />}
    </Box>
  );
};

export default Profile;
