import { Button, Divider, TextField } from '@mui/material';
import { FC, useRef, useState } from 'react';

import { Markdown } from 'src/atoms';

const markdown = `[Элементы MD](https://markdown-guide.pages.dev/cheat-sheet/)<!--rehype:rel=external-->

[про размеры картинок](https://developers.google.com/people/image-sizing?hl=ru)<!--rehype:rel=external&target=_blank-->

[про всякий кастом](https://www.npmjs.com/package/rehype-attr)<!--rehype:rel=external-->

<a href="navigate-/dashboard">ссылка с навигацией</a>

[тоже ссылка с навигацией и лучше использовать так](navigate-/dashboard)

<div component="block" data-background="uuid-7af33480-37ac-457c-bdb6-5d4ca5b35858[s600]" data-position="bottom">
  <h2>Заголовок 2</h2>
  <!--rehype:style=color:red;-->

  описание в<br/>2 строки, а если нужно и<br/>в 3

  <button navigate="/study" variant="outlined">button.next</button>
</div>
<video src="https://lk.test-cloud.ru/video/m12.mp4" data-ratio="8"></video>
<div component="block" data-contrast="true"
  data-background="uuid-561fb09d-f19f-4dac-a0a9-c48b1e370071[s600-c]" data-position="top">
  <h2><span class="material-icons-outlined">account_circle</span> текст рядом</h2>
  <!--rehype:style=text-align:center;-->

[Все иконки можно посмотреть тут](https://fonts.google.com/icons?icon.set=Material+Icons)
Клик на иконку и сбоку копируем как вставить

  <button navigate="/study" variant="outlined">button.next</button>
</div>

<div component="blockVideo" data-ratio="0.9" 
  data-background="https://youtu.be/QBK6xymmKHM?si=O7RJ545hZi6phzbw" data-position="bottom">
  <h2>Заголовок 2</h2>
  <!--rehype:style=color:red;-->

  описание

  <button navigate="/study" variant="outlined">button.next</button>
</div>


<div component="blockScroll">
  <div component="blockImg" data-background="uuid-7af33480-37ac-457c-bdb6-5d4ca5b35858[s600]" data-position="bottom">
    <h2>Блок 1</h2>
  </div>
  <div>
    <h2>Блок 2</h2>
  </div>
  <div component="blockVideo" data-ratio="0.9" 
    data-background="https://youtu.be/QBK6xymmKHM?si=O7RJ545hZi6phzbw" data-position="bottom">
    <h2>Блок 3</h2>
  </div>
</div>

Привет *user-name*!

Вот твой профиль *user-link*

Вот твое фото
![UserPhoto](uuid-user[s600-c])

А вот мое фото
![NotUserPhoto](uuid-561fb09d-f19f-4dac-a0a9-c48b1e370071[s600-c])

шизанутого размера картинка
![uvaga](https://lk.test-cloud.ru/api/v1/images/7af33480-37ac-457c-bdb6-5d4ca5b35858/)

если хочется закрутит углы
![RoundBorderPhoto](uuid-561fb09d-f19f-4dac-a0a9-c48b1e370071[s300-c])<!--rehype:style=border-radius:10px;width:50%;-->

или вдруг круглую
![RoundBorderPhoto](uuid-561fb09d-f19f-4dac-a0a9-c48b1e370071[s300-c])<!--rehype:style=border-radius:50%;width:50%;-->

## Заголовки

# Заголовок 1

## Заголовок 2

### Заголовок 3 (red)
<!--rehype:style=color:red;-->

#### Заголовок 4

##### Заголовок 5

###### Заголовок 6

---

A paragraph with *emphasis* and **strong importance**.

> A block quote with ~strikethrough~ and a URL: https://reactjs.org.

I need to highlight these ==very important words==.

* Lists
* [ ] todo
* [x] done

A table:

| a | b |
| - | - |

<iframe
  data-type="youtube"
  src="https://www.youtube.com/embed/dQw4w9WgXcQ"
></iframe>

<button navigate="/study" variant="outlined">button.next</button>
<button variant="contained">button.prev</button>

<button navigate="/dashbord" fullwidth>button.dashbord</button>

<button href="https://youtube.com" variant="outlined" fullwidth>button.goto.youtube</button>
`;

const MDStyleHelp: FC = () => {
  const [mdtext, setMdtext] = useState(markdown);
  const containerRef = useRef<HTMLDivElement>(null);

  const handlerCheck = () => {
    if (containerRef.current) {
      setMdtext(containerRef.current.getElementsByTagName('textarea')[0].value);
    }
  };

  return (
    <>
      <Markdown>{mdtext}</Markdown>

      <Divider sx={{ my: '16px' }} />

      <TextField ref={containerRef} fullWidth id="outlined-textarea" multiline defaultValue={mdtext} />
      <Button fullWidth onClick={handlerCheck}>
        Test
      </Button>
    </>
  );
};

export default MDStyleHelp;
