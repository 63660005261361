import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'src/hooks';
import { PageTitle } from 'src/molecules';
import { RegForm } from 'src/organisms';
import { selectUserMe } from 'src/store/userSlice';
import { ThemePaddings } from 'src/styles';

const Reg: FC = () => {
  const { t } = useTranslation('app');
  const userMe = useAppSelector(selectUserMe);

  if (!userMe) return null;

  return (
    <Box sx={{ p: ThemePaddings.page }}>
      <PageTitle title={t('reg.title')} data-qa={'reg.title'} help={{ tkey: 'reg' }} />

      <RegForm />
    </Box>
  );
};

export default Reg;
