import { Avatar, IconButton } from '@mui/material';
import { FC } from 'react';

import { TeamSlideItemProps } from './TeamSlideItem.types';

const TeamSlideItem: FC<TeamSlideItemProps> = ({ avatar, name, isSelf, onClick }) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        p: 0,
        flexBasis: 60,
        marginRight: '5px',
        '&:first-of-type': { marginLeft: '20px' },
        '&:last-of-type': { marginRight: '20px' },
        background: 'none',
      }}
    >
      <Avatar
        alt={name}
        src={avatar}
        sx={{
          width: 60,
          height: 60,
          border: '1px solid',
          borderColor: isSelf ? 'primary.main' : 'background.paper',
          backgroundColor: 'secondary.main',
        }}
      />
    </IconButton>
  );
};

export default TeamSlideItem;
