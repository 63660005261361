import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useForm, SubmitHandler, Resolver } from 'react-hook-form';
import useFormPersist from 'react-hook-form-persist';
import { useTranslation } from 'react-i18next';

import { InputField, CheckBoxInput, DrawerBottom, Markdown } from 'src/atoms';
import { StepTitle, WindowTitle } from 'src/molecules';
import { Help } from 'src/organisms';
import { TDeliveryFormValues } from 'src/services';

import { schema } from './DeliveryForm.constants';

const approveList = [
  'last_name',
  'first_name',
  'additional_name',
  'phone',
  'country',
  'city',
  'street',
  'house',
  'flat',
  'postcode',
  'comment',
];

const DeliveryForm: FC<{
  formKey: string;
  data: TDeliveryFormValues;
  onApprove: (data: TDeliveryFormValues) => Promise<unknown>;
  tkey?: string;
}> = ({ formKey, tkey: tkeyProp, data, onApprove }) => {
  const { t } = useTranslation('app');

  const FORM_DATA_KEY = `deliveryForm_${formKey}`;
  const tkey = tkeyProp ? tkeyProp + '.' : '';

  const [isOpenRule, setOpenRule] = useState<boolean>(false);
  const [isOpenApprove, setOpenApprove] = useState<TDeliveryFormValues | undefined>();

  const { handleSubmit, control, setValue, watch } = useForm<TDeliveryFormValues>({
    resolver: yupResolver(schema) as Resolver<TDeliveryFormValues, object> | undefined,
    mode: 'onChange',
    defaultValues: {
      ...data,
      yes_rule: false,
    },
  });

  useFormPersist(FORM_DATA_KEY, { watch, setValue });

  const onSubmit: SubmitHandler<TDeliveryFormValues> = (data) => {
    setOpenApprove(data);
  };

  const handlerApprove = () => {
    if (isOpenApprove) {
      onApprove(isOpenApprove).then(() => {
        setOpenApprove(undefined);
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={{ mb: '16px' }}>{t('delivery.description')}</Typography>
        <StepTitle step={1} title={t('delivery.step1.title')} subTitle={t('delivery.step1.subtitle')} />
        <Stack spacing={'10px'}>
          <InputField
            data-qa={'delivery.last_name'}
            name="last_name"
            control={control}
            placeholder={t('delivery.placeholder.last_name')}
            maxlength={200}
          />
          <InputField
            data-qa={'delivery.first_name'}
            name="first_name"
            control={control}
            placeholder={t('delivery.placeholder.first_name')}
            maxlength={200}
          />
          <InputField
            data-qa={'delivery.additional_name'}
            name="additional_name"
            control={control}
            placeholder={t('delivery.placeholder.additional_name')}
            maxlength={200}
          />
        </Stack>

        <Divider variant="fullWidth" sx={{ my: '24px' }} />

        <StepTitle step={2} title={t('delivery.step2.title')} subTitle={t('delivery.step2.subtitle')} />
        <InputField
          data-qa={'delivery.phone'}
          name="phone"
          control={control}
          placeholder={t('delivery.placeholder.phone')}
          maxlength={30}
        />

        <Divider variant="fullWidth" sx={{ my: '24px' }} />

        <StepTitle step={3} title={t('delivery.step3.title')} subTitle={t('delivery.step3.subtitle')} />
        <Stack spacing={'10px'}>
          <InputField
            data-qa={'delivery.country'}
            name="country"
            control={control}
            placeholder={t('delivery.placeholder.country')}
            maxlength={200}
          />
          <InputField
            data-qa={'delivery.city'}
            name="city"
            control={control}
            placeholder={t('delivery.placeholder.city')}
            maxlength={200}
          />
          <InputField
            data-qa={'delivery.street'}
            name="street"
            control={control}
            placeholder={t('delivery.placeholder.street')}
            maxlength={200}
          />
          <InputField
            data-qa={'delivery.house'}
            name="house"
            control={control}
            placeholder={t('delivery.placeholder.house')}
            maxlength={20}
          />
          <InputField
            data-qa={'delivery.flat'}
            name="flat"
            control={control}
            placeholder={t('delivery.placeholder.flat')}
            maxlength={15}
          />
          <InputField
            data-qa={'delivery.postcode'}
            name="postcode"
            control={control}
            placeholder={t('delivery.placeholder.postcode')}
            maxlength={6}
          />
        </Stack>

        <Divider variant="fullWidth" sx={{ my: '24px' }} />

        <StepTitle step={4} title={t('delivery.step4.title')} subTitle={t('delivery.step4.subtitle')} />
        <Stack spacing={'10px'}>
          <InputField
            data-qa={'delivery.comment'}
            name="comment"
            control={control}
            placeholder={t('delivery.placeholder.comment')}
            maxlength={200}
            multiline={true}
            minRows={4}
            maxRows={4}
          />
        </Stack>

        <CheckBoxInput
          sx={{ mt: '16px' }}
          name="yes_rule"
          control={control}
          data-qa={'delivery.approve.rule'}
          label={
            <Typography>
              {t('delivery.approve.rule')}{' '}
              <Typography
                component="span"
                data-qa="delivery.approve.rule.link"
                sx={{ textDecoration: 'underline' }}
                onClick={() => setOpenRule(true)}
              >
                {t('delivery.approve.rule.link')}
              </Typography>
            </Typography>
          }
        />

        <Box sx={{ my: '10px', position: 'relative', zIndex: 1 }}>
          <Button data-qa={'delivery.button'} variant="outlined" fullWidth type="submit">
            {t('delivery.button')}
          </Button>
        </Box>
      </form>

      <DrawerBottom
        isOpen={isOpenRule}
        onClose={() => setOpenRule(false)}
        sx={{ height: '100%', '& .MuiDrawer-paper': { height: '100%', zIndex: 1500 } }}
      >
        <WindowTitle
          title={t('delivery.rule.title')}
          data-qa={'delivery.rule.title'}
          onClose={() => setOpenRule(false)}
        />
        <Markdown>{t('delivery.rule.info.textMD')}</Markdown>
      </DrawerBottom>

      <DrawerBottom isOpen={!!isOpenApprove} onClose={() => setOpenApprove(undefined)}>
        <WindowTitle
          title={t(tkey + 'delivery.approve.title')}
          data-qa={tkey + 'delivery.approve.title'}
          onClose={() => setOpenApprove(undefined)}
        />
        <Help tkey={tkey + 'delivery.approve'} onNext={handlerApprove}>
          <>
            <Typography sx={{ my: '10px' }}>{t(tkey + 'delivery.approve.info')}</Typography>
            {isOpenApprove && (
              <Stack spacing={'10px'}>
                {approveList.map((key) => {
                  const _key = key as keyof TDeliveryFormValues;
                  let value = (isOpenApprove[_key] || '').toString();

                  return (
                    <Stack direction="row" key={`delivery.approve.${key}`}>
                      <Typography
                        textOverflow="ellipsis"
                        data-qa={`delivery.approve.label.${key}`}
                        sx={{ color: 'secondary.main', flex: 'none', width: '30%', overflow: 'hidden' }}
                      >
                        {t(`delivery.approve.label.${key}`)}
                      </Typography>
                      <Typography data-qa={`delivery.approve.value.${key}`}>{value}</Typography>
                    </Stack>
                  );
                })}
              </Stack>
            )}
            <Typography sx={{ my: '10px' }}>{t(tkey + 'delivery.approve.text')}</Typography>
          </>
        </Help>
      </DrawerBottom>
    </>
  );
};

export default DeliveryForm;
