import { Country, City } from '../GeolocationService';
import { UserRole } from '../UserService';

export enum TeamType {
  List = 'list',
  Tree = 'tree',
  Swarm = 'swarm',
}

export type TeamUser = {
  id: number;
  photo_uuid: string;
  display_name: string;
  user_id: number;
  role?: UserRole;
};

export type Ancestors = TeamUser;

export type TeamChildrenInList = {
  id: number;
  user_id: number;
  photo_uuid: string;
  display_name: string;
  date_joined: string;
  date_joined_diff?: number; //  количество дней между регой пользователя и участника
  role?: UserRole;
  country?: Country;
  city?: City;
  level?: number;
  public_address?: string;
  team_number?: number;
  personal_team_number?: number;
};

export type TeamChildrenInTree = Omit<TeamChildrenInList, 'team_number' | 'personal_team_number'> & {
  personal_team_number: number[];
};

export type TeamBase = TeamUser & {
  ancestors: Ancestors[];
};

export type TTeamMeta = {
  team: { personal_team_number: number; team_number: number; have_team: boolean };
};

export type TeamList = TeamBase & {
  children: TeamChildrenInList[];
  meta: TTeamMeta;
};

export type TeamTree = TeamBase & {
  children: TeamChildrenInTree[];
  meta: TTeamMeta;
};

export type TeamSwarm = TeamBase & {
  children: TeamChildrenInList[];
  meta: TTeamMeta;
};
