import { Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BPriceProps } from './Location.types';

const Address: FC<BPriceProps> = ({ city, country, ip, sx }) => {
  const { t } = useTranslation('app');

  return (
    <Typography sx={sx}>{[t(city || ''), t(country || ''), ip].filter((s) => s || undefined).join(', ')}</Typography>
  );
};

export default Address;
