import { Tab } from '@mui/material';
import { styled } from '@mui/material/styles';

type CarouselTabProps = {
  variant?: 'icononly';
};

export const CarouselTab = styled(Tab)<CarouselTabProps>(({ variant, theme }) => {
  const isIconOnly = variant === 'icononly';
  return {
    borderRadius: isIconOnly ? '0px' : '24px',
    border: isIconOnly ? '0px' : '2px solid',
    padding: isIconOnly ? '4px 4px' : '2px 2px',
    borderColor: theme.palette.background.default,
    marginTop: isIconOnly ? 3 : 6,
    marginRight: isIconOnly ? 0 : 6,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: 'none',
    minHeight: '48px',
    minWidth: '48px',

    '&.Mui-selected': {
      marginTop: 0,
      paddingBottom: 0,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
      borderRadius: '0px 0px 24px 24px',
      '& .Mui-tab-label': {
        marginLeft: '6px',
      },
    },
  };
});
