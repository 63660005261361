import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { StorageKeys } from 'src/constants';

const detectorOptions = {
  caches: ['localStorage'],
  lookupLocalStorage: StorageKeys.LANG,
  order: ['localStorage'],
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    detection: detectorOptions,
    defaultNS: 'common',
    fallbackLng: '',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
