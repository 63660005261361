import { Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';

import { Paths } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { TTelegramUser } from 'src/services';
import { selectAuthorized, selectUser, signInUser } from 'src/store/userSlice';
import { SearchParams } from 'src/utils';

// import { logger } from 'src/utils';

const TelegramWebApp: FC = () => {
  const ref = useRef(null);
  const { t } = useTranslation('app');
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { search: searchParams } = useLocation();
  const referal = new SearchParams(searchParams).getByType('referal', 'string') as string | undefined;

  const [user, setUser] = useState<TTelegramUser>();
  const isAuthorized = useAppSelector(selectAuthorized);
  const { authError } = useAppSelector(selectUser);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-web-app.js';
    script.async = true;
    script.onload = () => {
      const globeWin: any = window;
      const telegramApp = globeWin.Telegram.WebApp; //нужно получить объект window.Telegram.WebApp Телеграмма
      telegramApp.expand(); //разворачиваем на все окно

      if (telegramApp.initDataUnsafe.user.isBot) {
        return;
      }

      // пробуем авторизовать пользователя
      const user: TTelegramUser = {
        auth_date: telegramApp.initDataUnsafe.auth_date,
        id: telegramApp.initDataUnsafe.user.id,
        first_name: telegramApp.initDataUnsafe.user.first_name,
        last_name: telegramApp.initDataUnsafe.user.last_name,
        hash: telegramApp.initDataUnsafe.hash,
        photo_url: telegramApp.initDataUnsafe.user.photo_url,
        username: telegramApp.initDataUnsafe.user.username,
        query: telegramApp.initData,
      };

      setUser(user);

      dispatch(
        signInUser({
          provider: 'telegram',
          authData: user,
          referral_uuid: referal,
        })
      );
    };

    if (ref.current) {
      const TWA: any = ref.current;
      TWA.appendChild(script);
    }
  }, [dispatch, referal]);

  useEffect(() => {
    if (authError) {
      enqueueSnackbar(authError, { variant: 'error' });
    }
  }, [authError, enqueueSnackbar]);

  if (isAuthorized) {
    return <Navigate to={Paths.home} replace={true} />;
  }

  return (
    <>
      <Stack ref={ref} justifyContent="center" alignItems="center" sx={{ height: '100%', p: '16px' }}>
        {user && (
          <Typography variant="h2" textAlign="center" sx={{ width: '100%' }}>
            {t('telegram.hello') + ` ${user.first_name} ${user.last_name} (${user.username})!`}
          </Typography>
        )}
      </Stack>
    </>
  );
};

export default TelegramWebApp;
