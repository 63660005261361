import { Feature, Polygon } from 'geojson';
import { FC, useState, useRef, useCallback, useEffect } from 'react';
import { Map, MapRef, ViewState, Source, Layer } from 'react-map-gl';
import { useLocation, useNavigate } from 'react-router-dom';

import { MAPBOX_TOKEN, Paths, getImgSrc, pathsArg } from 'src/constants';
import { GlobeMarker } from 'src/molecules';
import { SearchParams, logger } from 'src/utils';

import { MapboxGlobeProps } from './MapboxGlobe.types';

import 'mapbox-gl/dist/mapbox-gl.css';

let idleTimer: NodeJS.Timer | undefined;
const idleTimerInterval: number = 10000;
const secondsPerRevolution = 120;
// Above zoom level 5, do not rotate.
const maxSpinZoom = 1.4;
// Rotate at intermediate speeds between zoom levels 3 and 5.
const slowSpinZoom = 3;

function spinGlobe(map: any) {
  // вращалка глобуса
  const zoom = map.getZoom();
  if (zoom < maxSpinZoom) {
    let distancePerSecond = 360 / secondsPerRevolution;
    if (zoom > slowSpinZoom) {
      // Slow spinning at higher zooms
      const zoomDif = (maxSpinZoom - zoom) / (maxSpinZoom - slowSpinZoom);
      distancePerSecond *= zoomDif;
    }
    const center = map.getCenter();
    center.lng += distancePerSecond;
    // Smoothly animate the map over one second.
    // When this animation is complete, it calls a 'moveend' event.
    map.easeTo({ center, duration: 1000, easing: (n: any) => n });
  }
}

const MapboxGlobe: FC<MapboxGlobeProps> = ({ data, userId, onZoom, logoPosition }) => {
  const navigate = useNavigate();

  const { search: searchParams } = useLocation();
  // параметры для отображения слоев
  // bounds - показ BBox который уходит на сервер
  const showBounds = new SearchParams(searchParams).getByType('bounds', 'boolean') as number | undefined;

  const mapRef = useRef<MapRef>(null);
  const [zoom, setZoom] = useState(0.8);
  const [bounds, setBounds] = useState<[number, number, number, number]>();

  const [rotate, setRotate] = useState(true);

  const handlerZoomEnd = () => {
    if (!onZoom) return;

    const { current: mapWrapper } = mapRef;
    if (!mapWrapper) return;

    const map = mapWrapper.getMap();
    const mapBounds = map.getBounds();

    const zoom = mapWrapper.getZoom();
    const bounds: [number, number, number, number] = [mapBounds.getWest(), -95, mapBounds.getEast(), 95]; //

    onZoom(zoom, bounds);
  };

  const onUserDownHandler = () => {
    setRotate(false);
    if (idleTimer) {
      clearTimeout(idleTimer);
      idleTimer = undefined;
    }
  };

  const onUserUpHandler = () => {
    onUserDownHandler();
    idleTimer = setTimeout(() => {
      setRotate(true);
    }, idleTimerInterval);
  };

  const onMoveHandler = ({ zoom: mapZoom }: ViewState) => {
    const { current: mapWrapper } = mapRef;
    if (!mapWrapper) return;

    const map = mapWrapper.getMap();
    const mapBounds = map.getBounds();

    // соберем BBox [x1, y1, x2, y2] 1 - левый верхний 2 правый нижний угол
    const newBounds: [number, number, number, number] = [
      mapBounds.getSouthWest().lng < mapBounds.getNorthEast().lng
        ? mapBounds.getSouthWest().lng
        : mapBounds.getNorthEast().lng,
      mapBounds.getSouthWest().lat > mapBounds.getNorthEast().lat
        ? mapBounds.getSouthWest().lat
        : mapBounds.getNorthEast().lat,
      mapBounds.getSouthWest().lng > mapBounds.getNorthEast().lng
        ? mapBounds.getSouthWest().lng
        : mapBounds.getNorthEast().lng,
      mapBounds.getSouthWest().lat < mapBounds.getNorthEast().lat
        ? mapBounds.getSouthWest().lat
        : mapBounds.getNorthEast().lat,
    ];

    const boundsChanged = bounds ? newBounds.reduce((acc, bound, idx) => acc || bound !== bounds[idx], false) : true;

    if (onZoom && (mapZoom !== zoom || boundsChanged)) {
      onZoom(mapZoom, newBounds);
    }

    setZoom(mapZoom);
    setBounds(newBounds);

    if (mapZoom !== zoom) {
      onUserUpHandler();
    }

    if (rotate) {
      spinGlobe(map);
    }
  };

  const getBoundsGeo = (bounds: [number, number, number, number] | undefined): Feature<Polygon>[] => {
    if (!bounds) return [];
    return [
      {
        type: 'Feature',
        id: 'boundsPoligon',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [bounds[0], bounds[1]],
              [bounds[0], bounds[3]],
              [bounds[2], bounds[3]],
              [bounds[2], bounds[1]],
              [bounds[0], bounds[1]],
            ],
          ],
        },
      },
    ];
  };

  useEffect(() => {
    const { current: mapWrapper } = mapRef;
    if (!mapWrapper) return;

    const map = mapWrapper.getMap();

    if (rotate) {
      spinGlobe(map);
    }
  }, [rotate]);

  const onMapLoad = useCallback(() => {
    const { current: mapWrapper } = mapRef;
    if (!mapWrapper) return;
    const map = mapWrapper.getMap();

    spinGlobe(map);
  }, []);

  function onGlobeMarkerHandler(isCount: boolean, userId: number, compositeId?: number) {
    if (!isCount) {
      return navigate(pathsArg(Paths.profileUser, { id: userId }), { state: { compositeId } });
    }
  }

  return (
    <Map
      initialViewState={{
        zoom: zoom,
        latitude: 27.471730109760017,
        longitude: 34.732489324994077,
      }}
      minZoom={0}
      mapStyle="mapbox://styles/ksupipr/ckyr3k8285cwo14tfvj0xdjtw"
      mapboxAccessToken={MAPBOX_TOKEN}
      projection={{
        name: 'globe',
      }}
      ref={mapRef}
      onLoad={onMapLoad}
      onMoveEnd={({ viewState }) => onMoveHandler(viewState)}
      attributionControl={false}
      onMouseDown={() => onUserDownHandler()}
      onTouchStart={() => onUserDownHandler()}
      onMouseUp={() => onUserUpHandler()}
      onTouchEnd={() => onUserUpHandler()}
      onZoomEnd={handlerZoomEnd}
      onDblClick={(e) => logger.log(e)}
      logoPosition={logoPosition}
    >
      {data.map((point) => {
        const [longitude, latitude] = point.geometry.coordinates;
        return (
          <GlobeMarker
            key={`point_${point.properties.id}`}
            status={point.properties.target?.status}
            longitude={longitude}
            latitude={latitude}
            isCount={point.properties.is_count}
            count={point.properties.count}
            photo={getImgSrc(point.properties.photo_uuid, 's32-c') || ''}
            isCluster={false}
            pointCount={undefined}
            onClick={() =>
              onGlobeMarkerHandler(
                point.properties.is_count,
                point.properties.user_id,
                point.properties.target?.composite_id
              )
            }
          />
        );
      })}

      {showBounds && (
        <Source type="geojson" data={{ type: 'FeatureCollection', features: getBoundsGeo(bounds) }}>
          <Layer
            type="fill"
            paint={{
              'fill-opacity': 0.3,
              'fill-color': '#ff0000',
            }}
          />
        </Source>
      )}
    </Map>
  );
};

export default MapboxGlobe;
