import { Box, Typography } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

export const LevelBlock = styled(Box)(() => ({
  position: 'absolute',
  fontSize: '90px',
  top: -8,
  left: -15,
  width: 60,
  height: 105,
  lineHeight: '105px',
  textAlign: 'center',
  background: ThemeColors.blackDark,
  border: '1px solid',
  borderRadius: '15px',
}));

export const ProgressBlock = styled(Box)(() => ({
  textAlign: 'center',
}));

export const Progress = styled(LinearProgress)(() => ({
  height: 17,
  borderRadius: 15,
  border: '1px solid',
  borderColor: 'currentColor',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    borderRadius: 15,
    borderColor: 'currentColor',
    backgroundColor: ThemeColors.blackDark,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 15,
    backgroundColor: 'currentColor',
  },
}));

export const Img = styled('img')(() => ({
  borderRadius: '15px',
  display: 'block',
}));

export const Title = styled(Typography)(() => ({
  textTransform: 'uppercase',
  backgroundColor: ThemeColors.blackDark,
  padding: '4px',
  textAlign: 'center',
  fontSize: '1.25rem',
  fontWeight: 600,
  display: 'block',
})) as typeof Typography;

export const Description = styled(Typography)(() => ({
  display: 'block',
})) as typeof Typography;
