import { AxiosResponse } from 'axios';

import agent from 'src/agent';
import { TTeamMeta, TTargetStatus, UserRole } from 'src/services';

import { GlobePoint, GlobeType } from './GlobeService.types';

export const getGlobeData = (
  type: GlobeType,
  filter?: {
    userId?: number;
    search?: string;
    status?: TTargetStatus[];
    role?: UserRole[];
  },
  zoom?: number,
  bounds?: [number, number, number, number]
): Promise<AxiosResponse<{ items: GlobePoint[]; meta?: TTeamMeta }>> => {
  let params = {
    type,
    user_id: (filter && filter.userId) || undefined,
    search: filter && filter.search && filter.search.length > 0 ? filter.search : undefined,
    status: filter && filter.status ? JSON.stringify(filter.status) : undefined,
    role: filter && filter.role ? JSON.stringify(filter.role) : undefined,
    zoom,
    bounds: bounds ? JSON.stringify(bounds) : undefined,
  };

  return agent.GET('globe', { params });
};

export const setGeolocation = (latitude: number, longitude: number): Promise<AxiosResponse<{}>> => {
  return agent.POST('player/set-coordinates', { lat: latitude, lon: longitude });
};
