import { Button, Stack } from '@mui/material';
import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyShoppingCart, PrevButton, ShoppingCartFees } from 'src/atoms';
import { PaymentProducts, ProductsList } from 'src/molecules';
import { TOrderItem } from 'src/services';

import { ShoppingCartProps } from './ShoppingCart.types';

const ShoppingCart: FC<ShoppingCartProps> = ({
  order,
  wrapperId,
  accentColor,
  selectedType,
  tkey: tkeyProp,
  confirmFunction,
  onPayment,
  onFinish,
  onDelete,
  onPrev,
}) => {
  const { t } = useTranslation('app');
  const [isOpen, setOpen] = useState(false);
  const [items, setItems] = useState<TOrderItem[]>([]);
  const [countBuy, setCountBuy] = useState<number>(0);

  const tkey = tkeyProp ? `${tkeyProp}.` : '';

  const onOpenPayment = () => {
    if (onPayment) {
      onPayment(items);
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    const orderItems = order.items;
    orderItems.map((item) => (item.count = item.count === undefined ? 1 : item.count));
    setItems(orderItems);
  }, [order]);

  const onClosePayment = () => setOpen(false);

  const handlerProductsChange = (newItems: TOrderItem[]) => {
    setItems(newItems);
  };

  useEffect(() => {
    setCountBuy(items.reduce((acc, item) => acc + (item.count || 0), 0));
  }, [items]);

  return (
    <>
      {items.length ? (
        <ProductsList
          selectedType={selectedType}
          items={items}
          accentColor={accentColor}
          onDelete={onDelete}
          onChange={handlerProductsChange}
        />
      ) : (
        <EmptyShoppingCart />
      )}

      <ShoppingCartFees items={items} />

      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
        {onPrev && <PrevButton onClick={() => onPrev()} />}
        <Button disabled={!items || !countBuy} variant="outlined" fullWidth onClick={onOpenPayment}>
          {t(`button.${tkey}pay`)}
        </Button>
      </Stack>

      {isOpen && (
        <PaymentProducts
          order={order}
          wrapperId={wrapperId}
          tkey={tkeyProp}
          isOpen={isOpen}
          onClose={onClosePayment}
          confirmFunction={confirmFunction}
          onFinish={onFinish}
        />
      )}
    </>
  );
};

export default ShoppingCart;
