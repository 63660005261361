import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, MenuList, MenuItem, Paper } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FONTS } from 'src/styles';
import { setPrefix } from 'src/utils';

import { LanguagesProps } from './Languages.types';

const Languages: FC<LanguagesProps> = ({ list, isShowLangs, onChange }) => {
  const { i18n } = useTranslation();
  const [transform, setTransform] = useState('translateY(100%)');

  const setLanguageHandler = (lang: number, prefix: string) => {
    i18n.changeLanguage(String(lang));
    setPrefix(prefix);
    if (onChange) onChange();
  };

  useEffect(() => {
    setTimeout(() => {
      setTransform('translateY(3px)');
    }, 0);
  }, [isShowLangs]);

  return (
    <Box sx={{ transition: '0.3s', transform, overflow: 'hidden' }}>
      <Paper
        sx={{
          backgroundColor: 'transparent',
          maxHeight: 559,
          overflowY: 'auto',
          overflowX: 'hidden',
          borderRadius: '14px 14px 0 0',
        }}
      >
        <MenuList sx={{ p: 0, mb: '0', overflow: 'hidden' }}>
          {list.map((lang) => (
            <MenuItem
              key={lang.id}
              value={lang.slug}
              onClick={() => setLanguageHandler(lang.id, lang.slug)}
              sx={{
                justifyContent: 'space-between',
                backgroundColor: (theme) => theme.palette.background.paper,
                borderBottomWidth: 2,
                fontFamily: FONTS,
                fontSize: '1.25rem',
                fontWeight: 500,
                lineHeight: '24px',
                p: '18px 20px',

                '&:hover': {
                  opacity: 0.9,
                },

                '&:first-of-type': {
                  borderRadius: '14px 14px 0 0',
                },
              }}
              divider={true}
            >
              {lang.title}
              <KeyboardArrowRightIcon />
            </MenuItem>
          ))}
        </MenuList>
      </Paper>
    </Box>
  );
};

export default Languages;
