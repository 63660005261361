import { Stack, Typography, Box } from '@mui/material';
import { FC } from 'react';

import { ThemeColors } from 'src/styles';

import Markdown from '../Markdown';

import { EmptyDataProps } from './EmptyData.types';

const EmptyData: FC<EmptyDataProps> = ({ title, text, sx, ...rest }) => {
  return (
    <Stack
      data-qa={rest['data-qa']}
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100%', ...sx }}
    >
      <Typography
        sx={{
          fontSize: '1.375rem',
          lineHeight: '28px',
          fontWeight: 500,
          my: '16px',
          textTransform: 'uppercase',
        }}
        variant="h1"
        component="p"
      >
        {title}
      </Typography>
      <Box
        sx={{
          fontSize: '0.812rem',
          lineHeight: '18px',
          fontWeight: 600,
          color: ThemeColors.gray500,
          textAlign: 'center',
          padding: '16px',
        }}
      >
        <Markdown>{text}</Markdown>
      </Box>
    </Stack>
  );
};

export default EmptyData;
