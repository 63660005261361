import { Stack, Typography, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Block = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
}));

export const Table = styled(Stack)(() => ({
  flexDirection: 'column',
  flex: 1,
}));

export const ActionBlock = styled(Box)(() => ({
  flex: 0,
  position: 'relative',
  zIndex: 0,
}));

export const Row = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  flex: 0,
  margin: '4px!important',
  minWidth: '120px',
}));

export const Value = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  flex: 1,
  margin: '4px!important',
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  // fontSize: '0.8em',
  width: '64px',
  height: '64px!important',
  lineHeight: '64px',
  borderRadius: '50%',
}));
