import { styled } from '@mui/material/styles';
import Markdown from 'react-markdown';

export const MarkdownStyled = styled(Markdown, {
  name: 'MarkdownStyped',
  slot: 'root',
})(({ theme }) => ({
  '& iframe[data-type="youtube"]': {
    width: '100%',
    aspectRatio: '16 / 9',
    border: 'none',
  },

  '& a': {
    color: theme.palette.primary.main,
  },

  '& img': {
    width: '100%',
  },

  '& .Block_flex_horizontal': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  '& .Block_flex_vertical': {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
})) as typeof Markdown;
