import { Fab } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';

import { parseError } from 'src/utils';

import { CropDialogProps, PixelCrop } from './CropDialog.types';
import { getCroppedImg } from './CropDialog.utils';

const CropDialog: FC<CropDialogProps> = ({ imageSrc, fileName, mimeType, onChange }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<PixelCrop>({ x: 0, y: 0, width: 600, height: 600 });

  const onCropComplete = useCallback((_: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation,
        { horizontal: false, vertical: false },
        mimeType,
        fileName
      );
      if (croppedImage) {
        onChange(croppedImage);
      }
    } catch (error) {
      enqueueSnackbar(parseError(error), { variant: 'error' });
    }
  }, [imageSrc, croppedAreaPixels, rotation, onChange, mimeType, fileName, enqueueSnackbar]);

  return (
    <>
      {imageSrc ? (
        <div>
          <Cropper
            image={imageSrc}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
      ) : null}
      <Fab variant="contrast" onClick={showCroppedImage} sx={{ position: 'absolute', top: 10, right: 10 }}>
        Ok
      </Fab>
    </>
  );
};

export default CropDialog;
