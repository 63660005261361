import { Avatar, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeFontWeight } from 'src/styles';

export const ProfileBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: 15,
  backgroundColor: theme.palette.background.paper,
  padding: '2px 10px',
  overflow: 'hidden',
  cursor: 'pointer',
}));

export const ProfileTitle = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
  textAlign: 'right',
  paddingRight: 20,
  fontSize: '0.75rem',
}));

export const AvatarBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: 2,
  zIndex: 1,
  borderRadius: '50%',
  border: '5px solid',
  margin: '2px 18px 2px 0px',
}));

export const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 50,
  height: 50,
}));

export const DisplayName = styled(Typography)(() => ({
  fontWeight: ThemeFontWeight.semiBold,
  fontSize: '1.125rem',
  lineHeight: '19px',
  textTransform: 'uppercase',
  margin: '0px 0px 7px 0px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export const PublicAddress = styled(Typography)(({ theme }) => ({
  fontWeight: ThemeFontWeight.regular,
  fontSize: '0.75rem',
  lineHeight: '14px',
  color: theme.palette.primary.main,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  flex: 1,
}));

export const DateReg = styled(Typography)(({ theme }) => ({
  fontWeight: ThemeFontWeight.light,
  fontSize: '0.625rem',
  lineHeight: '14px',
  marginBottom: 0,
  color: theme.palette.secondary.main,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));
