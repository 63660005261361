import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Switch2Links } from 'src/atoms';
import { Paths } from 'src/constants';
import { PageTitle } from 'src/molecules';
import { ThemePaddings } from 'src/styles';

import { getSwitchLinksForWallet } from './Wallet.utils';

const Wallet: FC = () => {
  const { t } = useTranslation('app');

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onCloseHandler = () => navigate(Paths.dashboard);

  const tkey = pathname === Paths.myNFT ? 'myNft' : 'wallet';

  return (
    <>
      <PageTitle title={t('wallet.title')} data-qa={'wallet.title'} help={{ tkey }} onClose={onCloseHandler} />
      <Box sx={{ p: ThemePaddings.walletTemplate }}>
        <Switch2Links currentPath={pathname} links={getSwitchLinksForWallet(t)} />
      </Box>
      <Outlet />
    </>
  );
};

export default Wallet;
