import { AxiosResponse } from 'axios';

import agent from 'src/agent';
import { ProfileFormValues } from 'src/organisms/ProfileForm/ProfileForm.types';
import { RegFormValues } from 'src/organisms/RegForm/RegForm.types';
import { getFormData, setToken } from 'src/utils';

import { AuthRequest, TokenObtain, TokenRefresh, UserMe, User, Player, TUserStudyProgress } from './UserService.types';

/*
export const signIn = async (authData: AuthRequest): Promise<TokenObtain> => {
  const res = await agent.POST<AuthRequest, TokenObtain>('auth', authData);
  setToken(res.data);
  return res.data;
};*/

export const signIn = async (
  provider: 'instagram' | 'google' | 'telegram' | 'wallet',
  authData: any,
  referral_uuid?: string
): Promise<TokenObtain> => {
  let method: any = provider;
  if (provider === 'wallet') method = 'auth';
  const res = await agent.POST<AuthRequest, TokenObtain>(method, { ...authData, referral_uuid });
  setToken(res.data);
  return res.data;
};

export const addUserSN = (
  provider: 'instagram' | 'google' | 'telegram' | 'wallet',
  authData: any
): Promise<AxiosResponse<{}>> => {
  let method: any = provider;
  if (provider === 'wallet') method = 'auth';
  return agent.POST(`add-${method}`, { ...authData });
};

export const checkVerify = async (refresh: TokenRefresh): Promise<TokenObtain> => {
  const res = await agent.POST<TokenRefresh, TokenObtain>('auth/token-refresh', refresh);
  setToken(res.data);
  return res.data;
};

export const getUserInfo = async (): Promise<UserMe> => {
  const { data } = await agent.GET('auth/me');
  return data;
};

export const setUserInfo = (data: RegFormValues): Promise<AxiosResponse<User>> => {
  const formData = getFormData(data);
  return agent.PATCH('auth/onboarding', formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};

export const getUserByReferralUuid = (referral_uuid: string): Promise<AxiosResponse<User>> => {
  return agent.GET('auth/referrer', { params: { referral_uuid } });
};

export const getPlayerData = (): Promise<AxiosResponse<Player>> => {
  return agent.GET('player');
};

export const setProfileData = (data: ProfileFormValues, noticeObj?: any): Promise<AxiosResponse<Player>> => {
  const formData = getFormData({ ...data, ...noticeObj });
  return agent.PATCH('player', formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};

/*
export const telegramSignIn = async (user: TTelegramUser): Promise<TokenObtain> => {
  const res = await agent.POST('telegram', user);
  setToken(res.data);
  return res.data;
};

export const googleSignIn = async (user: TGoogleUser): Promise<TokenObtain> => {
  const res = await agent.POST('google', user);
  setToken(res.data);
  return res.data;
};

export const instagramSignIn = async (user: TInstagramUser): Promise<TokenObtain> => {
  const res = await agent.POST('instagram', user);
  setToken(res.data);
  return res.data;
};*/

export const soltSignIn = async (solt: string): Promise<TokenObtain> => {
  const res = await agent.POST('auth-by-auth-link', { solt });
  setToken(res.data);
  return res.data;
};

export const videoUpload = (file: File, target_id: number): Promise<AxiosResponse<{}>> => {
  const data = new FormData();
  data.append('video_file', file);
  data.append('target_id', String(target_id));
  return agent.POST('video', data, { headers: { 'content-type': 'multipart/form-data' } });
};

export const getUserProgress = (): Promise<AxiosResponse<TUserStudyProgress[]>> => {
  return agent.GET('progress');
};

export const getAuthLink = async (): Promise<AxiosResponse<{ link: string; solt: string }>> => {
  return agent.GET('auth-link');
};

export const setWalletLink = async (params: {
  public_address: string;
  signature: string;
  nonce: string;
}): Promise<AxiosResponse<{}>> => {
  return agent.POST('add-wallet', { ...params });
};

export const setTonWallet = (wallet: string): Promise<AxiosResponse<any>> => {
  return agent.POST('add-ton-usdt', { wallet });
};
