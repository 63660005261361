import * as d3 from 'd3';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// import { worldWidgetImage } from 'src/assets/images';
import { Paths } from 'src/constants';
import { ImageWidget } from 'src/molecules';
import { ThemeColors } from 'src/styles';

import { StructureWidgetTitle } from './StructureWidget.styles';
import { StructureWidgetProps } from './StructureWidget.types';

const geoJson = {
  type: 'FeatureCollection',
  features: [] as any[],
};

const geoJsonArk = {
  type: 'FeatureCollection',
  features: [] as any[],
};

let prevCoord: any;

new Array(50).fill(0).forEach(() => {
  const coord1 = [(Math.random() - 0.5) * 180, (Math.random() - 0.5) * 360];
  const coord2 = [(Math.random() - 0.5) * 180, (Math.random() - 0.5) * 360];
  geoJson.features.push({
    type: 'Feature',
    properties: {},
    geometry: {
      coordinates: coord1,
      type: 'Point',
    },
  });
  geoJson.features.push({
    type: 'Feature',
    properties: {},
    geometry: {
      coordinates: coord2,
      type: 'Point',
    },
  });
  geoJsonArk.features.push({
    type: 'Feature',
    properties: {},
    geometry: {
      coordinates: [coord1, coord2],
      type: 'LineString',
    },
  });
  if (prevCoord) {
    geoJsonArk.features.push({
      type: 'Feature',
      properties: {},
      geometry: {
        coordinates: [prevCoord[0], coord2],
        type: 'LineString',
      },
    });
    geoJsonArk.features.push({
      type: 'Feature',
      properties: {},
      geometry: {
        coordinates: [prevCoord[1], coord1],
        type: 'LineString',
      },
    });
  } else {
    prevCoord = [coord1, coord2];
  }
});

/*8
arcsData = new Array(30).fill(0).map(() => ({
  startLat: (Math.random() - 0.5) * 180,
  startLng: (Math.random() - 0.5) * 360,
  endLat: (Math.random() - 0.5) * 180,
  endLng: (Math.random() - 0.5) * 360,
  color: [ThemeColors.blue, ThemeColors.blueLight, ThemeColors.purple, ThemeColors.purpleLight],
}));*/

const StructureWidgetD3: FC<{ size: number; geoJson: any }> = ({ size, geoJson }) => {
  const [rotation, setRotation] = useState(0);

  //d3.json('https://codepen.io/frontendcharts/pen/JBprpp.js', (err, json) => {
  // let geoJson = topojson.feature(json, json.objects.ne_110m_admin_0_countries);

  const projection = d3.geoOrthographic().fitSize([size, size], geoJson).rotate([rotation, 0, 0]);

  const geoGenerator = d3.geoPath().projection(projection);

  // const graticules = d3.geoGraticule()();
  const pathArc = geoGenerator(geoJsonArk as any);

  window.requestAnimationFrame(() => {
    setRotation(rotation + 0.2);
  });

  const scale = 0.99;
  const translate = (size - size * scale) / 2;

  const scale0 = 0.85;
  const translate0 = (size - size * scale0) / 2;

  return (
    <svg width={size} height={size}>
      <g transform={`scale(${scale0}, ${scale0}) translate(${translate0}, ${translate0})`}>
        <path
          stroke={ThemeColors.gold2}
          strokeWidth="0.5"
          transform={`scale(${scale}, ${scale}) translate(${translate}, ${translate})`}
          d={pathArc || undefined}
        />
        {/*
        <path
          //fill={ThemeColors.blackDark}
          stroke={ThemeColors.blackDark}
          strokeWidth="6.0"
          d={pathString || undefined}
        />
        <path stroke={ThemeColors.gold2} strokeWidth="2.0" d={pathString || undefined} />
        */}
        {geoJson.features.map((point: any, idx: number) => {
          // const center = d3.geoCircle (point.geometry.coordinates);
          const circle = d3.geoCircle().center(point.geometry.coordinates).radius(4);
          const pathCircle = geoGenerator(circle());

          return (
            pathCircle && (
              <path
                key={`StructureWidgetD3_point_${idx}`}
                fill={ThemeColors.blackDark}
                stroke={ThemeColors.gold2}
                strokeWidth="2.5"
                d={pathCircle}
              />
            )
          );
        })}
      </g>
    </svg>
  );
};

const StructureWidget: FC<StructureWidgetProps> = ({ size, sx }) => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(Paths.structure);
  };

  return (
    <ImageWidget
      onClick={onClickHandler}
      sx={sx}
      value={<StructureWidgetTitle component="div">{t('dashboard.structureText')}</StructureWidgetTitle>}
    >
      <StructureWidgetD3 size={(size || 120) - 20} geoJson={geoJson} />
    </ImageWidget>
  );
};

export default StructureWidget;
