import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Paths } from 'src/constants';
import { PageTitle } from 'src/molecules';
import {
  StudyList,
  StudyTarget,
  StudyTutorial,
  StudyWallet,
  StudyContract,
  StudyClans,
  StudySubscribe,
} from 'src/organisms';
import { getUserProgress, TUserStudyProgress } from 'src/services';
import { ThemePaddings } from 'src/styles';
import { RouterIdParam } from 'src/types';
import { parseErrorTr } from 'src/utils';

const Study: FC = () => {
  const { id } = useParams<RouterIdParam>();
  const { t } = useTranslation('app');
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [steps, setSteps] = useState<TUserStudyProgress[]>([]);

  const onCloseHandler = () => (id ? navigate(Paths.study) : navigate(Paths.dashboard));

  useEffect(() => {
    setLoading(true);
    getUserProgress()
      .then(({ data }) => {
        setSteps(data);
      })
      .catch((error: any) => {
        enqueueSnackbar(parseErrorTr('study', error), { variant: 'error' });
      })
      .finally(() => setLoading(false));
  }, [enqueueSnackbar]);

  let step;
  let StepControl;
  if (id) {
    step = steps.find((step) => step.name === id);
    switch (id) {
      case 'target':
        StepControl = StudyTarget;
        break;
      case 'wallet':
        StepControl = StudyWallet;
        break;
      case 'contract':
        StepControl = StudyContract;
        break;
      case 'clans':
        StepControl = StudyClans;
        break;
      case 'subscribe':
        StepControl = StudySubscribe;
        break;
      default:
        StepControl = StudyTutorial;
    }
  }

  /*
  if (id && steps.find((step) => step.id === Number(id))?.blocked) {
    return <Navigate to={Paths.study} replace={true} />;
  }
  */

  const stepCount = steps.reduce((acc, step) => {
    return acc + (step.is_success ? 1 : 0);
  }, 0);

  return (
    <Box sx={{ p: ThemePaddings.page, height: '100%' }}>
      <PageTitle
        title={t(id ? `study.${id}.title` : 'study.title')}
        subtitle={t(id ? `study.${id}.subtitle` : 'study.subtitle')}
        onClose={onCloseHandler}
        isLoading={isLoading}
      />
      {isLoading ? null : step ? (
        StepControl && (
          <StepControl
            name={step.name}
            stepCount={step.step_count || 1}
            onFinish={() => {
              if (stepCount === steps.length - 1) {
                navigate(Paths.dashboard);
              } else {
                navigate(Paths.study);
              }
            }}
          />
        )
      ) : (
        <>
          <StudyList items={steps} />
        </>
      )}
    </Box>
  );
};

export default Study;
