import { Undo, ClearOutlined, DeleteForeverOutlined, DisabledByDefaultOutlined, SelectAll } from '@mui/icons-material';
import { Box } from '@mui/material';
import { FC, useEffect, useRef, useReducer, useState, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AvatarBackgroundIcon,
  AvatarClothesIcon,
  AvatarColorIcon,
  AvatarEyesIcon,
  AvatarHairIcon,
  AvatarMaterialIcon,
  AvatarBagpackIcon,
  AvatarBasketIcon,
  AvatarCardIcon,
} from 'src/assets/icons';
import { FabMenuAction } from 'src/atoms';
import { FabMenu, PageTitle } from 'src/molecules';
import {
  DrawerAvatarMaterialChooser,
  DrawerAvatarSkinChooser,
  DrawerAvatarEyesChooser,
  DrawerAvatarHairsChooser,
  DrawerAvatarBackgroundChooser,
  DrawerAvatarClothesChooser,
} from 'src/organisms';

import {
  AvatarEditorView,
  AvatarEditorBlock,
  AvatarEditorLeftMenuBlock,
  AvatarEditorRightMenuBlock,
} from './AvatarEditor.styles';
import { AvatarState, AvatarAction, AvatarEditPart, AvatarEditorProps } from './AvatarEditor.types';

const initialState: AvatarState = {
  selectorCount: 0,
  materialCount: 0,
  selectedClothes: undefined,
  config: undefined,
};

const reducer = (state: AvatarState, action: AvatarAction): AvatarState => {
  const avatarModelReducer = (event: AvatarAction['event']) => {
    /**
     * Обработчик действий событий модели avatar-model
     *    outline:add - выделение участка
     *    outline:remove - снятие выделения
     *
     *    пример данных: {name: 'Part_89', guid: '! |'}
     */
    switch (event) {
      case 'selector:add':
        return {
          ...state,
          selectorCount: action.data?.count,
        };
      case 'selector:remove':
        return {
          ...state,
          selectorCount: action.data?.count,
        };
      case 'selector:clear':
        return {
          ...state,
          selectorCount: action.data?.count,
        };
      case 'selector:apply':
        return {
          ...state,
          selectorCount: action.data?.count,
        };
      case 'material:apply':
        return {
          ...state,
          materialCount: action.data?.count,
        };
      case 'material:remove':
        return {
          ...state,
          materialCount: action.data?.count,
        };
      default:
        return state;
    }
  };

  switch (action.type) {
    case 'avatar-selector':
    case 'avatar-material':
      return avatarModelReducer(action.event);
    case 'avatar-clothes':
      switch (action.event) {
        case 'select':
          return {
            ...state,
            selectedClothes: action.data?.selectedClothes,
          };
      }
      return state;
    case 'avatar-save':
      if (action.event === 'end') {
        return {
          ...state,
          config: action.data?.config,
        };
      }
      return state;
    case 'close':
      return initialState;
    default:
      return state;
  }
};

const AvatarEditor: FC<AvatarEditorProps> = ({ onClose }) => {
  const { t } = useTranslation('app');
  const [state, dispatch] = useReducer(reducer, initialState);
  const [menuValue, setMenuValue] = useState<string | undefined>();

  const pcFrame = useRef<Window | null>();

  const handlerClose = () => {
    window.postMessage({ target: 'avatar-save', event: 'begin' }, window.location.origin);
  };

  if (state.config) {
    onClose(state.config.img);
  }

  const onCloseDrawerHandler = () => {
    dispatch({ type: 'close' });
  };

  const handlerApplyMaterial = (tab?: number, level?: number, grade?: number) => {
    window.postMessage(
      { target: 'avatar-material', event: 'material:apply', data: { tab, level, grade } },
      window.location.origin
    );
  };

  const handlerApplySkin = (skin?: number) => {
    window.postMessage({ target: 'avatar-skin', event: 'material:apply', data: { skin } }, window.location.origin);
  };

  const handlerApplyEyes = (eyes?: number) => {
    window.postMessage({ target: 'avatar-eyes', event: 'material:apply', data: { eyes } }, window.location.origin);
  };

  const handlerApplyHairs = (hair?: number) => {
    window.postMessage({ target: 'avatar-hairs', event: 'model:apply', data: { hair } }, window.location.origin);
  };

  const handlerApplyBackground = (background?: number) => {
    window.postMessage({ target: 'avatar-background', event: 'apply', data: { background } }, window.location.origin);
  };

  const handlerApplyClothes = (level: number, type: number, clothes: number) => {
    // dispatch({ type: 'avatar-clothes', event: 'select', data: { selectedClothes: { type, clothes } } });
    window.postMessage(
      { target: 'avatar-clothes', event: 'apply', data: { type, clothes, level } },
      window.location.origin
    );
  };

  const handlerSelectClothes = (level: number, type: number, clothes: number) => {
    dispatch({ type: 'avatar-clothes', event: 'select', data: { selectedClothes: { level, type, clothes } } });
  };

  const handlerSubmenu = (val: string) => {
    switch (val) {
      case 'clearselect':
        window.postMessage({ target: 'avatar-selector', event: 'clear' }, window.location.origin);
        break;
      case 'selectall':
        window.postMessage({ target: 'avatar-selector', event: 'selectAll' }, window.location.origin);
        break;
      case 'flushmaterials':
        window.postMessage({ target: 'avatar-material', event: 'material:flush' }, window.location.origin);
        break;
      case 'removematerials':
        window.postMessage({ target: 'avatar-material', event: 'material:remove' }, window.location.origin);
        break;
      case 'flushclothes':
        window.postMessage({ target: 'avatar-clothes', event: 'flush' }, window.location.origin);
        break;
      case 'removeclothes':
        window.postMessage(
          { target: 'avatar-clothes', event: 'remove', data: state.selectedClothes },
          window.location.origin
        );
        break;
    }
  };

  useEffect(() => {
    const handler = (event: MessageEvent) => {
      if (event.origin === window.location.origin) {
        // тут обработка событий от аватара. Открытие интерфейсных окон для применения материалов
        dispatch({ type: event.data?.target, event: event.data.event, data: event.data.data });
      }
    };

    window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);
  }, []);

  const onMenuValue = (value: string | undefined) => {
    setMenuValue(value);
    const target =
      value === AvatarEditPart.eyes || value === AvatarEditPart.hair ? 'Head' : value === undefined ? 'Model' : 'Apply';
    const offset =
      value === AvatarEditPart.eyes || value === AvatarEditPart.hair ? -0.15 : value === undefined ? 0 : -0.3;
    window.postMessage(
      {
        target: 'avatar-camera',
        event: 'target',
        data: { target, offset },
      },
      window.location.origin
    );
    // для материалов включим еще режим выделения участков
    window.postMessage(
      { target: 'avatar-selector', event: 'enabled', data: value === AvatarEditPart.bagpack },
      window.location.origin
    );
  };

  const avatarURL = `/AvatarPC/index.html?config=${JSON.stringify({})}`;

  const submenuActions = {
    _all: [
      {
        value: 'back',
        name: 'откатить',
        icon: <Undo />,
        verify: () => false,
      },
    ],
    [AvatarEditPart.bagpack.toString()]: [
      {
        value: 'flushmaterials',
        name: 'снять все',
        icon: <DeleteForeverOutlined />,
        verify: () => !!state.materialCount,
      },
      {
        value: 'clearselect',
        name: 'очистить выделение',
        icon: <DisabledByDefaultOutlined />,
        verify: () => !!state.selectorCount,
      },
      {
        value: 'selectall',
        name: 'выделить все',
        icon: <SelectAll />,
        verify: () => true,
      },
      {
        value: 'removematerials',
        name: 'снять',
        icon: <ClearOutlined />,
        verify: () => !!state.selectorCount,
      },
    ],
    [AvatarEditPart.clothes.toString()]: [
      {
        value: 'flushclothes',
        name: 'снять все',
        icon: <DeleteForeverOutlined />,
        verify: () => true,
      },
      {
        value: 'removeclothes',
        name: 'снять',
        icon: <ClearOutlined />,
        verify: () => state.selectedClothes,
      },
    ],
  };

  const subMenu = (menu: string | undefined): ReactElement[] | undefined => {
    if (menu === undefined) return undefined;
    const actions = [...submenuActions['_all'], ...(submenuActions[menu] || [])];

    return actions
      .filter((action) => action.verify())
      .map((action) => {
        return (
          <FabMenuAction
            key={`submenu_${action.value}`}
            value={action.value}
            // name={t('avatar.menu.background')}
            name={action.name}
            icon={action.icon}
            onClick={handlerSubmenu}
          />
        );
      });
  };

  return (
    <AvatarEditorBlock direction="column">
      <Box sx={{ flex: 'none', height: '100px' }}>
        <PageTitle title={t('avatar.title')} onClose={handlerClose} isTransparent />
      </Box>
      <Box sx={{ flex: 1 }}>
        <AvatarEditorLeftMenuBlock>
          <FabMenu value={menuValue} onClear={() => onMenuValue(undefined)}>
            <FabMenuAction
              value={AvatarEditPart.material}
              name={t('avatar.menu.material')}
              icon={<AvatarMaterialIcon />}
              onClick={onMenuValue}
            >
              <FabMenuAction
                value={AvatarEditPart.bagpack}
                name={t('avatar.menu.bagpack')}
                icon={<AvatarBagpackIcon />}
                onClick={onMenuValue}
              />
              <FabMenuAction
                value={AvatarEditPart.wallet}
                name={t('avatar.menu.wallet')}
                icon={<AvatarCardIcon />}
                onClick={onMenuValue}
              />
              <FabMenuAction
                value={AvatarEditPart.market}
                name={t('avatar.menu.market')}
                icon={<AvatarBasketIcon />}
                onClick={onMenuValue}
              />
            </FabMenuAction>
            <FabMenuAction
              value={AvatarEditPart.background}
              name={t('avatar.menu.background')}
              icon={<AvatarBackgroundIcon />}
              onClick={onMenuValue}
            />
            <FabMenuAction
              value={AvatarEditPart.eyes}
              name={t('avatar.menu.eyes')}
              icon={<AvatarEyesIcon />}
              onClick={onMenuValue}
            />
            <FabMenuAction
              value={AvatarEditPart.hair}
              name={t('avatar.menu.hair')}
              icon={<AvatarHairIcon />}
              onClick={onMenuValue}
            />
            <FabMenuAction
              value={AvatarEditPart.clothes}
              name={t('avatar.menu.clothes')}
              icon={<AvatarClothesIcon />}
              onClick={onMenuValue}
            />
            <FabMenuAction
              value={AvatarEditPart.skin}
              name={t('avatar.menu.skin')}
              icon={<AvatarColorIcon />}
              onClick={onMenuValue}
            />
          </FabMenu>
        </AvatarEditorLeftMenuBlock>
        <AvatarEditorRightMenuBlock>
          <FabMenu value={menuValue} showNames={true} onClear={() => onMenuValue(undefined)}>
            {subMenu(menuValue)}
          </FabMenu>
        </AvatarEditorRightMenuBlock>
        <DrawerAvatarMaterialChooser
          isOpen={menuValue === AvatarEditPart.bagpack}
          onClose={onCloseDrawerHandler}
          isHasSelectParth={(state.selectorCount || 0) > 0}
          onApply={handlerApplyMaterial}
        />
        <DrawerAvatarBackgroundChooser
          isOpen={menuValue === AvatarEditPart.background}
          onClose={onCloseDrawerHandler}
          onApply={handlerApplyBackground}
        />
        <DrawerAvatarClothesChooser
          isOpen={menuValue === AvatarEditPart.clothes}
          onClose={onCloseDrawerHandler}
          onApply={handlerApplyClothes}
          onSelect={handlerSelectClothes}
        />
        <DrawerAvatarSkinChooser
          isOpen={menuValue === AvatarEditPart.skin}
          onClose={onCloseDrawerHandler}
          onApply={handlerApplySkin}
        />
        <DrawerAvatarEyesChooser
          isOpen={menuValue === AvatarEditPart.eyes}
          onClose={onCloseDrawerHandler}
          onApply={handlerApplyEyes}
        />
        <DrawerAvatarHairsChooser
          isOpen={menuValue === AvatarEditPart.hair}
          onClose={onCloseDrawerHandler}
          onApply={handlerApplyHairs}
        />
        <AvatarEditorView>
          <Box component="iframe" ref={pcFrame} title="Avatar" src={avatarURL} className="avatar_frame" />
        </AvatarEditorView>
      </Box>
    </AvatarEditorBlock>
  );
};

export default AvatarEditor;
