import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Stack, Button, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GoalStatusFilterProps, TGoalFilter } from './GoalStatusFilter.types';

const GoalStatusFilter: FC<GoalStatusFilterProps> = ({ filter, value, onChange, tkey, sx }) => {
  const { t } = useTranslation('app');

  const [val, setVal] = useState<TGoalFilter[]>(value || []);

  useEffect(() => {
    setVal(value || []);
  }, [value]);

  const toggleStatus = (status: TGoalFilter) => {
    const sIdx = (val || []).findIndex((stat) => stat === status);
    if (sIdx >= 0) {
      const newVal = (val || []).filter((stat) => stat !== status);
      setVal(newVal);
      onChange(newVal);
    } else {
      const newVal = (val || []).concat([status]);
      setVal(newVal);
      onChange(newVal);
    }
  };

  return (
    <Stack sx={sx} direction="row" justifyContent="center" alignContent="center" spacing={1}>
      {filter.map((status) => {
        const stateColorT = t(`${tkey}.color.${status}`);
        const stateColor = stateColorT[0] === '#' ? stateColorT : '#fff';
        const active = (val || []).findIndex((stat) => stat === status) >= 0;
        return (
          <Button
            onClick={() => toggleStatus(status)}
            key={`GoalStatusFilterBotton_${status}`}
            sx={{
              height: '25px',
              backgroundColor: active ? 'background.paper' : 'background.default',
              borderColor: active ? 'primary.main' : 'background.default',
              opacity: active ? 1 : 0.5,
              justifyContent: 'flex-start',
              overflow: 'hidden',
              whiteSpace: 'normal',
              flex: 1,
              '& .MuiButton-startIcon': {
                marginLeft: '-30px',
                marginRight: '0px',
              },
            }}
            variant="text"
            startIcon={
              <AccountCircleOutlinedIcon
                sx={{
                  color: stateColor,
                  width: '24px',
                  height: '24px',
                  fontWeight: 48,
                }}
              />
            }
          >
            <Typography
              data-qa={`${tkey}.${status}`}
              sx={{ fontSize: '0.625rem', textOverflow: 'ellipsis', lineHeight: '9px' }}
            >
              {t(`${tkey}.${status}`)}
            </Typography>
          </Button>
        );
      })}
    </Stack>
  );
};

export default GoalStatusFilter;
