import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CircleLoading, DrawerBottom } from 'src/atoms';
import { WindowTitle } from 'src/molecules';

import { PaymentTransactionLoaderProps } from './PaymentTransactionLoader.types';

const PaymentTransactionLoader: FC<PaymentTransactionLoaderProps> = ({
  isOpen,
  onClose,
  isComplite,
  tkey: tkeyProp,
}) => {
  const { t } = useTranslation('app');
  const tkey = tkeyProp || 'paymentTransaction';

  return (
    <DrawerBottom isOpen={isOpen} onClose={onClose}>
      <WindowTitle
        sx={{ mb: '20px' }}
        title={!isComplite ? t(`${tkey}.transactionCheckTitle`) : t(`${tkey}.paymentSuccessTitle`)}
        onClose={() => onClose()}
      />

      <Box sx={{ my: '34px' }}>
        <CircleLoading isLoading={!isComplite} />
      </Box>
      <Typography>{t(`${tkey}.waitTransaction`)}</Typography>
      <Box sx={{ width: '100%', zIndex: 0, position: 'relative', mt: '10px' }}>
        <Button variant="outlined" onClick={onClose} disabled={!isComplite} fullWidth>
          {t(`${tkey}.button.complete`)}
        </Button>
      </Box>
    </DrawerBottom>
  );
};

export default PaymentTransactionLoader;
