import { AxiosResponse } from 'axios';

import agent from 'src/agent';
import { Levels, PaginationParams } from 'src/types';

import { ClanList } from '../StructureService';

import { DemoData } from './DemoService.types';

export const getDemoData = (): Promise<AxiosResponse<DemoData>> => {
  return agent.GET('demo-status');
};

export const createFakeMemberForClan = (
  clanLevel: Levels,
  params?: PaginationParams
): Promise<AxiosResponse<ClanList>> => {
  const { limit = 1, offset = 0 } = params || {};
  return agent.POST('clans/create-fake-member', { clan_level: clanLevel, limit, offset });
};
