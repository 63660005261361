import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  minWidth: '90px',
  minHeight: '90px',
  overflow: 'hidden',

  '&:after': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: 5,
    background: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    zIndex: 2,
    height: '100%',
  },
}));

export const FillEnergy = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: 5,
  background: theme.palette.background.paper,
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  zIndex: 3,
}));

export const CurrentPoint = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  bottom: 0,
  zIndex: 2,
  width: '100%',
  height: '100%',
  textAlign: 'center',
  containerName: 'currentPoint',

  div: {
    marginLeft: '-32px',
    width: '82px',
    position: 'absolute',
    textAlign: 'center',
    zIndex: 1,
  },

  '&:before': {
    content: '""',
    position: 'absolute',
    top: '100%',
    right: '0',
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.primary.main,
    zIndex: -3,
  },

  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: '0',
    width: '100%',
    height: '100%',
    opacity: 0.5,
    backgroundColor: theme.palette.background.paper,
    zIndex: 0,
  },
}));

export const FromPoint = styled(CurrentPoint)(() => ({
  bottom: 0,
  top: 'unset',

  div: {
    bottom: -1,
    top: 'unset',
  },

  '&:before': {
    top: '100%',
    bottom: 'unset',
    width: 'calc(100% - 10px)',
    marginRight: '10px',
  },
}));

export const ToPoint = styled(CurrentPoint)(() => ({
  bottom: 'unset',
  top: 0,

  div: {
    bottom: 'unset',
    top: -1,
  },

  '&:before': {
    top: 'unset',
    bottom: '100%',
    width: 'calc(100% - 10px)',
    marginRight: '10px',
  },
}));

export const Point = styled(Box)(({ theme }) => ({
  position: 'relative',
  border: '1px solid',
  borderRadius: '10px',
  backgroundColor: theme.palette.background.paper,
  display: 'inline-block',
  padding: '8px',
  fontSize: '1.5rem',
  lineHeight: '28px',
  height: '48px',
  color: theme.palette.primary.main,
}));
