import { FC } from 'react';

import { Wrapper, Title, BadgeBlock } from './TitleBadge.styles';
import { TitleBordersProps } from './TitleBadge.types';

const TitleBorders: FC<TitleBordersProps> = ({ children, count, onClick, sx }) => {
  return (
    <Wrapper sx={sx} direction="row" justifyContent="space-between" alignItems="center">
      <Title>{children}</Title>
      {count > 0 && <BadgeBlock onClick={() => onClick && onClick()}>{count}</BadgeBlock>}
    </Wrapper>
  );
};

export default TitleBorders;
