import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

type TitleProps = {
  color?: string;
};

export const CarouselMaterialTitle = styled(Typography)<TitleProps>(({ color }) => ({
  color: ThemeColors.black,
  lineHeight: '30px',
  fontWeight: 'bold',
  '& .CarouselMaterialTitle__level': {
    color,
  },
}));
