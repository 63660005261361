import { FC } from 'react';

import { Block, BadgeBlock } from './IconButtonBadge.styles';
import { IconButtonBadgeProps } from './IconButtonBadge.types';

const IconButtonBadge: FC<IconButtonBadgeProps> = ({ icon, count, sx, onClick }) => {
  return (
    <Block variant="contained" sx={sx} onClick={() => (onClick ? onClick() : null)}>
      {icon}
      <BadgeBlock className={count ? 'IconButtonBadge_unread' : ''}>
        {count ? (count > 99 ? '99+' : count) : 0}
      </BadgeBlock>
    </Block>
  );
};

export default IconButtonBadge;
