import { Paper, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeGradients } from 'src/styles';

const componentName = 'LineStepper';

enum slots {
  root = 'Root',
  lines = 'Lines',
  line = 'Line',
}

export const StepperRoot = styled(Paper, {
  name: componentName,
  slot: slots.root,
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  background: theme.palette.background.default,
  padding: '8px 0',
}));

export const StepperLines = styled('div', {
  name: componentName,
  slot: slots.lines,
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

type StepperLineProps = {
  isActive?: boolean;
};

export const StepperLine = styled(Box, {
  name: componentName,
  slot: slots.line,
})<StepperLineProps>(({ theme, isActive = false }) => ({
  backgroundColor: theme.palette.action.disabled,
  borderRadius: '4px',
  flex: '1 1',
  height: 4,
  margin: '0 4px',
  ...(isActive && {
    background: ThemeGradients.bg,
  }),
}));
