import { Box } from '@mui/material';
import { FC, useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { LevelSwitcher, DrawerBottom } from 'src/atoms';
import { Paths, pathsArg } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { PageTitle } from 'src/molecules';
import { MapboxHex, HexagonView } from 'src/organisms';
import { getHexagonList, THexagonItem } from 'src/services';
import { logger } from 'src/utils';

import { HexGlobeBlock } from './HexGlobe.styles';

const HexGlobe: FC = () => {
  const { t } = useTranslation('app');
  const { level = '1' } = useParams<{ level: string }>();
  const { throwServerError } = useContext(ErrorContext);
  const [isLoading, setLoading] = useState(true);
  const [filledLevels, setFilledLevels] = useState<number[]>([]);
  const [hexList, setHexList] = useState<THexagonItem[]>([]);
  const [selectedHex, setSelectedHex] = useState<string>();

  const navigate = useNavigate();
  const onCloseHandler = () => navigate(Paths.dashboard);
  const onChangeLevel = (newLevel: number) => navigate(pathsArg(Paths.hexglobeLevel, { level: newLevel }));

  const handlerHexClick = (h3index: string) => {
    setSelectedHex(h3index);
  };

  useEffect(() => {
    const _level = parseInt(level, 10) || 1;
    /* генератор гексов из карты полигонов
    GeneratePoligonHex(_level).then((genHex) => {
      let hList: THexagonItem[] = [];
      genHex.forEach((h3list) => {
        h3list.forEach((h3idx) => {
          hList.push({
            index: h3idx,
            level: 1,
            title: '',
            status: 'FREEUNAVAIL',
          });
        });
      });
      setHexList(hList);
    });
    */
    setLoading(true);
    getHexagonList(_level)
      .then(({ data }) => {
        setHexList(data.results);
        setFilledLevels([1, 2, 3, 4, 5].map((l) => data.meta[l]?.length || 0));
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
        logger.error(error);
      })
      .finally(() => setLoading(false));
  }, [throwServerError, level]);

  return (
    <HexGlobeBlock direction="column">
      <Box
        sx={{
          position: 'absolute',
          top: '16px',
          zIndex: 2,
          left: '50%',
          right: 0,
          transform: 'translateX(-50%)',
          width: '98%',
          maxWidth: '510px',
        }}
      >
        <LevelSwitcher level={parseInt(level, 10) || 1} filled={filledLevels} onChangeLevel={onChangeLevel} />
      </Box>
      <Box sx={{ flex: 1, position: 'relative' }}>
        <MapboxHex data={hexList} onClick={handlerHexClick} />
      </Box>
      <DrawerBottom isOpen={!!selectedHex} onClose={() => setSelectedHex(undefined)}>
        {selectedHex ? <HexagonView h3index={selectedHex} /> : null}
      </DrawerBottom>
    </HexGlobeBlock>
  );
};

export default HexGlobe;
