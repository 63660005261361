import { Box } from '@mui/material';
import { FC, useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FixedSizeGrid as RWGrid } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { EmptyData, GoalHistoryItem, TitleBadge } from 'src/atoms';
import { DEFAULT_LIMIT } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { useResize } from 'src/hooks';
import { getGoalHistory, TTargetCurrentItem } from 'src/services';
import { ThemePaddings } from 'src/styles';
import { RouterIdParam } from 'src/types';
import { logger } from 'src/utils';

import { GoalListProps } from './GoalListHistory.types';

const GoalList: FC<GoalListProps> = ({ sx }) => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const { id } = useParams<RouterIdParam>();
  const user_id = parseInt(String(id), 10) || undefined;

  const { width: wrapperWidth, height: wrapperHeight } = useResize(containerRef);

  const [isLoading, setLoading] = useState(true);
  const [isPending, setPending] = useState(false);
  const [items, setItems] = useState<TTargetCurrentItem[] | null>(null);
  const [offset, setOffset] = useState(0);
  // const [count, setCount] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [maxCount, setMaxCount] = useState(0);

  useEffect(() => {
    if (offset === 0) {
      setLoading(true);
      getGoalHistory({ offset, limit: DEFAULT_LIMIT }, user_id)
        .then(({ data }) => {
          setItems(data.results);
          // setCount(data.results.length);
          setMaxCount(data.count);
          setOffset(DEFAULT_LIMIT);
        })
        .catch((error) => {
          if (error.response?.status >= 500) throwServerError();
          logger.error(error);
        })
        .finally(() => setLoading(false));
    }
  }, [offset, user_id, throwServerError]);

  useEffect(() => {
    const { current } = containerRef;
    if (!current) return;

    setWidth(current.offsetWidth);
    setHeight(current.offsetHeight);
  }, [containerRef]);

  useEffect(() => {
    if (!wrapperHeight && !wrapperWidth) return;

    setWidth(wrapperWidth);
    setHeight(wrapperHeight);
  }, [wrapperWidth, wrapperHeight]);

  const isItemLoaded = (index: number) => (items?.length ? !!items[index] : false);

  const loadMoreItems = () => {
    if (offset >= maxCount) return;

    if (!isPending) {
      setPending(true);
      getGoalHistory({ offset: offset, limit: DEFAULT_LIMIT }, user_id)
        .then(({ data }) => {
          items && setItems([...items, ...data.results]);
          setOffset((prevOffset) => prevOffset + DEFAULT_LIMIT);
        })
        .catch((error) => {
          if (error.response?.status >= 500) throwServerError();
          logger.error(error);
        })
        .finally(() => setPending(false));
    }
  };

  const NUM_COLUMNS = 2;
  const WIDTH_OFFSET = 0;
  const HEIGHT_OFFSET = 208;

  return (
    <>
      <TitleBadge count={maxCount}>{t('goalHistory.title')}</TitleBadge>
      <Box sx={{ px: ThemePaddings.pageX, py: ThemePaddings.pageX, height: '100%', ...sx }}>
        <Box ref={containerRef} sx={{ height: '100%' }}>
          {!isLoading &&
            (items?.length ? (
              <Box
                sx={{
                  position: 'relative',
                  width: width - WIDTH_OFFSET,
                  height: '100%',
                  margin: '0 auto',
                }}
              >
                <InfiniteLoader
                  isItemLoaded={isItemLoaded}
                  itemCount={maxCount}
                  loadMoreItems={loadMoreItems}
                  threshold={DEFAULT_LIMIT}
                >
                  {({ onItemsRendered, ref }) => (
                    <RWGrid
                      columnCount={NUM_COLUMNS}
                      rowCount={Math.ceil(items.length / NUM_COLUMNS)}
                      width={width - WIDTH_OFFSET}
                      columnWidth={(width - WIDTH_OFFSET) / NUM_COLUMNS}
                      height={height}
                      rowHeight={(width - WIDTH_OFFSET + HEIGHT_OFFSET) / NUM_COLUMNS}
                      onItemsRendered={(gridProps) => {
                        onItemsRendered({
                          overscanStartIndex: gridProps.overscanRowStartIndex * NUM_COLUMNS,
                          overscanStopIndex: gridProps.overscanRowStopIndex * NUM_COLUMNS,
                          visibleStartIndex: gridProps.visibleRowStartIndex * NUM_COLUMNS,
                          visibleStopIndex: gridProps.visibleRowStopIndex * NUM_COLUMNS,
                        });
                      }}
                      ref={ref}
                    >
                      {({ columnIndex, rowIndex, style }) => {
                        const itemIndex = rowIndex * NUM_COLUMNS + columnIndex;

                        const item = items[itemIndex];
                        const last = itemIndex === items.length - 1 ? 16 : 0;
                        return (
                          <div
                            style={{
                              ...style,
                              height: `${parseInt(style.height + '', 10) + last}px`,
                              paddingBottom: `${last}px`,
                            }}
                          >
                            {item && <GoalHistoryItem item={item} />}
                          </div>
                        );
                      }}
                    </RWGrid>
                  )}
                </InfiniteLoader>
              </Box>
            ) : (
              <EmptyData
                title={t('goalHistory.emptyTitle')}
                data-qa={'goalHistory.emptyTitle'}
                text={t('goalHistory.emptyText')}
              />
            ))}
        </Box>
      </Box>
    </>
  );
};

export default GoalList;
