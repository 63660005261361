export enum NoticeCategory {
  NewsAndSystem = 'NEWS_AND_SYSTEM',
  Mining = 'MINING',
  ForbesAndContests = 'FORBES_AND_CONTESTS',
  FinancialAndMarketplace = 'FINANCIAL_AND_MARKETPLACE',
  NewClansOpeningAndNewMembersActivation = 'NEW_CLANS_OPENING_AND_NEW_MEMBERS_ACTIVATION',
  NewMembersRegistering = 'NEW_MEMBERS_REGISTERING',
  Greetings = 'GREETINGS',
  TrainingsAndMotivation = 'TRAINING_AND_MOTIVATION',
}

export enum NoticeSubcategory {
  Joining = 'JOINING',
  AvatarCreated = 'AVATAR_CREATED',
  FirstClanOpened = 'FIRST_CLAN_OPENED',
  FirstClanMember = 'FIRST_CLAN_MEMBER',
  GenderChanged = 'GENDER_CHANGED',
  News = 'NEWS',
  ClanClosed = 'CLAN_CLOSED',
  ClanOpened = 'CLAN_OPENED',
  YouWereOvertaken = 'YOU_WERE_OVERTAKEN',
  NewClanMember = 'NEW_CLAN_MEMBER',
  TeamMemberFromNewCountry = 'TEAM_MEMBER_FROM_NEW_COUNTRY',
  TeamMemberFromNewCity = 'TEAM_MEMBER_FROM_NEW_CITY',
  WelcomeToDemo = 'WELCOME_TO_DEMO',
  TeamMemberForbesTop5 = 'TEAM_MEMBER_FORBES_TOP_5',
  TeamMemberForbesTop100 = 'TEAM_MEMBER_FORBES_TOP_100',
  TeamMemberTop10ByMaterial = 'TEAM_MEMBER_TOP_10_BY_MATERIAL',
  EmptyClan = 'EMPTY_CLAN',
  EmptyClan2 = 'EMPTY_CLAN_2',
  NextLevel = 'NEXT_LEVEL',
  NextLevel2 = 'NEXT_LEVEL_2',
  NewCitiesCountriesContinents = 'NEW_CITIES_COUNTRIES_CONTINENTS',
  EmptyTeam = 'EMPTY_TEAM',
  Sleep = 'SLEEP',
}

export enum Vote {
  Like = 'LIKE',
  Dislike = 'DISLIKE',
}

export enum NoticeType {
  Personal = 'PERSONAL',
  Broadcast = 'BROADCAST',
}

export type Notice = {
  id: number;
  category: NoticeCategory;
  subcategory: NoticeSubcategory;
  photo_uuid: string;
  title: string;
  short: string; // краткий анонс
  content: string; // полный текст, может быть пустым
  created_at: string;
  likes_count: number;
  my_vote: Vote;
  is_read: boolean;
  read_at: string;
  type: NoticeType;
};

export type NoticeParams = {
  is_read?: boolean;
  offset?: number;
  limit?: number;
};

export type NoticesResponse = {
  count: number;
  results: Notice[];
};
