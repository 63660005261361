import { Button } from '@mui/material';
import { FC } from 'react';

import { Wrapper } from './SwitchButtons.styles';
import { TSwitchButtons, TSwitchButtonsItem } from './SwitchButtons.types';

const SwitchButtons: FC<TSwitchButtons> = ({ value, buttons, isInverted, mainColor, contrastColor, sx, onClick }) => {
  const mColor = mainColor || 'text.primary';
  const cColor = contrastColor || 'background.paper';
  const _mainColor = isInverted ? mColor : cColor;
  const _contrastColor = isInverted ? cColor : mColor;

  const _value = value || buttons[0].id;
  const clickHandler = (item: TSwitchButtonsItem) => {
    if (onClick) {
      onClick(item);
    }
  };

  return (
    <Wrapper className="SwitchButtons" sx={{ bgcolor: _mainColor, ...sx }}>
      {buttons.map((item) => (
        <Button
          className={`SwitchButtons_button SwitchButtons_button__${item.id === _value ? 'active' : 'unactive'}`}
          sx={{
            fontSize: '1.125rem',
            padding: 0,
            height: 'auto',
            width: '48%',
            backgroundColor: item.id === _value ? _contrastColor : _mainColor,
            color: item.id === _value ? _mainColor : _contrastColor,

            '&:hover': {
              opacity: 0.8,
              backgroundColor: item.id === _value ? _contrastColor : _mainColor,
              color: item.id === _value ? _mainColor : _contrastColor,
            },

            '&.MuiButton-textPrimary': {
              borderColor: _mainColor,
            },
          }}
          key={`switchButtons_item_${item.id}`}
          onClick={() => clickHandler(item)}
        >
          {item.title}
        </Button>
      ))}
    </Wrapper>
  );
};

export default SwitchButtons;
