import { FC } from 'react';

import { FabMenuAction } from './FabMenuAction.styles';
import { FabMenuActionProps } from './FabMenuAction.types';

const FabMenu: FC<FabMenuActionProps> = ({ name, icon, selected, value, size, onClick }) => {
  const actionClasses = selected ? 'Mui-selected' : '';
  return (
    <FabMenuAction className={actionClasses} onClick={() => (onClick ? onClick(value) : null)} size={size || 'medium'}>
      {icon || name}
    </FabMenuAction>
  );
};

export default FabMenu;
