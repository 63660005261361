import { FC } from 'react';

import { Triangle } from 'src/atoms';
import { useAppSelector } from 'src/hooks';
import { ClanStatus } from 'src/services';
import { selectClanState } from 'src/store/clanSlice';

import { SelectedTriangleProps } from './SelectedTriangle.types';

const SelectedTriangle: FC<SelectedTriangleProps> = ({ size, level, onClickMember }) => {
  const { activeClan } = useAppSelector(selectClanState);

  if (!activeClan) return null;

  return (
    <Triangle
      size={size}
      level={level}
      isFilled={activeClan.status === ClanStatus.Open}
      data={activeClan}
      onClickMember={onClickMember}
    />
  );
};

export default SelectedTriangle;
