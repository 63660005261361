export const getFormData = <T extends Object>(document: T): FormData => {
  const data = new FormData();
  Object.entries(document).forEach(([name, value]) => {
    data.append(name, value);
  });

  return data;
};

export const isImageType = (docType?: string): boolean => {
  return docType === 'image/png' || docType === 'image/jpeg';
};

export const blobToFile = (theBlob: Blob, fileName: string): File =>
  new File([theBlob], fileName, {
    lastModified: new Date().getTime(),
    type: theBlob.type,
  });

export const blobUrlToFile = (blobUrl: string): Promise<File> =>
  new Promise((resolve) => {
    fetch(blobUrl).then((res) => {
      res.blob().then((blob) => {
        const filename = blobUrl.split('/').pop();
        const fileInfo = filename && filename.match(/\.([^.]+)$|$/);
        if (fileInfo) {
          const nameOfFile = `${fileInfo[0].slice(0, 50)}.${fileInfo[1]}`;
          const file = new File([blob], nameOfFile, { type: blob.type });
          resolve(file);
        }
      });
    });
  });
