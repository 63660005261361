import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IconButton } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerBottom } from 'src/atoms';
import { WindowTitle } from 'src/molecules';

import Help from '../Help';

import { HelpButtonDrawerProps } from './HelpButtonDrawer.types';

const HelpButtonDrawer: FC<HelpButtonDrawerProps> = ({ tkey, children, isOpen, sx }) => {
  const { t } = useTranslation('app');
  const [open, setOpen] = useState(isOpen || false);

  return (
    <>
      <IconButton sx={sx} onClick={() => setOpen(true)}>
        <HelpOutlineIcon />
      </IconButton>
      <DrawerBottom
        sx={{
          height: '100%',
          '& .DrawerBottom_styled': { border: 'none' },
          '& .MuiDrawer-paper': { height: '100%' },
        }}
        isOpen={open}
        onClose={() => setOpen(false)}
        data-qa={tkey}
      >
        <WindowTitle
          title={t(`${tkey}.info.title`)}
          data-qa={`${tkey}.info.title`}
          subtitle={
            t(`${tkey}.info.subtitle`) === `${tkey}.info.subtitle` ? t('info.subtitle') : t(`${tkey}.info.subtitle`)
          }
          onClose={() => setOpen(false)}
        />
        <Help tkey={tkey} sx={{ height: 'calc(100% - 50px)' }} onNext={() => setOpen(false)} />
      </DrawerBottom>
    </>
  );
};

export default HelpButtonDrawer;
