import { Box, IconButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FONTS, ThemeColors } from 'src/styles';

export const Levels = styled(Stack)(() => ({
  paddingLeft: 2,
  marginBottom: 30,
}));

export const Level = styled(IconButton)(() => ({
  position: 'relative',
  width: 56,
  height: 54,
  marginLeft: -1,
  padding: 0,

  '> svg': {
    width: 56,
    height: 54,
    fill: 'currentColor',
  },
}));

export const Number = styled(Box)(() => ({
  position: 'absolute',
  top: 5.5,
  left: 2,
  width: 42,
  height: 42,
  borderRadius: '100%',
  fontFamily: FONTS,
  fontSize: '2rem',
  lineHeight: '42px',
  fontWeight: 400,
  textAlign: 'center',
  color: ThemeColors.gray,
  paddingLeft: 1,
}));
