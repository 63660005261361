import { TFunction } from 'react-i18next';

import { SecretBoxValues, WearableItemsValues } from 'src/store/filtersSlice';
import { ColorOfLevels, ColorOfMiningLevels } from 'src/styles';

import {
  Bras,
  Clock,
  Glasses,
  Hat,
  Outerwear,
  Pants,
  Shirts,
  Shoes,
  Socks,
  Underwear,
  Decorations,
  TattooColor,
  BackgroundsColor,
  Tattoo,
  Backgrounds,
} from './wearable-icons';

export const getSecretBoxes = () => [
  { id: 1, label: 'Noname', color: ColorOfLevels.gray, value: SecretBoxValues.Noname },
  { id: 2, label: 'Rare', color: ColorOfLevels.lightBlue, value: SecretBoxValues.Rare },
  { id: 3, label: 'Super Rare', color: ColorOfLevels.blue, value: SecretBoxValues.SuperRare },
  { id: 4, label: 'Epic', color: ColorOfLevels.purple, value: SecretBoxValues.Epic },
  { id: 5, label: 'Legendary', color: ColorOfLevels.orange, value: SecretBoxValues.Legendary },
];

export const getMaterials = (t: TFunction<'app', undefined>) => [
  { id: 1, title: t('app:material.plastic'), level: 1, color: ColorOfMiningLevels.gray },
  { id: 2, title: t('app:material.ceramics'), level: 2, color: ColorOfMiningLevels.orange },
  { id: 3, title: t('app:material.stone'), level: 3, color: ColorOfMiningLevels.yellow },
  { id: 4, title: t('app:material.wood'), level: 4, color: ColorOfMiningLevels.green },
  { id: 5, title: t('app:material.glass'), level: 5, color: ColorOfMiningLevels.blueLight },
  { id: 6, title: t('app:material.metal'), level: 6, color: ColorOfMiningLevels.blue },
  { id: 7, title: t('app:material.meteorite'), level: 7, color: ColorOfMiningLevels.blueDark },
  { id: 8, title: t('app:material.energy'), level: 8, color: ColorOfMiningLevels.violet },
  { id: 9, title: t('app:material.digital'), level: 9, color: ColorOfMiningLevels.pink },
  { id: 10, title: t('app:material.fire'), level: 10, color: ColorOfMiningLevels.magenta },
];

export const getWearableItems = (t: TFunction<'app', undefined>) => [
  { id: 1, title: '', icon: Hat, value: WearableItemsValues.Head },
  { id: 2, title: '', icon: Underwear, value: WearableItemsValues.UnderwearBottom },
  { id: 3, title: '', icon: Bras, value: WearableItemsValues.UnderwearTop },
  { id: 4, title: '', icon: Shirts, value: WearableItemsValues.Chest },
  { id: 5, title: '', icon: Pants, value: WearableItemsValues.Legs },
  { id: 6, title: '', icon: Socks, value: WearableItemsValues.Feet },
  { id: 7, title: '', icon: Shoes, value: WearableItemsValues.Ankle },
  { id: 8, title: '', icon: Outerwear, value: WearableItemsValues.Outerwear },
  { id: 9, title: '', icon: Glasses, value: WearableItemsValues.Accessory },
  { id: 10, title: '', icon: Clock, value: WearableItemsValues.Wrist },
  { id: 11, title: '', icon: Decorations, value: WearableItemsValues.Jewelry },
  { id: 12, title: 'Tattoo', icon: TattooColor, iconInverted: Tattoo, value: WearableItemsValues.Tattoo },
  {
    id: 13,
    title: t('app:filter.backs'),
    icon: BackgroundsColor,
    iconInverted: Backgrounds,
    value: WearableItemsValues.Entourage,
  },
];
