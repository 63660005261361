import { Button, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Block = styled(Button)(({ theme }) => ({
  position: 'relative',
  padding: 0,
  border: 'none',
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  overflow: 'hidden',
}));

export const Wraper = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: 0,
  right: 0,
  transform: 'translate(0%, -50%)',
  padding: '10px',
  color: theme.palette.primary.main,
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1,
  '&:after': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    content: '""',
    backgroundColor: theme.palette.background.paper,
    opacity: 0.8,
    zIndex: -1,
  },
}));

export const Value = styled(Typography)(() => ({
  fontSize: '1.062rem',
  lineHeight: '18px',
  fontWeight: 600,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  flex: 'none',
})) as typeof Typography;
