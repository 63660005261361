import { IconButton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

import { ThemeGradients } from 'src/styles';

export const CloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  right: 14,
  top: 14,
}));

export const Title = styled(Typography)(() => ({
  fontSize: '1.375rem',
  lineHeight: '28px',
  fontWeight: 500,
  background: ThemeGradients.text,
  backgroundClip: 'text',
  textFillColor: 'transparent',
  margin: '4px auto 28px',
  textAlign: 'center',
  maxWidth: 100,
}));

export const Controls = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: 26,
}));

export const FiltersContainer = styled(Box)(() => ({
  overflowX: 'hidden',
  overflowY: 'auto',
  maxHeight: 420,
  margin: '0 -16px',
  padding: '0 16px',
}));
