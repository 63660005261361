import { AxiosResponse } from 'axios';

import agent from 'src/agent';
import { getAvatarConf } from 'src/services';

import {
  NFTList,
  NFTParams,
  NFTMaterialsParams,
  NFTMaterials,
  NFTMaterialsLevelCount,
  NFTMaterialsResult,
  NFTEyes,
  NFTEyesResult,
  NFTHairs,
  NFTHairsResult,
  NFTBackgroundsResult,
  NFTBackgrounds,
  NFTClothesResult,
  NFTClothes,
  NFTClothesParams,
  TCard,
} from './NFTService.types';

export const getNFTByParams = (params: NFTParams): Promise<AxiosResponse<NFTList>> => {
  return agent.GET('nfts', { params });
};

export const getNFTMaterials = (params: NFTMaterialsParams): Promise<AxiosResponse<NFTMaterials>> => {
  return agent.GET('nfts/materials', { params });
};

export const getNFTMaterialsMock = (params: NFTMaterialsParams): Promise<{ data: NFTMaterials }> => {
  return new Promise((resolve) => {
    const materialCount: NFTMaterialsLevelCount[] = Array.apply(null, Array(10)).map((_val, idx) => {
      return {
        material_level_tier: idx + 1,
        count: Math.floor(Math.random() * 10),
      };
    });

    const levelName = [
      'Кожа',
      'Пластик',
      'Керамика',
      'Камень',
      'Дерево',
      'Стекло',
      'Металл',
      'Метеорит',
      'Огонь',
      'Цифровой',
      'Эфир',
    ];

    const gradeName = [
      [
        'Белая',
        'Белая полутемная',
        'Темнобелая',
        'Черная',
        'Черная полутемная',
        'Темно черная',
        'Красная',
        'Красная полутемная',
        'Темнокрасная',
        'Желтая',
        'Желтая полутемная',
        'Темножелтая',
      ],
      ['Лимон', 'Бирюзовый', 'Фиолетовый', 'Амарант', 'Оранжевый', 'Океанический зеленый'],
      ['Белый перламутр', 'Графит', 'Красный', 'Розовый жемчуг', 'Черный жемчуг', 'Оранжевый блестящий'],
      ['Мрамор белый', 'Мрамор черный', 'Янтарь', 'Турмалин', 'Ларимар', 'Нефрит'],
      ['Бук', 'Белый дуб', 'Амарант', 'Венге', 'Красное', 'Сандал'],
      ['Белое', 'Фиолетовое', 'Зеленое', 'Голубая логуна', 'Тонированное', 'Сапфировое'],
      ['Голубая сталь', 'Черный титан', 'Розовое золото', 'Золотой самородок', 'Радужный Осмий', 'Калифорниум'],
      ['Лава', 'Разноцветный', 'Серебро', 'Желтый', 'Голубой', 'Радужный'],
      ['Древний огонь', 'Холодный огонь', 'Очищающее пламя', 'Инь-Янь', 'Огонь семи чакр', 'Ведический огонь'],
      ['Матрица', 'Пиксель', 'Мета', 'Космос', 'ДАО матрица', 'Галограмма'],
      ['Любовь', 'Вселенная', 'Генезис', 'ДАО', 'Абсолют', 'Сакрал'],
    ];

    const currentLevel = params.material_level_tier;
    const hasCurrentLevel = currentLevel !== undefined && currentLevel >= 0;
    const maxCount = hasCurrentLevel ? gradeName[currentLevel].length : 20;
    const s3Server = 'https://storage.yandexcloud.net/secret-stage/media/nft_img';

    const materialResult: NFTMaterialsResult[] = Array.apply(null, Array(maxCount)).map((_val, idx) => {
      const level = hasCurrentLevel ? currentLevel : Math.floor(Math.random() * 9) + 1;
      const grade = hasCurrentLevel ? idx : Math.floor(Math.random() * 5);

      return {
        count: Math.floor(Math.random() * 10),
        image_uuid: '',
        image: `${s3Server}/${level}/${grade}.png`,
        material_nfts: [],
        material_level_tier: level.toString(),
        material_grade_tier: grade.toString(),
        material_level_name: levelName[level],
        material_grade_name: gradeName[level][grade],
      };
    });

    setTimeout(
      () =>
        resolve({
          data: {
            on_levels_count: materialCount,
            results: materialResult,
            total_count: Math.floor(Math.random() * 100),
          },
        }),
      500
    );
  });
};

export const getNFTEyes = (): Promise<AxiosResponse<NFTEyes>> => {
  return agent.GET('nfts/eyes', {});
};

export const getNFTEyesMock = (): Promise<{ data: NFTEyes }> => {
  return new Promise((resolve) => {
    const maxCount = 10;
    const s3Server = 'https://storage.yandexcloud.net/secret-stage/media/nft_img';

    const eyeResult: NFTEyesResult[] = Array.apply(null, Array(maxCount)).map((_val, idx) => {
      return {
        count: Math.floor(Math.random() * 10),
        image_uuid: '',
        image: `${s3Server}/eyes/${idx}.png`,
        material_level_tier: idx.toString(),
        material_level_name: '',
      };
    });

    setTimeout(
      () =>
        resolve({
          data: {
            results: eyeResult,
            total_count: Math.floor(Math.random() * 100),
          },
        }),
      500
    );
  });
};

export const getNFTHairs = (): Promise<AxiosResponse<NFTHairs>> => {
  return agent.GET('nfts/hairs', {});
};

export const getNFTHairsMock = (): Promise<{ data: NFTHairs }> => {
  return new Promise((resolve) => {
    const maxCount = 3;
    const s3Server = 'https://storage.yandexcloud.net/secret-stage/media/nft_img';

    const eyeResult: NFTHairsResult[] = Array.apply(null, Array(maxCount)).map((_val, idx) => {
      return {
        count: Math.floor(Math.random() * 10),
        image_uuid: '',
        image: `${s3Server}/hairs/${idx}.png`,
        material_level_tier: idx.toString(),
        material_level_name: '',
      };
    });

    setTimeout(
      () =>
        resolve({
          data: {
            results: eyeResult,
            total_count: Math.floor(Math.random() * 100),
          },
        }),
      500
    );
  });
};

export const getNFTBackgrounds = (): Promise<AxiosResponse<NFTBackgrounds>> => {
  return agent.GET('nfts/backgrounds', {});
};

export const getNFTBackgroundsMock = (): Promise<{ data: NFTBackgrounds }> => {
  return new Promise((resolve) => {
    const maxCount = 3;
    const s3Server = 'https://storage.yandexcloud.net/secret-stage/media/nft_img';

    const eyeResult: NFTBackgroundsResult[] = Array.apply(null, Array(maxCount)).map((_val, idx) => {
      return {
        count: Math.floor(Math.random() * 10),
        image_uuid: '',
        image: `${s3Server}/backgrounds/${idx}.png`,
        material_level_tier: idx.toString(),
        material_level_name: '',
      };
    });

    setTimeout(
      () =>
        resolve({
          data: {
            results: eyeResult,
            total_count: Math.floor(Math.random() * 100),
          },
        }),
      500
    );
  });
};

export const getNFTClothes = (params: NFTClothesParams): Promise<AxiosResponse<NFTClothes>> => {
  return agent.GET('nfts/clothes', { params });
};

export const getNFTClothesMock = (params: NFTClothesParams): Promise<{ data: NFTClothes }> => {
  return new Promise((resolve) => {
    const config = getAvatarConf();
    const gender: string = config.gender || '';

    const clothesTypes =
      gender === 'male'
        ? []
        : [
            'Головные уборы',
            'Трусы',
            'Майки, лифчики',
            'Футболки, рубашки',
            'Штаны, юбки',
            'Носки, гольфы, чулки',
            'Обувь',
            'Верхняя одежда',
            'Аксессуары',
            'Часы',
            'Украшения',
            'Татуировки',
            'Окружение',
          ];

    const currentLevel = params.material_level_tier;

    const materialCount: NFTMaterialsLevelCount[] = clothesTypes.map((_val, idx) => {
      return {
        material_level_tier: idx,
        count: Math.floor(Math.random() * 10),
      };
    });

    const s3Server = 'https://storage.yandexcloud.net/secret-stage/media/clothes';

    const gradeAllowed: { [key: number]: string[] } =
      gender === 'male'
        ? {}
        : {
            1: ['Трусы'],
            3: ['Платье', 'Поло', 'Футболка'],
            4: ['Джинсы', 'Юбка'],
            6: ['Сандали', 'Туфли', 'Тапочки', 'Кроссовки'],
            7: ['Худи'],
            8: ['Перчатки'],
            9: ['Apple Watch'],
          };

    const mLevelResult = (levelName: string, level: number): NFTClothesResult[] => {
      const arr = gradeAllowed.hasOwnProperty(level) ? gradeAllowed[level] : [];
      return arr.map((gradeName: string, grade: number) => {
        return {
          count: Math.floor(Math.random() * 10),
          image_uuid: '',
          image: `${s3Server}/${level}/0/${grade}.png`,
          material_level_tier: level.toString(),
          material_type_tier: '0',
          material_grade_tier: grade.toString(),
          material_level_name: levelName,
          material_type_name: '',
          material_grade_name: gradeName,
        };
      });
    };

    const materialResult: NFTClothesResult[] =
      currentLevel !== undefined
        ? mLevelResult(clothesTypes[currentLevel], currentLevel)
        : clothesTypes.reduce((n: NFTClothesResult[], typeName: string, type: number) => {
            const res = mLevelResult(typeName, type);
            return res ? n.concat(res) : n;
          }, []);

    setTimeout(
      () =>
        resolve({
          data: {
            on_levels_count: materialCount,
            results: materialResult,
            total_count: Math.floor(Math.random() * 100),
          },
        }),
      500
    );
  });
};
// TODO: mock example
// export const getMockNFT = (params: NFTParams): Promise<NFTList> => {
//   return new Promise((resolve) => {
//     const { limit = 6, offset = 0 } = params;
//     const results = mockData.results.slice(offset, limit + offset);
//     setTimeout(() => resolve({ count: mockData.count, results }), 500);
//   });
// };

export const readNFT = (compositeId: string): Promise<AxiosResponse<TCard>> => {
  return agent.GET(`nfts/${compositeId}/get`, {});
};
