import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

export const Row = styled(Stack)(() => ({
  marginBottom: 16,
}));

export const SwitchRow = styled(Stack)(() => ({
  marginBottom: 26,
}));

export const EmailVerify = styled(Box)(() => ({
  display: 'flex',
  alignContent: 'center',
}));

export const GeoTitle = styled(Typography)(() => ({
  fontSize: '0.94rem',
  lineHeight: '20px',
  fontWeight: 600,
  color: ThemeColors.gray500,
  marginBottom: 8,
}));

export const GeoData = styled(Typography)(() => ({
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: 600,
  marginBottom: 15,
}));

export const SwitchList = styled('ul')(() => ({
  margin: 0,
  padding: 0,
  listStyle: 'none',
}));

export const SwitchListItem = styled('li')(() => ({
  position: 'relative',
  marginBottom: 26,
}));

export const CensorMessage = styled(Typography)(() => ({
  fontSize: '0.812rem',
  lineHeight: '18px',
  fontWeight: 600,
  color: ThemeColors.redLight,
  marginBottom: 16,
}));

export const TitleVerified = styled(Typography)(() => ({
  fontSize: '1rem',
  lineHeight: '21px',
  fontWeight: 400,
  textAlign: 'center',
  marginBottom: 16,
}));
