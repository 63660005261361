import { FC, useState, useCallback, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerChooser, CarouselMaterialTitle } from 'src/atoms';
import { ErrorContext } from 'src/context/ErrorContext';
import { MaterialsCarousel } from 'src/molecules';
import { getNFTHairsMock as getNFTHairs } from 'src/services';
import { NFTHairsResult } from 'src/services/NFTService/NFTService.types';

import { DrawerAvatarHairsChooserProps, HairItemProps } from './DrawerAvatarHairsChooser.types';

const dataToMaterialItem = (data: NFTHairsResult[]): HairItemProps[] => {
  return data.map((material, idx) => {
    return {
      id: `${material.material_level_tier}_${idx}`,
      img: material.image,
      level: parseInt(material.material_level_tier, 10),
      levelName: material.material_level_name,
    };
  });
};

const DrawerAvatarSkinChooser: FC<DrawerAvatarHairsChooserProps> = ({ isOpen, onClose, onApply }) => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);

  const [items, setItems] = useState<HairItemProps[]>();
  const [selected, setSelected] = useState<string | undefined>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const handleChange = (itemId: string | undefined) => {
    setSelected(itemId);
    setIsChanged(true);
  };

  const readData = useCallback(() => {
    getNFTHairs()
      .then(({ data }) => {
        const resultItems = dataToMaterialItem(data.results);
        setItems(resultItems);
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
      });
  }, [throwServerError]);

  useEffect(() => {
    if (isOpen) readData();
  }, [readData, isOpen]);

  const selectedItem = items?.find((item) => item.id === selected);

  const handlerApply = () => onApply && onApply(selectedItem?.level || 0);

  return (
    <DrawerChooser
      isOpen={isOpen}
      onClose={onClose}
      isChanged={isChanged}
      onApply={handlerApply}
      titleComponent={<CarouselMaterialTitle title={t('avatar.menu.hair')} />}
    >
      <MaterialsCarousel items={items} selected={selected} onSelect={(id) => handleChange(id)} />
    </DrawerChooser>
  );
};

export default DrawerAvatarSkinChooser;
