import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { isMarket } from 'src/constants';
import { getMarketBasketCount } from 'src/services';
import { RootState } from 'src/store';
import { parseError } from 'src/utils';

export interface MarketState {
  isPendingBasket: boolean;
  count: number;
  basketError: string | null;
}

const initialState: MarketState = {
  isPendingBasket: false,
  count: 0,
  basketError: null,
};

export const getBasketCount = createAsyncThunk<number, undefined, { rejectValue: string }>(
  'market/getBasket',
  async (_params, { rejectWithValue }) => {
    try {
      if (isMarket) {
        const data = await getMarketBasketCount();
        return data;
      }

      return 0;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    resetMarket: (state) => {
      state.count = 0;
      state.isPendingBasket = false;
      state.basketError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBasketCount.pending, (state) => {
        state.isPendingBasket = true;
      })
      .addCase(getBasketCount.fulfilled, (state, action) => {
        state.count = action.payload;
        state.isPendingBasket = false;
      })
      .addCase(getBasketCount.rejected, (state, action) => {
        state.count = 0;
        state.isPendingBasket = false;

        if (action.payload) {
          state.basketError = action.payload;
        }
      });
  },
});

export const { resetMarket } = marketSlice.actions;

export const selectMarketState = (state: RootState) => state.market;

export const marketReducer = marketSlice.reducer;
