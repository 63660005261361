import { Button, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

export const Block = styled(Box)(() => ({
  position: 'relative',
  backgroundColor: 'currentColor',
  borderRadius: '50%',
  zIndex: 1,
}));

export const CircleButton = styled(Button)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  margin: 15,
  border: '1px solid',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  borderColor: 'currentColor',
  '&:hover': {
    opacity: 1,
  },

  svg: {
    fill: 'currentColor',
  },
}));

export const CircleProgress = styled(CircularProgress)(({ size, color }) => ({
  position: 'relative',
  color: color,
  width: size,
  height: size,
  zIndex: -1,
}));
