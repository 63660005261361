import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

export const QRCodeBox = styled(Box)(() => ({
  position: 'absolute',
  width: 200,
  height: 200,
  top: -100,
  left: '50%',
  padding: 20,
  backgroundColor: ThemeColors.white,
  borderRadius: 40,
  transform: 'translateX(-50%)',
  boxShadow: '0px 3px 10px -1px rgba(0,0,0,0.75)',
}));

export const YourReferralInfoGradient = styled('div')(() => ({
  position: 'relative',
  // background: ThemeGradients.bg,
  borderRadius: 20,
  marginTop: 60,
  marginBottom: 20,
}));

export const YourReferralInfo = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: '10px 20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  border: '1px solid transparent',
  backgroundColor: theme.palette.background.default,
  borderRadius: 20,
  backgroundClip: 'padding-box',
}));

export const ReferralLink = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
}));

export const ReferralTitle = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: '0.75rem',
  fontWeight: 400,
  color: theme.palette.secondary.main,
}));

export const ReferralText = styled(Typography)(() => ({
  fontSize: '0.94rem',
  lineHeight: '20px',
  fontWeight: 600,
  textAlign: 'center',
}));
