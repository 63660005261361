import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

export const Wrapper = styled(Stack)(() => ({
  margin: '0 -16px',
  padding: '16px 0 16px 16px',
  borderTop: `1px solid ${ThemeColors.grayLight}`,
  flexDirection: 'row',
}));

export const Image = styled('img')(() => ({
  width: 72,
  height: 72,
  objectFit: 'cover',
  borderRadius: 12,
  marginRight: 8,
}));

export const Info = styled(Stack)(() => ({
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
}));

export const Name = styled(Typography)(() => ({
  fontSize: '0.94rem',
  lineHeight: '20px',
  fontWeight: 600,
  marginBottom: 2,
}));

export const Price = styled(Typography)(() => ({
  fontSize: '0.94rem',
  lineHeight: '20px',
  fontWeight: 600,

  svg: {
    fill: ThemeColors.yellow,
    marginRight: 4,
    verticalAlign: -3,
  },

  span: {
    fontSize: '1rem',
    lineHeight: '21px',
    fontWeight: 400,
    color: ThemeColors.gray500,
    marginLeft: 4,
  },
}));

export const Param = styled(Typography)(() => ({
  fontSize: '0.688rem',
  lineHeight: '13px',
  fontWeight: 400,

  span: {
    color: ThemeColors.gray500,
    width: '45%',
    display: 'inline-block',
  },
}));
