import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { FC, ReactNode } from 'react';

import { getLocale } from 'src/utils';

type TonWalletProviderProps = {
  children: ReactNode;
};

const TonWalletProvider: FC<TonWalletProviderProps> = ({ children }) => {
  const locale = getLocale();
  return (
    <TonConnectUIProvider
      manifestUrl="./tonconnect-manifest.json"
      restoreConnection={true}
      language={locale === '1' ? 'ru' : 'en'}
      walletsListConfiguration={{
        includeWallets: [
          {
            appName: 'BuyBrandNow',
            name: 'BuyBrandNowWallet',
            imageUrl: 'https://localhost:3000/logo.png',
            aboutUrl: 'https://localhost:3000/about',
            universalLink: 'https://localhost:3000',
            bridgeUrl: 'https://bridge.tonapi.io/bridge',
            platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
          },
        ],
      }}
    >
      {children}
    </TonConnectUIProvider>
  );
};

export default TonWalletProvider;
