import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors, ThemeGradients } from 'src/styles';

export const Oops = styled(Typography)(() => ({
  fontSize: '1.375rem',
  fontWeight: 500,
  lineHeight: '28px',
  marginBottom: 16,
  background: ThemeGradients.text,
  backgroundClip: 'text',
  textFillColor: 'transparent',
}));

export const Message = styled(Typography)(() => ({
  fontSize: '0.812rem',
  fontWeight: 600,
  lineHeight: '18px',
  color: ThemeColors.gray500,
  marginBottom: 40,
}));
