import { FC } from 'react';

import { TeamAncestorItem } from 'src/atoms';
import { getImgSrc } from 'src/constants';

import { Container } from './TeamAncestors.styles';
import { TeamAncestorsProps } from './TeamAncestors.types';

const TeamAncestors: FC<TeamAncestorsProps> = ({ currentId, ancestors, onNodeClick }) => {
  return (
    <Container>
      {ancestors.map((ancestor, idx) => (
        <TeamAncestorItem
          key={ancestor.id}
          avatar={getImgSrc(ancestor.photo_uuid, 's44-c') || ''}
          name={ancestor.display_name}
          role={ancestor.role}
          isSelf={currentId === ancestor.id || idx === 0}
          onClick={() => onNodeClick(ancestor.id)}
        />
      ))}
    </Container>
  );
};

export default TeamAncestors;
