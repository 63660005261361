import _ from 'lodash';

export type RouterIdParam = {
  id: string;
};

export type RouterCompositeIdParam = {
  compositeId: string;
};

export type RouterPageIdParam = {
  pageId: string;
};

export type RouterReferralUuidParam = {
  referral: string;
};

export type RouterStateStructure = {
  level: number;
};

export type RouterStateProfile = {
  compositeId: string;
};

export type RouterItemIdCart = {
  itemId: string;
};

export const isReferralUuid = (state: any): state is RouterReferralUuidParam => 'referral' in state;

export enum Levels {
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
  Fifth = 5,
}

export enum walletProviders {
  MetaMask = 'ethereum',
  TrustWallet = 'trustwallet',
  Ton = 'ton',
}

export enum Sizes {
  Small,
  Medium,
  Large,
  Widget,
}

// переменные для определения окружения пользователя
export const isBrowser = typeof window !== 'undefined';
export const isNode = !isBrowser;
export const userAgent = isBrowser ? _.get(window, 'navigator.userAgent') : '';
export const hasEthereum = isBrowser && _.has(window, 'ethereum');
export const hasTrustwallet = isBrowser && (_.has(window, 'trustwallet') || _.has(window, 'trustWallet'));
export const isAndroid = /(Android)/i.test(userAgent);
export const isIphone = /(iPhone|iPod)/i.test(userAgent);
export const isIpad = /(iPad)/i.test(userAgent);
export const isMobile = isIphone || isAndroid;

export const isMetamask = isMobile && hasEthereum;
export const isTrustwallet = isMobile && hasTrustwallet;
export const isTelegramWebApp = isBrowser && _.has(window, 'TelegramWebviewProxy');
