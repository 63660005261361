import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

export const Wrapper = styled(Stack)(() => ({
  borderRadius: 12,
  backgroundColor: ThemeColors.black,
  marginTop: 16,
  padding: '0 16px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 56,
}));

export const Title = styled(Typography)(() => ({
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: 600,
  textTransform: 'uppercase',
  color: ThemeColors.blueLight,
}));

export const Count = styled(Typography)(() => ({
  fontSize: '1.375rem',
  lineHeight: '28px',
  fontWeight: 500,
  color: ThemeColors.white,
}));
