import { Box, IconButton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Block = styled(Stack)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 16,
}));

export const Icon = styled('img')(({ theme }) => ({
  width: 40,
  height: 40,
  //border: `1px solid ${theme.palette.divider}`,
  borderRadius: 10,
  marginRight: 10,
  flex: 'none',
}));

export const NotificationHeader = styled(Stack)(() => ({
  width: '100%',
  flexDirection: 'column',
}));

export const TitleStack = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const Title = styled(Typography)(() => ({
  fontSize: '0.812rem',
  lineHeight: '18px',
  fontWeight: 600,
}));

export const Date = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '16px',
  fontWeight: 400,
  color: theme.palette.secondary.main,
}));

export const ShortText = styled(Typography)(() => ({
  fontSize: '0.688rem',
  lineHeight: '13px',
  fontWeight: 400,
}));

export const StatusIcon = styled(Box)(() => ({
  flex: 'none',
  alignSelf: 'center',
}));

export const LikeButton = styled(IconButton)(() => ({
  padding: 4,

  svg: {
    width: 20,
    height: 20,
  },
}));
