import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors, ThemeFontWeight } from 'src/styles';

export const TelegramButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.paper,
  textTransform: 'uppercase',
  fontWeight: ThemeFontWeight.semiBold,
  letterSpacing: '-0.41px',
  fontSize: '0.94rem',
  cursor: 'pointer',
  border: `1px solid ${ThemeColors.blueLight}`,
  margin: 0,
  '&:hover': {
    background: theme.palette.background.paper,
    cursor: 'pointer',
    border: `1px solid ${ThemeColors.blueLight}`,
  },
  '&:before': {
    position: 'absolute',
    top: -10,
    left: 11,
    width: '80px',
    height: '80px',
    background: theme.palette.background.paper,
    border: `1px solid ${ThemeColors.blueLight}`,
    content: '""',
    zIndex: -1,
    borderRadius: '50%',
  },
  '&:after': {
    position: 'absolute',
    top: -9,
    left: 12,
    width: '78px',
    height: '78px',
    background: theme.palette.background.paper,
    content: '""',
    borderRadius: '50%',
    zIndex: 0,
  },

  '& .icon': {
    position: 'absolute',
    top: -5,
    left: 16,
    width: '70px',
    height: '70px',
    border: `3px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    zIndex: 1,
  },

  '&.Mui-disabled': {
    '&:before': {
      background: theme.palette.background.default,
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    '&:after': {
      background: theme.palette.background.default,
    },
    '& .icon': {
      animation: 'loaderIcon 2s infinite',
    },
  },
  '@keyframes loaderIcon': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '50%': {
      transform: 'rotate(45deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
}));
