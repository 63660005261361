import { Avatar, Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

export const InfoWrapper = styled(Box)(({ theme }) => ({
  marginBottom: 16,
  paddingBottom: 16,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  lineHeight: '24px',
  //background: ThemeGradients.bg2,
  // backgroundClip: 'text',
  color: theme.palette.primary.main,
  fontWeight: 500,
  display: 'table',
  // textFillColor: 'transparent',
  margin: '0 0 20px',
  letterSpacing: '0.35px',
}));

export const UserInfo = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 8,
}));

export const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: 32,
  height: 32,
  border: `1px solid ${theme.palette.primary.main}`,
  marginRight: 8,
}));

export const UserName = styled(Typography)(() => ({
  fontSize: '0.94rem',
  fontWeight: 600,
}));

export const UserWallet = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '16px',
  color: theme.palette.secondary.main,
}));

export const PointsLine = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 8,
}));

export const Points = styled('div')(({ theme }) => ({
  flexBasis: '100%',
  height: 12,
  borderBottom: `2px dotted ${theme.palette.divider}`,
}));

export const PointsLineName = styled(Typography)(() => ({
  fontSize: '0.75rem',
  lineHeight: '16px',
  whiteSpace: 'nowrap',
  paddingRight: 8,
}));

export const PointsLinePrice = styled(Typography)(({ theme }) => ({
  fontSize: '0.812rem',
  lineHeight: '18px',
  fontWeight: 600,
  paddingLeft: 8,

  span: {
    fontSize: '0.75rem',
    lineHeight: '16px',
    fontWeight: 400,
    color: theme.palette.secondary.main,
    marginLeft: 4,
  },
}));

export const Total = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const TotalTitle = styled(Typography)(() => ({
  fontSize: '1.25rem',
  lineHeight: '24px',
  //background: ThemeGradients.bg2,
  //backgroundClip: 'text',
  fontWeight: 500,
  display: 'table',
  // textFillColor: 'transparent',
  letterSpacing: '0.35px',
}));

export const TotalPrice = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  lineHeight: '24px',
  fontWeight: 500,

  span: {
    marginLeft: 8,
    color: theme.palette.secondary.main,
  },
}));

export const ItemType = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'flex-start',
  marginBottom: 8,
}));

export const TypeNumber = styled(Typography)(() => ({
  minWidth: 20,
  fontSize: '0.94rem',
  lineHeight: '20px',
  fontWeight: 600,
  marginRight: 4,
}));

export const TypeTitle = styled(Typography)(() => ({
  fontSize: '0.94rem',
  lineHeight: '20px',
  fontWeight: 600,
}));

export const TxStack = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'flex-start',
  marginBottom: 16,
}));

export const TxTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '16px',
  fontWeight: 400,
  color: theme.palette.secondary.main,
  marginRight: 4,
}));

export const TxLink = styled('a')(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '0.75rem',
  lineHeight: '16px',
  color: ThemeColors.blue,
}));

export const Product = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginBottom: 16,
}));

export const ProductImage = styled('img')(() => ({
  width: 32,
  height: 32,
  objectFit: 'cover',
  borderRadius: 8,
  overflow: 'hidden',
  marginRight: 8,
}));

export const ProductInfo = styled(Stack)(() => ({
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const ProductTitle = styled(Typography)(() => ({
  fontSize: '0.812rem',
  lineHeight: '18px',
  fontWeight: 600,
}));

export const ProductSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '16px',
  color: theme.palette.secondary.main,
}));
