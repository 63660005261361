import { WSS_URL } from 'src/constants';
import { Notice } from 'src/services';
import { getToken, parseError, logger } from 'src/utils';

const { access: accessToken } = getToken();
const wsSocket = new WebSocket(`${WSS_URL}notices/?token=${accessToken}`);

export enum SecretSocketMessageType {
  NoticeNew = 'notice_new',
}

interface ISecretSocketMessage {
  type: SecretSocketMessageType;
  body: Notice;
}

const subscribers: Record<SecretSocketMessageType, Set<(payload: Notice) => void>> = {
  [SecretSocketMessageType.NoticeNew]: new Set(),
};

wsSocket.onmessage = (event: MessageEvent) => {
  try {
    const json: ISecretSocketMessage = JSON.parse(event.data);
    // TODO: implement type guard isSecretSocketMessageType later
    if (!json.type) throw new Error('No "type" field in ws message'); // 'body' can be empty, but message 'type' is required

    subscribers[json.type].forEach((subscriber) => {
      subscriber(json.body);
    });
  } catch (error) {
    logger.error(parseError(error));
  }
};

const methods = {
  subscribe(type: SecretSocketMessageType, callback: (payload: Notice) => void) {
    subscribers[type].add(callback);

    return () => {
      subscribers[type].delete(callback);
    };
  },
};

export default methods;
