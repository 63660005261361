import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';

import { ISelectFieldProps } from './SelectField.types';

const SelectField = <T extends FieldValues>(props: ISelectFieldProps<T>) => {
  const { name, options, control, defaultValue, placeholder, onChange: _onChange, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
          <>
            <Select
              value={value || ''}
              onChange={(ev, child) => {
                onChange(ev, child);
                if (_onChange) _onChange(ev.target.value);
              }}
              displayEmpty
              error={!!error}
              {...rest}
            >
              {placeholder && <MenuItem value="">{placeholder}</MenuItem>}
              {options.map((option) => (
                <MenuItem value={option.id} key={option.id}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
            {!!error && <FormHelperText error>{error.message}</FormHelperText>}
          </>
        </FormControl>
      )}
    />
  );
};

export default SelectField;
