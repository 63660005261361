import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Box)(({ theme }) => ({
  minHeight: '52px',
  top: 0,
  left: 0,
  right: 0,
  position: 'fixed',
  zIndex: 10,
  backgroundColor: theme.palette.background.default,
}));

export const Header = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: '600px',
  minWidth: '300px',
  width: '100%',
  margin: '0 auto',
  padding: '45px 8px 27px',
  height: 100,
}));

export const Title = styled(Typography)(() => ({
  textTransform: 'uppercase',
  margin: 0,
}));

export const Empty = styled('div')(() => ({
  width: 40,
}));
