import { FC, useState, useCallback, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerChooser, CarouselTab, LevelCircle, CarouselMaterialTitle } from 'src/atoms';
import { ErrorContext } from 'src/context/ErrorContext';
import { getMaterials } from 'src/molecules/filters/OperationType/OperationType.utils';
import { MaterialsCarouselTabs } from 'src/organisms';
import { getNFTMaterialsMock as getNFTMaterials } from 'src/services';
import { NFTMaterialsResult, NFTMaterialsLevelCount } from 'src/services/NFTService/NFTService.types';

import { DrawerAvatarMaterialChooserProps, MaterialItemProps } from './DrawerAvatarMaterialChooser.types';

const dataToMaterialItem = (data: NFTMaterialsResult[]): MaterialItemProps[] => {
  return data.map((material, idx) => {
    return {
      id: `${material.material_level_tier}_${material.material_grade_tier}_${idx}`,
      img: material.image,
      title: material.material_grade_name,
      level: parseInt(material.material_level_tier, 10),
      levelName: material.material_level_name,
      grade: parseInt(material.material_grade_tier, 10),
      gradeName: material.material_grade_name,
    };
  });
};

const DrawerAvatarMaterialChooser: FC<DrawerAvatarMaterialChooserProps> = ({
  isOpen,
  isHasSelectParth,
  onClose,
  onApply,
}) => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);

  const tabsItems = getMaterials(t);

  const [items, setItems] = useState<MaterialItemProps[]>();
  const [selected, setSelected] = useState<string | undefined>();
  const [selectedTab, setSelectedTab] = useState<number | undefined>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [levelCount, setLevelCount] = useState<NFTMaterialsLevelCount[]>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const handleChange = (tab: number, itemId: string | undefined) => {
    setSelectedTab(tab);
    setSelected(itemId);
    setIsChanged(true);
  };

  const readData = useCallback(() => {
    getNFTMaterials({ material_level_tier: selectedTab || undefined })
      .then(({ data }) => {
        const resultItems = dataToMaterialItem(data.results);
        setItems(resultItems);
        setLevelCount(data?.on_levels_count);
        setTotalCount(data?.total_count);
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
      });
  }, [throwServerError, selectedTab]);

  useEffect(() => {
    if (isOpen) readData();
  }, [readData, isOpen]);

  const selectedItem = items?.find((item) => item.id === selected);

  const handlerApply = () => onApply && onApply(selectedTab, selectedItem?.level, selectedItem?.grade);

  return (
    <DrawerChooser
      isOpen={isOpen}
      onClose={onClose}
      isChanged={isChanged && isHasSelectParth}
      onApply={handlerApply}
      titleComponent={<CarouselMaterialTitle item={selectedItem} title={t('avatar.menu.bagpack')} withLevel={true} />}
    >
      <MaterialsCarouselTabs items={items} selected={selected} onChange={handleChange}>
        <CarouselTab
          key="leveltab-0"
          label="Все"
          iconPosition="start"
          variant="icononly"
          icon={<LevelCircle color="#cfcfcf" selected={selectedTab === undefined} count={totalCount}></LevelCircle>}
        />
        {levelCount &&
          tabsItems.map((item, index) => {
            return (
              <CarouselTab
                key={`leveltab-${item.level}`}
                label={item.title}
                iconPosition="start"
                variant="icononly"
                icon={
                  <LevelCircle
                    color={item.color}
                    selected={selectedTab === index + 1}
                    count={levelCount.find((i) => i.material_level_tier === item.level)?.count}
                  >
                    {item.level}
                  </LevelCircle>
                }
              />
            );
          })}
      </MaterialsCarouselTabs>
    </DrawerChooser>
  );
};

export default DrawerAvatarMaterialChooser;
