import { Avatar } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Paths, pathsArg, getImgSrc } from 'src/constants';

import { AvatarLinkProps } from './AvatarLink.types';

const AvatarLink: FC<AvatarLinkProps> = ({ user_id, display_name, photo_uuid, size, role, sx, onClick }) => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const _size = size || 32;

  return (
    <Avatar
      title={display_name}
      src={getImgSrc(photo_uuid, `s${_size}-c`)}
      sx={{
        width: `${_size}px`,
        height: `${_size}px`,
        aspectRatio: '1 / 1',
        cursor: 'pointer',
        border: '2px solid',
        borderColor: role ? (t(`role.state.color.${role}`) as string) : 'secondary.main',
        ...sx,
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (!user_id) throw new Error('User without user_id field');

        if (onClick) {
          onClick(user_id);
        } else {
          navigate(pathsArg(Paths.profileUser, { id: user_id }));
        }
      }}
    />
  );
};

export default AvatarLink;
