import { cellToLatLng } from 'h3-js';
import { FC } from 'react';
import { Marker, MarkerProps } from 'react-map-gl';

import { PinMarker } from 'src/atoms';

import { MapboxPinMarkerProps } from './MapboxPinMarker.types';

const defaultOptions = {
  anchor: 'bottom',
  // rotationAlignment: 'horizon',
} as MarkerProps;

const MapboxPinMarker: FC<MapboxPinMarkerProps> = ({ data, onClick }) => {
  const markerList = data.map((item) => {
    let center: [number, number];
    if (typeof item.coord === 'string') {
      center = cellToLatLng(item.coord);
    } else {
      center = item.coord;
    }

    return (
      <Marker
        key={`marker_${item.id}`}
        {...defaultOptions}
        longitude={center[1]}
        latitude={center[0]}
        onClick={() => onClick && onClick(item)}
      >
        <PinMarker {...item} />
      </Marker>
    );
  });

  return <>{markerList}</>;
};

export default MapboxPinMarker;
