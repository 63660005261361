import { AvatarInfo } from 'src/services/AvatarService/AvatarService.types';

export enum viewMode {
  edit = 'edit',
  create = 'create',
}

export type AvatarViewProps = {
  data: AvatarInfo;
  onChange?: (mode: viewMode, meta?: unknown) => void;
};
