import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { copyToClipboard } from 'src/utils';

import { BPriceProps } from './Address.types';

const Address: FC<BPriceProps> = ({ address, title, color, onClick, onUnlink, sx }) => {
  const { t } = useTranslation('app');
  const { enqueueSnackbar } = useSnackbar();

  const onCopyHandler = () => {
    copyToClipboard(address);
    enqueueSnackbar(t('button.copied'), { variant: 'info' });
  };

  return (
    <Box
      onClick={() => onClick && onClick()}
      sx={{
        background: (theme) => theme.palette.background.paper,
        borderRadius: '20px',
        cursor: onClick ? 'pointer' : 'default',
        ...sx,
      }}
    >
      {(title || onUnlink) && (
        <Stack
          sx={{
            padding: '7px',
            paddingBottom: 0,
            overflow: 'hidden',
            position: 'relative',
          }}
          alignItems="center"
          justifyContent="space-between"
          direction="row"
        >
          {title && (
            <Typography noWrap overflow="hidden" textOverflow="ellipsis" sx={{ paddingLeft: '8px' }}>
              {title}
            </Typography>
          )}
          {onUnlink && (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                onUnlink();
              }}
              sx={{ flex: 0, marginLeft: '30px' }}
            >
              <LinkOffIcon sx={{ width: 15, height: 18 }} />
            </IconButton>
          )}
        </Stack>
      )}
      <Stack
        sx={{
          padding: '7px',
          overflow: 'hidden',
          position: 'relative',
        }}
        alignItems="center"
        justifyContent="space-between"
        direction="row"
      >
        <Typography
          noWrap
          overflow="hidden"
          textOverflow="ellipsis"
          sx={{ paddingLeft: '8px', color: color || 'currentcolor' }}
        >
          {address}
        </Typography>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            onCopyHandler();
          }}
          sx={{ flex: 0, marginLeft: '30px' }}
        >
          <ContentCopyIcon sx={{ width: 15, height: 18 }} />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default Address;
