// import SettingsOverscanIcon from '@mui/icons-material/SettingsOverscan';
import { Box } from '@mui/material';
import * as d3 from 'd3';
import { FC, useRef, useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { NoPhoto } from 'src/assets/icons';
import { UserLink } from 'src/atoms';
import { getImgSrc } from 'src/constants';
import { useAppSelector, useWindowSize } from 'src/hooks';
import { TeamChildrenInList } from 'src/services';
import { selectUserMe } from 'src/store/userSlice';

import { BeeSwarmProps } from './BeeSwarm.types';

const dodge = (data: any, { radius, x }: { radius: number; x: any }) => {
  const radius2 = radius ** 2;
  const circles = data.map((d: number) => ({ x: x(d), data: d })).sort((a: any, b: any) => a.x - b.x);
  const epsilon = 1e-3;
  let head: any = null,
    tail: any = null;

  function intersects(x: number, y: number) {
    let a = head;
    while (a) {
      if (radius2 - epsilon > (a.x - x) ** 2 + (a.y - y) ** 2) {
        return true;
      }
      a = a.next;
    }
    return false;
  }

  for (const b of circles) {
    while (head && head.x < b.x - radius2) head = head.next;

    // пытаемся найти куда присоседить
    if (intersects(b.x, (b.y = 0))) {
      let a = head;
      b.y = Infinity;
      do {
        let y1 = a.y + Math.sqrt(radius2 - (a.x - b.x) ** 2);
        let y2 = a.y - Math.sqrt(radius2 - (a.x - b.x) ** 2);
        if (Math.abs(y1) < Math.abs(b.y) && !intersects(b.x, y1)) b.y = y1;
        if (Math.abs(y2) < Math.abs(b.y) && !intersects(b.x, y2)) b.y = y2;
        a = a.next;
      } while (a);
    }

    b.next = null;
    if (head === null) head = tail = b;
    else tail = tail.next = b;
  }

  return circles;
};

const circleSize = 12;
const margin = {
  top: circleSize,
  right: circleSize,
  bottom: circleSize,
  left: 30,
};

type DodgeData = { x: number; y: number; data: TeamChildrenInList };

const scaleParse = (d: TeamChildrenInList, measure: keyof TeamChildrenInList) =>
  Math.abs(parseInt(d[measure] + '', 10) || 0);

const BeeSwarm: FC<BeeSwarmProps> = ({ data, measure, sx }) => {
  const { t } = useTranslation('app');

  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const containerRef = useRef<HTMLDivElement>(null);

  const userMe = useAppSelector(selectUserMe);

  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const [selectedNode, setSelectedNode] = useState<TeamChildrenInList>();

  const [currentGlobalZoomState, setCurrentGlobalZoomState] = useState(d3.zoomIdentity);
  const [currentYZoomState, setCurrentYZoomState] = useState(d3.zoomIdentity);

  // для проверки размеров ---
  useEffect(() => {
    if (!containerRef.current) return;

    setWidth(containerRef.current.offsetWidth);
    setHeight(containerRef.current.offsetHeight);
  }, [containerRef, windowWidth, windowHeight]);

  // --- для проверки размеров

  const gy = useRef<SVGSVGElement>(null);
  const chart = useRef<SVGSVGElement>(null);
  const svg = useRef<SVGSVGElement>(null);

  const scaleDomain = d3.extent(data, (d: TeamChildrenInList) => {
    // в логорифмической шкале нельзя пересекать 0 и все значения должны быть строго больше 0 или меньше
    return scaleParse(d, measure);
  }) as [number, number];

  const scaleRange = [margin.top, height - margin.bottom];

  // const isLogScale = false; // Math.abs(scaleDomain[0] - scaleDomain[1]) > 10;

  //const yScale = isLogScale ? d3.scaleLog(scaleDomain, scaleRange) : d3.scaleLinear(scaleDomain, scaleRange);
  const yScale = d3.scaleLinear(scaleDomain, scaleRange);

  if (currentYZoomState) {
    const newYScale = currentYZoomState.rescaleY(yScale);
    yScale.domain(newYScale.domain());
  }

  useEffect(() => {
    if (!svg.current) return;

    const zoom = d3.zoom();
    zoom
      .scaleExtent([1, 32])
      .extent([
        [margin.top, 0],
        [height - margin.top, height],
      ])
      .translateExtent([
        [margin.top, 0],
        [height - margin.top, height],
      ])
      .on('zoom', (event) => {
        const { k: newK, y: newY } = event.transform;
        const { k: prevK, y: prevY } = currentGlobalZoomState;

        setCurrentYZoomState(currentYZoomState.translate(0, (newY - prevY) / prevK).scale(newK / prevK));

        setCurrentGlobalZoomState(event.transform);
      });
    d3.select(svg.current as any).call(zoom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, svg, width]);

  useEffect(
    () =>
      void d3.select(gy.current as any).call(
        d3
          .axisLeft(yScale)
          .ticks(10, `.${d3.precisionFixed(1)}f`)
          .tickSizeOuter(10)
          .tickSizeInner(4)
      ),
    [gy, yScale]
  );
  useEffect(() => void d3.select(gy.current as any), []);

  /*
  useEffect(() => {
    setChartData(
      dodge(data, {
        radius: circleSize * 2 - 2,
        x: (d: any) => yScale(d[measure]),
      })
    );
  }, [data, measure]);
  */

  const chartData: DodgeData[] = dodge(data, {
    radius: circleSize * 2 - 2,
    x: (d: any) => yScale(scaleParse(d, measure)),
  });

  const handlerClick = (item: DodgeData) => {
    setSelectedNode(item.data);
  };

  return (
    <Box
      className="BeeSwarm"
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        ...sx,
      }}
    >
      <Box
        ref={containerRef}
        sx={{
          width: '100%',
          height: '100%',
          svg: {
            backgroundColor: 'background.paper',
            '& .BeeSwarm_item__point': {
              cursor: 'pointer',
            },
            '& #BeeSwarm_item__hoverLine, circle.BeeSwarm_item__point_active': {
              strokeDashoffset: 0,
              strokeDasharray: '3 3',
              animation: 'dash 10s linear forwards infinite',
            },

            '@keyframes dash': {
              to: {
                strokeDashoffset: 90,
              },
            },
          },
        }}
      >
        <svg ref={svg} width={width} height={height}>
          <g ref={chart} transform={`translate(${margin.left},0)`}>
            <g stroke="currentColor" strokeWidth="2">
              {chartData.map((d, i) => (
                <Fragment key={`item_circle_${i}`}>
                  <circle
                    className={`BeeSwarm_item__point ${
                      d.data.id === selectedNode?.id ? 'BeeSwarm_item__point_active' : ''
                    }`}
                    cx={width / 2 - circleSize - d.y}
                    cy={d.x}
                    r={circleSize - 2}
                    stroke={d.data.role ? (t(`role.state.color.${d.data.role}`) as string) : 'currentColor'}
                    fill={`url(#filter_image_${d.data.user_id})`}
                    onClick={() => handlerClick(d)}
                  />
                  <defs>
                    <pattern
                      id={`filter_image_${d.data.user_id}`}
                      x={0}
                      y={0}
                      viewBox={`0 0 ${circleSize * 2} ${circleSize * 2}`}
                      width="100%"
                      height="100%"
                    >
                      <image
                        //x={width / 2 - circleSize * 2 - d.y}
                        //y={d.x - circleSize}
                        x={0}
                        y={0}
                        width={circleSize * 2}
                        height={circleSize * 2}
                        xlinkHref={
                          d.data.photo_uuid ? getImgSrc(d.data.photo_uuid, `s${circleSize * 2}-c`) || '' : NoPhoto
                        }
                      />
                    </pattern>
                  </defs>
                  {selectedNode && selectedNode.id === d.data.id && (
                    <line
                      id="BeeSwarm_item__hoverLine"
                      x1={margin.left}
                      x2={width / 2 - circleSize * 2 - d.y}
                      y1={d.x}
                      y2={d.x}
                      strokeWidth="1"
                    />
                  )}
                </Fragment>
              ))}
            </g>
            <g ref={gy} transform={`translate(${margin.left},0)`} />
          </g>
        </svg>
      </Box>

      {selectedNode && selectedNode.user_id && userMe?.user_id !== selectedNode.user_id && (
        <UserLink
          sx={{
            position: 'absolute',
            top: 0,
            right: 16,
            zIndex: 3,
            backgroundColor: 'background.default',
            borderRadius: '10px',
            padding: '10px',
            maxWidth: '50%',
          }}
          showName={true}
          user_id={selectedNode.user_id}
          display_name={selectedNode.display_name}
          photo_uuid={selectedNode.photo_uuid}
        />
      )}
    </Box>
  );
};

export default BeeSwarm;
