import { FC, useState, useCallback, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerChooser, CarouselMaterialTitle } from 'src/atoms';
import { ErrorContext } from 'src/context/ErrorContext';
import { MaterialsCarousel } from 'src/molecules';
import { getNFTMaterialsMock as getNFTMaterials } from 'src/services';
import { NFTMaterialsResult } from 'src/services/NFTService/NFTService.types';

import { DrawerAvatarSkinChooserProps, MaterialItemProps } from './DrawerAvatarSkinChooser.types';

const dataToMaterialItem = (data: NFTMaterialsResult[]): MaterialItemProps[] => {
  return data.map((material, idx) => {
    return {
      id: `${material.material_level_tier}_${material.material_grade_tier}_${idx}`,
      img: material.image,
      title: material.material_grade_name,
      level: parseInt(material.material_level_tier, 10),
      levelName: material.material_level_name,
      grade: parseInt(material.material_grade_tier, 10),
      gradeName: material.material_grade_name,
    };
  });
};

const DrawerAvatarSkinChooser: FC<DrawerAvatarSkinChooserProps> = ({ isOpen, onClose, onApply }) => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);

  const [items, setItems] = useState<MaterialItemProps[]>();
  const [selected, setSelected] = useState<string | undefined>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const handleChange = (itemId: string | undefined) => {
    setSelected(itemId);
    setIsChanged(true);
  };

  const readData = useCallback(() => {
    getNFTMaterials({ material_level_tier: 0 })
      .then(({ data }) => {
        const resultItems = dataToMaterialItem(data.results);
        setItems(resultItems);
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
      });
  }, [throwServerError]);

  useEffect(() => {
    if (isOpen) readData();
  }, [readData, isOpen]);

  const selectedItem = items?.find((item) => item.id === selected);

  const handlerApply = () => onApply && onApply(selectedItem?.grade || 0);

  return (
    <DrawerChooser
      isOpen={isOpen}
      onClose={onClose}
      isChanged={isChanged}
      onApply={handlerApply}
      titleComponent={<CarouselMaterialTitle item={selectedItem} title={t('avatar.menu.skin')} />}
    >
      <MaterialsCarousel items={items} selected={selected} onSelect={(id) => handleChange(id)} />
    </DrawerChooser>
  );
};

export default DrawerAvatarSkinChooser;
