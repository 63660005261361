import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

export const Price = styled(Typography)(({ theme }) => ({
  fontSize: '1em',
  lineHeight: '1em',
  fontWeight: 600,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  '& .BPrice_icon': {
    marginRight: 4,
    verticalAlign: 'middle',
    marginTop: '-3px',

    '&.BPrice_icon__bnb': {
      fill: ThemeColors.yellow,
    },
  },

  '& .BPrice_bcost': {
    whiteSpace: 'nowrap',
  },

  '& .BPrice_cost': {
    whiteSpace: 'nowrap',
    fontSize: '1em',
    lineHeight: '1em',
    fontWeight: 400,
    color: theme.palette.secondary.main,
    marginLeft: 4,
  },
})) as typeof Typography;
