import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

export const StepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: theme.palette.divider,
  marginLeft: '2px',
  display: 'flex',
  alignItems: 'center',
  ...(ownerState.active && {
    color: ThemeColors.green,
  }),

  '& .StepIcon-activeIcon': {
    marginLeft: '2px',
  },

  '& .StepIcon-completedIcon': {
    color: ThemeColors.green,
    zIndex: 1,
    fontSize: '1.25rem',
  },
  '& .StepIcon-circle': {
    zIndex: 1,
    fontSize: '1.25rem',
    color: 'currentColor',
  },
}));
