import { Avatar, IconButton } from '@mui/material';
import { FC } from 'react';

import { TriangleIcon, TriangleUserEmptyIcon, UserEmptyBorderIcon, UserEmptyIcon } from 'src/assets/icons';
import { getImgSrc } from 'src/constants';
import { ClanListChildrenNode, ClanPosition } from 'src/services';
import { LEVELS_TO_COLORS } from 'src/styles';

import { TriangleWrapper, Clan, TopLevel, SecondLevel, ThirdLevel, ClanMembers } from './Triangle.styles';
import { TriangleProps } from './Triangle.types';

const reduceChild = (acc: (null | ClanListChildrenNode)[], child: ClanListChildrenNode) => {
  if (child.position === ClanPosition.Left) {
    acc[0] = child;
  } else {
    acc[1] = child;
  }
  return acc;
};

const Triangle: FC<TriangleProps> = ({ data, size, level, isFilled, onClickMember }) => {
  const user = data && data.user ? data.user : null;
  const userChildren = data && data.children ? data.children.reduce(reduceChild, [null, null]) : [null, null];

  const childLeftChildren =
    userChildren && userChildren[0]?.children
      ? userChildren[0].children.reduce(reduceChild, [null, null])
      : [null, null];
  const childRightChildren =
    userChildren && userChildren[1]?.children
      ? userChildren[1].children.reduce(reduceChild, [null, null])
      : [null, null];

  const accentColor = LEVELS_TO_COLORS[level - 1];

  return (
    <TriangleWrapper size={size} sx={{ color: accentColor }}>
      <Clan direction="column" alignItems="center" justifyContent="center">
        <TopLevel size={size}>
          <ClanMembers>
            {user ? (
              <IconButton sx={{ color: accentColor }} onClick={() => onClickMember && onClickMember(user.id)}>
                <Avatar src={getImgSrc(user.photo_uuid, 's70-c')} alt={user.display_name}>
                  <UserEmptyIcon className="Triangle_icon" />
                </Avatar>
              </IconButton>
            ) : (
              <UserEmptyBorderIcon onClick={() => onClickMember && onClickMember()} style={{ color: accentColor }} />
            )}
          </ClanMembers>
        </TopLevel>
        <SecondLevel size={size}>
          <ClanMembers>
            {userChildren[0] && userChildren[0]?.user ? (
              <IconButton
                sx={{ color: accentColor }}
                onClick={() => onClickMember && !!userChildren[0] && onClickMember(userChildren[0].user.id)}
              >
                <Avatar
                  src={getImgSrc(userChildren[0].user.photo_uuid, 's70-c')}
                  alt={userChildren[0].user.display_name}
                >
                  <UserEmptyIcon className="Triangle_icon" />
                </Avatar>
              </IconButton>
            ) : (
              <UserEmptyBorderIcon onClick={() => onClickMember && onClickMember()} />
            )}
          </ClanMembers>
          <ClanMembers>
            {userChildren[1] && userChildren[1]?.user ? (
              <IconButton
                sx={{ color: accentColor }}
                onClick={() => onClickMember && !!userChildren[1] && onClickMember(userChildren[1].user.id)}
              >
                <Avatar
                  src={getImgSrc(userChildren[1].user.photo_uuid, 's70-c')}
                  alt={userChildren[1].user.display_name}
                >
                  <UserEmptyIcon className="Triangle_icon" />
                </Avatar>
              </IconButton>
            ) : (
              <UserEmptyBorderIcon onClick={() => onClickMember && onClickMember()} />
            )}
          </ClanMembers>
        </SecondLevel>
        <ThirdLevel size={size}>
          <ClanMembers>
            {childLeftChildren[0] && childLeftChildren[0]?.user ? (
              <IconButton
                sx={{ color: accentColor }}
                onClick={() => onClickMember && !!childLeftChildren[0] && onClickMember(childLeftChildren[0].user.id)}
              >
                <Avatar
                  src={getImgSrc(childLeftChildren[0].user.photo_uuid, 's70-c')}
                  alt={childLeftChildren[0].user.display_name}
                >
                  <UserEmptyIcon className="Triangle_icon" />
                </Avatar>
              </IconButton>
            ) : (
              <TriangleUserEmptyIcon onClick={() => onClickMember && onClickMember()} style={{ color: accentColor }} />
            )}
            {childLeftChildren[1] && childLeftChildren[1]?.user ? (
              <IconButton
                sx={{ color: accentColor }}
                onClick={() => onClickMember && !!childLeftChildren[1] && onClickMember(childLeftChildren[1].user.id)}
              >
                <Avatar
                  src={getImgSrc(childLeftChildren[1].user.photo_uuid, 's70-c')}
                  alt={childLeftChildren[1].user.display_name}
                >
                  <UserEmptyIcon className="Triangle_icon" />
                </Avatar>
              </IconButton>
            ) : (
              <TriangleUserEmptyIcon onClick={() => onClickMember && onClickMember()} style={{ color: accentColor }} />
            )}
          </ClanMembers>

          <ClanMembers>
            {childRightChildren[0] && childRightChildren[0]?.user ? (
              <IconButton
                sx={{ color: accentColor }}
                onClick={() => onClickMember && !!childRightChildren[0] && onClickMember(childRightChildren[0].user.id)}
              >
                <Avatar
                  src={getImgSrc(childRightChildren[0].user.photo_uuid, 's70-c')}
                  alt={childRightChildren[0].user.display_name}
                >
                  <UserEmptyIcon className="Triangle_icon" />
                </Avatar>
              </IconButton>
            ) : (
              <TriangleUserEmptyIcon onClick={() => onClickMember && onClickMember()} style={{ color: accentColor }} />
            )}
            {childRightChildren[1] && childRightChildren[1]?.user ? (
              <IconButton
                sx={{ color: accentColor }}
                onClick={() => onClickMember && !!childRightChildren[1] && onClickMember(childRightChildren[1].user.id)}
              >
                <Avatar
                  src={getImgSrc(childRightChildren[1].user.photo_uuid, 's70-c')}
                  alt={childRightChildren[1].user.display_name}
                >
                  <UserEmptyIcon className="Triangle_icon" />
                </Avatar>
              </IconButton>
            ) : (
              <TriangleUserEmptyIcon onClick={() => onClickMember && onClickMember()} style={{ color: accentColor }} />
            )}
          </ClanMembers>
        </ThirdLevel>
      </Clan>
      <TriangleIcon style={{ opacity: isFilled ? 0.25 : 1 }} />
    </TriangleWrapper>
  );
};

export default Triangle;
