import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Routes, Route, Navigate } from 'react-router-dom';

import { DemoBg } from 'src/assets/images';
import { NotificationWSController, ScrollToTop } from 'src/atoms';
import { Paths, isDev, isMarket } from 'src/constants';
import { ErrorContext, ErrorContextProvider } from 'src/context/ErrorContext';
import { ThemeContext } from 'src/context/themeContext';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { MDPage, AnyPage } from 'src/landing/pages';
import {
  Components,
  MDStyleHelp,
  SignIn,
  Reg,
  Referral,
  Dashboard,
  Profile,
  TeamList,
  TeamTree,
  TeamGlobe,
  Structure,
  ShoppingCart,
  Globe,
  StructureTree,
  Finance,
  MyNFT,
  NFTCard,
  NFTBurn,
  Transaction,
  ProfileUser,
  Notifications,
  NotificationsArchive,
  Goal,
  GoalCard,
  GoalList,
  GoalHistory,
  GetMoney,
  Feedback,
  Study,
  StudyStart,
  Auth,
  Blockchain,
  TelegramWebApp,
  GetTarget,
  Referal,
  Exchange,
  Avatar,
  HexGlobe,
} from 'src/pages';
import { getDemoData, getPhrases } from 'src/services';
import { selectDemoData, setDemoData } from 'src/store/demoSlice';
import { checkVerifyUser, getInfoUser } from 'src/store/userSlice';
import { getTheme } from 'src/styles';
import { Notification, Team, Wallet, Application } from 'src/templates';
import { getLocale, getToken, translationsArrayToObject, logger } from 'src/utils';

import { GuestRoute, PrivateRoute, PublicRoute } from './elements';

const CloseSnackbarAction = ({ id }: any) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      style={{ marginTop: '0.1em' }}
      onClick={() => {
        closeSnackbar(id);
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

const Router: FC = () => {
  const locale = getLocale();
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { is_demo } = useAppSelector(selectDemoData);

  const { mode, changeShowLang, isShowLang } = useContext(ThemeContext);
  const { throwServerError } = useContext(ErrorContext);
  const themeMui = getTheme(mode);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getDemoData()
      .then(({ data }) => {
        dispatch(setDemoData(data));
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
        logger.error(error);
      });
  }, [dispatch, throwServerError]);

  useEffect(() => {
    const token = getToken().refresh;
    if (token) {
      dispatch(checkVerifyUser({ refresh: token }));
      dispatch(getInfoUser());
    }
  }, [dispatch]);

  useEffect(() => {
    if (locale && isLoading) {
      getPhrases(Number(locale)).then(({ data }) => {
        i18n.addResourceBundle(locale, 'app', translationsArrayToObject(data), true, true);
        setLoading(false);
        changeShowLang();
      });
    }
  }, [locale, i18n, changeShowLang, isLoading]);

  if (isShowLang && isLoading) return null;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale === '1' ? ruLocale : enLocale}>
      <SnackbarProvider maxSnack={3} action={(snackbarId) => <CloseSnackbarAction id={snackbarId} />}>
        <ThemeProvider theme={themeMui}>
          <CssBaseline />
          <Container
            sx={{
              maxWidth: '600px',
              minWidth: '300px',
              // height: 'calc(100vh + env(safe-area-inset-bottom))',
              backgroundImage: is_demo ? `url(${DemoBg})` : undefined,
              position: 'relative',
            }}
            className="h-screen"
            maxWidth={false}
          >
            <NotificationWSController />
            <ErrorContextProvider>
              <ScrollToTop>
                <Routes>
                  <Route element={<PublicRoute />}>
                    {/* доступны всем и с авторизацией и без */}
                    <Route path={Paths.termsOfUse} element={<MDPage tkey="termsOfUse" />} />
                    <Route path={Paths.privacyPolicy} element={<MDPage tkey="privacyPolicy" />} />
                    <Route path={Paths.pageAny} element={<AnyPage />} />
                  </Route>

                  <Route element={<GuestRoute />}>
                    {/* доступны только без авторизации, если авторизовался то не открыть */}
                    <Route path={Paths.referral} element={<Referral />} />
                    <Route path={Paths.auth} element={<Auth />} />
                    <Route path={Paths.signIn} element={<SignIn allowReg={false} />} />
                    <Route path={Paths.telegramWebApp} element={<TelegramWebApp />} />
                  </Route>

                  <Route path={Paths.reg} element={<Reg />} />

                  <Route path={Paths.profile} element={<Profile />} />

                  <Route path={Paths.avatar} element={<Avatar />} />
                  <Route path={Paths.hexglobe} element={<HexGlobe />}>
                    <Route path={Paths.hexglobeHex} element={<HexGlobe />} />
                    <Route path={Paths.hexglobeLevel} element={<HexGlobe />} />
                  </Route>

                  <Route path={Paths.notifications} element={<Notification />}>
                    <Route index element={<Notifications />} />
                    <Route path={Paths.notificationsArchive} element={<NotificationsArchive />} />
                  </Route>
                  <Route path={Paths.goalCard} element={<GoalCard />} />
                  <Route path={Paths.goalHistory} element={<GoalHistory />} />
                  <Route path={Paths.transaction} element={<Transaction />} />
                  <Route path={Paths.feedback} element={<Feedback />} />
                  <Route path={Paths.shoppingCart} element={<ShoppingCart />} />
                  <Route path={Paths.myReferral} element={<Referal />} />

                  <Route path={Paths.globeAll} element={<Globe />} />

                  <Route path={Paths.team} element={<Team />}>
                    <Route index element={<TeamList />} />
                    <Route path={Paths.teamTree} element={<TeamTree />} />
                    <Route path={Paths.teamGlobe} element={<TeamGlobe />} />
                    <Route path={Paths.teamGlobeAll} element={<TeamGlobe />} />
                  </Route>

                  <Route path={Paths.exchange} element={<Exchange />} />

                  <Route path={Paths.start} element={<StudyStart />} />
                  <Route path={Paths.study} element={<Study />}>
                    <Route path={Paths.studyStep} element={<Study />} />
                  </Route>

                  <Route path={Paths.dashboard} element={<Dashboard />} />
                  <Route path={Paths.profileUser} element={<ProfileUser />} />

                  <Route path={Paths.wallet} element={<Wallet />}>
                    <Route index element={<Finance />} />
                    <Route path={Paths.myNFT} element={<MyNFT />} />
                  </Route>

                  <Route path={Paths.goal} element={<Goal />} />

                  <Route path={Paths.structure} element={<Structure />} />
                  <Route path={Paths.structureTree} element={<StructureTree />} />

                  <Route path={Paths.NFTCard} element={<NFTCard />} />
                  <Route path={Paths.NFTBurn} element={<NFTBurn />} />

                  <Route path={Paths.getTarget} element={<GetTarget />} />
                  <Route path={Paths.getMoney} element={<GetMoney />} />

                  <Route path={Paths.goalList} element={<GoalList />}>
                    <Route path={Paths.goalProduct} element={<GoalList />} />
                  </Route>

                  {isDev && (
                    <>
                      <Route path="/components" element={<Components />} />
                      <Route path="/mdstyle" element={<MDStyleHelp />} />
                      <Route path="/web3" element={<Blockchain />} />
                    </>
                  )}

                  <Route path="*" element={<Navigate to={isMarket ? Paths.home : Paths.dashboard} replace />} />
                </Routes>
              </ScrollToTop>
            </ErrorContextProvider>
          </Container>
        </ThemeProvider>
      </SnackbarProvider>
    </LocalizationProvider>
  );
};

export default Router;
