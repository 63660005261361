import { Box, LinearProgress } from '@mui/material';
import { FC, useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeGrid as RWGrid } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { EmptyData, NFTItem } from 'src/atoms';
import { LIMIT_NFTS } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { useAppSelector, useResize } from 'src/hooks';
import { getNFTByParams, NFTShort } from 'src/services';
import { selectUserMe } from 'src/store/userSlice';
import { ThemePaddings } from 'src/styles';
import { logger } from 'src/utils';

const MyNFT: FC = () => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const userMe = useAppSelector(selectUserMe);

  const { width: wrapperWidth, height: wrapperHeight } = useResize(containerRef);

  const [isLoading, setLoading] = useState(true);
  const [isPending, setPending] = useState(false);
  const [nfts, setNfts] = useState<NFTShort[] | null>(null);
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (offset === 1) {
      setLoading(true);
      getNFTByParams({ offset, limit: LIMIT_NFTS, owner: userMe?.user_id })
        .then(({ data }) => {
          setNfts(data.results);
          setCount(data.count);
          setOffset(LIMIT_NFTS);
        })
        .catch((error) => {
          if (error.response?.status >= 500) throwServerError();
          logger.error(error);
        })
        .finally(() => setLoading(false));
    }
  }, [offset, throwServerError, userMe?.user_id]);

  useEffect(() => {
    const { current } = containerRef;
    if (!current) return;

    setWidth(current.offsetWidth);
    setHeight(current.offsetHeight);
  }, [containerRef]);

  useEffect(() => {
    if (!wrapperHeight && !wrapperWidth) return;

    setWidth(wrapperWidth);
    setHeight(wrapperHeight);
  }, [wrapperWidth, wrapperHeight]);

  const isItemLoaded = (index: number) => (nfts?.length ? !!nfts[index] : false);

  const loadMoreItems = () => {
    if (offset >= count) return;

    if (!isPending) {
      setPending(true);
      getNFTByParams({ offset: offset, limit: LIMIT_NFTS, owner: userMe?.user_id })
        .then(({ data }) => {
          nfts && setNfts([...nfts, ...data.results]);
          setOffset((prevOffset) => prevOffset + LIMIT_NFTS);
        })
        .catch((error) => {
          if (error.response?.status >= 500) throwServerError();
          logger.error(error);
        })
        .finally(() => setPending(false));
    }
  };

  const NUM_COLUMNS = 2;
  const WIDTH_OFFSET = 32;

  return (
    <Box ref={containerRef} sx={{ p: ThemePaddings.myNFT, height: 'calc(100% - 162px)' }}>
      {isLoading && (
        <LinearProgress sx={{ position: 'absolute', marginLeft: 0, left: 0, top: 0, width: '100%', zIndex: 10 }} />
      )}

      {!isLoading &&
        (nfts?.length ? (
          <Box
            sx={{
              position: 'relative',
              width: width - WIDTH_OFFSET,
              height: '100%',
              margin: '0 auto',
            }}
          >
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={count}
              loadMoreItems={loadMoreItems}
              threshold={LIMIT_NFTS}
            >
              {({ onItemsRendered, ref }) => (
                <RWGrid
                  columnCount={NUM_COLUMNS}
                  rowCount={Math.ceil(nfts.length / NUM_COLUMNS)}
                  width={width - WIDTH_OFFSET}
                  columnWidth={(width - WIDTH_OFFSET) / NUM_COLUMNS}
                  height={height}
                  rowHeight={(width + 140) / 2} // 252
                  onItemsRendered={(gridProps) => {
                    onItemsRendered({
                      overscanStartIndex: gridProps.overscanRowStartIndex * NUM_COLUMNS,
                      overscanStopIndex: gridProps.overscanRowStopIndex * NUM_COLUMNS,
                      visibleStartIndex: gridProps.visibleRowStartIndex * NUM_COLUMNS,
                      visibleStopIndex: gridProps.visibleRowStopIndex * NUM_COLUMNS,
                    });
                  }}
                  ref={ref}
                >
                  {({ columnIndex, rowIndex, style }) => {
                    const itemIndex = rowIndex * NUM_COLUMNS + columnIndex;

                    const nft = nfts[itemIndex];
                    return <div style={style}>{nft && <NFTItem nft={nft} />}</div>;
                  }}
                </RWGrid>
              )}
            </InfiniteLoader>
          </Box>
        ) : (
          <EmptyData title={t('myNFT.emptyTitle')} data-qa={'myNFT.emptyTitle'} text={t('myNFT.emptyText')} />
        ))}
    </Box>
  );
};

export default MyNFT;
