import { Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeFontWeight } from 'src/styles';

export const Wrapper = styled(Stack)(({ theme }) => ({
  padding: '4px 16px',
  background: theme.palette.background.paper,
})) as typeof Stack;

export const Title = styled(Typography)(() => ({
  fontSize: '1.25rem',
  lineHeight: '24px',
  fontWeight: ThemeFontWeight.semiBold,
}));

export const BadgeBlock = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '24px',
  fontWeight: ThemeFontWeight.semiBold,
  textAlign: 'center',
  padding: '0px 8px',
  border: '1px solid',
  borderRadius: '24px',
  borderColor: theme.palette.primary.main,
  background: theme.palette.background.paper,
  cursor: 'pointer',
}));
