import { AxiosResponse } from 'axios';

import agent from 'src/agent';
import { THierarchyMenuItem } from 'src/molecules';
import { PaginationParams } from 'src/types';
import { getFormData } from 'src/utils';

import { TCard } from '../NFTService';

import {
  TTargetListItem,
  TTargetListItemAttributes,
  TTargetCurrentItem,
  TTargetHistory,
  TTargetFeedbackList,
  TTargetList,
  TTargetStatusHistory,
} from './TargetsService.types';

export const getGoalList = (
  pagination?: PaginationParams,
  filter?: {
    parent?: number;
    search?: string;
    top?: boolean;
  }
): Promise<AxiosResponse<TTargetList>> => {
  const { limit = 1, offset = 0 } = pagination || {};
  return agent.GET('targets/list', { params: { limit, offset, ...(filter || {}) } });
};

export const getGoalItem = (
  compositeId: string,
  attributes: TTargetListItemAttributes[]
): Promise<AxiosResponse<TTargetListItem>> => {
  return agent.POST(`targets/${compositeId}/read`, { attributes });
};

export const setGoalItem = (
  compositeId: string,
  attributes: TTargetListItemAttributes[]
): Promise<AxiosResponse<TTargetListItem>> => {
  return agent.POST('/targets/update', { composite_id: compositeId, attributes });
};

export const getGoalCurrent = (): Promise<AxiosResponse<TTargetCurrentItem>> => {
  return agent.GET('targets/current');
};

export const getGoalHistory = (params?: PaginationParams, user_id?: number): Promise<AxiosResponse<TTargetHistory>> => {
  const { limit = 1, offset = 0 } = params || {};
  return agent.GET('targets/user-target-list', { params: { limit, offset, user_id } });
};

export const getGoalRead = (compositeId: string): Promise<AxiosResponse<TCard>> => {
  return agent.GET(`targets/${compositeId}/user-target-read/`);
};

export type TDeliveryFormValues = {
  additional_name: string;
  city_id: number;
  country: string;
  first_name: string;
  flat: string;
  house: string;
  last_name: string;
  phone: string;
  postcode: string;
  street: string;
  user: number;
  yes_rule?: boolean;
  city?: string;
  comment?: string;
};

export const deliveryCreate = (compositeId: string): Promise<AxiosResponse<{ delivery: TDeliveryFormValues }>> => {
  return agent.GET(`targets/${compositeId}/delivery-create`);
};

export const deliveryUpdate = (compositeId: string, data: TDeliveryFormValues): Promise<AxiosResponse<{}>> => {
  const formData = getFormData(data);
  return agent.POST(`targets/${compositeId}/delivery-update`, formData);
};

export const getStateProgress = (compositeId: string): Promise<AxiosResponse<TTargetStatusHistory[]>> => {
  return agent.GET(`targets/${compositeId}/status`);
};

//------ market
export const putItemInBasket = (
  compositeId: string,
  attributes: TTargetListItemAttributes[]
): Promise<AxiosResponse<TTargetListItem>> => {
  return agent.POST('/targets/order/update', { composite_id: compositeId, attributes });
};

export const getMarketCatrgory = (): Promise<AxiosResponse<THierarchyMenuItem[]>> => {
  return agent.GET('targets/hierarchy-list');
};

export const getMarketBasketCount = async (): Promise<number> => {
  const { data } = await agent.GET('targets/order/counter');
  return data.count || 0;
};

export const getMarketFeedback = (
  params?: PaginationParams,
  target_id?: number
): Promise<AxiosResponse<TTargetFeedbackList>> => {
  const { limit = 1, offset = 0 } = params || {};
  return agent.GET('targets/feedback', { params: { limit, offset, id: target_id } });
};
