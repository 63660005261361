// import FavoriteIcon from '@mui/icons-material/Favorite';
//import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NoPhoto } from 'src/assets/images';
import { BPrice } from 'src/atoms';
// import { MyVote } from 'src/services';
//import { abbrNum } from 'src/utils';
import { Paths, pathsArg, getImgSrc } from 'src/constants';

import { NFT, Wrapper, Image, Info, Title, SubTitle, Row } from './NFTItem.styles';
import { NFTItemProps } from './NFTItem.types';

const NFTItem: FC<NFTItemProps> = ({ nft }) => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  return (
    <Wrapper>
      <NFT onClick={() => navigate(pathsArg(Paths.NFTCard, { compositeId: nft.composite_id }))}>
        <Info>
          <Title>{t(nft.name)}</Title>
          <SubTitle>
            {t('NFTItem.id')} {nft.id}
          </SubTitle>
        </Info>
        <Image src={nft.image_uuid ? getImgSrc(nft.image_uuid, 's300-c') : NoPhoto} alt={t(nft.name)} />
        <Info>
          <Row>
            <BPrice
              sx={{ fontSize: '0.75rem', lineHeight: '16px' }}
              price={{
                bcost: nft.price.bcost,
                bcurrency: nft.price.bcurrency,
              }}
            />
            {nft.price.cost && (
              <BPrice
                sx={{
                  fontSize: '0.75rem',
                  lineHeight: '16px',
                  color: 'secondary.main',
                  '& .BPrice_icon': { display: 'none' },
                }}
                price={{
                  bcost: nft.price.cost || 0,
                  bcurrency: nft.price.currency || '',
                }}
              />
            )}
            {/*<Likes>
              <LikeButton
                sx={{
                  color: (theme) => (nft.my_vote === MyVote.Like ? ThemeColors.redLight : theme.palette.primary.main),
                }}
              >
                {nft.my_vote === MyVote.Like ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </LikeButton>
              {abbrNum(+nft.likes_count)}
              </Likes> */}
          </Row>
        </Info>
      </NFT>
    </Wrapper>
  );
};

export default NFTItem;
