import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyTeamWrapper, Title, Text } from './EmptyTeam.styles';

const EmptyTeam: FC = () => {
  const { t } = useTranslation('app');
  return (
    <EmptyTeamWrapper>
      <Title>
        <span>{t('team.emptyTitle')}</span>
      </Title>
      <Text>{t('team.emptyText')}</Text>
    </EmptyTeamWrapper>
  );
};

export default EmptyTeam;
