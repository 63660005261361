import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TriangleBg } from 'src/assets/icons';
import { BPrice, CircleGradientLoading, DrawerBottom, HistoryTransaction, Triangle } from 'src/atoms';
import { getOrdersByClanId, TOrder, ClanStatus, TOrderPrice } from 'src/services';
import { Sizes } from 'src/types';

import { HistoryDetailsWrapper, TriangleWrapper } from './HistoryDetails.styles';
import { HistoryDetailsProps } from './HistoryDetails.types';

const HistoryDetails: FC<HistoryDetailsProps> = ({ isOpen, onClose, level, triangle, onClickMember }) => {
  const { t } = useTranslation('app');
  const { enqueueSnackbar } = useSnackbar();

  const clanId = triangle.id;
  const [loadImage, setLoadImage] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [payments, setPayments] = useState<TOrder[]>([]);
  const [sum, setSum] = useState<TOrderPrice>();

  useEffect(() => {
    setPayments([]);
    setLoading(true);
    getOrdersByClanId(clanId)
      .then(({ data }) => {
        setPayments(data.results);
        setSum(data.balance);
      })
      .catch(({ message }) => {
        enqueueSnackbar(message, { variant: 'error' });
      })
      .finally(() => setLoading(false));
  }, [clanId, enqueueSnackbar]);

  useEffect(() => {
    /**
     * TODO: use axios with blob extraction and URL.createObjectURL() to get image url
     * Or just place original url to state inside axios then
     */
    const img = new Image();
    img.src = TriangleBg;
    img.onload = () => setLoadImage(true);
  }, []);

  if (!loadImage) return null;

  return (
    <DrawerBottom isOpen={isOpen} onClose={onClose} sx={{ maxHeight: '90%' }}>
      <IconButton sx={{ position: 'absolute', right: 14, top: 14 }} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <TriangleWrapper sx={{ background: `url(${TriangleBg})` }}>
        <Triangle
          size={Sizes.Medium}
          level={level}
          isFilled={triangle?.status === ClanStatus.Open}
          data={triangle}
          onClickMember={onClickMember}
        />
      </TriangleWrapper>
      <HistoryDetailsWrapper>
        {isLoading && (
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: 130 }}>
            <CircleGradientLoading size={60} />
          </Stack>
        )}

        {payments?.length > 0 &&
          payments.map((payment, index) => (
            <HistoryTransaction
              key={payment.id}
              payment={payment}
              isOdd={index % 2 === 0}
              isLast={payments.length === index + 1}
            />
          ))}

        {!isLoading && sum && (
          <Stack direction="column" justifyContent="space-between" sx={{ my: '24px', px: '16px' }} spacing="10px">
            <Typography variant="h1" component="p" sx={{ my: 0 }}>
              {t('structure.historyDetailsTotal')}:
            </Typography>
            <BPrice price={sum} />
          </Stack>
        )}
      </HistoryDetailsWrapper>
    </DrawerBottom>
  );
};

export default HistoryDetails;
