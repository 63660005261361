import { FC } from 'react';

import { CarouselTab as CarouselTabStyle } from './CarouselTab.styles';
import { CarouselTabProps } from './CarouselTab.types';

const CarouselTab: FC<CarouselTabProps> = (props) => {
  const labelOut = <span className="Mui-tab-label">{props.label}</span>;
  return <CarouselTabStyle {...props} label={labelOut}></CarouselTabStyle>;
};

export default CarouselTab;
