import { Drawer, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DrawerStyled = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    border: 'none',
  },
}));

export const DrawerBoxStyled = styled(Box)(({ theme }) => ({
  width: 'auto',
  background: theme.palette.background.paper,
  border: '1px solid',
  borderBottom: 'none',
  borderRadius: '30px 30px 0 0',
  padding: '16px 16px 16px',
  height: 'inherit',
}));
