import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AvatarWidgetBlank } from 'src/assets/images';
import { Paths } from 'src/constants';
import { ImageWidget } from 'src/molecules';

import { AvatarTitle, AvatarText } from './AvatarWidget.styles';
import { AvatarWidgetProps } from './AvatarWidget.types';

const AvatarWidget: FC<AvatarWidgetProps> = ({ enabled: enabledProp, preview, sx }) => {
  const { t } = useTranslation('app');

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const enabled = enabledProp === undefined ? true : enabledProp;

  const onProfileHandler = () => {
    if (enabled) {
      navigate(Paths.avatar);
    } else {
      enqueueSnackbar(t('avatarWidget.noAvatarInfo'), {
        variant: 'info',
      });
    }
  };

  return (
    <ImageWidget
      onClick={onProfileHandler}
      sx={sx}
      value={
        <AvatarText>
          <AvatarTitle>{t('dashboard.avatar')}</AvatarTitle>
        </AvatarText>
      }
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 0,
          backgroundImage: `url('${preview || AvatarWidgetBlank}')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 10%',
          width: '100%',
          height: '100%',
          border: 'none',
          borderRadius: '20px',
        }}
      >
        {' '}
      </Box>
    </ImageWidget>
  );
};

export default AvatarWidget;
