import { Fab } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FabMenuAction = styled(Fab)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.primary.main,

  '&.Mui-selected': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
  },
}));
