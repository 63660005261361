import { FC } from 'react';
import { useOutlet } from 'react-router-dom';

// import { Paths } from 'src/constants';
import { useAppSelector } from 'src/hooks';
import { selectAuthorized } from 'src/store/userSlice';

const PublicRoute: FC = () => {
  const isAuthorized = useAppSelector(selectAuthorized);
  // const userMe = useAppSelector(selectUserMe);
  const outlet = useOutlet();
  // const { pathname } = useLocation();

  if (!isAuthorized) {
    // return <Navigate to={Paths.signIn} replace={true} />;
  }

  if (isAuthorized) {
    //
  }

  return <>{outlet}</>;
};

export default PublicRoute;
