import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { IconButton } from '@mui/material';
import { FC } from 'react';

import { PrevButtonProps } from './PrevButton.types';

const PrevButton: FC<PrevButtonProps> = ({ onClick, sx }) => {
  return (
    <IconButton
      data-qa={'button.prev'}
      onClick={() => onClick()}
      sx={{
        flex: 'none',
        border: '1px solid',
        width: '56px',
        height: '56px',
        backgroundColor: 'background.paper',
        ...sx,
      }}
      size="large"
    >
      <KeyboardBackspaceIcon />
    </IconButton>
  );
};

export default PrevButton;
