import { AxiosResponse } from 'axios';

import agent from 'src/agent';

import { Notice, NoticeParams, NoticesResponse } from './NotificationService.types';

export const getNotices = async (params?: NoticeParams): Promise<NoticesResponse> => {
  const { data } = await agent.GET('notices', { params });
  return data;
};

export const setLikeNotice = (noticeId: number): Promise<AxiosResponse<Notice>> => {
  return agent.POST(`notices/${noticeId}/like`);
};

export const readAllNotices = () => {
  return agent.POST('notices/read_all');
};

export const readNotice = (noticeId: number): Promise<AxiosResponse<Notice>> => {
  return agent.POST(`notices/${noticeId}/read`);
};
