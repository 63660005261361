import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AvatarEditorBlock = styled(Stack)(() => ({
  height: 'inherit',
  position: 'relative',
}));

export const AvatarEditorView = styled(Box)(() => ({
  padding: '0px',
  margin: '0px',
  overflow: 'hidden',
  flex: 1,
  height: '100%',
  position: 'relative',
  '& .avatar_frame': {
    width: '100%',
    height: '100%',
    border: 'none',
    padding: 'inherit',
    margin: 'inherit',
  },
}));

const menuWidth = 64;
const offsetWidth = 16;
const offsetHeight = 116;

export const AvatarEditorLeftMenuBlock = styled(Stack)(() => ({
  position: 'absolute',
  top: offsetHeight,
  left: offsetWidth,
}));

export const AvatarEditorRightMenuBlock = styled(Stack)(() => ({
  position: 'absolute',
  top: offsetHeight,
  right: offsetWidth + menuWidth,
}));
