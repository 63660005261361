import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HexGlobeBlock = styled(Stack)(() => ({
  height: 'inherit',
  position: 'relative',
}));

export const HexGlobeView = styled(Box)(() => ({
  padding: '0px',
  margin: '0px',
  overflow: 'hidden',
  flex: 1,
  height: '100%',
  position: 'relative',
  '& .hexglobe_frame': {
    width: '100%',
    height: '100%',
    border: 'none',
    padding: 'inherit',
    margin: 'inherit',
  },
}));
