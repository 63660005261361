// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Stack } from '@mui/material';
// import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerShareText } from 'src/molecules';
import { ThemeFontWeight } from 'src/styles';
// import { copyToClipboard } from 'src/utils';

import { ReferralWidgetBox, ReferralTitle, ReferralLink } from './ReferralWidget.styles';
import { ReferralWidgetProps } from './ReferralWidget.types';

const ReferralWidget: FC<ReferralWidgetProps> = ({ displayName, referralLink, isHideShare, sx }) => {
  const { t } = useTranslation('app');
  // const { enqueueSnackbar } = useSnackbar();

  const [isOpen, setOpen] = useState(false);

  const onOpenHandler = () => setOpen(true);
  const onCloseHandler = () => setOpen(false);

  /*
  const onCopyHandler = () => {
    copyToClipboard(referralLink);
    enqueueSnackbar(t('button.copied'), { variant: 'info' });
  };*/

  return (
    <>
      <ReferralWidgetBox sx={sx}>
        <ReferralTitle>{t('dashboard.referralTitle')}</ReferralTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '17px 16px' }}>
          <ReferralLink>{referralLink}</ReferralLink>
          {/*<IconButton aria-label="copy" sx={{ color: ThemeColors.white }} onClick={onCopyHandler}>
            <ContentCopyIcon />
          </IconButton>*/}
        </Stack>
        {!isHideShare && (
          <Stack sx={{ pb: '20px', px: '16px', position: 'relative', zIndex: 1, alignItems: 'center' }}>
            <Button
              variant="outlined"
              sx={{ width: '220px', height: '40px', fontSize: '0.875rem', fontWeight: ThemeFontWeight.semiBold }}
              onClick={onOpenHandler}
            >
              {t('button.inviteFriend')}
            </Button>
          </Stack>
        )}
      </ReferralWidgetBox>

      <DrawerShareText
        title={displayName || t('onboarding.unknownName')}
        link={referralLink}
        isOpen={isOpen}
        tKey="referal"
        onClose={onCloseHandler}
      />
    </>
  );
};

export default ReferralWidget;
