import * as yup from 'yup';

import { emailRegExp } from 'src/constants';
import { getErrorMail, maxStringLength, getRequiredField } from 'src/utils';

const maxLengthEmail = () => maxStringLength(256);

export const schema = yup.object().shape({
  display_name: yup.string().required(getRequiredField).nullable(),
  email: yup.string().required(getRequiredField).max(256, maxLengthEmail).matches(emailRegExp, {
    message: getErrorMail,
    excludeEmptyString: true,
  }),
  gender: yup.string().required(getRequiredField).nullable(),
  birthday: yup.string().required(getRequiredField),
});
