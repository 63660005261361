import { Button, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NoPhoto } from 'src/assets/images';
import { BPrice, GoalItem } from 'src/atoms';
import { Paths, pathsArg, getImgSrc, isMarket, globeTKey } from 'src/constants';
import { useAppSelector } from 'src/hooks';
import { TargetStatus } from 'src/services';
import { selectUserMe } from 'src/store/userSlice';
import { formatDate } from 'src/utils';

import { SubTitle, Row, State, Column, OpacityBlock } from './GoalHistoryItem.styles';
import { GoalHistoryItemProps } from './GoalHistoryItem.types';

const GoalHistoryItemWaitFeedback: FC<GoalHistoryItemProps> = ({ item, readonly }) => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  const _navigate = !readonly ? navigate : () => false;

  const feedback = item.status === TargetStatus.ModerateFeedback ? 'moderate' : 'wait';
  return (
    <OpacityBlock>
      <Row spacing={2} sx={{ height: '100%', alignItems: 'center', justifyContent: 'center', whiteSpace: 'normal' }}>
        <Typography sx={{ textAlign: 'center' }}>
          {t(`${globeTKey}goal.item.feedback.${feedback}.title`)}{' '}
          {!isMarket && feedback === 'wait' && t(`goal.item.feedback.${feedback}.ddt`, { count: item.ddt })}
        </Typography>
        <Typography sx={{ fontSize: '0.75rem', textAlign: 'center' }}>
          {t(`${globeTKey}goal.item.feedback.${feedback}.info`)}
        </Typography>
        {feedback === 'wait' && (
          <Button
            onClick={(ev) => {
              ev.stopPropagation();
              _navigate(pathsArg(Paths.feedback, { id: item.id }));
            }}
            variant="outlined"
            sx={{ fontSize: '0.75rem', height: '24px' }}
          >
            {t('goal.item.feedback.button')}
          </Button>
        )}
      </Row>
    </OpacityBlock>
  );
};

const GoalHistoryItem: FC<GoalHistoryItemProps> = ({ item, readonly }) => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  const userMe = useAppSelector(selectUserMe);
  const isMy = userMe?.user_id === item.owner;

  const _navigate = !readonly ? navigate : () => false;

  const stateColorT = t(`goal.state.color.${item.status}`);
  const stateColor = stateColorT[0] === '#' ? stateColorT : '#fff';

  return (
    <GoalItem
      item={{
        id: item.composite_id,
        name: item.target.name,
        photo_uuid: item.target.photo_uuid,
        cost: item.cost,
        info: (
          <Column>
            <SubTitle sx={{ flex: 1 }}>{formatDate(item.updated_at)}</SubTitle>
            <State data-qa={`goal.state.${item.status}`} sx={{ ml: '4px', flex: 2, color: stateColor }}>
              {t(`goal.state.${item.status}`)}
            </State>
          </Column>
        ),
      }}
      onClick={(item) => _navigate(pathsArg(Paths.goalCard, { compositeId: item.id }))}
    >
      <>
        {isMy && (item.status === TargetStatus.Feedback || item.status === TargetStatus.ModerateFeedback) ? (
          <GoalHistoryItemWaitFeedback item={item} readonly={readonly} />
        ) : null}
      </>
    </GoalItem>
  );
};

export default GoalHistoryItem;
