import { Box, Button, Divider, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DrawerBottom, EmptyData, PaymentCost, PaymentTransactionLoader, ShoppingCartFees } from 'src/atoms';
import { Buy, Paths } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { PageTitle, WindowTitle } from 'src/molecules';
import { ShoppingCart as ShoppingCartControl } from 'src/organisms';
import { createPackOrder, getOrderById, OrderStatus, sendTargetConfirm, TOrder, TOrderItem } from 'src/services';
import { ThemePaddings } from 'src/styles';

const TIME_GET_STATUS = 10000;

const GetTarget: FC = () => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { throwServerError } = useContext(ErrorContext);

  const [isLoading, setLoading] = useState(false);

  const [order, setOrder] = useState<TOrder>();
  const [error, setError] = useState<string>();

  const [paymentOpen, setPaymentOpen] = useState<boolean>(false);
  const [transactionOpen, setTransactionOpen] = useState<boolean>(false);
  const [isTransactionComplite, setTransactionComplite] = useState(false);

  const [status, setStatus] = useState<OrderStatus | null>(null);
  const [isReqPay, setReqPay] = useState(false);

  const onCloseHandler = () => (window.history.length ? navigate(-1) : navigate(Paths.dashboard));

  useEffect(() => {
    setLoading(true);
    createPackOrder([Buy.productNFT])
      .then(({ data }) => {
        setOrder(data);
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
        enqueueSnackbar(t('errorMsg.getTarget'), { variant: 'error' });
        if (error.response?.status === 409) {
          // HTTP_409_CONFLICT товара нет на складе
          setError('EMPTY_PRODUCT');
        }
      })
      .finally(() => setLoading(false));
  }, [throwServerError, enqueueSnackbar, t]);

  const handlerPayment = (items: TOrderItem[]) => {
    setPaymentOpen(true);
  };

  useEffect(() => {
    if (!status || !order?.id) return;

    const getStatusTimeout = setInterval(() => {
      if (isReqPay) return;

      setReqPay(true);
      getOrderById(order?.id)
        .then(({ data }) => {
          if (data.status !== OrderStatus.Completed) return;
          clearInterval(getStatusTimeout);
          setTransactionComplite(true);
        })
        .catch(({ message }) => {
          clearInterval(getStatusTimeout);
          enqueueSnackbar(message, { variant: 'error' });
        })
        .finally(() => setReqPay(false));
    }, TIME_GET_STATUS);

    return () => {
      clearInterval(getStatusTimeout);
      setTransactionComplite(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, order, enqueueSnackbar]);

  const confirmGet = () => {
    if (!order) return;
    setLoading(true);
    sendTargetConfirm(order.id)
      .then(({ data }) => {
        setStatus(data.status);
        setPaymentOpen(false);
        setTransactionComplite(false);
        setTransactionOpen(true);
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
        enqueueSnackbar(t('errorMsg.getTarget'), { variant: 'error' });
        if (error.response?.status === 409) {
          // HTTP_409_CONFLICT товара нет на складе
          setError('EMPTY_PRODUCT');
        }
      })
      .finally(() => setLoading(false));
  };

  const transactionClose = () => {
    if (!isTransactionComplite) return false;
    onCloseHandler();
  };

  return (
    <>
      <PageTitle
        title={t('getTarget.title')}
        data-qa={'getTarget.title'}
        onClose={onCloseHandler}
        help={{
          tkey: 'getTarget',
        }}
        isLoading={isLoading}
      />
      <Box sx={{ p: ThemePaddings.page }}>
        {order ? <ShoppingCartControl order={order} onPayment={handlerPayment} /> : ''}
        {error && <EmptyData title={t(`getTarget.error.${error}.title`)} text={`getTarget.error.${error}.text`} />}
      </Box>

      {order && (
        <DrawerBottom isOpen={paymentOpen} onClose={() => false}>
          <WindowTitle
            sx={{ mb: '20px' }}
            title={t('getTarget.confirm.title')}
            onClose={() => setPaymentOpen(false)}
            isLoading={isLoading}
          />

          <Typography sx={{ mb: '16px' }}>{t('getTarget.warning')}</Typography>

          <ShoppingCartFees showFeeOnly={true} items={order.items} showTotal={false} />

          <PaymentCost
            sx={{
              '& .CostCalc_label': {
                fontSize: '1.062rem',
                fontWeight: 'bold',
                lineHeight: '36px',
              },
            }}
            tkey={'getTarget.confirm.total'}
            price={order.balance_change}
          />

          <Divider variant="fullWidth" sx={{ my: '25px' }} />
          <Box sx={{ zIndex: 1, position: 'relative' }}>
            <Button variant="outlined" onClick={() => confirmGet()} sx={{ width: '100%' }}>
              {t('getMoney.confirm.button')}
            </Button>
          </Box>
        </DrawerBottom>
      )}

      <PaymentTransactionLoader
        isOpen={transactionOpen}
        onClose={transactionClose}
        isComplite={isTransactionComplite}
        tkey="getTarget"
      />
    </>
  );
};

export default GetTarget;
