import { AxiosResponse } from 'axios';

import agent from 'src/agent';

import { AvatarInfo, AvatarGender, AvatarConfig } from './AvatarService.types';

const AVATAR_CONFIG_KEY = 'secret_edit_avatar_config';

export const getAvatarInfo = (avatarId?: string): Promise<AxiosResponse<AvatarInfo>> => {
  return agent.GET('nfts/avatar/read', { params: { id: avatarId } });
};

export const setAvatarGender = (gender: AvatarGender): Promise<AxiosResponse<AvatarInfo>> => {
  return agent.POST('nfts/avatar/update', { sex: gender });
};

const getAvatarConfig = (img: string): AvatarConfig => {
  const configStr = window.localStorage.getItem(AVATAR_CONFIG_KEY);

  let config_parses: AvatarConfig = {};
  try {
    config_parses = JSON.parse(configStr || '{}') as AvatarConfig;
  } catch (_err) {
    config_parses = {} as AvatarConfig;
  }

  return {
    background: config_parses.background || 0,
    body: config_parses.body || [],
    clothes: config_parses.clothes || [],
    eyes: config_parses.eyes || 0,
    hair: config_parses.hair || 0,
    img: img || '',
  };
};

export const setAvatarConfig = (img: string): Promise<AxiosResponse<AvatarInfo>> => {
  return agent.POST('nfts/avatar/update', { config: getAvatarConfig(img) });
};

export const getAvatarConf = () => {
  const configStr = window.localStorage.getItem(AVATAR_CONFIG_KEY);
  let config: { gender?: string; img?: string } = {};
  try {
    config = JSON.parse(configStr || '{}');
  } catch (_err) {
    config = {};
  }
  return config;
};

//  для замеса моков для демо
export const getAvatarInfoHalfMock = (): Promise<AxiosResponse<AvatarInfo>> => {
  return getAvatarInfo().then((res) => {
    const config = getAvatarConf();
    res.data.sex = config.gender || '';
    res.data.snapshot = {
      ...res.data.snapshot,
      ...{ preview: config.img || '' },
    };
    return res;
  });
};

export const setAvatarInfoHalfMock = (gender: string): void => {
  window.localStorage.setItem(
    AVATAR_CONFIG_KEY,
    JSON.stringify({
      gender,
    })
  );
};

export const deleteAvatarConfig = () => {
  window.localStorage.removeItem(AVATAR_CONFIG_KEY);
};
