import { Box } from '@mui/material';
import { FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { EmptyData } from 'src/atoms';
import { Paths } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
// import { useAppDispatch, useAppSelector } from 'src/hooks';
import { PageTitle } from 'src/molecules';
import { ShoppingCart as ShoppingCartControl } from 'src/organisms';
import { createPackOrder, TOrder } from 'src/services';
// import { selectClanState, setClanLevels, setCurrentLevel } from 'src/store/clanSlice';
import { ThemePaddings } from 'src/styles';
import { RouterItemIdCart } from 'src/types';
// import { checkNumberPrice } from 'src/utils';

const ShoppingCart: FC = () => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const { itemId } = useParams<RouterItemIdCart>();
  const { throwServerError } = useContext(ErrorContext);

  const [isLoading, setLoading] = useState(false);

  const [order, setOrder] = useState<TOrder>();
  const [error, setError] = useState<string>();

  const onCloseHandler = () => (window.history.length ? navigate(-1) : navigate(Paths.dashboard));

  const wrapperId = itemId ? Number(itemId) : undefined;
  /*
  const dispatch = useAppDispatch();
  const { clanLevels, level, haveClanIds } = useAppSelector(selectClanState);


  const [checked, setChecked] = useState<ClanLevel[]>([]);
  const [itemsWrappers, setItemsWrappers] = useState<number[]>([]);
  const [isOpen, setOpen] = useState(false);

  const onOpenPayment = () => {
    setOpen(true);
  };

  const onClosePayment = () => setOpen(false);

  useEffect(() => {
    if (!clanLevels) {
      setChecked([]);
      return;
    }

    const result = clanLevels.filter(
      (clanItem) =>
        (!clanItem.is_clan_open && !clanItem.have_clan && clanItem.tier <= level) ||
        (clanItem.have_clan && haveClanIds.includes(clanItem.id))
    );
    setChecked(result);
    const itemsWrappers = result.map((checkItem) => checkItem.item_wrapper.id);
    setItemsWrappers(itemsWrappers);
  }, [clanLevels, level, haveClanIds]);

  useEffect(() => {
    getDefaultOrderItems()
      .then(({ data }) => setDefaultOrderItems(data.items))
      .catch(() => setDefaultOrderItems([]));

    return () => {
      dispatch(setCurrentLevel(0));
      dispatch(setClanLevels(null));
      setChecked([]);
    };
  }, [dispatch]);

  const summary = checked.reduce(
    (acc, clanItem) => {
      const price = checkNumberPrice(clanItem.land_spawner.land_price);

      return { price: acc.price + price, commission: acc.commission + clanItem.fee };
    },
    { price: 0, commission: 0 }
  );

  const defaultOrderFees = defaultOrderItems.reduce((fee, orderItem) => fee + orderItem.fee, 0);
    */

  useEffect(() => {
    if (!wrapperId) return;
    setLoading(true);
    createPackOrder([wrapperId])
      .then(({ data }) => {
        setOrder(data);
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
        // enqueueSnackbar(t('errorMsg.getPackageOrder'), { variant: 'error' });
        if (error.response?.status === 409) {
          // HTTP_409_CONFLICT товара нет на складе
          setError('EMPTY_PRODUCT');
        }
        if (error.response?.status === 404) {
          // корзина пуста
          setError('EMPTY');
        }
      })
      .finally(() => setLoading(false));
  }, [throwServerError, wrapperId]);

  return (
    <>
      <PageTitle
        title={t('shoppingCart.title')}
        data-qa={'shoppingCart.title'}
        onClose={onCloseHandler}
        help={{
          tkey: 'shoppingCart',
        }}
        isLoading={isLoading}
      />
      <Box sx={{ p: ThemePaddings.page }}>
        {order && wrapperId ? <ShoppingCartControl order={order} wrapperId={wrapperId} /> : ''}
        {error && (
          <EmptyData title={t(`shoppingCart.error.${error}.title`)} text={`shoppingCart.error.${error}.text`} />
        )}
      </Box>
    </>
  );
};

export default ShoppingCart;
