import CloseIcon from '@mui/icons-material/Close';
import { Box, Checkbox, IconButton, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BPrice, InputCounter } from 'src/atoms';
import { getImgSrc } from 'src/constants';
import { ThemeColors } from 'src/styles';

import {
  ProductListWrapper,
  Product,
  ProductImage,
  ProductInfo,
  Name,
  PriceTitle,
  ProductBox,
  Description,
} from './ProductsList.styles';
import { ProductsListProps } from './ProductsList.types';

const ProductsList: FC<ProductsListProps> = ({ items, accentColor, onChange, onDelete, selectedType }) => {
  const { t } = useTranslation('app');
  const itemsList = items;
  const handlerChange = () => {
    onChange && onChange([...itemsList]);
  };

  const handlerCheck = (id: number, checked: boolean) => {
    let isBefore = true;
    const _checked = selectedType === 'linear' ? true : checked;
    const _count = _checked ? 1 : 0;

    itemsList.map((item) => {
      if (item.id === id) {
        item.count = _count;
        isBefore = false;
      } else {
        if (selectedType === 'linear') {
          item.count = isBefore ? item.count || _count : 0;
        }
      }
      return item;
    });
  };

  const _accentColor = accentColor || 'currentColor';

  return (
    <ProductListWrapper className="ProductList_root">
      {itemsList.length > 0 &&
        itemsList.map((item, idx, arr) => (
          <ProductBox key={`product_${item.item_type}_${idx}`} sx={{ paddingLeft: selectedType ? '0px' : '16px' }}>
            <Product className={item.count ? '' : 'ProductList_product__disabled'}>
              {selectedType && (
                <Stack justifyContent="center">
                  <Checkbox
                    checked={(item.count || 0) > 0}
                    onChange={(event) => {
                      handlerCheck(item.id, event.target.checked);
                      handlerChange();
                    }}
                  />
                </Stack>
              )}
              <ProductImage>
                <img src={getImgSrc(item.package.picture.uuid, 's72-c')} alt={t(`orderType.${item.item_type}`)} />
              </ProductImage>
              <ProductInfo>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Name>
                    {/* [{t(`orderType.${item.item_type}`)}]{' '}*/}
                    <Box component="span" sx={{ color: _accentColor }}>
                      {t(item.package.title)}
                    </Box>
                  </Name>
                  {onDelete && (
                    <IconButton
                      data-qa="shoppingCart.button.delete"
                      sx={{ alignSelf: 'center', pointerEvents: 'auto', mt: '0px', p: 0, color: ThemeColors.gray500 }}
                      onClick={() => onDelete(item)}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Stack>
                <Description sx={{ flex: 1, alignSelf: 'flex-start' }}>{t(item.package.short)}</Description>
                <Stack direction="row" spacing={1} alignItems="baseline" justifyContent="space-between">
                  <PriceTitle sx={{ color: _accentColor }}>{t('shoppingCart.price')}</PriceTitle>
                  <BPrice sx={{ fontSize: '0.75rem' }} price={item.pay_total} />
                </Stack>
              </ProductInfo>
            </Product>
            {item.count && item.package.can_buy_count && item.package.can_buy_count > 1 ? (
              <Stack sx={{ mb: 1, mt: 2 }} direction="row" spacing={1} alignItems="center">
                <Name sx={{ flex: 1 }}>{t('shoppingCart.quantity')}</Name>
                <InputCounter
                  value={item.count || 1}
                  onChange={(val) => {
                    item.count = val;
                    handlerChange();
                  }}
                  min={1}
                  max={item.package.can_buy_count}
                />
              </Stack>
            ) : null}
          </ProductBox>
        ))}
    </ProductListWrapper>
  );
};

export default ProductsList;
