import { StorageKeys } from 'src/constants';
import { Gender } from 'src/services';
import { getItemLS, setItemLS, logger } from 'src/utils';

export interface IDemoUserData {
  gender: Gender;
  displayName: string;
  birthday: string;
  email: string;
  photo: string;
}

const isDemoUserData = (d: any): d is IDemoUserData => {
  // TODO: сделать нормальный typeguard потом
  // if (!('gender' in d) || !ALL_GENDERS.includes(d.gender)) return false;
  return !!d;
};

export const getDemoUserData = () => {
  try {
    const dataJson = getItemLS(StorageKeys.DEMO_USER_DATA);
    if (!dataJson) return null;
    const data = JSON.parse(dataJson);
    return isDemoUserData(data) ? data : null;
  } catch (error) {
    logger.error(error);
    return null;
  }
};

/**
 * @returns возвращает null, если все прошло нормально. Вернет объект ошибки, если вылетело
 */
export const setDemoUserData = (newData: IDemoUserData) => {
  try {
    const json = JSON.stringify(newData);
    setItemLS(StorageKeys.DEMO_USER_DATA, json);
  } catch (error) {
    return error;
  }
};
