import { Grid } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Markdown } from 'src/atoms';

const MDPage: FC<{ tkey: string }> = ({ tkey }) => {
  const { t } = useTranslation('app');

  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid item xs={12} data-qu={`${tkey}.descriptionMD`}>
        <Markdown>{t(`${tkey}.descriptionMD`)}</Markdown>
      </Grid>
    </Grid>
  );
};

export default MDPage;
