import { Box } from '@mui/material';
import { CarouselProvider, Slider, Slide, CarouselContext, DotGroup, Dot } from 'pure-react-carousel';
import { FC, useContext, useEffect } from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { CarouselProps } from './Carousel.types';

const CarouselSlider: FC<{ children: CarouselProps['children']; onChange?: CarouselProps['onChange'] }> = ({
  children,
  onChange,
}) => {
  const carouselContext = useContext(CarouselContext);
  useEffect(() => {
    if (!onChange) return;

    function handlerChange() {
      if (!onChange) return;
      onChange(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(handlerChange);
    return () => carouselContext.unsubscribe(handlerChange);
  }, [carouselContext, onChange]);

  return (
    <>
      <Slider className="Carousel_slider">
        {children?.map((widget, idx) => (
          <Slide className="Carousel_slide" key={`Carousel_Slider__${idx}`} index={idx}>
            {widget}
          </Slide>
        ))}
      </Slider>
      <DotGroup renderDots={() => null} className="Carousel_dotGroup">
        {children?.map((widget, idx) => (
          <Dot className="Carousel_dot" key={`Carousel_Dots__${idx}`} slide={idx} />
        ))}
      </DotGroup>
    </>
  );
};

const Carousel: FC<CarouselProps> = ({
  height: heightProp,
  width: widthProp,
  visibleSlides,
  onChange,
  contrast,
  children,
  sx,
}) => {
  const steps = children?.length || 0;

  return (
    <Box
      className="Carousel_root"
      sx={{
        backgroundColor: contrast ? 'background.contrast' : 'background.default',
        position: 'relative',
        userSelect: 'none',
        height: heightProp + 32 + 'px',
        width: '100%',
        // py: '16px',

        '& .Carousel_slider': {
          padding: '16px',
        },
        '& .Carousel_slide .carousel__inner-slide': {
          padding: '0 2px',
        },

        '& .Carousel_dotGroup': {
          display: 'flex',
          direction: 'row',
          justifyContent: 'space-around',
          alignContent: 'center',
          flexGrow: 1,
          position: 'absolute',
          bottom: '5px',
          width: '100%',
          padding: '0 30%',
        },

        '& .Carousel_dot': {
          border: 0,
          margin: '0 2px',
          height: '4px',
          cursor: 'pointer',
          minWidth: '4px',
          borderRadius: '4px',
          backgroundColor: contrast ? 'background.paper' : 'background.contrast',
          flex: 1,
          opacity: 0.5,
        },
        '& .Carousel_dot.carousel__dot--selected': {
          opacity: 1,
        },
        ...sx,
      }}
    >
      <CarouselProvider
        infinite={true}
        totalSlides={steps}
        naturalSlideHeight={heightProp}
        naturalSlideWidth={widthProp || 600}
        visibleSlides={visibleSlides || 1}
        isIntrinsicHeight={true}
        touchEnabled={true}
      >
        <CarouselSlider children={children} onChange={onChange} />
      </CarouselProvider>
    </Box>
  );
};

export default Carousel;
