import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ThemeColors } from 'src/styles';

const EmptyShoppingCart: FC = () => {
  const { t } = useTranslation('app');
  return (
    <Stack justifyContent="center" alignItems="center" sx={{ height: '260px' }}>
      <Typography component="p" variant="h1" sx={{ mt: 0, mb: '16px' }}>
        {t('shoppingCart.emptyTitle')}
      </Typography>
      <Typography
        component="p"
        sx={{ fontSize: '0.812rem', lineHeight: '18px', fontWeight: 600, color: ThemeColors.gray500 }}
      >
        {t('shoppingCart.emptyText')}
      </Typography>
    </Stack>
  );
};

export default EmptyShoppingCart;
