import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '16px',
  padding: 6,
  height: 78,
  cursor: 'pointer',
  marginBottom: 11,
  backgroundColor: theme.palette.background.paper,
  filter: 'drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.1))',
}));

export const InfoBlock = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexBasis: '100%',
  height: '100%',
  overflow: 'hidden',
}));

export const Statistic = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexBasis: 60,
  marginLeft: 6,
  marginRight: 6,
}));

export const Name = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  lineHeight: '16px',
  fontWeight: 600,
  textTransform: 'uppercase',
  color: theme.palette.primary.main,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
}));

export const Country = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '14px',
  color: theme.palette.secondary.main,
}));
export const Address = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '24px',
  color: theme.palette.primary.main,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
}));

export const DateRegistration = styled(Typography)(({ theme }) => ({
  fontSize: '0.625rem',
  lineHeight: '13px',
  color: theme.palette.secondary.main,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
}));

export const Label = styled(Typography)(({ theme }) => ({
  fontSize: '0.625rem',
  lineHeight: '12px',
  color: theme.palette.secondary.main,
}));

export const Count = styled(Typography)(() => ({
  fontSize: '0.75rem',
  lineHeight: '14px',
  fontWeight: 600,
}));
