export const formatDate = (str: string): string => {
  if (!str) {
    throw new Error('Invalid Date string');
  }
  const date = new Date(str);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day < 10 ? `0${day}` : day}.${month < 10 ? `0${month}` : month}.${year}`;
};

export const formatTime = (str: string): string => {
  if (!str) {
    throw new Error('Invalid Date string');
  }

  const date = new Date(str);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
};

export const formatTimeSeconds = (seconds: number) => {
  if (!seconds) {
    return '00:00';
  }

  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, '0');

  if (hh) {
    return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
  } else {
    return `${mm}:${ss}`;
  }
};

export const isToday = (str: string): boolean => {
  if (!str) {
    throw new Error('Invalid Date string');
  }

  const incomingDate = new Date(str).toISOString().slice(0, 10);
  const currentDate = new Date().toISOString().slice(0, 10);

  return incomingDate === currentDate;
};
