import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IosShareIcon from '@mui/icons-material/IosShare';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { QRCodeSVG } from 'qrcode.react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// import { QRLogo } from 'src/assets/icons';
import { DrawerBottom } from 'src/atoms';
import { copyToClipboard } from 'src/utils';

import {
  QRCodeBox,
  YourReferralInfo,
  YourReferralInfoGradient,
  ReferralLink,
  ReferralTitle,
  ReferralText,
} from './DrawerShareText.styles';
import { DrawerShareTextProps } from './DrawerShareText.types';

const DrawerShareText: FC<DrawerShareTextProps> = ({ title, link, shareText, tKey, isOpen, onClose }) => {
  const { t } = useTranslation('app');
  const { enqueueSnackbar } = useSnackbar();

  const userShare = {
    title: title,
    text: shareText || t(`${tKey}.share.shareText`),
    url: link,
  };

  const isSupportedShare = !!navigator?.share;

  const onCopyHandler = () => {
    copyToClipboard(link);
    enqueueSnackbar(t('button.copied'), { variant: 'info' });
  };

  const onShareHandler = async () => {
    if (isSupportedShare) {
      await navigator?.share(userShare);
    }
  };

  return (
    <DrawerBottom isOpen={isOpen} onClose={onClose}>
      <QRCodeBox>
        <QRCodeSVG
          value={link}
          size={160}
          // imageSettings={{ src: QRLogo, height: 50, width: 50, excavate: false }}
        />
      </QRCodeBox>
      <Stack direction="row" justifyContent={isSupportedShare ? 'space-between' : 'flex-end'}>
        {isSupportedShare && (
          <IconButton aria-label="share" onClick={onShareHandler}>
            <IosShareIcon />
          </IconButton>
        )}
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <YourReferralInfoGradient>
        <YourReferralInfo>
          <Box>
            <ReferralTitle>{t(`${tKey}.share.title`)}</ReferralTitle>
            <ReferralLink>{link}</ReferralLink>
          </Box>
          <IconButton aria-label="copy" onClick={onCopyHandler}>
            <ContentCopyIcon />
          </IconButton>
        </YourReferralInfo>
      </YourReferralInfoGradient>
      <ReferralText>{t(`${tKey}.share.text`)}</ReferralText>
      {isSupportedShare && (
        <Box sx={{ position: 'relative', zIndex: 1, mt: '16px' }}>
          <Button variant="outlined" fullWidth aria-label="share" onClick={onShareHandler}>
            {t('button.share')}
          </Button>
        </Box>
      )}
    </DrawerBottom>
  );
};

export default DrawerShareText;
