import { Avatar, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Marker, MarkerProps } from 'react-map-gl';

import { PinMarker } from 'src/atoms';
import { ThemeColors } from 'src/styles';

import { GlobeMarkerProps } from './GlobeMarker.types';

const GlobeMarker: FC<GlobeMarkerProps> = ({
  longitude,
  latitude,
  isCount,
  count,
  photo,
  isCluster,
  status,
  pointCount,
  isPin,
  onClick,
}) => {
  const { t } = useTranslation('app');
  const counter = pointCount || count || 0;
  const sizeNumber = counter.toString().length;
  const sizeCircle = counter > 10 ? 18 * sizeNumber - 10 : 18;

  const circleBorder =
    t(`goal.state.color.${status}`) === `goal.state.color.${status}`
      ? ThemeColors.white
      : t(`goal.state.color.${status}`);
  interface MarkerPropsExt extends MarkerProps {
    rotationAlignment?: any;
  }

  const mProps: MarkerPropsExt = {
    longitude,
    latitude,
    onClick,
  };

  if (isPin) {
    mProps.rotationAlignment = 'horizon';
    mProps.offset = [0, -42 / 2];
  }

  return (
    <Marker {...mProps} style={{ zIndex: isPin ? 2 : status ? 1 : 0 }}>
      {isCluster ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            width: sizeCircle,
            height: sizeCircle,
            borderRadius: '100%',
            backgroundColor: ThemeColors.white,
            color: ThemeColors.black,
            border: `1px solid ${circleBorder}`,
            boxShadow: `1px 1px 5px ${ThemeColors.black}`,
            zIndex: 0,
          }}
        >
          {pointCount}
        </Stack>
      ) : isCount ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            width: sizeCircle,
            height: sizeCircle,
            borderRadius: '100%',
            backgroundColor: ThemeColors.white,
            color: ThemeColors.black,
            border: `1px solid ${circleBorder}`,
            boxShadow: `1px 1px 5px ${ThemeColors.black}`,
            zIndex: 0,
          }}
        >
          {count}
        </Stack>
      ) : isPin ? (
        <PinMarker photo={photo} size={42} sx={{ zIndex: 1 }} color={circleBorder} />
      ) : (
        <Avatar
          src={photo}
          alt=""
          sx={{
            zIndex: 0,
            width: 32,
            height: 32,
            border: status ? '2px solid' : '1px solid',
            borderColor: circleBorder,
          }}
        />
      )}
    </Marker>
  );
};

export default GlobeMarker;
