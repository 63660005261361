import HistoryIcon from '@mui/icons-material/History';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'src/hooks';
import { ClanStatus } from 'src/services';
import { selectClanState, setSelectedClan } from 'src/store/clanSlice';
import { LEVELS_TO_COLORS } from 'src/styles';
import { Sizes } from 'src/types';
import { formatDate } from 'src/utils';

import Triangle from '../Triangle';

import { HistoryItemProps } from './HistoryItem.types';

const HistoryItem: FC<HistoryItemProps> = ({ clanItem, level, onPaymentsOpen }) => {
  const { t } = useTranslation('app');
  const { activeClanId } = useAppSelector(selectClanState);
  const dispatch = useAppDispatch();

  const onSelectedClanHandler = () => {
    dispatch(setSelectedClan(clanItem));
  };

  const onPaymentHandler = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onPaymentsOpen(clanItem.id);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        height: '100%',
        py: '16px',
        borderTop: '1px solid',
        borderColor: 'divider',
        backgroundColor: clanItem.id === activeClanId ? 'background.paper' : 'background.default',
      }}
      onClick={onSelectedClanHandler}
    >
      <Box sx={{ flexBasis: 64, mx: '16px' }}>
        <Triangle size={Sizes.Small} level={level} isFilled={clanItem.status === ClanStatus.Open} data={clanItem} />
      </Box>
      <Box sx={{ flexBasis: '100%' }}>
        <Typography sx={{ fontSize: '0.75rem', lineHeight: '16px', fontWeight: 400, mb: '10px' }}>
          {t('structure.status')}:
          <b>
            {clanItem.status === ClanStatus.Open
              ? ` ${t('structure.statusOpen')}`
              : ` ${t('structure.statusClose')} ${clanItem.closed_at && formatDate(clanItem.closed_at)}`}
          </b>
        </Typography>
        <Typography sx={{ fontSize: '0.75rem', lineHeight: '16px', fontWeight: 400 }}>
          {t('structure.openingDate')}: <b>{clanItem.created_at && formatDate(clanItem.created_at)}</b>
        </Typography>
      </Box>
      <Button
        onClick={onPaymentHandler}
        sx={{
          border: 0,
          flexDirection: 'column',
          px: '5px',
          fontSize: '0.75rem',
          lineHeight: '16px',
          fontWeight: 400,
          mx: '16px',
          color: LEVELS_TO_COLORS[level - 1],
          backgroundColor: 'transparent',

          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <HistoryIcon sx={{ width: '24px', height: '24px' }} />
        {t('button.payments')}
      </Button>
    </Stack>
  );
};

export default HistoryItem;
