import { Fab } from '@mui/material';
import { FC, Children, useMemo, cloneElement } from 'react';

import { ArrowBackIcon, ArrowDownIcon } from 'src/assets/icons';

import { FabMenuBlock, FabMenuName } from './FabMenu.styles';
import { FabMenuProps } from './FabMenu.types';

const findRoot = (children: FabMenuProps['children'], value?: string) => {
  let root;
  if (value === undefined) return root;

  if (children && children.length > 0) {
    const selectedChild = children.find((child) => child.props.value === value);
    if (selectedChild) root = selectedChild;
    else {
      children?.forEach((child) => {
        if (child?.props.children) {
          const finded = findRoot(child?.props.children, value);
          if (finded) root = child;
        }
      });
    }
  }

  return root;
};

const FabMenu: FC<FabMenuProps> = ({ children, spacing, value, showNames, sx, onClear }) => {
  const root = useMemo(() => !!value && findRoot(children, value), [children, value]);

  return (
    <FabMenuBlock spacing={spacing || 2} sx={sx}>
      {root && (
        <>
          <Fab onClick={onClear} size="small" variant="contrast">
            <ArrowBackIcon />
          </Fab>

          {cloneElement(root, {
            selected: true,
          })}
          <FabMenuName>{root.props.name}</FabMenuName>
          {root.props.children && <ArrowDownIcon />}
        </>
      )}
      {Children.map(root ? root.props.children : children, (child) => {
        return (
          <>
            {child &&
              cloneElement(child, {
                selected: child.props.value === value,
                size: root ? 'small' : undefined,
              })}
            {showNames && <FabMenuName>{child.props.name}</FabMenuName>}
          </>
        );
      })}
    </FabMenuBlock>
  );
};

export default FabMenu;
