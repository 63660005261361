import { along, distance } from '@turf/turf';
import { dequal } from 'dequal';
import { Feature, LineString } from 'geojson';
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Layer, Source } from 'react-map-gl';

import { ClusterValue } from 'src/hooks/useSupercluster/useSupercluster.types';

import { GlobeLinesProps } from './GlobeLines.types';

const STEPS = 80;
const COLORS = ['#FBDA30', '#377BFF', '#47DEFF', '#9B37FF', '#FF37F7', '#FF5E85'];

function getRoutes(
  origin: ClusterValue,
  destinations: ClusterValue[],
  withCoordinates?: boolean
): Feature<LineString>[] {
  return destinations.map((item) => {
    return {
      type: 'Feature',
      id: `${origin.properties.id}_${item.properties.id}`,
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: withCoordinates ? [origin.geometry.coordinates, item.geometry.coordinates] : [],
      },
    };
  });
}

const GlobeLines: FC<GlobeLinesProps> = (props) => {
  const { origin, destinations } = props;
  const [features, setFeatures] = useState<Feature<LineString>[]>(() => getRoutes(origin, destinations));

  const routesCoordinates = useMemo(() => {
    const routes = getRoutes(origin, destinations, true);
    const out = routes.map((route) => {
      const lineDistance = distance(origin.geometry.coordinates, route.geometry.coordinates[1] ?? []);
      const arc = [];

      for (let i = 0; i <= lineDistance; i += lineDistance / STEPS) {
        const segment = along(route, i);
        arc.push(segment.geometry.coordinates);
      }

      return arc;
    });

    return out;
  }, [destinations, origin]);

  const counter = useRef(0);
  const isRevert = useRef(false);
  const animation = useRef<number>();
  const color = useMemo(() => {
    const idx = Math.ceil(Math.random() * COLORS.length);
    return COLORS[idx] || COLORS[0];
  }, []);

  const animateLine = useCallback(() => {
    if (isRevert.current) {
      if (counter.current <= 0) {
        isRevert.current = false;
        setFeatures((prev) => {
          return prev.map((item) => {
            return {
              ...item,
              geometry: { ...item.geometry, coordinates: [] },
            };
          });
        });
      } else {
        setFeatures((prev) => {
          return prev.map((item) => {
            const [, ...coordinates] = item.geometry.coordinates;
            return {
              ...item,
              geometry: {
                ...item.geometry,
                coordinates,
              },
            };
          });
        });
        counter.current = counter.current - 1;
      }
    } else {
      if (counter.current >= STEPS - 1) {
        isRevert.current = true;
      } else {
        setFeatures((prev) => {
          return prev.map((item, idx) => {
            const nextCoordinates = routesCoordinates[idx]?.[counter.current];

            if (nextCoordinates) {
              return {
                ...item,
                geometry: {
                  ...item.geometry,
                  coordinates: [...item.geometry.coordinates, nextCoordinates],
                },
              };
            }

            return item;
          });
        });
        counter.current = counter.current + 1;
      }
    }

    animation.current = requestAnimationFrame(animateLine);
  }, [routesCoordinates]);

  useEffect(() => {
    setFeatures(getRoutes(origin, destinations));
    counter.current = 0;
    isRevert.current = false;
  }, [destinations, origin]);

  useEffect(() => {
    animateLine();

    return () => {
      if (animation.current) {
        cancelAnimationFrame(animation.current);
      }
    };
  }, [animateLine]);

  return (
    <Source type="geojson" data={{ type: 'FeatureCollection', features }}>
      <Layer
        type="line"
        maxzoom={4}
        minzoom={0}
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        paint={{
          'line-width': ['interpolate', ['linear'], ['zoom'], 0, 3, 4, 1],
          'line-opacity': ['interpolate', ['linear'], ['zoom'], 3, 0.8, 4, 0],
          'line-color': color,
        }}
      />
    </Source>
  );
};

export default memo(GlobeLines, (prev, next) => {
  return dequal(prev, next);
});
