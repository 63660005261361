import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack, Typography, LinearProgress, Box } from '@mui/material';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Paths } from 'src/constants';
import { ThemeContext } from 'src/context';
import { HelpButtonDrawer } from 'src/organisms';
import { ThemeColors, ThemePaddings } from 'src/styles';

import { PageTitleProps } from './PageTitle.types';

const PageTitle: FC<PageTitleProps> = ({
  title,
  subtitle,
  isLoading,
  help,
  onClose,
  isTransparent,
  children,
  sx,
  ...rest
}) => {
  const { isDemo } = useContext(ThemeContext);
  const navigate = useNavigate();

  const titleClick = () => {
    navigate(Paths.dashboard);
  };

  return (
    <>
      <Box
        sx={{
          minHeight: '52px',
          pointerEvents: 'none',
          top: 0,
          left: 0,
          right: 0,
          position: 'fixed',
          zIndex: 20,
          backgroundColor: isTransparent || isDemo ? 'transparent' : (theme) => theme.palette.background.default,
          ...sx,
        }}
      >
        <Stack
          sx={{
            position: 'relative',
            maxWidth: '600px',
            width: '100%',
            margin: '0 auto',
            height: '100px',
            px: ThemePaddings.pageX,
            ...(sx || {}),
          }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center">
            <Typography
              data-qa={rest['data-qa']}
              onClick={titleClick}
              sx={{ textTransform: 'uppercase', cursor: 'pointer', pointerEvents: 'auto' }}
              variant="h1"
              noWrap
            >
              {title}
            </Typography>
            {help && <HelpButtonDrawer {...help} sx={{ pointerEvents: 'auto' }} />}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            {children}
            {onClose && (
              <IconButton
                data-qa="title.button.close"
                sx={{ alignSelf: 'center', pointerEvents: 'auto', color: ThemeColors.gray500 }}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
          {isLoading && <LinearProgress sx={{ position: 'absolute', marginLeft: 0, left: 0, top: 0, width: '100%' }} />}
          {subtitle && (
            <Typography
              sx={{
                position: 'absolute',
                textTransform: 'uppercase',
                fontSize: '0.812rem',
                px: ThemePaddings.pageX,
                bottom: 10,
                left: 0,
                pointerEvents: 'auto',
                color: (theme) => theme.palette.secondary.main,
              }}
            >
              {subtitle}
            </Typography>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default PageTitle;
