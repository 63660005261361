import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

export const Block = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'inherit',
  '& .nextButton': {
    marginTop: '16px',
    flexShrink: 0,
  },
}));

export const StepBlock = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1',
}));

export const StepTitle = styled(Typography)(() => ({}));

export const StepText = styled(Typography)(() => ({
  fontSize: '12px',
  lineHeight: '16px',
  color: ThemeColors.gray500,
}));

export const StepList = styled('ul')(() => ({
  fontSize: '12px',
  color: ThemeColors.gray500,
  margin: 0,
  '& li': {
    marginBottom: '1em',
  },
}));

export const StepImg = styled('img')(() => ({}));
