import { Instagram, Telegram, YouTube, IosShare } from '@mui/icons-material';
import { Box, Stack, IconButton } from '@mui/material';
import { FC, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Paths, pathsArg } from 'src/constants';
import { DrawerShareText } from 'src/molecules';
import { formatDate } from 'src/utils';

import { ProfileBox, PublicAddress, AvatarBox, ProfileAvatar, DisplayName, DateReg } from './YourProfileWidget.styles';
import { YourProfileWidgetProps } from './YourProfileWidget.types';

const _icon: { [key: string]: any } = {
  instagram: Instagram,
  telegram: Telegram,
  youtube: YouTube,
};

const YourProfileWidget: FC<YourProfileWidgetProps> = ({
  displayName,
  photo,
  dateJoined,
  publicAddress,
  publicAddressType,
  user_id,
}) => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const [share, setShare] = useState(false);

  const onProfileHandler = () => {
    navigate(Paths.profile);
  };

  const handlerShare = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    // setShare(true);
    navigate(pathsArg(Paths.profileUser, { id: user_id }));
  };

  const IconComponent = publicAddressType ? _icon[publicAddressType] : null;

  return (
    <>
      <ProfileBox onClick={onProfileHandler}>
        {/* <ProfileTitle>{t('dashboard.yourProfile')}</ProfileTitle> */}
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <AvatarBox>
            <ProfileAvatar alt={displayName} src={photo} />
          </AvatarBox>
          <Box sx={{ overflow: 'hidden', width: '100%' }}>
            <DisplayName>{displayName}</DisplayName>
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              {IconComponent && <IconComponent sx={{ fontSize: '1rem' }} />}
              <PublicAddress>{publicAddress}</PublicAddress>
            </Stack>
            <DateReg>
              {t('dashboard.regDate')}: {formatDate(dateJoined)}
            </DateReg>
          </Box>
          <IconButton onClick={handlerShare}>
            <IosShare />
          </IconButton>
        </Stack>
      </ProfileBox>

      {publicAddress && (
        <DrawerShareText
          title={displayName || t('onboarding.unknownName')}
          link={publicAddress}
          isOpen={share}
          tKey={`profile${publicAddressType}`}
          onClose={() => setShare(false)}
        />
      )}
    </>
  );
};

export default YourProfileWidget;
