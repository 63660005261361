import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Switch2Links } from 'src/atoms';
import { Paths, isMarket } from 'src/constants';
import { TeamFilterContextProvider } from 'src/context';
import { PageTitle } from 'src/molecules';
import { ThemePaddings } from 'src/styles';

import { getSwitchLinksForTeam } from './Team.utils';

const Team: FC = () => {
  const { t } = useTranslation('app');
  const { pathname } = useLocation();
  localStorage.setItem('teamLastPath', pathname);

  const navigate = useNavigate();
  const onCloseHandler = () => (window.history.length ? navigate(-1) : navigate(Paths.dashboard));

  const tkey = pathname === Paths.teamTree ? 'teamTree' : pathname === Paths.teamGlobe ? 'teamGlobe' : 'team';

  return (
    <>
      <PageTitle
        title={t('team.title')}
        data-qa={'team.title'}
        help={{ tkey }}
        isTransparent
        onClose={onCloseHandler}
      />
      <Box sx={{ p: ThemePaddings.teamTemplate, position: 'relative', zIndex: 1, pointerEvents: 'none' }}>
        <Switch2Links currentPath={pathname} links={getSwitchLinksForTeam(t, pathname)} />
      </Box>
      <TeamFilterContextProvider>
        <Outlet />
      </TeamFilterContextProvider>
    </>
  );
};

export default Team;
