import axios from 'axios';

import { getApiUrl, Paths } from 'src/constants';
import { getLocale, getToken, hasToken, removeToken } from 'src/utils';

const agent = axios.create({
  baseURL: getApiUrl(),
  headers: {
    'content-type': 'application/json',
    'Accept-Language': getLocale(),
  },
});

agent.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error.response ? error.response.status : 408;

    if (status >= 500) {
      window.console.error(error.toString());
    }

    if (status >= 502) {
      window.console.error(error.toString());
    }

    if (status === 401) {
      removeToken();
      window.location.href = Paths.home;
    }

    return Promise.reject(error);
  }
);

agent.interceptors.request.use(
  (config) => {
    const updConfig = { ...config };

    if (hasToken() && updConfig.headers) {
      updConfig.headers.Authorization = `Bearer ${getToken().access}`;
    }

    if (updConfig.url?.length && !updConfig.url.includes('?') && updConfig.url[updConfig.url.length - 1] !== '/') {
      updConfig.url += '/';
    }

    return updConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default agent;
