import { Box } from '@mui/material';
import { FC, useState } from 'react';

import { MaterialsCarousel } from 'src/molecules';

import { CarouselTabs } from './MaterialsCarouselTabs.styles';
import { MaterialsCarouselTabsProps } from './MaterialsCarouselTabs.types';

const MaterialsCarouselTabs: FC<MaterialsCarouselTabsProps> = ({ items, selected, onChange, children, tab }) => {
  const [selectedTab, setSelectedTab] = useState<number>(tab || 0);
  const [selectedItem, setSelectedItem] = useState<string | undefined>(selected);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    onChange(newValue, selectedItem);
  };

  const handleSelected = (id: string | undefined) => {
    setSelectedItem(id);
    onChange(selectedTab, id);
  };

  return (
    <Box>
      <MaterialsCarousel items={items} selected={selectedItem} onSelect={(id) => handleSelected(id)} />
      <CarouselTabs value={selectedTab} onChange={handleChange}>
        {children}
      </CarouselTabs>
    </Box>
  );
};

export default MaterialsCarouselTabs;
