import { Stack } from '@mui/material';
import { FC } from 'react';

import { DateRowProps } from './DateRow.types';

const DateRow: FC<DateRowProps> = ({ children, height }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      sx={{
        height,
        fontSize: '0.75rem',

        '&:before, &:after': {
          content: '""',
          display: 'block',
          backgroundColor: 'divider',
          width: '35%',
          height: '1px',
        },
      }}
    >
      {children}
    </Stack>
  );
};

export default DateRow;
