import { Box, Button } from '@mui/material';
import { cellToLatLng, latLngToCell, getResolution } from 'h3-js';
import { GeoJSONSourceOptions } from 'mapbox-gl';
import { FC, useState, useRef, useEffect, useCallback } from 'react';
import MapGL, { Layer, LayerProps, Source } from 'react-map-gl';

import { MAPBOX_TOKEN, isDev } from 'src/constants';
import { MapboxPinMarker } from 'src/molecules';
import { MapboxPinMarkerData } from 'src/molecules/MapboxPinMarker/MapboxPinMarker.types';
import { ThemeColors } from 'src/styles';
import { hexToRgbArr, logger } from 'src/utils';
import { GenerateHexGeoJson } from 'src/utils/layers/h3-datagen';

import { MapboxHexProps } from './MapboxHex.types';

import 'mapbox-gl/dist/mapbox-gl.css';

const h3listKey = 'hexMapList';
const _getDevHexList = () => {
  const h3list = window.localStorage.getItem(h3listKey);
  if (h3list) {
    return JSON.parse(h3list);
  } else {
    return [];
  }
};

const _appendDevHexList = (h3idx: string) => {
  const oldLength = _getDevHexList().length;
  let h3List = _removeDevHexList(h3idx);
  if (oldLength === h3List.length) {
    h3List.push({ index: h3idx, level: 1, status: 'SELECTDEV', title: null });
  }
  window.localStorage.setItem(h3listKey, JSON.stringify(h3List));
  return h3List;
};

const _removeDevHexList = (h3idx: string) => {
  let h3List = _getDevHexList();
  h3List = h3List.filter((idx: { index: string }) => idx.index !== h3idx);
  window.localStorage.setItem(h3listKey, JSON.stringify(h3List));
  return h3List;
};

const _getDevMode = () => {
  const devmode = new URLSearchParams(window.location.search).get('devmode');
  return devmode !== null;
};

const MapboxHexLayer: FC<{ id: string; source: string }> = ({ id, source }) => {
  return (
    <Layer
      {...({
        id: id,
        source: source,
        type: 'fill',
        maxzoom: 9,
        minzoom: 0,
        tolerance: 0.9,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'fill-outline-color': ThemeColors.white,
          //
          //"fill-color": ["case", [">", ["get", "count"], 0], ["case", ['boolean', ['feature-state', 'hover'], false], "#FFFF00", "#FFD700"], ["case", ['boolean', ['feature-state', 'hover'], false], "#FFD700", "#333"]],
          'fill-color': [
            'case',
            ['boolean', ['feature-state', 'select'], false],
            hexToRgbArr(ThemeColors.blueLight),
            ['boolean', ['get', 'aviable'], true],
            hexToRgbArr(ThemeColors.grayLight),
            ['boolean', ['get', 'busy'], true],
            hexToRgbArr(ThemeColors.purple),
            ['boolean', ['get', 'freeunaviable'], true],
            hexToRgbArr(ThemeColors.gray),
            ['boolean', ['get', 'selectdev'], true],
            hexToRgbArr(ThemeColors.red),
            hexToRgbArr(ThemeColors.grayDark, 0.8),
          ],
          //"fill-opacity": ["case", ['boolean', ['feature-state', 'select'], false], 0, 1],
          'fill-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 9, 0],
        },
      } as LayerProps)}
    />
  );
};

const MapboxHex: FC<MapboxHexProps> = ({ data, onClick }) => {
  const [h3Data, setH3Data] = useState<GeoJSONSourceOptions['data']>();
  const [markersList, setMarkersList] = useState<MapboxPinMarkerData[]>([]);

  const [h3DataDev, setH3DataDev] = useState<GeoJSONSourceOptions['data']>();

  const _isDev = _getDevMode() && isDev;

  const mapRef = useRef(null);

  useEffect(() => {
    if (data) {
      setH3Data(GenerateHexGeoJson(data));
    }

    if (_isDev) {
      const h3devList = _getDevHexList();
      if (h3devList) {
        setH3DataDev(GenerateHexGeoJson(h3devList));
      }
    }
  }, [data, _isDev]);

  const handlerGetDevList = () => {
    const h3devList = _getDevHexList();
    logger.debug(h3devList.map((d: any) => d.index));
  };

  useEffect(() => {
    /* маркеры на ножках для своих гексов */
    if (!data) return;
    const markers: MapboxPinMarkerData[] = [];

    data.forEach((hexItem) => {
      const coord = cellToLatLng(hexItem.index);
      if (hexItem.status === 'BUSY') {
        markers.push({
          id: hexItem.index,
          coord: coord,
          photo: '/logo.png',
        });
      }
    });

    setMarkersList(markers);
  }, [data]);

  const onLoadHandler = useCallback(() => {
    const map: any = mapRef.current;
    if (!map) return;

    let selectedHEX: string;
    let h3res: number;

    if (_isDev) {
      map.on('click', (e: any) => {
        const src = map.getSource('h3_source');
        if (src && src._data && h3res === undefined) {
          const h3idx0 = map.getSource('h3_source')._data.features[0].id;
          h3res = getResolution(h3idx0);
        }
        const h3idx = latLngToCell(e.lngLat.lat, e.lngLat.lng, h3res);
        const h3devList = _appendDevHexList(h3idx);
        setH3DataDev(GenerateHexGeoJson(h3devList));
      });
    }

    map.on('click', 'h3_layer', (e: any) => {
      if (_isDev) return;
      if (e.features.length > 0) {
        const hexProp = e.features[0].properties;
        const isUnaviable = hexProp.unaviable;
        if (isUnaviable) return;

        const hexID = hexProp.id;
        if (selectedHEX === hexID) {
          if (onClick) onClick(hexID);
        } else {
          map.setFeatureState({ source: 'h3_source', id: hexID }, { select: true });
          if (selectedHEX) map.setFeatureState({ source: 'h3_source', id: selectedHEX }, { select: false });

          map.flyTo({
            center: [hexProp.hexCenterLng, hexProp.hexCenterLat],
            duration: 2000,
            essential: true,
          });
          selectedHEX = hexID;
        }
      }
    });
  }, [_isDev, onClick]);

  if (!h3Data) {
    return null;
  }

  return (
    <>
      {_isDev ? (
        <Box sx={{ position: 'absolute', zIndex: 3, top: '110px', right: '16px' }}>
          <Button onClick={() => handlerGetDevList()}>G</Button>
        </Box>
      ) : null}
      <MapGL
        ref={mapRef}
        style={{ width: '100%', height: '100vh' }}
        mapStyle="mapbox://styles/ksupipr/ckyr3k8285cwo14tfvj0xdjtw"
        mapboxAccessToken={MAPBOX_TOKEN}
        projection={{ name: 'globe' }}
        reuseMaps={true}
        attributionControl={false}
        logoPosition="top-right"
        initialViewState={{
          //latitude: 1.290270,
          //longitude: 103.851959,

          latitude: 55.751244,
          longitude: 37.618423,
          zoom: 1,
          bearing: 0,
          pitch: 0,
        }}
        terrain={{
          source: 'mapbox-raster-dem',
          exaggeration: 2,
        }}
        onLoad={onLoadHandler}
      >
        <MapboxPinMarker
          data={markersList}
          onClick={(item) => {
            const map: any = mapRef.current;
            if (map) {
              map.flyTo({
                center: item.coord,
                duration: 2000,
                zoom: 1.5,
                curve: 2,
                essential: true,
              });
            }
          }}
        />
        <Source id="h3_source" type="geojson" promoteId="id" data={h3Data}>
          <MapboxHexLayer id="h3_layer" source="h3_source" />
        </Source>
        {h3DataDev ? (
          <Source id="h3_source_dev" type="geojson" promoteId="id" data={h3DataDev}>
            <MapboxHexLayer id="h3_layer_dev" source="h3_source_dev" />
          </Source>
        ) : null}
      </MapGL>
    </>
  );
};

export default MapboxHex;
