import { AxiosResponse } from 'axios';

import agent from 'src/agent';
import { Levels, PaginationParams } from 'src/types';

import { ClanList, ClanTreeParams, ClanTreeResponse } from './StructureService.types';

export const getClansList = (clanLevel: Levels, params?: PaginationParams): Promise<AxiosResponse<ClanList>> => {
  const { limit = 1, offset = 0 } = params || {};
  return agent.GET('clans', { params: { clan_level: clanLevel, limit, offset } });
};

export const getClansTree = (params: ClanTreeParams): Promise<AxiosResponse<ClanTreeResponse>> => {
  return agent.GET('clans/tree', { params: { ...params, is_fake: true } });
};
