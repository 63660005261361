import { FC } from 'react';

import { LevelsIcon } from 'src/assets/icons';
import { levelsForSwitch } from 'src/constants';

import { Levels, Level, Number } from './LevelSwitcher.styles';
import { LevelSwitcherProps } from './LevelSwitcher.types';

const LevelSwitcher: FC<LevelSwitcherProps> = ({ level, filled, onChangeLevel }) => {
  return (
    <Levels flexDirection="row" justifyContent="center">
      {levelsForSwitch.map((itemLevel) => (
        <Level
          sx={{ color: itemLevel.color, opacity: itemLevel.level > level ? 0.25 : 1 }}
          key={itemLevel.level}
          onClick={() => onChangeLevel(itemLevel.level)}
        >
          <Number
            sx={{
              backgroundColor:
                itemLevel.level <= level && filled.includes(itemLevel.level) ? itemLevel.color : 'transparent',
              color: (theme) =>
                itemLevel.level <= level && filled.includes(itemLevel.level)
                  ? theme.palette.primary.main
                  : theme.palette.secondary.main,
            }}
          >
            {itemLevel.level}
          </Number>
          <LevelsIcon />
        </Level>
      ))}
    </Levels>
  );
};

export default LevelSwitcher;
