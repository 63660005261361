import { Avatar, IconButton, Stack, Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getImgSrc } from 'src/constants';
import { FONTS, ThemeColors } from 'src/styles';
import { abbrNum } from 'src/utils';

import { TeamTreeChildProps } from './TeamTreeChild.types';

const TeamTreeChild: FC<TeamTreeChildProps> = ({ child, onNodeClick, size: sizeProp, className, sx }) => {
  const { t } = useTranslation('app');
  const size = sizeProp || 44;
  return (
    <Stack direction="column" alignItems="center" sx={sx}>
      <IconButton className={className} onClick={() => onNodeClick(child.id)} sx={{ p: 0, mb: '16px' }}>
        <Avatar
          src={getImgSrc(child.photo_uuid, 's60-c')}
          alt={child.display_name}
          sx={{
            width: size,
            height: size,
            border: `2px solid ${ThemeColors.grayLight}`,
            borderColor: t(`role.state.color.${child.role}`) as string,
          }}
        />
      </IconButton>
      {child.personal_team_number.length > 0 &&
        child.personal_team_number.map((number, index) => (
          <Box
            key={`${index}_${number}`}
            sx={{
              width: 32,
              height: 32,
              borderRadius: '100%',
              border: '1px solid',
              borderColor: 'divider',
              mb: '16px',
              display: 'flex',
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: FONTS,
              fontSize: '0.625rem',
              fontWeight: 500,
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',

              '&:before': {
                content: '""',
                position: 'absolute',
                top: '-17px',
                left: '15px',
                width: '1px',
                height: 16,
                backgroundColor: 'divider',
                zIndex: -1,
              },
            }}
          >
            {abbrNum(number)}
          </Box>
        ))}
    </Stack>
  );
};

export default TeamTreeChild;
