import { Accordion, Box, IconButton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FONTS } from 'src/styles';

export const NotificationAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 13,
  marginBottom: 8,

  '&:first-of-type': {
    borderTopLeftRadius: 13,
    borderTopRightRadius: 13,
  },

  '&:last-of-type': {
    borderBottomLeftRadius: 13,
    borderBottomRightRadius: 13,
  },

  '&.Mui-expanded': {
    margin: '0 0 8px',
  },

  '&:before': {
    height: 0,
  },

  '.MuiAccordionDetails-root': {
    padding: '2px 16px 16px',
    fontFamily: FONTS,
    fontSize: '0.75rem',
    lineHeight: '16px',

    p: {
      margin: 0,
    },
  },

  '.MuiAccordionSummary-root': {
    minHeight: 64,

    '&.Mui-expanded': {
      minHeight: 46,
    },
  },
  '.MuiAccordionSummary-content': {
    alignItems: 'center',

    '&.Mui-expanded': {
      margin: 0,

      img: {
        width: 24,
        height: 24,
        borderRadius: 4,
        marginRight: 8,
      },

      '& .shortText': {
        display: 'none',
      },
    },
  },
}));

export const Icon = styled('img')(({ theme }) => ({
  width: 40,
  height: 40,
  //border: `1px solid ${theme.palette.divider}`,
  borderRadius: 10,
  marginRight: 10,
  flex: '0 0 auto',
}));

export const NotificationHeader = styled(Stack)(() => ({
  width: '100%',
  flexDirection: 'column',
}));

export const TitleStack = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const Title = styled(Typography)(() => ({
  fontSize: '0.812rem',
  lineHeight: '18px',
  fontWeight: 600,
}));

export const Date = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '16px',
  fontWeight: 400,
  color: theme.palette.secondary.main,
}));

export const ShortText = styled(Box)(() => ({
  fontSize: '0.688rem',
  lineHeight: '13px',
  fontWeight: 400,
}));

export const Likes = styled(Box)(() => ({
  fontFamily: FONTS,
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: 400,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
}));

export const LikeButton = styled(IconButton)(() => ({
  padding: 4,

  svg: {
    width: 20,
    height: 20,
  },
}));
