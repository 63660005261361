import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { copyToClipboard } from 'src/utils';

import { CopyValueProps } from './CopyValue.types';

const CopyValue: FC<CopyValueProps> = ({ value, link, onClick, sx }) => {
  const { t } = useTranslation('app');
  const { enqueueSnackbar } = useSnackbar();

  const onCopyHandler = (value: string) => {
    copyToClipboard(value);
    enqueueSnackbar(t('button.copied'), { variant: 'info' });
  };

  return (
    <Stack direction="row" sx={sx} alignItems="center">
      <Typography
        sx={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', minWidth: 0, whiteSpace: 'nowrap' }}
        onClick={() => (onClick ? onClick() : link ? window.open(link, '_blank') : false)}
      >
        {value}
      </Typography>
      <IconButton
        sx={{ flex: '0 0 auto' }}
        onClick={(event) => {
          event.stopPropagation();
          onCopyHandler(value);
        }}
      >
        <ContentCopyIcon sx={{ width: 15, height: 18 }} />
      </IconButton>
    </Stack>
  );
};

export default CopyValue;
