import { Instagram } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginSocialInstagram, IResolveParams } from 'reactjs-social-login';

import { useAppDispatch, useAppSelector } from 'src/hooks';
import { addUserSN } from 'src/services';
import { selectUser, signInUser } from 'src/store/userSlice';
import { parseError, parseErrorTr } from 'src/utils';

import { InstagramLoginProps } from './InstagramLogin.types';

// https://developers.facebook.com/apps/325085207187457/dashboard/

const InstagramLogin: FC<InstagramLoginProps> = ({ referral, connection, isReg, onFinish }) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isShowAuth } = useAppSelector(selectUser);
  const { t } = useTranslation('app');
  const page = isReg ? 'registration' : connection ? 'connection' : 'signin';

  const REDIRECT_URI = window.location.href;

  const handleTelegramResponse = (user: IResolveParams['data']) => {
    if (connection) {
      addUserSN('instagram', user)
        .then(() => onFinish && onFinish())
        .catch((error) => {
          enqueueSnackbar(parseErrorTr(`instagram_${page}`, error), { variant: 'error' });
        });
    } else {
      dispatch(
        signInUser({
          provider: 'instagram',
          authData: user,
          referral_uuid: referral,
        })
      );
    }
  };

  return (
    <LoginSocialInstagram
      // isOnlyGetToken
      client_id={process.env.REACT_APP_INSTAGRAM_APP_ID || ''}
      client_secret={process.env.REACT_APP_INSTAGRAM_APP_SECRET || ''}
      redirect_uri={REDIRECT_URI || ''}
      onResolve={({ provider, data }: IResolveParams) => {
        handleTelegramResponse(data);
      }}
      onReject={(err: any) => {
        enqueueSnackbar(parseError(err), { variant: 'error' });
      }}
    >
      <Button
        disabled={isShowAuth}
        data-qa={`signIn.${page}.instagram.title`}
        variant="text"
        fullWidth
        sx={{ '& svg': { mr: '4px' } }}
      >
        <Instagram /> {t(`signIn.${page}.instagram.title`)}
      </Button>
    </LoginSocialInstagram>
  );
};

export default InstagramLogin;
