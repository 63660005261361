import { Accordion, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FONTS, ThemeFontWeight } from 'src/styles';

export const CustomizedAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  background: 'none',
  marginBottom: '-1px',

  '&.Mui-expanded': {
    margin: 0,
    // borderBottom: '1px solid',
    borderRadius: '0px',
    // borderColor: theme.palette.divider,
    marginBottom: '-1px',
  },
  '&:before': {
    height: 0,
  },
  '.MuiAccordionDetails-root': {
    padding: '16px',
    background: 'none',
  },
  '.MuiAccordionSummary-root': {
    margin: '0px -16px',
    padding: '0px 16px',
    border: '1px solid',
    borderColor: theme.palette.divider,
    background: theme.palette.background.paper,
    borderLeft: 'none',
    borderRight: 'none',
    height: '64px',
  },
  '.MuiAccordionSummary-content': {
    background: 'none',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '.MuiAccordionSummary-expandIconWrapper': {
    padding: '0px 16px',
  },
}));

export const Title = styled('p')(({ theme }) => ({
  fontFamily: FONTS,
  fontSize: '1.062rem',
  fontWeight: ThemeFontWeight.semiBold,
  color: theme.palette.primary.main,
  lineHeight: '20px',
  letterSpacing: '-0.24px',
  margin: 0,
  paddingLeft: '16px',
}));

export const Counter = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  borderRadius: '10px',
  padding: '0px 4px',
}));
