import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { BotIcon } from 'src/assets/icons';
import { DrawerBottom } from 'src/atoms';
import { Paths } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { getDemoUserData } from 'src/local-storages';
import { ClanStatus, createFakeMemberForClan, getClansListToBuy, setUserInfo, signIn } from 'src/services';
import { selectClanState, setClanLevels, setSelectedClan } from 'src/store/clanSlice';
import { getInfoUser, IParamsSignIn, selectUserMe } from 'src/store/userSlice';
import { blobUrlToFile, parseError, removeToken } from 'src/utils';

import DrawerShareText from '../DrawerShareText';

import {
  Wrapper,
  RemoveButton,
  InviteButton,
  CloseButton,
  Title,
  Text,
  InviteRealFriendButton,
  Label,
  SubLabel,
  Divider,
  AddDemoBot,
  BotButton,
  BotInfo,
  AddBotTitle,
  AddBotText,
} from './DemoClans.styles';
import { DemoClansProps } from './DemoClans.types';

const DemoClans: FC<DemoClansProps> = ({ setClanList, setClosedLevels, setOffset, setLevel, currentStatus }) => {
  const { t } = useTranslation('app');
  const userMe = useAppSelector(selectUserMe);
  if (!userMe) throw new Error('userMe does not exist');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { level } = useAppSelector(selectClanState);

  const referralLink = userMe.referral_link;

  const [isOpen, setOpen] = useState(false);
  const [isOpenShare, setOpenShare] = useState(false);
  const [isPending, setPending] = useState(false);
  const [isRemoving, setRemoving] = useState(false);

  const onOpenHandler = () => setOpen(true);
  const onCloseHandler = () => setOpen(false);

  const onOpenShareHandler = () => {
    setOpen(false);
    setOpenShare(true);
  };
  const onCloseShareHandler = () => setOpenShare(false);

  const onAddDemoBotHandler = () => {
    setPending(true);
    createFakeMemberForClan(level)
      .then(({ data }) => {
        setClanList(data.results);
        dispatch(setSelectedClan(data.results[0]));
        setClosedLevels(data.closed_levels);
        onCloseHandler();
      })
      .catch(({ message }) => {
        enqueueSnackbar(message, { variant: 'error' });
      })
      .finally(() => setPending(false));
  };

  const onClearClansHandler = async () => {
    setRemoving(true);
    removeToken();
    try {
      const demoUserData = getDemoUserData();

      const params: IParamsSignIn = {
        public_address: uuidv4(),
        nonce: 'demo',
        signature: 'demo',
      };

      await signIn('wallet', params);

      if (demoUserData) {
        const userPhoto = await blobUrlToFile(demoUserData.photo);

        await setUserInfo({
          birthday: demoUserData.birthday,
          display_name: demoUserData.displayName,
          email: demoUserData.email,
          gender: demoUserData.gender,
          photo: userPhoto,
        });
      }

      dispatch(getInfoUser());

      if (!demoUserData) {
        navigate(Paths.reg, { replace: true });
      } else {
        const { data } = await getClansListToBuy();
        dispatch(setClanLevels(data));
        setLevel(1);
        setOffset(0);
      }
    } catch (error) {
      enqueueSnackbar(parseError(error), { variant: 'error' });
      dispatch(setClanLevels(null));
    } finally {
      setRemoving(false);
    }
  };

  return (
    <>
      <Wrapper>
        <RemoveButton onClick={onClearClansHandler}>
          {isRemoving ? <CircularProgress /> : <DeleteOutlineIcon />}
        </RemoveButton>
        <InviteButton variant="outlined" onClick={onOpenHandler} disabled={currentStatus === ClanStatus.Close}>
          {t('button.inviteFriendDemo')}
        </InviteButton>
      </Wrapper>

      <DrawerBottom isOpen={isOpen} onClose={onCloseHandler}>
        <CloseButton onClick={onCloseHandler}>
          <CloseIcon />
        </CloseButton>
        <Title>{t('demo.titleInviteFriend')}</Title>
        <Text>{t('demo.textInviteFriend')}</Text>

        <AddDemoBot>
          <BotButton onClick={onAddDemoBotHandler} disabled={isPending}>
            <BotIcon />
            <BotInfo>
              <AddBotTitle>{t('demo.addDemoBotTitle')}</AddBotTitle>
              <AddBotText>{t('demo.addDemoBotText')}</AddBotText>
            </BotInfo>
          </BotButton>
        </AddDemoBot>

        <InviteRealFriendButton variant="outlined" onClick={onOpenShareHandler}>
          <Label>{t('button.inviteRealFriend')}</Label>
          <SubLabel>{t('button.inviteRealFriendSub')}</SubLabel>
        </InviteRealFriendButton>

        <Divider />

        <Button sx={{ width: '100%' }} onClick={onCloseHandler}>
          {t('button.close')}
        </Button>
      </DrawerBottom>

      <DrawerShareText
        title={userMe?.display_name || t('onboarding.unknownName')}
        link={referralLink}
        isOpen={isOpenShare}
        tKey="referal"
        onClose={onCloseShareHandler}
      />
    </>
  );
};

export default DemoClans;
