import { Box } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Loading, TransactionInfo } from 'src/atoms';
import { Paths, pathsArg } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { DrawerShareText, HeaderShare } from 'src/molecules';
import { Order, getOrderById } from 'src/services';
import { ThemePaddings } from 'src/styles';
import { RouterIdParam } from 'src/types';
import { logger } from 'src/utils';

const Transaction: FC = () => {
  const { t } = useTranslation('app');
  const { id } = useParams<RouterIdParam>();
  const { throwServerError, throwNotFound } = useContext(ErrorContext);

  const link = `${window.location.origin}${pathsArg(Paths.transaction, { id: id })}`;

  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState<Order>();

  const onOpenHandler = () => setOpen(true);
  const onCloseHandler = () => setOpen(false);

  useEffect(() => {
    const orderId = Number(id);
    if (orderId) {
      setLoading(true);
      getOrderById(orderId)
        .then(({ data }) => {
          setTransaction(data);
        })
        .catch((error) => {
          if (error.response?.status >= 500) throwServerError();
          if (error.response?.status >= 400) throwNotFound();
          logger.error(error);
        })
        .finally(() => setLoading(false));
    } else {
      throwNotFound();
    }
  }, [id, throwNotFound, throwServerError]);

  if (!id) return null;

  return (
    <>
      {isLoading && <Loading />}

      {transaction && (
        <>
          <HeaderShare transactionId={id} backLink={Paths.wallet} isShare onOpenHandler={onOpenHandler}>
            {t('transaction.title')}
          </HeaderShare>
          <Box sx={{ padding: ThemePaddings.transaction }}>
            <TransactionInfo transaction={transaction} />
          </Box>
        </>
      )}

      <DrawerShareText
        tKey={'transaction.share'}
        title={t('Transaction')}
        link={link}
        isOpen={isOpen}
        onClose={onCloseHandler}
      />
    </>
  );
};

export default Transaction;
