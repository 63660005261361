import { Box, IconButton } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ClanTriangleIcon } from 'src/assets/icons';
import { ClanActivation, LevelSwitcher } from 'src/atoms';
import { Paths, pathsArg } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { PageTitle } from 'src/molecules';
import { ClansTree } from 'src/organisms';
import { ClanTree, ClanTreeParams, getClansTree } from 'src/services';
import { selectClanState, setSelectedClan } from 'src/store/clanSlice';
import { selectUserMe } from 'src/store/userSlice';
import { LEVELS_TO_COLORS, ThemePaddings } from 'src/styles';
import { Levels, RouterStateStructure } from 'src/types';
import { logger } from 'src/utils';

const StructureTree: FC = () => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const levelState = state ? (state as RouterStateStructure).level : 1;
  const { activeClanId } = useAppSelector(selectClanState);
  const dispatch = useAppDispatch();

  const userMe = useAppSelector(selectUserMe);

  const onCloseHandler = () => navigate(Paths.dashboard);

  const onStructureClanHandler = () => {
    navigate(Paths.structure, { state: { level } });
  };

  const [isLoading, setLoading] = useState(true);
  const [level, setLevel] = useState(levelState || Levels.First);
  const [closedLevels, setClosedLevels] = useState<number[]>([]);
  const [userClanNodes, setUserClanNodes] = useState<number[]>([]);
  const [referrerClanNodes, setReferrerClanNodes] = useState<number[]>([]);
  const [clanTree, setClanTree] = useState<ClanTree | null>(null);

  const onChangeLevel = (changeLevel: Levels) => {
    setLevel(changeLevel);
    dispatch(setSelectedClan(null));
  };

  const onClanActivation = () => {
    navigate(pathsArg(Paths.shoppingCart, { itemId: level }));
    // TODO: активация клана
  };

  useEffect(() => {
    if (activeClanId || level) {
      setLoading(true);

      const params: ClanTreeParams = {};
      if (activeClanId) params.clan_node_id = activeClanId;
      if (level) params.clan_level = level;

      getClansTree(params)
        .then(({ data }) => {
          setClanTree(data.clan_node);
          setClosedLevels(data.closed_levels || []);
          setUserClanNodes(data.user_nodes || []);
          setReferrerClanNodes(data.referrer_nodes || []);
        })
        .catch((error) => {
          if (error.response?.status >= 500) throwServerError();
          if (error.response?.status >= 400) setClanTree(null);
          logger.error(error);
        })
        .finally(() => setLoading(false));
    }
  }, [activeClanId, level, throwServerError]);

  return (
    <>
      <PageTitle
        title={t('structureTree.title')}
        data-qa={'structureTree.title'}
        onClose={onCloseHandler}
        help={{ tkey: 'structureTree' }}
        isLoading={isLoading}
      />
      <Box
        sx={{
          position: 'fixed',
          top: 100,
          left: 0,
          right: 0,
          zIndex: 100,
          backgroundColor: 'background.default',
          height: 60,
        }}
      >
        <LevelSwitcher level={level} filled={closedLevels} onChangeLevel={onChangeLevel} />
      </Box>
      <Box sx={{ p: ThemePaddings.structureTree, position: 'relative', height: '100%' }}>
        {!isLoading &&
          (clanTree ? (
            <>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 160,
                  right: 16,
                  color: LEVELS_TO_COLORS[level - 1],
                  zIndex: 3,
                  svg: { fill: 'currentColor', stroke: 'currentColor' },
                }}
                onClick={onStructureClanHandler}
              >
                <ClanTriangleIcon />
              </IconButton>
              <ClansTree
                data={clanTree}
                userClanNodes={userClanNodes}
                referrerClanNodes={referrerClanNodes}
                userId={userMe?.user_id}
                accentColor={LEVELS_TO_COLORS[level - 1]}
              />
            </>
          ) : (
            <Box sx={{ pt: '31px' }}>
              <ClanActivation level={level} onClanActivation={onClanActivation} />
            </Box>
          ))}
      </Box>
    </>
  );
};

export default StructureTree;
