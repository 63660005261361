import { Button } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AvatarCreateStep01,
  AvatarCreateStep02,
  AvatarCreateStep03,
  AvatarCreateStep04,
  AvatarCreateStep05,
  AvatarCreateStep06,
} from 'src/assets/images';
import { LineStepper } from 'src/atoms';
import { AvatarGenderSelector } from 'src/molecules';
import { Gender } from 'src/molecules/AvatarGenderSelector/AvatarGenderSelector.types';
import { setAvatarGender } from 'src/services';

import { Block, StepBlock, StepTitle, StepImg, StepList } from './AvatarCreater.styles';
import { AvatarCreaterProps } from './AvatarCreater.types';

const stepItems = [
  <StepBlock>
    <StepImg src={AvatarCreateStep01} alt="" />
    <StepTitle variant="h1">Что такое Аватар?</StepTitle>
    <StepList>
      <li>Выберите пол, цвет кожи, примените полученные Материалы на 100 участков тела.</li>
      <li>Покупайте на NFT Маркет материалы и носимые предметы и применяйте к нему.</li>
      <li>
        Улучшайте своего аватара повышая его ценность, попадите в SECRET TOP-100 и тогда он будет опубликован в
        коллекции SECRET.
      </li>
    </StepList>
  </StepBlock>,

  <StepBlock>
    <StepImg src={AvatarCreateStep02} alt="" />
    <StepTitle variant="h1">Ваш аватар - ваш бизнес</StepTitle>
    <StepList>
      <li>
        Ваш аватар это “майнинг машина”. Новые Материалы производятся только путем майнинга аватарами других
        пользователей, поэтому их количество ограничено, спрос высок и цена постоянно растет на NFT Маркет.
      </li>
      <li>
        Улучшайте своего аватара, повышайте скорость майнинга, производите Материалы, используйте их для улучшения
        Аватара или продавайте на NFT Маркет и зарабатывайте!
      </li>
    </StepList>
  </StepBlock>,

  <StepBlock>
    <StepImg src={AvatarCreateStep03} alt="" />
    <StepTitle variant="h1">Майнинг</StepTitle>
    <StepList>
      <li>
        Получите при открытие новых Кланов уникальные материалы для каждого из 100 кусочков тела вашего аватара.
        Покупайте и продавайте материалы, предметы одежды и инвентаря
      </li>
    </StepList>
  </StepBlock>,

  <StepBlock>
    <StepImg src={AvatarCreateStep04} alt="" />
    <StepTitle variant="h1">Множество носимых предметов</StepTitle>
    <StepList>
      <li>Получите при открытие Кланов уникальные материалы для каждого из 100 кусочков тела вашего аватара.</li>
      <li>
        Покупайте и продавайте материалы, предметы одежды и инвентаря. Чем быстрее вы закрываете предыдущий Клан и
        открываете новый, тем больше Материалов у вас будет
      </li>
    </StepList>
  </StepBlock>,

  <StepBlock>
    <StepImg src={AvatarCreateStep05} alt="" />
    <StepTitle variant="h1">Разнообразные конкурсы с предметами</StepTitle>
    <StepList>
      <li>
        Кастомизируйете своего Аватара и участвуйте в разнообразных конкурсах, чтобы получить NFT Аватара, которое вы
        сможете продать на OpenSea.io
      </li>
    </StepList>
  </StepBlock>,

  <StepBlock>
    <StepImg src={AvatarCreateStep06} alt="" />
    <StepTitle variant="h1">Эра 1 - все только начинается!</StepTitle>
    <StepList>
      <li>
        Создание аватаров доступно только 3 месяца с момента активации. После окончания этого периода создание аватаров
        остановится.
      </li>
      <li>
        Все созданные и опубликованные аватары буду доступны только на NFT Маркете и Opensea в составе “Коллекции
        Аватаров SECRET”.
      </li>
      <li>Все участники которые присоединяться к проекту после окончания этого периода</li>
    </StepList>
  </StepBlock>,
];

const AvatarCreater: FC<AvatarCreaterProps> = ({ onFinish }) => {
  const { t } = useTranslation('app');
  const maxSteps = stepItems.length;
  const [step, setStep] = useState<number>(0);
  const [gender, setGender] = useState<Gender>();
  const handleNextStep = () => setStep(step + 1);
  const handleSelectGender = (gender: Gender) => setGender(gender);
  const handleCreate = () => {
    // TODO: сохранение на сервере и только после вызывать финиш
    setAvatarGender(gender === Gender.male ? 'male' : 'female').then(() => {
      onFinish();
    });
  };

  if (step >= maxSteps) {
    return (
      <Block>
        <AvatarGenderSelector onChange={handleSelectGender} gender={gender} />
        <Button className="nextButton" variant="contained" disabled={gender === undefined} onClick={handleCreate}>
          {t('button.save')}
        </Button>
      </Block>
    );
  }

  return (
    <Block>
      <LineStepper steps={maxSteps} activeStep={step} />
      {stepItems[step]}
      <Button className="nextButton" variant="contained" onClick={handleNextStep}>
        {t('button.next')}
      </Button>
    </Block>
  );
};

export default AvatarCreater;
