import { styled } from '@mui/material/styles';

export const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  height: 60,
  marginBottom: 18,

  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 5,
    bottom: 5,
    left: 0,
    right: 0,
    background: theme.palette.background.paper,
  },
}));

export const Slider = styled('div')(() => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  height: 60,
  overflowX: 'scroll',
  overflowY: 'hidden',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',

  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));
