import { AxiosResponse } from 'axios';

import agent from 'src/agent';
import { getAvatarConf } from 'src/services';

import { Dashboard, TClosedChannel, TProfileDashboard, TProfileStat, TSocialNetworks } from './DashboardService.types';

export const getDashboard = (): Promise<AxiosResponse<Dashboard>> => {
  return agent.GET('dashboard').then((resp) => {
    // мок добавляющий картинку аватара для виджета
    const config = getAvatarConf();
    resp.data.avatar = config.img || '';
    return resp;
  });
};

export const getDashboardByUserId = (userId: number): Promise<AxiosResponse<TProfileDashboard>> => {
  return agent.GET(`player/${userId}`);
};

export const getClosedChannel = (): Promise<AxiosResponse<TClosedChannel>> => {
  return agent.GET('closed_channel');
};

export const getSocialNetworks = (): Promise<AxiosResponse<TSocialNetworks[]>> => {
  return agent.GET('social');
};

export const geStatByUserId = (userId: number, begin?: Date, end?: Date): Promise<AxiosResponse<TProfileStat>> => {
  return agent.GET(`player/${userId}/stat`, { params: { date_end: begin, date_begin: end } });
};
