import { FC } from 'react';

import { MarkerPhoto, MarkerPin } from './PinMarker.styles';
import { PinMarkerProps } from './PinMarker.types';

const PinMarker: FC<PinMarkerProps> = ({ photo, size, color, sx }) => {
  const _size = size || 42;
  return (
    <MarkerPin
      sx={{
        height: `${_size}px`,
        ...sx,
      }}
    >
      <MarkerPhoto
        className="pinmarker_photo"
        sx={{
          left: `-${_size / 2}px`,
          top: `-${_size / 2}px`,
          width: `${_size}px`,
          height: `${_size}px`,
          borderColor: color ? color : 'primary.main',
          backgroundImage: `url('${photo}')`,
        }}
      />
    </MarkerPin>
  );
};

export default PinMarker;
