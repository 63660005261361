import { Box } from '@mui/material';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Paths } from 'src/constants';
import { ThemeContext } from 'src/context';
import { PageTitle } from 'src/molecules';
import { ThemeColors, ThemePaddings } from 'src/styles';
import { getLocale } from 'src/utils';

const Exchange: FC = () => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const { mode } = useContext(ThemeContext);
  const { search: searchParams } = useLocation();

  const locale = getLocale();

  const onCloseHandler = () => {
    navigate(Paths.home);
  };

  const pageSearchParams = new URLSearchParams(searchParams);

  const urlFrame = new URL('https://changenow.io/embeds/exchange-widget/v2/widget.html');
  const params = urlFrame.searchParams;

  params.append('FAQ', 'true');
  params.append('amount', '0.1');
  params.append('amountFiat', '1500');
  params.append('from', 'btc');
  params.append('to', 'eth');
  params.append('fromFiat', 'eur');
  params.append('toFiat', 'eth');
  params.append('toTheMoon', 'true');
  params.append('link_id', '8710cc680fd199');
  params.append('isFiat', 'true');

  params.append('logo', 'false');
  params.append('horizontal', 'false');
  params.append('lang', locale === '1' ? 'ru-RU' : 'en-US');
  params.append('locales', 'true');
  params.append('primaryColor', mode === 'light' ? ThemeColors.black : ThemeColors.white);
  params.append('backgroundColor', mode === 'light' ? ThemeColors.white : ThemeColors.black);
  params.append('darkMode', (mode === 'dark').toString());

  pageSearchParams.forEach((val, key) => {
    if (params.has(key)) params.delete(key);
    params.append(key, val);
  });

  return (
    <Box sx={{ p: ThemePaddings.page, height: '100%' }}>
      <PageTitle
        title={t('exchange.title')}
        data-qa={'exchange.title'}
        help={{ tkey: 'exchange' }}
        onClose={onCloseHandler}
      />
      <iframe
        title={t('exchange.title')}
        id="iframe-widget"
        src={urlFrame.toString()}
        style={{ height: '100%', width: '100%', border: 'none' }}
      ></iframe>
      <script
        defer
        type="text/javascript"
        src="https://changenow.io/embeds/exchange-widget/v2/stepper-connector.js"
      ></script>
    </Box>
  );
};

export default Exchange;
