import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CircleGradientLoading, CircleLoading, DrawerBottom, BPrice, PaymentCost } from 'src/atoms';
import { Paths, isMarket } from 'src/constants';
import {
  getAddressAndBalance,
  getNetworkChainId,
  neededChainId,
  fundInvoiceAddr,
  formatBalance,
} from 'src/helpers/Blockchain/Blockchain';
import { useAppDispatch } from 'src/hooks';
import { TonWalletPay, WindowTitle } from 'src/molecules';
import {
  createPackOrder,
  getOrderById,
  OrderStatus,
  priceSum,
  sendOrderConfirm,
  TOrder,
  TOrderItem,
  TOrderPrice,
} from 'src/services';
import { ThemeColors } from 'src/styles';
import { delay, parseError } from 'src/utils';

import { Total, TotalLabel, CheckInfo, CheckTitle, CheckTitleBlock, RefreshButton } from './PaymentProducts.styles';
import { PaymentProductsProps } from './PaymentProducts.types';

const TIME_GET_STATUS = 10000;

const defaultPrice = {
  cost: 0,
  bcost: 0,
  currency: '',
  bcurrency: '',
} as TOrderPrice;

const PaymentProducts: FC<PaymentProductsProps> = ({
  isOpen,
  onClose,
  order: orderProp,
  wrapperId: wrapperIdProp,
  tkey: tkeyProp,
  confirmFunction,
  onFinish,
}) => {
  const { t } = useTranslation('app');
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [isLoadingPay, setLoadingPay] = useState(false);
  const [isSuccessPay, setSuccessPay] = useState(false);
  const [isReqPay, setReqPay] = useState(false);
  const [status, setStatus] = useState<OrderStatus | null>(null);
  const [totalPrice, setTotalPrice] = useState<TOrderPrice>(defaultPrice);
  const [summPrice, setSummPrice] = useState<TOrderPrice>(defaultPrice);
  const [summFee, setSummFee] = useState<TOrderPrice>(defaultPrice);
  const [items, setItems] = useState<TOrderItem[]>([]);

  const [isLoading, setLoading] = useState(!!wrapperIdProp);
  const [order, setOrder] = useState<TOrder>(orderProp);

  const [bBalance, setBBalance] = useState<bigint>();
  const [, setBAddress] = useState<string>();
  const [isRefreshBalance, setRefreshBalance] = useState(false);

  const tkey = tkeyProp ? `${tkeyProp}.` : '';

  const onRefreshBalance = useCallback(async () => {
    setRefreshBalance(true);
    try {
      const { address, balance } = await getAddressAndBalance();
      setRefreshBalance(false);
      setBBalance(balance);
      setBAddress(address);
    } catch (error: any) {
      setRefreshBalance(false);
      enqueueSnackbar(`${t(`payment.${tkey}walletError`)}: ${error.message}`, { variant: 'error' });
    }
  }, [enqueueSnackbar, t, tkey]);

  const [bNetwork, setNetwork] = useState<bigint>();
  const [isRefreshNetwork, setRefreshNetwork] = useState(false);

  const onRefreshNetwork = useCallback(async () => {
    setRefreshNetwork(true);
    try {
      const { chainId } = await getNetworkChainId();
      setRefreshNetwork(false);
      setNetwork(chainId);
    } catch (error: any) {
      setRefreshNetwork(false);
      enqueueSnackbar(`${t(`payment.${tkey}walletError`)}: ${error.message}`, { variant: 'error' });
    }
  }, [enqueueSnackbar, t, tkey]);

  useEffect(() => {
    if (!order) return;
    const orderItems = order.items;
    orderItems.map((item) => (item.count = item.count === undefined ? 1 : item.count));
    setItems(orderItems);
  }, [order]);

  useEffect(() => {
    const _summPrice = items.reduce((price, orderItem) => priceSum(price, orderItem.buyer_total, orderItem.count), {
      ...defaultPrice,
    });
    const _summFee = items.reduce((fee, orderItem) => priceSum(fee, orderItem.fee_total, orderItem.count), {
      ...defaultPrice,
    });
    setSummPrice(_summPrice);
    setSummFee(_summFee);

    setTotalPrice(priceSum(_summPrice, _summFee, 1));
    onRefreshNetwork();
    onRefreshBalance();
  }, [items, onRefreshBalance, onRefreshNetwork]);

  useEffect(() => {
    if (!orderProp) {
      setLoading(false);
      enqueueSnackbar(t(`payment.${tkey}error`), { variant: 'error' });
    } else {
      if (!wrapperIdProp) return;

      const itemsWrapers = orderProp.items.reduce((acc, item) => {
        if (item.wrapper && item.count) acc.push(item.wrapper);
        return acc;
      }, [] as number[]);

      if (itemsWrapers.length === 0) itemsWrapers.push(wrapperIdProp);

      setLoading(true);
      createPackOrder(itemsWrapers)
        .then(({ data }) => {
          setOrder(data);
        })
        .catch((error) => {
          // if (error.response?.status >= 500) throwServerError();
          enqueueSnackbar(t(`payment.${tkey}error`), { variant: 'error' });
        })
        .finally(() => setLoading(false));
    }
  }, [enqueueSnackbar, orderProp, t, tkey, wrapperIdProp]);

  useEffect(() => {
    if (!status || !order?.id) return;

    const getStatusTimeout = setInterval(() => {
      if (isReqPay) return;

      setReqPay(true);
      getOrderById(order?.id)
        .then(({ data }) => {
          if (data.status !== OrderStatus.Completed) return;
          clearInterval(getStatusTimeout);
          setSuccessPay(true);
        })
        .catch(({ message }) => {
          setLoadingPay(false);
          clearInterval(getStatusTimeout);
          enqueueSnackbar(message, { variant: 'error' });
        })
        .finally(() => setReqPay(false));
    }, TIME_GET_STATUS);

    return () => {
      clearInterval(getStatusTimeout);
      setSuccessPay(false);
      setLoadingPay(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, order, dispatch, enqueueSnackbar]);

  const _confirmFunction = (
    order: TOrder,
    txId: string,
    callback: (order: TOrder) => void,
    errback: (message: string) => void
  ) => {
    sendOrderConfirm(order?.id, txId)
      .then(({ data }) => {
        callback(data);
      })
      .catch(({ message }) => {
        errback(message);
      });
  };

  const onPayHandler = async () => {
    const invoice = order?.id;
    const amount = totalPrice.bcost;
    const contractId = order?.contract_id;
    if (!contractId || !invoice) return;
    if (order?.id && amount > 0) {
      const confirmFoo = confirmFunction || _confirmFunction;

      setLoadingPay(true);
      fundInvoiceAddr(contractId, invoice, amount)
        .then((txId) => {
          confirmFoo(
            order,
            txId,
            (data) => {
              setStatus(data.status);
            },
            (message) => {
              setLoadingPay(false);
              enqueueSnackbar(message, { variant: 'error' });
            }
          );
        })
        .catch((error: any) => {
          setLoadingPay(false);
          enqueueSnackbar(parseError(error), { variant: 'error' });
        });
    }
  };

  const onCompleteHandler = () => {
    if (onFinish) {
      onFinish();
    } else {
      navigate(Paths.dashboard);
    }
  };

  const onOverHandler = async () => {
    onClose();
    await delay(500);
    setSuccessPay(false);
    setLoadingPay(false);
  };

  const titleKey = !isLoadingPay
    ? `shoppingCart.${tkey}payForGoodsTitle`
    : !isSuccessPay
    ? `shoppingCart.${tkey}transactionCheckTitle`
    : `shoppingCart.${tkey}paymentSuccessTitle`;

  return (
    <DrawerBottom isOpen={isOpen} onClose={!isSuccessPay ? onOverHandler : onCompleteHandler}>
      <WindowTitle
        sx={{ mb: '20px' }}
        data-qa={titleKey}
        title={t(titleKey)}
        onClose={!isSuccessPay ? onOverHandler : onCompleteHandler}
        isLoading={isLoading}
      />

      {!isLoadingPay ? (
        <>
          <PaymentCost tkey={`shoppingCart.${tkey}titleTotalCost`} price={summPrice} />
          <PaymentCost tkey={'shoppingCart.titleBlockchainFee'} price={summFee} />

          <Total>
            <TotalLabel>{t('shoppingCart.totalPayable')}</TotalLabel>
            <BPrice price={totalPrice} />
          </Total>

          {!isMarket ? (
            <>
              <CheckInfo>
                <CheckTitleBlock component="div">
                  <CheckTitle sx={{ color: bNetwork && bNetwork !== neededChainId ? ThemeColors.red : 'primary.main' }}>
                    {bNetwork && bNetwork === neededChainId
                      ? t('shoppingCart.networkCheck')
                      : `${t('shoppingCart.networkNeed')} ${neededChainId}`}
                  </CheckTitle>
                  {bNetwork !== undefined ? <Box>ChainID {bNetwork.toString()}</Box> : null}
                </CheckTitleBlock>
                {isRefreshNetwork ? (
                  <CircleGradientLoading />
                ) : bNetwork && bNetwork === neededChainId ? (
                  <CheckCircleIcon sx={{ color: ThemeColors.greenLight, width: 28, height: 28, m: '-2px' }} />
                ) : (
                  <RefreshButton onClick={onRefreshNetwork}>
                    <CachedRoundedIcon />
                  </RefreshButton>
                )}
              </CheckInfo>
              <CheckInfo>
                <CheckTitleBlock component="div">
                  <CheckTitle
                    sx={{ color: bBalance && bBalance >= totalPrice.bcost ? 'primary.main' : ThemeColors.red }}
                  >
                    {bBalance && bBalance >= totalPrice.bcost
                      ? t('shoppingCart.balanceCheck')
                      : t('shoppingCart.balanceCheck.noMoney')}
                  </CheckTitle>
                  {bBalance !== undefined ? <Box>{formatBalance(bBalance)}</Box> : null}
                </CheckTitleBlock>

                {isRefreshBalance ? (
                  <CircleGradientLoading />
                ) : bBalance && bBalance >= totalPrice.bcost ? (
                  <CheckCircleIcon sx={{ color: ThemeColors.greenLight, width: 28, height: 28, m: '-2px' }} />
                ) : (
                  <RefreshButton onClick={onRefreshBalance}>
                    <CachedRoundedIcon />
                  </RefreshButton>
                )}
              </CheckInfo>

              <Divider variant="fullWidth" sx={{ my: '25px' }} />
              <Box sx={{ width: '100%', zIndex: 0, position: 'relative' }}>
                {order.contract_id ? (
                  <Button
                    variant="outlined"
                    onClick={onPayHandler}
                    sx={{ width: '100%' }}
                    disabled={!(bBalance && bBalance >= totalPrice.bcost && bNetwork === neededChainId)}
                  >
                    {t(`button.${tkey}goToPay`)}
                  </Button>
                ) : (
                  <Typography>{t(`shoppingCart.${tkey}noContract`)}</Typography>
                )}
              </Box>
            </>
          ) : (
            <TonWalletPay amount={totalPrice.bcost} wallet={order?.wallet} />
          )}
        </>
      ) : (
        <>
          <Box sx={{ my: '34px' }}>
            <CircleLoading isLoading={!isSuccessPay} />
          </Box>
          <Typography>{t(`shoppingCart.${tkey}waitTransaction`)}</Typography>
          <Box sx={{ width: '100%', zIndex: 0, position: 'relative', mt: '10px' }}>
            <Button variant="outlined" onClick={onCompleteHandler} disabled={!isSuccessPay} fullWidth>
              {t(`button.${tkey}toComplete`)}
            </Button>
          </Box>
        </>
      )}
    </DrawerBottom>
  );
};

export default PaymentProducts;
