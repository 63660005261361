import { Stack, Button, Box } from '@mui/material';
import { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NoPhoto } from 'src/assets/images';
import { BPrice, DrawerBottom, UserLink, Markdown, CopyValue, Accordion } from 'src/atoms';
import { Paths, getImgSrc, pathsArg } from 'src/constants';
import { useAppSelector } from 'src/hooks';
import { VideoPlayer, WindowTitle } from 'src/molecules';
import { Delivery, GoalStates } from 'src/organisms';
import { TTargetCurrentItem, TTargetListItemAttributes, TargetStatus } from 'src/services';
import { selectUserMe } from 'src/store/userSlice';

import { FirstBlock, Img, Title, Id, Row, Label, Value } from './Card.styles';
import { CardProps } from './Card.types';

const AccordionCard: FC<{ field: string; value?: any; children?: JSX.Element }> = ({ field, value, children }) => {
  const { t } = useTranslation('app');
  return (
    <Accordion
      sx={{
        '& .Accordion_SummaryTitle': {
          paddingLeft: 0,
        },
        '& .Accordion_Details': {
          paddingLeft: 0,
          paddingRight: 0,
        },
      }}
      id={field}
      title={t(`card.${field}.title`)}
      data-qa={`card.${field}`}
      isExpanded
    >
      {value ? <Markdown>{t(value)}</Markdown> : children}
    </Accordion>
  );
};

const TargetCard: FC<{ compositeId: string; item: TTargetCurrentItem; itMy: boolean; tkey: string }> = ({
  compositeId,
  item,
  itMy,
  tkey,
}) => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  const [spec, setSpec] = useState<{
    [key: string]: TTargetListItemAttributes[];
  }>({});
  const [isVideoOpen, setVideoOpen] = useState(false);

  const [delivery, setDelivery] = useState<boolean>(false);

  useEffect(() => {
    const attributes = {} as {
      [key: string]: TTargetListItemAttributes[];
    };

    item.target.attributes.attributes.forEach((item) => {
      if (!attributes[item.group]) attributes[item.group] = [];
      attributes[item.group].push(item);
    });
    setSpec(attributes);
  }, [item]);

  const handlerDeliveryFinish = () => {
    setDelivery(false);
    navigate(pathsArg(Paths.NFTBurn, { compositeId: compositeId }));
  };

  return (
    <>
      <AccordionCard field="specifications">
        <>
          {Object.keys(spec).map((group: string) => (
            <Row key={`TargetCard_spec_${group}`}>
              <Label>{t(`goalItem.approve.${group}`)}:</Label>
              <Value>{spec[group].find((item) => item.selected)?.title}</Value>
            </Row>
          ))}
        </>
      </AccordionCard>

      <AccordionCard field="state">
        <GoalStates compositeId={compositeId} />
      </AccordionCard>

      {item?.video_link && (
        <DrawerBottom isOpen={isVideoOpen} onClose={() => setVideoOpen(false)}>
          <>
            <WindowTitle
              data-qa="goalItem.video.title"
              title={t('goalItem.video.title')}
              onClose={() => setVideoOpen(false)}
            />
            <VideoPlayer
              url={item.video_link}
              sx={{
                mb: '16px',
                '& video': {
                  borderRadius: '16px',
                  backgroundColor: 'background.paper',
                },
              }}
            />
          </>
        </DrawerBottom>
      )}

      {itMy ? (
        <Box sx={{ mt: '16px' }}>
          {item.status === TargetStatus.Feedback && (
            <Button
              data-qa={`card.${tkey}.button.${TargetStatus.Feedback}`}
              onClick={() => navigate(pathsArg(Paths.feedback, { id: item.id }))}
              variant="outlined"
              fullWidth
            >
              {t(`card.${tkey}.button.${TargetStatus.Feedback}`)}
            </Button>
          )}

          {item.status === TargetStatus.Progressing && (
            <>
              <Button
                data-qa={`card.${tkey}.button.${TargetStatus.Progressing}`}
                onClick={() => setDelivery(true)}
                variant="outlined"
                fullWidth
              >
                {t(`card.${tkey}.button.${TargetStatus.Progressing}`)}
              </Button>

              <DrawerBottom
                sx={{
                  height: '100%',
                  '& .DrawerBottom_styled': { border: 'none' },
                  '& .MuiDrawer-paper': { height: '100%' },
                }}
                isOpen={delivery}
                onClose={() => false}
              >
                <WindowTitle
                  title={t('delivery.title')}
                  data-qa={'delivery.title'}
                  onClose={() => setDelivery(false)}
                />
                <Box sx={{ height: 'calc(100% - 40px)', overflow: 'hidden', overflowY: 'scroll' }}>
                  <Delivery compositeId={compositeId} onFinish={handlerDeliveryFinish} />
                </Box>
              </DrawerBottom>
            </>
          )}
        </Box>
      ) : null}
    </>
  );
};

const Card: FC<CardProps> = ({ item, sx }) => {
  const { t } = useTranslation('app');
  const userMe = useAppSelector(selectUserMe);
  const itMy = userMe?.user_id === item.owner.user_id;

  const tkey = item.nft_type;
  return (
    <Stack sx={sx}>
      <FirstBlock sx={{ mb: '16px', pt: '16px' }}>
        {item.TARGET_NFT && item.TARGET_NFT.target && item.TARGET_NFT.video_link ? (
          <VideoPlayer url={item.TARGET_NFT.video_link} />
        ) : (
          <Img src={item.photo_uuid ? getImgSrc(item.photo_uuid, 'w600') : NoPhoto} />
        )}
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
          <Title>{t(item.title)}</Title>
          {item.token_id && (
            <Id>
              {t('card.id')} {item.token_id}
            </Id>
          )}
        </Stack>
      </FirstBlock>
      {item.owner && (
        <Row>
          <Label>{t('card.owner')}:</Label>
          <UserLink {...item.owner} showName={true} />
        </Row>
      )}
      {item.price && (
        <Row>
          <Label>{t('card.price')}:</Label>
          <BPrice price={item.price} />
        </Row>
      )}
      <Row>
        <Label>{t('card.txid')}:</Label>
        {item.txid && item.txid.txid ? <CopyValue value={item.txid.txid} link={item.txid.link} /> : null}
      </Row>
      <AccordionCard field="description" value={item.description} />
      {item.TARGET_NFT && item.TARGET_NFT.target && (
        <TargetCard compositeId={item.composite_id} tkey={tkey} itMy={itMy} item={item.TARGET_NFT} />
      )}
    </Stack>
  );
};

export default Card;
