import { FC } from 'react';

import { CarouselItemButton } from './CarouselItem.styles';
import { CarouselItemProps } from './CarouselItem.types';

const CarouselItem: FC<CarouselItemProps> = ({ id, img, title, disabled, selected, onClick }) => {
  const itemClickHandler = (id: string) => {
    if (disabled) return;
    onClick(id);
  };

  return (
    <CarouselItemButton
      onClick={() => itemClickHandler(id)}
      title={title}
      disabled={disabled}
      className={selected ? 'CarouselItem_selected' : ''}
      sx={{
        backgroundImage: `url(${img})`,
      }}
    ></CarouselItemButton>
  );
};

export default CarouselItem;
