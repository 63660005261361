import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { Box, Stack } from '@mui/material';
import { FC } from 'react';

import { ThemeColors } from 'src/styles';

import CircleGradientLoading from '../CircleGradientLoading';

import { DotFlashing, Circle } from './CircleLoading.styles';
import { CircleLoadingProps } from './CircleLoading.types';

const CircleLoading: FC<CircleLoadingProps> = ({ isLoading, size: sizeProp, className }) => {
  const size = sizeProp || 96;
  const k = 0.645833333333333;
  const thing = Math.ceil(size * 0.0625) || 1;
  const dfsize = Math.ceil(size * 0.104166666666667) || 1;
  return (
    <Stack justifyContent="center" alignItems="center" className={className}>
      <Box sx={{ position: 'relative', width: size, height: size }}>
        {!isLoading && (
          <Circle
            sx={{
              width: size,
              height: size,
              '&:after': {
                position: 'absolute',
                top: thing,
                left: thing,
                right: thing,
                bottom: thing,
              },
            }}
          >
            <DoneRoundedIcon sx={{ width: size * k, height: size * k, fill: 'url(#linearColors)', zIndex: 5 }} />
            <svg width={0} height={0}>
              <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
                <stop offset="0%" stopColor={ThemeColors.gold1} />
                <stop offset="100%" stopColor={ThemeColors.gold2} />
              </linearGradient>
            </svg>
          </Circle>
        )}

        {isLoading && (
          <>
            <CircleGradientLoading size={size} thickness={2.5} />
            <DotFlashing size={dfsize} />
          </>
        )}
      </Box>
    </Stack>
  );
};

export default CircleLoading;
