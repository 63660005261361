import { Typography, Stack, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { MetaMaskImg } from 'src/assets/images';

const MetamaskInstall = () => {
  const { t } = useTranslation('app');

  return (
    <Stack>
      <Typography sx={{ mb: 2 }}>{t('signIn.installMetamaskMsg')}</Typography>
      <Button variant="contained" href="https://metamask.io/" target="_blank" rel="noopener noreferrer">
        <Box component="img" className="MuiButton_iconimg__left" src={MetaMaskImg} alt="MetaMask Logo" sx={{ ml: 1 }} />
        {t('button.installMetamask')}
      </Button>
    </Stack>
  );
};

export default MetamaskInstall;
