import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors, ThemeGradients } from 'src/styles';

export const Wrapper = styled(Box)(() => ({
  position: 'relative',
  padding: '24px 8px',
}));

export const Opacity = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  bottom: 70,
  left: 0,
  right: 0,
  backgroundColor: ThemeColors.white,
  zIndex: 10,
  opacity: 0.75,
}));

export const Progress = styled(Box)(() => ({
  position: 'absolute',
  top: 24,
  height: 24,
  background: ThemeGradients.bgLight,
  zIndex: 1,
}));

export const ProgressFilled = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  height: 24,
  background: ThemeGradients.bg2,
}));

export const Grid = styled('div')(() => ({
  display: 'grid',
  position: 'relative',
  gridTemplateColumns: 'repeat(10, 1fr)',
  height: 24,
  zIndex: 3,
}));

export const Cell = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-around',
}));

export const Level = styled(Stack)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  width: 24,
  height: 24,
  border: '2px solid',
  borderRadius: 16,
  backgroundColor: ThemeColors.white,
  fontSize: '1rem',
  lineHeight: '21px',
  fontWeight: 600,
}));

export const Union = styled(Box)(() => ({
  position: 'absolute',
  top: 20,
  height: 32,
  zIndex: 5,

  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 16,
    padding: 2,
    background: 'linear-gradient(to right, #11bbfe, #8c41fb)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
  },
}));

export const Status = styled(Typography)(() => ({
  fontSize: '0.812rem',
  lineHeight: '18px',
  background: ThemeGradients.bg2,
  backgroundClip: 'text',
  fontWeight: 600,
  display: 'table',
  textFillColor: 'transparent',
  margin: '0',
  letterSpacing: '0.35px',
  width: '100%',
  marginTop: -16,
  textAlign: 'center',
}));

export const NotActive = styled(Box)(() => ({
  marginTop: 20,
}));

export const NotActiveText = styled(Typography)(() => ({
  fontSize: '0.812rem',
  lineHeight: '18px',
  fontWeight: 600,
  margin: '0',
  letterSpacing: '0.35px',
  width: '100%',
  textAlign: 'center',
}));
