import { IconButton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors, ThemeGradients, ThemeFontWeight } from 'src/styles';

export const Title = styled(Typography)(() => ({
  fontSize: '1.375rem',
  lineHeight: '28px',
  fontWeight: 500,
  background: ThemeGradients.text,
  backgroundClip: 'text',
  textFillColor: 'transparent',
  marginTop: 4,
  marginBottom: 24,
}));

export const SubTitle = styled(Typography)(() => ({
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: 400,
  color: ThemeColors.gray500,
  marginBottom: 34,
}));

export const Total = styled(Stack)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  justifyContent: 'space-between',
  flexDirection: 'column',
  marginTop: 29,
  marginBottom: 25,
  padding: '20px 0',

  '& .MuiTypography-root': {
    fontSize: '1.25rem',
    lineHeight: '24px',
    fontWeight: 500,
  },
}));

export const TotalLabel = styled(Typography)(() => ({
  // background: ThemeGradients.text,
  // backgroundClip: 'text',
  // textFillColor: 'transparent',
}));

export const Value = styled(Typography)(() => ({
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: 400,
  color: ThemeColors.gray500,
  display: 'flex',
  alignItems: 'center',

  span: {
    color: ThemeColors.black,
    fontWeight: 600,
    marginRight: 4,
  },

  svg: {
    marginRight: 4,
    fill: ThemeColors.orange,
  },
}));

export const CostCalc = styled(Stack)(() => ({
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginBottom: 18,
}));

export const CostCalcLabel = styled(Typography)(() => ({
  fontSize: '0.688rem',
  lineHeight: '22px',
  fontWeight: ThemeFontWeight.regular,
}));

export const CostCalcValue = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: 600,

  span: {
    marginRight: 4,
  },

  svg: {
    marginRight: 4,
    fill: ThemeColors.yellow,
  },
}));

export const CheckInfo = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 18,
}));

export const CheckTitleBlock = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '16px',
  color: theme.palette.secondary.dark,
})) as typeof Typography;

export const CheckTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: 400,
})) as typeof Typography;

export const RefreshButton = styled(IconButton)(({ theme }) => ({
  width: 24,
  height: 24,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.primary.main,
  padding: 0,

  svg: {
    width: 18,
    height: 18,
  },

  ':hover': {
    border: `1px solid ${theme.palette.background.default}`,
    color: theme.palette.secondary.main,
  },
}));
