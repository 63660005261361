import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeFontWeight } from 'src/styles';

export const StructureWidgetTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.375rem',
  fontWeight: ThemeFontWeight.regular,
  color: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})) as typeof Typography;

export const Image = styled('img')(() => ({
  overflow: 'auto',
}));
