import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, Stack } from '@mui/material';
import { FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { CircleLoading, SearchField } from 'src/atoms';
import { Paths } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { GoalStatusFilter, PageTitle } from 'src/molecules';
import { MapboxGlobe } from 'src/organisms';
import {
  getGlobeData,
  GlobePoint,
  GlobeType,
  TTargetStatus,
  FilterStatusList,
  FilterRoleList,
  UserRole,
} from 'src/services';
import { logger } from 'src/utils';

const Globe: FC = () => {
  const { t } = useTranslation('app');
  const { pathname } = useLocation();
  const { throwServerError } = useContext(ErrorContext);

  // const { width: windowWidth } = useWindowSize();

  const loaderSize = 345; //windowWidth > 345 ? 345 : windowWidth - 16;

  const navigate = useNavigate();
  const onCloseHandler = () => navigate(Paths.dashboard);

  const [data, setData] = useState<GlobePoint[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [isCircleLoading, setCircleLoading] = useState(true);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const [statusFilter, setStatusFilter] = useState<TTargetStatus[]>([]);
  const [roleFilter, setRoleFilter] = useState<UserRole[]>(FilterRoleList);

  const [zoom, setZoom] = useState<number>();
  const [bounds, setBounds] = useState<[number, number, number, number]>();

  useEffect(() => {
    // const type = pathname === Paths.teamGlobeAll ? GlobeType.AllProject : GlobeType.MyTeam;
    const type = GlobeType.AllProject;
    setLoading(true);
    getGlobeData(
      type,
      {
        search: searchFilter,
        status: statusFilter,
        role: roleFilter,
      },
      zoom,
      bounds
    )
      .then(({ data }) => {
        setData(data.items);
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
        logger.error(error);
      })
      .finally(() => {
        setLoading(false);
        setCircleLoading(false);
      });
  }, [pathname, throwServerError, searchFilter, statusFilter, roleFilter, zoom, bounds]);

  const handlerZoom = (zoom: number, bounds: [number, number, number, number]) => {
    setZoom(zoom);
    setBounds(bounds);
  };

  return (
    <>
      <PageTitle
        title={t('globe.title')}
        data-qa={'globe.title'}
        help={{ tkey: 'globe' }}
        onClose={onCloseHandler}
        isTransparent
        isLoading={isLoading}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 70,
          zIndex: 3,
          left: '50%',
          right: 0,
          transform: 'translateX(-50%)',
          width: '100%',
          maxWidth: '510px',
          padding: '16px 16px 0px',
          pointerEvents: 'none',
        }}
      >
        {!searchOpen ? (
          <IconButton
            sx={{
              border: '1px solid',
              backgroundColor: 'background.paper',
              pointerEvents: 'auto',
              float: 'right',
            }}
            onClick={() => setSearchOpen(true)}
          >
            <SearchIcon />
          </IconButton>
        ) : (
          <SearchField
            sx={{
              pointerEvents: 'auto',
            }}
            name="search"
            defaultValue={searchFilter}
            placeholder={t('globe.search.placeholder')}
            maxlength={150}
            onClear={() => {
              setSearchFilter(undefined);
              setSearchOpen(false);
            }}
            onSearch={(value) => {
              setSearchFilter(value);
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          height: '100%',
          position: 'relative',
          '& .Globe_Loader': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 1,
            '&__circle': {
              position: 'absolute',
              zIndex: 1,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            },

            '&:before': {
              content: '""',
              position: 'absolute',
              left: 0,
              right: 0,
              zIndex: 1,
              display: 'block',
              width: '100%',
              height: '100%',
              background: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(16px)',
            },
          },
        }}
      >
        {/*<Box
          sx={{
            position: 'absolute',
            top: 102,
            zIndex: 2,
            left: '50%',
            right: 0,
            transform: 'translateX(-50%)',
            width: '98%',
            maxWidth: '510px',
          }}
        >
          <Switch2Links currentPath={pathname} links={getSwitchLinksForGlobe(t)} isInverted />
        </Box>*/}
        {isCircleLoading && (
          <Box className="Globe_Loader">
            <CircleLoading isLoading={true} size={loaderSize} className="Globe_Loader__circle" />
          </Box>
        )}
        <MapboxGlobe data={data} onZoom={handlerZoom} />
      </Box>

      <Stack
        sx={{
          width: '100%',
          position: 'absolute',
          bottom: 32,
          padding: '0px 16px 4px',
        }}
      >
        <GoalStatusFilter
          sx={{ width: '100%' }}
          value={roleFilter}
          filter={FilterRoleList}
          tkey="role.state"
          onChange={(role) => setRoleFilter(role as UserRole[])}
        />

        <GoalStatusFilter
          sx={{ width: '100%', mt: '10px' }}
          value={statusFilter}
          filter={FilterStatusList}
          tkey="goal.state"
          onChange={(status) => setStatusFilter(status as TTargetStatus[])}
        />
      </Stack>
    </>
  );
};

export default Globe;
