import { Box, Skeleton } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Paths } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { PageTitle, ProductWidget } from 'src/molecules';
import { GoalListHistoryWidget } from 'src/organisms';
import { getGoalCurrent, TTargetCurrentItem } from 'src/services';
import { ThemePaddings } from 'src/styles';
import { logger } from 'src/utils';

const Goal: FC = () => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [item, setItem] = useState<TTargetCurrentItem | undefined>(undefined);

  const onCloseHandler = () => navigate(Paths.dashboard);

  useEffect(() => {
    setLoading(true);
    getGoalCurrent()
      .then(({ data }) => {
        setItem(data);
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
        logger.error(error);
      })
      .finally(() => setLoading(false));
  }, [throwServerError]);

  return (
    <>
      <Box sx={{ p: ThemePaddings.page, pb: ThemePaddings.pageX }}>
        <PageTitle
          title={t('goal.title')}
          data-qa={'goal.title'}
          help={{ tkey: 'goal' }}
          onClose={onCloseHandler}
          isLoading={isLoading}
        />
        {!isLoading ? (
          <ProductWidget
            item={item?.target}
            balance={item?.balance}
            cost={item?.cost}
            progress={item?.progress}
            height={300}
            showActions={true}
          />
        ) : (
          <Skeleton variant="rectangular" sx={{ height: '364px', borderRadius: '15px' }} />
        )}
      </Box>
      <GoalListHistoryWidget limit={5} />
    </>
  );
};

export default Goal;
