import { Box } from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Paths } from 'src/constants';

import GoalItemParams from '../GoalItemParams';
import GoalList from '../GoalList';
import Help from '../Help';

const StudyTarget: FC = () => {
  const navigate = useNavigate();

  const [help, setHelp] = useState<boolean>(true);
  const [goalId, setGoalId] = useState<string>();
  const onApprove = () => navigate(Paths.study);

  if (help) {
    return <Help tkey="study.target" sx={{ height: '100%' }} onNext={() => setHelp(false)} />;
  } else {
    return (
      <Box sx={{ height: '100%' }}>
        {goalId ? (
          <GoalItemParams withCustome={true} compositeId={goalId} onApproveClose={() => onApprove()} />
        ) : (
          <GoalList sx={{ height: '100%' }} onClick={(item) => setGoalId(item.composite_id)} />
        )}
      </Box>
    );
  }
};

export default StudyTarget;
