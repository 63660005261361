import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// import { ThemeFontWeight } from 'src/styles';

export const Title = styled(Box)(({ theme }) => ({
  // fontSize: '1.375rem',
  // fontWeight: ThemeFontWeight.regular,
  color: theme.palette.primary.main,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  whiteSpace: 'normal',
})) as typeof Box;

export const Image = styled('img')(() => ({
  overflow: 'auto',
}));
