import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DemoData } from 'src/services';
import { RootState } from 'src/store';

const initialState: DemoData = {
  url: '',
  demo_url: '',
  is_demo: false,
};

const demoSlice = createSlice({
  name: 'demo',
  initialState,
  reducers: {
    setDemoData: (state, action: PayloadAction<DemoData>) => {
      state.url = action.payload.url;
      state.demo_url = action.payload.demo_url;
      state.is_demo = action.payload.is_demo;
    },
  },
});

export const { setDemoData } = demoSlice.actions;

export const selectDemoData = (state: RootState) => state.demo;

export const demoReducer = demoSlice.reducer;
