import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';

import { IInputFieldProps } from './CheckBoxInput.types';

const CheckboxField = <T extends FieldValues>(props: IInputFieldProps<T>) => {
  const { name, control, defaultValue, label, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControlLabel
          label={label}
          value="top"
          {...rest}
          control={
            <Checkbox
              sx={{ color: error ? 'red' : 'currentColor' }}
              checked={value}
              onChange={onChange}
              checkedIcon={<CheckBoxOutlinedIcon />}
            />
          }
        />
      )}
    />
  );
};

export default CheckboxField;
