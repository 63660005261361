import { Box, CircularProgress, Stack } from '@mui/material';
import { FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BreadCrumbs, GoalListItem, Markdown, ScrollLoader } from 'src/atoms';
import { DEFAULT_LIMIT, Paths, getImgSrc, isMarket, pathsArg } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { getGoalList, TPathHierarchy, TTargetListItem } from 'src/services';
import { logger } from 'src/utils';

import { GoalListProps } from './GoalList.types';

const GoalList: FC<GoalListProps> = ({ parent, search, hidePath, limit: limitProp, onClick, sx }) => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [isPending, setPending] = useState(false);
  const [items, setItems] = useState<TTargetListItem[] | null>(null);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const [path, setPath] = useState<TPathHierarchy | undefined>();

  const onCategoryClick = (id?: number) =>
    id ? navigate(pathsArg(Paths.pageMarketCategory, { id })) : navigate(Paths.pageMarket);

  const limit = limitProp || DEFAULT_LIMIT;

  useEffect(() => {
    setOffset(0);
  }, [search, parent]);

  useEffect(() => {
    if (offset === 0) {
      setLoading(true);
      getGoalList({ offset, limit }, { parent, search })
        .then(({ data }) => {
          setItems(data.results);
          setOffset(limit);
          setHasMore(!(data.results.length < offset));
          setPath(data.path || null);
        })
        .catch((error) => {
          if (error.response?.status >= 500) throwServerError();
          logger.error(error);
        })
        .finally(() => setLoading(false));
    }
  }, [limit, offset, parent, search, throwServerError]);

  const loadMoreItems = () => {
    if (!hasMore) return;

    if (!isPending) {
      setPending(true);
      getGoalList({ offset, limit }, { parent, search })
        .then(({ data }) => {
          items && setItems([...items, ...data.results]);
          setOffset((prevOffset) => prevOffset + limit);
          setHasMore(!(data.results.length < offset));
        })
        .catch((error) => {
          if (error.response?.status >= 500) throwServerError();
          logger.error(error);
        })
        .finally(() => setPending(false));
    }
  };

  const isFolders = Boolean(items && items?.length && items[0].is_folder);

  const folderSxItem = {
    p: '0px 0px 4px 4px',
    borderRadius: '4px',
    overflow: 'hidden',
    width: '50%',
    '& img': {
      aspectRatio: '1/1',
      padding: '15%',
    },
  };

  const folderSxBox = {
    position: 'relative',
    mb: '16px',
    display: 'flex',
    flexWrap: 'wrap',
    '& .GoalListItem_unactive': {
      opacity: 0.5,
    },
  };

  const sxItem = {
    mb: '4px',
  };

  const sxBox = {
    position: 'relative',
    mb: '16px',
  };

  return (
    <Box sx={{ height: '100%', ...sx }}>
      {isLoading ? (
        <Stack alignItems="center" sx={{ my: '16px' }}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          {isMarket && !hidePath && <BreadCrumbs sx={{ m: '16px', mt: 0 }} items={path} onClick={onCategoryClick} />}
          {items?.length ? (
            <Box sx={isFolders ? folderSxBox : sxBox}>
              {items.map((item) => (
                <GoalListItem
                  sx={isFolders ? folderSxItem : sxItem}
                  key={`goal_item_${item.composite_id}`}
                  item={item}
                  onClick={(item: TTargetListItem) => {
                    if (item.is_folder) {
                      onCategoryClick(item.target_id);
                    } else if (onClick) {
                      onClick(item);
                    }
                  }}
                />
              ))}
              {hasMore && limitProp === undefined && (
                <ScrollLoader isLoading={isPending} hasMoreData={hasMore} onLoad={loadMoreItems} />
              )}
            </Box>
          ) : (
            <Stack direction="column" sx={{ p: '16px' }}>
              <Box
                component="img"
                src={getImgSrc(t('goalList.empty.img'), 's600')}
                alt={t('goalList.empty.title')}
                sx={{ width: '100%' }}
              />
              <Markdown>{t('goalList.empty')}</Markdown>
            </Stack>
          )}
        </>
      )}
    </Box>
  );
};

export default GoalList;
