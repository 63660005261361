import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ClanListItem, ClanLevel } from 'src/services';
import { RootState } from 'src/store';

export interface ClanState {
  activeClan: ClanListItem | null;
  activeClanId: number;
  level: number;
  clanLevels: ClanLevel[] | null;
  haveClanIds: number[];
}

const initialState: ClanState = {
  activeClan: null,
  activeClanId: 0,
  level: 0,
  clanLevels: null,
  haveClanIds: [],
};

const clanSlice = createSlice({
  name: 'clan',
  initialState,
  reducers: {
    setSelectedClan: (state, action: PayloadAction<ClanListItem | null>) => {
      state.activeClan = action.payload;
      state.activeClanId = action.payload?.id || 0;
    },
    setSelectedClanId: (state, action: PayloadAction<number | null>) => {
      state.activeClanId = action.payload || 0;
    },
    setCurrentLevel: (state, action: PayloadAction<number>) => {
      state.level = action.payload || 0;
    },
    setClanLevels: (state, action: PayloadAction<ClanLevel[] | null>) => {
      state.clanLevels = action.payload;
    },
    setHaveClanIds: (state, action: PayloadAction<number>) => {
      const index = state.haveClanIds.findIndex((i) => i === action.payload);
      index < 0 ? state.haveClanIds.push(action.payload) : state.haveClanIds.splice(index, 1);
    },
  },
});

export const { setSelectedClan, setSelectedClanId, setCurrentLevel, setClanLevels, setHaveClanIds } = clanSlice.actions;

export const selectClanState = (state: RootState) => state.clan;

export const clanReducer = clanSlice.reducer;
