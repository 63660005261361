import { LinearProgress } from '@mui/material';

const Loading = () => (
  <LinearProgress
    className="LinearProgress"
    sx={{ position: 'absolute', marginLeft: 0, left: 0, top: 0, width: '100%', zIndex: 99 }}
  />
);

export default Loading;
