import { keyframes, styled } from '@mui/material/styles';

import { ThemeColors, ThemeGradients } from 'src/styles';

const dotFlashing = keyframes`
  0% {
      background: ${ThemeGradients.bg};
  }

  50% {
    background: ${ThemeColors.gold1};
  }

  100% {
    background: ${ThemeColors.gold2};
  }
`;

export const DotFlashing = styled('div', {
  shouldForwardProp: (props) => props !== 'size',
})<{
  size: number;
}>(({ size }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: size,
  height: size,
  borderRadius: '50%',
  background: ThemeGradients.bg,
  animation: `${dotFlashing} .5s infinite linear alternate`,
  animationDelay: '.3s',
  transform: 'translate(-50%, -50%)',

  '&:before': {
    content: '""',
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    left: -(size + size / 2),
    width: size,
    height: size,
    borderRadius: '50%',
    background: ThemeGradients.bg,
    animation: `${dotFlashing} .5s infinite alternate`,
    animationDelay: '0s',
  },
  '&:after': {
    content: '""',
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    left: size + size / 2,
    width: size,
    height: size,
    borderRadius: '50%',
    background: ThemeGradients.bg,
    animation: `${dotFlashing} .5s infinite alternate`,
    animationDelay: '.6s',
  },
}));

export const Circle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  backgroundClip: 'padding-box',
  borderRadius: '100%',
  background: ThemeGradients.bg,

  '&:after': {
    position: 'absolute',
    top: 6,
    left: 6,
    right: 6,
    bottom: 6,
    backgroundColor: theme.palette.background.paper,
    content: '""',
    zIndex: 1,
    borderRadius: '100%',
  },
}));
