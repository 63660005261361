import { Box, Stack } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { DrawerBottom, EmptyTeam, Loading } from 'src/atoms';
import { isMarket } from 'src/constants';
import { TeamFilterContext } from 'src/context';
import { ErrorContext } from 'src/context/ErrorContext';
import { useAppSelector } from 'src/hooks';
import { GoalStatusFilter, InfoTable, WindowTitle } from 'src/molecules';
import { ClansSwarm, Tree } from 'src/organisms';
import { Ancestors, FilterRoleList, getTeam, TeamChildrenInTree, TeamTree, TeamType, UserRole } from 'src/services';
import { selectUserMe } from 'src/store/userSlice';
import { SearchParams } from 'src/utils';

const TeamList: FC = () => {
  const { t } = useTranslation('app');
  const { search: searchParams } = useLocation();
  const userId = new SearchParams(searchParams).getByType('userId', 'number') as number | undefined;
  const userMe = useAppSelector(selectUserMe);
  const { throwServerError } = useContext(ErrorContext);

  const [isLoading, setLoading] = useState(false);
  const [childrenData, setChildrenData] = useState<TeamChildrenInTree[]>([]);
  const [ancestors, setAncestors] = useState<Ancestors[]>([]);
  const [meta, setMeta] = useState<TeamTree['meta']>();
  const [currentId, setCurrentId] = useState<number>(userMe?.player_id || 0);

  const [openSwarm, setOpenSwarm] = useState<boolean>(false);

  const { role: roleFilter, changeRole: setRoleFilter } = useContext(TeamFilterContext);

  const onSetCurrentIdHandler = (memberId: number) => {
    if (currentId === memberId) {
      const currentUser = ancestors.find((child) => child.id === memberId);
      if (currentUser && currentUser.user_id) {
        // если кликнули повторно на выделенного
        // navigate(pathsArg(Paths.profileUser, { id: currentUser.user_id }));
        setOpenSwarm(true);
      }
    } else setCurrentId(memberId);
  };

  useEffect(() => {
    setLoading(true);
    if (currentId) {
      getTeam(TeamType.Tree, {
        playerId: currentId,
        userId,
        role: roleFilter,
      })
        .then(({ data }) => {
          const dataTree = data as TeamTree;

          setAncestors([
            ...dataTree.ancestors,
            {
              id: dataTree.id,
              display_name: dataTree.display_name,
              photo_uuid: dataTree.photo_uuid,
              user_id: dataTree.user_id,
            },
          ]);
          setChildrenData(dataTree.children);
          setMeta(dataTree.meta);
        })
        .catch((error) => {
          if (error.response.status >= 500) throwServerError();
        })
        .finally(() => setLoading(false));
    } else {
      setTimeout(() => {
        setCurrentId(userMe?.player_id || 0);
      }, 1000);
    }
  }, [currentId, userId, throwServerError, userMe, roleFilter]);

  return (
    <>
      {isLoading && <Loading />}
      <Stack sx={{ height: isMarket ? '100%' : 'calc(100% - 164px)', paddingTop: '0px' }}>
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          {!isLoading && ((ancestors && ancestors.length > 1) || (childrenData && childrenData.length)) ? (
            <Tree
              currentId={currentId}
              ancestors={ancestors}
              childrenData={childrenData}
              onNodeClick={onSetCurrentIdHandler}
              isLoading={isLoading}
            />
          ) : isLoading ? null : (
            <EmptyTeam />
          )}
        </Box>
        <Stack
          sx={{
            flex: 'none',
            width: '100%',
          }}
        >
          <GoalStatusFilter
            sx={{ width: '100%', padding: '10px 16px' }}
            value={roleFilter}
            filter={FilterRoleList}
            tkey="role.state"
            onChange={(role) => setRoleFilter(role as UserRole[])}
          />

          <InfoTable
            sx={{
              borderTop: '1px solid',
              borderColor: 'secondary.main',
              width: '100%',
            }}
            items={[
              {
                title: t('team.meta.team.title'),
                mark: t('team.meta.team.mark'),
                value: meta?.team.team_number,
              },
              {
                title: t('team.meta.private.title'),
                mark: t('team.meta.private.mark'),
                value: meta?.team.personal_team_number,
              },
            ]}
          />
        </Stack>
      </Stack>

      {openSwarm && currentId && (
        <DrawerBottom
          isOpen={openSwarm}
          onClose={() => setOpenSwarm(false)}
          sx={{
            height: '100%',
            '& .DrawerBottom_styled': { padding: 0 },
            '& .MuiDrawer-paper': { height: '100%' },
          }}
        >
          <WindowTitle title={t('team.swarm.title')} onClose={() => setOpenSwarm(false)} sx={{ p: '16px', pb: 0 }} />
          <ClansSwarm userId={currentId} sx={{ height: 'calc(100% - 64px)' }} />
        </DrawerBottom>
      )}
    </>
  );
};

export default TeamList;
