import { Box, Button, Divider as DividerMUI, IconButton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FONTS, ThemeColors, ThemeGradients } from 'src/styles';

export const Wrapper = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingLeft: 16,
  paddingRight: 16,
  maxWidth: 400,
  margin: '24px auto',
}));

export const RemoveButton = styled(IconButton)(() => ({
  border: `1px solid ${ThemeColors.redLight}`,
  color: ThemeColors.redLight,
  width: 60,
  height: 60,

  svg: {
    width: 32,
    height: 32,
  },

  '& .MuiCircularProgress-root': {
    width: '32px!important',
    height: '32px!important',
    color: ThemeColors.redLight,
  },
}));

export const InviteButton = styled(Button)(() => ({
  marginLeft: 4,
  padding: '0 29px',
}));

export const CloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: 14,
  right: 14,
}));

export const Title = styled(Typography)(() => ({
  fontSize: '1.375rem',
  lineHeight: '28px',
  fontWeight: 500,
  background: ThemeGradients.text,
  backgroundClip: 'text',
  textFillColor: 'transparent',
  margin: '0 auto 16px',
  textAlign: 'center',
  maxWidth: 190,
}));

export const Text = styled(Typography)(() => ({
  fontSize: '0.75rem',
  lineHeight: '16px',
  margin: '0 auto 16px',
  textAlign: 'center',
  maxWidth: 290,
}));

export const InviteRealFriendButton = styled(Button)(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  margin: '0 auto',
}));

export const Label = styled('span')(() => ({
  fontFamily: FONTS,
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: 600,
  marginBottom: 2,
}));

export const SubLabel = styled('span')(() => ({
  fontFamily: FONTS,
  fontSize: '0.75rem',
  lineHeight: '16px',
  fontWeight: 400,
}));

export const Divider = styled(DividerMUI)(() => ({
  marginTop: 24,
  marginBottom: 24,
}));

export const AddDemoBot = styled(Box)(() => ({
  borderRadius: 24,
  background: ThemeGradients.bg2,
  padding: 2,
  marginBottom: 16,
}));

export const BotButton = styled(Button)(() => ({
  background: ThemeColors.white,
  border: 0,
  width: '100%',
  height: '100%',
  borderRadius: 22,
  padding: '10px 0',

  svg: {
    width: 54,
    height: 71,
  },
}));

export const BotInfo = styled(Stack)(() => ({
  flexDirection: 'column',
  textAlign: 'left',
  maxWidth: 230,
  marginLeft: 9,
}));

export const AddBotTitle = styled(Typography)(() => ({
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: 600,
  marginBottom: 2,
}));

export const AddBotText = styled(Typography)(() => ({
  fontSize: '0.75rem',
  lineHeight: '16px',
  fontWeight: 400,
}));
