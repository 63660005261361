import { TOrderPrice } from 'src/services';

export type TTargetListItemAttributes = {
  id: number;
  group: string; // любое название характеристики
  selected: boolean; // признак того что характеристика выбрана
  exclude: number[]; // список ид, которые исплючаются из выбора при активной характеристике (противоречащие)
  disabled?: boolean; // задизаблен при текущем выборе

  img?: string; // специфический параметр для цвета
  title: string; // текстовое обозначение характеристики
  type: 'colors' | 'switch' | 'buttons'; // типы характеристик
  // 'colors' - строка кружочков с картинками
  // 'switch' - строка перекючателей, фон общий, активная со контрастным фоном
  // 'buttons' - группа нескольких кнопок, активная с рамкой
  value: string; // значение характеристики
};

export type TTargetListItemCosts = {
  [key: number]: TOrderPrice;
};

export type TPathHierarchy = {
  id: number;
  title: string;
  link?: string;
  parent?: number;
  children?: TPathHierarchy;
};

export type TTargetListItem = {
  attributes: {
    cost: TTargetListItemCosts;
    attributes: TTargetListItemAttributes[];
  };
  description: string;
  is_active: boolean; // активен ли товар
  is_folder: boolean; // признак что это категория/папка
  name: string; // имя с уточнением характеристик
  registry_name: string; // общее имя для реестров
  photo_uuid: string; // uuid картинки
  carousel: string[]; // список uuid картинок
  target_id: number;
  composite_id: string; // сборный ID с указанием типа
  type: string;
  video_link: string;
  delivery?: string;
  cost?: TOrderPrice;
  terms_of_payment?: string;
  path?: TPathHierarchy;
};

export type TTargetList = {
  path: TPathHierarchy;
  results: TTargetListItem[];
};

export type TTargetItemApproveFuntion = (
  item: TTargetListItem,
  attributes: TTargetListItemAttributes[],
  callback: (item: TTargetListItem) => void,
  errback: (message: string) => void
) => void;

export enum TargetStatus {
  Current = 'CURRENT',
  Feedback = 'WAIT_FEEDBACK',
  ModerateFeedback = 'MODERATE_FEEDBACK',
  GetProduct = 'COMPLETED',
  GetMoney = 'GET_MONEY',
  Progressing = 'PROCESSING',
  Failed = 'FAILED',
  Delivery = 'WAIT_DELIVERY',
}

export type TTargetStatus =
  | TargetStatus.Current
  | TargetStatus.Feedback
  | TargetStatus.ModerateFeedback
  | TargetStatus.GetMoney
  | TargetStatus.Progressing
  | TargetStatus.GetProduct
  | TargetStatus.Failed
  | TargetStatus.Delivery;

export const FilterStatusList = [TargetStatus.GetProduct, TargetStatus.Feedback, TargetStatus.GetMoney];

export type TTargetCurrentItem = {
  id: number;
  composite_id: string; // сборный ID с указанием типа
  balance: TOrderPrice;
  cost: TOrderPrice;
  owner: number;
  progress: number;
  status: TTargetStatus;
  ddt: number; // время в часах сколько осталось для отзыва
  target: TTargetListItem;
  updated_at: string;
  track_id?: string;
  video_link?: string;
};

export type TTargetHistory = {
  count: number;
  results: TTargetCurrentItem[];
};

export type TTargetFeedback = {
  updated_at: string;
  user: {
    display_name: string;
    photo_uuid: string;
    telegram: string;
    user_id: number;
  };
  video_link: string;
};

export type TTargetFeedbackList = {
  count: number;
  results: TTargetFeedback[];
};

export type TTargetOnGlobe = {
  id: number;
  composite_id: number;
  user_id: number;
  status: TTargetStatus;
  updated_at: string;
};

export type TTargetStatusHistoryLink = {
  label: string; // заголовок
  value: string; // значение
  link?: string; // ссылка, если есть
};

export type TTargetStatusHistory = {
  status: TTargetStatus;
  id: number;
  title: string;
  description: string;
  udt: string;
  links?: TTargetStatusHistoryLink[];
  state?: boolean; // пройден/нет. undefined - текущий
};
