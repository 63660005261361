import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Stack, Box, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TPathHierarchy } from 'src/services';

import { BreadCrumbsProps } from './BreadCrumbs.types';

type TPath = { id: number; title: string; link?: string };

const BreadCrumbs: FC<BreadCrumbsProps> = ({ items, onClick, sx }) => {
  const { t } = useTranslation('app');

  const [pathList, setPathList] = useState<TPath[]>([]);

  const itemClickHandler = (id: number | undefined) => onClick && onClick(id);

  const path = useCallback(() => {
    if (!items) return [];

    const pathList: TPath[] = [];

    const _pushPath = (item: TPathHierarchy, pathList: TPath[]) => {
      pathList.push({
        id: item.id,
        title: item.title,
        link: item.link,
      });

      if (item.children) {
        _pushPath(item.children, pathList);
      } else {
        return pathList;
      }
    };

    _pushPath(items, pathList);
    pathList.reverse();
    return pathList;
  }, [items]);

  useEffect(() => {
    setPathList(path());
  }, [items, path]);

  return (
    <Stack direction="row" sx={{ cursor: 'pointer', ...sx }}>
      <Box key={`BreadCrumbs_${0}`} sx={{ mr: '4px' }} onClick={() => itemClickHandler(undefined)}>
        <HomeOutlinedIcon fontSize="small" />
      </Box>
      <Stack direction="row-reverse" sx={{ cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {pathList.map((item, idx) => (
          <Typography
            key={`BreadCrumbs_${item.id}`}
            sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', flex: idx === 0 ? 'none' : 1 }}
          >
            •
            <Box component="span" onClick={() => itemClickHandler(item.id)} sx={{ mx: '4px' }}>
              {t(item.title || '')}
            </Box>
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
};

export default BreadCrumbs;
