import { Instagram, Telegram, YouTube } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorContext } from 'src/context/ErrorContext';
import { getSocialNetworks, TSocialNetworks } from 'src/services';
import { logger } from 'src/utils';

import { SNListProps } from './SNList.types';

const SNButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  padding: 0,
  width: '40px',
  height: '40px',
  minWidth: '40px',
  minHeight: '40px',
  borderColor: theme.palette.background.paper,
  '&.SNButton_active': {
    borderColor: 'transparent',
  },
  '&:not(.SNButton_active):after': {
    display: 'none',
  },
})) as typeof Button;

const icons = {
  instagram: Instagram,
  telegram: Telegram,
  youtube: YouTube,
};

const SNList: FC<SNListProps> = ({ items: itemsProps, official, sx }) => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);

  const [, setLoading] = useState(!itemsProps);
  const [items, setItems] = useState<TSocialNetworks[] | null>(itemsProps || null);

  useEffect(() => {
    if (items) return;
    setLoading(true);
    getSocialNetworks()
      .then(({ data }) => {
        setItems(data);
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
        logger.error(error);
      })
      .finally(() => setLoading(false));
  }, [items, throwServerError]);

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        flexWrap="nowrap"
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: '40px', ...sx }}
      >
        {items &&
          items.map((item) => {
            const Icon = icons[item.icon];
            const link = official ? item.official : item.link;
            if (!link) return null;

            return (
              <SNButton
                key={`snbutton_${item.icon}_${item.id}`}
                variant="outlined"
                onClick={() => window.open(link, '_blank')}
                className={item.subscribed ? 'SNButton_active' : ''}
                title={t(item.title)}
              >
                <Icon />
              </SNButton>
            );
          })}
      </Stack>
    </>
  );
};

export default SNList;
