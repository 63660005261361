import { Telegram } from '@mui/icons-material';
import { Stack, Button, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'src/hooks';
import { TTelegramUser, addUserSN } from 'src/services';
import { selectUser, signInUser } from 'src/store/userSlice';
import { parseErrorTr } from 'src/utils';

import { TelegramLoginProps } from './TelegramLogin.types';

const botName = process.env.REACT_APP_TELEGRAM_BOT;
const botID = process.env.REACT_APP_TELEGRAM_BOT_ID;

export interface TelegramLoginButtonProps {
  title: string;
  botName: string;
  dataOnAuth?: (user: TTelegramUser) => void;
  dataAuthUrl?: string;
  buttonSize?: 'large' | 'medium' | 'small';
  cornerRadius?: number;
  requestAccess?: boolean;
  usePic?: boolean;
  lang?: string;
  disabled?: boolean;
  botID?: string;
}

const TelegramLoginButton: FC<TelegramLoginButtonProps> = ({
  botName,
  buttonSize,
  cornerRadius,
  requestAccess,
  usePic,
  dataAuthUrl,
  dataOnAuth,
  lang,
  title,
  disabled,
  botID,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const widgetVersion = 22;

    const globeWindow: any = window;

    globeWindow.onTelegramAuth = (user: TTelegramUser) => dataOnAuth && dataOnAuth(user);

    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js?' + widgetVersion;
    script.setAttribute('data-telegram-login', botName);
    script.setAttribute('data-size', buttonSize || 'large');
    if (cornerRadius !== undefined) {
      script.setAttribute('data-radius', cornerRadius.toString());
    }
    if (requestAccess) {
      script.setAttribute('data-request-access', 'write');
    }
    script.setAttribute('data-userpic', (usePic || false).toString());
    if (lang) {
      script.setAttribute('data-lang', lang);
    }
    if (dataAuthUrl !== undefined) {
      script.setAttribute('data-auth-url', dataAuthUrl);
    } else {
      script.setAttribute('data-onauth', 'onTelegramAuth(user)');
    }
    script.async = true;

    if (ref.current) {
      const TButton: any = ref.current;
      TButton.appendChild(script);
    }
  }, [botName, buttonSize, cornerRadius, dataAuthUrl, dataOnAuth, lang, requestAccess, usePic]);

  return (
    <Box sx={{ position: 'relative' }}>
      <div className="TelegramLoginButton_root" ref={ref}></div>
      {botID && (
        <Button
          disabled={disabled}
          onClick={() => {
            const tgScriptWin: any = window;
            tgScriptWin.Telegram.Login.auth({ bot_id: botID, request_access: true }, (data: TTelegramUser) => {
              if (data) {
                dataOnAuth && dataOnAuth(data);
              }
            });
          }}
          variant="text"
          fullWidth
          sx={{ '& svg': { mr: '4px' } }}
        >
          <Telegram /> {title}
        </Button>
      )}
    </Box>
  );
};

const TelegramLogin: FC<TelegramLoginProps> = ({ referral, connection, isReg, onFinish }) => {
  const dispatch = useAppDispatch();
  const { isShowAuth } = useAppSelector(selectUser);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('app');
  const page = isReg ? 'registration' : connection ? 'connection' : 'signin';

  const handleTelegramResponse = (user: TTelegramUser) => {
    if (connection) {
      addUserSN('telegram', user)
        .then(() => onFinish && onFinish())
        .catch((error) => {
          enqueueSnackbar(parseErrorTr(`telegram_${page}`, error), { variant: 'error' });
        });
    } else {
      dispatch(
        signInUser({
          provider: 'telegram',
          authData: user,
          referral_uuid: referral,
        })
      );
    }
  };

  const botStyle: any = botID
    ? {
        position: 'absolute',
        top: -1000,
        left: -1000,
        opacity: 0,
      }
    : {
        textAlign: 'center',
      };

  return (
    <Stack
      sx={{
        '& .TelegramLoginButton_root': botStyle,
      }}
    >
      {botName && (
        <TelegramLoginButton
          data-qa={`signIn.${page}.telegram.button`}
          title={t(`signIn.${page}.telegram.title`)}
          disabled={isShowAuth}
          usePic={false}
          dataOnAuth={handleTelegramResponse}
          botName={botName}
          botID={botID}
        />
      )}
    </Stack>
  );
};

export default TelegramLogin;
