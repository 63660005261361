import { Box, Typography, Grid } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BPrice, Loading, SwitchButtons } from 'src/atoms';
import { Paths } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { InfoTable, LevelsStat } from 'src/molecules';
import { geStatByUserId, TProfileStat } from 'src/services';
import { logger } from 'src/utils';

import { UserStateProps } from './UserState.types';

const getPeriod = (period?: string) => {
  let begin = new Date(),
    end = new Date();
  switch (period) {
    case '24h':
      end.setDate(end.getDate() - 1);
      break;
    case '1w':
      end.setDate(end.getDate() - 7);
      break;
    case '1m':
      end.setDate(end.getDate() - 30);
      break;
    case '3m':
      end.setDate(end.getDate() - 90);
      break;
    case '6m':
      end.setDate(end.getDate() - 180);
      break;
    case '1y':
      end.setDate(end.getDate() - 365);
      break;
    default:
      return [undefined, undefined];
  }
  return [begin, end];
};

const UserState: FC<UserStateProps> = ({ userId }) => {
  const { t } = useTranslation('app');
  const { throwServerError, throwNotFound } = useContext(ErrorContext);
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [state, setState] = useState<TProfileStat>();

  const [period, setPeriod] = useState('all');

  useEffect(() => {
    setLoading(true);
    logger.log(getPeriod(period));
    const [begin, end] = getPeriod(period);
    geStatByUserId(userId, begin, end)
      .then(({ data }) => {
        setState(data);
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
        if (error.response?.status >= 400) throwNotFound();
        logger.error(error);
      })
      .finally(() => setLoading(false));
  }, [userId, period, throwNotFound, throwServerError]);

  if (!userId) return null;

  return (
    <Box sx={{ position: 'relative', '& .LinearProgress': { top: '58px' } }} className="UserState_root">
      {isLoading && <Loading />}
      <Grid container spacing={'1rem'} sx={{ mb: 2 }}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <SwitchButtons
            sx={{
              width: 'auto',
              border: 'none',
              background: 'none',
              position: 'relative',
              alignItems: 'center',
              margin: '0 1rem 0px',
              '&:after': {
                position: 'absolute',
                backgroundColor: 'background.paper',
                content: '""',
                zIndex: -1,
                borderRadius: '15px',
                height: '17px',
                left: '-0.5rem',
                right: '-0.5rem',
              },

              '& .SwitchButtons_button': {
                fontSize: '0.75rem',
                width: '100%',
                color: 'text.primary',
                height: '28px',
                px: 'calc(0.5rem + 1px)',
                overflow: 'hidden',
                borderColor: 'text.primary',
                minWidth: '2rem',
              },

              '& .SwitchButtons_button__unactive': {
                background: 'none',
                border: 'none',
                overflow: 'hidden',
              },
              '& .SwitchButtons_button__active': {
                backgroundColor: 'background.paper',
                border: '1px solid',
                borderColor: (theme) => theme.palette.primary.main + '!important',
                borderRadius: '10px',
                px: '0.5rem',
                overflow: 'hidden',
              },
            }}
            buttons={[
              {
                id: '24h',
                title: t('profile.stat.filter.period.24h'),
              },
              {
                id: '1w',
                title: t('profile.stat.filter.period.1w'),
              },
              {
                id: '1m',
                title: t('profile.stat.filter.period.1m'),
              },
              {
                id: '3m',
                title: t('profile.stat.filter.period.3m'),
              },
              {
                id: '6m',
                title: t('profile.stat.filter.period.6m'),
              },
              {
                id: '1y',
                title: t('profile.stat.filter.period.1y'),
              },
              {
                id: 'all',
                title: t('profile.stat.filter.period.all'),
              },
            ]}
            value={period}
            onClick={(item: any) => setPeriod(item.id.toString())}
          />
        </Grid>

        {state?.team && (
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <InfoTable
              onClick={() => navigate(Paths.teamGlobe + `?userId=${userId}`)}
              sx={{
                borderRadius: '10px',
                // border: '1px solid',
                // borderColor: 'secondary.main',
              }}
              items={[
                {
                  title: t('profile.stat.team.title'),
                  mark: t('profile.stat.team.mark'),
                  value: state ? state.team?.team_number || 0 : undefined,
                },
                {
                  title: t('profile.stat.private.title'),
                  mark: t('profile.stat.private.mark'),
                  value: state ? state.team?.personal_team_number || 0 : undefined,
                },
              ]}
            >
              <Typography textAlign="center">{t('profile.stat.seeOnGlobe')}</Typography>
            </InfoTable>
          </Grid>
        )}
        {state?.earned && (
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <InfoTable
              sx={{
                borderRadius: '10px',
              }}
              items={[
                {
                  title: t('profile.stat.earned.title'),
                  // mark: t('profile.stat.earned.mark'),
                  value: <BPrice price={state.earned} />,
                },
              ]}
            />
          </Grid>
        )}
        {state?.cashback && (
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <InfoTable
              sx={{
                borderRadius: '10px',
              }}
              items={[
                {
                  title: t('profile.stat.cashback.title'),
                  // mark: t('profile.stat.earned.mark'),
                  value: <BPrice price={state.cashback} />,
                },
              ]}
            />
          </Grid>
        )}
        {state?.clans && (
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Box sx={{ backgroundColor: 'background.paper', padding: '8px 16px', borderRadius: '10px' }}>
              <Typography textAlign="center" sx={{ mb: '4px' }}>
                {t('profile.stat.levels.title')}
              </Typography>
              <LevelsStat levels={Object.values(state.clans)} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default UserState;
