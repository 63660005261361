import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Loading } from 'src/atoms';
import { Hello, Onboarding } from 'src/organisms';
import { getUserByReferralUuid, User } from 'src/services';
import { RouterReferralUuidParam } from 'src/types';

const Referral: FC = () => {
  const { referral } = useParams<RouterReferralUuidParam>();
  const { enqueueSnackbar } = useSnackbar();

  const [user, setUser] = useState<User>();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (referral) {
      getUserByReferralUuid(referral)
        .then(({ data }) => {
          setUser(data);
        })
        .catch(({ message }) => {
          enqueueSnackbar(message, { variant: 'error' });
        })
        .finally(() => setLoading(false));
    }
  }, [enqueueSnackbar, referral]);

  return (
    <>
      {isLoading && <Loading />}
      <Hello>
        <Onboarding user={user} referralUuid={user && referral} />
      </Hello>
    </>
  );
};

export default Referral;
