import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MarkerPhoto = styled(Box)(({ theme }) => ({
  position: 'absolute',
  cursor: 'pointer',
  background: theme.palette.primary.main,
  border: '2px solid',
  borderColor: theme.palette.primary.main,
  borderRadius: '50%',
  backgroundSize: 'cover',
})) as typeof Box;

export const MarkerPin = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: '2px',
  position: 'relative',
  width: '2px',
})) as typeof Box;
