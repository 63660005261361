import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Paths } from 'src/constants';
import { useAppSelector } from 'src/hooks';
import { DrawerShareText } from 'src/molecules';
import { selectUserMe } from 'src/store/userSlice';

const Referal: FC = () => {
  const { t } = useTranslation('app');

  const userMe = useAppSelector(selectUserMe);
  const navigate = useNavigate();

  const onclose = () => {
    navigate(Paths.home);
  };

  return (
    <>
      {userMe && (
        <DrawerShareText
          title={userMe.display_name || t('onboarding.unknownName')}
          link={userMe.referral_link}
          isOpen={true}
          tKey="referal"
          onClose={() => onclose()}
        />
      )}
    </>
  );
};

export default Referal;
