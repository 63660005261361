import { format, parseISO } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LevelOfMemberTeam, AvatarLink } from 'src/atoms';
import { isMarket } from 'src/constants';

import { Container, InfoBlock, Statistic, Name, DateRegistration, Label, Count, Address } from './MemberOfTeam.styles';
import { MemberOfTeamProps } from './MemberOfTeam.types';

const MemberOfTeam: FC<MemberOfTeamProps> = ({
  member,
  onSelectMember,
  showAddress: showAddressProp,
  children,
  sx,
}) => {
  const { t } = useTranslation('app');
  const showAddress = isMarket ? true : showAddressProp;

  return (
    <Container onClick={() => onSelectMember(member.id)} sx={sx}>
      <AvatarLink
        user_id={member.user_id}
        photo_uuid={member.photo_uuid}
        display_name={member.display_name}
        size={65}
        sx={{ flexBasis: 65, marginRight: '6px' }}
      />
      <InfoBlock sx={showAddress ? { justifyContent: 'flex-start' } : {}}>
        <Name>{member.display_name}</Name>
        {showAddress ? <Address>{member.public_address}</Address> : <LevelOfMemberTeam level={member.level || 0} />}
        <DateRegistration>
          {t('dashboard.regDate')}: {format(parseISO(member.date_joined), 'dd.MM.yyyy')}
        </DateRegistration>
      </InfoBlock>
      {member.team_number || member.personal_team_number ? (
        <Statistic>
          <Label>{t('team.paramTeam')}</Label>
          <Count>{member.team_number || 0}</Count>

          <Label>{t('team.paramPersonal')}</Label>
          <Count>{member.personal_team_number || 0}</Count>
        </Statistic>
      ) : children ? (
        <Statistic>{children}</Statistic>
      ) : null}
    </Container>
  );
};

export default MemberOfTeam;
