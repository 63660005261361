import { Box } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { EmptyData } from 'src/atoms';
import { Paths } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { PageTitle } from 'src/molecules';
import { ShoppingCart } from 'src/organisms';
import { TOrder, createNftBurn, confirmNftBurn, TOrderConfirmFuntion } from 'src/services';
import { ThemePaddings } from 'src/styles';
import { RouterCompositeIdParam } from 'src/types';

const ProfileUser: FC = () => {
  const { t } = useTranslation('app');
  const { compositeId } = useParams<RouterCompositeIdParam>();

  const navigate = useNavigate();

  const { throwServerError } = useContext(ErrorContext);

  const [isLoading, setLoading] = useState(false);
  const [order, setOrder] = useState<TOrder>();

  const onCloseHandler = () => (window.history.length ? navigate(-1) : navigate(Paths.dashboard));

  useEffect(() => {
    if (!compositeId) return;
    setLoading(true);
    createNftBurn(compositeId)
      .then(({ data }) => {
        setOrder(data);
      })
      .catch((error) => {
        if (error.response.status >= 500) throwServerError();
      })
      .finally(() => setLoading(false));
  }, [compositeId, throwServerError]);

  const confirmFunction: TOrderConfirmFuntion = (order, txId, callback, errback) => {
    if (!compositeId) return;
    setLoading(true);
    confirmNftBurn(compositeId, txId)
      .then(({ data }) => {
        callback(data);
      })
      .catch((error) => {
        if (error.response.status >= 500) throwServerError();
        errback(error.message);
      })
      .finally(() => setLoading(false));
  };

  if (!compositeId)
    return <EmptyData title={t('nftburn.emptyTitle')} data-qa={'nftburn.emptyTitle'} text={t('nftburn.emptyText')} />;

  return (
    <Box sx={{ p: ThemePaddings.page }}>
      <PageTitle
        title={t('nftburn.title')}
        data-qa={'nftburn.title'}
        help={{ tkey: 'nftburn' }}
        isLoading={isLoading}
        onClose={onCloseHandler}
      />
      {order ? (
        <ShoppingCart
          order={order}
          tkey={order.wrapper_type}
          onFinish={onCloseHandler}
          confirmFunction={confirmFunction}
        />
      ) : (
        ''
      )}
    </Box>
  );
};

export default ProfileUser;
