import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Paths } from 'src/constants';
import { PageTitle } from 'src/molecules';
import { GoalCard } from 'src/organisms';
import { ThemePaddings } from 'src/styles';
import { RouterCompositeIdParam } from 'src/types';

const Goal: FC = () => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const { compositeId } = useParams<RouterCompositeIdParam>();

  const onCloseHandler = () => (window.history.length ? navigate(-1) : navigate(Paths.dashboard));

  return (
    <>
      <Box sx={{ p: ThemePaddings.page, pb: '12px' }}>
        <PageTitle title={t('goal.title')} data-qa={'goal.title'} onClose={onCloseHandler} />
        {compositeId && <GoalCard compositeId={compositeId} />}
      </Box>
    </>
  );
};

export default Goal;
