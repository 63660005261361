import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Stack } from '@mui/material';
import { FC, useState } from 'react';

import { InputButton, InputField } from './InputCounter.styles';
import { InputCounterProps } from './InputCounter.types';

const toInt = (val: any) => {
  const out = parseInt(val, 10);
  return Number.isNaN(out) ? 0 : out;
};

const InputCounter: FC<InputCounterProps> = ({ onChange, value, error, fullWidth, min, max, sx, ...rest }) => {
  const [val, setVal] = useState<number | undefined>(value || min || undefined);

  const handlerChange = (newVal: number | undefined) => {
    if (newVal === undefined) {
      setVal(newVal);
    } else if (newVal >= (min || 0) && (max ? max >= newVal : true)) {
      setVal(newVal);
      onChange && onChange(newVal);
    }
  };

  return (
    <Stack direction="row" spacing={1}>
      <InputButton onClick={() => handlerChange((val || min || 0) - 1)}>
        <RemoveIcon />
      </InputButton>
      <InputField
        onChange={(e) => handlerChange(e.target.value === '' ? undefined : toInt(e.target.value))}
        value={val}
        error={!!error}
        helperText={error ? error.message : null}
        fullWidth={!!fullWidth}
        inputProps={{
          size: max ? Math.ceil(max / 10) + 1 : 3,
          maxLength: max ? Math.ceil(max / 10) + 1 : 3,
          min: min || 0,
          max: max,
        }}
        {...rest}
      />
      <InputButton onClick={() => handlerChange((val || min || 0) + 1)}>
        <AddIcon />
      </InputButton>
    </Stack>
  );
};

export default InputCounter;
