import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { NoPhoto } from 'src/assets/images';
import { BPrice } from 'src/atoms';
import { getImgSrc } from 'src/constants';

import { Block, Wrapper, Image, Info, Title, Row, Column } from './GoalItem.styles';
import { GoalItemProps } from './GoalItem.types';

const GoalItem: FC<GoalItemProps> = ({ item, onClick, children, sx }) => {
  const { t } = useTranslation('app');

  return (
    <Wrapper className="GoalItem_root">
      <Block onClick={onClick ? () => onClick(item) : () => null} sx={sx}>
        {item.info && (
          <Info>
            <Column>{item.info}</Column>
          </Info>
        )}
        <Box sx={{ flex: '1 1', overflow: 'hidden' }}>
          <Image src={item.photo_uuid ? getImgSrc(item.photo_uuid, 's300-c') : NoPhoto} alt={t(item.name)} />
        </Box>
        <Info>
          <Row>
            <Title sx={{ alignSelf: 'flex-start', mb: '4px' }}>{t(item.name)}</Title>
            {item.cost && (
              <BPrice
                sx={{ fontSize: '0.75rem', lineHeight: '16px', color: 'primary.main' }}
                price={{
                  bcost: item.cost.bcost,
                  bcurrency: item.cost.bcurrency,
                }}
              />
            )}
            {item.cost && item.cost.cost && (
              <BPrice
                sx={{
                  fontSize: '0.75rem',
                  lineHeight: '16px',
                  color: 'secondary.main',
                  '& .BPrice_icon': { display: 'none' },
                }}
                price={{
                  bcost: item.cost.cost || 0,
                  bcurrency: item.cost.currency || '',
                }}
              />
            )}
          </Row>
        </Info>
        {children}
      </Block>
    </Wrapper>
  );
};

export default GoalItem;
