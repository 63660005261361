import { Stack } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';

import { SwitchStyled, MainLabel, SwitchBox, OtherLabel } from './SwitchField.styles';
import { ISwitchFieldProps } from './SwitchField.types';

const SwitchField = <T extends FieldValues>(props: ISwitchFieldProps<T>) => {
  const { name, control, defaultValue, label, beforeLabel, afterLabel, onClickHandler, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => {
        return (
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <MainLabel>{label}</MainLabel>
            <SwitchBox onClick={onClickHandler}>
              {beforeLabel && <OtherLabel>{beforeLabel}</OtherLabel>}
              <SwitchStyled onChange={onChange} checked={value ?? false} {...rest} />
              {afterLabel && <OtherLabel>{afterLabel}</OtherLabel>}
            </SwitchBox>
          </Stack>
        );
      }}
    />
  );
};

export default SwitchField;
