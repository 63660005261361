import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Paths, pathsArg } from 'src/constants';
import { PageTitle } from 'src/molecules';
import { GoalList as GoalListGrid, GoalItemParams } from 'src/organisms';
import { ThemePaddings } from 'src/styles';
import { RouterCompositeIdParam } from 'src/types';

const GoalList: FC = () => {
  const { t } = useTranslation('app');

  const navigate = useNavigate();
  const { compositeId } = useParams<RouterCompositeIdParam>();

  const onItemClick = (compositeId: string) => navigate(pathsArg(Paths.goalProduct, { compositeId }));

  const onCloseHandler = () => (compositeId ? navigate(Paths.goalList) : navigate(Paths.goal));
  const onApprove = () => navigate(Paths.goal);

  const tkey = compositeId ? 'goalListCard' : 'goalList';

  return (
    <Box sx={{ height: '100%' }}>
      <PageTitle title={t('goalList.title')} data-qa={'goalList.title'} help={{ tkey }} onClose={onCloseHandler} />
      {compositeId ? (
        <GoalItemParams
          sx={{ padding: ThemePaddings.page }}
          compositeId={compositeId}
          onApproveClose={() => onApprove()}
        />
      ) : (
        <GoalListGrid
          sx={{ paddingTop: '100px', height: 'calc(100% - 100px)' }}
          onClick={(item) => onItemClick(item.composite_id)}
        />
      )}
    </Box>
  );
};

export default GoalList;
