import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { FC, useState } from 'react';

import { Counter, CustomizedAccordion, Title } from './Accordion.styles';
import { AccordionProps } from './Accordion.types';

const Accordion: FC<AccordionProps> = ({ id, title, isExpanded, count, sx, children }) => {
  const [isExpandedPanel, setExpandedPanel] = useState(isExpanded || false);

  const onChangeExpandedHandle = () => setExpandedPanel((prevExp) => !prevExp);

  return (
    <CustomizedAccordion sx={sx} expanded={isExpandedPanel} onChange={onChangeExpandedHandle}>
      <AccordionSummary
        className="Accordion_Summary"
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <Title className="Accordion_SummaryTitle">{title}</Title>
        {count && <Counter className="Accordion_SummaryCounter">{count}</Counter>}
      </AccordionSummary>
      <AccordionDetails className="Accordion_Details">{children}</AccordionDetails>
    </CustomizedAccordion>
  );
};

export default Accordion;
