import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { Stack, IconButton, Box, Button } from '@mui/material';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Webcam from 'react-webcam';

import { useResize } from 'src/hooks';
import { formatTimeSeconds } from 'src/utils';

import VideoPlayer from '../VideoPlayer';

import { CameraRecProps, CameraRecTimerProps } from './CameraRec.types';

const CameraRecTimer: FC<CameraRecTimerProps> = ({ countdown, maximum, onFinish }) => {
  const getTime = useCallback((startTime: number): number => {
    const time = Date.now() - startTime;
    const seconds = Math.floor(time / 1000);
    return seconds;
  }, []);

  const [startTimer] = useState<number>(countdown ? Date.now() + (maximum || 0) * 1000 : Date.now());
  const [recTimer, setRecTimer] = useState<number>(getTime(startTimer));

  useEffect(() => {
    let intervalId = setInterval(() => {
      const seconds = getTime(startTimer);
      if ((maximum && seconds >= maximum) || (countdown && seconds >= 0)) {
        clearInterval(intervalId);
        onFinish && onFinish(seconds);
      }
      setRecTimer(seconds);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [countdown, getTime, maximum, onFinish, startTimer]);

  return <>{formatTimeSeconds(Math.abs(recTimer))}</>;
};

const CameraRec: FC<CameraRecProps> = ({ width: widthProp, height: heightProp, onFinish, sx }) => {
  const { t } = useTranslation('app');

  const containerRef = useRef<HTMLDivElement>(null);
  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [isFrontCam, setIsFrontCam] = useState(true);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [width, setWidth] = useState(0);
  const [, setHeight] = useState(0);
  const [devices, setDevices] = useState([]);
  const [file, setFile] = useState<File>();
  const [preview, setPreview] = useState('');

  const { width: wrapperWidth } = useResize(containerRef);

  useEffect(() => {
    const { current } = containerRef;
    if (!current) return;

    const ratio = widthProp / heightProp;

    setWidth(current.offsetWidth);
    setHeight(current.offsetWidth / ratio);
  }, [containerRef, widthProp, heightProp]);

  useEffect(() => {
    if (!wrapperWidth) return;

    const ratio = widthProp / heightProp;

    setWidth(wrapperWidth);
    setHeight(wrapperWidth / ratio);
  }, [wrapperWidth, widthProp, heightProp]);

  const handleDevices = useCallback(
    (mediaDevices: any) => setDevices(mediaDevices.filter(({ kind }: { kind: string }) => kind === 'videoinput')),
    [setDevices]
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const handleDataAvailable = useCallback(
    ({ data }: any) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handlerUserMedia = useCallback(() => {
    const { current: webcam } = webcamRef;
    if (!webcam || !webcam.video) return;

    webcam.video.volume = 0.0;
  }, [webcamRef]);

  const handleStartCaptureClick = useCallback(() => {
    const { current: webcam } = webcamRef;
    if (!webcam || !webcam.stream) return;

    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcam.stream, {
      mimeType: 'video/webm',
    });
    mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current?.stop();
    setCapturing(false);
  }, [mediaRecorderRef, setCapturing]);

  useEffect(() => {
    if (!capturing && recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: 'video/webm',
      });

      const file = new File([blob], `feedback_${Date.now()}.webm`, { type: 'video/webm' });
      setFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result?.toString() || '');
      };
      reader.readAsDataURL(file);
    }
  }, [recordedChunks, capturing]);

  const handlerReRecord = () => {
    setPreview('');
    setFile(undefined);
    setRecordedChunks([]);
  };

  /*
  const handleDownload = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: 'video/webm',
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      // a.style = 'display: none';
      a.href = url;
      a.download = `feedback_${Date.now()}.webm`;
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);
  */

  const videoConstraints = {
    width: widthProp,
    height: heightProp,
    facingMode: isFrontCam ? 'user' : { exact: 'environment' },
  };
  return (
    <Stack sx={sx} ref={containerRef} width="100%" height="100%" position="relative">
      <Box
        sx={{
          flex: 1,
          height: '100%',
          position: 'relative',
          '& video': {
            borderRadius: '16px',
            backgroundColor: 'background.paper',
          },
        }}
      >
        {preview ? (
          <VideoPlayer
            height="100%"
            url={preview}
            sx={{
              '& video': {
                borderRadius: '16px',
                backgroundColor: 'background.paper',
              },
            }}
          />
        ) : (
          <Webcam
            width={width}
            height="100%"
            audio={true}
            mirrored={true}
            ref={webcamRef}
            videoConstraints={videoConstraints}
            onUserMedia={handlerUserMedia}
          />
        )}
      </Box>
      {!preview ? (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            position: 'absolute',
            width: '100%',
            bottom: 0,
            left: 0,
            paddingLeft: '8px',
          }}
        >
          <Box sx={{ flex: 1, lineHeight: '50px', textShadow: '1px 1px 0px black' }}>
            {capturing ? (
              <CameraRecTimer
                countdown={true}
                maximum={60}
                onFinish={(duration) => {
                  handleStopCaptureClick();
                }}
              />
            ) : (
              '01:00'
            )}
          </Box>
          {devices.length > 1 ? (
            <IconButton onClick={() => setIsFrontCam(!isFrontCam)}>
              <CameraswitchIcon fontSize="large" />
            </IconButton>
          ) : null}
          {capturing ? (
            <IconButton onClick={handleStopCaptureClick}>
              <StopCircleIcon fontSize="large" />
            </IconButton>
          ) : (
            <IconButton onClick={handleStartCaptureClick}>
              <FiberManualRecordIcon
                color="error"
                fontSize="large"
                sx={{
                  border: '1px solid',
                  borderColor: 'primary.main',
                  borderRadius: '50%',
                  backgroundColor: 'background.paper',
                }}
              />
            </IconButton>
          )}
        </Stack>
      ) : (
        <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: '8px' }}>
          <Button sx={{ flex: 1 }} onClick={handlerReRecord}>
            {t('cameraRec.reRecording')}
          </Button>
          <Button sx={{ flex: 1 }} onClick={() => file && onFinish(file)}>
            {t('cameraRec.submit')}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default CameraRec;
