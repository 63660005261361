import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SwitchButtons } from 'src/atoms';

import { Switch2LinksProps } from './Switch2Links.types';

const Switch2Links: FC<Switch2LinksProps> = ({ currentPath, links, isInverted }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const buttons = links.map((link) => ({
    id: link.path,
    title: link.title,
  }));

  return (
    <SwitchButtons
      sx={{ pointerEvents: 'auto' }}
      buttons={buttons}
      value={currentPath}
      onClick={(item) => navigate(item.id.toString() + search, { replace: true })}
      isInverted={!!isInverted}
    />
  );
};

export default Switch2Links;
