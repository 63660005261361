import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { animateScroll } from 'react-scroll';

import { LogoLight, LogoDark } from 'src/assets/images';
import { DrawerBottom, IconButtonBadge } from 'src/atoms';
import { Paths, isMarket } from 'src/constants';
import { ThemeContext } from 'src/context';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { HierarchyMenu, THierarchyMenuItem, Languages } from 'src/molecules';
import { SNList } from 'src/organisms';
import { Language, getLanguages } from 'src/services';
import { getBasketCount, selectMarketState } from 'src/store/marketSlice';
import { selectAuthorized } from 'src/store/userSlice';
import { ThemePaddings, ThemeFontWeight } from 'src/styles';
import { getPrefix } from 'src/utils';

const homeRoutes: THierarchyMenuItem = {
  id: 1,
  title: '',
  link: Paths.home,
  children: [
    {
      id: 2,
      title: 'landing.menu.about', // о нас
      link: Paths.pageAbout,
    },
    {
      id: 3,
      title: 'landing.menu.market', // маркет
      link: Paths.pageMarket,
    },
    {
      id: 4,
      title: 'landing.menu.jointpurchases', // вместе дешевле
      link: Paths.pageJoint,
    },
    {
      id: 5,
      title: 'landing.menu.shoppingsafety', // безопасность покупок
      link: Paths.pageSafety,
    },
    {
      id: 6,
      title: 'landing.menu.paymentAndDelivery', // оплата и доставка
      link: Paths.pageDelivery,
    },
    {
      id: 7,
      title: 'landing.menu.goods', // товары
      link: Paths.pageMarket + '#goods',
    },
    {
      id: 8,
      title: 'landing.menu.faq', // чзв
      link: Paths.home + '#faq',
    },
  ],
};

if (!isMarket) {
  homeRoutes.children = homeRoutes.children?.filter((item) => item.title !== 'landing.menu.market');
}

const HomeHeader: FC = () => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const { mode } = useContext(ThemeContext);
  const isAuthorized = useAppSelector(selectAuthorized);

  const { isShowLang, changeShowLang } = useContext(ThemeContext);
  const [languageList, setList] = useState<Language[]>([]);
  const [showLangList, setShowLangList] = useState<boolean>(!isShowLang);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { count: basketCount } = useAppSelector(selectMarketState);

  const { hash } = useLocation();
  const [offsetY, setOffsetY] = useState(0);
  useEffect(() => {
    const readyCheck = setTimeout(() => {
      if (document.readyState === 'complete') {
        clearInterval(readyCheck);
        var element = document.getElementById(hash.slice(1));
        if (element) {
          setOffsetY(element.offsetTop - 80);
        }
      }
    }, 500);
  }, [hash]);

  useEffect(() => {
    animateScroll.scrollTo(offsetY, {
      smooth: true,
      duration: 500,
      delay: 500,
    });
  }, [offsetY]);

  const languagePref = getPrefix();

  useEffect(() => {
    if (!languageList.length) {
      getLanguages()
        .then(({ data }) => setList(data))
        .catch(() => setList([]));
    }
  }, [languageList]);

  useEffect(() => {
    if (isAuthorized) dispatch(getBasketCount());
  }, [dispatch, isAuthorized]);

  const handlerLogin = () => {
    navigate(Paths.dashboard);
  };

  const handlerLang = () => {
    setShowLangList(true);
  };

  const handlerChangeLang = () => {
    setShowLangList(false);
    changeShowLang();
  };

  const smallButton = {
    margin: 0,
    my: ThemePaddings.pageX,
    height: '30px',
    fontSize: '0.75rem',
    fontWeight: ThemeFontWeight.medium,
    width: '70px',
    backgroundColor: 'background.darker',
    color: 'primary.main',
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          paddingTop: '2px',
          backgroundColor: 'background.paper',
          marginBottom: '14px',
          position: 'fixed',
          minHeight: '66px',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 20,
        }}
      >
        <Stack
          sx={{
            position: 'relative',
            maxWidth: '600px',
            width: '100%',
            margin: '0 auto',
          }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center">
            <Box
              component="img"
              data-qa="home.logo"
              onClick={() => navigate(Paths.home)}
              src={mode === 'light' ? LogoLight : LogoDark}
              sx={{ marginLeft: '16px', height: '1.8rem', cursor: 'pointer' }}
            />
            {/*<HelpButtonDrawer tkey="dashboard" />*/}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={'1rem'} sx={{ mr: ThemePaddings.pageX }}>
            <Button
              variant="contained"
              onClick={handlerLang}
              data-qa="button.language"
              sx={{
                ...smallButton,
                minWidth: '30px',
                width: '30px',
                padding: '0 0.8rem',
                textTransform: 'uppercase',
              }}
            >
              {languagePref}
            </Button>
            {isMarket && isAuthorized && (
              <IconButtonBadge
                count={basketCount}
                icon={<ShoppingCartOutlinedIcon />}
                onClick={() => navigate(Paths.marketShoppingCart)}
                sx={{
                  minWidth: '50px',
                  height: '30px',
                  margin: 0,
                  fontSize: '0.625rem',
                  fontWeight: ThemeFontWeight.medium,
                }}
              />
            )}
            <Button
              variant="contained"
              onClick={handlerLogin}
              data-qa="button.dashboard"
              sx={{
                ...smallButton,
                width: 'auto',
                padding: '0 0.5rem',
              }}
            >
              {isAuthorized ? t('button.dashboard') : t('button.signIn')}
            </Button>
            <IconButton onClick={() => setOpenMenu(true)}>
              <MenuIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>

      <DrawerBottom isOpen={showLangList} onClose={!isShowLang ? () => null : handlerChangeLang}>
        <Languages list={languageList} isShowLangs={showLangList} onChange={handlerChangeLang} />
      </DrawerBottom>
      <DrawerBottom
        anchor="top"
        sx={{
          height: '100%',
          '& .DrawerBottom_styled': { border: 'none' },
          '& .MuiDrawer-paper': { height: '100%' },
        }}
        isOpen={openMenu}
        onClose={() => setOpenMenu(false)}
        data-qa="home.menu"
      >
        <HierarchyMenu
          menu={homeRoutes}
          onClose={() => setOpenMenu(false)}
          onClick={(item) => {
            if (item.link) {
              navigate(item.link);
              setOpenMenu(false);
            }
          }}
        />
      </DrawerBottom>
    </>
  );
};

const HomeFutter: FC = () => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  return (
    <>
      {/* <Divider /> */}
      <Stack spacing="10px" sx={{ padding: '32px 16px 16px 16px' }}>
        <Typography>{t('home.futter.social.title')}:</Typography>
        <SNList official={true} sx={{ justifyContent: 'flex-start' }} />
        <Typography sx={{ color: 'secondary.main' }}>
          © {new Date().getFullYear()} {t('home.futter.copyright')}
        </Typography>
        <Stack spacing="10px" direction="row">
          <Typography
            data-qa="home.futter.link.termsOfUse"
            onClick={() => navigate(Paths.termsOfUse)}
            sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
          >
            {t('home.futter.link.termsOfUse')}
          </Typography>
          <Box>|</Box>
          <Typography
            data-qa="home.futter.link.privacyPolicy"
            onClick={() => navigate(Paths.privacyPolicy)}
            sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
          >
            {t('home.futter.link.privacyPolicy')}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

const HomePage: FC = () => {
  // const { t } = useTranslation('app');

  return (
    <Stack sx={{ height: '100%' }}>
      <HomeHeader />
      <Box sx={{ position: 'relative', flex: 1, paddingTop: '64px' }}>
        <Outlet />
      </Box>
      <HomeFutter />
    </Stack>
  );
};

export default HomePage;
