import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

export const DemoStack = styled(Stack)(() => ({
  background: ThemeColors.black,
  padding: '7px 10px',
  borderRadius: 15,
  marginBottom: 15,
}));

export const DemoText = styled(Typography)(() => ({
  margin: '0 5px',
  color: ThemeColors.white,
  padding: '0 4px',
  fontSize: '0.75rem',
  lineHeight: '14px',
}));
