import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FONTS } from 'src/styles';

export const DateWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 32,
  marginBottom: 8,
  fontFamily: FONTS,
  fontSize: '0.75rem',
  lineHeight: '16px',

  '&:before, &:after': {
    content: '""',
    display: 'block',
    background: theme.palette.divider,
    width: '35%',
    height: '1px',
  },
}));
