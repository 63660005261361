import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { FC, useState, useEffect, useContext, useReducer, useMemo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Accordion, BPrice, BreadCrumbs, CircleLoading, DrawerBottom, EmptyData, Loading, Markdown } from 'src/atoms';
import { Paths, getImgSrc, isMarket, pathsArg } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { useAppSelector } from 'src/hooks';
import { ColorCooser, VideoPlayer, WindowTitle } from 'src/molecules';
import { CarouselPhoto, HelpButtonDrawer, ProductFeedbackList } from 'src/organisms';
import {
  getGoalItem,
  TTargetListItem,
  TOrderPrice,
  TTargetListItemCosts,
  setGoalItem,
  TTargetItemApproveFuntion,
  TPathHierarchy,
  TTargetListItemAttributes,
} from 'src/services';
import { selectAuthorized } from 'src/store/userSlice';
import { logger, parseError } from 'src/utils';

import {
  Block,
  Title,
  Column,
  SubTitle,
  Row,
  Info,
  MemoButton,
  SizeButton,
  LabelTitle,
  ImageCustom,
} from './GoalItemParams.styles';
import { GoalItemParamsProps } from './GoalItemParams.types';

type GoalAtribute = {
  compositeId: number;
  groups: {
    [key: string]: TTargetListItemAttributes[];
  };
};

type GoalAction = {
  action: string;
  group?: string;
  id?: number;
  value?: string;
  isActive?: boolean;
  state?: GoalAtribute;
};

const reducer = (state: GoalAtribute, action: GoalAction): GoalAtribute => {
  const _state = action.state !== undefined ? { ...action.state } : { ...state };
  switch (action.action) {
    case 'setActive':
      const items = action.group ? _state.groups[action.group] : undefined;
      let item;
      if (action.id) {
        item = items?.find((item) => item.id === action.id);
      } else if (action.value) {
        item = items?.find((item) => item.value === action.value);
      }

      if (item) {
        items?.forEach((item) => {
          if (item.id === action.id || item.value === action.value) item.selected = !!action.isActive;
          else item.selected = false;
        });
      }
      break;
    case 'setState':
      break;
  }

  // соберем исключения и раздизаблим все
  let _exclude: number[] = Object.values(_state.groups).reduce((exAcc, items) => {
    const activeItem = items.reduce((acc, item) => {
      item.disabled = false;
      if (item.selected) acc = item;
      return acc;
    }, undefined as TTargetListItemAttributes | undefined);

    // отфильтруем выбранный активный
    return exAcc.concat(activeItem?.exclude || []).filter((id) => id !== action.id);
  }, [] as number[]);

  let newCompositeId = '';
  // задизаблим и соберем комплексный ключ
  Object.values(_state.groups).forEach((items) => {
    const activeItem = items.reduce((acc, item) => {
      if (_exclude.indexOf(item.id) >= 0) {
        item.disabled = true;
        item.selected = false;
      }

      if (item.selected) acc = item;
      return acc;
    }, undefined as TTargetListItemAttributes | undefined);
    if (activeItem) newCompositeId = newCompositeId + activeItem.id;
  });

  _state.compositeId = parseInt(newCompositeId, 10) || 0;

  return _state;
};

const GoalItemParams: FC<GoalItemParamsProps> = ({
  compositeId,
  onApprove,
  onApproveClose,
  withCustome,
  tkey: tkeyProp,
  sx,
}) => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);
  const navigate = useNavigate();
  const isAuthorized = useAppSelector(selectAuthorized);

  const [state, dispatch] = useReducer(reducer, {
    compositeId: 0,
    groups: {},
  });

  const [isLoading, setLoading] = useState(true);
  const [isDrawerLoading, setDrawerLoading] = useState(true);
  const [isApprove, setApprove] = useState(false);
  const [isChooseOpen, setIsChooseOpen] = useState(false);
  const [isVideoOpen, setVideoOpen] = useState(false);
  const [item, setItem] = useState<TTargetListItem | null>(null);
  const [costs, setCosts] = useState<TTargetListItemCosts | null>(null);
  const [cost, setCost] = useState<TOrderPrice | null>(null);

  const [reload, setReload] = useState(0);

  const [feedbackCount, setFeedbackCount] = useState<number>();

  const [isCustomOpen, setCustomOpen] = useState<boolean | null>(null);

  const [path, setPath] = useState<TPathHierarchy | undefined>();

  const onCategoryClick = (id?: number) =>
    id ? navigate(pathsArg(Paths.pageMarketCategory, { id })) : navigate(Paths.pageMarket);

  const tkey = tkeyProp || '';

  const handlerPlayVideo = () => {
    setVideoOpen(true);
  };

  const defApproveFunction: TTargetItemApproveFuntion = (item, attributes, callback, errback) => {
    setGoalItem(item.composite_id, attributes)
      .then(() => {
        callback(item);
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
        logger.error(error);
        errback(parseError(error));
      });
  };

  const onChooseClose = (approve: boolean | null) => {
    if (approve && item?.composite_id) {
      let attributes: TTargetListItemAttributes[] = [];

      Object.values(state.groups).forEach((items) => {
        const selected = items.find((item) => item.selected);
        if (selected) attributes.push(selected);
      });

      const approveFunction: TTargetItemApproveFuntion = onApprove || defApproveFunction;

      setDrawerLoading(true);
      approveFunction(
        item,
        attributes,
        () => {
          setApprove(true);
          setDrawerLoading(false);
        },
        () => {
          setDrawerLoading(false);
        }
      );
    } else {
      setIsChooseOpen(false);
      if (isApprove && onApproveClose) {
        onApproveClose();
      }
    }
  };

  const selectedAttributes = useMemo(() => {
    let attributes: TTargetListItemAttributes[] = [];
    Object.values(state.groups).forEach((items) => {
      const selected = items.find((item) => item.selected);
      if (selected) attributes.push(selected);
    });
    return attributes;
  }, [state]);

  useEffect(() => {
    if (compositeId) {
      setLoading(true);
      getGoalItem(compositeId, selectedAttributes)
        // getGoalList()
        .then(({ data }) => {
          const _item = data;
          setItem(_item);
          setPath(_item.path || undefined);

          const attributes = {
            compositeId: 0,
            groups: {} as {
              [key: string]: TTargetListItemAttributes[];
            },
          };

          setCosts(_item.attributes.cost);

          _item?.attributes.attributes.forEach((item) => {
            if (!attributes.groups[item.group]) attributes.groups[item.group] = [];
            attributes.groups[item.group].push(item);
          });

          if (withCustome) {
            // тут кастомы
            switch (_item.type) {
              case 'IPHONE':
                if (isCustomOpen === null) setCustomOpen(true);
            }
          }

          dispatch({ action: 'setState', state: attributes });
        })
        .catch((error) => {
          if (error.response?.status >= 500) throwServerError();
          logger.error(error);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
      setItem(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compositeId, throwServerError, withCustome, reload]);

  useEffect(() => {
    if (costs && state.compositeId) {
      const _cost = costs[state.compositeId];
      if (_cost) {
        setCost(_cost);
      } else {
        setCost(null);
      }
    } else {
      setCost(null);
    }
  }, [costs, state.compositeId]);

  const handlerParamClick = (group: string, id?: number) => {
    dispatch({ action: 'setActive', group, id, isActive: true });
    setReload(Math.random());
  };

  return (
    <>
      {isLoading && <Loading />}
      {item && (
        <Box sx={sx} className="GoalItemParams_root">
          {isMarket && <BreadCrumbs sx={{ m: '16px', mt: 0 }} items={path} onClick={onCategoryClick} />}
          <Block className={item.is_active ? 'GoalItemParams_active' : 'GoalItemParams_unactive'}>
            <CarouselPhoto images={item.carousel && item.carousel.length > 0 ? item.carousel : [item.photo_uuid]} />
            {item.video_link && (
              <Stack justifyContent="center" sx={{ position: 'absolute', top: '108px', right: '24px', width: '42px' }}>
                <IconButton
                  onClick={handlerPlayVideo}
                  sx={{ border: '1px solid', mb: '4px', backgroundColor: 'background.paper' }}
                  data-qa={'goalItem.button.video'}
                  title={t('goalItem.button.video')}
                >
                  <PlayArrowIcon fontSize="medium" />
                </IconButton>
              </Stack>
            )}
            <Info>
              <Row spacing={2}>
                <Title>{t(item.name)}</Title>
                <Stack direction="row" flexWrap="wrap" justifyContent="space-between">
                  {state.groups &&
                    Object.keys(state.groups).map((group) => {
                      const groupType = state.groups[group][0].type;
                      const items = state.groups[group];
                      switch (groupType) {
                        case 'colors':
                          return (
                            <ColorCooser
                              key={`goalItem.params.${group}`}
                              sx={{
                                padding: '5px',
                                margin: '4px',
                                background: (theme) => theme.palette.background.darker,
                                borderRadius: '50px',
                                flex: 'none',
                              }}
                              active={items.reduce(
                                (acc: number | undefined, b) => (b.selected ? b.id : acc),
                                undefined
                              )}
                              onChange={(id) => handlerParamClick(group, id as number)}
                              colors={items.map((col) => ({
                                id: col.id,
                                value: col.value,
                                img: getImgSrc(col.img, 's42-c'),
                                title: col.title,
                                isDisabled: col.disabled,
                              }))}
                            />
                          );
                        case 'switch':
                          return (
                            <Column
                              key={`goalItem.params.${group}`}
                              sx={{
                                padding: '5px',
                                margin: '4px',
                                background: (theme) => theme.palette.background.darker,
                                borderRadius: '50px',
                                height: '52px',
                                flex: 1,
                              }}
                            >
                              {items.map((item, idx) => (
                                <SizeButton
                                  sx={{ marginLeft: idx ? '8px' : 0, height: '40px', whiteSpace: 'nowrap' }}
                                  onClick={() => handlerParamClick(group, item.id)}
                                  variant="text"
                                  className={item.selected ? 'SizeButton_active' : ''}
                                  key={`Goal${item.group}_${item.id}`}
                                  fullWidth
                                  disabled={!!item.disabled}
                                  title={item.title}
                                >
                                  {item.value}
                                </SizeButton>
                              ))}
                            </Column>
                          );
                        case 'buttons':
                          return (
                            <Column
                              key={`goalItem.params.${group}`}
                              sx={{
                                padding: '5px',
                                margin: '4px',
                                flex: 1,
                                flexWrap: 'wrap',
                              }}
                            >
                              {items.map((item, idx) => (
                                <MemoButton
                                  sx={{ marginLeft: idx ? '8px' : 0, height: '52px' }}
                                  onClick={() => handlerParamClick(group, item.id)}
                                  variant="text"
                                  className={item.selected ? 'MemoButton_active' : ''}
                                  key={`Goal${item.group}_${item.id}`}
                                  fullWidth
                                  disabled={!!item.disabled}
                                  title={item.title}
                                >
                                  {item.value}
                                </MemoButton>
                              ))}
                            </Column>
                          );
                      }

                      return null;
                    })}
                </Stack>

                <Column>
                  <SubTitle>{t('goalItem.cost')}:</SubTitle>
                  <Column sx={{ width: 'auto' }}>
                    {cost && <BPrice price={cost} />}
                    <HelpButtonDrawer tkey="goalItemCost" />
                  </Column>
                </Column>
              </Row>
              <Box sx={{ my: '16px', mx: '16px' }}>
                <Accordion
                  id="productDescription"
                  title={t('accordionTitle.description')}
                  data-qa={'accordionTitle.description'}
                >
                  <Markdown>{t(item.description)}</Markdown>
                </Accordion>
                <Accordion
                  id="productFeedback"
                  title={t('accordionTitle.feedback')}
                  data-qa={'accordionTitle.feedback'}
                  count={feedbackCount}
                >
                  <ProductFeedbackList targetId={item.target_id} onLoad={(data) => setFeedbackCount(data.count)} />
                </Accordion>
                {item.terms_of_payment && (
                  <Accordion
                    id="productTermsOfPayment"
                    title={t('accordionTitle.termsOfPayment')}
                    data-qa={'accordionTitle.termsOfPayment'}
                  >
                    <Markdown>{t(item.terms_of_payment)}</Markdown>
                  </Accordion>
                )}
                {item.delivery && (
                  <Accordion
                    id="productDelivery"
                    title={t('accordionTitle.delivery')}
                    data-qa={'accordionTitle.delivery'}
                  >
                    <Markdown>{t(item.delivery)}</Markdown>
                  </Accordion>
                )}
              </Box>
              <Row>
                <Button
                  disabled={!isAuthorized || !item.is_active || !cost}
                  variant="outlined"
                  onClick={() => setIsChooseOpen(true)}
                  fullWidth
                >
                  {t(item.is_active ? tkey + 'goalItem.button.choose' : 'goalItem.button.soon')}
                </Button>
              </Row>
            </Info>
          </Block>
        </Box>
      )}
      {!item && !isLoading && (
        <EmptyData
          sx={sx}
          title={t('goalItem.emptyTitle')}
          data-qa={'goalItem.emptyTitle'}
          text={t('goalItem.emptyText')}
        />
      )}
      {item?.video_link && (
        <DrawerBottom isOpen={isVideoOpen} onClose={() => setVideoOpen(false)}>
          <>
            <WindowTitle
              data-qa="goalItem.video.title"
              title={t('goalItem.video.title')}
              onClose={() => setVideoOpen(false)}
            />
            <VideoPlayer
              url={item.video_link}
              sx={{
                mb: '16',
                '& video': {
                  borderRadius: '16px',
                  backgroundColor: 'background.paper',
                },
              }}
            />
          </>
        </DrawerBottom>
      )}

      <DrawerBottom isOpen={isChooseOpen} onClose={() => onChooseClose(false)}>
        {isApprove ? (
          <>
            <WindowTitle
              data-qa={isDrawerLoading ? tkey + 'goalItem.approve.titleWait' : tkey + 'goalItem.approve.titleDone'}
              title={t(isDrawerLoading ? tkey + 'goalItem.approve.titleWait' : tkey + 'goalItem.approve.titleDone')}
              onClose={() => onChooseClose(null)}
            />
            <Typography
              sx={{ mb: '16px' }}
              data-qa={
                isDrawerLoading ? tkey + 'goalItem.approve.descriptionWait' : tkey + 'goalItem.approve.descriptionDone'
              }
            >
              {t(
                isDrawerLoading ? tkey + 'goalItem.approve.descriptionWait' : tkey + 'goalItem.approve.descriptionDone'
              )}
            </Typography>
            <CircleLoading isLoading={isDrawerLoading} />
            <Row sx={{ zIndex: 1, flex: 'none', pt: '16px', position: 'relative' }}>
              <Button
                data-qa={tkey + 'goalItem.approve.descriptionDone.button.continue'}
                disabled={isDrawerLoading}
                variant="text"
                onClick={() => onChooseClose(null)}
                fullWidth
              >
                {t(tkey + 'goalItem.approve.descriptionDone.button.continue')}
              </Button>

              {isMarket && (
                <Button
                  sx={{ mt: '16px' }}
                  data-qa={tkey + 'goalItem.approve.descriptionDone.button.basket'}
                  disabled={isDrawerLoading}
                  variant="text"
                  onClick={() => navigate(Paths.marketShoppingCart)}
                  fullWidth
                >
                  {t(tkey + 'goalItem.approve.descriptionDone.button.basket')}
                </Button>
              )}
            </Row>
          </>
        ) : (
          <>
            <WindowTitle
              title={t('goalItem.approve.title')}
              data-qa={'goalItem.approve.title'}
              onClose={() => onChooseClose(false)}
            />
            <Typography sx={{ mb: '16px' }}>{t('goalItem.approve.description')}:</Typography>
            <Row spacing={1}>
              <Grid container spacing="4px">
                <Grid item xs={4}>
                  <LabelTitle>{t('goalItem.approve.name')}</LabelTitle>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{item?.name}</Typography>
                </Grid>
                {Object.keys(state.groups).map((group: string) => (
                  <Fragment key={`GoalParam_choose_${group}`}>
                    <Grid item xs={4}>
                      <LabelTitle>{t(`goalItem.approve.${group}`)}</LabelTitle>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography>{state.groups[group].find((item) => item.selected)?.title}</Typography>
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
              <Column>
                <LabelTitle>{t('goalItem.cost')}</LabelTitle>
                {cost && <BPrice price={cost} />}
              </Column>
              <Button disabled={!cost} variant="text" onClick={() => onChooseClose(true)} fullWidth>
                {t('goalItem.button.approve')}
              </Button>
            </Row>
          </>
        )}
      </DrawerBottom>

      <DrawerBottom
        isOpen={!!isCustomOpen}
        onClose={() => false}
        sx={{ height: '100%', '& .DrawerBottom_styled': { border: 'none' }, '& .MuiDrawer-paper': { height: '100%' } }}
      >
        <WindowTitle
          data-qa={`goalItem.custom.${item?.type}.title`}
          title={t(`goalItem.custom.${item?.type}.title`)}
          subtitle={t(`goalItem.custom.${item?.type}.subtitle`)}
          onClose={() => setCustomOpen(false)}
        />
        <Typography>{t(`goalItem.custom.${item?.type}.description`)}</Typography>
        <Stack justifyContent="space-between" sx={{ height: 'calc(100% - 100px)', mt: '16px', overflow: 'auto' }}>
          {isCustomOpen && (
            <Stack direction="row" sx={{ flex: 1 }}>
              {state.groups['size'].map((size) => (
                <ImageCustom
                  key={`imgCustom_${size.id}.${size.id}`}
                  data-qa={`goalItem.custom.${item?.type}.img.${size.id}`}
                  className={size.selected ? 'goalItem_custom__active' : ''}
                  onClick={() => dispatch({ action: 'setActive', group: 'size', id: size.id, isActive: true })}
                  src={getImgSrc(t(`goalItem.custom.${item?.type}.img.${size.id}`), 'w300-p')}
                  alt={'left'}
                />
              ))}
            </Stack>
          )}
          <Box sx={{ zIndex: 1, flex: 'none', pt: '16px' }}>
            <Button
              data-qa={`goalItem.custom.${item?.type}.button`}
              variant="outlined"
              onClick={() => setCustomOpen(false)}
              fullWidth
            >
              {t(`goalItem.custom.${item?.type}.button`)}
            </Button>
          </Box>
        </Stack>
      </DrawerBottom>
    </>
  );
};

export default GoalItemParams;
