import { Typography, Box, Stack, Button } from '@mui/material';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LogoLight, LogoDark } from 'src/assets/images';
import { Markdown } from 'src/atoms';
import { getImgSrc, Paths } from 'src/constants';
import { ThemeContext } from 'src/context';
import { OnboardingUser } from 'src/molecules';

import { OnboardingProps } from './Onbording.types';

const Onboarding: FC<OnboardingProps> = ({ user, referralUuid }) => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const { mode } = useContext(ThemeContext);

  const goToSignIn = (id?: number) => {
    navigate(Paths.signIn, { replace: true, state: { referral: referralUuid } });
  };

  const page = user?.user_id ? 'refer' : 'norefer';

  return (
    <>
      <Stack alignItems="center" sx={{ px: '20px', pb: '50px' }}>
        <Box component="img" src={mode === 'light' ? LogoLight : LogoDark} sx={{ marginTop: '16px', height: '30px' }} />
        <Typography sx={{ mt: '20px', mb: '20px', width: '100%', textAlign: 'left' }} component="div">
          <Markdown>{t(`hello.${page}.textMD`)}</Markdown>
        </Typography>

        {user?.user_id && (
          <OnboardingUser
            id={user?.user_id}
            avatarUrl={getImgSrc(user?.photo, 's150-c')}
            userName={user?.display_name}
            // goToSignIn={goToSignIn}
          />
        )}

        <Button
          variant="outlined"
          sx={{ mx: '30px!important', mt: '0!important' }}
          onClick={() => {
            if (user?.user_id) goToSignIn();
            else navigate(Paths.home, { replace: true });
          }}
        >
          {t(`button.${page}.agree`)}
        </Button>
      </Stack>
    </>
  );
};

export default Onboarding;
