/**
 * process.env.REACT_APP_IS_DEV - признак DEV режима
 * REACT_APP_IS_DEV = true - устанавливается в файле .env
 */

const _getUrl = (apiUrl: string | undefined) => {
  if (apiUrl) {
    return apiUrl[0] === ':' || apiUrl[0] === '/' ? new URL(window.location.origin + '/' + apiUrl).href : apiUrl;
  } else {
    return window.location.origin + '/';
  }
};

export const isMarket = process.env.REACT_APP_IS_MARKET === 'true';
export const isDev = process.env.REACT_APP_IS_DEV === 'true';

export const getApiUrl = (isDemo?: boolean) => {
  return _getUrl(process.env.REACT_APP_API_URL);
};

export const API_URL = getApiUrl(false);

export const WSS_URL = _getUrl(isMarket ? process.env.REACT_APP_MARKET_WSS_URL : process.env.REACT_APP_WSS_URL);

export const globeTKey = isMarket ? 'market.' : '';

export const getImgSrc = (photo_uuid: string | undefined, size: string = 's100-c') => {
  /**
   *  size по умолчанию s100-c
   * https://developers.google.com/people/image-sizing?hl=ru
   */
  return photo_uuid ? `${getApiUrl()}images/${photo_uuid}/?resize_params=${size}` : photo_uuid;
};

export const getVideoSrc = (video_uuid: string | undefined) => {
  return video_uuid ? `${getApiUrl()}videos/${video_uuid}` : video_uuid;
};
