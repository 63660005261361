import { ethers, parseEther, formatEther } from 'ethers';

import { isDev } from 'src/constants';
import { walletProviders } from 'src/types';
import { logger } from 'src/utils';

//import constractBep20 from './contracts/BEP20.json';
import tokenAbiERC20 from './contracts/IERC20.json';
import { getWalletProvider, setWalletProvider, NoProviderError, getProvider } from './providers';
// import registerAbi from './contracts/InvoiceRegister.json';

export const neededChainId = isDev ? BigInt('97') : BigInt('56');
const TOCKEN_ID = 'BNB';

class NoChainIdError extends Error {
  constructor(chainId: bigint) {
    super(
      `Для работы в приложении необходим кошелек с токином ${TOCKEN_ID} (Chain ID ${neededChainId.toString()}).
Текущий Chain ID = ${chainId.toString()}`
    );
  }
}

/*
class USDTError extends Error {
  constructor(amount: number, balance: number) {
    super(`Недостаточно средств на балансе. Необходимо ${amount} ${TOCKEN_ID}, но доступно ${balance} ${TOCKEN_ID}.`);
  }
}


class ApproveFailedError extends Error {
  constructor(amount: number) {
    super(`Не получилось разрешение на использование ${amount} ${TOCKEN_ID}.`);
  }
}
*/

class FundFailedError extends Error {
  constructor() {
    super('Не получилось пополнить счёт.');
  }
}

export const connectToProviderWallet = async (token: string, providerIn: walletProviders | undefined) => {
  const wallet = await connectToWallet(providerIn);
  const address = await wallet.signer.getAddress();

  return {
    token,
    provider: wallet.provider,
    signer: wallet.signer,
    address,
    signature: wallet.signature,
  };
};

export const getNetwork = async (): Promise<{ chainId: number }> => {
  const providerIn = getWalletProvider();
  if (!providerIn) {
    throw new NoProviderError(undefined);
  }
  const provider = providerIn.provider;
  await provider.send('eth_requestAccounts', []);
  const { chainId } = await provider.getNetwork();

  return {
    chainId: Number(ethers.formatEther(chainId)),
  };
};

/**
 * Внутри нет try/catch. Функцию надо оборачивать самостоятельно
 */
export const getBalanceByAddress = async (address: string): Promise<string> => {
  const providerIn = getWalletProvider();
  if (!providerIn) {
    throw new NoProviderError(undefined);
  }
  const provider = providerIn.provider;
  await provider.send('eth_requestAccounts', []);
  const balanceBigNumber = await provider.getBalance(address);
  const balance = ethers.formatEther(balanceBigNumber);

  return balance;
};

/// ---- newWay ----------------------------------------------------------------
export const checkNetwork = (chainId: bigint) => {
  logger.log('checkNetwork', neededChainId, chainId);
  return BigInt(neededChainId) === chainId;
};

export const getWallet = async (provType?: walletProviders) => {
  const prov = provType ? getProvider(provType) : getWalletProvider();
  if (prov) {
    setWalletProvider(prov.type);
    return await getSigner(prov.provider);
  } else {
    throw new NoProviderError(provType);
  }
};

export const connectToWallet = async (provType?: walletProviders) => {
  const walletInfo = await getWallet(provType);
  const address = await walletInfo.signer.getAddress();

  const signature = await walletInfo.signer.signMessage(
    `Будет выполнена попытка авторизации с использованием аккаунта ${address}`
  );
  return {
    ...walletInfo,
    signature,
  };
};

export const getSigner = async (prov: ethers.Eip1193Provider, network?: ethers.Networkish | undefined) => {
  const provider = new ethers.BrowserProvider(prov, network);
  const accounts = await provider.listAccounts();
  const signer = await provider.getSigner();
  await provider.send('eth_requestAccounts', []);
  const { chainId } = await provider.getNetwork();
  if (!checkNetwork(chainId)) {
    throw new NoChainIdError(chainId);
  }

  /*
  const signature = await signer.signMessage(
    `Будет выполнена попытка авторизации с использованием аккаунта ${address}

Если пользователя в системе еще нет, то он будет создан и автоматически авторизован`
  );
  */

  //setWalletProvider(prov);

  return {
    accounts,
    provider,
    signer,
  };
};

export const getAddress = async (signer: ethers.JsonRpcSigner): Promise<string> => {
  const address = await signer.getAddress();
  return address;
};

export const getAddressAndBalance = async (
  signer?: ethers.JsonRpcSigner
): Promise<{
  address: string;
  balance: bigint;
}> => {
  const _signer = signer ? signer : (await getWallet()).signer;
  const address = await _signer.getAddress();
  const balance = await _signer.provider.getBalance(address);

  return {
    address,
    balance,
  };
};

export const getNetworkChainId = async (
  signer?: ethers.JsonRpcSigner
): Promise<{
  chainId: bigint;
  name: string;
}> => {
  const _signer = signer ? signer : (await getWallet()).signer;
  const { chainId, name } = await _signer.provider.getNetwork();
  return {
    chainId,
    name,
  };
};

export type TTokenAddresses = {
  type: string;
  title: string;
  address: string;
  balance: bigint | null;
};

export const getTokensBalance = async (signer: ethers.JsonRpcSigner): Promise<TTokenAddresses[]> => {
  const tokenAddresses: TTokenAddresses[] = [
    {
      type: 'USDT',
      title: 'USDT Token',
      address: '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
      balance: null,
    },
    {
      type: 'MTK',
      title: 'MyToken',
      address: '0xC11Db48009e1a03Aa65C5457D87cCB3CEF7F640D',
      balance: null,
    },
  ];

  const address = await signer.getAddress();
  for (let token of tokenAddresses) {
    const contract = new ethers.Contract(token.address, tokenAbiERC20.abi).connect(signer);
    token.balance = await contract.getFunction('balanceOf')(address);
  }

  return tokenAddresses;
};

type TSendParams = {
  to: string;
  value: string;
  invoice: number;
};

export const sendToContract = async (signer: ethers.JsonRpcSigner, params: TSendParams) => {
  //  проверим что сеть нужная
  const { chainId } = await signer.provider.getNetwork();
  if (!checkNetwork(chainId)) {
    throw new NoChainIdError(chainId);
  }

  // сформируем данные для отправки
  const contract = params.to; // куда
  const value = params.value; // сколько wei заплатить

  var data = '0xac60a6cd'; // keccak256_32("payInvoice(uint256)")
  data = data + params.invoice.toString(16).padStart(64, '0'); // uint256 invoice, заполнить

  const address = await signer.getAddress(); // от кого

  const transaction = {
    from: address,
    to: contract,
    data: data,
    value: '0x' + parseEther(value).toString(16),
  };
  logger.log('transaction', transaction);

  try {
    // отправка на контракт
    const txId = await signer.provider.send('eth_sendTransaction', [transaction, 'latest']);
    logger.log('txId', txId);
    return txId;
  } catch (err) {
    logger.error(err);
    throw new FundFailedError();
  }
};

export const fundInvoiceAddr = async (contractId: string, invoice: number, amount: number) => {
  const wallet = await getWallet();
  return sendToContract(wallet.signer, {
    to: contractId,
    invoice,
    value: amount.toString(),
  });
};

export const formatBalance = (balance: bigint) => {
  return formatEther(balance);
};
