import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeGradients } from 'src/styles';

export const CardWrapper = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  maxWidth: 343,
  background: ThemeGradients.wallet,
  borderRadius: 16,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'hidden',
  margin: '0 auto 40px',
  padding: '16px 16px 20px',
}));

export const CardHead = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}));

export const BinanceSmartChain = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '0.688rem',
  lineHeight: '13px',
  textAlign: 'center',
  maxWidth: 70,

  p: {
    margin: 0,
  },

  svg: {
    width: 32,
    height: 32,
    fill: theme.palette.primary.main,
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  lineHeight: '21px',
  fontWeight: 400,
  textTransform: 'uppercase',
  marginTop: 5,
  marginBottom: 9,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  span: {
    color: theme.palette.primary.main,
  },
}));

export const MetaMask = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '9px 16px',
  margin: '0 -16px',

  img: {
    width: 31,
    height: 30,
    margin: '0 5px',
  },

  div: {
    width: '75%',
  },
}));

export const MetaMaskTitle = styled(Typography)(() => ({
  fontSize: '0.688rem',
  lineHeight: '13px',
  letterSpacing: 0.066,
}));

export const WalletAddress = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '1rem',
  height: '1rem',
  color: theme.palette.primary.main,
  margin: 0,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

export const WalletBalance = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 16,
}));

export const BalanceText = styled(Typography)(({ theme }) => ({
  fontSize: '0.94rem',
  lineHeight: '22px',
  fontWeight: 600,
  color: theme.palette.secondary.main,
  textTransform: 'uppercase',
}));

export const Balance = styled(Typography)(({ theme }) => ({
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: 600,
  color: theme.palette.primary.main,

  span: {
    fontWeight: 400,
    marginLeft: 8,
  },
}));
