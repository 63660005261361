import { IResolveParams } from 'reactjs-social-login';

import { TSocialNetworks, TeamChildrenInList } from 'src/services';

import { City, Country } from '../GeolocationService';

export type AuthRequest = {
  public_address: string;
  nonce: string;
  signature: string;
  referral_uuid?: string;
};

export type TokenObtain = {
  access: string;
  refresh: string;
};

export type TokenRefresh = {
  refresh: string;
};

export enum Gender {
  Male = 'male',
  Female = 'female',
}

export const ALL_GENDERS = Object.values(Gender);

export type UserMe = {
  user_id: number;
  player_id: number;
  parent_id: number;
  referral_uuid: string;
  referral_link: string;
  photo_uuid: string;
  photo: string;
  email: string;
  display_name: string;
  gender: Gender;
  birthday: string;
  is_onboarded: boolean;
  is_study: boolean;
  progress: TUserStudyProgress[];
  social?: TSocialNetworks[];
};

export type User = {
  user_id: number;
  referral_uuid: string;
  email: string;
  display_name: string;
  gender: Gender;
  birthday: string;
  photo_uuid: string;
  photo: string;
  role?: UserRole;
};

export enum NoticeCategories {
  'NEW_MEMBERS_REGISTERING',
  'FORBES_AND_CONTESTS',
  'GREETINGS',
  'MINING',
  'NEW_CLANS_OPENING_AND_NEW_MEMBERS_ACTIVATION',
  'TRAINING_AND_MOTIVATION',
  'FINANCIAL_AND_MARKETPLACE',
}

export type Player = Omit<User, 'user_id' | 'referral_uuid'> & {
  user_id: number;
  is_email_verified: boolean;
  is_nudity_enabled: boolean;
  is_auto_clan_enabled: boolean; // положение галки автомат
  can_auto_clan_enabled: boolean; // можно ли менять галку автомат (disable)
  coordinates: {
    is_location_update_enabled: boolean;
    lat: number;
    long: number;
  };
  available_notice_categories: NoticeCategories[];
  notice_categories_subscriptions: NoticeCategories[];
  country: Country;
  city: City;
  ip: string;
  referral_uuid: string;
  referral_link: string;
  referrer: TeamChildrenInList;
  public_address?: string;
  user_contract?: {
    contract_id: string;
    address?: string;
  };
  language?: string;
  progress?: TUserStudyProgress[];
  social?: TSocialNetworks[];
  ton_usdt?: string;
};

export type TTelegramUser = {
  auth_date: number;
  id: number;
  first_name: string;
  last_name: string;
  hash: string;
  photo_url: string;
  username: string;
  query?: string;
};

export type TGoogleUser = {
  userInfo: any;
  access_token: string;
  authuser: string;
  expires_in: number;
  prompt: string;
  scope: string;
  token_type: string;
};

export type TInstagramUser = IResolveParams['data'];

export type TUserStudyProgress = {
  id: number;
  is_success: boolean | null;
  name: string;
  step_count: number;
};

export enum UserRole {
  Inactive = 'INACTIVE',
  Buyer = 'BUYER',
  Partner = 'PARTNER',
}

export const FilterRoleList = [UserRole.Inactive, UserRole.Buyer, UserRole.Partner];
