import { Box } from '@mui/material';
import { FC } from 'react';

import { TelegramLogo120 } from 'src/assets/images';
import { ImgProgressbar, CircleColor } from 'src/atoms';
import { HierarchyMenu, ProductWidget, ScrollLanding, YouTubeSubscribe } from 'src/molecules';
import { BeeSwarm } from 'src/molecules';
import { TeamChildrenInList } from 'src/services';
import { logger } from 'src/utils';

const menu = {
  id: 0,
  title: 'root',
  children: [
    {
      id: 1,
      title: 'menu1',
      children: [
        {
          id: 4,
          title: 'menu4',
          children: [],
        },
        {
          id: 5,
          title: 'menu5',
          children: [
            {
              id: 6,
              title: 'menu6',
              children: [],
            },
            {
              id: 7,
              title: 'menu7',
              children: [],
            },
            {
              id: 8,
              title: 'menu8',
              children: [],
            },
          ],
        },
      ],
    },
    {
      id: 2,
      title: 'menu2',
      children: [],
    },
    {
      id: 3,
      title: 'menu2',
      children: [],
    },
  ],
};

const Components: FC = () => {
  return (
    <>
      <ScrollLanding>
        <Box sx={{ width: '100%', height: '200%' }}>111</Box>
        <Box sx={{ width: '100%', height: '200%' }}>222</Box>
        <Box sx={{ width: '100%', height: '200%' }}>333</Box>
      </ScrollLanding>
      <Box sx={{ width: '100%', height: '400px' }}>
        <BeeSwarm
          data={
            [
              {
                id: 1,
                user_id: 1,
                photo_uuid: '561fb09d-f19f-4dac-a0a9-c48b1e370071',
                display_name: '',
                date_joined: '',
                team_number: 5,
              },
              {
                id: 2,
                user_id: 2,
                photo_uuid: '561fb09d-f19f-4dac-a0a9-c48b1e370071',
                display_name: '',
                date_joined: '',
                team_number: 15,
              },
              {
                id: 3,
                user_id: 3,
                photo_uuid: '',
                display_name: '',
                date_joined: '',
                team_number: 16,
              },
              {
                id: 4,
                user_id: 4,
                photo_uuid: '',
                display_name: '',
                date_joined: '',
                team_number: 17,
              },
              {
                id: 5,
                user_id: 5,
                photo_uuid: '561fb09d-f19f-4dac-a0a9-c48b1e370071',
                display_name: '',
                date_joined: '',
                team_number: 1239,
              },
            ] as TeamChildrenInList[]
          }
          measure={'team_number'}
        />
      </Box>
      <YouTubeSubscribe channelName="GoogleDevelopers" />
      <HierarchyMenu menu={menu} onClick={(item) => logger.log(item)} onClose={() => false} />
      <Box sx={{ p: '16px' }}>
        <ImgProgressbar percent={0}>
          <img src={TelegramLogo120} alt="" />
        </ImgProgressbar>
      </Box>
      <Box sx={{ p: '16px' }}>
        <ImgProgressbar percent={5}>
          <img src={TelegramLogo120} alt="" />
        </ImgProgressbar>
      </Box>
      <Box sx={{ p: '16px' }}>
        <ImgProgressbar percent={15}>
          <img src={TelegramLogo120} alt="" />
        </ImgProgressbar>
      </Box>
      <Box sx={{ p: '16px' }}>
        <ImgProgressbar percent={98}>
          <img src={TelegramLogo120} alt="" />
        </ImgProgressbar>
      </Box>
      <CircleColor color="#ff6612" />
      <CircleColor color="#66ff12" active={true} />
      <ProductWidget />
    </>
  );
};

export default Components;
