import { Typography, Avatar, IconButton } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { NoPhoto } from 'src/assets/images';
import { ThemeColors } from 'src/styles';

import { OnboardingUserProps } from './OnboardingUser.types';

const OnboardingUser: FC<OnboardingUserProps> = ({ goToSignIn, id, userName, avatarUrl }) => {
  const { t } = useTranslation('app');

  if (!id) {
    return (
      <>
        <Typography textAlign="center" variant="h2" component="div" sx={{ mt: '40px!important' }}>
          {t('onboarding.helloFriend')}
        </Typography>
        <IconButton sx={{ my: '24px', alignSelf: 'center' }} onClick={() => goToSignIn && goToSignIn()}>
          <Avatar src={NoPhoto} sx={{ width: 150, height: 150, border: `1px solid ${ThemeColors.grayLight}` }} />
        </IconButton>
        <Typography textAlign="center" variant="h2" component="div" sx={{ mb: '40px!important' }}>
          {t('onboarding.youGo')}
        </Typography>
      </>
    );
  }
  return (
    <>
      <Typography textAlign="center" variant="h2" component="div" sx={{ mt: '40px!important' }}>
        {t('onboarding.helloThisYourFriend')}, {userName || t('onboarding.unknownName')}!
      </Typography>
      <IconButton sx={{ my: '24px', alignSelf: 'center' }} onClick={() => goToSignIn && goToSignIn(id)}>
        <Avatar
          src={avatarUrl || NoPhoto}
          sx={{ width: 150, height: 150, border: `1px solid ${ThemeColors.grayLight}` }}
        />
      </IconButton>
      <Typography textAlign="center" variant="h2" component="div" sx={{ mb: '40px!important' }}>
        {t('onboarding.youGoWithMe')}
      </Typography>
    </>
  );
};

export default OnboardingUser;
