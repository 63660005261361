import { Box, Switch, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

export const SwitchStyled = styled(Switch)(() => ({
  width: 44,
  height: 22,
  padding: 0,

  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '2px',
    transitionDuration: '300ms',
    color: ThemeColors.black,

    '&.Mui-checked': {
      transform: 'translateX(22px)',

      '& + .MuiSwitch-track': {
        background: ThemeColors.black,
        border: `1px solid ${ThemeColors.black}`,
        opacity: 1,
      },
      '& .MuiSwitch-thumb': {
        background: ThemeColors.white,
        boxShadow: '-1px 0px 5px rgba(0, 0, 0, 0.25)',
      },
    },

    '&.Mui-disabled': {
      '& + .MuiSwitch-track': {
        opacity: 0.3,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 18,
    height: 18,
    border: 0,
  },

  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    backgroundColor: ThemeColors.white,
    border: `1px solid ${ThemeColors.gray}`,
    height: 22,
    opacity: 1,
    transition: 'background-color 300ms',
  },
}));

export const MainLabel = styled(Typography)(() => ({
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '21px',
  marginRight: 10,
}));

export const SwitchBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));
