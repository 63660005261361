import { Box } from '@mui/material';
import { FC, useState, useMemo } from 'react';
import { Waypoint } from 'react-waypoint';

import { ScrollLandingProps } from './ScrollLanding.types';

const ScrollLanding: FC<ScrollLandingProps> = ({ children, sx }) => {
  const keyId = useMemo(() => Math.round(Math.random() * 1000), []);
  const windowHeight = useMemo(() => window.outerHeight, []);

  const [fixedBar, setFixedBar] = useState<number | undefined>();

  const childs = children?.filter((child) => typeof child === 'object');

  return (
    <>
      {childs?.map((child, idx) => {
        return (
          <Box
            key={`ScrollElem${keyId}_${idx}`}
            sx={{
              height: windowHeight * 1.5 + 'px',
            }}
          >
            <Waypoint
              scrollableAncestor={window}
              onEnter={() => {
                setFixedBar(idx);
              }}
              onLeave={({ currentPosition }) => {
                if (idx === childs.length - 1) {
                  setFixedBar(undefined);
                }
                if (currentPosition === 'below' && idx === 0) setFixedBar(undefined);
              }}
            />
            <Box
              sx={{
                position: idx === fixedBar ? 'fixed' : 'relative',
                zIndex: idx === fixedBar ? 1 : 0,
                top: '0px',
                height: '100%',
                width: '100%',
                backgroundColor: 'background.default',
                // opacity: 1,
              }}
            >
              <Box
                sx={{
                  animation: idx === fixedBar ? 'fadeIn 1s linear' : '',
                  '@keyframes fadeIn': {
                    '0%': { opacity: 0 },
                    '100%': { opacity: 1 },
                  },
                  '@keyframes fadeOut': {
                    '0%': { opacity: 1 },
                    '100%': { opacity: 0 },
                  },
                }}
              >
                {child}
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default ScrollLanding;
