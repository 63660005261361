import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Stack)(({ theme }) => ({
  height: 40,
  margin: '0 15px 20px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderRadius: 20,
  border: `1px solid ${theme.palette.secondary.main}`,
  padding: 5,
}));
