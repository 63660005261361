import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import IosShareIcon from '@mui/icons-material/IosShare';
import { IconButton } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Paths } from 'src/constants';

import { Wrapper, Header, Title, Empty } from './HeaderShare.styles';
import { HeaderShareProps } from './HeaderShare.types';

const HeaderShare: FC<HeaderShareProps> = ({ onOpenHandler, children, isShare, backLink }) => {
  const navigate = useNavigate();

  const onBackward = () => navigate(backLink || Paths.dashboard);

  return (
    <Wrapper>
      <Header>
        <IconButton onClick={onBackward}>
          <ArrowBackIosNewIcon />
        </IconButton>
        <Title variant="h1" noWrap>
          {children}
        </Title>
        {isShare ? (
          <IconButton onClick={onOpenHandler}>
            <IosShareIcon />
          </IconButton>
        ) : (
          <Empty />
        )}
      </Header>
    </Wrapper>
  );
};

export default HeaderShare;
