import CloseIcon from '@mui/icons-material/Close';
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CircleLoading, DrawerBottom } from 'src/atoms';

import { SaveProfileConfirmationProps } from './SaveProfileConfirmation.types';

const SaveProfileConfirmation: FC<SaveProfileConfirmationProps> = ({
  isOpen,
  onClose,
  onSubmitForm,
  isShowLoader,
  isLoading,
}) => {
  const { t } = useTranslation('app');

  return (
    <DrawerBottom isOpen={isOpen} onClose={onClose}>
      <IconButton sx={{ position: 'absolute', right: 14, top: 14 }} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <Stack direction="row" justifyContent="center">
        <Typography sx={{ mt: '4px', mb: '16px' }} variant="h2" component="p" noWrap>
          {t('saveProfile.title')}
        </Typography>
      </Stack>
      <Typography variant="body1" component="p" sx={{ mb: '20px', textAlign: 'center' }}>
        {t('saveProfile.text')}
      </Typography>

      {isShowLoader && <CircleLoading isLoading={isLoading} />}

      <Divider variant="fullWidth" sx={{ my: '20px' }} />

      <Stack direction="row" justifyContent="space-between" sx={{ position: 'relative', zIndex: 0 }} spacing="10px">
        <Button variant="text" disabled={isShowLoader} onClick={onClose} fullWidth>
          {t('button.cancel')}
        </Button>
        <Button variant="contained" disabled={isShowLoader} onClick={onSubmitForm} fullWidth>
          {t('button.save')}
        </Button>
      </Stack>
    </DrawerBottom>
  );
};

export default SaveProfileConfirmation;
