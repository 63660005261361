import { Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

export const Block = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.darker,
  border: 'none',
  height: 'auto',
  padding: '2px 6px',
  minWidth: '3.5em',
  justifyContent: 'space-around',
  color: theme.palette.primary.main,
  '& svg': {
    // marginLeft: -8,
    width: '18px',
  },
})) as typeof Button;

export const BadgeBlock = styled(Box)(({ theme }) => ({
  // marginLeft: -4,
  backgroundColor: theme.palette.secondary.dark,
  fontSize: '0.8em',
  lineHeight: '1em',
  verticalAlign: 'middle',
  padding: 4,
  marginLeft: '4px',
  borderRadius: '1em',
  minWidth: '16px',
  minHeight: '16px',
  '&.IconButtonBadge_unread': {
    backgroundColor: ThemeColors.red,
  },
})) as typeof Box;
