import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Button, Divider, IconButton, Stack, TextField, Typography } from '@mui/material';
import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerBottom } from 'src/atoms';
import { City, Country, getCitiesList, getCountriesList } from 'src/services';

import { ChangeGeolocationProps, Options } from './ChangeGeolocation.types';

const ChangeGeolocation: FC<ChangeGeolocationProps> = ({
  isOpen,
  onClose,
  country,
  city,
  onChangeGeoData,
  onSaveCountry,
  onSaveCity,
  tkey,
}) => {
  const { t } = useTranslation('app');

  const [isLoading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [isCityDisabled, setCityDisabled] = useState(false);
  const [countryList, setCountryList] = useState<Country[]>();
  const [cityList, setCityList] = useState<City[]>();
  const [countryOptions, setCountryOptions] = useState<Options[] | null>(null);
  const [countryValue, setCountryValue] = useState<Options | null>(null);
  const [cityOptions, setCityOptions] = useState<Options[] | null>(null);
  const [cityValue, setCityValue] = useState<Options | null>(null);

  const onChangeGeoHandler = () => {
    if (countryValue && countryList) {
      const country = countryList.find((country) => country.id === countryValue.id);
      country && onSaveCountry(country);
    }

    if (cityValue && cityList) {
      const city = cityList.find((city) => city.id === cityValue.id);
      city && onSaveCity(city);
    }

    onChangeGeoData();
  };

  useEffect(() => {
    setLoading(true);
    if (country) {
      getCountriesList()
        .then(({ data }) => {
          const options: Options[] = data.results.map((country) => ({
            id: country.id,
            label: country.translation,
          }));
          setCountryList(data.results);
          setCountryOptions(options);

          return getCitiesList(country.id);
        })
        .then(({ data }) => {
          const options: Options[] = data.results.map((city) => ({
            id: city.id,
            label: city.translation,
          }));
          setCityList(data.results);
          setCityOptions(options);
        })
        .finally(() => setLoading(false));
    }
  }, [country]);

  useEffect(() => {
    if (country && city) {
      setCountryValue({ id: country.id, label: country.translation });
      setCityValue({ id: city.id, label: city.translation });
      setCityDisabled(false);
    }
  }, [country, city]);

  return (
    <DrawerBottom isOpen={isOpen} onClose={onClose}>
      <IconButton sx={{ position: 'absolute', right: 14, top: 14 }} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <Stack direction="row" justifyContent="center">
        <Typography sx={{ mt: '4px', mb: '16px' }} variant="h2" component="p" noWrap>
          {t(`${tkey || 'changeGeolocation'}.title`)}
        </Typography>
      </Stack>

      <Typography variant="body1" component="p" sx={{ mb: '20px', textAlign: 'center' }}>
        {t(`${tkey || 'changeGeolocation'}.text`)}
      </Typography>

      <Autocomplete
        renderInput={(params) => (
          <TextField {...params} placeholder={t('placeholder.chooseCountry')} variant="outlined" />
        )}
        options={countryOptions || []}
        value={countryValue}
        onChange={(_event: any, newValue: Options | null) => {
          setCityDisabled(!newValue);
          setDisabled(true);
          setCountryValue(newValue);
          if (newValue && countryList) {
            const country = countryList.find((country) => country.id === newValue.id);
            country && onSaveCountry(country);
          }
          onSaveCity(null);
          setCityValue(null);
        }}
        disabled={isLoading}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        sx={{ mb: '16px' }}
      />

      <Autocomplete
        renderInput={(params) => <TextField {...params} placeholder={t('placeholder.chooseCity')} variant="outlined" />}
        options={cityOptions || []}
        value={cityValue}
        onChange={(_event: any, newValue: Options | null) => {
          setDisabled(!newValue);
          setCityValue(newValue);
        }}
        disabled={isLoading || isCityDisabled}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.label}
          </li>
        )}
      />

      <Divider variant="fullWidth" sx={{ my: '24px' }} />

      <Stack direction="row" justifyContent="space-between">
        <Button variant="text" onClick={onClose} sx={{ width: '48%' }}>
          {t('button.cancel')}
        </Button>
        <Button variant="contained" onClick={onChangeGeoHandler} sx={{ width: '48%' }} disabled={isDisabled}>
          {t('button.change')}
        </Button>
      </Stack>
    </DrawerBottom>
  );
};

export default ChangeGeolocation;
