/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
import { isDev } from 'src/constants';

export const logger = {
  log: (...args: any) => {
    if (console) {
      console.log(...args);
    }
  },

  debug: (...args: any) => {
    if (console && isDev) {
      console.log(...args);
    }
  },

  error: (...args: any) => {
    if (console) {
      console.error(...args);
    }
  },

  warn: (...args: any) => {
    if (console) {
      console.warn(...args);
    }
  },
};
