import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, MenuList, MenuItem } from '@mui/material';
import * as d3 from 'd3';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FONTS } from 'src/styles';

import WindowTitle from '../WindowTitle';

import { HierarchyMenuProps, THierarchyMenuItem } from './HierarchyMenu.types';

const HierarchyMenu: FC<HierarchyMenuProps> = ({ menu, root, onRootChange, onClick, onClose, sx }) => {
  const { t } = useTranslation('app');

  const [rootItem, setRootItem] = useState<d3.HierarchyNode<THierarchyMenuItem>>();

  const changeRootItem = (node: d3.HierarchyNode<THierarchyMenuItem>) => {
    if (node.children && node.children.length > 0) {
      if (onRootChange) onRootChange(node.data);
      setRootItem(node);
    } else {
      onClick && onClick(node.data);
    }
  };

  useEffect(() => {
    const hData = d3.hierarchy(menu);
    if (root) {
      const rootItem = hData.find((item) => {
        return item.data.id === root;
      });
      if (rootItem) setRootItem(rootItem);
    } else {
      setRootItem(hData);
    }
  }, [menu, root]);

  return (
    <Box sx={sx}>
      {rootItem ? (
        <>
          <WindowTitle
            title={t(rootItem.data.title)}
            onBack={rootItem.parent ? () => rootItem.parent && changeRootItem(rootItem.parent) : undefined}
            onClose={onClose}
            sx={{
              '& .WindowTitle_title': {
                padding: '16px',
              },
            }}
          />
          <MenuList sx={{ p: 0, mb: '0', overflow: 'hidden' }}>
            {rootItem?.children?.map((node) => (
              <MenuItem
                data-qa={node.data.title}
                key={`hiererchyMenuItem_${node.data.id}`}
                value={node.data.id}
                onClick={() => changeRootItem(node)}
                sx={{
                  justifyContent: 'space-between',
                  backgroundColor: (theme) => theme.palette.background.paper,
                  borderBottomWidth: 2,
                  fontFamily: FONTS,
                  fontSize: '1.25rem',
                  fontWeight: 500,
                  lineHeight: '24px',
                  p: '16px',

                  '&:hover': {
                    opacity: 0.9,
                  },
                }}
                divider={false}
              >
                {t(node.data.title)}
                {node.children && node.children.length > 0 ? <KeyboardArrowRightIcon /> : null}
              </MenuItem>
            ))}
          </MenuList>
        </>
      ) : null}
    </Box>
  );
};

export default HierarchyMenu;
