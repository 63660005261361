import { Stack } from '@mui/material';
import { FC } from 'react';

import { CircleColor } from 'src/atoms';

import { ColorCooserProps } from './ColorCooser.types';

const ColorCooser: FC<ColorCooserProps> = ({ colors, active, onChange, sx }) => {
  return (
    <Stack sx={sx} direction="row" spacing={1} justifyContent="center" alignContent="center">
      {colors.map((color) => (
        <CircleColor
          sx={{ cursor: 'pointer' }}
          onClick={() => onChange && onChange(color.id)}
          key={`colorsShooserItem_${color.id}`}
          color={color.value}
          img={color.img}
          active={color.id === active}
          disabled={color.isDisabled || false}
        />
      ))}
    </Stack>
  );
};

export default ColorCooser;
