import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AvatarTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '2rem',
  textAlign: 'center',
}));

export const AvatarText = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const AvatarImage = styled('img')(() => ({
  height: '100%',
  objectFit: 'cover',
  flex: 'none',
}));
