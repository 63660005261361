import { FC } from 'react';

import { Wraper, Block, Value } from './ImageWidget.styles';
import { ImageWidgetProps } from './ImageWidget.types';

const ImageWidget: FC<ImageWidgetProps> = ({ value, children, sx, onClick }) => {
  return (
    <Block sx={sx} onClick={() => (onClick ? onClick() : null)}>
      {children}
      <Wraper className="ImageWidget_wrapper">
        <Value component="div">{value}</Value>
      </Wraper>
    </Block>
  );
};

export default ImageWidget;
