import { Box, Divider, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors, ThemeFontWeight } from 'src/styles';

export const Row = styled(Stack)(() => ({
  marginBottom: 16,
}));

export const SwitchRow = styled(Stack)(() => ({
  marginBottom: 26,
}));

export const EmailVerify = styled(Box)(() => ({
  display: 'flex',
  alignContent: 'center',
}));

export const GeoTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.94rem',
  lineHeight: '20px',
  fontWeight: 600,
  color: theme.palette.secondary.main,
  marginBottom: 8,
}));

export const GeoData = styled(Typography)(() => ({
  fontSize: '0.875rem',
  lineHeight: '22px',
  fontWeight: ThemeFontWeight.semiBold,
  marginBottom: 15,
}));

export const SwitchList = styled('ul')(() => ({
  margin: 0,
  padding: 0,
  listStyle: 'none',
}));

export const SwitchListItem = styled('li')(() => ({
  position: 'relative',
  marginBottom: 26,
}));

export const CensorMessage = styled(Typography)(() => ({
  fontSize: '0.812rem',
  lineHeight: '18px',
  fontWeight: 600,
  color: ThemeColors.redLight,
  marginBottom: 16,
}));

export const TitleVerified = styled(Typography)(() => ({
  fontSize: '1rem',
  lineHeight: '21px',
  fontWeight: ThemeFontWeight.regular,
}));

export const TextWarning = styled(Typography)(() => ({
  fontSize: '0.75rem',
  lineHeight: '18px',
  color: ThemeColors.redLight,
  fontWeight: ThemeFontWeight.regular,
  marginTop: '10px',
}));

export const TextSuccess = styled(Typography)(() => ({
  fontSize: '0.75rem',
  lineHeight: '18px',
  color: ThemeColors.green,
  fontWeight: ThemeFontWeight.regular,
  marginTop: '10px',
}));

export const ValueVerified = styled(Typography)(({ theme }) => ({
  fontSize: '1.062rem',
  lineHeight: '21px',
  fontWeight: ThemeFontWeight.regular,
  paddingLeft: '16px',
  border: '1px solid',
  borderColor: theme.palette.divider,
}));

export const RowDivider = styled(Divider)(() => ({
  marginBottom: '16px',
  marginLeft: '-16px',
  marginRight: '-16px',
}));
