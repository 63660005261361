import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LogoLight, LogoDark } from 'src/assets/images';
import { Loading, IconButtonBadge, Markdown, TitleBadge } from 'src/atoms';
import { Paths, getImgSrc } from 'src/constants';
import { ThemeContext } from 'src/context';
import { ErrorContext } from 'src/context/ErrorContext';
import { useAppDispatch } from 'src/hooks';
import {
  YourProfileWidget,
  AvatarWidget,
  WorldWidget,
  StructureWidget,
  PackWidget,
  TeamWidget,
  WalletWidget,
  ProductWidget,
  BaseWidget,
} from 'src/molecules';
import { ReferralWidget, SNList, Carousel, UserState } from 'src/organisms';
import { Dashboard as DashboardType, TSocialNetworks, getDashboard, setParams } from 'src/services';
import { getInfoUser } from 'src/store/userSlice';
import { ThemePaddings, ThemeFontWeight } from 'src/styles';
import { removeToken } from 'src/utils';

enum DashboardState {
  Short = 0,
  Full = 1,
}

const DashboardHeader: FC<{ notice?: number; isFull?: boolean }> = ({ notice, isFull }) => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const { mode } = useContext(ThemeContext);
  const dispatch = useAppDispatch();

  const handlerLogout = () => {
    removeToken();
    window.location.href = Paths.home;
  };

  const handlerStudy = () => {
    setParams({ is_study: false }).then(() => {
      dispatch(getInfoUser());
      navigate(Paths.study);
    });
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ paddingTop: '2px', backgroundColor: 'background.paper', marginBottom: '14px' }}
    >
      <Stack direction="row" alignItems="center">
        <Box
          component="img"
          data-qa="dashboard.logo"
          onClick={() => navigate(Paths.home)}
          src={mode === 'light' ? LogoLight : LogoDark}
          sx={{ marginLeft: '16px', height: '1.8rem', cursor: 'pointer' }}
        />
        {/*<HelpButtonDrawer tkey="dashboard" />*/}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        spacing={ThemePaddings.pageX}
        sx={{ mr: ThemePaddings.pageX, py: ThemePaddings.pageX }}
      >
        {isFull && (
          <Button
            variant="contained"
            onClick={handlerStudy}
            sx={{
              margin: 0,
              height: '30px',
              fontSize: '0.75rem',
              fontWeight: ThemeFontWeight.medium,
              width: '70px',
              backgroundColor: 'background.darker',
              color: 'primary.main',
            }}
          >
            {t('button.study')}
          </Button>
        )}
        <IconButtonBadge
          count={notice || 0}
          icon={<MailOutlineIcon />}
          onClick={() => navigate(Paths.notifications)}
          sx={{ height: '30px', margin: 0, fontSize: '0.625rem', fontWeight: ThemeFontWeight.medium }}
        />
        <Button
          variant="contained"
          onClick={handlerLogout}
          data-qa="button.logout"
          sx={{
            margin: 0,
            m: ThemePaddings.pageX,
            height: '30px',
            fontSize: '0.75rem',
            fontWeight: ThemeFontWeight.medium,
            width: '70px',
            backgroundColor: 'background.darker',
            color: 'primary.main',
          }}
        >
          {t('button.logout')}
        </Button>
      </Stack>
    </Stack>
  );
};

const Dashboard: FC = () => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setLoading] = useState(true);
  const [dashboard, setDashboard] = useState<DashboardType>();
  const [publicAddress, setPublicAddress] = useState<TSocialNetworks>();

  const userStudyProgress = dashboard?.player?.progress;

  const dashboardState =
    userStudyProgress && userStudyProgress[userStudyProgress.length - 1].is_success
      ? DashboardState.Full
      : DashboardState.Short;

  useEffect(() => {
    setLoading(true);
    getDashboard()
      .then(({ data }) => {
        setDashboard(data);
        if (data?.player?.social) {
          const defItem = data.player.social.findIndex((item) => item.is_default);
          setPublicAddress(data.player.social[defItem >= 0 ? defItem : 0]);
        }
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
        enqueueSnackbar(error, { variant: 'error' });
      })
      .finally(() => setLoading(false));
  }, [enqueueSnackbar, throwServerError]);

  const widgets = dashboard?.widgets.map((widget) => {
    const widgetHeight = dashboardState === DashboardState.Full ? 290 : 430;
    switch (widget.type) {
      case 'sales':
        return (
          <Box sx={{ mb: 2 }} key={`widget_${widget.id}`}>
            <PackWidget
              sx={{
                maxHeight: '140px',
                height: '140px',
                minHeight: '140px',
                '& .PackWidgetBox_text': {
                  maxHeight: '68px',
                  height: '68px',
                  minHeight: '68px',
                },
              }}
            />
          </Box>
        );
      case 'goal':
        return (
          <Box key={`widget_${widget.id}`}>
            <ProductWidget
              item={widget.data.target}
              balance={widget.data.balance}
              cost={widget.data.cost}
              progress={widget.data.progress}
              height={widgetHeight}
            />
          </Box>
        );
      case 'globe':
        return <WorldWidget key={`widget_${widget.id}`} size={widgetHeight} sx={{ height: `${widgetHeight}px` }} />;
      case 'hexglobe':
        return <WorldWidget key={`widget_${widget.id}`} size={widgetHeight} sx={{ height: `${widgetHeight}px` }} />;
      case 'avatar':
        return (
          <AvatarWidget
            key={`widget_${widget.id}`}
            sx={{ height: `${widgetHeight}px` }}
            preview={widget.data?.config?.img}
          />
        );
      case 'structure':
        return <StructureWidget key={`widget_${widget.id}`} size={widgetHeight} sx={{ height: `${widgetHeight}px` }} />;
      default:
        if (widget.data.tKey && widget.data.link) {
          return (
            <Box key={`widget_${widget.id}`}>
              <BaseWidget tKey={widget.data.tKey} link={widget.data.link} sx={{ height: widgetHeight }} />
            </Box>
          );
        } else {
          return null;
        }
    }
  });

  return (
    <Box>
      <DashboardHeader notice={dashboard?.notice} isFull={dashboardState === DashboardState.Full} />
      {isLoading ? <Loading /> : null}
      {dashboard && (
        <>
          <Box sx={{ px: ThemePaddings.pageX, pt: '3px', pb: '10px' }}>
            <YourProfileWidget
              user_id={dashboard.user_id}
              displayName={dashboard.display_name}
              photo={getImgSrc(dashboard.photo_uuid)}
              dateJoined={dashboard.date_joined}
              publicAddress={publicAddress?.account}
              publicAddressType={publicAddress?.icon}
            />
            {dashboardState === DashboardState.Full && (
              <ReferralWidget
                displayName={dashboard.display_name}
                referralLink={dashboard.player.referral_link}
                sx={{ mt: '10px', maxHeight: '140px', height: '140px', minHeight: '140px' }}
              />
            )}
          </Box>
          <Carousel height={dashboardState === DashboardState.Full ? 290 : 430} sx={{ mb: '10px' }} contrast={true}>
            {widgets?.filter((widget) => !!widget)}
          </Carousel>
          {dashboardState === DashboardState.Short && (
            <Typography
              data-qa={'dashboard.state.short.descriptionMD'}
              sx={{ backgroundColor: 'background.paper', p: '16px', margin: '-10px 0px 16px', color: 'secondary.main' }}
            >
              <Markdown>{t('dashboard.state.short.descriptionMD')}</Markdown>
            </Typography>
          )}
          <Grid container spacing={'15px'} sx={{ px: ThemePaddings.pageX, pb: '34px' }}>
            {dashboardState === DashboardState.Full && (
              <>
                <Grid item xs={6}>
                  <TeamWidget count={dashboard.team_count} />
                </Grid>
                <Grid item xs={6}>
                  <WalletWidget balance={dashboard.bbalance} />
                </Grid>
                <Grid item xs={12}>
                  <TitleBadge count={0} sx={{ my: '8px', backgroundColor: 'background.default' }}>
                    {t('profile.stat.title')}
                  </TitleBadge>
                  <UserState userId={dashboard.user_id} />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <SNList official={true} />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default Dashboard;
