import { Stack, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors } from 'src/styles';

export const SortStack = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const SortButton = styled(Button)(() => ({
  width: '48%',
  height: 40,

  '&.checked': {
    backgroundColor: ThemeColors.black,
    color: ThemeColors.white,
  },
}));
