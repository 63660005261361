import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Markdown } from 'src/atoms';
import { getImgSrc } from 'src/constants';
import { ImageWidget } from 'src/molecules';

import { Image, Title } from './BaseWidget.styles';
import { BaseWidgetProps } from './BaseWidget.types';

const BaseWidget: FC<BaseWidgetProps> = ({ tKey, link, sx }) => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  const onClickHandler = () => {
    if (link) {
      navigate(link);
    }
  };

  return (
    <ImageWidget
      data-qa={`widget.${tKey}`}
      onClick={onClickHandler}
      sx={sx}
      value={
        <Title data-qa={`widget.${tKey}.titleMD`}>
          <Markdown>{t(`widget.${tKey}.titleMD`)}</Markdown>
        </Title>
      }
    >
      <Image data-qa={`widget.${tKey}.img`} src={getImgSrc(t(`widget.${tKey}.img`), 's600')} />
    </ImageWidget>
  );
};

export default BaseWidget;
