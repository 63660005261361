import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { NoPhoto } from 'src/assets/images';
import { UserLink } from 'src/atoms';
import { TradeRole } from 'src/services';
import { ThemeColors } from 'src/styles';
import { getPriceInFormat } from 'src/utils';

import {
  InfoWrapper,
  Title,
  UserWallet,
  PointsLine,
  Points,
  PointsLineName,
  PointsLinePrice,
  Total,
  TotalTitle,
  TotalPrice,
  ItemType,
  TypeNumber,
  TypeTitle,
  TxStack,
  TxTitle,
  TxLink,
  Product,
  ProductImage,
  ProductInfo,
  ProductTitle,
  ProductSubTitle,
} from './TransactionInfo.styles';
import { TransactionInfoProps } from './TransactionInfo.types';

const TransactionInfo: FC<TransactionInfoProps> = ({ transaction }) => {
  const { t } = useTranslation('app');

  const balanceChange = Number.parseFloat(transaction.balance_change);
  const totalPortfix = transaction.trade_role !== TradeRole.Neutral ? t(`orderRole.${transaction.trade_role}`) : '';

  return (
    <>
      <InfoWrapper>
        <Title>{t('transaction.from')}</Title>
        <UserLink {...transaction.buyer} sx={{ mb: 1 }} />
        <UserWallet>{transaction.buyer.public_address}</UserWallet>
      </InfoWrapper>

      {/* 
      TODO: удалить после отрисовки дизайна
      <InfoWrapper>
        <Title>{t('transaction.to')}</Title>
        <UserInfo>
          <UserAvatar src={transaction.seller.photo} alt={transaction.seller.display_name} />
          <UserName>{transaction.seller.display_name}</UserName>
        </UserInfo>
        <UserWallet>{transaction.seller.public_address}</UserWallet>
      </InfoWrapper> */}

      <InfoWrapper>
        <Title>{t('transaction.forGoods')}</Title>
        {transaction.items?.map((product, idx) => (
          <Fragment key={product.id}>
            <ItemType>
              <TypeNumber>{idx + 1}.</TypeNumber>
              <TypeTitle>
                {product.action && t(`orderAction.${product.action}`)} {t(`orderType.${product.item_type}`)}
              </TypeTitle>
            </ItemType>
            <TxStack>
              <TxTitle>TxID:</TxTitle>
              <TxLink href={`https://bscscan.com/tx/${product.transaction_id}`} target="_blank">
                {product.transaction_id}
              </TxLink>
            </TxStack>
            <Product>
              <ProductImage src={product.image || NoPhoto} alt={t(`orderType.${product.item_type}`)} />
              <ProductInfo>
                <ProductTitle>{t(`orderType.${product.item_type}`)}</ProductTitle>
                <ProductSubTitle>id {product.id}</ProductSubTitle>
              </ProductInfo>
            </Product>
            {product.seller && (
              <>
                <Title>{t('transaction.to')}</Title>
                <UserLink {...product.seller} />
              </>
            )}
            <PointsLine>
              <PointsLineName>{t('transaction.price')}</PointsLineName>
              <Points />
              <PointsLinePrice>
                {getPriceInFormat(product.price)}
                <span>BNB</span>
              </PointsLinePrice>
            </PointsLine>
            {product.fees.length > 0 &&
              product.fees.map((fee) => (
                <PointsLine key={`fee_${fee.id}`}>
                  <PointsLineName>{t(`orderFees.${fee.fee_type}`)}</PointsLineName>
                  <Points />
                  <PointsLinePrice>
                    {getPriceInFormat(fee.fee_amount)}
                    <span>BNB</span>
                  </PointsLinePrice>
                </PointsLine>
              ))}
          </Fragment>
        ))}
      </InfoWrapper>

      {transaction.trade_role && (
        <InfoWrapper>
          <Title>
            {t('transaction.total')}
            {totalPortfix ? ` ${totalPortfix}` : ''}:
          </Title>
          <PointsLine>
            <PointsLineName>{t('transaction.totalCost')}</PointsLineName>
            <Points />
            <PointsLinePrice>
              {getPriceInFormat(transaction.buyer_total)}
              <span>BNB</span>
            </PointsLinePrice>
          </PointsLine>

          {transaction.trade_role === TradeRole.Buyer && (
            <PointsLine>
              <PointsLineName>{t('transaction.finalCommission')}</PointsLineName>
              <Points />
              <PointsLinePrice>
                {getPriceInFormat(transaction.fee_total)}
                <span>BNB</span>
              </PointsLinePrice>
            </PointsLine>
          )}
        </InfoWrapper>
      )}

      <Total>
        <TotalTitle>{t('transaction.total')}</TotalTitle>
        <TotalPrice sx={{ color: balanceChange < 0 ? ThemeColors.redLight : ThemeColors.greenLight }}>
          {getPriceInFormat(transaction.balance_change)}
          <span>BNB</span>
        </TotalPrice>
      </Total>
    </>
  );
};

export default TransactionInfo;
