import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { IInputFieldProps } from './SearchField.types';

const SearchField = <T extends FieldValues>(props: IInputFieldProps<T>) => {
  const { name, onChange, onClear, onSearch, defaultValue, maxlength, sx, ...rest } = props;

  const [search, setSearch] = useState<string | undefined>(defaultValue);

  const onChangeHandler = (event: any) => {
    const val = event.target.value;
    setSearch(val);
    return onChange && onChange(val);
  };

  const onClearHandler = () => {
    setSearch(undefined);
    return onClear && onClear();
  };

  const onSearchHandler = () => {
    return onSearch && onSearch(search);
  };

  return (
    <TextField
      sx={{
        backgroundColor: 'background.default',
        '& .MuiOutlinedInput-root input': {
          px: '0px',
        },
        ...sx,
      }}
      onChange={onChangeHandler}
      value={search}
      fullWidth
      inputProps={{
        maxLength: maxlength || 250,
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSearchHandler();
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="reset" onClick={() => onClearHandler()}>
              <HighlightOffIcon />
            </IconButton>
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment position="start">
            <IconButton aria-label="search" onClick={() => onSearchHandler()}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

export default SearchField;
