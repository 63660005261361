import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { connectToProviderWallet } from 'src/helpers';
import {
  checkVerify,
  getUserInfo,
  signIn,
  soltSignIn,
  TGoogleUser,
  TInstagramUser,
  TokenObtain,
  TokenRefresh,
  TTelegramUser,
  UserMe,
} from 'src/services';
import { RootState } from 'src/store';
import { walletProviders } from 'src/types';
import { isWalletError, parseError, parseErrorTr } from 'src/utils';

export interface IParamsSignIn {
  public_address: string;
  nonce: string;
  signature: string;
  referral_uuid?: string;
}

export interface UserState {
  isAuthorized: boolean;
  isShowAuth: boolean;
  authError: string | null;
  userMe: UserMe | null;
}

const initialState: UserState = {
  isAuthorized: false,
  isShowAuth: false,
  authError: null,
  userMe: null,
};

export const signInUser = createAsyncThunk<
  TokenObtain,
  {
    referral_uuid: string | undefined;
    provider: 'instagram' | 'google' | 'telegram' | 'wallet';
    authData: TInstagramUser | TGoogleUser | TTelegramUser | IParamsSignIn;
  },
  { rejectValue: string; state: RootState }
>('user/signIn', async (signInArgs, { rejectWithValue, dispatch }) => {
  const { referral_uuid, provider, authData } = signInArgs;
  try {
    const data = await signIn(provider, authData as IParamsSignIn, referral_uuid);

    dispatch(getInfoUser());

    return data;
  } catch (error) {
    return rejectWithValue(parseErrorTr(`${provider}Error`, error));
  }
});

export const connectWallet = async (provider: walletProviders) => {
  const walletResponse = await connectToProviderWallet(uuidv4(), provider);
  const params = {
    public_address: walletResponse.address,
    nonce: walletResponse.token,
    signature: walletResponse.signature,
  };
  return params;
};

export const checkVerifyUser = createAsyncThunk<TokenObtain, TokenRefresh, { rejectValue: string }>(
  'user/checkVerify',
  async (refresh, { rejectWithValue }) => {
    try {
      const data = await checkVerify(refresh);

      return data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const getInfoUser = createAsyncThunk<UserMe, undefined, { rejectValue: string }>(
  'user/getInfo',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getUserInfo();

      return data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signInUser.pending, (state) => {
        state.isAuthorized = false;
        state.isShowAuth = true;
        state.authError = null;
      })
      .addCase(signInUser.fulfilled, (state, action) => {
        state.isAuthorized = !!action.payload?.access;
        state.isShowAuth = false;
        state.authError = null;
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.isAuthorized = false;
        state.isShowAuth = false;
        if (action.payload) {
          state.authError = action.payload;
        }
      });

    builder
      .addCase(checkVerifyUser.pending, (state) => {
        state.authError = null;
      })
      .addCase(checkVerifyUser.fulfilled, (state, action) => {
        state.isAuthorized = !!action.payload?.access;
        state.authError = null;
      })
      .addCase(checkVerifyUser.rejected, (state, action) => {
        state.isAuthorized = false;
        if (action.payload) {
          state.authError = action.payload;
        }
      });

    builder
      .addCase(getInfoUser.fulfilled, (state, action) => {
        state.userMe = action.payload;
        state.authError = null;
      })
      .addCase(getInfoUser.rejected, (state, action) => {
        state.userMe = null;
        if (action.payload) {
          state.authError = action.payload;
        }
      });
  },
});

export const signInSolt = createAsyncThunk<TokenObtain, { solt: string }, { rejectValue: string; state: RootState }>(
  'user/signIn',
  async (signInArgs, { rejectWithValue, dispatch }) => {
    try {
      const { solt } = signInArgs;

      const data = await soltSignIn(solt);

      dispatch(getInfoUser());

      return data;
    } catch (error) {
      if (isWalletError(error)) {
        return rejectWithValue(parseErrorTr('linkAuthError', error.code));
      } else {
        return rejectWithValue(parseError(error));
      }
    }
  }
);

export const selectUser = (state: RootState) => state.user;
export const selectAuthorized = (state: RootState) => state.user.isAuthorized;
export const selectUserMe = (state: RootState) => state.user.userMe;

export const userReducer = userSlice.reducer;
