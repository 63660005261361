import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { EmptyData } from 'src/atoms';
import { Paths } from 'src/constants';
import { PageTitle } from 'src/molecules';
import { NFTCard } from 'src/organisms';
import { ThemePaddings } from 'src/styles';
import { RouterCompositeIdParam } from 'src/types';

const ProfileUser: FC = () => {
  const { t } = useTranslation('app');
  const { compositeId } = useParams<RouterCompositeIdParam>();

  const navigate = useNavigate();
  const onCloseHandler = () => (window.history.length ? navigate(-1) : navigate(Paths.dashboard));

  if (!compositeId)
    return <EmptyData title={t('NFTCard.emptyTitle')} data-qa={'NFTCard.emptyTitle'} text={t('NFTCard.emptyText')} />;

  return (
    <Box sx={{ p: ThemePaddings.page }}>
      <PageTitle
        title={t('nftcard.title')}
        data-qa={'nftcard.title'}
        help={{ tkey: 'nftcard' }}
        onClose={onCloseHandler}
      />

      <NFTCard compositeId={compositeId} />
    </Box>
  );
};

export default ProfileUser;
