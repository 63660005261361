import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Stack, Avatar, Fab, Dialog, DialogContent, Typography, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useState, ChangeEvent, useEffect } from 'react';

import { NoAvatar } from 'src/assets/icons';
import { CropDialog } from 'src/molecules';
import { ThemeColors } from 'src/styles';

import { UploadAvatarProps } from './UploadAvatar.types';

const UploadAvatar: FC<UploadAvatarProps> = ({ value, onChange, hasError, textError, defaultValue }) => {
  const [image, setImage] = useState<File>();
  const [preview, setPreview] = useState(defaultValue || '');
  const [fileName, setFileName] = useState('');
  const [mimeType, setMimeType] = useState('');
  const [isCropOpen, setCropOpen] = useState(false);

  const handlerSelectFile = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      if (file) {
        setImage(file);
        setFileName(file.name);
        setMimeType(file.type);
        setCropOpen(true);
      }
    }
  };

  const handlerClose = () => setCropOpen(false);

  const handlerCrop = async (file: File) => {
    setImage(file);
    onChange(file);
    setCropOpen(false);
  };

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result?.toString() || '');
      };
      reader.readAsDataURL(image);
    }
  }, [image]);

  return (
    <Box sx={{ position: 'relative' }}>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Stack position="relative">
          {!preview && (
            <IconButton component="label" htmlFor="upload-photo">
              <Avatar
                sx={{
                  width: 150,
                  height: 150,
                  border: '1px solid',
                  borderColor: 'secondary.main',
                  backgroundColor: 'background.paper',
                  color: 'primary.main',
                  p: preview ? 0 : 1,
                  zIndex: -1,
                }}
                src={NoAvatar}
              />
            </IconButton>
          )}
          {preview && (
            <>
              <Avatar
                sx={{
                  width: 150,
                  height: 150,
                  border: '2px solid',
                  borderColor: 'primary.light',
                  p: preview ? 0 : 1,
                  zIndex: -1,
                }}
                src={preview}
              />
              <Fab
                component="label"
                htmlFor="upload-photo"
                sx={{
                  width: 50,
                  height: 50,
                  zIndex: 'auto',
                  border: '1px solid transparent',
                  borderColor: 'primary.main',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  //backgroundClip: 'padding-box',
                  backgroundColor: 'background.paper',
                  color: 'primary.main',
                  /*
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: -3,
                    left: -3,
                    width: 50,
                    height: 50,
                    borderRadius: 50 / 2,
                    background: ThemeGradients.marker,
                    zIndex: -1,
                  },*/
                }}
              >
                <EditOutlinedIcon sx={{ width: '25px', height: '25px' }} />
              </Fab>
            </>
          )}
        </Stack>

        <input
          type="file"
          accept="image/jpeg, image/png"
          style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
          onChange={handlerSelectFile}
          id="upload-photo"
        />
      </Stack>
      {hasError && (
        <Typography
          sx={{
            color: ThemeColors.redLight,
            fontSize: '0.75rem',
            textAlign: 'center',
            position: 'absolute',
            bottom: -25,
            left: 0,
            right: 0,
          }}
        >
          {textError}
        </Typography>
      )}

      <Dialog open={isCropOpen} onClose={handlerClose} fullScreen>
        <DialogContent>
          <CropDialog imageSrc={preview} onChange={handlerCrop} fileName={fileName} mimeType={mimeType} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default UploadAvatar;
