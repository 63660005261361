import { PaletteMode } from '@mui/material';
import { useState, FC, ReactNode, createContext } from 'react';

import { StorageKeys } from 'src/constants';
import { getCensor, getLocale } from 'src/utils';

type ThemeContextType = {
  mode: PaletteMode;
  toggleMode: VoidFunction;
  isShowLang: boolean;
  changeShowLang: VoidFunction;
  isShowCensor: boolean;
  changeShowCensor: (censor: string) => void;
  isDemo: boolean;
  toggleDemo: VoidFunction;
};

type ThemeContextProviderProps = {
  children: ReactNode;
};

const defaultMode = 'dark'; // light dark

export const ThemeContext = createContext<ThemeContextType>({
  // TODO: заменить на это, когда будет темная тема
  // mode: isDarkTheme() ? 'dark' : 'light',
  mode: defaultMode,
  toggleMode: () => {
    throw new Error('Not implemented');
  },
  isShowLang: false,
  changeShowLang: () => {
    throw new Error('Not implemented');
  },
  isShowCensor: false,
  changeShowCensor: (censor: string) => {
    throw new Error('Not implemented');
  },
  isDemo: false,
  toggleDemo: () => {
    throw new Error('Not implemented');
  },
});

export const ThemeContextProvider: FC<ThemeContextProviderProps> = ({ children }) => {
  // TODO: заменить на это, когда будет темная тема
  // const [mode, setMode] = useState<PaletteMode>(isDarkTheme() ? 'dark' : 'light');
  const [mode, setMode] = useState<PaletteMode>(defaultMode);
  const [isShowLang, setShowLang] = useState(!!getLocale());
  const [isShowCensor, setShowCensor] = useState(!!getCensor());
  const [isDemo, setDemo] = useState(false);

  const toggleMode = (): void => {
    // TODO: заменить на это, когда будет темная тема
    // const newMode = isDarkTheme() ? 'light' : 'dark';
    const newMode = defaultMode;
    localStorage.setItem(StorageKeys.THEME, newMode);
    setMode(newMode);
  };

  const changeShowLang = () => {
    const newShowLang = !!getLocale();
    localStorage.setItem(StorageKeys.SHOW_LANG, String(newShowLang));
    setShowLang(newShowLang);
  };

  const changeShowCensor = (censor: string) => {
    localStorage.setItem(StorageKeys.CENSOR, censor);
    setShowCensor(!!censor);
  };

  const toggleDemo = (): void => {
    setDemo((prevDemo) => !prevDemo);
  };

  const value = {
    mode,
    toggleMode,
    isShowLang,
    changeShowLang,
    isShowCensor,
    changeShowCensor,
    isDemo,
    toggleDemo,
  };

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};
