import { StorageKeys } from 'src/constants';
import { TokenObtain } from 'src/services';

export const setToken = (token: TokenObtain) => localStorage.setItem(StorageKeys.TOKEN, JSON.stringify(token));

export const getToken = (): TokenObtain => {
  const token = localStorage.getItem(StorageKeys.TOKEN);

  if (!token) return { access: '', refresh: '' };
  return JSON.parse(token);
};

export const hasToken = () => !!getToken().access;

export const removeToken = () => localStorage.removeItem(StorageKeys.TOKEN);
