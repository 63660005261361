import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getMaterials } from 'src/molecules/filters/OperationType/OperationType.utils';

import { CarouselMaterialTitle } from './CarouselMaterialTitle.styles';
import { TitleProps } from './CarouselMaterialTitle.type';

const LevelName: FC<{ level: number; grade: number }> = ({ level, grade }) => {
  const { t } = useTranslation('app');
  return (
    <>
      {t('material.lvl')}.{level}.{grade}{' '}
    </>
  );
};

const MaterialTilte: FC<TitleProps> = ({ item, title, withLevel }) => {
  const { t } = useTranslation('app');

  const tabsItems = getMaterials(t);
  const levelInfo = tabsItems.find((l) => l.level === item?.level);

  return (
    <CarouselMaterialTitle color={levelInfo?.color}>
      {item ? (
        <>
          <span className="CarouselMaterialTitle__level">
            {withLevel ? <LevelName level={item.level} grade={item.grade} /> : ''}
            {item.levelName}
          </span>{' '}
          - {item.gradeName}
        </>
      ) : (
        title
      )}
    </CarouselMaterialTitle>
  );
};

export default MaterialTilte;
