import { Button, Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Address, Markdown } from 'src/atoms';
import { Paths } from 'src/constants';
import { WalletConnect } from 'src/molecules';
import { getAuthLink } from 'src/services';
import { walletProviders } from 'src/types';

import Help from '../Help';

const StudyWallet: FC = () => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  const [hasWallet, setHasWallet] = useState<boolean>();
  const [link, setLink] = useState<string>('');

  useEffect(() => {
    if (hasWallet) {
      getAuthLink().then(({ data }) => {
        setLink(data.link);
      });
    }
  }, [hasWallet]);

  if (hasWallet === undefined) {
    return (
      <>
        <Markdown>{t('study.wallet.info.textMD')}</Markdown>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
          <Button data-qa={'study.wallet.button.yes'} variant="text" onClick={() => setHasWallet(true)} fullWidth>
            {t('study.wallet.button.yes')}
          </Button>
          <Button data-qa={'study.wallet.button.no'} variant="text" onClick={() => setHasWallet(false)} fullWidth>
            {t('study.wallet.button.no')}
          </Button>
        </Stack>
      </>
    );
  } else {
    return (
      <>
        {hasWallet ? (
          <Help tkey="study.wallet.yes" onPrev={() => setHasWallet(undefined)} onNext={() => setHasWallet(false)}>
            <>
              <Markdown>{t('study.wallet.yes.info.textMD')}</Markdown>
              <Stack direction="column">
                <Address
                  address={link}
                  title={t('study.wallet.yes.link.title')}
                  data-qa={'study.wallet.yes.link.title'}
                />
                <Typography sx={{ my: '10px' }}>{t('study.wallet.yes.descriptionLink')}</Typography>
                <WalletConnect onConnect={() => navigate(Paths.study)} walletProvider={walletProviders.TrustWallet} />
                <Typography sx={{ my: '10px' }}>{t('study.wallet.yes.descriptionButton')}</Typography>
              </Stack>
            </>
          </Help>
        ) : (
          <Help tkey="study.wallet.no" onPrev={() => setHasWallet(undefined)} onNext={() => setHasWallet(true)} />
        )}
      </>
    );
  }
};

export default StudyWallet;
