import { AxiosResponse } from 'axios';

import agent from 'src/agent';
import { PaginationParams } from 'src/types';

import { CitiesResponse, CountriesResponse, Geolocation } from './GeolocationService.types';

export const getGeolocation = (): Promise<AxiosResponse<Geolocation>> => {
  return agent.GET('locations/geo-location');
};

export const getCountriesList = (params?: PaginationParams): Promise<AxiosResponse<CountriesResponse>> => {
  const { limit = 300, offset = 0 } = params || {};
  return agent.GET('cities/countries', { params: { limit, offset } });
};

export const getCitiesList = (countryId: number, params?: PaginationParams): Promise<AxiosResponse<CitiesResponse>> => {
  const { limit = 10000, offset = 0 } = params || {};
  return agent.GET('cities', { params: { country_id: countryId, limit, offset } });
};
