import { IconButton, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeFontWeight } from 'src/styles';

export const InputButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.primary.main,
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  width: 30,
  height: 30,
}));

export const InputField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    padding: '0px',
    paddingRight: '0px!important',
    '& input': {
      padding: '0px 8px',
      textAlign: 'center',
      height: '30px',
      width: '60px',
      backgroundColor: theme.palette.background.default,
      fontSize: '1.25rem',
      fontWeight: ThemeFontWeight.semiBold,
    },
  },
}));
