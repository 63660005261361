import { TFunction } from 'react-i18next';

import { Paths } from 'src/constants';
import { SwitchLinks } from 'src/types';

export const getSwitchLinksForNotification = (t: TFunction<'app', undefined>): SwitchLinks[] => {
  return [
    { id: 1, title: t('app:notifications.new'), path: Paths.notifications },
    { id: 2, title: t('app:notifications.archive'), path: Paths.notificationsArchive },
  ];
};
