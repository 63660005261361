import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { RouterPageIdParam } from 'src/types';

import MDPage from '../MDPage';

const AnyPage: FC = () => {
  const { pageId } = useParams<RouterPageIdParam>();

  return <MDPage tkey={pageId || 'page'} />;
};

export default AnyPage;
