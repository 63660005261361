import * as yup from 'yup';

import { getRequiredField, getErrorPhoto } from 'src/utils';

// const maxLengthEmail = () => maxStringLength(256);

export const schema = yup.object().shape({
  display_name: yup.string().required(getRequiredField).nullable(),
  has_photo: yup.boolean().isTrue(getErrorPhoto),
  yes_offerta: yup.boolean().required(getRequiredField).isTrue(),
  yes_rule: yup.boolean().required(getRequiredField).isTrue(),
});
