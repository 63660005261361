import { AxiosResponse } from 'axios';

import agent from 'src/agent';

import { UserRole } from '../UserService';

import { TeamList, TeamTree, TeamType } from './TeamService.types';

export const getTeam = (
  type: TeamType,
  filter: {
    playerId?: number;
    userId?: number;
    role?: UserRole[];
  }
): Promise<AxiosResponse<TeamList | TeamTree>> => {
  let params = {
    show_type: type,
    user_id: (filter && filter.userId) || undefined,
    player_id: (filter && filter.playerId) || undefined,
    role: filter && filter.role ? JSON.stringify(filter.role) : undefined,
  };
  return agent.GET('team', { params });
};
