import { Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BPrice, UserLink } from 'src/atoms';
import { TradeRole } from 'src/services';
import { ThemeColors } from 'src/styles';

import { Type } from './HistoryTransaction.styles';
import { HistoryTransactionProps } from './HistoryTransaction.types';

const HistoryTransaction: FC<HistoryTransactionProps> = ({ payment, currancy, isLast, height = 65, onClick }) => {
  const { t } = useTranslation('app');

  const isBuy = payment.trade_role === TradeRole.Buyer;
  const owner = isBuy ? payment.seller : payment.buyer;

  const paymentItemsTypes = payment.items.map((item) => t(`orderType.${item.item_type}`)).join(', ');
  const payCost = payment.balance_change;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        // backgroundColor: (theme) => theme.palette.background.paper,
        p: '0 16px',
        // borderTop: '1px solid',
        // borderBottom: isLast ? '1px solid' : 'none',
        // borderColor: 'divider',
        height,
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick}
    >
      <BPrice
        sx={{
          color: payCost.bcost > 0 ? ThemeColors.greenLight : 'primary.main',
          '& .BPrice_icon': {
            display: 'none',
          },
          '& .BPrice_bcurrency': {
            color: 'secondary.main',
          },
          '& .BPrice_cost': {
            display: 'none',
          },
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          minWidth: '130px',
          maxWidth: '25%',
          flex: 'none',
        }}
        price={payCost}
      />
      <Type sx={{ flex: 1 }}>{paymentItemsTypes}</Type>
      {owner ? <UserLink {...owner} sx={{ width: '25%', flex: 'none' }} /> : null}
    </Stack>
  );
};

export default HistoryTransaction;
