//@ts-ignore
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { FC } from 'react';
import { Controller, FieldValues } from 'react-hook-form';

import { IInputFieldProps } from './InputField.types';

const InputIconClear: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <InputAdornment position="end">
      <IconButton aria-label="reset" onClick={onClick}>
        <HighlightOffIcon />
      </IconButton>
    </InputAdornment>
  );
};

const InputField = <T extends FieldValues>(props: IInputFieldProps<T>) => {
  const { name, control, defaultValue, maxlength, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          onChange={onChange}
          value={value}
          error={!!error}
          helperText={error ? error.message : null}
          fullWidth
          inputProps={{
            maxLength: maxlength || 250,
          }}
          InputProps={{
            endAdornment: value && <InputIconClear onClick={() => onChange('')} />,
          }}
          {...rest}
        />
      )}
    />
  );
};

export default InputField;
