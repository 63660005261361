import { FC } from 'react';

import { BNBIcon, USDTIcon } from 'src/assets/icons';
import { formatCurrency } from 'src/utils';

import { Price } from './BPrice.styles';
import { BPriceProps } from './BPrice.types';

const BPrice: FC<BPriceProps> = ({ price: priceProp, withSign, sx }) => {
  const price =
    typeof priceProp === 'object'
      ? priceProp
      : {
          bcost: Number(priceProp) || 0,
          bcurrency: '',
          cost: null,
          currency: '',
        };

  return (
    <Price sx={sx} component="div" className="BPrice">
      {price.bcurrency.toLocaleLowerCase() === 'usdt' ? (
        <USDTIcon width="1em" height="1em" className="BPrice_icon BPrice_icon__usdt" />
      ) : (
        <BNBIcon width="1em" height="1em" className="BPrice_icon BPrice_icon__bnb" />
      )}
      <span className="BPrice_bcost">
        {formatCurrency(price.bcost, 7, withSign)} <span className="BPrice_bcurrency">{price.bcurrency}</span>
      </span>
      {price.cost ? (
        <span className="BPrice_cost">
          (~{formatCurrency(price.cost, 2)} <span className="BPrice_currency">{price.currency}</span>)
        </span>
      ) : (
        ''
      )}
    </Price>
  );
};

export default BPrice;
