import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AccordionSummary } from '@mui/material';
import { FC } from 'react';

import { getImgSrc } from 'src/constants';

import CircleLoading from '../CircleLoading';

import { Block, Icon, NotificationHeader, TitleStack, Title, ShortText, StatusIcon } from './StudyStepItem.styles';
import { NotificationItemProps } from './StudyStepItem.types';

const StudyStepItem: FC<NotificationItemProps> = ({ id, img, title, shortText, state, disabled, onClick }) => {
  return (
    <Block sx={{}} onClick={() => !disabled && onClick && onClick(id)}>
      <AccordionSummary sx={{ opacity: disabled ? 0.5 : 1 }} aria-controls={`${id}-content`} id={`${id}-header`}>
        <Icon src={getImgSrc(img, 's40-c')} alt="" />
        <NotificationHeader>
          <TitleStack>
            <Title>{title}</Title>
          </TitleStack>
          <ShortText className="shortText">{shortText}</ShortText>
        </NotificationHeader>
        <StatusIcon>
          {state === 'complete' ? (
            <CheckCircleIcon fontSize="medium" color="success" />
          ) : !disabled ? (
            <CircleLoading isLoading={true} size={24} />
          ) : null}
        </StatusIcon>
      </AccordionSummary>
    </Block>
  );
};

export default StudyStepItem;
