import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Paths } from 'src/constants';
import { PageTitle } from 'src/molecules';
import { GoalListHistory } from 'src/organisms';
import { ThemePaddings } from 'src/styles';

const GoalHistory: FC = () => {
  const { t } = useTranslation('app');

  const navigate = useNavigate();

  const onCloseHandler = () => (window.history.length ? navigate(-1) : navigate(Paths.dashboard));

  return (
    <>
      <Box sx={{ p: ThemePaddings.page, pb: '0px' }}>
        <PageTitle
          title={t('goalHistory.title')}
          data-qa={'goalHistory.title'}
          help={{ tkey: 'goalHistory' }}
          onClose={onCloseHandler}
        />
      </Box>
      <GoalListHistory sx={{ height: 'calc(100% - 136px)', pb: 0 }} />
    </>
  );
};

export default GoalHistory;
