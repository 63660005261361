import { UserRole } from '../UserService';

export enum ClanStatus {
  Open = 'OPEN',
  Close = 'CLOSED',
}

export enum ClanPosition {
  Left = 'LEFT',
  Right = 'RIGHT',
}

export type UserInClanNode = {
  id: number;
  player_id: number;
  photo: string;
  photo_uuid: string;
  display_name: string;
  role?: UserRole;
};

export type ClanListChildrenNode = {
  id: number;
  user: UserInClanNode;
  position: ClanPosition;
  children: ClanListChildrenNode[];
};

export type ClanListItem = {
  id: number;
  user: UserInClanNode;
  status: ClanStatus;
  created_at: string;
  closed_at: string;
  children: ClanListChildrenNode[];
};

export type ClanList = {
  count: number;
  results: ClanListItem[];
  closed_levels: number[];
};

export type ClanAncestors = {
  id: number;
  user: UserInClanNode;
};

export type ClanTreeChildrenNode = {
  id: number;
  user?: UserInClanNode;
  count?: number;
  position?: ClanPosition;
  sub_branches?: number[];
  children: ClanTreeChildrenNode[];
  clan_ids?: number[];
  is_fake?: boolean;
  clan?: ClanTreeChildrenNode;
};

export type ClanTree = {
  id: number;
  user: UserInClanNode;
  status: ClanStatus;
  created_at: string;
  closed_at: string;
  ancestors: ClanAncestors[];
  children: ClanTreeChildrenNode[];
  clan_ids: number[];
};

export type ClanTreeResponse = {
  clan_node: ClanTree;
  closed_levels: number[];
  user_nodes: number[]; // все кланы пользователя
  referrer_nodes: number[]; // все кланы реферала (спонсора)
};

export type ClanTreeParams = {
  clan_node_id?: number;
  clan_level?: number;
};
