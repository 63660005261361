import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { FC, useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { DateRow, EmptyData, HistoryTransaction, Markdown, SwitchButtons } from 'src/atoms';
import { LIMIT_ORDERS, Paths, isMarket, pathsArg } from 'src/constants';
import { ErrorContext } from 'src/context/ErrorContext';
import { useAppDispatch, useAppSelector, useResize } from 'src/hooks';
import { Card, TonWalletConnect, WalletConnect } from 'src/molecules';
import { Carousel } from 'src/organisms';
import { getOrdersByParams, TOrder, OrderParams, TOrderPrice, TOrderWallet } from 'src/services';
import { setOpenFilters } from 'src/store/filtersSlice';
import { selectUserMe } from 'src/store/userSlice';
import { ThemePaddings } from 'src/styles';
import { walletProviders } from 'src/types';
import { formatDate, logger } from 'src/utils';

const ITEM_SIZE = 53;

type PaymentListDivider = {
  isDate: boolean;
  created_at: string;
};

const isPaymentListDivider = (a: TOrder | PaymentListDivider): a is PaymentListDivider => {
  return 'isDate' in a;
};

const Finance: FC = () => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);
  const navigate = useNavigate();
  const userMe = useAppSelector(selectUserMe);
  const dispatch = useAppDispatch();

  const scrollRef = useRef<List<any> | null>();
  const containerRef = useRef<HTMLDivElement>(null);

  const { height: wrapperHeight } = useResize(containerRef);

  const [isLoading, setLoading] = useState(true);
  // const [level, setLevel] = useState(0);
  const [payments, setPayments] = useState<TOrder[] | null>(null);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [height, setHeight] = useState(0);
  const [filters, setFilters] = useState<OrderParams>({});
  const [balance, setBalance] = useState<TOrderPrice>();

  const [wallets, setWallets] = useState<TOrderWallet[]>([]);
  const [wallet, setWallet] = useState<TOrderWallet>();

  const handlerBalanceType = (fType: string) => {
    setFilters({ ...filters, balance_change: fType });
    setOffset(0);
  };

  useEffect(() => {
    if (!offset) {
      const params: OrderParams = {};
      params.offset = offset;
      params.limit = LIMIT_ORDERS;

      setLoading(true);
      getOrdersByParams({ ...params, ...filters, wallet: wallet?.wallet })
        .then(({ data }) => {
          setPayments(data.results);
          setCount(data.count);
          setBalance(data.balance);
          setWallets(data.wallets);
          if (!wallet) setWallet(data.wallets[0]);

          setOffset(LIMIT_ORDERS);
        })
        .catch((error) => {
          if (error.response?.status >= 500) throwServerError();
          logger.error(error);
        })
        .finally(() => setLoading(false));
    }
  }, [offset, throwServerError, userMe, filters, wallet]);

  useEffect(
    () => () => {
      setPayments(null);
      setCount(0);
      setOffset(0);
      dispatch(setOpenFilters(false));
    },
    [dispatch]
  );

  useEffect(() => {
    const { current } = containerRef;
    if (!current) return;

    setHeight(current.offsetHeight);
  }, [containerRef]);

  useEffect(() => {
    if (!wrapperHeight) return;

    setHeight(wrapperHeight);
  }, [wrapperHeight]);

  const update = () => {
    setPayments(null);
    setCount(0);
    setOffset(0);
  };

  const isItemLoaded = (index: number) => (payments?.length ? !!payments[index] : false);

  const loadMoreItems = () => {
    if (offset >= count) return;

    const params: OrderParams = {};
    // if (level > 0) params.clan_tier = level;
    params.offset = offset;
    params.limit = LIMIT_ORDERS;

    getOrdersByParams({ ...params, ...filters })
      .then(({ data }) => {
        payments && setPayments([...payments, ...data.results]);
        setOffset((prevOffset) => prevOffset + LIMIT_ORDERS);
      })
      .catch((error) => {
        if (error.response?.status >= 500) throwServerError();
        logger.error(error);
      });
  };

  const paymentResult =
    payments?.reduce<(TOrder | PaymentListDivider)[]>((acc, payment, index) => {
      const prevPayment = payments[index - 1];
      const prevDate = prevPayment?.created_at ? formatDate(prevPayment.created_at) : '';
      const currentDate = payment.created_at ? formatDate(payment.created_at) : '';

      if (prevDate !== currentDate) acc.push({ isDate: true, created_at: currentDate });
      acc.push(payment);

      return acc;
    }, []) || [];

  const handlerWalletChange = (index: number) => {
    if (wallets && wallet && wallets[index].wallet !== wallet?.wallet) {
      setWallet(wallets[index]);
      setOffset(0);
    }
  };

  return (
    <>
      <Box ref={containerRef} sx={{ p: ThemePaddings.wallet, height: 'calc(100% - 388px - 162px)' }}>
        {isLoading && (
          <LinearProgress sx={{ position: 'absolute', marginLeft: 0, left: 0, top: 0, width: '100%', zIndex: 20 }} />
        )}

        {wallet && wallets ? (
          <Carousel
            height={200}
            sx={{
              mb: '10px',
              background: 'none',
            }}
            onChange={handlerWalletChange}
          >
            {wallets.map((item) => (
              <Box key={`walletCard_${item.wallet}`} sx={{ position: 'relative' }}>
                <Card
                  balance={item.wallet === wallet?.wallet ? balance : undefined}
                  address={item.address}
                  tkey={`wallet.${item.wallet}`}
                  color={t(`wallet.${item.wallet}.cardColor`)}
                />
                {!item.address && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      borderRadius: '16px',
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                      width: '100%',
                      maxWidth: '343px',
                      height: '100%',
                      margin: '0 auto 40px',
                      padding: '16px 16px 20px',
                    }}
                  >
                    <Markdown>{t(`wallet.connection.${item.walletProvider}.description`)}</Markdown>
                    {item.walletProvider === walletProviders.Ton ? (
                      <TonWalletConnect wallet={item.address} onUpdate={update} />
                    ) : (
                      <WalletConnect onConnect={update} walletProvider={walletProviders.TrustWallet} />
                    )}
                  </Box>
                )}
              </Box>
            ))}
          </Carousel>
        ) : (
          isMarket &&
          !isLoading && (
            <Box sx={{ padding: '16px' }}>
              <Markdown>{t(`wallet.connection.${walletProviders.Ton}.description`)}</Markdown>
              <TonWalletConnect wallet={wallet?.wallet || ''} onUpdate={update} />
            </Box>
          )
        )}

        <Stack direction="row" justifyContent="center">
          <SwitchButtons
            sx={{
              width: 'auto',
              border: 'none',
              background: 'none',
              position: 'relative',
              alignItems: 'center',
              '&:after': {
                position: 'absolute',
                backgroundColor: 'background.paper',
                content: '""',
                zIndex: -1,
                borderRadius: '15px',
                height: '17px',
                left: '-10px',
                right: '-10px',
              },

              '& .SwitchButtons_button': {
                fontSize: '0.75rem',
                width: 'auto',
                color: 'text.primary',
                height: '28px',
                px: '11px',
              },

              '& .SwitchButtons_button__unactive': {
                background: 'none',
                border: 'none',
              },
              '& .SwitchButtons_button__active': {
                backgroundColor: 'background.paper',
                border: '1px solid',
                borderColor: 'text.primary',
                borderRadius: '10px',
                px: '10px',
              },
            }}
            buttons={[
              {
                id: 'all',
                title: t('finance.filter.all'),
              },
              {
                id: 'income',
                title: t('finance.filter.income'),
              },
              {
                id: 'expense',
                title: t('finance.filter.expense'),
              },
            ]}
            value={filters.balance_change}
            onClick={(item) => handlerBalanceType(item.id.toString())}
          />
        </Stack>

        <Box
          sx={{ backgroundColor: 'background.paper', p: '10px 16px', m: '0px -16px 0px -16px', position: 'relative' }}
        >
          <Typography
            sx={{
              fontSize: '1.25rem',
              lineHeight: '24px',
              fontWeight: 500,
              my: '16px',
              justifyContent: 'center',
              display: 'flex',
              color: 'secondary.main',
            }}
            variant="h2"
            component="p"
          >
            {t('wallet.historyTitle')}
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            sx={{
              backgroundColor: 'background.default',
              borderRadius: 12,
              padding: '4px 0',
              mb: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: '0.812rem',
                lineHeight: '18px',
                fontWeight: 600,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              {t('wallet.historySum')}
            </Typography>
            <Typography
              sx={{
                fontSize: '0.812rem',
                lineHeight: '18px',
                fontWeight: 600,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              {t('wallet.historyForWhat')}
            </Typography>
            <Typography
              sx={{
                fontSize: '0.812rem',
                lineHeight: '18px',
                fontWeight: 600,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              {t('wallet.historyToFrom')}
            </Typography>
          </Stack>
        </Box>

        {!isLoading &&
          (payments?.length ? (
            <>
              <Box
                sx={{
                  position: 'relative',
                  margin: '0 -16px',
                  height: '100%',
                }}
              >
                <InfiniteLoader
                  isItemLoaded={isItemLoaded}
                  itemCount={count}
                  loadMoreItems={loadMoreItems}
                  threshold={LIMIT_ORDERS}
                >
                  {({ onItemsRendered, ref }) => (
                    <List
                      height={height < 360 ? 360 : height}
                      width={'100%'}
                      itemCount={paymentResult.length}
                      itemSize={ITEM_SIZE}
                      onItemsRendered={onItemsRendered}
                      ref={(listElement) => {
                        ref(listElement);
                        scrollRef.current = listElement;
                      }}
                    >
                      {({ index, style }) => {
                        const paymentItem = paymentResult[index];

                        if (isPaymentListDivider(paymentItem) && paymentItem.isDate) {
                          return (
                            <div style={style}>
                              <DateRow height={ITEM_SIZE}>{paymentItem.created_at}</DateRow>
                            </div>
                          );
                        }

                        return (
                          <div style={style}>
                            {paymentItem && !isPaymentListDivider(paymentItem) && (
                              <HistoryTransaction
                                payment={paymentItem}
                                currancy={balance?.bcurrency}
                                height={ITEM_SIZE}
                                onClick={() => navigate(pathsArg(Paths.transaction, { id: paymentItem.id }))}
                              />
                            )}
                          </div>
                        );
                      }}
                    </List>
                  )}
                </InfiniteLoader>
              </Box>
            </>
          ) : (
            <EmptyData title={t('wallet.emptyTitle')} data-qa={'wallet.emptyTitle'} text={t('wallet.emptyText')} />
          ))}
      </Box>

      {/* <FilterIconButton
        isSelectFilters={
          !!allFilters.amount || !!allFilters.datePeriod || !!allFilters.sortFinance || !!allFilters.operationType
        }
      />
      <FiltersOfWallet
        min={minMax.min}
        max={minMax.max}
        onApplyFilters={onApplyFiltersHandler}
        onResetFilters={onResetFiltersHandler}
      />*/}
    </>
  );
};

export default Finance;
