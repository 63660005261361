import { Stack, Typography, Skeleton, Box } from '@mui/material';
import { FC } from 'react';

import { ThemeFontWeight } from 'src/styles';

import { InfoTableProps } from './InfoTable.types';

const TableItem: FC<{ title: string; value?: any; mark?: string }> = ({ title, value, mark }) => {
  const blockSx = {
    fontSize: '1.062rem',
    fontWeight: ThemeFontWeight.semiBold,
    borderRadius: '10px',
    backgroundColor: 'background.default',
    width: '100%',
    minHeight: '26px',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Stack sx={{ flex: 1, alignItems: 'center' }}>
      <Typography sx={{ fontSize: '0.75rem', mb: '8px' }}>{title}</Typography>
      {value !== undefined ? (
        <Typography component="div" sx={blockSx}>
          {value}
          {mark && (
            <Typography component="span" sx={{ fontSize: '0.75rem', fontWeight: ThemeFontWeight.regular, pl: '4px' }}>
              {' ' + mark}
            </Typography>
          )}
        </Typography>
      ) : (
        <Skeleton variant="rectangular" component="div" sx={blockSx} />
      )}
    </Stack>
  );
};

const InfoTable: FC<InfoTableProps> = ({ items, onClick, children, sx }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        cursor: onClick ? 'pointer' : 'default',
        ...sx,
      }}
      onClick={() => onClick && onClick()}
    >
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding: '8px 16px',
        }}
        direction="row"
        spacing={1}
      >
        {items ? items.map((item, idx) => <TableItem key={`InfoTableItem_${idx}`} {...item} />) : null}
      </Stack>
      {children}
    </Box>
  );
};

export default InfoTable;
