import { FeatureCollection, Geometry } from 'geojson';
import { polygonToCells, cellToLatLng, cellToBoundary, getResolution } from 'h3-js';
import { GeoJSONSourceOptions } from 'mapbox-gl';

import { THexagonItem } from 'src/services';

import { h3ToPolygon } from './h3-utils';

export async function loadGeoJson() {
  const geojson_file = await (await fetch('/data/ne_110m_admin_0_countries.geojson')).json();
  return geojson_file;
}

export function GeneratePoligonHex(level: number) {
  const geojson_file = loadGeoJson();
  return GeneratePoligonFromGeoJson(geojson_file, level);
}

export async function GenerateOceansPoligonHex(level: number) {
  const geojson_file = await (await fetch('/data/oceans.json')).json();
  return GeneratePoligonFromGeoJson(geojson_file, level);
}

export async function GenerateAllGHex(level: number) {
  const geojson_file = await (await fetch('/data/ne_110m_admin_0_countries.geojson')).json();
  const geojson_file2 = await (await fetch('/data/oceans.json')).json();
  geojson_file.features = geojson_file.features.concat(geojson_file2.features);
  return GeneratePoligonFromGeoJson(geojson_file, level);
}

export async function GenerateAllHex(level: number) {
  const geojson = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: {
          coordinates: [
            [
              [90, 90],
              [90, -90],
              [-90, -90],
              [-90, 90],
              [90, 90],
            ],
          ],
          type: 'Polygon',
        },
      },
    ],
  };
  return GeneratePoligonFromGeoJson(geojson, level);
}

export function GetHexLevel(hex: string) {
  return getResolution(hex);
}

export async function GeneratePoligonFromGeoJson(geojson_data: any, level: number) {
  const h3Res = level;
  let h3Cells: [][] = [];

  for (const geoData of geojson_data.features) {
    const geoJson = geoData.geometry;
    let h3PoligonCell;

    if (geoJson.type === 'Polygon') {
      h3PoligonCell = polygonToCells(geoJson.coordinates, h3Res, true);
    } else if (geoJson.type === 'MultiPolygon') {
      h3PoligonCell = geoJson.coordinates.reduce(
        (n: string[], coords: any) => n.concat(polygonToCells(coords, h3Res, true)),
        []
      );
    }

    h3Cells.push(h3PoligonCell);
  }

  return h3Cells;
}

export async function GenerateHex(level: number) {
  const poligonHex = GeneratePoligonHex(level);
  // const  multy = cellsToMultiPolygon(poligonHex.map((h3idx) => h3idx.hex), true);
  return poligonHex;
}

export const hexToFeature = (hex: THexagonItem, coverage: number) => {
  const h3Idx = hex.index;
  const hexCenter = cellToLatLng(h3Idx);
  //const hexGeoJson = cellToBoundary(h3Idx, true).reverse(); // correct polygon winding
  const hexGeoJson = h3ToPolygon(h3Idx, coverage || 1).reverse();

  // stitch longitudes at the anti-meridian
  const centerLng = hexCenter[1];
  hexGeoJson.forEach((d) => {
    const edgeLng = d[0];
    if (Math.abs(centerLng - edgeLng) > 170) {
      // normalize large lng distances
      d[0] += centerLng > edgeLng ? 360 : -360;
    }
  });

  return {
    id: h3Idx,
    type: 'Feature',
    properties: {
      id: h3Idx,
      hexCenterLat: hexCenter[0],
      hexCenterLng: hexCenter[1],
      center: [hexCenter[1], hexCenter[0]],
      level: hex.level,
      title: hex.title,
      aviable: hex.status === 'FREEAVAIL',
      freeunaviable: hex.status === 'FREEUNAVAIL',
      unaviable: hex.status === 'UNAVAIL',
      busy: hex.status === 'BUSY',
      selectdev: hex.status === 'SELECTDEV',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [hexGeoJson],
    },
  };
};

export const GenerateHexGeoJson = (h3info: THexagonItem[], coverage?: number): GeoJSONSourceOptions['data'] => {
  return {
    type: 'FeatureCollection',
    // id: 'hexogons',
    features: h3info.map((hexInfo: THexagonItem) => {
      return hexToFeature(hexInfo, coverage || 1);
    }),
  } as FeatureCollection<Geometry>;
};

export const GenerateHexPoligonGeoJson = (h3info: THexagonItem[], coverage?: number) => {
  return {
    type: 'FeatureCollection',
    id: 'hexogons',
    features: h3info.map((hexItem) => {
      const hexGeoJson = cellToBoundary(hexItem.index, true).reverse();
      const hexCenter = cellToLatLng(hexItem.index);
      const centerLng = hexCenter[1];

      hexGeoJson.forEach((d) => {
        const edgeLng = d[0];
        if (Math.abs(centerLng - edgeLng) > 170) {
          // normalize large lng distances
          d[0] += centerLng > edgeLng ? 360 : -360;
        }
      });

      return {
        type: 'Feature',
        properties: {
          id: hexItem.index,
        },
        geometry: {
          type: 'Polygon',
          coordinates: hexGeoJson,
        },
      };
    }),
  };
};
