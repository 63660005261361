import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CircleLoading, EmptyData } from 'src/atoms';
import { ErrorContext } from 'src/context/ErrorContext';
import { readNFT, TCard } from 'src/services';
import { Card } from 'src/templates';

import { NFTCardProps } from './NFTCard.types';

const NFTCard: FC<NFTCardProps> = ({ compositeId }) => {
  const { t } = useTranslation('app');
  const { throwServerError } = useContext(ErrorContext);

  const [isLoading, setLoading] = useState(false);
  const [info, setInfo] = useState<TCard>();

  useEffect(() => {
    setLoading(true);
    readNFT(compositeId)
      .then(({ data }) => {
        setInfo(data as TCard);
      })
      .catch((error) => {
        if (error.response.status >= 500) throwServerError();
      })
      .finally(() => setLoading(false));
  }, [compositeId, throwServerError]);

  if (isLoading) {
    return <CircleLoading isLoading={isLoading} />;
  }

  if (info) {
    return <Card item={info} sx={{ height: '100%' }} />;
  } else {
    return <EmptyData title={t('NFTCard.emptyTitle')} data-qa={'NFTCard.emptyTitle'} text={t('NFTCard.emptyText')} />;
  }
};

export default NFTCard;
