import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import FaceIcon from '@mui/icons-material/Face';
import GroupsIcon from '@mui/icons-material/Groups';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import { Box, Paper, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { Paths } from 'src/constants';

import { ScrollToTopProps } from './BottomNavigation.types';

const pages = [
  { label: 'menu.home', nav: Paths.dashboard, icon: AccountBalanceIcon },
  { label: 'menu.avatar', nav: Paths.avatar, icon: FaceIcon },
  { label: 'menu.play', nav: Paths.hexglobe, icon: SportsEsportsIcon },
  { label: 'menu.team', nav: Paths.team, icon: GroupsIcon },
  { label: 'menu.wallet', nav: Paths.wallet, icon: AccountBalanceWalletIcon },
];

const getIndex = (path: string) => {
  const _path = path.split('/')[1];
  const idx = pages.findIndex((page) => page.nav.split('/')[1] === _path);
  return idx > 0 ? idx : 0;
};

const BottomNavigationFixed: FC<ScrollToTopProps> = ({ children }) => {
  const location = useLocation();
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  const [value, setValue] = useState(getIndex(location.pathname));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Box sx={{ pb: 7 }} className="h-screen">
      {children}
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            navigate(pages[newValue].nav);
          }}
        >
          {pages.map((page) => {
            const Icon = page.icon;
            return <BottomNavigationAction key={`menu_${page.label}`} label={t(page.label)} icon={<Icon />} />;
          })}
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default BottomNavigationFixed;
