import { AxiosError } from 'axios';
import { differenceInYears, parse } from 'date-fns';
import { t } from 'i18next';

import { StorageKeys } from 'src/constants';
import { Phrase } from 'src/services';

export const getLocale = (): string => window.localStorage.getItem(StorageKeys.LANG) || '';
export const getPrefix = (): string => window.localStorage.getItem(StorageKeys.LANG_PREFIX) || '';
export const setPrefix = (pref: string): void => window.localStorage.setItem(StorageKeys.LANG_PREFIX, pref);
export const getCensor = (): string => window.localStorage.getItem(StorageKeys.CENSOR) || '';

export const hexToRgb = (hex: string, opacity = 1) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        opacity,
      }
    : null;
};

export const hexToRgbStr = (hex: string, opacity = 1) => {
  const rgb = hexToRgb(hex, opacity);
  return `rgb(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, ${rgb?.opacity})`;
};

export const hexToRgbArr = (hex: string, opacity = 1): ['rgba', number, number, number, number] => {
  const rgb = hexToRgb(hex, opacity);
  if (rgb) {
    return ['rgba', rgb?.r, rgb?.g, rgb?.b, rgb?.opacity];
  } else {
    return ['rgba', 0, 0, 0, 1];
  }
};

export const isDarkTheme = (): boolean => {
  const localTheme = localStorage.getItem(StorageKeys.THEME);

  if (localTheme) {
    return localTheme === 'dark';
  } else if (window.matchMedia) {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  } else {
    return false;
  }
};

export const parseError = (errorLike: unknown): string => {
  if (errorLike instanceof AxiosError) {
    if (errorLike.response?.status === 406) {
      return 'USER_NOT_REGISTERED';
    }
    return 'BAD_REQUEST';
  }
  if (errorLike instanceof Error) return errorLike.message;
  if (typeof errorLike === 'string') return errorLike;
  if (typeof errorLike === 'number') return String(errorLike);
  if (errorLike instanceof Object) return String(errorLike);
  return 'Unknown error';
};

export const parseErrorTr = (key: string, errorLike: unknown): string => {
  const errMsg = parseError(errorLike);
  return t(`app:${key}.${errMsg}`);
};

export const isWalletError = (o: any): o is { code: number; message: string } => {
  if (!('code' in o) && typeof o.code !== 'number') return false;
  if (!('message' in o) && typeof o.message !== 'string') return false;
  return true;
};

export const translationsArrayToObject = (translations: Phrase[]) => {
  return translations.reduce((result, translationModel) => {
    const _result: any = Object.assign({}, result);
    _result[translationModel.phrase_title] = translationModel.translation;
    return _result;
  }, {});
};

export const copyToClipboard = async (data: string) => {
  await navigator.clipboard.writeText(data);
};

export const isComingOfAge = (dob: string): boolean => {
  const date = parse(dob, 'yyyy-MM-dd', new Date());
  const age = differenceInYears(new Date(), date);
  return age >= 18 ? true : false;
};

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const abbrNum = (n: number) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
};

export const addZeroNum = (n: number) => {
  const max = 1000000000;
  if (n > max) return n;
  else return (max + n).toString().slice(1);
};

// вычисляет угол в градусах между 2мя точками
export const degree2p = (p1: [number, number], p2: [number, number]) =>
  (p2[0] > p1[0] ? 0 : 180) + (p2[0] !== p1[0] ? Math.atan((p2[1] - p1[1]) / (p2[0] - p1[0])) : 0) * (180.0 / Math.PI);

export class SearchParams extends URLSearchParams {
  getByType(name: string, byType?: 'number' | 'string' | 'boolean'): string | number | boolean | undefined {
    let param: string | number | undefined = super.get(name) || undefined;
    if (param) {
      switch (byType) {
        case 'number':
          return parseInt(param, 10);
        case 'boolean':
          return param.toLowerCase() === 'true' || param === '1' ? true : false;
      }
    }
    return param;
  }
}
