import { TFunction } from 'react-i18next';

import { Paths } from 'src/constants';
import { SwitchLinks } from 'src/types';

export const getSwitchLinksForTeam = (t: TFunction<'app', undefined>, current: string): SwitchLinks[] => {
  // const globePath = current === Paths.teamGlobeAll ? Paths.teamGlobeAll : Paths.teamGlobe;
  const globePath = Paths.teamGlobe;

  return [
    { id: 1, title: t('app:team.switchGlobe'), path: globePath },
    { id: 2, title: t('app:team.switchTree'), path: Paths.teamTree },
    { id: 3, title: t('app:team.switchList'), path: Paths.team },
  ];
};
