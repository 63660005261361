import { Box, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BPrice, LevelSwitcher, Loading, ShoppingCartFees } from 'src/atoms';
import { Paths, getImgSrc } from 'src/constants';
import { TOrder, TOrderPrice, createPackOrder, priceSum } from 'src/services';
import { LEVELS_TO_COLORS } from 'src/styles';
import { Levels } from 'src/types';

import Help from '../Help';
import ShoppingCart from '../ShoppingCart';

const defaultPrice = {
  cost: 0,
  bcost: 0,
  currency: '',
  bcurrency: '',
} as TOrderPrice;

const StudyClans: FC = () => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [help, setHelp] = useState<boolean>(true);
  const [isLoading, setLoading] = useState(true);
  const [level, setLevel] = useState(Levels.First);
  const [order, setOrder] = useState<TOrder>();
  const [shop, setShop] = useState<boolean>(false);

  const accentColor = LEVELS_TO_COLORS[level - 1];

  const gettedLevel = shop ? Levels.Fifth : level;

  const onChangeLevel = (changeLevel: Levels) => {
    setLevel(changeLevel);
  };

  const priceLevel = useCallback(() => {
    if (order) {
      return order.items.reduce((price, orderItem) => priceSum(price, orderItem.buyer_total, orderItem.count), {
        ...defaultPrice,
      });
    }
    return { ...defaultPrice };
  }, [order]);

  useEffect(() => {
    if (help) return;
    setLoading(true);
    createPackOrder([gettedLevel])
      .then(({ data }) => {
        data.items.map((item, idx) => {
          item.count = idx < level ? 1 : 0;
          return item;
        });
        setOrder(data);
      })
      .catch((error) => {
        // if (error.response?.status >= 500) throwServerError();
        enqueueSnackbar(t('errorMsg.clanCreate'), { variant: 'error' });
      })
      .finally(() => setLoading(false));
  }, [enqueueSnackbar, help, level, t, gettedLevel]);

  if (help) {
    return (
      <>
        <Help tkey="study.clans" onNext={() => setHelp(false)} />
      </>
    );
  } else if (shop) {
    return (
      <Help sx={{ pb: '10px', mb: '16px', overflowY: 'scroll' }} tkey="study.clans.shop">
        <>
          {isLoading ? (
            <Loading />
          ) : (
            order && (
              <ShoppingCart
                selectedType="linear"
                order={order}
                wrapperId={gettedLevel}
                onFinish={() => navigate(Paths.study)}
                onPrev={() => setShop(false)}
              />
            )
          )}
        </>
      </Help>
    );
  } else {
    return (
      <>
        <Help
          sx={{ pb: '10px', mb: '16px', overflowY: 'scroll' }}
          tkey="study.clans.connect"
          onPrev={() => setHelp(true)}
          onNext={() => setShop(true)}
        >
          <>
            <Box
              sx={{
                position: 'fixed',
                top: 100,
                left: 0,
                right: 0,
                zIndex: 100,
                backgroundColor: (theme) => theme.palette.background.default,
                height: 60,
              }}
            >
              <LevelSwitcher level={level} filled={[1, 2, 3, 4, 5]} onChangeLevel={onChangeLevel} />
            </Box>
            {isLoading && <Loading />}
            <Stack sx={{ pt: '70px', pb: '10px', position: 'relative' }} alignItems="center">
              <Box
                sx={{ width: '100%', height: 'auto', minHeight: '100px', textAlign: 'center' }}
                data-qa={`study.clans.connect.level${level}.img`}
                component="img"
                src={getImgSrc(t(`study.clans.connect.level${level}.img`), 's600')}
              />
              <Typography variant="h2" sx={{ color: accentColor, py: '16px' }} textAlign="center">
                {t(`study.clans.connect.level${level}.title`)}
              </Typography>
              <Typography textAlign="center">{t(`study.clans.connect.level${level}.description`)}</Typography>
              {order && (
                <>
                  <BPrice sx={{ mb: '16px' }} price={priceLevel()} />
                  <ShoppingCartFees items={order.items} />
                </>
              )}
            </Stack>
          </>
        </Help>
      </>
    );
  }
};

export default StudyClans;
