import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeFontWeight } from 'src/styles';

export const ReferralWidgetBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: 15,
}));

export const ReferralTitle = styled('span')(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: ThemeFontWeight.medium,
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
  marginBottom: 12,
  position: 'relative',
  border: '1px solid',
  borderColor: theme.palette.background.default,
  borderRadius: '15px 0 15px 0',
  backgroundColor: theme.palette.background.paper,
  padding: '8px 16px',
  top: 2,
}));

export const ReferralLink = styled('span')(({ theme }) => ({
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: ThemeFontWeight.semiBold,
  color: theme.palette.secondary.main,
  overflow: 'hidden',
  textDecoration: 'underline',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
}));
