import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeColors, ThemeGradients } from 'src/styles';

export const Text = styled(Typography)(() => ({
  fontSize: '0.688rem',
  lineHeight: '13px',
  color: ThemeColors.gray500,
  marginBottom: 16,
}));

export const SpeedWrapper = styled(Box)(() => ({
  width: 280,
  height: 208,
  margin: '0 auto 16px',
}));

export const Chart = styled(Box)(() => ({
  position: 'relative',
  height: 140,
  borderRadius: '140px 140px 0 0',
}));

export const Semicircle = styled(Box)(() => ({
  position: 'absolute',
  bottom: 0,
  left: 96,
  width: 88,
  height: 44,
  borderRadius: '44px 44px 0 0',
  backgroundColor: ThemeColors.black,
}));

export const Arrow = styled(Box)(() => ({
  position: 'absolute',
  bottom: 12,
  left: 32,
  width: 0,
  height: 0,
  borderStyle: 'solid',
  borderWidth: '0 12px 112px 12px',
  borderColor: `transparent transparent ${ThemeColors.black} transparent`,
  transform: 'rotate(-90deg)',
  transformOrigin: 'center bottom',
  zIndex: 10,
}));

export const Triangle = styled(Box)(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  transformOrigin: 'center bottom',
  overflow: 'hidden',
}));

export const Circle = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  background: 'silver',
  borderRadius: '50% 50% 0 0 / 100% 100% 0 0',
  transformOrigin: 'center bottom',
  transform: 'rotate(120deg)',
}));

export const CircleValue = styled(Typography)(() => ({
  position: 'absolute',
  top: 50,
  left: 10,
  width: 115,
  fontSize: '1.125rem',
  fontWeight: 600,
  textAlign: 'center',
  color: ThemeColors.white,
  transform: 'rotate(-52deg)',
  zIndex: 5,
}));

export const SpeedInfo = styled(Box)(() => ({
  height: 68,
  border: `1px solid ${ThemeColors.black}`,
  borderRadius: '0 0 16px 16px',
}));

export const SpeedInfoTitle = styled(Typography)(() => ({
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: 600,
  background: ThemeGradients.text,
  backgroundClip: 'text',
  textFillColor: 'transparent',
  textAlign: 'center',
  marginTop: 8,
  marginBottom: 8,
}));

export const SpeedInfoValue = styled(Typography)(() => ({
  fontSize: '1.062rem',
  lineHeight: '22px',
  fontWeight: 600,
  margin: 0,
  textAlign: 'center',
}));
