import { Stack, Button, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { MetaMaskImg, TrustWalletLogoIcon32 } from 'src/assets/images';
import { connectToProviderWallet } from 'src/helpers';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { MetamaskInstall, TrustWalletInstall } from 'src/molecules';
import { setWalletLink } from 'src/services';
import { selectUser, signInUser, connectWallet, getInfoUser, IParamsSignIn } from 'src/store/userSlice';
import { hasEthereum, hasTrustwallet, isMetamask, isTrustwallet, walletProviders } from 'src/types';
import { parseError, parseErrorTr } from 'src/utils';

import { WalletConnectProps } from './WalletConnect.types';

const WalletConnect: FC<WalletConnectProps> = ({ referral, isReg, walletProvider, onConnect }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { isShowAuth } = useAppSelector(selectUser);
  const { t } = useTranslation('app');
  const [autoAuth, setAutoAuth] = useState(true);

  const page = isReg === undefined ? 'connect' : isReg === true ? 'registration' : 'signin';

  const signInHandler = async (provider: walletProviders | undefined) => {
    if (isReg === undefined && provider) {
      connectWallet(provider)
        .then((params) => {
          setWalletLink(params)
            .then(() => {
              dispatch(getInfoUser());
              if (onConnect) onConnect();
            })
            .catch((err) => {
              enqueueSnackbar(parseErrorTr(`wallet_${page}`, err), { variant: 'error' });
            });
        })
        .catch((err) => {
          enqueueSnackbar(parseError(err), { variant: 'error' });
        });
    } else {
      const hash = uuidv4();
      try {
        const metamaskResponse = await connectToProviderWallet(hash, provider);
        signInWallet({
          public_address: metamaskResponse.address,
          nonce: metamaskResponse.token,
          signature: metamaskResponse.signature,
        });
      } catch (error) {
        return enqueueSnackbar(parseError(error), { variant: 'error' });
      }
    }
  };

  const signInWallet = (params: IParamsSignIn) => {
    dispatch(
      signInUser({
        provider: 'wallet',
        referral_uuid: referral,
        authData: params,
      })
    );
  };

  if ((isMetamask || isTrustwallet) && autoAuth) {
    if (isReg === false) {
      signInHandler(isMetamask ? walletProviders.MetaMask : walletProviders.TrustWallet);
    }
    setAutoAuth(false);
  }

  return (
    <Stack>
      {!walletProvider ||
        (walletProvider === walletProviders.MetaMask &&
          (hasEthereum ? (
            <Button
              data-qa={`walletConnect.${page}.Metamask`}
              variant="text"
              onClick={() => signInHandler(walletProviders.MetaMask)}
              disabled={isShowAuth}
              sx={{ px: '15px', mb: 1 }}
            >
              <Box component="img" src={MetaMaskImg} alt="MetaMask Logo" sx={{ mr: 1 }} />{' '}
              {t(`walletConnect.${page}.Metamask`)}
            </Button>
          ) : (
            <MetamaskInstall />
          )))}
      {!walletProvider ||
        (walletProvider === walletProviders.TrustWallet &&
          (hasTrustwallet ? (
            <Button
              data-qa={`walletConnect.${page}.TrustWallet`}
              variant="text"
              onClick={() => signInHandler(walletProviders.TrustWallet)}
              disabled={isShowAuth}
              sx={{ px: '15px', mb: 1 }}
            >
              <Box component="img" src={TrustWalletLogoIcon32} alt="TrustWallet Logo" sx={{ mr: 1 }} />
              {t(`walletConnect.${page}.TrustWallet`)}
            </Button>
          ) : (
            <TrustWalletInstall />
          )))}
    </Stack>
  );
};

export default WalletConnect;
