import CachedIcon from '@mui/icons-material/Cached';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, Divider, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BNBIcon } from 'src/assets/icons';
import { BPrice, ShoppingCartFees } from 'src/atoms';
// import { PaymentProducts } from 'src/molecules';
import { checkNumberPrice } from 'src/utils';

import HelpButtonDrawer from '../HelpButtonDrawer';

import { CheckTitle, CostCalc, CostCalcLabel, Row } from './GetMoneyForm.styles';
import { GetMoneyFormProps } from './GetMoneyForm.types';

const GetMoneyForm: FC<GetMoneyFormProps> = ({ order, wrapperId, onChange, onFinish, onReload, accentColor }) => {
  const { t } = useTranslation('app');
  // const [isOpen, setOpen] = useState(false);
  const [outValue, setOutValue] = useState<string | undefined>();

  const onOpenPayment = () => {
    if (outValue) onFinish(Number(outValue));
  };

  const toint = (stringint: string) => {
    try {
      return parseInt(stringint, 10) || 0;
    } catch (error) {
      return 0;
    }
  };

  const onValueChange = (val: string | undefined) => {
    // const newVal = val ? val.replace(/[^\d.]/g, '') : undefined;
    let newVal = val ? val.replace(/[^\d+(.\d+)?$]/g, '') : undefined;
    if (newVal) {
      const newValList = newVal.split('.');
      if (newValList.length > 1) {
        newVal = toint(newValList[0]) + '.' + newValList.slice(1).join('');
      }
    }
    setOutValue(newVal);
  };

  // const onClosePayment = () => setOpen(false);

  return (
    <>
      <Typography sx={{ mb: '16px' }}>{t('getMoney.warning')}</Typography>

      <CostCalc className="GetMoneyCostCalc_black GetMoneyCostCalc_black__border">
        <CostCalcLabel>{t('getMoney.cost.label.balance')}:</CostCalcLabel>
        <Row>
          <BPrice sx={{ fontSize: '1.062rem' }} price={order.balances.current_balance} />
          <IconButton sx={{ marginTop: '-8px!important', p: 0 }} onClick={() => onReload()}>
            <CachedIcon />
          </IconButton>
        </Row>

        <Divider variant="fullWidth" sx={{ my: '10px' }} />

        <CostCalcLabel>{t('getMoney.cost.label.notRecomend')}:</CostCalcLabel>
        <Row>
          <BPrice sx={{ fontSize: '1.062rem' }} price={order.balances.notrecommend} />
          <HelpButtonDrawer sx={{ marginTop: '-8px!important', p: 0 }} tkey="getMoney.notRecomend" />
        </Row>
        <Divider variant="fullWidth" sx={{ my: '10px' }} />
        <CostCalcLabel>{t('getMoney.cost.label.recomend')}:</CostCalcLabel>
        <BPrice sx={{ fontSize: '1.062rem' }} price={order.balances.recommend} />

        <Divider variant="fullWidth" sx={{ my: '10px' }} />

        <CostCalcLabel sx={{ mb: '16px' }}>{t('getMoney.cost.label.count')}</CostCalcLabel>
        <TextField
          sx={{
            backgroundColor: 'background.paper',
            '& .MuiOutlinedInput-root input': {
              px: '0px',
            },
          }}
          onChange={(event) => onValueChange(event.target.value)}
          onBlur={() => onChange(outValue ? Number(outValue) : undefined)}
          value={outValue || '0.0'}
          error={checkNumberPrice(outValue || '') > order.balances.current_balance.bcost}
          fullWidth
          inputProps={{
            maxLength: 20,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="reset" onClick={() => onValueChange(undefined)}>
                  <HighlightOffIcon />
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <BNBIcon className="BPrice_icon_input" />
              </InputAdornment>
            ),
          }}
        />
        {/*<CostCalcLabel sx={{ color: 'secondary.main' }}>(~2000 USDT)</CostCalcLabel>*/}
      </CostCalc>

      <ShoppingCartFees showFeeOnly={true} items={order.items} showTotal={false} />

      <Divider variant="fullWidth" sx={{ my: '8px' }} />
      <CostCalc>
        <CheckTitle>{t('getMoney.cost.label.total')}:</CheckTitle>
        <BPrice sx={{ fontSize: '1.062rem' }} price={order.balance_change} />
      </CostCalc>

      <Divider variant="fullWidth" sx={{ my: '8px', mb: '16px' }} />

      <Button
        data-qa="button.getMoney"
        disabled={checkNumberPrice(outValue || '') > order.balances.current_balance.bcost}
        variant="outlined"
        sx={{ width: '100%' }}
        onClick={onOpenPayment}
      >
        {t('button.getMoney')}
      </Button>
    </>
  );
};

export default GetMoneyForm;
